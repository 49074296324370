/**
 * ORIGINAL FILE = tasksService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
const format = JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd");
export class TasksService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    DatabaseApi;
    assertNever;
    statuses;
    statusesOptionsRecords = {
        "Completed": { id: 0, status: "Completed", label: "Completed", colorClass: "default" },
        "Incompleted": { id: 1, status: "Incompleted", label: "Incompleted", colorClass: "grey" },
        "Unassigned": { id: 2, status: "Unassigned", label: "Unassigned", colorClass: "red" },
        "Broadcasting": { id: 3, status: "Broadcasting", label: "Broadcasting", colorClass: "orange" },
        "Assigned": { id: 4, status: "Assigned", label: "Assigned", colorClass: "grey" },
        "Scheduled": { id: 5, status: "Scheduled", label: "Scheduled", colorClass: "blue" },
        "Missing": { id: 6, status: "Missing", label: "Missing", colorClass: "red" },
        "Future": { id: 7, status: "Future", label: "Future", colorClass: "lightblue" },
        "Canceled": { id: 8, status: "Canceled", label: "Canceled", colorClass: "red" }
    };
    constructor(DatabaseApi2, endpoint, $rootScope, DatabaseApi, assertNever) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
        this.DatabaseApi = DatabaseApi;
        this.assertNever = assertNever;
        this.statuses = Object.values(this.statusesOptionsRecords);
    }
    /**
     * Get all caregiver tasks by date range
     */
    getCaregiverTasks = (caregiverId, filters) => {
        let queryParams = "";
        if (filters.from !== null && filters.to !== null) {
            queryParams = new URLSearchParams({
                from: filters.from.format(format),
                to: filters.to.format(format),
            });
        }
        const queryParamsString = queryParams.toString();
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/tasks?${queryParamsString}`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                caregiverId: caregiverId,
            },
        });
        return this.DatabaseApi2.get(url).then((res) => {
            return res.data.caregiverTaskInstances.map(this.parseCaregiverTask);
        });
    };
    getTaskStatus = (task) => {
        switch (task.type) {
            case "RegularTask":
                if (task.taskInstanceCanceledAt) {
                    return this.statusesOptionsRecords.Canceled;
                }
                if (task.completionDate !== null) {
                    return this.statusesOptionsRecords.Completed;
                }
                if (task.hasClockedIn) {
                    return this.statusesOptionsRecords.Incompleted;
                }
                const overDueDate = moment().isAfter(moment(task.dueDate.toString()));
                if (overDueDate) {
                    return this.statusesOptionsRecords.Missing;
                }
                if (!overDueDate && task.scheduleDateTime !== null) {
                    return this.statusesOptionsRecords.Scheduled;
                }
                return this.statusesOptionsRecords.Assigned;
            case "FutureTask":
                return this.statusesOptionsRecords.Future;
            case "DraftTask":
                return this.statusesOptionsRecords.Unassigned;
            case "Broadcasting":
                return this.statusesOptionsRecords.Broadcasting;
            default:
                return this.assertNever(task);
        }
    };
    parseCaregiverTask = (caregiverTask) => {
        const patient = this.DatabaseApi.getPatientById(caregiverTask.patientId);
        const status = this.getTaskStatus(caregiverTask);
        return { ...caregiverTask, patientAddress: patient.address?.text, status: status };
    };
    deletePatientTaskDocument = (patientId, patientTaskInstanceId, patientDocumentTypeId) => {
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_task_instances/:patientTaskInstanceId/patient_documents/:documentTypeId`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: patientId,
                patientTaskInstanceId: patientTaskInstanceId,
                documentTypeId: patientDocumentTypeId,
            },
        });
        return this.DatabaseApi2.delete(url);
    };
}
angular.module("dashboard").service("tasksService", TasksService);
