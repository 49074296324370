'use strict'
app.controller("hhaIntegrationUnlinkedPatientsCtrl", function (
    $scope,
    NgTableParams,
    hhaxIntegrationService
) {
    const init = () => {
        $scope.globalFilter = { val: "" };
        $scope.isLoading = true;

        hhaxIntegrationService.getUnlinkedPatients().then(res => {
            initTable(res.data.unlinkedPatients);
            $scope.isLoading = false;
        })
    }


    const initTable = (data) => {
        const options = {
            count: 25,
            sorting: { firstName: "desc" }
        };

        $scope.tableParams = new NgTableParams(options, {
            counts: [],
            dataset: data
        });
    }

    $scope.applyGlobalSearch = () => {
        const filter = { $: $scope.globalFilter.val };
        if ($scope.tableParams) {
          angular.extend($scope.tableParams.filter(), filter);
        }
      };

    init();
});