/**
 * ORIGINAL FILE = assertNever.const.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
function assertNever(value, noThrow) {
    if (noThrow) {
        return value;
    }
    throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}
angular.module("dashboard").constant("assertNever", assertNever);
export {};
