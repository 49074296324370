app.directive("paginationPanel", function () {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/pagination-panel.html',
    scope: {
      paginationLogic: '=',
    },
    link: function (scope) {
      scope.getCursorStyleForPageLabel = function (pageLabel) {
        var cursorStyle = "pointer";
        if (pageLabel.isClickDisabled) {
          cursorStyle = "not-allowed";
        }
        else if (scope.paginationLogic.isPageSelected(pageLabel.value)) {
          cursorStyle = "default";
        }
        return cursorStyle;
      };
    }
  };
});
