app.controller('faxModalCtrl', function($scope, DatabaseApi, $rootScope, mfModal) {
  $scope.result = {};

  $scope.isLoading = false;

  $scope.documents = $scope.$resolve.documents;

  $scope.send = function () {
    var payload = { phoneNumber: "+1" + $scope.result.phoneNumber, name: $scope.result.name };
    if ($scope.result.body) {
      payload.body = $scope.result.body;
    }

    const documentIds = $scope.documents.map(function(doc) { return doc.id;});
    
    $scope.isLoading = true;

    let url = "agencies/" + $rootScope.agencyId;

    if($scope.$resolve.docsType === 'notes'){
      payload.noteIds = documentIds;
      url = url + '/agency_members/' + $rootScope.agencyMemberId;
      const notesUrl = `${$scope.$resolve.patientId}/send_notes_by_fax`;
      if($scope.$resolve.isCaregiver){
        url = `${url}/caregivers/${notesUrl}`;
      } else {
        url = `${url}/patients/${notesUrl}`;
      }
    } else {
      payload.documentIds = documentIds;
      url = url + "/" + $rootScope.agencyMemberId + "/send_patient_documents_by_fax";
    }

    DatabaseApi.post(url, payload).then(function() {
      $scope.$close();
      $scope.$resolve?.onSuccess?.();
    }, function(error) {
      let errorMessage = 'Failed, please try again';
      if (
        (
          error.data &&
          error.data.details &&
          error.data.details.length > 0 &&
          error.data.details[0] === "Agency member signature not found!"
        ) || (
          typeof error === 'string' &&
          error.data.includes("Agency member signature not found!")
        )
      ) {
        errorMessage = "This action cannot be performed without a saved signature. Please setup your signature (through Settings → Edit Profile) and try again";
      }
      //toaster.pop('error', errorMessage);
      const modal = mfModal.create({
        variant: "danger",
        subject: "Error",
        message: errorMessage,
        layoutOrder: ["message"],
        confirmLabel: "Ok",
        hideCancelButton: true,
        onComplete: () => {
          modal.close();
          $scope.isLoading = false;
        }
      });      
    });
  };

    $scope.copyHistory = function(row){
        $scope.result.phoneNumber = angular.copy(row.phoneNumber);
        $scope.result.phoneNumber = $scope.result.phoneNumber.substr(2);
        $scope.result.name = row.name;
    };

    if($scope.$resolve.docsType !== 'notes'){
      DatabaseApi.get("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_documents_sending_history/" + $scope.$resolve.patientId + "/FAX",
          $scope.result).then(function(res) {
          $scope.history = res.data.history;
          console.log($scope.history);
      }, function() {

      });
    }

});
