app.controller('patientVbpNewReportModalCtrl', function($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {

    $scope.newReport = {
        answers: $scope.$resolve.questions.map(question => {
            return {
                date: '',
                nursingQuestionId: question.id,
                questionText: question.questionText,
                isInitial: '',
                possibleAnswers: question.possibleAnswers,
                showNotesAndRisk: false,
                answer: '',
                notes: '',
                date: '',
                riskLevel: 1,
                riskLevelSlider: {
                    value: 1,
                    options: {
                        floor: 1,
                        ceil: 3,
                        showSelectionBar: true,
                        showTicksValues: true,
                        getTickColor: (value) => {
                            if (value === 2) return 'orange';
                            if (value === 3) return 'red';
                            return '#d8e0f3';
                        },
                        translate: function(value) {
                            if (value === 1) return 'Not urgent';
                            if (value === 2) return 'Urgent';
                            if (value === 3) return 'Very urgent';
                            return '';
                        }
                    }
                }
            };
        }),
    };
    $scope.isCalendarOpen = false;

    $scope.closeModal = (res) => {
        if (!res) {
            res = 'ok';
        }
        $uibModalInstance.close(res);
    }

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    }

    $scope.submitVbpReport = () => {
        const errorMessage = validateForm();
        if (errorMessage !== '') {
            toaster.pop('error', errorMessage);
            return;
        }

        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${$scope.$resolve.patient.id}/vbp_items`;
        DatabaseApi.post(url, convertFormToRequestBody()).then((res) => {
            toaster.pop('success', 'Report submitted successfully');
            $scope.closeModal('success');
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong');
        });
    }

    const convertFormToRequestBody = () => {
        const body = angular.copy($scope.newReport);
        body.date = LocalDate.from(nativeJs(body.date));
        delete body.possibleAnswers;
        body.answers = body.answers.map(answer => {
            if (answer.answer === '') {
                return null;
            }
            delete answer.isInitial;
            answer.date = body.date;
            if (!answer.showNotesAndRisk) {
                answer.notes = null;
                answer.riskLevel = null
            } else {
                if (!answer.notes) {
                    answer.notes = null;
                }
                answer.riskLevel = answer.riskLevelSlider.value;
            }
            delete answer.showNotesAndRisk;
            delete answer.riskLevelSlider;
            return answer;
        }).filter(answer => answer !== null);
        return body;
    }

    const validateForm = () => {
        let errorMessage = '';
        if ($scope.newReport.date === null || $scope.newReport.date === '') {
            errorMessage = 'Missing report date';
        } else {
            // $scope.newReport.answers.find(answer => {
            //     if (answer.isInitial === '' && answer.answer !== '') {
            //         errorMessage = 'Missing initial';
            //         return true;
            //     } else if (answer.isInitial !== '' && answer.answer === '') {
            //         errorMessage = 'Missing answer';
            //         return true;
            //     }
            //     return false;
            // });
        }
        return errorMessage;
    }

    $scope.onAnswerChange = (answer) => {
        const possibleAnswer = answer.possibleAnswers.find(x => x.text === answer.answer);
        answer.showNotesAndRisk = possibleAnswer.isTriggeringAlert;
    }
});