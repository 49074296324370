"use strict";
app.controller('cityTaxCodeCtrl', function($scope, $uibModalInstance, fields, data, toaster, FormService, cityTaxCodeService){
    $scope.fields = FormService.getFields(fields, data);

    $scope.form = {};

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid) return;

        const newData = FormService.prepareFormData($scope.fields);
        newData.officesIds = newData.offices.map(item => item.id);

        if (data.id) {
            cityTaxCodeService.editCityTaxCode(data.id, newData).then((res) => {
                    toaster.pop('success', "City tax code edited succeffully");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop('error', "Something went wrong", "Could not edit city tax code")
                });
        } else {
            cityTaxCodeService.createCityTaxCode(newData).then((res) => {
                toaster.pop('success', "City tax code created succeffully");
                $scope.closeModal();
            }, (err) => {
                toaster.pop('error', "Something went wrong", "Could not create city tax code")
            });
        }
    };

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

});
