'use strict';
app.controller('linkedPatientSetupModalCtrl', function ($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {
    $scope.selectedLinkedPatient = {};
    var qs = 'limit=10' + '&offset=0&general_filter=';

    $scope.linkedPatientAutoCompleteOptions = {
        minimumChars: 1,
        dropdownWidth: '508px',
        data: function (searchText) {

            return DatabaseApi.get('agencies/' + $rootScope.agencyId + '/patients_search?' + qs + searchText).then(function (response) {
                return response.data.patients.filter(function(_patient) {
                    return _patient.id !== $scope.patient.id
                });
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not get patients");
            });
        },
        renderItem: function (item) {
            var patientText = [item.firstName, item.middleName, item.lastName].filter(Boolean).join(" ");
            return {
                value: patientText,
                label: "<p class='auto-complete'>" + patientText + ' - ' + item.address + "</p>"
            };
        },
        itemSelected: function (e) {
            $scope.selectedLinkedPatient = e.item;
            $scope.selectedLinkedPatient.fullName = [e.item.firstName, e.item.middleName, e.item.lastName].filter(Boolean).join(" ");
        }

    }

    $scope.submitLinkedPatient = function (isMutual) {

        var linkedPatientCreateEditParams = {
            id: Number($scope.patient.id),
            mutualLink: isMutual,
            linkedWith: $scope.selectedLinkedPatient.id
        }

        DatabaseApi.put('agencies/' + $rootScope.agencyId + "/patients/" + $scope.patient.id + "/linked_patients" , linkedPatientCreateEditParams)
            .then(function (res) {
                toaster.pop('success', "Patient was linked successfully");
                $scope.patient.linkedPatient = res.data;
                $scope.closeModal();
            }, function (err) {
                if(err.status === 409) {
                    toaster.pop('error', "Patient link problem", "Selected patient is already linked");
                } else {
                    toaster.pop('error', "Something went wrong", "Could not set patient link");
                }
            });
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };
});
