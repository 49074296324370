angular
  .module("dashboard")
  .factory(
    "BoostTrainingCenterModalService",
    ($rootScope, $uibModal, Consts, Analytics, wildcard) => ({
      open({ caregiverIds }) {
        // Step 1 - calculate price per sms
        const PRICE_PER_SMS = Math.ceil(
          Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT
        );

        // Step 2 - trigger an event
        Analytics.event("Boost clicked", { type: "TRAINING_BOOST" });

        // Step 3 - open the modal
        const modalInstance = $uibModal.open({
          templateUrl: "admin/views/boost-modal.html",
          size: "lg",
          controller: "boostModalCtrl",
          resolve: {
            textAmountToBroadcast: () => caregiverIds.length,
            priceOverallCents: () => caregiverIds.length * PRICE_PER_SMS,
            type: () => "TRAINING_BOOST",
          },
          windowTopClass: "boost-modal",
        });

        modalInstance.result.then((res) => {
          if (res === "BOOST" && caregiverIds.length) {
            const { agencyId, agencyMemberId } = $rootScope;
            
            const url = wildcard(
              "agency/:agencyId/:agencyMemberId/boost",
              agencyId,
              agencyMemberId
            );

            const params = {
              caregiverIds: caregiverIds,
              price: PRICE_PER_SMS,
              type: "TRAINING_BOOST",
              visit: null,
            };

            DatabaseApi.post(url, params)
              .then(() => {
                Analytics.event("Boost done", {
                  type: "TRAINING_BOOST",
                  boostSentAmount: caregiverIds.length,
                  pricePerSms: PRICE_PER_SMS,
                });

                toaster.pop(
                  "success",
                  "Boosted successfully",
                  "Allow 10 minutes for sms to be sent"
                );
              })
              .catch(function (err) {
                toaster.pop(
                  "error",
                  "Boost failed",
                  err.data.error ? err.data.error : "Please try again."
                );
              });
          }
        });
      },
    })
  );
