angular
  .module("dashboard")
  .factory(
    "TrainingCenterBundleService",
    ($rootScope, DatabaseApi, officesService, wildcard) => ({
      /**
       * Get agency's bundles
       */
      get() {
        const { agencyId } = $rootScope;
        const endpoint = wildcard(
          "agencies/:agencyId/training_center/all_bundles_summary",
          agencyId
        );

        return DatabaseApi.get(endpoint);
      },

      /**
       * Create a new bundle with default values
       */
      async createBundleWithDefaults() {
        const { agencyId, agencyMemberId } = $rootScope;

        const offices = await officesService.getOffices();

        const endpoint = wildcard(
          "agencies/:agencyId/training_center/create_new_bundle",
          agencyId
        );

        const params = {
          chatAgencyMemberId: agencyMemberId,
          signingAgencyMemberId: agencyMemberId,
          signingAgencyMemberTitle: "Agency Member",
          enforceWatchOrder: false,
          requiredForBillingCompliance: false,
          canWatchDuringVisitShift: false,
          showBundleAfterDueDate: false,
          caregiverStatuses: [],
          caregiverCertifications: offices.map((office) => ({
            officeId: office.id,
            certifications: [],
          })),
          displayData: [],
          dueDates: [],
          payroll: {
            isOvertime: false,
            durationInSeconds: 0,
            paycodePerCertificationMap: {},
          },
        };

        return DatabaseApi.post(endpoint, params);
      },
    })
  );
