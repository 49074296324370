'use strict'
app.controller("caregiversComplianceCtrl", function (
    $scope,
    $rootScope,
    $filter,
    NgTableParams,
    Consts,
    $http,
    DatabaseApi,
    toaster,
    wildcard,
    $uibModal,
    entityNotesModalService,
    $timeout,
    $window,
    Storage,
    complianceService,
    complianceConsts,
) {
    const fieldTypesMap = complianceConsts.fieldTypesMap;
    const ngTableOptions = {
        count: 25,
        sorting: { timeStamp: "desc" }
    };

    const localStorageKeyPrefix = "caregiversComplianceTableSettings2:";

    const defaultColumns = {
        "caregiver": {
            "Caregiver Name": true,
            "Caregiver ID": true,
            "Status": true,
            "Compliance Status": true,
            "Days to expire": true,
            "Languages": false,
            "Certifications": true,
            "Installation": false,
            "Last Seen": false,
            "Address": false,
            "Offices": false,
            "Phone": false,
            "Actions": true,
        },
        "items": {
            "Caregiver Name": true,
            "Caregiver ID": true,
            "Document Name": true,
            "Status": true,
            "Caregiver Status": true,
            "Certifications": true,
            "Days to expire": true,
            "Uploaded at": true,
            "Uploaded by": true,
            "Expiration date": true,
            "Last Notification": true,
            "Reminder": true,
        },
    };

    const itemFields = {};

    let caregiversMap = DatabaseApi.caregivers() || {};

    let filterModalInstance;

    let documentTypes;

    $scope.complianceGlobalFilter = { val: "" };

    const emptyFilters = {
        certification: [],
        status: [],
        offices: [],
        expiryDate: { startDate: null, endDate: null },
        issue: [],
        showMissingItems: true,
        itemStatus: [],
        complianceStatus: [],
        showExpiredItems: false
    };

    $scope.filterComplianceBy = {
        certification: [],
        status: [{ id: 1 }],
        offices: [],
        expiryDate: { startDate: moment(), endDate: moment().add(29, "days") },
        issue: [],
        showMissingItems: true,
        itemStatus: [],
        complianceStatus: [],
        showExpiredItems: true
    };

    $scope.complianceCache = null;

    $scope.issues = [];

    $scope.tableColumns = {};

    $scope.activeTab = "items";

    $scope.activeFilters = [];

    let previousServerFiltersKeyVal = {};

    initTableColumns();

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];

    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.statuses = [
        { id: 1, label: "Active", value: "ACTIVE", text: "Active", statusClass: "green" },
        { id: 2, label: "On Hold", value: "ON_HOLD", text: "On Hold", statusClass: "yellow" },
        { id: 3, label: "On Leave", value: "ON_LEAVE", text: "On Leave", statusClass: "orange" },
        { id: 4, label: "Pending Application", value: "PENDING", text: "Pending Application", statusClass: "lightblue" },
        { id: 5, label: "Inactive", value: "SUSPENDED", text: "Inactive", statusClass: "azur" },
        { id: 6, label: "Terminated", value: "TERMINATED", text: "Terminated", statusClass: "red" },
        { id: 7, label: "Quit", value: "QUIT", text: "Quit", statusClass: "azur" }
    ];

    $scope.itemStatuses = [
        { id: 1, label: "Compliant", statusClass: "green" },
        { id: 2, label: "Missing", statusClass: "orange" },
        { id: 3, label: "Not Compliant", statusClass: "red" },
        { id: 4, label: "Pending Uploads", statusClass: "blue" },
        { id: 5, label: "Resolved", statusClass: "gray" },
        { id: 6, label: "Not due yet", statusClass: "gray" },
    ];

    $scope.complianceStatuses = [
        { id: 1, label: "Compliant", statusClass: "green" },
        { id: 2, label: "Not Compliant", statusClass: "red" }
    ];

    $scope.officesComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.items = [];

    $scope.isDataLoaded = false;

    const now = moment();

    const compliantStatuses = complianceService.compliantStatuses;

    let documentNameDescendantsMap = new Map();

    let relevantIssues = new Set();

    $scope.dateRangeOptions = {
        ranges: {
            "Next 7 Days": [now, moment().add(6, "days").endOf("day")],
            "Next 14 Days": [now, moment().add(13, "days").endOf("day")],
            "Next 30 Days": [now, moment().add(29, "days").endOf("day")],
            "Next Year": [
                moment().add(1, "year").startOf("year"),
                moment().add(1, "year").endOf("year"),
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Next Month": [
                moment().add(1, "month").startOf("month"),
                moment().add(1, "month").endOf("month"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
        },
        alwaysShowCalendars: true,
        applyClass: "btn-primary",
        locale: {
            direction: "ltr date-range-picker-v2",
            format: "D MMM YY",
        },
        autoApply: true,
        minDate: new Date("2001-01-01"),
        eventHandlers: {
            'apply.daterangepicker': () => {
                updateDateRangePickerDaysText();
            }
        }
    };

    $scope.offices = $scope.$resolve.offices
        .filter(office => office.active)
        .map(office => ({ id: office.id, label: office.name }));

    $scope.baseCols = [
        { title: "Effective Date" },
        { title: "Expiration Date" },
        { title: "View Upload" },
        { title: "Compliant" },
    ];

    $scope.baseColsAppending = [
        { title: "Uploaded At" },
        { title: "Uploaded By" },
        { title: "Actions" },
    ];

    function initTableColumns() {
        initTableColumnsUnit("caregiver");
        initTableColumnsUnit("items");
    }

    function initTableColumnsUnit(tab) {
        $scope.tableColumns[tab] = getColumns(tab);

        $scope.$watch(
            "tableColumns",
            () => onTableColumnsChange(tab),
            true
        );
    }

    function getColumns(tab) {
        const stored = getTableColumnsFromLocalStorage(tab);

        if (!stored) {
            return defaultColumns[tab];
        }

        const newColumns = JSON.parse(JSON.stringify(defaultColumns[tab]));

        for (const key in newColumns) {
            if (stored.hasOwnProperty(key)) {
                newColumns[key] = stored[key];
            }
        }

        return newColumns;
    }

    function getTableColumnsFromLocalStorage(tab) {
        const columns = Storage.getObject(localStorageKeyPrefix + tab);

        if (columns && Object.keys(columns).length) {
            return columns;
        }

        return null;
    }

    function onTableColumnsChange(tab) {
        if ($scope.tableColumns[tab]) {
            Storage.setObject(
                localStorageKeyPrefix + tab,
                $scope.tableColumns[tab]
            );
        }
    }

    $scope.setTab = (tabName) => {
        removeStatusFilter();
        $scope.activeTab = tabName;
    }

    $scope.removeFilter = (filter) => {
        $scope.filterComplianceBy[filter.key] = JSON.parse(JSON.stringify(emptyFilters[filter.key]));
    }

    $scope.clickOpendRow = (close) => {
        if (close) {
            angular.element(document.getElementById("active-row")).remove();
            $scope.activeRow = null;
        } else {
            $rootScope.openCaregiverModal($scope.activeRow.id);
        }
    };

    $scope.$watch('filterComplianceBy', function () {
        $scope.clickOpendRow(true);
        filterTables();
    }, true);

    $scope.applyComplianceGlobalSearch = function (term) {
        const filter = { $: term };

        if ($scope.caregiverIncomplianceTable) {
            angular.extend($scope.caregiverIncomplianceTable.filter(), filter);
        }

        if ($scope.caregiverIncomplianceTable) {
            angular.extend($scope.itemsTable.filter(), filter);
        }
    };

    function initComplianceTable() {
        $scope.caregiverIncomplianceTable = new NgTableParams(ngTableOptions, {
            counts: [],
            dataset: $scope.caregiverData,
            getData: (params) => {
                if (!$scope.caregiverData) {
                    return [];
                }

                const sorting = params.sorting();

                if (Object.keys(sorting).length > 0) {
                    const [column, order] = Object.entries(sorting)[0];

                    $scope.caregiverData.sort((a, b) => {
                        const compare = ('' + a[column]).localeCompare(b[column]);
                        return order === 'desc' ? compare * -1 : compare;
                    });
                }

                let items = $scope.caregiverData;

                if (params.filter()['$']) {
                    const q = params.filter()["$"].toLowerCase();
                    const num = parseInt(q, 10);

                    items = $scope.caregiverData.filter(item => {
                        return item.displayNameSearch.includes(q) ||
                            item.caregiver === num ||
                            item.displayId === num ||
                            item.caregiverCode === q;
                    });
                }

                params.total(items.length);

                return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
            },
        });
    }

    function applyComplianceItemsSearchFilter(items, query, columnsToSearch) {
        return items.filter(item => {
            return columnsToSearch.some(column => item[column] && item[column].toLowerCase().includes(query.toLowerCase()));
        });
    }

    function sortNumeric(a, b) {
        if (b < a) {
            return -1;
        }
        if (b > a) {
            return 1;
        }
        return 0;
    }

    function initItemsTable() {
        const options = {
            count: 25
        };

        const columnsToSearch = ['name', 'displayName', 'caregiverCode', 'uploadedBy'];
        $scope.itemsTable = new NgTableParams(options, {
            counts: [],
            dataset: $scope.itemsData,
            getData: function (params) {
                if (!$scope.itemsData) {
                    return [];
                }

                const sorting = params.sorting();

                if (Object.keys(sorting).length > 0) {
                    const [column, order] = Object.entries(sorting)[0];
                    $scope.itemsData.sort((a, b) => {
                        const aVal = column.split(".").reduce((prev, curr) => prev && prev[curr], a);
                        const bVal = column.split(".").reduce((prev, curr) => prev && prev[curr], b);
                        const numeric = !isNaN(aVal) && !isNaN(bVal);
                        const compare = numeric ? sortNumeric(aVal, bVal) : ("" + aVal).localeCompare(bVal);
                        return order === "desc" ? compare * -1 : compare;
                    });
                }

                const items = params.filter()['$']
                    ? applyComplianceItemsSearchFilter($scope.itemsData, params.filter()['$'], columnsToSearch)
                    : $scope.itemsData;

                params.total(items.length);

                return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
            }
        });
    }

    const complianceFilterByMethods = {
        hasCertification: function (compliance, selectedCertifications) {
            if (!compliance.certifications) return false;
            return compliance.certifications.some(c => selectedCertifications.includes(c));
        },
        hasStatus: function (compliance, selectedStatuses) { return selectedStatuses.indexOf(compliance.status) !== -1; },
        hasItemStatus: function (compliance, selectedItemStatuses) { return selectedItemStatuses.indexOf(compliance.itemStatus) !== -1; },
        hasComplianceStatus: function (compliance, selectedComplianceStatuses) { return selectedComplianceStatuses.indexOf(compliance.complianceStatus) !== -1; },
        HasOffices: function (caregiver, offices) { return caregiver.officeIds.find(function (office) { return offices.indexOf(office) !== -1; }) !== undefined; },
        hasExpiryDate: function (compliance, startDate, endDate) {
            const incompliances = compliance.Incompliances ? compliance.Incompliances : [compliance.incompliance];
            return incompliances.find((incompliance) => {
                if (!incompliance.expiryDate) return false;
                const expiryDateMoment = moment(incompliance.expiryDate);
                return expiryDateMoment.isBetween(startDate, endDate) ||
                    ($scope.filterComplianceBy.showExpiredItems && expiryDateMoment.isBefore(now));
            }) !== undefined;
        },
        hasIssue: function (compliance, _) {
            const incompliances = compliance.Incompliances
                ? compliance.Incompliances
                : [compliance.incompliance];
                
            return incompliances.find((incompliance) => relevantIssues.has(incompliance.name)) !== undefined;
        }
    };

    function filterTables() {
        const serverSideFiltersChanged = !angular.equals(
            previousServerFiltersKeyVal,
            getServerFiltersKeyVal()
        );

        if (serverSideFiltersChanged) {
            loadCaregiversData();
        } else {
            setRelevantIssues()

            $scope.caregiverData = filterTable($scope.complianceCache, serverSideFiltersChanged);
            $scope.itemsData = filterTable($scope.items, serverSideFiltersChanged);
    
            $scope.activeRow = $scope.activeRow
                ? $scope.caregiverData.find(row => row.caregiver === $scope.activeRow.caregiver)
                : null;
    
            initComplianceTable();
            initItemsTable();
    
            if ($scope.activeRow) {
                initActiveIssueTable($scope.activeRow);
            }
    
            if ($scope.complianceGlobalFilter.val) {
                $scope.applyComplianceGlobalSearch($scope.complianceGlobalFilter.val);
            }
        }
    }

    const filters = [
        // Certifications
        {
            key: "certification",
            method: complianceFilterByMethods.hasCertification,
            values: () => $scope.filterComplianceBy.certification.map((obj) => $scope.certifications.find((cert) => cert.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Certifications",
            isClientFilter: true,
        },

        // Statuses
        {
            key: "status",
            method: complianceFilterByMethods.hasStatus,
            values: () => $scope.filterComplianceBy.status.map((obj) => $scope.statuses.find((stat) => stat.id === obj.id).value),
            operator: "isNotEmpty",
            chipLabel: (values) => values.map(status => $scope.getStatusByValue(status).text).join(", "),
            title: "Statuses",
            isClientFilter: true,
        },

        // Item Statuses
        {
            key: "itemStatus",
            method: complianceFilterByMethods.hasItemStatus,
            values: () => $scope.filterComplianceBy.itemStatus.map((obj) => $scope.itemStatuses.find((stat) => stat.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Item Statuses",
            isClientFilter: true,
        },

        // Compliance Statuses
        {
            key: "complianceStatus",
            method: complianceFilterByMethods.hasComplianceStatus,
            values: () => $scope.filterComplianceBy.complianceStatus.map((obj) => $scope.complianceStatuses.find((stat) => stat.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Compliance Statuses",
            isClientFilter: true,
        },

        // Offices
        {
            key: "offices",
            method: complianceFilterByMethods.HasOffices,
            values: () => $scope.filterComplianceBy.offices.map((office) => office.id),
            operator: "isNotEmpty",
            chipLabel: (values) => $scope.showCaregiverOffices(values),
            title: "Offices",
            isClientFilter: true,
        },

        // Time Period
        {
            key: "expiryDate",
            method: (compliance, selectedExpiryDate) => complianceFilterByMethods.hasExpiryDate(compliance, selectedExpiryDate.startDate, selectedExpiryDate.endDate),
            values: () => !$scope.isDataLoaded && previousServerFiltersKeyVal.expiryDate ? previousServerFiltersKeyVal.expiryDate : $scope.filterComplianceBy.expiryDate,
            operator: "hasStartAndEndDate",
            chipLabel: (values) => "From " + $filter("mfShortDate")(new Date(values.startDate)) + " To " + $filter("mfShortDate")(new Date(values.endDate)),
            title: "Time Period",
            isClientFilter: false,
        },

        // Issues
        {
            key: "issue",
            method: complianceFilterByMethods.hasIssue,
            values: () => getSelectedIssues(),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Issues",
            isClientFilter: true,
        },

        // Missing Items
        {
            key: "showMissingItems",
            method: (compliance, _) => compliance.hasNotMissing === true,
            values: () => $scope.filterComplianceBy.showMissingItems,
            operator: "isFalse",
            chipLabel: (_) => "No missing items",
            title: "",
            isClientFilter: true,
        },

        // Expired Items
        {
            key: "showExpiredItems",
            method: (compliance, _) => true,
            values: () => $scope.filterComplianceBy.showExpiredItems,
            operator: "isTrue",
            chipLabel: (_) => "Include items with issues",
            title: "",
            isClientFilter: false,
        },
    ];

    function isActiveFilter(filter) {
        switch (filter.operator) {
            case "isNotEmpty":
                return filter.values().length > 0;

            case "hasStartAndEndDate":
                return filter.values().startDate && filter.values().endDate;

            case "isFalse":
                return filter.values() === false;

            case "isTrue":
                return filter.values() === true;

            default:
                console.error(`Missing case for operator "${filter.operator}" in isActiveFilter() method`);
        }
    }

    function filterTable(data) {
        if (data === null) return;

        return filterClientSide(data);
    }

    function setActiveFilters() {
        $scope.activeFilters = filters.filter(isActiveFilter);
    }

    function removeStatusFilter() {
        const key = $scope.activeTab === "caregiver" ? "complianceStatus" : "itemStatus";

        const filter = $scope.activeFilters.find(filter => filter.key === key);

        if (filter) {
            $scope.removeFilter(filter);
            setActiveFilters();
        }
    }

    function getServerFiltersKeyVal() {
        setActiveFilters();

        return Object.fromEntries(
            $scope.activeFilters
                .filter(f => !f.isClientFilter)
                .map(f => [f.key, f.values()])
        );
    }

    function filterClientSide(data) {
        let filteredCompliance = data;

        const activeClientFilters =  $scope.activeFilters.filter(f => f.isClientFilter);

        if (activeClientFilters.length > 0) {
            filteredCompliance = filteredCompliance.filter((compliance) => {
                let isFiltered = true;

                for (const activeFilter of activeClientFilters) {
                    if (!isFiltered) break;

                    isFiltered = isFiltered && activeFilter.method(compliance, activeFilter.values());
                }

                return isFiltered;
            });
        }

        return filteredCompliance;
    }

    $scope.openFilterModal = () => {
        filterModalInstance = $uibModal.open({
            templateUrl: 'admin/views/caregivers-compliance-filter-modal.html',
            size: 'md',
            scope: $scope,
            resolve: {},
            backdrop: true,
            backdropClass: 'transparent-backdrop',
            windowClass: "modal modal-slide-in-right uib-side-modal"
        });

        return filterModalInstance;
    }

    $scope.closeModal = () => {
        filterModalInstance && filterModalInstance.close();
    };

    $scope.caregiversIncompliancesGet = (activeRow) => {
        const params = new URLSearchParams(Object.fromEntries(
            [
                ["from", $scope.filterComplianceBy.expiryDate.startDate ? $scope.filterComplianceBy.expiryDate.startDate.format('YYYY-MM-DD') : null],
                ["to", $scope.filterComplianceBy.expiryDate.endDate ? $scope.filterComplianceBy.expiryDate.endDate.format('YYYY-MM-DD') : null],
                ["showExpiredItemsRegardlessDateFilter", $scope.filterComplianceBy.showExpiredItems]
            ].filter(param => param[1] !== null)
        )).toString();

        const queryParamsStr = params.length > 0 ? `?${params}` : ``;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/incompliances${queryParamsStr}`
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);

        $scope.isDataLoaded = false;

        DatabaseApi.get(url).then((response) => {
            $scope.isDataLoaded = true;

            if (!response.data.caregiversIncompliances || !response.data.caregiversIncompliances.length) {
                return;
            }

            $scope.caregiverData = [];
            $scope.itemsData = [];
            $scope.issues = [];
            $scope.items = [];
            $scope.activeRowIncompliances = [];

            transformResponse(response);

            setDocuments(response.data.documents);

            $scope.complianceCache = response.data.caregiversIncompliances;

            previousServerFiltersKeyVal = getServerFiltersKeyVal();

            filterTables();

            if (activeRow) {
                getComplianceItemRecordsInfo(activeRow, true);
            }
        },
            (_) => {
                $scope.isDataLoaded = true;
                toaster.pop("error", "Something went wrong", "could not load compliance data");
            }
        );
    };

    function transformIssues(issues) {
        return issues.map((issue, index) => {
            return { id: index + 1, label: issue };
        });
    }

    function setIsClickableComplianceItem(incompliance) {
        return incompliance.groupType === null;
    }

    function transformResponse(response) {
        response.data.caregiversIncompliances.forEach((row, i) => {
            const caregiver = caregiversMap[row.caregiver.toString()];

            response.data.caregiversIncompliances[i] = angular.extend(
                response.data.caregiversIncompliances[i],
                caregiver
            );

            if (row.displayName) {
                row.displayNameSearch = row.displayName.toLowerCase();
            }

            let issuesAmount = 0;
            let minDaysToExpire = NaN;
            let minDaysIsForDueDate = false;

            complianceService.setFollowupDocumentsComplianceTracking(row.Incompliances);
            const rowGroupedComplianceItems = complianceService.buildGroupItemsTree(row.Incompliances);
            const hasNonGroupDescendantSet = complianceService.getHasNonGroupDescendantSet(rowGroupedComplianceItems);
            const flattenedItems = complianceService.flattenGroupDocuments(rowGroupedComplianceItems);
            const flattendItemsMap = new Map(flattenedItems.map(obj => [obj.caregiverDocumentTypeId, obj]));
            const caregiverStatus = $scope.getStatusByValue(caregiver.status);

            row.Incompliances = row.Incompliances.map((incompliance) => {

                if (!compliantStatuses.includes(incompliance.status)) {
                    issuesAmount++;
                }

                const expiryParams = complianceService.getExpiryOrDueDateParams(
                    incompliance.status,
                    incompliance.dueDate,
                    incompliance.expiryDate,
                    incompliance.requireReVerification
                );

                Object.assign(incompliance, expiryParams.data);

                incompliance.uploadedBy = $rootScope.getCAgencyMemberName(incompliance.uploadedBy);

                incompliance.notificationsTable = new NgTableParams(ngTableOptions, {
                    counts: [],
                    dataset: incompliance.notifications
                });

                incompliance.lastNotificationDate = getLastNotificationDate(incompliance.notifications);
                incompliance.caregiverId = row.caregiver;

                if (belongsToItemsView(incompliance, hasNonGroupDescendantSet)) {
                    // WE WANT TO SET A GROUP TREE FOR GROUP TYPE ITEMS, AND FLATTEN TO GET CHILDREN PARENTS
                    // FOR GROUP ITEM MODAL
                    if (incompliance.groupType) {
                        const itemGroupTree = flattendItemsMap.get(incompliance.caregiverDocumentTypeId);
                        if (itemGroupTree) {
                            incompliance.children = itemGroupTree.children;
                        }
                    }

                    incompliance.parentTrail = incompliance.parentCaregiverDocumentTypeId
                            ? $scope.getParentsTrail(flattendItemsMap, incompliance.parentCaregiverDocumentTypeId)
                            : null;

                    $scope.items.push({
                        ...caregiversMap[row.caregiver.toString()],
                        incompliance: angular.copy(incompliance),
                        itemStatus: incompliance.status,
                        displayNameSearch: row.displayNameSearch || "",
                        caregiverId: row.caregiver,
                        caregiverDocumentTypeId: incompliance.caregiverDocumentTypeId,
                        uniqueId: `${row.caregiver}-${incompliance.caregiverDocumentTypeId}`,
                        isClickableComplianceItem: setIsClickableComplianceItem(incompliance),
                        name: incompliance.name,
                        caregiverNextStatus: row.caregiverNextStatus,
                        caregiverNextStatusText: row.caregiverNextStatus ? $scope.getStatusByValue(row.caregiverNextStatus.status).text : null,
                        statusText: caregiverStatus.text,
                        statusColor: caregiverStatus.statusClass
                    });
                }

                if (!isNaN(incompliance.daysToExpire) && !expiryParams.data.missingHireDate && !expiryParams.discardExpiry && incompliance.showDaysToExpire) {
                    if (incompliance.daysToExpire < minDaysToExpire || isNaN(minDaysToExpire)) {
                        minDaysIsForDueDate = expiryParams.isUsingDueDate;
                    }
                    
                    minDaysToExpire = isNaN(minDaysToExpire)
                        ? incompliance.daysToExpire
                        : Math.min(incompliance.daysToExpire, minDaysToExpire);
                }

                return incompliance;
            });


            row.caregiverNextStatusText = row.caregiverNextStatus ? $scope.getStatusByValue(row.caregiverNextStatus.status).text : null;
            row.statusText = caregiverStatus.text;
            row.statusColor = caregiverStatus.statusClass;
            row.Incompliances = rowGroupedComplianceItems;
            row.issuesAmount = issuesAmount;
            row.complianceStatus = issuesAmount === 0 ? "Compliant" : "Not Compliant";
            row.confirmed = calcConfirmed(row.appInstalled, row.appInstalledDate);
            row.strOffices = $scope.showCaregiverOffices(row.officeIds);
            row.minDaysToExpire = minDaysToExpire;
            row.minDaysToExpireText = complianceService.calculateDaysElapsed(
                minDaysToExpire,
                minDaysIsForDueDate ? "Due" : "Expires",
                minDaysIsForDueDate ? "Was due" : "Expired",
            );
            row.isValidMinExpiry = !isNaN(minDaysToExpire) && minDaysToExpire >= 0;
            row.minDaysToExpireSort = isNaN(row.minDaysToExpire) ? 'Ω' : row.minDaysToExpire; // Sorting with null values is inconsistent
            row.shouldDisplayDaysToExpire = shouldDisplayDaysToExpire(row);
        });
    }

    function belongsToItemsView(item, hasNonGroupDescendantSet) {
                // Show resolved only if it has records
        return (item.status !== "Resolved" || item.latestInstanceId) &&

                // Show missing only if it's a root level item
               (item.status !== "Missing" || !item.parentCaregiverDocumentTypeId) &&

               // Show group items only if they are root level and they don't have descendants that aren't group items 
               (!item.groupType || (!item.parentCaregiverDocumentTypeId && !hasNonGroupDescendantSet.has(item.caregiverDocumentTypeId)));
    }

    function calcConfirmed(isAppInstalled, appInstalledDate) {
        if (isAppInstalled) {
            return "installed";
        }
        
        if (appInstalledDate) {
            return "uninstalled";
        }

        return "notinstalled";
    }

    function getLastNotificationDate(notifications) {
        notifications.sort((a, b) => new Date(b.sentAt) - new Date(a.sentAt));

        return notifications.length > 0 ? notifications[0].sentAt : null;
    }

    $scope.exportComplianceTable = (withFields) => {
        const tableData = $filter('filter')(
            $scope.activeTab === "items" ? $scope.itemsData : $scope.caregiverData,
            $scope.complianceGlobalFilter.val
        );

        const fieldsColumns = withFields ? getExportFieldsColumns(tableData) : [];

        const columnTitles = getColumnsToExport(fieldsColumns);

        const rows = [columnTitles.map(transformColumnNameForExport)];

        pushTableDataToExportRows(tableData, rows, columnTitles, fieldsColumns);

        if (rows.length === 0) {
            return;
        }

        const blob = createBlob(rows);

        serveCsvFile(blob);
    };

    function transformColumnNameForExport(columnName) {
        if (columnName === "Days to expire") {
            return "Days to expire/due";
        }

        if (columnName === "Expiration date") {
            return "Expiry/Due date";
        }

        return columnName;
    }

    function getColumnsToExport(fieldsColumns) {
        const exludedTitles = ["Photo", "Actions", "Reminder", "View", "Add"];

        const titles = [];

        for (const key in $scope.tableColumns[$scope.activeTab]) {
            if (exludedTitles.includes(key)) {
                continue;
            }

            if ($scope.tableColumns[$scope.activeTab][key]) {
                titles.push(key);
            }
        }

        if ($scope.activeTab === "caregiver") {
            titles.push("Total Issues");
        } else {
            titles.push("Effective Date");
            for (const fieldColumn of fieldsColumns) {
                titles.push(fieldColumn.columnName);
            }
        }

        return titles;
    }

    function getExportFieldsColumns(tableData) {
        const filteredDocumentTypes = new Set(
            tableData.map(r => r.incompliance.documentTypeId)
        );

        const returnFields = [];

        if ($scope.activeTab === "items") {
            for (let [documentTypeId, fields] of Object.entries(itemFields)) {
                documentTypeId = Number(documentTypeId);

                if (filteredDocumentTypes.has(documentTypeId)) {
                    const doc = documentTypes.find(d => d.id === documentTypeId);

                    for (const field of fields) {
                        returnFields.push({
                            id: field.id,
                            columnName: `${doc.name} - ${field.name}`,
                            documentTypeId: documentTypeId,
                        });
                    }
                }
            }
        }

        return returnFields;
    }

    function pushTableDataToExportRows(tableData, rows, columnTitles, fieldsColumns) {
        for (const caregiver of tableData) {
            const exportMap = buildExportCellsMap(caregiver, fieldsColumns);

            const rowCells = columnTitles.map(columnTitle => buildExportCellContent(exportMap, columnTitle));

            rows.push(rowCells);
        }
    }

    function buildExportCellsMap(caregiver, fieldsColumns) {
        const map = {
            "caregiver": () => {
                return {
                    "Caregiver Name": caregiver.displayName,
                    "Caregiver ID": caregiver.displayId || "",
                    "Languages": caregiver.languages.join(', '),
                    "Certifications": caregiver.certifications.join(', '),
                    "Installation": caregiver.confirmed === "installed" ? "Yes" : (caregiver.confirmed === "notinstalled" ? "No" : "Uninstalled"),
                    "Last Seen": caregiver.lastSeen ? moment(caregiver.lastSeen) : "",
                    "Address": caregiver.address,
                    "Offices": caregiver.strOffices,
                    "Phone": caregiver.phoneNumber,
                    "Status": $scope.getStatusByValue(caregiver.status).text,
                    "Compliance Status": caregiver.complianceStatus,
                    "Days to expire": isNaN(caregiver.minDaysToExpire) ? "" : caregiver.minDaysToExpire,
                    "Total Issues": caregiver.Incompliances.length,
                }
            },
            "items": () => {
                const cols = {
                    "Caregiver Name": caregiver.displayName,
                    "Caregiver ID": caregiver.displayId || "",
                    "Caregiver Status": caregiver.status,
                    "Certifications": caregiver.certifications.join(", "),
                    "Document Name": caregiver.incompliance.name,
                    "Status": caregiver.incompliance.status,
                    "Uploaded at": caregiver.incompliance.uploadedAt ? $filter("mfShortDate")(new Date(caregiver.incompliance.uploadedAt)) : "",
                    "Uploaded by": caregiver.incompliance.uploadedBy,
                    "Expiration date": caregiver.incompliance.expiryDate ? $filter("mfShortDate")(new Date(caregiver.incompliance.expiryDate)) : "",
                    "Days to expire": isNaN(caregiver.incompliance.daysToExpire) ? "" : caregiver.incompliance.daysToExpire,
                    "Last Notification": caregiver.incompliance.lastNotificationDate ? $filter("mfShortDate")(new Date(caregiver.incompliance.lastNotificationDate)) : "",
                    "Effective Date": caregiver.incompliance.effectiveDate ? $filter("mfShortDate")(new Date(caregiver.incompliance.effectiveDate)) : "",
                };

                for (const field of fieldsColumns) {
                    cols[field.columnName] = getFieldColumnValue(caregiver.incompliance, field);
                }

                return cols;
            },
        };

        return map[$scope.activeTab]();
    }

    function getFieldColumnValue(incompliance, field) {
        if (field.documentTypeId === incompliance.documentTypeId) {
            const instanceField = incompliance.fields?.find(f => f.id === field.id);

            if (instanceField && instanceField.value) {
                return typeof instanceField.value === "string"
                    ? instanceField.value
                    : instanceField.value.text;
            }
        }

        return "";
    }

    function buildExportCellContent(exportMap, columnTitle) {
        if (!exportMap.hasOwnProperty(columnTitle)) {
            console.error(`"${columnTitle}" is missing from the map returned at buildExportCellsMap()`);

            return "";
        }

        const data = exportMap[columnTitle];

        const shouldAddQuotes = isNaN(data) || columnTitle === "Phone";

        return shouldAddQuotes ? `"${data}"` : data;
    }

    function createBlob(rows) {
        let csvContent = "";

        rows.forEach((rowArray) => {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        return new Blob([csvContent], { type: "text/csv" });
    }

    function serveCsvFile(blob) {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", "medflyt-incompliant-caregivers-export.csv");

        link.click();

        window.URL.revokeObjectURL(url);
    }

    $scope.openImportComplianceModal = () => {
        const progress = { value: 0 };

        const modal = $uibModal.open({
            templateUrl: "admin/views/drag-file-modal.html",
            controller: "dragFileModal",
            resolve: {
                accept: () => ".xls,.csv,.xlsx",
                title: () => "Import Compliance items from Excel",
                onDragFile: () => file => uploadComplianceTable(file),
                progress: () => progress
            }
        });

        const onSuccessUpload = response => {
            const { totalImported, totalSkipped, tableType } = response.data.result;
            toaster.pop(
                "success",
                `${tableType} Imported`,
                `Imported ${totalImported} items, skipped ${totalSkipped} items.`,
                3000
            );
        };

        const onErrorUpload = response => {
            let message = "Something went wrong...";

            if (response.data.type !== undefined) {
                message = response.data.missing;
            }

            toaster.pop("error", `Import failed`, message, 3000);
        };

        const uploadComplianceTable = file => {
            const formData = new FormData();

            formData.append("file", file, file.name);

            $http({
                url: wildcard(
                    `${Consts.api}hr/agencies/:agencyId/compliance_items/import_table`,
                    $rootScope.agencyId
                ),
                method: "POST",
                data: formData,
                headers: { "Content-Type": undefined },
                uploadEventHandlers: {
                    progress: e => (progress.value = (e.loaded / e.total) * 100)
                }
            })
                .then(onSuccessUpload)
                .catch(onErrorUpload)
                .finally(() => modal.dismiss());
        };
    }

    function loadCaregiversData() {
        $scope.caregiversIncompliancesGet();
    }

    updateDateRangePickerDaysText();
    loadCaregiversData();

    $rootScope.$on("caregiver_compliance_item_created", (event, data) => {
        loadCaregiversData();
    });

    $rootScope.$on("got_data", function (event) {
        if (Object.keys(caregiversMap).length > 0) return;
        $scope.gotData = true;
        caregiversMap = DatabaseApi.caregivers() || {};
        loadCaregiversData();
    });

    $rootScope.$on("caregiver_changed", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });

    $rootScope.$on("new_caregiver", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });

    $scope.sendReminderDummy = () => {
        $timeout(function () {
            toaster.pop("success", "Success!", "Message Sent");
        }, 1500);
    };

    $scope.openDummyModal = (type) => {
        $uibModal.open({
            templateUrl: "admin/views/explain-modal.html",
            size: "md",
            controller: "boostModalCtrl",
            resolve: {
                textAmountToBroadcast: function () {
                    return 0;
                },
                priceOverallCents: function () {
                    return 0;
                },
                type: function () {
                    return type;
                }
            }
        });
    };

    $scope.handleNotesModalOpen = (profileId, profileName) => {
        entityNotesModalService.handleNotesModalOpen({ profileId, profileName });
    }

    $scope.getStatusByValue = (statusValue) => {
        return $scope.statuses.find((stat) => stat.value === statusValue);
    }

    $scope.getItemStatusByLabel = (label) => {
        return $scope.itemStatuses.find((status) => status.label === label);
    }

    $scope.showCaregiverOffices = (officeIds) => {
        if (!officeIds) return "";
        let presentedOffices;
        const includeInactiveOffices = $scope.filterComplianceBy.offices.find(office => office.id === -1) !== undefined;
        if (includeInactiveOffices) {
            presentedOffices = officeIds
                .map(officeId => offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.name)
        } else {
            presentedOffices = officeIds
                .map(officeId => $scope.offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.label)
        }

        return presentedOffices.join(", ");
    }

    $scope.handleViewIssueFileClick = (caregiverId, itemId) => {
        const url = `agencies/:agencyId/caregivers/:caregiverId/compliance_instances/:complianceInstanceId/preview`
            .replace(':agencyId', $rootScope.agencyId)
            .replace(':caregiverId', caregiverId)
            .replace(':complianceInstanceId', itemId);

        DatabaseApi.get(url).then((res) => {
            $window.open(res.data.fileUrl);
        }, (_) => {
            toaster.pop('error', 'Something went wrong', 'could not get file');
        });
    }

    $scope.clickComplianceRow = (row) => {
        if ($scope.activeRow && $scope.activeRow.id === row.id) {
            $scope.activeRow = null;
            return;
        }

        if ($scope.activeRowIssue) {
            $scope.activeRowIssue = null;
        }

        initActiveIssueTable(row);
    };

    function initActiveIssueTable(row) {
        $scope.activeRow = row;

        $scope.activeRowIncompliances = row.Incompliances;

        $scope.activeRowIssuesTable = new NgTableParams({
            count: 25,
            sorting: { name: "asc" }
        }, {
            counts: [],
            dataset: $scope.activeRowIncompliances,
            getData: (params) => complianceService.getCaregiverComplianceTrackingActiveRowData(params, $scope.activeRowIncompliances),
        });
    }

    $scope.clickComplianceItemRow = (row, rowIssue) => {
        if ($scope.activeRow && $scope.activeRow.id === row.id && $scope.activeRowIssue && $scope.activeRowIssue.id === rowIssue.id) {
            $scope.activeRowIssue = null;
            return;
        }

        $scope.activeRowIssue = rowIssue;
    }

    $scope.clickOpendRow = (close) => {
        if (close) {
            angular.element(document.getElementById("active-row")).remove();
            $scope.activeRow = null;
        } else {
            $rootScope.openCaregiverModal($scope.activeRow.id);
        }
    };

    // Should be used in "Caregiver" tab only
    function shouldDisplayDaysToExpire(row) {
        if (isNaN(row.minDaysToExpire)) return false;

        // Non compliant (some missing documents) and the non-missing docuemnt
        // With lowest days to expire value is a future date.
        if (row.complianceStatus === "Not Compliant" && row.minDaysToExpire >= 0) return false;

        return true;
    }

    $scope.addNewComplianceItem = (item, type, caregiverDocumentUploadId) => {
        if (item.groupType) {
            openGroupItemModal(item);
            return;
        }
        
        const newScope = $scope.$new();
        const documentType = documentTypes.find(x => x.id === item.caregiverDocumentTypeId);

        newScope.item = item;
        newScope.caregiverId = $scope.activeRow.caregiver;
        newScope.itemId = documentType.id;
        newScope.type = type;
        newScope.fields = itemFields[documentType.id];
        newScope.documentName = item.name;

        if (caregiverDocumentUploadId) {
            newScope.caregiverDocumentUploadId = caregiverDocumentUploadId;
        }

        if (item.defaultExpirySettings) {
            newScope.defaultExpirySettings = item.defaultExpirySettings;
        }

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/caregiver-compliance-instance-modal.html',
            controller: 'caregiverComplianceInstanceModalCtrl',
            size: 'lg',
            scope: newScope,
            windowClass: "compliance-instance-modal"
        });

        modalInstance.result.then((res) => {
            item.id = res.data.id;
            item.status = res.data.isCompliant ? "Compliant" : "Not Compliant";
            item.hasFileAttached = res.data.hasAttachedFile;

            if ( type === 'NEW_RECORD' || type === 'PENDING') {
                getComplianceItemRecordsInfo(item, true);
            }

            updateActiveRowTable();

            filterTables();
        });
    };

    function updateDateRangePickerDaysText() {
        const startDate = $scope.filterComplianceBy.expiryDate.startDate;
        const endDate = $scope.filterComplianceBy.expiryDate.endDate;
        if (!startDate || !endDate) return;
        const diff = Math.abs(startDate.diff(endDate, 'days')) + 1;
        $scope.expiryDateRangeText = `${diff} days`;
    };

    function updateActiveRowTable() {
        if ($scope.activeRow) {
            const updatedRow = $scope.caregiverData.find(r => r.id === $scope.activeRow.id);
            updatedRow && initActiveIssueTable(updatedRow);
        }
    }

    const getAgencyDocumentTypes = function () {
        if (!$rootScope.agencyId || !$rootScope.agencyMemberId) return;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/document_types`
            .replace(':agencyId', $rootScope.agencyId)
            .replace(':agencyMemberId', $rootScope.agencyMemberId);

        DatabaseApi.get(url).then((res) => {
            documentTypes = res.data.documentTypes;

            documentTypes.forEach(type => {
                if (type.complianceData.fields) {
                    itemFields[type.id] = type.complianceData.fields;
                }
            });
        });
    };

    const setComplianceItemRecordsInfo = (row, data) => {
        row.instances = data.instances.filter(i => !i.fictiveType);
        row.caregiverPendingUploads = data.caregiverPendingUploads;
        row.historyStatusChanges = data.historyStatusChanges;

        row.fields = itemFields[row.incompliance.caregiverDocumentTypeId];

        complianceService.setItemFieldsModels(row.fields);
        if (row.instances.length > 0) {
            row.cols = [
                ...$scope.baseCols,
                ...row.fields.map(field => ({ title: field.name })),
                ...$scope.baseColsAppending,
            ];
        }

        const requiredFields = Object.keys(row).filter(field => {
            if (fieldTypesMap[field] && row[field] === true) {
                return field;
            }
        });

        row.modalFields = requiredFields.map(field => {
            const fieldName = field.replace("requires", "");
            return fieldName[0].toLowerCase() + fieldName.substr(1);
        });

        row.instances.forEach(instance => {
            complianceService.setItemFieldsModels(instance.fields);
        });

        row.activeUploadTab = 0;
    };

    const getComplianceItemRecordsInfo = (row, loadAfterAction = false) => {
        if ($scope.activeComplianceItemStatusRow && $scope.activeComplianceItemStatusRow.isLoadingRecords) return;
        if (row.caregiverPendingUploads && !loadAfterAction) {
            $scope.activeComplianceItemStatusRow = row;
            return;
        }

        $scope.activeComplianceItemStatusRow = row;
        $scope.activeComplianceItemStatusRow.isLoadingRecords = true;
        complianceService.getComplianceItemRecordsInfo(row.caregiverId, row.incompliance.caregiverDocumentTypeId)
            .then((res) => {
                setComplianceItemRecordsInfo(row, res.data);
            }).catch((err) => {
                toaster.pop('error', "Failed to get compliance item records info");
            }).finally(() => {
                $scope.activeComplianceItemStatusRow.isLoadingRecords = false;
            });
    };

    const openGroupItemModal = (item) => {
        item.fields = itemFields[item.caregiverDocumentTypeId];
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/caregiver-compliance-group-modal.html',
            controller: 'caregiverComplianceGroupModalCtrl',
            size: 'lg',
            windowClass: 'top-top',
            resolve: {
                parentItem: () => item,
                caregiverId: () => item.caregiverId
            }
        });

        modalInstance.result.then(async (res) => {
            loadCaregiversData();
        }, () => { });
    };

    $scope.clickComplianceItemStatusRow = (row) => {
        if (!row.isClickableComplianceItem) return;
        if ($scope.activeComplianceItemStatusRow && $scope.activeComplianceItemStatusRow.uniqueId === row.uniqueId) {
            $scope.activeComplianceItemStatusRow = null;
            return;
        }
        getComplianceItemRecordsInfo(row);
    };

    $scope.getParentsTrail = (map, docId) => {
        const doc = map.get(docId);

        if (doc?.parentCaregiverDocumentTypeId) {
            return $scope.getParentsTrail(map, doc.parentCaregiverDocumentTypeId) + " » " + doc.name;
        }

        return doc?.name;
    };

    function setDocuments(documents) {
        const documentsMap = new Map();
        documentNameDescendantsMap = new Map();

        for (const document of documents) {
            documentsMap.set(document.caregiverDocumentTypeId, document);
        }

        for (const document of documents) {
            document.children = document.children.map(id => {
                const doc = documentsMap.get(id);

                if (doc) {
                    doc.isChild = true;
                }

                return doc;
            }).filter(doc => Boolean(doc));
        }

        for (const document of documents) {
            const decendantNames = getDocumentDescendants([document]).map(doc => doc.name);

            if (decendantNames) {
                documentNameDescendantsMap.set(document.name, decendantNames);
            }
        }

        $scope.issues = transformIssues(getIssues(documents.filter(d => !d.isChild)));
    }

    function getRelevantIssues(documentNames) {
        const issues = [];

        for (const documentName of documentNames) {
            const children = documentNameDescendantsMap.get(documentName);

            if (children) {
                issues.push(...children);
            }   
        }

        return new Set(issues);
    }

    function getDocumentDescendants(documents) {
        const descendants = [...documents];

        for (const document of documents) {
            if (document.children) {
                descendants.push(
                    ...getDocumentDescendants(document.children)
                );
            }
        }

        return descendants;
    }

    function getSelectedIssues() {
        return $scope.filterComplianceBy.issue.map((obj) => $scope.issues.find((issue) => issue.id === obj.id).label);
    }

    function getIssues(documents) {
        const issues = new Set();

        for (const document of documents) {
            issues.add(document.name);

            if (document.children) {
                getIssues(document.children).forEach(issue => issues.add(issue));
            }
        }

        return Array.from(issues);
    }

    function setRelevantIssues() {
        relevantIssues = getRelevantIssues(getSelectedIssues());

        $scope.filterComplianceBy.issue = [...relevantIssues].map((issueName) => $scope.issues.find((issue) => issue.label === issueName));
    }

    getAgencyDocumentTypes();
});