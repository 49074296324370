"use strict";

angular
  .module("dashboard")
  .controller(
    "acceptBulkVisitIInstancesChangesCtrl",
    ($scope, $filter, DatabaseApi, PatientModalService) => {
      $scope.total = $scope.$resolve.total;
      $scope.changes = $scope.$resolve.changes;
      $scope.onApprove = $scope.$resolve.onApprove;
      $scope.onReject = $scope.$resolve.onReject;
      $scope.patientId = $scope.$resolve.patientId;
      $scope.caregiversMap = DatabaseApi.caregivers();

      $scope.updatedFields = Object.keys($scope.changes);

      $scope.serviceCodes = DatabaseApi.activeServiceCodes().reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );

      $scope.payrollCodes = DatabaseApi.activePayrollCodes().reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );

      PatientModalService.getPatientContractBasic($scope.patientId).then(contracts => {
        $scope.contractsOptions = $filter("onlyActivePatientContracts")(
            contracts,
            false
        ).map(contract => ({
            ...contract,
            label: $filter("formatPatientContract")(contract)
        }));

        initFieldMap();
      });

      const initFieldMap = () => {
        $scope.fieldMap = {
          caregiverId: {
            label: "Caregiver",
            predicate: (v) => {
              if (v === null) {
                  return `✖ Unassigned caregiver`;
              }
  
              const caregiver = $scope.caregiversMap[v];
  
              return `${caregiver.firstName} ${caregiver.lastName}`;
            },
          },
          address: {
            label: "Address",
            predicate: ({ address }) => address,
          },
          address2: {
            label: "Address 2",
            predicate: (v) => v,
          },
          billingAdjustmentMinutes: {
            label: "Billing Adjustment (Minutes)",
            predicate: (v) => `${v}min`,
          },
          clockDistanceApproved: {
            label: "Clock in/out distance approved",
            predicate: (v) => (v ? "✔️" : "❌"),
          },
          manualClockTimeEditApproved: {
            label: "Manual clock times edit approved",
            predicate: (v) => (v ? "✔️" : "❌"),
          },
          isOnPreviousDay: {
            label: "Bill on previous day",
            predicate: (v) => (v ? "✔️" : "❌"),
          },
          missedVisit: {
            label: "Missed visit",
            predicate: (v) => (v ? "✔️" : "❌"),
          },
          startTime: {
            label: "Start Time",
            predicate: (v) => $filter("mfShortTime")(v),
          },
          endTime: {
            label: "End Time",
            predicate: (v) => $filter("mfShortTime")(v),
          },
          clockinTime: {
            label: "Clock In",
            predicate: (v) => $filter("mfShortTime")(v),
          },
          clockoutTime: {
            label: "Clock Out",
            predicate: (v) => $filter("mfShortTime")(v),
          },
          payrollAdjustmentMinutes: {
            label: "Payroll Adjustment (Minutes)",
            predicate: (v) => `${v}min`,
          },
          travelTimeMinutes: {
            label: "Travel Time (Minutes)",
            predicate: (v) => `${v}min`,
          },
          manualHoldInvoicing: {
            label: "Manual Hold Invoicing",
            predicate: (v) => (v ? "✔️" : "❌")
          },
          manualHoldInvoicingNotes: {
            label: "Manual Hold Invoicing Notes",
            predicate: (v) => v
          },
          patientContractId: {
            label: "Contract",
            predicate: (v) => {
              const contractType = $scope.contractsOptions.find(contract => contract.id === v);
              if (contractType) {
                return contractType.contractTypeName;
              }
              return "❌";
            }
          },
          serviceCodeId: {
            label: "Service Code",
            predicate: (v) => {
              const serviceCode = $scope.serviceCodes[v];
              if (serviceCode) {
                return serviceCode.code;
              }
              return "❌";
            }
          },
          payrollCodeId: {
            label: "Payroll Code",
            predicate: (v) => {
              const payrollCode = $scope.payrollCodes[v];
              if (payrollCode) {
                return payrollCode.displayId;
              }
              return "❌";
            }
          }
        };
      };
    }
  );
