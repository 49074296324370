'use strict'
app.controller("caregiversPendingApplicationCtrl", function (
    $scope,
    $rootScope,
    $filter,
    NgTableParams,
    Consts,
    $http,
    DatabaseApi,
    toaster,
    wildcard,
    $uibModal,
    entityNotesModalService,
    $timeout,
    offices,
    Storage,
    $q,
    OrientationCenterService,
) {
    $scope.isLoading = {
        addBundle: new Set(),
    };

    let prosMap = DatabaseApi.caregivers() || {};
    const columnsPendingApplication = Storage.getObject("pendingApplicationTableSettings");

    $scope.pendingApplicationFilters = {
        certification: {},
        languages: [],
        stage: {},
        offices: [],
        from: moment().subtract(1, "month"),
        to: moment()
    };
    $scope.pendingApplicationStatistics = {};
    $scope.selectedPendingCertificationLabel = null;
    $scope.pendingApplicationGlobalFilter = { val: "" };

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.languages = [
        { id: 1, label: "English" },
        { id: 2, label: "Spanish" },
        { id: 3, label: "Chinese" },
        { id: 4, label: "Portuguese" },
        { id: 5, label: "French" },
        { id: 6, label: "Russian" },
        { id: 7, label: "Arabic" },
    ];

    $scope.offices = offices
        .filter(office => office.active)
        .map(office => ({
            id: office.id,
            label: office.name
        }));

    $scope.offices.unshift({ id: -1, label: "No/Inactive Offices" });

    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1
    };

    $scope.certificationsingleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        scrollable: true,
        closeOnSelect: true,
        enableSearch: true
    };

    $scope.stageSingleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        displayProp: 'labelWithCount'
    };

    $scope.officesComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    getIncompleteHRWithSectionStages();

    function getIncompleteHRWithSectionStages() {
        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages').then(function (res) {
            const defaultStageOptions = [{ id: -2, label: "All stages", order: -2 }, { id: -1, label: "Pending for Review", order: -1 }];

            $scope.stages = {};
            $scope.certifications.forEach(certification => {
                $scope.stages[certification.label] = defaultStageOptions;
            });

            Object.keys(res.data.sectionStages).forEach(function (cert) {
                if (!$scope.stages[cert]) {
                    return;
                }
                $scope.stages[cert] = $scope.stages[cert].concat(res.data.sectionStages[cert]);
                $scope.stages[cert].sort(function (a, b) { return a.order < b.order ? -1 : 1 });
            });
            $scope.incompleteHRGet();
        }, function (err) {
            toaster.pop('error', 'Failed to load HR stages');
        });
    }

    $scope.incompleteHRGet = function () {
        $scope.isLoadingIncompleteForms = true;
        DatabaseApi.get(
            "agencies/" + $rootScope.agencyId + "/hr_incomplete_forms"
        ).then(
            async function (response) {
                if (
                    !response.data.caregiversIncompleteHRForms ||
                    !response.data.caregiversIncompleteHRForms.length
                )
                    return;
                var percentageSum;

                response.data.caregiversIncompleteHRForms.forEach(function (row, i) {
                    response.data.caregiversIncompleteHRForms[i] = angular.extend(
                        response.data.caregiversIncompleteHRForms[i],
                        prosMap[row.caregiver.toString()]
                    );
                    row.incompleteHRForms.forEach(function (item) {
                        item.color = perc2color(item.percentage);
                    });

                    percentageSum = row.incompleteHRForms.reduce(function (acc, obj) { return acc + obj.percentage; }, 0);
                    row.progressPercentage = Math.round(percentageSum / row.incompleteHRForms.length);

                    if (!row.caregiverCurrentStageId || !row.certification || !$scope.stages[row.certification]) {
                        row.stage = "";
                        row.stageColor = "";
                    } else {
                        var stage = $scope.stages[row.certification].find(function (stage) {
                            return stage.id === row.caregiverCurrentStageId;
                        });
                        if (stage) {
                            row.stage = stage.label;
                            row.stageColor = stage.labelColor;
                        } else {
                            row.stage = "";
                            row.stageColor = "";
                        }
                    }

                });

                $scope.caregiversIncompleteHRForms = response.data.caregiversIncompleteHRForms;
                filterIncompleteHrTable();
            }).catch(() => toaster.pop('error', 'Could not get pending caregivers applications'))
            .finally(() => $scope.isLoadingIncompleteForms = false);
    };

    $scope.getHRPendingStatistics = async function () {
        var deferred = $q.defer();

        const rangeQueryParams = "from=" + $filter("date")(new Date($scope.pendingApplicationFilters.from), "yyyy-MM-dd") +
            "&to=" + $filter("date")(new Date($scope.pendingApplicationFilters.to), "yyyy-MM-dd");

        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/caregiver_pending_statistics?' + rangeQueryParams).then(function (res) {
            $scope.pendingApplicationStatistics.becameActive = res.data.becameActive;
            deferred.resolve();
        }, function (err) {
            toaster.pop('error', 'Could not get hr statistics');
            deferred.reject();
        });

        return deferred.promise;
    }

    $scope.onPendingApplicationDateRangeChanged = function (startDate, endDate) {
        $scope.pendingApplicationFilters.from = moment(startDate);
        $scope.pendingApplicationFilters.to = moment(endDate);
        $scope.getHRPendingStatistics();
    }

    const incompleteHrFilterByMethods = {
        caregiverHasCertification: function (caregiver, certification) { return caregiver.certification === certification; },
        caregiverHasLanguage: function (caregiver, languages) { return caregiver.languages.find(function (lang) { return languages.indexOf(lang) !== -1; }) !== undefined; },
        caregiverHasOffices: function (caregiver, offices) { return caregiver.officeIds.find(function (office) { return offices.indexOf(office) !== -1; }) !== undefined; },
        caregiverHasStage: function (caregiver, stage) {
            switch (stage.id) {
                // all stages
                case -2: {
                    return true;
                }
                // pending for review
                case -1: {
                    return caregiver.pendingForReview === true;
                }
                default: {
                    return caregiver.stage === stage.label;
                }
            }
        },
        caregiverIsInRange: function (caregiver) {
            let caregiverApplicationStartDate = caregiver.applicationStartDate;
            if (caregiverApplicationStartDate === null) {
                return true;
            } else {
                caregiverApplicationStartDate = moment(caregiver.applicationStartDate)
                return caregiverApplicationStartDate.isBetween($scope.pendingApplicationFilters.from, $scope.pendingApplicationFilters.to);
            }
        },
    };

    function filterIncompleteHrTable() {
        if (!$scope.caregiversIncompleteHRForms) return;

        var filters = [];

        var selectedCertification = $scope.pendingApplicationFilters.certification;
        if (selectedCertification && typeof selectedCertification.id === "number") {
            selectedCertification = $scope.certifications.find(cert => cert.id === selectedCertification.id);
            if (selectedCertification) {
                selectedCertification = selectedCertification.label;
                filters.push(function (caregiver) { return incompleteHrFilterByMethods.caregiverHasCertification(caregiver, selectedCertification); });
            }
        }

        var selectedLanguages = $scope.pendingApplicationFilters.languages.map(function (obj) {
            return $scope.languages.find(function (lang) { return lang.id === obj.id; }).label;
        });
        if (selectedLanguages.length > 0) {
            filters.push(function (caregiver) { return incompleteHrFilterByMethods.caregiverHasLanguage(caregiver, selectedLanguages); });
        }

        var selectesStageId = $scope.pendingApplicationFilters.stage.id;
        if (selectesStageId && typeof selectedCertification === "string") {
            const selectedStage = $scope.stages[selectedCertification].find(stage => stage.id === selectesStageId);
            filters.push(function (caregiver) { return incompleteHrFilterByMethods.caregiverHasStage(caregiver, selectedStage); });
        }

        var selectedOffices = $scope.pendingApplicationFilters.offices.map((office) => office.id);
        if (selectedOffices && selectedOffices.length > 0) {
            filters.push(function (caregiver) { return incompleteHrFilterByMethods.caregiverHasOffices(caregiver, selectedOffices); });
        }

        if ($scope.pendingApplicationFilters.from && $scope.pendingApplicationFilters.to) {
            filters.push(function (caregiver) { return incompleteHrFilterByMethods.caregiverIsInRange(caregiver); });
        }

        var filteredPendingCaregivers = $scope.caregiversIncompleteHRForms;
        if (filters.length > 0) {
            filteredPendingCaregivers = filteredPendingCaregivers.filter(function (caregiver) {
                var isFiltered = true;
                for (var idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](caregiver);
                }
                return isFiltered;
            });
        }

        setPendingApplicationStatistics(filteredPendingCaregivers);
        initIncompleteHrTable(filteredPendingCaregivers);
    }

    function setPendingApplicationStatistics(data) {
        // set pending for review for careigvers
        $scope.pendingApplicationStatistics.pendingForReview = data.filter(c => c.pendingForReview === true).length;
        $scope.pendingApplicationStatistics.total = data.length;


        const selectedCertification = $scope.pendingApplicationFilters.certification;
        if (Object.keys(selectedCertification).length === 0) {
            return;
        }
        const certification = $scope.certifications.find(cert => cert.id === selectedCertification.id).label;
        const caregiverPerCertification = $scope.caregiversIncompleteHRForms.filter(c => c.certification === certification);

        // dropdown labels - should be per certificate
        Object.keys($scope.stages).forEach(function (cert) {
            let numberOfCaregiversAtStage;
            $scope.stages[cert].forEach(stage => {
                switch (stage.id) {
                    // all stages
                    case -2: {
                        stage.labelWithCount = stage.label + ` (${caregiverPerCertification.length})`;
                        break
                    }
                    // pending for review
                    case -1: {
                        const totalPendingForReview = $scope.caregiversIncompleteHRForms.filter(c => c.certification === certification && c.pendingForReview === true);
                        stage.labelWithCount = stage.label + ` (${totalPendingForReview.length})`;
                        break
                    }
                    default: {
                        numberOfCaregiversAtStage = $scope.caregiversIncompleteHRForms.filter(c => c.certification === certification && c.stage === stage.label).length;
                        stage.labelWithCount = numberOfCaregiversAtStage === 0 ? stage.label : stage.label + ` (${numberOfCaregiversAtStage})`;
                    }
                }
            })
        });
    }

    function initIncompleteHrTable(data) {
        $scope.incompleteHrTableData = data;

        var options = {
            count: 25,
            sorting: { timeStamp: "desc" }
        };
        $scope.incompletHrTable = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: $scope.incompleteHrTableData
        });

        if ($scope.pendingApplicationGlobalFilter.val) {
            $scope.applyPendingApplicationGlobalSearch($scope.pendingApplicationGlobalFilter.val);
        }
    }

    $scope.applyPendingApplicationGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.incompletHrTable) {
            angular.extend($scope.incompletHrTable.filter(), filter);
        } else {
            console.log("no table");
        }
    };

    $scope.pendingApplicationCertificationsEvents = {
        onItemSelect: function (item) {
            const certification = $scope.certifications.find(cert => cert.id === item.id);
            if (certification) {
                $scope.selectedPendingCertificationLabel = certification.label;
                $scope.pendingApplicationFilters.stage = { id: -2 };
            }
        },
        onItemDeselect(item) {
            $scope.selectedPendingCertificationLabel = null;
        },
        onDeselectAll() {
            $scope.selectedPendingCertificationLabel = null;
        }
    }

    $scope.pendingApplicationCertificationsEvents = {
        onItemSelect: function (item) {
            const certification = $scope.certifications.find(cert => cert.id === item.id);
            if (certification) {
                $scope.selectedPendingCertificationLabel = certification.label;
                $scope.pendingApplicationFilters.stage = { id: -2 };
            }
        },
        onItemDeselect(item) {
            $scope.selectedPendingCertificationLabel = null;
        },
        onDeselectAll() {
            $scope.selectedPendingCertificationLabel = null;
        }
    }

    function perc2color(perc) {
        var r,
            g,
            b = 0;
        if (perc < 50) {
            r = 229;
            g = Math.round(5.1 * perc);
        } else {
            g = 175;
            r = Math.round(510 - 5.1 * perc);
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1;
        return "#" + ("000000" + h.toString(16)).slice(-6);
    }

    if (columnsPendingApplication && Object.keys(columnsPendingApplication).length) {
        var isStageExists = false;
        var Offices = false;
        var isApplicationStartDateExists = false;
        var isIDExists = false;

        $scope.pendingApplicationTableColumnsLength = 0;
        Object.keys(columnsPendingApplication).forEach(function (t) {
            if (t === "Stage") isStageExists = true;
            if (t === "ID") isIDExists = true;
            if (t === "Offices") Offices = true;
            if (t === "ApplicationStartDate") isApplicationStartDateExists = true;
            if (columnsPendingApplication[t]) $scope.pendingApplicationTableColumnsLength++;
        });

        if (!isStageExists) {
            columnsPendingApplication.Stage = true;
        }

        if (!isIDExists) {
            columnsPendingApplication.ID = true;
        }
        if (!Offices) {
            columnsPendingApplication.Offices = true;
        }

        if (!isApplicationStartDateExists) {
            columnsPendingApplication.ApplicationStartDate = true;
        }

        if (!isApplicationStartDateExists) {
            columnsPendingApplication.ApplicationStartDate = true;
        }


        $scope.pendingApplicationTableColumns = columnsPendingApplication;
    } else {
        // no columns
        $scope.pendingApplicationTableColumns = {
            ID: true,
            Photo: true,
            Name: true,
            Gender: true,
            Birthdate: true,
            Languages: true,
            InstalledAt: true,
            Branch: true,
            Certification: true,
            Installation: true,
            ApplicationStartDate: true,
            LastSeen: true,
            Address: true,
            Offices: true,
            Phone: true,
            Progress: true,
            Stage: true,
            Actions: true
        };
        $scope.pendingApplicationTableColumnsLength = 0;
        Object.keys($scope.pendingApplicationTableColumns).forEach(function (t) {
            if ($scope.pendingApplicationTableColumns[t]) $scope.pendingApplicationTableColumnsLength++;
        });
    }

    $scope.openRemoteLinkModal = function () {
        $uibModal.open({
            templateUrl: "admin/views/remote-application-link-modal.html",
            size: "md",
            controller: "remoteApplicationLinkModalCtrl"
        });
    }
    $scope.goToProfile = function (id, certification) {
        $rootScope.openCaregiverModal(id, undefined, undefined, certification);
    };

    $scope.handleNotesModalOpen = function (profileId, profileName) {
        entityNotesModalService.handleNotesModalOpen({ profileId, profileName });
    }

    $scope.exportIncompleteHrTable = function () {
        // Filter table data by global filter
        const list = $filter('filter')($scope.incompleteHrTableData, $scope.pendingApplicationGlobalFilter.val);
        const rows = [];
        const titles = [];
        for (const key in $scope.pendingApplicationTableColumns) {
            if ($scope.pendingApplicationTableColumns[key] &&
                key !== "Photo" &&
                key !== "Actions" &&
                (key !== "Branch" || $scope.visitSettings.allowBranches)) {
                titles.push(key);
            }
        }
        rows.push(titles);

        list.forEach(function (caregiver) {
            const row = [];
            titles.forEach(function (title) {
                switch (title) {
                    case "Name": {
                        row.push(caregiver.displayName || "");
                        break;
                    }
                    case "Gender": {
                        row.push(caregiver.gender || "");
                        break;
                    }
                    case "Birthdate": {
                        row.push($filter("mfShortDate")(caregiver.birthDate) || "");
                        break;
                    }
                    case "Languages": {
                        row.push(caregiver.languages.join(" | ") || "");
                        break;
                    }
                    case "InstalledAt": {
                        let string = " ";
                        caregiver.appInstalledAt.forEach(function (d) {
                            if (string !== " ") string += " | ";
                            string += $filter("mfShortDate")(d);
                        });
                        row.push(string);
                        break;
                    }
                    case "Branch": {
                        row.push(row.caregiverBranch || "");
                        break;
                    }
                    case "Certification": {
                        row.push(caregiver.certification || "");
                        break;
                    }
                    case "Installation": {
                        if (caregiver.confirmed === "installed") row.push("Installed");
                        else if (caregiver.confirmed === "uninstalled") row.push("Un-Installed");
                        else row.push("Not Installed");
                        break;
                    }
                    case "LastSeen": {
                        if (!caregiver.lastSeen) row.push("");
                        else row.push($filter("mfShortDate")(caregiver.lastSeen) || "");
                        break;
                    }
                    case "Address": {
                        let address = caregiver.address;
                        row.push('"' + address + '"' || '" "');
                        break;
                    }

                    case "Offices": {
                        row.push(row.officeIds || "");
                        break;
                    }

                    case "Phone": {
                        let string = " ";
                        caregiver.phoneNumbers.forEach(function (f) {
                            if (string !== " ") string += " | ";
                            string +=
                                $filter("americanphone")(f.phoneNumber) + " (" + f.type + ")";
                        });
                        row.push(string);
                        break;
                    }
                    case "Progress": {
                        let percentage = caregiver.progressPercentage;
                        if (!percentage) percentage = 0;
                        row.push(percentage + "%")
                        break;
                    }
                    case "Stage": {
                        row.push(caregiver.stage || "");
                        break;
                    }
                    default: {
                        row.push("");
                        break;
                    }
                }
            });

            rows.push(row);
        });

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "medflyt-pending-application-caregivers-export.csv");

        link.click();
        window.URL.revokeObjectURL(url);
    }

    $scope.showCaregiverOffices = function (officeIds) {
        let presentedOffices;
        const includeInactiveOffices = $scope.pendingApplicationFilters.offices.find(office => office.id === -1) !== undefined;
        if (includeInactiveOffices) {
            presentedOffices = officeIds
                .map(officeId => offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.name)
        } else {
            presentedOffices = officeIds
                .map(officeId => $scope.offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.label)
        }

        return presentedOffices.join(", ");
    }

    $scope.addOrientationBundle = function (row) {
        const { caregiver, displayName } = row;
        $scope.isLoading.addBundle.add(caregiver);

        OrientationCenterService.addMissingOrientations(caregiver)
            .then(() => {
                row.showAddBundle = false;
                toaster.pop("success", `Added orientations for ${displayName}`);
            })
            .catch((err) => {
                console.log(err);
                toaster.pop(
                    "error",
                    `Couldn't add orientations for ${displayName}`,
                    "Please try again later"
                );
            })
            .finally(() => {
                $scope.isLoading.addBundle.delete(caregiver);
            });
    };

    $scope.$watch(
        "pendingApplicationTableColumns",
        function () {
            if ($scope.pendingApplicationTableColumns) {
                Storage.setObject("pendingApplicationTableSettings", $scope.pendingApplicationTableColumns);
                $scope.pendingApplicationTableColumnsLength = 0;
                Object.keys($scope.pendingApplicationTableColumns).forEach(function (t) {
                    if ($scope.pendingApplicationTableColumns[t]) $scope.pendingApplicationTableColumnsLength++;
                });
                // initTable(prosArr);
            }
        },
        true
    );

    $scope.$watch('pendingApplicationFilters', function () {
        filterIncompleteHrTable();
    }, true);

    $rootScope.$on("got_caregivers_data", function (event) {
        prosMap = DatabaseApi.caregivers();
    });
});