"use strict";
app.controller(
  "agencyBranchSettingModalCtrl",
  function (
    $scope,
    $rootScope,
    $uibModalInstance,
    fields,
    data,
    toaster,
    FormService,
    agencyBranchService
  ) {
    $scope.fields = FormService.getFields(fields, data);

    $scope.form = {};

    $scope.fieldChanged = (item) => {
      if (item.type.type === "Boolean" && data.id) {
        $scope.submitForm();
      }
    };

    $scope.submitForm = () => {
      if ($scope.form.data.$invalid) return;

      const body = FormService.prepareFormData($scope.fields);

      if (data.id) {
        agencyBranchService
          .editAgencyBranch(
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            data.id,
            body
          )
          .then(
            (res) => {
              toaster.pop("success", "Branch saved successfully");
              $scope.closeModal();
            },
            (err) => {
              toaster.pop(
                "error",
                "Something went wrong",
                "Could not edit note subject"
              );
            }
          );
      } else {
        agencyBranchService
          .createAgencyBranch(
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            body
          )
          .then(
            (res) => {
              toaster.pop("success", "Branch saved successfully");
              $scope.closeModal();
            },
            (err) => {
              toaster.pop(
                "error",
                "Something went wrong",
                "Could not create new branch"
              );
            }
          );
      }
    };

    $scope.closeModal = () => {
      $uibModalInstance.close("ok");
    };

    $scope.exit = () => {
      $uibModalInstance.dismiss();
    };
  }
);
