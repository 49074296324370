app.controller("caregiverTrainingCenterAddBundleModalCtrl", function (
  $scope,
  caregiverTrainingCenterAddBundleModalService,
  toaster
) {
  $scope.potentialBundles = undefined;
  $scope.isLoading = true;

  this.$onInit = function () {
    if ($scope.$resolve.caregiverId === undefined) return;

    $scope.caregiverId = $scope.$resolve.caregiverId;

    caregiverTrainingCenterAddBundleModalService
      .getPotentialManualBundles($scope.caregiverId)
      .then((res) => ($scope.potentialBundles = res.data.available))
      .catch(() => {
        toaster.pop("error", "Error loading bundles");
      })
      .finally(() => ($scope.isLoading = false));
  };

  $scope.addBundle = function ({
    bundleId,
    dueDateId,
    visibleDate,
    completionDate,
  }) {
    $scope.isLoading = true;

    caregiverTrainingCenterAddBundleModalService
      .addPotentialManualBundle({
        caregiverId: $scope.caregiverId,
        bundleId,
        dueDateId,
        visibleDate,
        completionDate,
      })
      .then(() => {
        toaster.pop("success", "Bundle was added!");
        $scope.potentialBundles = $scope.potentialBundles.filter(
          (bundle) => bundle.bundleId !== bundleId
        );
        caregiverTrainingCenterAddBundleModalService
          .getBundleAddedEmitter()
          .addBundle();
      })
      .catch(() => {
        toaster.pop("error", "Error adding bundle");
      })
      .finally(() => ($scope.isLoading = false));
  };
});
