app.controller('mapModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, visit, DatabaseApi, toaster, Socket, SweetAlert, $timeout){
  
  window.scrollTo(0,0);
  
  //console.log('modal');
  $scope.visit = visit;
  //console.log($scope.visit);
  
  $scope.closeModal = function(){
    $uibModalInstance.close('cancel');
  };
 
  $scope.map = {zoom: 13};
  
  var gpsData = {};
  if(visit.checkinLocation && visit.checkinLocation.addressComponents && visit.checkinLocation.addressComponents.location){
    $scope.map.center = {
      latitude: visit.checkinLocation.addressComponents.location.lat, 
      longitude: visit.checkinLocation.addressComponents.location.lng
    };
    $scope.map.checkinLocation = {
      latitude: visit.checkinLocation.addressComponents.location.lat, 
      longitude: visit.checkinLocation.addressComponents.location.lng
    };
  }
  if(visit.checkoutLocation && visit.checkoutLocation.addressComponents && visit.checkoutLocation.addressComponents.location){
    $scope.map.checkoutLocation = {
      latitude: visit.checkoutLocation.addressComponents.location.lat, 
      longitude: visit.checkoutLocation.addressComponents.location.lng
    };
  }
  if(visit.caregiverVisitLocation && visit.caregiverVisitLocation.length){
    var arr = [];
    visit.caregiverVisitLocation.forEach(function(point){
      arr.push({latitude: point.location.lat, longitude: point.location.lng});
    });
    
    $scope.map.poly = arr;
  }
  
  $scope.options = {};
  
  $timeout(function () {
    $scope.showMap = true;
  }, 100);
  
  
  
  
});