/**
 * ORIGINAL FILE = timesheetsService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
class TimesheetsService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    $uibModal;
    entityNoteService;
    noteConsts;
    constructor(DatabaseApi2, endpoint, $rootScope, $uibModal, entityNoteService, noteConsts) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
        this.$uibModal = $uibModal;
        this.entityNoteService = entityNoteService;
        this.noteConsts = noteConsts;
    }
    editTimesheetsStatus(params, newStatus) {
        const modalInstance = this.$uibModal.open({
            templateUrl: "admin/views/approve-duty-sheets-clock-times-modal.html",
            size: "md",
            controller: "approveDutySheetsClockTimesModalCtrl",
            resolve: {
                requestNoteText: () => false,
                changeToTimesheetStatus: () => newStatus
            }
        });
        modalInstance.result.then((res) => {
            const { approveDutySheetNote } = res;
            if (approveDutySheetNote && approveDutySheetNote.note) {
                const body = {
                    timesheets: []
                };
                params.timesheets.forEach((item) => {
                    approveDutySheetNote.note.visitInstanceId = item.visitInstanceId;
                    const note = this.entityNoteService.buildEntityNoteRequest(approveDutySheetNote.note, this.noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME, {
                        patientId: item.patientId,
                        caregiverId: item.caregiverId
                    });
                    const result = {
                        timesheetId: item.timesheetId,
                        note: note
                    };
                    body.timesheets.push(result);
                });
                const path = newStatus === "APPROVED" ?
                    "agencies/:agencyId/agency_members/:agencyMemberId/time_sheet_approval" :
                    "agencies/:agencyId/agency_members/:agencyMemberId/time_sheet_decline";
                const url = this.endpoint({
                    path: path,
                    params: {
                        agencyId: this.$rootScope.agencyId,
                        agencyMemberId: this.$rootScope.agencyMemberId,
                    },
                });
                return this.DatabaseApi2.post(url, body)
                    .then(params.onSuccess)
                    .catch(params.onCatch)
                    .finally(() => {
                    this.$rootScope.$broadcast("refresh_timesheets");
                    if (params.onFinally) {
                        params.onFinally();
                    }
                });
            }
            if (params.onFinally) {
                params.onFinally();
            }
        });
    }
}
angular.module("dashboard").service("timesheetsService", TimesheetsService);
export {};
