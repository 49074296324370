app.controller('overtimeCalculatorModalCtrl', function($scope, $rootScope, $uibModalInstance, $http) {

    $scope.caregiverCode = "";

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };

    $scope.keyPress = function ($event) {
        if ($event.which === 13) {
            $scope.searchOvertime();
        }
    }

    $scope.searchOvertime = function () {
        $scope.isLoading = true;
        $http.get("https://ahc-api.s3.amazonaws.com/" + $scope.caregiverCode).then(function(res) {
            $scope.caregiverCode = "";

            var amazingHours = -1;
            var itergenHours = -1;
            var total = -1;
            var amazingKey = "";
            var itergenKey = "";

            Object.keys(res.data).forEach(function (key) {
                switch (key[0]) {
                    case 'A': {
                        amazingHours = res.data[key];
                        amazingKey = " (" + key + ")";
                        break;
                    }
                    case 'I': {
                        itergenHours = res.data[key];
                        itergenKey = " (" + key + ")";
                        break;
                    }
                    case 'T': {
                        total = res.data[key];
                        break;
                    }
                    default:
                        break
                }
            });

            $scope.isLoading = false;

            $scope.overtimeData = (amazingHours === -1 && itergenHours === -1 && total === -1) ? "No results" : {
                amazing: amazingHours === -1 ? "" : amazingHours,
                amazingKey: amazingKey,
                itergen: itergenHours === -1 ? "" : itergenHours,
                itergenKey: itergenKey,
                total: total
            };
        }).catch(function (e) {
            $scope.isLoading = false;
            $scope.overtimeData = "No results";
        });
    }

    setTimeout(function() {
        document.getElementById("caregiverCodeInput").focus();
    }, 0);
});