"use strict";
angular.module("dashboard").component("entityChatsListComponent", {
  templateUrl: "admin/views/entity-chats-list-component.html",
  bindings: {
    entity: "<",
    chats: "<",
    onChatClick: "&",
  },
  controller: function () {
    this.filter = { displayName: "" };
    this.orderByArr = { radio: "", val: "" };
    this.selectChat = (chat) => {
      if (chat.chatRoomType === undefined) {
        chat.fromList = true;
        chat.chatRoomType = {
          caregiverId: chat.id,
          patientId: this.entity ? this.entity.id : null,
        };
      }
      this.onChatClick({ chat });
    };

    const fuzzy_match = (str, pattern) => {
      pattern = pattern.split("").reduce(function (a, b) {
        return a + "[^" + b + "]*" + b;
      });
      var regx;
      try {
        regx = new RegExp(pattern);
      } catch (e) { }
      if (regx) return regx.test(str);
      else return false;
    };

    this.isToday = (date) => {
      const now = moment().subtract(24, 'h');
      const expirationDate = moment(date);
      return expirationDate.diff(now, 'h') > 0;
    }

    this.fuzzyFilter = (row) => {
      // console.log("row=", row);
      if (
        this.filter.myMessagesOnly &&
        (!row ||
          !row.chat ||
          row.chat.chatRoomType.agencyMemberId !== $rootScope.agencyMemberId)
      )
        return false;
      if (
        !this.filter.displayName &&
        (!row || !row.chat || !row.chat.messages || !row.chat.messages.length)
      )
        return false;
      if (!this.filter || !this.filter.displayName) return true;
      if (row.displayId && fuzzy_match(row.displayId.toString().toLocaleLowerCase(), this.filter.displayName.toLocaleLowerCase())) return true;
      if (
        fuzzy_match(
          row.displayName.toLocaleLowerCase(),
          this.filter.displayName.toLocaleLowerCase()
        )
      )
        return true;
      if (
        row.caregiverCode &&
        fuzzy_match(
          row.caregiverCode.toLocaleLowerCase(),
          this.filter.displayName.toLocaleLowerCase()
        )
      )
        return true;
      return false;
    };
  },
});
