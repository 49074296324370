app.controller('customerEngagementCtrl', function ($scope, $uibModal, DatabaseApi, toaster, NgTableParams, $q) {
    
    $scope.reps = [];
    DatabaseApi.get('admin/customer-engagement/reps').then(function (res) {
        $scope.reps = res.data.reps
    }, function (err) {
        
    });
    
    $scope.engagementAgencyMembersMap = {};
    $scope.customerEngagementFilters = {
        isActiveAgency: true
    };

    const customerEngagementFilterByMethods = {
        isActive: (agency, isActiveAgency) => agency.active === isActiveAgency,
    };

    function filterCustomerEngagementTable() {
        if (!$scope.agencies) return;
        let filters = [];

        const isActiveAgency = $scope.customerEngagementFilters.isActiveAgency;
        if (isActiveAgency === true) {
            filters.push(function (agency) { return customerEngagementFilterByMethods.isActive(agency, isActiveAgency); });
        }

        let filteredAgencies = $scope.agencies;
        if (filters.length > 0) {
            filteredAgencies = filteredAgencies.filter(function (agency) {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](agency);
                }
                return isFiltered;
            });
        }

        initAgenciesAndProductsTable(filteredAgencies);
    }

    function initAgenciesAndProductsTable(items) {
        const options = {
            count: 25
        };
        $scope.agenciesAndProductsTable = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    $scope.applyCustomerEngagementGlobalSearch = function (term) {
        const filter = { $: term };
        if ($scope.agenciesAndProductsTable) {
            angular.extend($scope.agenciesAndProductsTable.filter(), filter);
        }
    };

    function getAgenciesAndProducts() {
        const agenciesAndProductsUrl = 'admin/customer-engagement/agencies_and_products';
        DatabaseApi.get(agenciesAndProductsUrl).then(function (res) {
            $scope.agencies = res.data.agencies;
            $scope.products = res.data.products;
            enrichAgenciesAndProducts();
            filterCustomerEngagementTable();
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'could not get agencies and products');
        });
    }

    function enrichAgenciesAndProducts() {
        $scope.agencies.forEach(agency => {
            agency.products.forEach(product => {
                let isActive = false;
                if (product) {
                    let subscriptionStartDate;
                    let subscriptionEndDate;
                    if (product.subscription_start && !product.subscription_end) {
                        subscriptionStartDate = new Date(product.subscription_start);
                        isActive = moment(new Date()).isAfter(subscriptionStartDate);
                    }
                    else if (product.subscription_start && product.subscription_end) {
                        subscriptionStartDate = new Date(product.subscription_start);
                        subscriptionEndDate = new Date(product.subscription_end);
                        isActive = moment(new Date()).isBetween(subscriptionStartDate, subscriptionEndDate);
                    }
                    product.isActive = isActive;
                }
            });
        });
    }

    async function getEngagementAgencyMembers(agencyId) {
        let deferred = $q.defer();

        const agencyMembers = $scope.engagementAgencyMembersMap[agencyId];

        if (agencyMembers) {
            deferred.resolve(agencyMembers);
        }

        const agencyMembersUrl = 'admin/customer-engagement/agency_members/' + agencyId;
        DatabaseApi.get(agencyMembersUrl).then(function (res) {
            $scope.engagementAgencyMembersMap[agencyId] = res.data.agencyMembers;
            deferred.resolve(res.data.agencyMembers);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'could not get agency members');
            deferred.reject(null);
        });
        return deferred.promise;
    }

    $scope.onAddAgencyProduct = async (agency, product) => {
        const agencyMembers = await getEngagementAgencyMembers(agency.agencyId);
        const agencyProduct = agency.products[product.id - 1];

        let newScope = $scope.$new();
        newScope.agency = agency;
        newScope.product = agencyProduct === null ? product : agencyProduct;
        newScope.isNewProduct = agencyProduct === null;
        newScope.agencyMembers = agencyMembers;
        newScope.reps = $scope.reps;
        newScope.repName = {val: ''};

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/agency-product-modal.html',
            size: 'lg',
            controller: 'agencyProductModalCtrl',
            scope: newScope
        });

        modalInstance.result.then(function (res) {
            if (res && res.id) {
                getAgenciesAndProducts();
            }
        });
    }

    $scope.updateAgencyEngagementStatus = (agency) => {
        const url = 'admin/customer-engagement/agencies/' + agency.agencyId + '/' + agency.active;
        DatabaseApi.patch(url).then(function (res) {
            toaster.pop('success', "Agency status updated successfully");
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Could not update agency status');
        });
    }

    $scope.$watch('customerEngagementFilters', function () {
        filterCustomerEngagementTable();
    }, true);

    getAgenciesAndProducts();
});

app.directive('agencyProduct', function ($timeout) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/agency-product.html',
        scope: {
            product: '=',
            addAgencyProduct: '&'
        }
    };
});