app.controller('approveVisitInstanceDeleteModalCtrl', function (
    $rootScope,
    $scope,
    DatabaseApi,
    $uibModalInstance,
    toaster,
    wildcard,
    noteConsts,
    entityNoteService,
    VisitInstanceModalService,
) {
    $scope.visitInstanceIds = $scope.$resolve.visitInstanceIds;
    $scope.approvalNote = {};
    $scope.manualRemoveNote = {};
    $scope.isManualRemoveVisitNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_MANUAL_REMOVE);
    // FOR THE APPROVAL NOTE, WE HAVE requestNoteText, indicates 'billing/paid/payroll draft';

    $scope.approveForm = () => {
        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
            $scope.manualRemoveNote,
            noteConsts.NoteTypes.VISIT_MANUAL_REMOVE
        );
        if (!isNoteValid) {
            $scope.removedVisitNoteValidations = { isPredefinedValid, isMessageValid };
            return;
        }

        if ($scope.$resolve.requestNoteText) {
            const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
                $scope.approvalNote, 
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL
            );
            if (!isNoteValid) {
                $scope.approvalNoteValidations = { isPredefinedValid, isMessageValid };
                return;
            }
        }

        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/delete_visit_instances",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );
        const body = {
            ids: $scope.visitInstanceIds
        };

        body.manualRemoveNote = entityNoteService.buildEntityNoteRequest(
            $scope.manualRemoveNote,
            noteConsts.NoteTypes.VISIT_MANUAL_REMOVE,
        );

        if ($scope.$resolve.requestNoteText) {
            body.approvalNote = entityNoteService.buildEntityNoteRequest(
                $scope.approvalNote,
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL,
            );
        }

        DatabaseApi.post(url, body).then(function (res) {
            const result = {
                success: res.data.deletedVisitInstanceIds && res.data.deletedVisitInstanceIds.length > 0
            };
            if (body.ids.length === 1 && res.data.deletedVisitInstanceIds.length === 1 && body.manualRemoveNote) {
                const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType(noteConsts.NoteTypes.VISIT_MANUAL_REMOVE);
                const answer = noteSettings.predefinedAnswers.find(a => a.id === body.manualRemoveNote.notePredefinedAnswerId);
                result.manualRemovedVisitNote = body.manualRemoveNote;
                result.manualRemovedVisitNote.predefinedAnswerText = answer.text;
            }
            if (skippedVisitsData) {
                VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: res.data.skippedVisitsAndMissingPermissions,
                    updatedVisitsAmount: res.data.deletedVisitInstanceIds.length,
                    action: "delete"
                });
            } else {
                toaster.pop('success', "Success", 'Visit instances deleted successfully');
            }

            $uibModalInstance.close(result);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', "Couldn't delete visit instance");
        });
    }

    $scope.handleNoteChanges = (noteModel, updatedNote) => {
        $scope[noteModel] = updatedNote;
    };

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };
});