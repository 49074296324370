app.controller('clientSurveyModalCtrl', function($scope, $rootScope, $uibModalInstance, toaster, DatabaseApi){

    $scope.form = {
        rating: null,
        comment: null
    };
    $scope.uploading = false;

    $scope.closeModal = function(){
        $uibModalInstance.dismiss();
    };

    $scope.send = function() {

        $scope.uploading = true;
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/client_survey"
                    .replace(":agencyId", $rootScope.agencyId)
                    .replace(":agencyMemberId", $rootScope.agencyMemberId);
        DatabaseApi.post(url, $scope.form).then(function (res) {
            toaster.pop('success','Thank You!');
            $scope.closeModal();
        }, function (err) {
            $scope.closeModal();
        });
    };

    $scope.colors = [
        'FF0000',  
        'FF3300', 
        'ff6600', 
        'ff9900', 
        'FFCC00',
        'FFFF00',
        'ccff00',
        '99ff00',
        '66ff00',
        '33ff00',
        '00FF00'
    ];

});

