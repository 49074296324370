"use strict";
app.controller('settingsCtrl', function ($scope, $rootScope, NgTableParams, DatabaseApi, toaster, $uibModal, officesService, agencyPatientStatusService, rangesCalculateService, wildcard, cityTaxCodeService, generalUtils) {
    $scope.navigationItems = {
        active: 'scroll-about',
        list: [
            {
                id: 'scroll-office',
                title: 'Offices'
            },
            {
                id: 'scroll-certification',
                title: 'Agency Certifications'
            },
            {
                id: 'scroll-payRollCode',
                title: 'Pay codes'
            },
            {
                id: 'scroll-payRate',
                title: 'Pay rates'
            },
            {
                id: 'scroll-payRollSetup',
                title: 'Payroll Setup'
            },
            {
                id: 'scroll-agency-holiday',
                title: 'Holidays'
            },
            {
                id: 'scroll-pto',
                title: 'PTO'
            },
            {
                id: 'scroll-serviceCode',
                title: 'Service codes'
            },
            {
                id: 'scroll-payer',
                title: 'Payers'
            },
            {
                id: 'scroll-contract',
                title: 'Contracts'
            },
            {
                id: 'scroll-uploadLogo',
                title: 'Upload Logo'
            }
        ]
    };

    function toggleHiddenPayRates() {
        if (!$scope.payRates) {
            return;
        }
        if ($scope.setupSettingsFilter.payRates.status) {
            // need to show
            $scope.setupSettingsFilter.payRates.status = false;
            const uniquePayCodes = extractUniquePayCodes($scope.payRates);
            setPayCodeTable(uniquePayCodes);
        } else {
            // need to hide
            $scope.setupSettingsFilter.payRates.status = true;
            const activePayRates = $scope.payRates.filter(row => row.payrollCodeIsActive);
            const uniquePayCodes = extractUniquePayCodes(activePayRates);
            setPayCodeTable(uniquePayCodes);
        }
    }

    /**
     * 
     * @param {$scope.setupSettingsFilter} setupObject 
     * @param {{
     *  toggleHidden: boolean
     * }} settings - optional 
     */
    $scope.filterSetups = (setupObject, settings) => {
        let filteredItems = [];
        if (setupObject.searchString) {
            $scope[setupObject.scopeKey].forEach(item => {
                if (item[setupObject.searchStringKey].toLowerCase().includes(setupObject.searchString.toLowerCase())) {
                    filteredItems.push(item);
                }
            })
        } else {
            filteredItems.push(...$scope[setupObject.scopeKey]);
        }
        if (settings && settings.toggleHidden) {
            setupObject.status = !setupObject.status;
        }
        if (setupObject.status === true) {
            filteredItems = filteredItems.filter(item => item[setupObject.booleanFilterKey] === true);
        }
        setBillingTableData(setupObject.billingDataKey, filteredItems);
    }

    $scope.setupSettingsFilter = {
        payRates: {
            statue: true,
            toggleFn: toggleHiddenPayRates,
        },
        payCodes: {
            status: true,
            booleanFilterKey: "isActive",
            scopeKey: "payrollCodes",
            billingDataKey: "payrollCode",
            searchString: null,
            searchStringKey: "displayId",
        },
        cartifications: {
            status: true,
            booleanFilterKey: "isActive",
            scopeKey: "certifications",
            billingDataKey: "certification",
            searchString: null,
            searchStringKey: "certification",
        },
        contracts: {
            status: true,
            booleanFilterKey: "active",
            scopeKey: "contracts",
            billingDataKey: "contract",
            searchString: null,
            searchStringKey: "name",
        },
        serviceCodes: {
            status: true,
            booleanFilterKey: "isActive",
            scopeKey: "serviceCodes",
            billingDataKey: "serviceCode",
            searchString: null,
            searchStringKey: "code",
        },
        payers: {
            status: true,
            booleanFilterKey: "isActive",
            scopeKey: "payers",
            billingDataKey: "payer",
            searchString: null,
            searchStringKey: "name",
        },
        payrollSetups: {
            status: true,
            booleanFilterKey: "isActive",
            scopeKey: "payrollSetups",
            billingDataKey: "payrollSetup",
            searchString: null,
            searchStringKey: "name",
        },
        holidays: {
            status: true,
            booleanFilterKey: "active",
            scopeKey: "agencyHolidays",
            billingDataKey: "agencyHoliday",
            searchString: null,
            searchStringKey: "name",
        }
    }

    $scope.billingTablesMap = {
        office: null,
        certification: null,
        contract: null,
        serviceCode: null,
        payer: null,
        payRollCode: null,
        payrollSetup: null,
        payRate: null,
        pto: null
    }

    $scope.setSettingsTab = function (tab) {
        $scope.selectedTab = tab;
        if (tab === 'settings') {
            $scope.getCountiesData();
        }
    }

    function setBillingTableData(tableKey, items, options) {
        if (options === undefined) {
            options = {
                count: 10,
                sorting: { id: "desc" }
            };
        }

        $scope.billingTablesMap[tableKey] = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function setPayRateTableData(items) {
        var options = {
            sorting: { startDate: "desc" },
            count: Infinity
        };

        $scope.billingTablesMap['payRate'] = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function setPayCodeTable(items) {
        const options = {
            sorting: { name: "asc" },
            count: 5
        };

        $scope.payCodesTable = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    $scope.locationGroups = [];
    $scope.counties = [];

    window.scrollTo(0, 0);

    $scope.settings = DatabaseApi.GetSettings();

    $scope.$watch('settings', function () {
        if ($scope.settings) $rootScope.visitSettings = $scope.settings;
    })

    $scope.updateSettings = function (data) {
        //$rootScope.visitSettings = data;
        if ($scope.visitSettings.hoursWorked >= 0) {
            $scope.visitSettings.hoursWorked_firstDayOfWeek = parseInt($scope.visitSettings.hoursWorked);
        } else {
            $scope.visitSettings.hoursWorked_firstDayOfWeek = undefined;
            $scope.visitSettings.hoursWorked = undefined;
        }

        //if(!data.pendingAcceptanceTime || data.pendingAcceptanceTime < 1) data.pendingAcceptanceTime = 10;
        //if(!data.firstDayOfWeek || data.firstDayOfWeek < 1) data.firstDayOfWeek = 0;
        //if(!data.logOutTime || data.logOutTime < 1) data.logOutTime = 10;

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/settings', $rootScope.visitSettings).then(function (res) {
        }, function (err) {

        });
    };

    $scope.newContract = { name: '' };
    // get contract types
    function getContracts() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/contract_types').then(function (res) {
            $scope.contracts = res.data.contractTypes;
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get source types");
        });

    }
    getContracts();

    $scope.submitNewContract = function (name) {
        $scope.newContract.name = '';
        $scope.sending = true;

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/contract_type', { name: name }).then(function (res) {
            $scope.sending = false;
            getContracts();
            toaster.pop('success', "New contract added");
        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add contract");
        });

    };


    $scope.newServiceCode = { name: '' };

    $scope.submitNewServiceCode = function (name) {
        $scope.newContract.name = '';
        $scope.sending = true;

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/service_code', { code: name }).then(function (res) {
            $scope.sending = false;
            getServiceCodes();
            toaster.pop('success', "New service code added");
        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add service code");
        });

    };

    /////////////////
    /// office settings
    /////////////////

    // get offices
    async function getOffices() {
        $scope.offices = officesService.offices;
        if (!$scope.offices) {
            officesService.getOffices().then((res) => {
                $scope.offices = res;
                DatabaseApi.storeOffices($scope.offices);
                setBillingTableData('office', $scope.offices);
            }).catch(err => {
                toaster.pop('error', "Something went wrong", "could not get offices");
            });
        } else {
            DatabaseApi.storeOffices($scope.offices);
            setBillingTableData('office', $scope.offices);
        }
    }
    getOffices();

    /////////////////
    /// ceritifcation settings
    /////////////////

    async function getCertifications() {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/certifications_settings",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );

        DatabaseApi.get(url).then((res) => {
            $scope.certifications = res.data.certifications;
            const certificationTableOptions = {
                count: 10
            };
            setBillingTableData('certification', $scope.certifications.filter(x => x.isActive), certificationTableOptions);
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get certifications");
        });
    }
    getCertifications();

    $scope.setCertificationActive = (row) => {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/certifications/:certification",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            row.certification
        );
        const body = {
            active: row.isActive
        };
        DatabaseApi.patch(url, body).then((res) => {
            const agencyCertifications = $scope.certifications.map(item => ({
                certification: item.certification,
                isActive: item.isActive
            }));
            DatabaseApi.storeAgencyCertifications(agencyCertifications);
            toaster.pop('success', 'Certification updated successfully');
        }, (err) => {
            row.isActive = !row.isActive;
            toaster.pop('error', "Something went wrong", "Failed updating certification");
        });
    }

    /////////////////
    /// contract settings
    /////////////////

    //$scope.newContractType = {name: ''};
    // get contract types
    async function getContracts() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/contract_types').then(function (res) {
            $scope.contracts = res.data.contractTypes;
            DatabaseApi.storeContractTypes($scope.contracts);
            setBillingTableData('contract', $scope.contracts.filter(x => x.active));
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get contract types");
        });
    }
    getContracts();


    /////////////////
    /// service codes settings
    /////////////////

    // get service codes
    async function getServiceCodes() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/service_codes').then(function (res) {
            $scope.serviceCodes = res.data.serviceCodes;
            DatabaseApi.storeServiceCodes($scope.serviceCodes);
            setBillingTableData('serviceCode', $scope.serviceCodes.filter(x => x.isActive));
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get service codes");
        });
    }
    getServiceCodes();


    /////////////////
    /// payer settings
    /////////////////

    function extractUniquePayCodes(payRates) {
        const uniquePayCodes = [...new Set(payRates.map(payRate => payRate.payrollCodeId))].map(payCodeId => ({
            id: payCodeId,
            name: payRates.find(payRate => payRate.payrollCodeId === payCodeId).payrollCodeName,
            isOpen: false
        }));

        return uniquePayCodes;
    }

    //$scope.newPayer = {name: ''};
    // get payers
    async function getPayers() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/payers').then(function (res) {
            $scope.payers = res.data.payers;
            DatabaseApi.storePayers($scope.payers);
            setBillingTableData('payer', $scope.payers);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get payers");
        });
    }
    getPayers();

    async function getPayrollCodes() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_codes').then(function (res) {
            $scope.payrollCodes = res.data.payrollCodes;
            DatabaseApi.storePayrollCodes($scope.payrollCodes);
            setBillingTableData('payrollCode', $scope.payrollCodes.filter(row => row.isActive));
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get payroll codes");
        });
    }
    getPayrollCodes();

    async function getPayrollSetups() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_setups').then(function (res) {
            $scope.payrollSetups = res.data.payrollSetups;
            DatabaseApi.storePayrollSetups($scope.payrollSetups);
            setBillingTableData('payrollSetup', $scope.payrollSetups.filter(x => x.isActive));
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get payroll setups");
        });
    }
    getPayrollSetups();

    async function getPayRates() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/pay_rates').then(function (res) {
            $scope.payRates = res.data.payRates;
            for (const pRtIdx in $scope.payRates) {
                for (const pRcIdx in DatabaseApi.payrollCodes()) {
                    if ($scope.payRates[pRtIdx].payrollCodeId === DatabaseApi.payrollCodes()[pRcIdx].id) {
                        $scope.payRates[pRtIdx].payrollCodeName = DatabaseApi.payrollCodes()[pRcIdx].displayId;
                        break;
                    }
                }
            }

            const activePayRates = $scope.payRates.filter(row => row.payrollCodeIsActive);
            const uniquePayCodes = extractUniquePayCodes(activePayRates);

            $scope.rangesDisplay = [];
            uniquePayCodes.forEach(payCode => {
                $scope.rangesDisplay = [...$scope.rangesDisplay, 
                                        ...rangesCalculateService.calculateRanges($scope.payRates.filter(payRate => payRate.payrollCodeId === payCode.id))]; 
            });

            DatabaseApi.storePayRates($scope.payRates);
            setPayCodeTable(uniquePayCodes);
            setPayRateTableData($scope.rangesDisplay);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get pay rates");
        });
    }
    getPayRates();

    async function getPtoSettings() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/caregiver_pto_settings').then(function (res) {
            $scope.ptoSettings = res.data.settings;
            $scope.ptoSettings.forEach(ptoSetting => {
                ptoSetting.officeName = $scope.getOfficeName(ptoSetting.officeId);
            });
            setBillingTableData('pto', $scope.ptoSettings);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get PTO settings");
        });
    }

    async function getDispalyIdsSettings() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/display_ids_settings').then(function (res) {
            $scope.displayIds = res.data;
            if ($scope.displayIds.patient)
                $scope.displayIds.patient.pattern = getIdPattern($scope.displayIds.patient.firstDigit, $scope.displayIds.patient.length);
            if ($scope.displayIds.caregiver)
                $scope.displayIds.caregiver.pattern = getIdPattern($scope.displayIds.caregiver.firstDigit, $scope.displayIds.caregiver.length);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get ID settings");
        });
    }
    getDispalyIdsSettings();
    $rootScope.$on('savedDisplayIdSettings', function (event) {
        getDispalyIdsSettings();
    });
    
    $scope.removePtoSetting = (row) => {
        DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/caregiver_pto_settings/' + row.id).then(function (res) {
            toaster.pop('success', "Removed PTO setting");
            getPtoSettings();
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not remove PTO settings");
        });
    }
    getPtoSettings();

    async function getAgencyHolidays() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/holidays').then(function (res) {
            $scope.agencyHolidays = res.data.agencyHolidays;
            DatabaseApi.storeAgencyHolidays($scope.agencyHolidays.filter(x => x.active));
            setBillingTableData('agencyHoliday', $scope.agencyHolidays);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get agency holidays");
        });
    }
    getAgencyHolidays();
    
    async function getAgencyBranches() {
        DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/branches`).then((res) => {
            $scope.agencyBranches = res.data.list;
            DatabaseApi.storeAgencyBranches($scope.agencyBranches);
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Could not get branches");
        });
    }
    getAgencyBranches();

    async function getAgencyPatientStatus() {
        const agencyPatientStatuses = await agencyPatientStatusService.getAgencyPatientStatuses();
        $scope.agencyPatientStatuses = agencyPatientStatuses;
        DatabaseApi.storeAgencyPatientStatuses($scope.agencyPatientStatuses);
    }
    getAgencyPatientStatus();

    $scope.updateAgencyPatientStatus = async function (item) {
      if (!item) {
        toaster.pop(
          "error",
          "Something went wrong",
          "Could not change patient status settings"
        );
        return;
      }
      await agencyPatientStatusService.editAgencyPatientStatusActive(item.text, {
        active: item.active,
      });
      getAgencyPatientStatus();
    };

    async function getAgencyCustomFields() {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/custom_fields"
                    .replace(":agencyId", $rootScope.agencyId)
                    .replace(":agencyMemberId", $rootScope.agencyMemberId);

        DatabaseApi.get(url).then((res) => {
            $scope.agencyCustomFields = res.data.list;
            DatabaseApi.storeAgencyCustomFields($scope.agencyCustomFields);
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Could not get custom fields");
        });
    }
    getAgencyCustomFields();

    async function getCityTaxCodes() {
        cityTaxCodeService.getCityTaxCodes().then(res => {
            $scope.cityTaxCodes = res;
            $scope.cityTaxCodes.forEach(cityTaxCode => {
                cityTaxCode.officesNames = cityTaxCode.officesIds.map(officeId => $scope.getOfficeName(officeId));
                cityTaxCode.offices = cityTaxCode.officesIds.map(officeId => ({ id: officeId, label: $scope.getOfficeName(officeId)}));
            });

            DatabaseApi.storeCityTaxCodes($scope.cityTaxCodes);
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Could not get city tax codes");
        });
    }
    getCityTaxCodes();
    
    async function getBillingIssuesTemplatesSettings() {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/billing_issues_templates_settings"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        DatabaseApi.get(url).then((res) => {
            $scope.billingIssuesTemplates = res.data.billingIssuesTemplatesSettings;
            $scope.billingIssuesTemplates.forEach(row => {
                row.roles = row.roleIds.map(roleId =>
                    res.data.roles.find(role => role.id === roleId).name
                );
            });
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Could not get billing issues templates settings");
        });
    }
    getBillingIssuesTemplatesSettings();

    $scope.updateAgencyHolidayStatus = function (item) {
        if (!item) {
            toaster.pop('error', "Something went wrong", "Could not change holiday settings")
            return;
        }
        if (item.date) {
            const now = moment();
            const holidayDate = moment(item.date);

            // Allow edit only when a future holiday (view mode only on past events)
            if (holidayDate.diff(now, 'h') < 0) {
                item.active = !item.active;
                toaster.pop('warning', "Could not edit holiday", "Past holidays can not be edited");
                return;
            }
        }
        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/holiday/' + item.id, { id: item.id, active: item.active })
            .then(function (res) {
                toaster.pop('success', 'Holiday updated successfully', '');
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not change holiday settings")
            })
    }

    $scope.getOfficeName = function (officeId) {
        if (!$scope.offices) return;

        const office = $scope.offices.find(({ id }) => officeId === id);
        return office ? office.name : "No office";
    };

    $scope.getPtoLabelNames = function (labelIds) {
        if (!$scope.caregiverPtoLabels) return;
        return labelIds.map(labelId => {
            const label = $scope.caregiverPtoLabels.find(loadedLabel => loadedLabel.id === labelId);
            return label === undefined ? null : label.text;
        }).filter(label => label !== null).join(', ');
    };

    $scope.getTreeLabeledText = function (item) {
        if (item.possibleAnswers.length > 0) {
            return item.itemName + " (" + item.possibleAnswers.join(", ") + ")";

        }
        return item.itemName;
    }

    $scope.updateSettingStatus = function (itemId, isActive) {
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/vbp_settings', { itemId: itemId, isActive: isActive })
            .then(function (res) {
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not change Value Based Payment settings")
            })
    }


    $scope.getCountiesData = function () {
        DatabaseApi.getLocationGroupsAndCounties().then(function (res) {
            $scope.locationGroups = res.locationGroups;
            $scope.counties = res.counties;
        }, function (err) {
            toaster.pop('error', "Failed to retrieve counties data");
        });
    }
    $scope.getCountiesData();

    $scope.openCountiesModal = function () {
        var newScope = $scope.$new();
        const { counties, locationGroups } = DatabaseApi.locationGroupsAndCounties();
        newScope.locationGroups = angular.copy(locationGroups);
        newScope.locationGroups.forEach(locationGroup => {
            locationGroup.officeIds = locationGroup.officeIds.map(function (officeId) {
                return $scope.offices.find(function (office) {
                    return office.id === officeId;
                });
            });
        })
        newScope.counties = counties;
        newScope.offices = $scope.offices.map(office => ({ id: office.id, label: office.name }));


        return $uibModal.open({
            templateUrl: 'admin/views/counties-modal.html',
            size: 'lg',
            scope: newScope,
            controller: 'countiesModalCtrl',
            windowTopClass: "boost-modal"
        });
    }

    /////////////////
    /// Management Settings
    /////////////////
    $rootScope.$on('got_data', function (event) {
        loadManagementSettings();
    });

    const loadManagementSettings = () => {
        $scope.caregiverPtoLabels = DatabaseApi.caregiverPtoLabels();
        $scope.arCollectionStatuses = DatabaseApi.arCollectionStatuses();
        $scope.arReasons = DatabaseApi.arReasons();
        $scope.arClaimStatuses = DatabaseApi.arClaimStatuses();
        $scope.statusChangeTos = DatabaseApi.statusChangeTos();
        $scope.statusChangeReasons = DatabaseApi.statusChangeReasons();
    }
    loadManagementSettings();
    /////////////

    $scope.importBillingData = function () {
        $scope.isLoading = true;
        toaster.pop('success', "Data is being synced, you'll receive an email when the process is done");
        var url = "agencies/" + $rootScope.agencyId +
            "/agency_members/" + $rootScope.agencyMemberId +
            "/import_agency_external_data";
        DatabaseApi.post(url).then(function (res) {
            $scope.isLoading = false;
        }, function (err) {
            $scope.isLoading = false;
        });
    }

    const getIdPattern = function (firstDigit, length) {
        let Xstring = '';
        for (let i = 1; i < length; i++) {
            Xstring = Xstring.concat('X');
        }
        return firstDigit + Xstring;
    }
    $scope.openSettingsModal = function (type, data, viewModeOnly = true) {
        var types = {
            'office': {
                template: 'office-setup-modal.html',
                controller: 'officeSetupModalCtrl',
                fields: "Office",
                get: getOffices
            },
            'contract': {
                template: 'contract-setup-modal.html',
                controller: 'contractSetupModalCtrl',
                fields: "ContractType",
                get: getContracts
            },
            'serviceCode': {
                template: 'service-code-setup-modal.html',
                controller: 'serviceCodeSetupModalCtrl',
                fields: "ServiceCode",
                get: getServiceCodes
            },
            'payer': {
                template: 'payer-setup-modal.html',
                controller: 'payerSetupModalCtrl',
                fields: "Payer",
                get: getPayers
            },
            'payrollCode': {
                template: 'payroll-code-setup-modal.html',
                controller: 'payrollCodeSetupModalCtrl',
                fields: "PayrollCode",
                get: getPayrollCodes
            },
            'payrollSetup': {
                template: 'payroll-setups-setup-modal.html',
                controller: 'payrollSetupsSetupModalCtrl',
                fields: "PayrollSetup",
                get: getPayrollSetups
            },
            'payRate': {
                template: 'payrate-setup-modal.html',
                controller: 'payRateSetupModalCtrl',
                fields: "PayRate",
                get: getPayRates
            },
            'statusChangeReason': {
                template: 'status-change-reason-setup-modal.html',
                controller: 'statusChangeReasonSetupModalCtrl',
                fields: "StatusChangeReason",
                get: DatabaseApi.getStatusChangeReasons
            },
            'statusChangeTo': {
                template: 'status-change-to-setup-modal.html',
                controller: 'statusChangeToSetupModalCtrl',
                fields: "StatusChangeTo",
                get: DatabaseApi.getStatusChangeTo
            },
            'arClaimStatus': {
                template: 'ar-claim-status-setup-modal.html',
                controller: 'arClaimStatusSetupModalCtrl',
                fields: "ClaimStatus",
                get: DatabaseApi.getArClaimStatuses
            },
            'arReason': {
                template: 'ar-reason-setup-modal.html',
                controller: 'arReasonSetupModalCtrl',
                fields: "Reason",
                get: DatabaseApi.getArReasons
            },
            'arCollectionStatus': {
                template: 'ar-collection-status-setup-modal.html',
                controller: 'arCollectionStatusSetupModalCtrl',
                fields: "CollectionStatus",
                get: DatabaseApi.getArCollectionStatuses
            },
            'caregiverPtoLabel': {
                template: 'caregiver-pto-labels-modal.html',
                controller: 'caregiverPtoLabelSetupModalCtrl',
                fields: "PtoLabel",
                get: DatabaseApi.getPtoLabels
            },
            'agencyHoliday': {
                template: 'agency-holiday-modal.html',
                controller: 'agencyHolidayModalCtrl',
                fields: "AgencyHoliday",
                get: getAgencyHolidays
            },
            'pto': {
                template: 'agency-pto-setting-modal.html',
                controller: 'agencyPTOSettingModalCtrl',
                fields: "",
                get: getPtoSettings
            },
            'displayIdSettings': {
                template: 'display-id-settings.html',
                controller: 'displayIdSettingsModalCtrl',
                fields: "",
                get: getDispalyIdsSettings
            },
            'branch': {
                template: 'agency-branch-setting-modal.html',
                controller: 'agencyBranchSettingModalCtrl',
                fields: "AgencyBranch",
                get: getAgencyBranches
            },
            'customField': {
                template: 'custom-field-setting-modal.html',
                controller: 'customFieldSettingModalCtrl',
                fields: "CustomField",
                get: getAgencyCustomFields
            },
            'cityTaxCode': {
                template: 'city-tax-code-modal.html',
                controller: 'cityTaxCodeCtrl',
                fields: 'CityTaxCode',
                get: getCityTaxCodes
            },
            'billingIssuesTemplate': {
                template: 'billing-issues-template-setup-modal.html',
                controller: 'billingIssuesTemplateSetupModalCtrl',
                fields: 'BillingIssuesTemplate',
                get: getBillingIssuesTemplatesSettings
            }
        };

        var selected = types[type];

        if (!selected) {
            toaster.pop('warning', 'Something went wrong');
            return;
        }


        var newScope = $scope.$new();
        newScope.viewModeOnly = viewModeOnly;

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/' + selected.template,
            controller: selected.controller,
            scope: newScope,
            size: 'lg',
            resolve: {
                fields: function () { return DatabaseApi.formFields(selected.fields); },
                data: function () { return data; }
            }
        });

        modalInstance.result.then(function (res) {
            selected.get().then(() => {
                loadManagementSettings();
            });
        }, function () {
        });

    }

    $scope.goToItem = function (item) {
        if (!item) {
            return;
        }
        $scope.navigationItems.active = item.id;
        generalUtils.scrollToElement(item.id)
    };

    $scope.updateSecuritySettings = function () {
        var securitySettingsObj = {
            agencyIpSecuritySetting: {}
        };
        var ipAddressesWhitelist = $rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist;
        switch ($rootScope.visitSettings.agencyIpSecuritySetting.type) {
            case 'Open': {
                $rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist = [];
                break;
            }
            case 'WhitelistOnly': {
                if (!ipAddressesWhitelist) {
                    ipAddressesWhitelist = [];
                    return;
                }
                else if (ipAddressesWhitelist && ipAddressesWhitelist.length > 0) {
                    securitySettingsObj.agencyIpSecuritySetting.ipAddressesWhitelist = ipAddressesWhitelist;
                    break;
                }
            }
        }
        securitySettingsObj.agencyIpSecuritySetting.type = $rootScope.visitSettings.agencyIpSecuritySetting.type;

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/ip_security_settings', securitySettingsObj).then(function (res) {
            toaster.pop('success', "Security settings updated successfully");
        }, function (err) {
            toaster.pop('error', "Could not update security settings");
        });
    }

    $scope.validateIPAddress = function (ipaddress) {
        if (/^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$|\/\d{1,2})){4}$/.test(ipaddress) || /^::1$/.test(ipaddress)) {
            $scope.updateSecuritySettings();
        } else {
            if ($rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist) {
                $rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist.pop();
                toaster.pop('error', "You have entered an invalid IP address");
            }
        }
    }

    $scope.onIPAddressRemove = function () {
        if ($rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist.length === 0) {
            toaster.pop('error', "Cannot set whitelist with no specified ip's, please switch to Open mode");
            return;
        }
        $scope.updateSecuritySettings();
    }
});

