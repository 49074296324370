"use strict";
angular
  .module("dashboard")
  .component("caregiversTrainingCenterOverviewBundles", {
    templateUrl: "admin/views/caregivers-training-center-overview-bundles.html",
    bindings: {
      bundles: "<",
      isFetching: "<",
      activeBundleId: "<",
      onClickBundle: "&",
      onClickBoostViews: "&",
    },
    controller: function ($timeout) {
      const GREEN_COLOR = "#3077db";
      const RED_COLOR = "#FF5684";
      const GRAY_COLOR = "#E5E6E8";
      const BLUE_COLOR = "#46A8FF";

      this.labels = [
        "Has certificate",
        "Has started",
        "Not started",
        "Has exempt",
      ];

      // Chart.js object array, so they can be cleared when new ones are drawn
      this.charts = [];

      this.hasNext;
      this.hasPrev;
      this.itemWidth;
      this.resizeListener;
      this.hasCarouselInit = false;
      this.disableInteraction = false;

      this.$onChanges = () => {
        if (this.bundles) {
          this.charts.forEach((chart) => chart.destroy());
          this.setBundlesPies();
        }

        if (!this.hasCarouselInit && document.querySelector(".carousel-items") !== null) {
          this.hasCarouselInit = true;
          this.carouselInit();
        }
      };

      this.$onDestroy = function () {
        this.onCarouselDestroy();
      };

      this.carouselInit = () => {
        this.evaluateItemWidth();
        this.evaluateArrowsAvailability();

        this.resizeListener = window.addEventListener("resize", e => {
          this.evaluateItemWidth();
          this.evaluateArrowsAvailability();
        });
      };

      this.evaluateItemWidth = () => {
        const itemElem = document.querySelector(".carousel-item");

        if (itemElem !== null) {
          this.itemWidth = itemElem.getBoundingClientRect().y;
        }
      };

      this.evaluateArrowsAvailability = () => {
        const itemsElem = document.querySelector(".carousel-items");

        if (itemsElem === null) {
          console.log(".carousel-items is not found. skipping arrows evaluation");
          return;
        }

        if (this.itemWidth === undefined) {
          console.log("carousel-item width is undefined. skipping arrows evaluation");
          return;
        }

        const containerWidth = itemsElem.getBoundingClientRect().width;
        const containerOffset = itemsElem.scrollLeft;
        this.hasPrev = containerOffset > 0;
        this.hasNext = (containerWidth + containerOffset) < this.itemWidth * this.bundles.length;
        this.disableInteraction = false;
      };

      this.onCarouselDestroy = () => {
        if (this.resizeListener !== undefined) {
          this.resizeListener.removeEventListener();
        }
      };

      this.carouselNext = () => {
        if (this.hasNext && !this.disableInteraction) {
          this.pushCarouselScroll(this.itemWidth);
        }
      };

      this.carouselPrev = () => {
        if (this.hasPrev && !this.disableInteraction) {
          this.pushCarouselScroll(-this.itemWidth);
        }
      };

      this.carouselTo = (bundle) => {
        const index = this.bundles.findIndex(({ bundleId }) => bundleId === bundle.bundleId);

        if (index !== -1) {
          this.scrollCarouselTo(itemWidth * index);
        }
      };

      this.pushCarouselScroll = (amount) => {
        const itemsElem = document.querySelector(".carousel-items");

        if (itemsElem !== null) {
          this.scrollCarouselTo(itemsElem.scrollLeft + amount);
        }
      };

      this.scrollCarouselTo = (amount) => {
        const itemsElem = document.querySelector(".carousel-items");

        if (itemsElem !== null) {
          itemsElem.scrollTo({
            left: amount,
            behavior: "smooth"
          });

          this.disableInteraction = true;
          $timeout(() => this.evaluateArrowsAvailability(), 250);
        }
      };

      this.handleClickBundle = (bundle) => {
        this.onClickBundle({ bundle });
      };

      this.handleClickBoostViews = ($event, bundle) => {
        $event.stopPropagation();
        this.onClickBoostViews({ bundle });
      };

      this.setBundlesPies = () => {
        setTimeout(() => this.bundles.forEach(this.setBundlePie), 0);
      };

      this.setBundlePie = (bundleStat) => {
        const canvas = document.getElementById(
          "bundle-chart-" + bundleStat.bundleId
        );
        const ctx = canvas.getContext("2d");

        this.charts.push(new Chart(ctx, this.getPieConfigByBundleStats(bundleStat)));
      };

      this.getPieConfigByBundleStats = ({ bundleName, statistics }) => {
        return {
          type: "horizontalBar",
          plugins: [ChartDataLabels],
          data: {
            datasets: [
              {
                data: [
                  statistics.numHasCertificate,
                  statistics.numStarted,
                  statistics.numNotStarted,
                  statistics.numExempt
                ],
                backgroundColor: [
                  GREEN_COLOR,
                  BLUE_COLOR,
                  RED_COLOR,
                  GRAY_COLOR,
                ],
              },
            ],
            labels: this.labels,
          },
          options: {
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 25,
                top: 20,
                bottom: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                anchor: "end",
                padding: 5,
                align: "end",
                clamp: true,
                offset: 10,
              },
            },
          },
        };
      };
    },
  });
