/**
 * ORIGINAL FILE = contractTypeService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
export class ContractTypeService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    contractTypes = undefined;
    constructor(DatabaseApi2, endpoint, $rootScope) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
    }
    /**
     * Get a list of agency contractTypes
     */
    async getContractTypes(params = {
        onlyActives: false,
        refetch: false
    }) {
        const url = this.endpoint({
            path: "agencies/:agencyId/contract_types",
            params: {
                agencyId: this.$rootScope.agencyId
            },
        });
        if (!params.refetch && this.contractTypes !== undefined) {
            return params.onlyActives ? this.contractTypes.filter(row => row.active) : this.contractTypes;
        }
        return this.DatabaseApi2.get(url).then((res) => {
            this.contractTypes = res.data.contractTypes;
            return params.onlyActives ? this.contractTypes.filter(row => row.active) : this.contractTypes;
        });
    }
    ;
}
angular.module("dashboard").service("contractTypeService", ContractTypeService);
