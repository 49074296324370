"use strict";

angular
  .module("dashboard")
  .controller("testCalendarCtrl", function ($scope, mfModal, $uibModal, $timeout, Currency) {
    
    $scope.init = false;

    $timeout(() => $scope.init = true, 750);

    const fakeItems = [
      {
        key: 1,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 8),
        type: "UNSTAFFED_VISIT",
        payload: {
          visitInstanceId: 1,
            billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
            startTime: "2021-02-09T09:04:18.249Z",
            endTime: "2021-02-09T12:05:18.249Z",
            billOnPreviousDay: false,
        },
      },
      {
        key: 2,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 9),
        type: "DELETED_VISIT",
        payload: {
          visitInstanceId: 2,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 3,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 10),
        type: "MISSED_VISIT",
        payload: {
          visitInstanceId: 3,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 4,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 10),
        type: "UNSTAFFED_VISIT",
        payload: {
          visitInstanceId: 4,
          billingStatus: "NO_AUTHORIZATION", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 5,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 11),
        type: "UNSTAFFED_VISIT",
        payload: {
          visitInstanceId: 5,
          billingStatus: "INVALID_AUTHORIZATION", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 6,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 14),
        type: "BROADCASTED_VISIT",
        payload: {
          visitInstanceId: 6,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          broadcastStatus: "UNSTAFFED", // UNSTAFFED / PENDING_ACCEPTANCE / REQUIRE_CHANGES / AWAITING_ASSIGNMENT
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 7,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 15),
        type: "BROADCASTED_VISIT",
        payload: {
          visitInstanceId: 7,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          broadcastStatus: "PENDING_ACCEPTANCE", // UNSTAFFED / PENDING_ACCEPTANCE / REQUIRE_CHANGES / AWAITING_ASSIGNMENT
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 8,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 16),
        type: "BROADCASTED_VISIT",
        payload: {
          visitInstanceId: 8,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          broadcastStatus: "REQUIRE_CHANGES", // UNSTAFFED / PENDING_ACCEPTANCE / REQUIRE_CHANGES / AWAITING_ASSIGNMENT
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 9,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 16),
        type: "BROADCASTED_VISIT",
        payload: {
          visitInstanceId: 9,
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          broadcastStatus: "AWAITING_ASSIGNMENT", // UNSTAFFED / PENDING_ACCEPTANCE / REQUIRE_CHANGES / AWAITING_ASSIGNMENT
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 10,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 18),
        type: "ASSIGNED_VISIT",
        payload: {
          caregiver: null,
          visitInstanceId: 10,
          status: "PAID_TIME_OFF", // UNSTAFFED / DELETED / MISSED / BROADCASTING / ASSIGNED / PAID_TIME_OFF
          billingStatus: "VALID", // VALID / NO_AUTHORIZATION / INVALID_AUTHORIZATION
          broadcastStatus: null, // UNSTAFFED / PENDING_ACCEPTANCE / REQUIRE_CHANGES / AWAITING_ASSIGNMENT
          startTime: "2021-02-09T09:04:18.249Z",
          endTime: "2021-02-09T09:05:18.249Z",
          clockInTime: "2021-02-09T09:04:17.249Z",
          clockOutTime: "2021-02-09T09:05:19.249Z",
          billOnPreviousDay: false,
        },
      },
      {
        key: 11,
        checked: false,
        date: JSJoda.LocalDate.of(2021, 2, 22),
        type: "PAID_TIME_OFF",
        payload: {
          visitInstanceId: 11,
        },
      },
    ];

    const extraColumns = [
      {
        heading: "Custom",
        className: "extra",
        // include: "../admin/views/calendar-week-aggregation-column.html",
        html: (items) => `total <strong>${items.length}</strong> items`,
      },
    ];

    const fakeActions = [
      {
        label: "New visit",
        invoke: (selection) => console.log("TODO new visit", selection),
      },
      {
        label: "New note",
        invoke: (selection) => console.log("TODO new note", selection),
      },
      {
        label: "Set on vacation",
        invoke: (selection) => console.log("TODO set on vacation", selection),
      },
    ];

    const handleClickItem = (item) => {
      console.log("item", item);
    };

    const testOptionCallback = () => {
      console.log("testOptionCallback");
    }

    const handleClickWarningModal = () => {
      const modal = mfModal.create({
        subject: "Delete item",
        options: [
          { id: 1, label: "Delete selected items (4)" },
          { id: 2, label: "Delete selected items going forward" },
          { id: 3, label: "Delete selected items until a specific date"}
        ],
        message: `Are you sure you want to delete 4 visits?\n All of your deleted visits will be saved on your calendar.`,
        layoutOrder: ["message", "options"],
        cancelLabel: "Cancel",
        confirmLabel: "Delete",
        onConfirm: ({ selectedOption }) => {
          modal.setLoading(true);
          $timeout(() => {
            if (selectedOption) {
              modal.close();
              return;
            }

            modal.update({
              isLoading: false,
              message: "Please select an option!",
            });
          }, 1000);
        },
        onCancel: () => console.log("Canceled"),
        onComplete: () => console.log("Complete"),
      });
    };

    function handleClickVisitBroadcastOptionsModal() {
      $uibModal.open({
        templateUrl: "admin/views/visit-broadcast-options-modal.html",
        size: "md",
        controller: "visitBroadcastOptionsModalCtrl",
        windowClass: "visit-broadcast-options-modal center-center",
        resolve: {
            onSubmit: () => (x) => console.log("data", x),
            patientName: () => "Johan Brey"
        }
      });
    }

    $scope.historyTables = [{ tableName: 'patient', recordId: 19 }];

    $scope.items = fakeItems;
    $scope.actions = fakeActions;
    $scope.extraColumns = extraColumns;
    $scope.handleClickItem = handleClickItem;

    $scope.handleClickWarningModal = handleClickWarningModal;
  });
