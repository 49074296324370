app.controller('sendEncodedPdfModalCtrl', function ($scope, DatabaseApi, $rootScope, toaster, $uibModalInstance) {

    $scope.req = {
        attachmentName: $scope.attachmentName
    };
    
    const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' +
        $rootScope.agencyMemberId + '/send_encoded_pdf/' + $scope.type;

    $scope.sendPdf = () => {
        $scope.isLoading = true;
        const encodedPdf = $scope.type === 'email' ? $scope.encodedPdf.split("base64,")[1] :
        $scope.encodedPdf
        let reqObj = Object.assign($scope.req, {
            encodedPdf: encodedPdf,
        });
        DatabaseApi.post(url, reqObj).then(function () {
            toaster.pop('success', "Success", "Pdf sent successfully");
            $scope.closeModal();
        }, function () {
            toaster.pop('error', 'Failed, please try again');
            $scope.isLoading = false;
        });
    }

    $scope.closeModal = () => {
        $uibModalInstance.close();
    };
});
