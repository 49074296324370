"use strict";
app.controller("entityGeneralNotesModalCtrl",function (
  $rootScope,
  $scope,
  $uibModal,
  DatabaseApi,
  entityNotesModalService,
  SweetAlert,
  toaster,
  $timeout,
  noteService,
  wildcard,
  entityNoteService,
  noteConsts
) {
  $scope.agencyId = $rootScope.agencyId;
  $scope.isAdmin = $rootScope.user.admin;
  $scope.modalData = entityNotesModalService.notesData;
  $scope.modalData.activeSubjects = [];
  $scope.isCalendar = false;
  $scope.title = `${$scope.modalData.notesType} Notes`;
  $scope.selectedNoteIds = [];
  // Impossible, but handled
  if ($scope.modalData === undefined) {
    console.error("Notes modal data not available");
    $timeout(() => {
      toaster.pop("error", "Something went wrong", "Please try again");
      entityNotesModalService.closeModal();
    });
    return;
  }

  $scope.notesDisplay = [];
  $scope.notesType = $scope.modalData.notesType;
  $scope.isCaregiver = $scope.notesType.startsWith('Caregiver');

  $scope.subjects = (()=>{
    const isCaregiver = $scope.isCaregiver;
    const isPatient = $scope.notesType == "Patient" || $scope.notesType == "PatientCalendar";
      return DatabaseApi.agencyNoteSubjectsWithNoteTypes(true).filter(
        subject=>(subject.showOnCaregiverProfile && isCaregiver) || (subject.showOnPatientProfile && isPatient)
      );
  })();

  $scope.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();
  $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
  $scope.caregiversMap = DatabaseApi.caregivers();
  $scope.patientsMap = DatabaseApi.patients();

  fetchNotes();

  function filterNotes() {
    if ($scope.modalData.activeSubjects.length === 0) {
      $scope.notesDisplay = $scope.notes;
    } else {
      const subjectIds = [];
      const noteTypes = [];
      $scope.modalData.activeSubjects.forEach(activeSubject =>{
        if (typeof activeSubject.id === "string" && activeSubject.id.startsWith("nt_")) {
          noteTypes.push(activeSubject.id.substr(3));
        }else{
          subjectIds.push(activeSubject.id);
        }
      });
      
      $scope.notesDisplay = $scope.notes.filter(
        (note) => (note.subject ? subjectIds.includes(note.subject.id) : false) || noteTypes.includes(note.noteType)
      );
    }
    $scope.notesDisplay.forEach(note => {
      note.checked = false;
    });
    $scope.selectedNoteIds = [];
  }

  $scope.handleCheckNote = (note) => {
    note.checked = !note.checked;
    if (note.checked) {
      $scope.selectedNoteIds.push(note.id);
    } else {
      $scope.selectedNoteIds = $scope.selectedNoteIds.filter(noteId => noteId !== note.id);
    }
  };

  $scope.exportSelectedNotes = () => {
    const noteIds = $scope.notesDisplay.filter(note => note.checked).map(note => note.id);
    $scope.exportNotes(noteIds);
  };

  $scope.exportNotes = (noteIds) => {
    if (!noteIds || !noteIds.length) {
      return;
    }
    let baseUrl = ""
    if ($scope.notesType === "Caregiver") {
      baseUrl = "agencies/:agencyId/agency_members/:agencyMemberId/caregiver/:caregiverId/export_notes";
    } else if ($scope.notesType === "Patient") {
      baseUrl = "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/export_notes";
    } else {
      return;
    }
    const url = wildcard(
      baseUrl,
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.modalData.profileId
    );
    const body = {
      noteIds: noteIds
    };
    DatabaseApi.post(url, body).then((res) => {
      $scope.notesDisplay.forEach(note => {
        note.checked = false;
      });
      window.open(res.data.fileUrl).focus();
    }, (err) => {
      toaster.pop("error", "Something went wrong", "Failed exporting notes");
    });
  };

  $scope.sendSelectedNotes = (sendType) => {
    const notes = $scope.notesDisplay.filter(note => note.checked);
    switch(sendType){
      case 'fax':
        $scope.sendNotesByFax(notes);
        break;
      case 'email':
        $scope.sendNotesByEmail(notes)
        break;
    }
    
  };

  $scope.sendNotesByEmail = (notes) => {
    const documents = notes.map( note => {
        return {
          id: note.id, 
          title: note.subjectText
        }
      });
    
    $uibModal.open({
        templateUrl: 'admin/views/email-modal.html',
        size: 'lg',
        resolve: {
            documents: function () { return documents; },
            patientId: function () { return $scope.modalData.profileId; },
            patientFullName: function () { 
              let displayName = "";
              if($scope.isCaregiver) {
                displayName = $scope.caregiversMap[$scope.modalData.profileId].displayName
              } else {
                displayName = $scope.patientsMap[$scope.modalData.profileId].displayName
              }
              return displayName; 
            },
            docsType: function () { return ('notes'); },
            isCaregiver: function(){ return $scope.isCaregiver}
        },
        controller: 'emailModalCtrl'
    });
  };

  $scope.sendNotesByFax = (notes) => {
    const documents = notes.map( note => {
      return {
        id: note.id, 
        title: note.subjectText
      }
    });
    $uibModal.open({
        templateUrl: 'admin/views/fax-modal.html',
        size: 'lg',
        resolve: {
            documents: function () { return documents; },
            patientId: function () { return $scope.modalData.profileId; },
            docsType: function () { return ('notes'); },
            isCaregiver: function(){ return $scope.isCaregiver}
        },
        controller: 'faxModalCtrl'
    });
  };

  $scope.subjectEvents = {
    onSelectionChanged: () => {
      filterNotes();
    },
  }

  function fetchNotes() {
    $scope.isLodaing = true;
    let serviceUrl; 

    switch ($scope.notesType) {
      case "Caregiver":
        serviceUrl = noteService.getCaregiverNotes($scope.modalData.profileId);
        break;
      case "Patient":
        serviceUrl = noteService.getPatientNotes($scope.modalData.profileId);
        break;
      case "CaregiverCalendar":
        $scope.isCalendar = true;
        $scope.title = `Caregiver Notes - ${moment($scope.modalData.calendarDay).format('L')}`;
        serviceUrl = noteService.getCaregiverNotes($scope.modalData.profileId);
        $scope.modalData.activeSubjects.push({id: "nt_calendar"});
        break;
      case "PatientCalendar":
        $scope.isCalendar = true;
        $scope.title = `Patient Notes - ${moment($scope.modalData.calendarDay).format('L')}`;
        serviceUrl = noteService.getPatientNotes($scope.modalData.profileId);
        $scope.modalData.activeSubjects.push({id: "nt_calendar"});
        break;
    }

    return serviceUrl.then((data) => {
        // Mapping agency memebers names
        mapNotes(data);
        if ($scope.isCalendar) {
          $scope.notes = $scope.notes.filter(
            (note) => (note.calendarDate === $scope.modalData.calendarDay) 
          );
        }
        filterNotes();
      })
      .catch(() => toaster.pop("error", "Failed to fetch notes"))
      .finally(() => ($scope.isLodaing = false));
  }

  const mapNotes = (data) => {
    $scope.notes = data.map((note) => {
      note.isBulk = note.noteType === "bulk";
      note.isProfileNote = note.isBulk || note.noteType === "profile_note";
      note.subjectText = note.isProfileNote ? note.subject.text : noteConsts.NoteTypesTranslations[note.noteType];
      note.noteRichText = note.predefinedAnswerText && note.noteRichText ?
      entityNoteService.setRichNoteText(note.noteRichText) : note.noteRichText;
      return {
        ...note,
        createdBy:
          $scope.agencyMembers[note.createdBy] !== undefined
            ? $scope.agencyMembers[note.createdBy].name
            : ($scope.agencyMembersNames[note.createdBy] !== undefined
              ? ($scope.agencyMembersNames[note.createdBy] + " (Inactive)")
              : "Deleted Coordinator"),
        createdByPhotoURL:
          $scope.agencyMembers[note.createdBy] !== undefined
            ? $scope.agencyMembers[note.createdBy].photoUrl
            : undefined
      }
    });
  }

  $scope.closeModal = function () {
    entityNotesModalService.closeModal();
  };

  $scope.closeParentModal = () => {
    $scope.$parent.$ctrl.changeState(undefined);
  }

  $scope.subjectSelectionSettings = {
    closeOnBlur: true,
    displayProp: "text",
    scrollable: true,
    scrollableHeight: "400px",
    smartButtonMaxItems: 4,
    enableSearch: true,
    smartButtonTextConverter: function (itemText, originalItem) {
      return itemText;
    },
    styleActive: true,
  };

  $scope.openCreateNewNote = async () => {
    const preSelected = {
        caregiverId: $scope.notesType === "Caregiver" ? $scope.modalData.profileId : undefined,
        patientId: $scope.notesType === "Patient" ? $scope.modalData.profileId : undefined
    }

    const newScope = $scope.$new();
    newScope.preSelected = preSelected;
 
    const modalInstance = $uibModal.open({
        templateUrl: 'admin/views/create-note-modal.html',
        size: 'md',
        controller: 'createNoteModalCtrl',
        scope: newScope,
        windowClass: "modal modal-slide-in-right create-note-container"
    });

    modalInstance.result.then(function (res) {
       fetchNotes();
    }, function () { // Dismissed
       fetchNotes();
    });
  };


  $scope.openEditNote = async (noteId) => {
    const noteDetails = await noteService.getNoteDetails(noteId);
    const note = {
        ...noteDetails
    }

    if (noteDetails.caregiverId !== null) {
      note.caregiver = $scope.caregiversMap[noteDetails.caregiverId];
    }
    if (noteDetails.patientId !== null) {
      note.patient = $scope.patientsMap[noteDetails.patientId];
    }

    const newScope = $scope.$new();
    newScope.note = note;
    newScope.editMode = true;
 
    const modalInstance = $uibModal.open({
        templateUrl: 'admin/views/create-note-modal.html',
        size: 'md',
        controller: 'createNoteModalCtrl',
        scope: newScope,
        windowClass: "modal modal-slide-in-right create-note-container"
    });

    modalInstance.result.then(function (res) {
       fetchNotes();
    }, function () { // Dismissed
       fetchNotes();
    });
  };

  $scope.handleDeleteNote = (noteId) => {
    SweetAlert.swal({
      title: "Remove note",
      text: "Are you sure you want to remove this note? ",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, remove",
      closeOnConfirm: true,
      closeOnCancel: true
  }, (isConfirm) => {
      if (isConfirm) {
        noteService.deleteNote(noteId, $rootScope.agencyId, $rootScope.agencyMemberId).then(res => {
            toaster.pop(
            "success",
            "Success",
            "Delete note"
            );
            fetchNotes();
            $scope.closeModal();
        }).catch(err => {
            toaster.pop(
            "error",
            "Something went wrong",
            "Failed to Delete note");
        });            
      }
  });
  };

  $scope.showNoteComments = async (note) => {
    note.showComments = !note.showComments;
    if (note.showComments) {
      fetchNoteComments(note);
    }
  };

  const fetchNoteComments = (note) => {
    noteService
      .getNoteComments(note.id)
      .then((res) => {
        note.comments = mapComments(res);
      })
      .catch((err) => {
        toaster.pop("warning", "Something went wrong");
      });
  };

  $scope.handleCommentsChange = (note) => {
    fetchNoteComments(note);
  }

  const mapComments = (comments) => {
    return comments.map((comment) => ({
      ...comment,
      isUserAuthor: comment.createdBy == $rootScope.agencyMemberId,
      createdByName:
        $scope.agencyMembers[comment.createdBy] !== undefined
          ? $scope.agencyMembers[comment.createdBy].name
          : $scope.agencyMembersNames[comment.createdBy] !== undefined
          ? $scope.agencyMembersNames[comment.createdBy] + " (Inactive)"
          : "Deleted Coordinator",
      createdByPhotoURL:
        $scope.agencyMembers[comment.createdBy] !== undefined
          ? $scope.agencyMembers[comment.createdBy].photoUrl
          : undefined,
    }));
  };

  $scope.fetchNoteTitle = (note) => {
    if(note.title === undefined){
      note.title = "";
      noteService
      .getNoteTitle(note.id, note.noteType)
      .then((res) => {
        note.title = res.title;
      })
      .catch((err) => {
        delete note.title;
        toaster.pop("warning", "Something went wrong");
      });
    }
  }

  $scope.getNoteTitle = (note) => {
    return note.title? note.title : 'loading...';
  }

});
