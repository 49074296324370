app.controller('exportDutySheetDataModal', function($scope, $rootScope, DatabaseApi, toaster, wildcard) {
    $scope.fromDate = new Date($scope.$resolve.fromDate);
    $scope.toDate = new Date($scope.fromDate);
    $scope.toDate.setDate($scope.fromDate.getDate() + 6);
    $scope.exporting = false;
    $scope.caregivers = DatabaseApi.caregivers();

    $scope.exportData = (type) => {
        $scope.exporting = true;
        const from = LocalDate.from(nativeJs($scope.fromDate));
        const to = LocalDate.from(nativeJs($scope.toDate));
        const url = wildcard(
            `agencies/:agencyId/patients/:patientId/patient_document_version/:patientDocumentVersionId/generate_duty_sheets_csv_by_dates?from=${from}&to=${to}`,
            $rootScope.agencyId,
            $scope.$resolve.patientId,
            $scope.$resolve.patientDocumentVersionId
        );
        DatabaseApi.get(url).then(({ data }) => {
            switch (type) {
                case "CSV":
                    generateToCsv(data);
                    break;
                default: {
                    break;
                }
            }  
            $scope.exporting = false;
        }).catch(error => {
            toaster.pop("error", "Something went wrong");
            console.error(error);
            $scope.exporting = false;
        });
    };

    const generateToCsv = (data) => {
        const relevantItems = data.planOfCare.docItems.filter(item => item.itemType === "check");
        const days = [];
        for (const day = moment($scope.fromDate); day.isSameOrBefore(moment($scope.toDate)); day.add(1, 'days')) {
            days.push(day.format('YYYY-MM-DD'));
        }

        const titles = ["Task Name", "Task Code"];
        days.forEach(day => {
            titles.push(day);
        })
        const rows = [titles];
        for (const task of relevantItems) {
            const row = [
                task.label,
                task.id
            ];

            for (const day of days) {
                const dayOfWeek = moment(day).format('dddd').toUpperCase();
                const dayDutySheets = data.dutySheets.filter(
                    (v) => v.startDate.toString() === day
                );

                if (dayDutySheets === undefined || dayDutySheets.length === 0) {
                    row.push("");
                    continue;
                }

                const frequencyPerWeek = task.frequencyPerWeek;
                const everyVisit = !task.days && !(frequencyPerWeek && task.visitPerWeek) && task.isRequired;
                const hasDays = task.days && task.days.includes(dayOfWeek);
                const asNeeded = task.asNeeded = !task.isRequired && !(frequencyPerWeek && task.visitPerWeek) && !task.days;

                if (!(everyVisit || asNeeded || hasDays || frequencyPerWeek)) {
                    continue;
                }

                const caregiversDuties = [];
                for (const dutySheet of dayDutySheets) {
                    const caregiver = $scope.caregivers[dutySheet.caregiverId];
                    const answer = dutySheet.answers.find(answer => answer.questionId === task.id);
                    const notes = dutySheet.answers.find(answer => answer.questionId === (task.id + 1000));
                    caregiversDuties.push(`${caregiver.displayName} ${answer !== undefined && answer.answer.includes("Done") ? "Fulfilled" : "Not Fulfilled"}.${notes !== undefined ? ` Notes: ${notes.answer}` : ''}`);
                }
                row.push('"' + caregiversDuties.join(", ") + '"');
            }
            rows.push(row);
        }
        let csvContent = "";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        const universalBOM = "\uFEFF";
        const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);

        const from = LocalDate.from(nativeJs($scope.fromDate));
        const to = LocalDate.from(nativeJs($scope.toDate));
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${from}-${to}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    $scope.selectedRangeWeekOrLess = () => {
        return moment($scope.toDate).diff($scope.fromDate, 'd') <= 7;
    }

    $scope.isExportPDFEnabled = () => {
        return !$scope.exporting && $scope.selectedRangeWeekOrLess();
    }

    $scope.exportPDF = () => {
        const from = LocalDate.from(nativeJs($scope.fromDate));
        const to = LocalDate.from(nativeJs($scope.toDate));
        const url = wildcard(
            `agencies/:agencyId/patients/:patientId/patient_document_version/:patientDocumentVersionId/generate_duty_sheets_pdf_by_dates?from=${from}&to=${to}`,
            $rootScope.agencyId,
            $scope.$resolve.patientId,
            $scope.$resolve.patientDocumentVersionId
        );
    
        $scope.exporting = true;
        DatabaseApi.get(url).then((res) => {
            window.open(res.data.fileUrl, "_blank");
            $scope.exporting = false;
        }).catch(error => {
            toaster.pop("error", "Something went wrong", "Failed to export patient duty sheets to pdf");
            console.error(error);
            $scope.exporting = false;
        });
    }
});