"use strict";

angular
    .module("dashboard")
    .factory("walkthroughsService",
        ($rootScope, $uibModal, DatabaseApi, wildcard, Storage) => (
            {
                getWalkthroughs: () => {
                    const { agencyId, agencyMemberId } = $rootScope;
                    const endpoint = wildcard(
                        "agencies/:agencyId/agency_members/:agencyMemberId/get_agency_member_walkthroughs",
                        agencyId,
                        agencyMemberId
                    );

                    return DatabaseApi.get(endpoint);
                },
                updateWalkthroughPosition: async (walkthroughColumnName, position) => {
                    const { agencyId, agencyMemberId } = $rootScope;
                    const endpoint = wildcard(
                        "agencies/:agencyId/agency_members/:agencyMemberId/walkthroughs/:walkthroughColumnName/set_agency_member_walkthrough/:position",
                        agencyId,
                        agencyMemberId,
                        walkthroughColumnName,
                        position
                    );

                    return DatabaseApi.patch(endpoint);
                },
                handleWalkthroughs: async (walkthroughName) => {
                    if ($rootScope.walkthroughModalInstanceOpened) {
                        return;
                    }
                    $rootScope.walkthroughModalInstanceOpened = true;

                    let walkthroughColumnName;
                    let walkthroughElements = [];

                    switch (walkthroughName) {
                        case "caregiversNotes":
                            walkthroughColumnName = 'caregiversPatientsNotes';
                            walkthroughElements = [
                                {
                                    src: "/step1.png",
                                    alt: "step 1"
                                }, {
                                    src: "/step2.png",
                                    alt: "step 2"
                                }, {
                                    src: "/step3.png",
                                    alt: "step 3"
                                }, {
                                    src: "/step4.png",
                                    alt: "step 4"
                                }, {
                                    src: "/step5.png",
                                    alt: "step 5"
                                }, {
                                    src: "/step6.png",
                                    alt: "step 6"
                                }
                            ];
                            break;
                        case "patientsNotes":
                            walkthroughColumnName = 'caregiversPatientsNotes';
                            walkthroughElements = [
                                {
                                    src: "/step1.png",
                                    alt: "step 1"
                                }, {
                                    src: "/step2.png",
                                    alt: "step 2"
                                }, {
                                    src: "/step3.png",
                                    alt: "step 3"
                                }, {
                                    src: "/step4.png",
                                    alt: "step 4"
                                }, {
                                    src: "/step5.png",
                                    alt: "step 5"
                                }, {
                                    src: "/step6.png",
                                    alt: "step 6"
                                }
                            ];
                            break;
                        default:
                            return;
                    }

                    let { walkthroughs } = Storage.getObject('walkthroughs');
                    if (walkthroughs === undefined) {
                        return;
                    }
                    if (Object.keys(walkthroughs).length === 0) {
                        walkthroughs = { [walkthroughColumnName]: 0 };
                        Storage.setObject("walkthroughs", { walkthroughs });
                    }
                    const walkthroughElementPos = walkthroughs[walkthroughColumnName];
                    if (walkthroughElementPos === undefined) {
                        return;
                    }
                    if (walkthroughElementPos === walkthroughElements.length) {
                        return;
                    }
                    $rootScope.walkthroughModalInstance = $uibModal.open({
                        templateUrl: "admin/views/walkthrough-swiper-modal.html",
                        controller: "walkthroughSwiperModalCtrl",
                        windowClass: "walkthrough",
                        resolve: {
                            modalData: {
                                walkthroughName,
                                walkthroughColumnName,
                                walkthroughElements,
                                walkthroughElementPos
                            }
                        }
                    });
                    $rootScope.walkthroughModalInstance.result.then(function (res) {
                        $rootScope.walkthroughModalInstanceOpened = false;
                    }, function () {
                        $rootScope.walkthroughModalInstanceOpened = false;
                    });
                }
            }
        )
    )