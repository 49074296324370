app.controller('patientContractModalCtrl', function(
    $scope,
    $rootScope,
    Consts,
    $http,
    DatabaseApi,
    $uibModalInstance,
    patient,
    codes,
    contracts,
    toaster,
    PatientModalService
){

    window.scrollTo(0,0);
    $scope.patient = patient;

    $scope.codes = codes;
    $scope.contracts = contracts.filter(contract => contract.active);

    $scope.contract = {
        contractType: null,
        serviceCodeId: null,
        isPrimary:  null,
        altPatientId:  null,
        startDate: new Date($scope.patient.createdAt),
        endDate: null,
        startOfCareDate: null,
        notes: null,
        placementId: null
    };

    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };

    $scope.cal = {
        startDate: false,
        endDate: false
    };

    $scope.setContractDate = (date, dateField) => {
        $scope.contract[dateField] = date ? date : null;
    };

    const dateToLocalDate = (date) => {
        if (date) {
            var localDateObj = JSJoda.LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
            return localDateObj.format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd"));
        } else {
            return null;
        }
    };

    $scope.setContractId = function(){
        $scope.contract.contractType = parseInt($scope.contract.contractObj);
    };

    $scope.setServiceCodeId = function(){
        $scope.contract.serviceCodeId = parseInt($scope.contract.serviceCodeObj);
    };

    const getAddContractBody = async () => {
        if ($scope.contract.contractType === null) {
            return {errorMessage: "Please select contract"};
        }
        if (!$scope.contract.startDate && $scope.contract.endDate) {
            return {errorMessage: "Can't create end date without start date"};
        }
        if ($scope.contract.startDate && $scope.contract.endDate) {
            const startDateMoment = moment($scope.contract.startDate);
            const endDateMoment = moment($scope.contract.endDate);
            if (endDateMoment.isBefore(startDateMoment)) {
                return {errorMessage: "End date can't be before start date"};
            }
        }

        if ($scope.contract.medicaidNumber !== null && $scope.contract.medicaidNumber !== undefined && $scope.contract.medicaidNumber !== '') {
            const isUnique = await PatientModalService.checkForDuplicateMedicaidNumber($scope.patient.id, $scope.contract.medicaidNumber);
            if (!isUnique) {
                return {errorMessage: "Medicaid ID already exists"};
            };
        }

        let body = {};
        Object.assign(body, $scope.contract);
        body.startDate = dateToLocalDate(body.startDate);
        body.endDate = dateToLocalDate(body.endDate);
        return body;
    };

    const submitPatientContract = (isCreatingPlanOfCare) => {
        $scope.sending = true;

        const url = "agencies/:agencyId/patients/:patientId/contracts"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":patientId", patient.id);
        getAddContractBody().then(body => {
            if (body.errorMessage) {
                toaster.pop("error", body.errorMessage);
                $scope.sending = false;
                return;
            }
            DatabaseApi.post(url, body).then((res) => {
                toaster.pop('success', "Contract created", isCreatingPlanOfCare ? 'Plan of care created as well' : '');
                $scope.closeModal();
            }, (err) => {
                $scope.sending = false;
                toaster.pop('error', "Something went wrong", "could not add contract");
            });
        });
    }

    $scope.submit = function(){
        submitPatientContract();
    };

    $scope.addContractLabel = function(){

        if(!$scope.contract.newContractLabel) return;
        $scope.sending = true;
        console.log($scope.contract);

        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/contract_type', {name: $scope.contract.newContractLabel}).then(function (res) {
            $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/contract_types').then(function (res) {
                $scope.contracts = res.data.contractTypes.filter(contract => contract.active);
                $scope.contract.contractObj = $scope.contracts[$scope.contracts.length -1].id + '';
                $scope.setContractId();

                toaster.pop('success', "Contract created", "");
                $scope.sending = true;
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not get contracts");
                $scope.sending = true;
            });

        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add contract");
        });
    };

    $scope.addServiceCodeLabel = function(){

        if(!$scope.contract.newServiceCodeLabel) return;
        $scope.sending = true;
        console.log($scope.contract);

        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/service_code', {code: $scope.contract.newServiceCodeLabel}).then(function (res) {

            $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/service_codes').then(function (res) {
                $scope.codes = res.data.serviceCodes;
                $scope.contract.serviceCodeObj = $scope.codes[$scope.codes.length -1].id + '';
                $scope.setServiceCodeId();

                toaster.pop('success', "Service code created", "");
                $scope.sending = true;
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not get service codes");
                $scope.sending = true;
            });

        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add service code");
        });

    };
});
