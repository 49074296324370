const dependencies = [
  "ui.router",
  "ngAnimate",
  "ngTouch",
  "ui.bootstrap",
  "countTo",
  "ngTable",
  "ngFileUpload",
  "daterangepicker",
  "google.places",
  "uiGmapgoogle-maps",
  "bootstrapLightbox",
  "xeditable",
  "bm.uiTour",
  "oitozero.ngSweetAlert",
  "ngSanitize",
  "toaster",
  "ui.utils.masks",
  "angular-web-notification",
  "NgSwitchery",
  "ngMap",
  "angularPayments",
  "angularMoment",
  "mightyDatepicker",
  "rzModule",
  "checklist-model",
  "angularLoad",
  "ui.calendar",
  "signature",
  "dateDirectives",
  "ui.select",
  "summernote",
  "angularjs-dropdown-multiselect",
  "smoothScroll",
  "dndLists",
  "wysiwyg.module",
  "autoCompleteModule",
  "colorpicker.module",
  "ckeditor",
  "ui.sortable",
  "ui.grid",
  'ui.grid.saveState',
  'ui.grid.selection',
  'ui.grid.cellNav',
  'ui.grid.resizeColumns',
  'ui.grid.moveColumns',
  'ui.grid.pinning',
  'ui.grid.grouping',
  'ui.grid.exporter',
  'ui.grid.pagination',
  'ui.grid.autoResize',
  "ui.mask"
];

if (appEnv !== "development") {
  dependencies.push("ngRaven");
}

var app = angular.module("dashboard", dependencies);
