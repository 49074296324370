app.controller("masterWeekModalCtrl", function (
    $scope,
    $rootScope,
    MasterWeekService,
    DatabaseApi,
    SweetAlert,
    toaster,
) {
    this.$onInit = async function () {
        $scope.patient = $scope.$resolve.patient;
        $scope.masterWeek = _.cloneDeep($scope.$resolve.masterWeek);
        console.log("Opened edit week modal:", $scope.masterWeek);


        initializeCaregivers();
        initializeCaregiversForSelection();
        initializeContracts();
        initializeServiceCodes();

        if ($scope.masterWeek === undefined) {
            $scope.generateTemplateWeek();
        } else {
            $scope.week = $scope.masterWeek.week;
            $scope.days = $scope.masterWeek.days;
            $scope.prepareCaregivers($scope.masterWeek.days, $scope.masterWeek.week.serviceCode);
            $scope.updateServiceCodesAvailableByContract($scope.masterWeek.week.contract);
            $scope.updateServiceCode($scope.masterWeek.week.serviceCode);
        }
    };

    const now = new Date();
    const minDate = new Date("01/01/2017");
    $scope.datePickerOptions = {
        minDate,
        autoApply: true,
        autoUpdateInput: true,
        locale: {
            format: "MM/DD/YYYY",
        },
    };
    $scope.datePickerData = {};

    $scope.validateSaveWeek = () => {
        let valid = false;
        if ($scope.week) {
            if ($scope.week.periodDisplay) {
                const { periodDisplay } = $scope.week;
                if (periodDisplay.startDate && periodDisplay.endDate) {
                    valid = true;
                }
            }
        }

        return !valid;
    };

    $scope.saveWeek = async () => {
        $scope.savingWeek = true
        // This is to prevent MasterWeekService from mutating the week values
        let weekCloneForApi = _.cloneDeep($scope.masterWeek);
        weekCloneForApi.days.forEach(day => { delete day.caregiversForSelection; });
        if ($scope.week.forCreate) {
            try {
                await MasterWeekService.insertMasterWeek({
                    agencyId: $rootScope.agencyId,
                    agencyMemberId: $rootScope.agencyMemberId,
                    patientId: $scope.patient.id,
                    masterWeek: weekCloneForApi,
                });

                $scope.$broadcast("refresh_visits");
            } catch (e) {
                $scope.savingWeek = false;
                toaster.pop('error', "Something went wrong");
            }
        } else {
            try {
                SweetAlert.swal(
                    {
                        title: "Update master week",
                        text: `Pay attention!
                               Changes apply only on future visits.`,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3077EB",
                        confirmButtonText: "Continue",
                        closeOnConfirm: false,
                        showLoaderOnConfirm: true,
                        closeOnCancel: true,
                    },
                    async function (confirmed) {
                        if (confirmed) {
                            const flags = await MasterWeekService.updateMasterWeek($scope.week.masterWeekId, {
                                agencyId: $rootScope.agencyId,
                                agencyMemberId: $rootScope.agencyMemberId,
                                patientId: $scope.patient.id,
                                masterWeek: weekCloneForApi,
                            });

                            let message = "Master week has been successfully updated";

                            if (flags.length > 0) {
                                message = {
                                    title: "Some visits weren't created",
                                    text: flags.map(flag => flag.description).join(' \n\n'),
                                    type: "info"
                                }
                            }

                            swal(message);

                            $scope.$broadcast("refresh_visits");
                        }
                    }
                );
            } catch (e) {
                $scope.savingWeek = false;
                toaster.pop('error', "Something went wrong");
            }
        }

        $scope.week.forCreate = undefined;
        $scope.savingWeek = false;
        $scope.$close({
            week: $scope.week,
            days: $scope.days
        });
    };

    $scope.deleteWeek = async () => {
        SweetAlert.swal(
            {
                title: "Remove master week",
                text: "Are you sure you want to remove this master week?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Remove",
                closeOnConfirm: true,
                closeOnCancel: true,
            },
            async function (confirmed) {
                if (confirmed) {
                    if (!$scope.week.forCreate) {
                        await MasterWeekService.deleteMasterWeek($scope.week.masterWeekId, {
                            agencyId: $rootScope.agencyId,
                            agencyMemberId: $rootScope.agencyMemberId,
                            patientId: $scope.patient.id,
                        });
                    }
                    $scope.week = undefined;
                    $scope.$close("DELETED");
                }
            }
        );
    };

    $scope.cancelWeek = async () => {
        $scope.$close("CANCELED");
    };

    $scope.toggleDay = (day, index) => {
        if (day.readyForSchedule) {
            console.log("Removed day:", day);

            day.readyForSchedule = false;
        } else {
            console.log("Added day:", day);

            let lastReadyDay;
            if (index > 0) {
                lastReadyDay = $scope.days[index - 1];
            } else {
                lastReadyDay = $scope.days.find((day) => day.readyForSchedule);
            }

            if (lastReadyDay) {
                const { caregiver } = lastReadyDay;

                day.caregiver = caregiver;
            }

            $scope.updateCaregivers(day, $scope.week.serviceCode);

            day.readyForSchedule = true;
        }
    };

    $scope.updateContract = (contract) => {
        console.log("Updated contract:", contract);
        const { id, name } = contract;

        $scope.week.contract = {
            id,
            name,
        };

        $scope.updateServiceCodesAvailableByContract(contract);
    };

    $scope.updateServiceCodesAvailableByContract = (contract) => {
        if ($scope.allContractsServiceCodesIds) {
            var serviceCodesSet = {};

            for (const svcContract of $scope.allContractsServiceCodesIds) {
                if (!contract || svcContract.contractId === contract.id) {
                    serviceCodesSet[svcContract.serviceCodeId] = true;
                }
            }

            $scope.currAvailableServiceCodes = $scope.allServiceCodes.filter(svc => serviceCodesSet[svc.id]);
        } else {
            $scope.currAvailableServiceCodes = $scope.allServiceCodes;
        }
    }

    $scope.updateTotalScheduledVisitTime = function (day) {
        const { startHour, endHour } = day.periodDisplay;

        if (startHour !== undefined && endHour !== undefined) {
            let start = LocalDateTime.from(nativeJs(startHour));
            let end = LocalDateTime.from(nativeJs(endHour));

            if (start.isAfter(end)) {
                end = end.plusDays(1);
            }

            const duration = Duration.between(start, end);

            day.period.durationInMinutes = duration.toMinutes();
        }
    };

    $scope.updateServiceCode = async (serviceCode) => {
        console.log("Updated service code:", serviceCode);
        if (serviceCode && serviceCode.id) {
            $scope.week.potentialPayrollCodes = await DatabaseApi.getPayrollCodesForServiceCode(
                serviceCode.id
            ).then((payrollCodes) =>
                payrollCodes.map((payrollCode) => {
                    const { displayId: name, ...rest } = payrollCode;
                    return { name, ...rest };
                })
            );

            $scope.week.potentialPayrollCodes = $scope.week.potentialPayrollCodes.filter(
                (payCode) => payCode.isActive
            );

            $scope.week.potentialPayrollCodes.unshift({
                id: null,
                name: "None",
            });

            for (const day of $scope.days) {
                $scope.updateCaregivers(day, serviceCode);
            }
        }
    };

    $scope.updateCaregivers = (day, serviceCode) => {
        if (serviceCode && serviceCode.certification) {
            day.caregiversForSelection = $scope.caregiversForSelection.filter(
                (caregiver) => {
                    const hasCertificate = caregiver.certifications && caregiver.certifications.includes(serviceCode.certification);
                    return hasCertificate || caregiver.id === null;
                }
            );
        } else {
            day.caregiversForSelection = $scope.caregiversForSelection;
        }
    };

    $scope.prepareCaregivers = (days, fullServiceCode) => {
        for (const day of days) {
            if (fullServiceCode) {
                $scope.updateCaregivers(day, fullServiceCode);
            }
        }
    };

    $scope.generateTemplateWeek = () => {
        const WEEK_LENGTH = 7;
        let contract = null;
        if ($scope.patient.contracts.length === 1) {
            contract = {
                id: $scope.patient.contracts[0].id,
                name: $scope.patient.contracts[0].contractTypeName,
            };
        }

        $scope.masterWeek = {
            week: {
                forCreate: true,
                period: {
                    startDate: null,
                    endDate: null,
                },
                periodDisplay: {
                    startDate: null,
                    endDate: null,
                },
                contract,
                potentialPayrollCodes: [
                    {
                        id: null,
                        name: "None",
                    },
                ],
                payrollCode: null,
                serviceCode: null,
                patient: {
                    id: $scope.patient.id,
                    name: `${$scope.patient.firstName} ${$scope.patient.lastName}`,
                }
            },
            days: [],
        };

        for (let i = 0; i < WEEK_LENGTH; i++) {
            const day = {
                dayOfWeek: i + 1,
                readyForSchedule: i === 0, // Make only the first day editable
                period: {
                    startHour: new Date("1970-01-01 08:00:00"),
                    endHour: new Date("1970-01-01 15:00:00"),
                },
                periodDisplay: {
                    startHour: new Date("1970-01-01 08:00:00"),
                    endHour: new Date("1970-01-01 15:00:00"),
                },
                caregiver: null
            };
            $scope.masterWeek.days.push(day);
            $scope.updateTotalScheduledVisitTime(day);
            $scope.updateCaregivers(day, $scope.masterWeek.week.serviceCode);
        }

        $scope.week = $scope.masterWeek.week;
        $scope.days = $scope.masterWeek.days;
        console.log("Generated week:", $scope.week);
    };

    $scope.weekHeaderString = () => {
        let result = "From » To";
        if (
            $scope.week &&
            $scope.week.periodDisplay &&
            $scope.week.periodDisplay.startDate &&
            $scope.week.periodDisplay.endDate
        ) {
            result =
                new Date($scope.week.periodDisplay.startDate).toDateString() +
                " » " +
                new Date($scope.week.periodDisplay.endDate).toDateString();
        }
        return result;
    };

    function initializeCaregivers() {
        $scope.caregiversFilterCache = {};
        $scope.caregivers = Object.values(DatabaseApi.caregivers())
            .filter((caregiver) => {
                return ["ACTIVE"].includes(caregiver.status);
            })
            .map((caregiver) => {
                const { displayName: name, ...rest } = caregiver;
                return { name, ...rest };
            });
    }

    function initializeCaregiversForSelection() {
        $scope.caregiversForSelection = _.cloneDeep($scope.caregivers).filter(caregiver => {
            return caregiver.officeIds && caregiver.officeIds.includes($scope.patient.currentOfficeId);
        });
        $scope.caregiversForSelection.unshift({
            id: null,
            name: "None",
        });
    }

    function initializeContracts() {
        $scope.contracts = $scope.patient.contracts.map((contract) => {
            const { contractTypeName: name, ...rest } = contract;
            return { name, ...rest };
        });
        $scope.contracts.unshift({
            name: "None",
            id: null,
        });
        console.log("Contracts:", $scope.contracts);
    }

    function initializeServiceCodes() {
        $scope.allServiceCodes = Object.values(DatabaseApi.activeServiceCodes())
            .map((serviceCode) => {
                const { code: name, ...rest } = serviceCode;
                return { name, ...rest };
            });
        $scope.allServiceCodes.unshift({
            name: "None",
            id: null,
        });

        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/contract_service_codes/' + $scope.patient.id).then(res => {
            $scope.allContractsServiceCodesIds = res.data.contractServiceCodes;
            if ($scope.week && $scope.week.contract) {
                $scope.updateServiceCodesAvailableByContract($scope.week.contract);
            } else {
                $scope.updateServiceCodesAvailableByContract();
            }
        });

        $scope.currAvailableServiceCodes = $scope.allServiceCodes;
        console.log("Service codes:", $scope.currAvailableServiceCodes);
    }
});
