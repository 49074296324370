"use strict";

app.controller(
  "TrainingCenterExemptsCtrl",
  function (
    $scope,
    $rootScope,
    $timeout,
    toaster,
    TrainingCenterExemptsService,
    DatabaseApi,
    NgTableParams,
    SweetAlert
  ) {
    const loading = {
      caregivers: false,
      exempt: false,
    };

    const selected = {
      bundle: {},
      years: [],
    };

    const display = {
      data: undefined,
      table: undefined,
      selectAll: true,
    };

    const bundleSelection = {
      settings: {
        styleActive: true,
        singleSelection: false,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
      },
      options: undefined,
      events: {
        onItemSelect: function (item) {
          yearsSelection.options = mapYears(getYears(item.id));
          $timeout(() => {
            $("#yearsSelect").find(".dropdown-toggle").trigger("click");
          }, 50);
        },
      },
    };

    function mapYears(years) {
      return years.map(({ dueDateId, year }) => ({
        id: dueDateId,
        label: year,
      }));
    }

    function getYears(bundleId) {
      const bundle = bundleSelection.options.find(findById(bundleId));
      return bundle ? bundle.years : undefined;
    }

    const yearsSelection = {
      settings: {
        styleActive: true,
        singleSelection: false,
        smartButtonMaxItems: 1,
        showCheckAll: true,
        showUncheckAll: true,
      },
      options: undefined,
      events: {
        onItemSelect,
        onSelectAll: onItemSelect,
        onItemDeselect: onItemSelect,
        onDeselectAll: onItemSelect,
      },
    };

    function onItemSelect() {
      const dueDateIds = selected.years.map(getId);

      loading.caregivers = true;
      debouncedGetCaregivers(dueDateIds);
    }

    (function init() {
      function mapBundles(bundles) {
        return bundles.map(({ bundleId, title, years }) => ({
          id: bundleId,
          label: title,
          years,
        }));
      }

      TrainingCenterExemptsService.getFilters()
        .then((bundles) => {
          bundleSelection.options = mapBundles(bundles);
          // not populating yearSelection on init - populated after a bundle is selected
        })
        .catch((err) => toaster.pop("error", err));
    })();

    function setTableData(caregivers) {
      display.data = caregivers;

      const options = {
        count: 15,
        sorting: { firstName: "asc" },
      };

      display.table = new NgTableParams(options, {
        counts: [],
        dataset: display.data,
      });
    }

    function getCaregivers(dueDateIds) {
      if (dueDateIds.length === 0) {
        return;
      }

      return TrainingCenterExemptsService.getCaregivers({
        dueDateIds,
      })
        .then((caregiverIds) => {
          const caregivers = angular.copy(
            caregiverIds.map((id) => DatabaseApi.getCaregiverById(id))
          );
          setTableData(caregivers);
        })
        .finally(() => {
          loading.caregivers = false;
          $timeout(function () {
            angular.element("html").trigger("click");
          }, 1);
        });
    }
    const debouncedGetCaregivers = debounce(getCaregivers, 1000);

    function toggleSelect(caregiver) {
      caregiver.selected = !caregiver.selected;
    }

    function toggleSelectAll() {
      display.selectAll = !display.selectAll;
      for (const caregiver of display.data) {
        caregiver.selected = !display.selectAll;
      }
    }

    function haveData() {
      return display.data && display.data.length > 0;
    }

    function haveSelected() {
      return display.data.some(getSelected);
    }

    function countSelected() {
      if (display.data !== undefined) {
        return display.data.filter(getSelected).length;
      }
      return 0;
    }

    function getOfficeName(officeIds) {
      const offices = DatabaseApi.offices().filter(({ id }) =>
        officeIds.includes(id)
      );
      const officeNames = offices.map(({ name }) => name);
      return offices.length === 0 ? "No office" : officeNames.join(", ");
    }

    function getId({ id }) {
      return id;
    }

    function getSelected({ selected }) {
      return selected;
    }

    function findById(idToFind) {
      return ({ id }) => id === idToFind;
    }

    function handleExemptionSuccess(caregiverIds) {
      toaster.pop("success", "Bulk exemption was successful");

      const allExempted = caregiverIds.length === display.data.length;
      let dataToSet = undefined;
      if (allExempted) {
        selected.bundle = {};
        selected.years = [];
        display.selectAll = true;
      } else {
        dataToSet = display.data.filter(({ selected }) => !selected);
      }

      setTableData(dataToSet);
    }

    function exempt() {
      const caregiverIds = display.data.filter(getSelected).map(getId);
      const caregiversCount = caregiverIds.length;

      const bundleId = selected.bundle.id;
      const bundleName = bundleSelection.options.find(findById(bundleId)).label;

      const years = yearsSelection.options.filter(({ id }) =>
        selected.years.map(getId).includes(id)
      );
      const yearLabels = years.map(({ label }) => label);

      SweetAlert.swal(
        {
          title: `Bulk exempting from "${bundleName}"`,
          text: `You're about to exempt (${caregiversCount}) caregivers, from years (${yearLabels})`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3077EB",
          confirmButtonText: "Exempt",
          closeOnConfirm: true,
          closeOnCancel: true,
          allowOutsideClick: true,
        },
        function (isConfirm) {
          if (!isConfirm) return;
          loading.exempt = true;

          const dueDateIds = years.map(getId);

          TrainingCenterExemptsService.exemptCaregivers({
            dueDateIds,
            caregiverIds,
            notes: null,
          })
            .then(() => {
              handleExemptionSuccess(caregiverIds);
            })
            .catch(() => toaster.pop("error", "Something went wrong"))
            .finally(() => (loading.exempt = false));
        }
      );
    }

    $scope.toggleSelect = toggleSelect;
    $scope.toggleSelectAll = toggleSelectAll;
    $scope.haveData = haveData;
    $scope.haveSelected = haveSelected;
    $scope.countSelected = countSelected;
    $scope.getOfficeName = getOfficeName;
    $scope.exempt = exempt;
    $scope.display = display;
    $scope.loading = loading;
    $scope.selected = selected;
    $scope.bundleSelection = bundleSelection;
    $scope.yearsSelection = yearsSelection;

    function debounce(callback, wait) {
      let timeout;
      return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
      };
    }
  }
);
