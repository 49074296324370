/**
 * ORIGINAL FILE = notesCtrl.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
class NotesCtrl {
    $state;
    activeTabIndex = 0;
    tabs = [
        {
            id: 0,
            name: "Reports",
            state: "app.notes.report",
        },
        {
            id: 1,
            name: "Inbox",
            state: "app.notes.mentionsInbox",
        },
        {
            id: 2,
            name: "Done",
            state: "app.notes.mentionsDone",
        },
    ];
    constructor($state) {
        this.$state = $state;
        this.setInitialRoute();
    }
    setInitialRoute = () => {
        let route = this.tabs.find((r) => r.state === this.$state.current.name);
        if (route === undefined) {
            route = this.tabs[0];
        }
        this.activeTabIndex = route.id;
        this.goToMentionState(route.state);
    };
    goToMentionState = (notesState) => {
        this.$state.go(notesState);
    };
}
angular.module("dashboard").controller("notesCtrl", NotesCtrl);
