angular
  .module("dashboard")
  .controller(
    "newPatientAddressModalCtrl",
    function ($scope, $rootScope, $filter, DatabaseApi) {
      const evvLocations = [
        { id: "HOME", label: "Home" },
        { id: "COMMUNITY", label: "Community" },
      ];

      const phonenumbers = $scope.$resolve.patient.phoneNumbers.map((x) => ({
        id: x.id,
        label: $filter("phonenumber")(x.phonenumber),
      }));

      const form = {
        fields: {
          address: undefined,
          address2: undefined,
          description: undefined,
          evvLocation: {},
          phoneNumberIds: [],
        },
        valid: false,
        loading: false,
      };

      function submit() {
        form.loading = true;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/addresses`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patient.id);

        const data = {
          address: form.fields.address.formatted_address,
          address2: form.fields.address2,
          evvLocation: form.fields.evvLocation.id,
          phoneNumberIds: form.fields.phoneNumberIds.map((x) => x.id),
          description: form.fields.description,
        };

        return DatabaseApi.post(url, data)
          .then(({ data }) => $scope.$resolve.onSuccess(data.address))
          .catch((e) => $scope.$resolve.onFail(e))
          .finally(() => (form.loading = false));
      }

      $scope.$watchGroup(
        [
          "form.fields.type.id",
          "form.fields.label",
          "form.fields.address",
        ],
        () => {
          validateForm();
        });


      $scope.$watchCollection(
          "form.fields.phoneNumberIds",
        () => {
          validateForm();
        }
      );

      $scope.$watch(
        "form.fields.evvLocation",
      () => {
        validateForm();
      }
    ,true);


      $scope.evvLocations = evvLocations;
      $scope.phonenumbers = phonenumbers;
      $scope.submit = submit;
      $scope.form = form;

      const validateForm = ()=> {
        const isAddressValid = form.fields.address?.formatted_address !== undefined;
        const isEvvLocationValid = form.fields.evvLocation.id !== undefined;
        const isEvvPhonenumbers = form.fields.phoneNumberIds !== undefined && form.fields.phoneNumberIds.length > 0;
        form.valid = isAddressValid && isEvvLocationValid && isEvvPhonenumbers
      }
    }
  );
