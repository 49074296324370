app.component("agencyUserPermissionsRoles", {
  templateUrl: "../../admin/views/agency-user-permissions-roles.html",
  controller: agencyUserPermissionsRolesCtrl,
});

function agencyUserPermissionsRolesCtrl(
  $scope,
  toaster,
  agencyPermissionsRolesService,
  $uibModal,
  $filter
) {
  $scope.globalFilter = { val: '' };
  const init = async () => {
    $scope.agencyRoles = await agencyPermissionsRolesService.getAgencyRolesPermissions();
    $scope.agencyPermissionGroups = await agencyPermissionsRolesService.getAllPermissions();
    $scope.agencyPermissionGroups = $scope.agencyPermissionGroups.map(
      (permissionGroup) => ({
        ...permissionGroup,
        permissions: permissionGroup.permissions.map((permissionType) => {
          const permission = {};
          permission.id = permissionType;
          $scope.agencyRoles.forEach((role) => {
            const foundPermission = role.permissions.find(
              (rolePermission) => rolePermission === permissionType
            )
            permission[
              `${role.name}_${permissionType}`
            ] = foundPermission !== undefined ? true : false;
          });
          return permission;
        }),
      })
    );
  };

  $scope.$ctrl.$onInit = () => {
    init();
  };

  $scope.allowAllRolePermissions = (roleName) => {
    const body = { permissions: [] };
    // Get all keys..
    body.permissions = $scope.agencyPermissionGroups.reduce((all, perm) => all.concat(perm.permissions.map(per => per.id)), []);
    agencyPermissionsRolesService
      .editRolePermissions(roleName, body)
      .then(function (res) {
        // Load all again
        init();
        toaster.pop("success", `Successfully updated ${roleName} permissions`);
      })
      .catch(function (err) {
        toaster.pop(
          "error",
          "Something went wrong",
          `Failed to update ${roleName} permissions`
        );
      });
  };

  $scope.snakeCaseFilter = (row) => {
    if ($scope.globalFilter.val === '') {
      return row;
    }
    const camelCased = $filter("camelCase")(row.id).toLowerCase();
    if (camelCased.includes($scope.globalFilter.val.toLowerCase())) {
      return row;
    }
  }
  $scope.blockAllRolePermissions = (roleName) => {
    // Empty array will result in no permissions
    const body = { permissions: [] };
    agencyPermissionsRolesService
      .editRolePermissions(roleName, body)
      .then(function (res) {
        // Load all again
        init();
        toaster.pop("success", `Successfully updated ${roleName} permissions`);
      })
      .catch(function (err) {
        toaster.pop(
          "error",
          "Something went wrong",
          `Failed to update ${roleName} permissions`
        );
      });
  };

  $scope.updateRolePermissions = (roleName) => {
    const body = { permissions: [] };
    for (const permissionGroup of $scope.agencyPermissionGroups) {
      for (const permission of permissionGroup.permissions) {
        if (permission[`${roleName}_${permission.id}`] === true) {
          body.permissions.push(permission.id);
        }
      }
    };
    agencyPermissionsRolesService
      .editRolePermissions(roleName, body)
      .then(function (res) {
        toaster.pop("success", `Successfully updated ${roleName} permissions`);
      })
      .catch(function (err) {
        toaster.pop(
          "error",
          "Something went wrong",
          `Failed to update ${roleName} permissions`
        );
      });
  };

  $scope.openCreateRoleModal = function () {
    var newScope = $scope.$new();
    newScope.roles = angular.copy($scope.agencyRoles);

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-permission-role-modal.html",
      size: "sm",
      controller: "agencyPermissionRoleModalCtrl",
      scope: newScope,
    });
    modalInstance.result.then(function () {
      init();
    });
  };

  $scope.openRoleHistoryModal = function (recordId) {
    var newScope = $scope.$new();
    const tables = angular.copy([{tableName: 'agency_role', recordId}]);

    $uibModal.open({
      templateUrl: 'admin/views/history-timeline-modal.html',
      controller: "historyTimelineModalCtrl",
      scope: newScope,
      size: 'lg',
      resolve: {
        tables: () => tables,
      }
    });
  };
}
