angular.module("dashboard").component("newVersion", {
  templateUrl: "admin/views/new-version.html",
  controller: function($scope) {
    $scope.shouldDisplayNewVersion = false;
    const localDistHash = localStorage.getItem("localDistHash");

    function deleteAllCacheStorage() {
      console.log("deleteAllCacheStorage");
      return caches
        .keys()
        .then(keys => Promise.all(keys.map(key => caches.delete(key))));
    }

    if (localDistHash !== window.distFolderHash) {
      $scope.shouldDisplayNewVersion = true;
      deleteAllCacheStorage().then(() => {
        console.log("deleteAllCacheStorage resolved. setting new localDistHash:", window.distFolderHash);
        localStorage.setItem("localDistHash", window.distFolderHash);
        window.location.reload();
      });
    }

    const loaddingMesasgesBank = [
      `I can and I will.`,
      `Live the moment.`,
      `Never stop dreaming.`,
      `Love more. Worry less.`,
      `The best is yet to come.`,
      `Better things are coming.`,
      `Work hard. Stay humble.`,
      `Keep moving forward.`,
      `No rain. No flowers.`,
      `Dream big. Pray bigger.`,
      `Actually, you can.`,
      `Focus on the good.`,
      `If you want it, work for it.`,
      `Prove them wrong.`,
      `Take the risk or lose the chance`,
      `You are amazing. Remember that.`,
      `Don’t dream your life, live your dream.`,
      `Learn from yesterday, live for today, hope for tomorrow.`,
      `No pressure, no diamonds.`,
      `Every wall is a door.`,
      `Live for yourself.`,
      `Life is short. Live passionately.`,
      `Life is a one time offer, use it well.`
      // https://www.therandomvibez.com/short-quote/
    ];
    const randomIndex = Math.floor(Math.random() * Math.floor(loaddingMesasgesBank.length));
    $scope.loadingMessage = loaddingMesasgesBank[randomIndex];
  }
});
