export class EligibilityChecksService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    DatabaseApi;
    payerService;
    officesService;
    contractTypeService;
    constructor(DatabaseApi2, endpoint, $rootScope, DatabaseApi, payerService, officesService, contractTypeService) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
        this.DatabaseApi = DatabaseApi;
        this.payerService = payerService;
        this.officesService = officesService;
        this.contractTypeService = contractTypeService;
    }
    getEligibilityStatusText = (isEligible) => {
        return isEligible === null ? 'ERROR' : (isEligible ? 'ELIGIBLE' : 'NOT ELIGIBLE');
    };
    getEligibilityStatusColor = (isEligible) => {
        return isEligible ? 'lightblue' : 'red';
    };
    async runEligibilityCheck(patientId) {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/check_eligibility",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: patientId
            },
        });
        const result = await this.DatabaseApi2.post(url);
        return result.data.status;
    }
    async getEligibiltyChecks(runDate) {
        const format = JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd");
        const queryParams = new URLSearchParams(runDate === undefined ? {} : { runDate: runDate.format(format) });
        const queryParamsString = queryParams.toString();
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/eligibility_checks?${queryParamsString}`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
            },
        });
        const agencyMembers = this.DatabaseApi.getAgencyMembers();
        const patientsMap = this.DatabaseApi.patients();
        const contractTypes = await this.contractTypeService.getContractTypes({ onlyActives: false, refetch: false });
        const offices = await this.officesService.getOffices({ onlyActives: false, refetch: false });
        const payers = await this.payerService.getPayers({ onlyActives: false, refetch: false });
        const res = await this.DatabaseApi2.get(url);
        return res.data.records.map(record => {
            const patientActiveContractsNames = contractTypes
                .filter(contract => record.activeContractTypes !== null && record.activeContractTypes.includes(contract.id))
                .map(contract => contract.name);
            const patientOfficeId = patientsMap[record.patientId].currentOfficeId;
            const responsePayer = payers.find(payer => payer.id === record.responsePayerId);
            const createdByName = record.createdBy === null ? 'System' : agencyMembers[record.createdBy.toString()];
            const patientOffice = offices.find(office => office.id === patientOfficeId);
            const patientTeamId = patientsMap[record.patientId].agencyTeamId;
            const patientId = patientsMap[record.patientId].id;
            const patientDisplayName = patientsMap[record.patientId].displayName;
            const patientDisplayId = patientsMap[record.patientId].displayId;
            const patientStatus = patientsMap[record.patientId].status;
            const patientAssignedCoordinator = patientsMap[record.patientId].assignedCoordinator;
            const responseMessage = record.vendorResponse === null ? null : JSON.stringify(Object.fromEntries(Object.entries(record.vendorResponse).filter(([_, v]) => v != null && v !== '')), null, "\n").replace(/[\[\]\{\}\"]+/g, '');
            record.message = record.message !== null ? record.message : (record.isEligibleOnDifferentPayer ? 'This patient is eligibile, but not for any of his active contracts' : null);
            return { ...record,
                patientTeamId,
                patientId,
                patientDisplayName,
                patientDisplayId,
                patientOfficeId,
                patientStatus,
                patientAssignedCoordinator,
                patientActiveContractsNames,
                responsePayerName: responsePayer === undefined ? '' : responsePayer.name,
                createdByName,
                responseMessage,
                patientOfficeName: patientOffice === undefined ? '' : patientOffice.name
            };
        });
    }
}
angular.module("dashboard").service("eligibilityChecksService", EligibilityChecksService);
