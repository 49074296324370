'use strict';
app.directive("patientPayRates", ["NgTableParams", "$uibModal", "toaster", "DatabaseApi", "$rootScope", "rangesCalculateService", function (NgTableParams, $uibModal, toaster, DatabaseApi, $rootScope, rangesCalculateService) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/patient-pay-rates.html',
        scope: {
            patientId: '=',
        },
        link: function (scope) {
            scope.openPatientPayRateModal = (patientPayRateInitValues) => {
                const modalInstance = $uibModal.open({
                    templateUrl: 'admin/views/patient-pay-rate-modal.html',
                    size: 'lg',
                    controller: 'patientPayRateModalCtrl',
                    resolve: {
                        patientId: () => scope.patientId,
                        patientPayRateInitValues: () => patientPayRateInitValues
                    }
                });

                modalInstance.result.then((res) => {
                    if (res === 'success') {
                        fetchData();
                    }
                });
            }
            scope.getTypeText = (type) => {
                switch (type) {
                    case ('FIXED_DIFF'):
                        return "Fixed rate raise (+)"
                    case ('PERCENTAGE'):
                        return "Percentage rate raise (%)"
                    case ('BASE_RATE'):
                        return "Base rate (by weekly template)"
                }
            }

            const getBaseRate = (patientPayRate) => patientPayRate.type === "BASE_RATE"
                ? "-"
                : patientPayRate.isBasedOnCaregiverRate
                    ? "Caregiver's rate"
                    : "Base Rate";

            scope.deletePayRate = (id) => {
                DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + scope.patientId + '/pay_rates/' + id).then(function (res) {
                    fetchData();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not delete patient pay rate");
                })
            }

            const fetchData = () => {
                DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + scope.patientId + '/pay_rates').then(function (res) {
                    const patientPayRates = transformPatientPayRates(res.data.patientPayRates);
                    const defaultPayRates = transformDefaultPatientPayRates(res.data.patientDefaultPayRates);
                    let rangesDisplay = [];

                    scope.payCodes.forEach(payCode => {
                        rangesDisplay = [...rangesDisplay, 
                                         ...rangesCalculateService.calculateRanges(patientPayRates.filter(payRate => payRate.payCodeId === payCode.id))]; 
                    });

                    initDefaultPayRatesTable(defaultPayRates);
                    initPatientPayRatesTable(rangesDisplay);
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not get patient pay rates");
                })
            }

            const initDefaultPayRatesTable = (data) => {
                if (!data) return;
                var options = {
                    count: 10,
                    sorting: { startDate: "desc" }
                };

                scope.patientDefaultPayRatesTable = new NgTableParams(options, {
                    counts: [],
                    dataset: data
                });
            }

            const initPatientPayRatesTable = (data) => {
                if (!data) return;
                var options = {
                    count: 10,
                    sorting: { startDate: "desc" }
                };

                scope.patientPayRatesTable = new NgTableParams(options, {
                    counts: [],
                    dataset: data
                });
            }

            function transformPatientPayRates(patientPayRates) {
                scope.payCodes = new Map();

                const payRates = patientPayRates;

                payRates.forEach(patientPayRate => {
                    patientPayRate.payCodeName = DatabaseApi.payrollCodes().find(payCode => patientPayRate.payCodeId === payCode.id).displayId;
                    scope.payCodes.set(patientPayRate.payCodeId, patientPayRate.payCodeName);
                    patientPayRate.baseRate = getBaseRate(patientPayRate);
                });

                scope.payCodes = [...scope.payCodes].map(([id, name]) => ({ id, name }));

                return payRates;
            }

            function transformDefaultPatientPayRates(patientDefaultPayRates) {
                const defaultPayRates = patientDefaultPayRates.map(rate => ({
                    ...rate,
                    type: "BASE_RATE",
                }));

                defaultPayRates.forEach(defaultPayRate => {
                    defaultPayRate.payCodeName = DatabaseApi.payrollCodes().find(payCode => defaultPayRate.payCodeId === payCode.id).displayId;
                    defaultPayRate.baseRate = getBaseRate(defaultPayRate);
                });

                return defaultPayRates;
            }

            fetchData();
        }
    };
}]);
