app.controller('payrollCodeSetupModalCtrl', function ($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService) {

    $scope.fields = FormService.getFields(fields, data);
    $scope.form = {};
    
    $scope.submitForm = function () {
        if ($scope.form.data.$invalid) {
            return;
        }

        $scope.formSending = true;
        var newData = FormService.prepareFormData($scope.fields);

        console.log(newData);

        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_code/' + data.id, newData)
                .then(function (res) {
                    console.log(res);
                    toaster.pop('success', "Payroll saved");
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change payroll code settings")
                }).finally(function () {
                    $scope.formSending = false;
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_code', newData).then(function (res) {
                console.log(res);
                toaster.pop('success', "Payroll saved");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add payroll code");
            }).finally(function () {
                $scope.formSending = false;
            });
        }   
    };
    $scope.fieldChanged = function(item){
        if(item.type.type === "Boolean" && data.id){
            $scope.submitForm();
        }
    }
    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };
});
