app.controller('patientUpcomigTaskCancelModalCtrl', function (
    $scope,
    $rootScope,
    DatabaseApi,
    $uibModalInstance,
    noteConsts,
    entityNoteService,
    ) {
    $scope.manualRemoveNote = {};
    const taskStatus = $scope.task.status;
    $scope.isNoteRequired = taskStatus === "Scheduled" && entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_MANUAL_REMOVE);
    
    $scope.submitForm = async (action) => {
        const body = {};
        if ($scope.isNoteRequired) {
            const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
                $scope.manualRemoveNote,
                noteConsts.NoteTypes.VISIT_MANUAL_REMOVE
            );
            if (!isNoteValid) {
                $scope.noteValidations = { isPredefinedValid, isMessageValid };
                return;
            }
            body.cancelTaskNote = entityNoteService.buildEntityNoteRequest(
                $scope.manualRemoveNote,
                noteConsts.NoteTypes.VISIT_MANUAL_REMOVE,
            );
        }

        const task = $scope.task;
        switch (action) {
            case "cancel":
                return;
            case "cancelAll": {
                
                if (task.type === "RegularTask") {
                    const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_instances/${task.taskInstanceId}/cancel`;
                    await DatabaseApi.post(url, {});
                }
                const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_tasks/${task.taskId}/cancel`;
                await DatabaseApi.post(url, {});
                break;
            }
            case "cancelSpecific": {
                if (task.taskInstanceId) {
                    const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_instances/${task.taskInstanceId}/cancel`;
                    await DatabaseApi.post(url, body);
                }
                break;
            }
        }
        $rootScope.$emit('patient_task_saved');
        $scope.closeModal();
    }

    $scope.handleNoteChanges = (updatedNote) => {
        $scope.manualRemoveNote = updatedNote;
    };

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };
});