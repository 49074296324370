app.controller('uploadScanModalCtrl', function ($scope, $rootScope, $uibModalInstance, toaster, Consts, $http, proId, DatabaseApi) {
  window.scrollTo(0, 0);

  $scope.uploading = false;
  $scope.file = null;

  $scope.closeModal = function () {
    $uibModalInstance.dismiss();
  };

  $scope.$watch('file', function () {
    if ($scope.file && $scope.file.name) {
      var fileType = $scope.file.name.split('.');
      fileType = fileType[fileType.length - 1];
      fileType = fileType.toLowerCase();
      if (fileType === 'pdf' || fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {

      } else {
        toaster.pop('warning', "Unsupported file type", '');
        $scope.file = null;
      }
    }
  });

  $scope.sendFile = function () {
    $scope.uploading = true;
    let formData = new FormData();
    if ($scope.caregiverComplianceItemStatusId) {
      formData.append('caregiverComplianceItemStatusId', $scope.caregiverComplianceItemStatusId);
    }
    formData.append('file', $scope.file, $scope.file.name);

    const url = Consts.api + 'hr/agencies/' + $rootScope.agencyId + '/caregivers/' + proId +
      '/compliance_items/' + $scope.caregiverComplianceItemId + '/add_file';

    $http({
      url: url,
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': undefined }
    }).then(function (response) {
      $scope.file = null;
      $scope.fileSent = 'Thanks for uploading, the file was uploaded successfully.';
      toaster.pop('success', "Success", "File Uploaded");
      $scope.uploading = false;
      $uibModalInstance.close(true);
    }, function (response) {
      $scope.file = null;
      $scope.fileSent = 'An Error Occurred, please refresh the page and try again';
      toaster.pop('error', "Something Went Wrong", 'Please try again');
      $scope.uploading = false;
    });
  };
});
