/**
 * ORIGINAL FILE = eligibilityChecksCtrl.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
const eligibilityChecksTableSettings = {
    count: 25,
    sorting: {
        "createdAt": "desc",
    },
};
class EligibilityChecksCtrl {
    patientStatusUtils;
    officesService;
    contractTypeService;
    eligibilityChecksService;
    NgTableParams;
    $rootScope;
    createDropdownFilter;
    dateRangeDefaultOptions;
    toaster;
    dateUtils;
    $timeout;
    $filter;
    csvExportUtil;
    eligibilityChecks = [];
    dateRangeOptions;
    table;
    isLoading = false;
    filters = {
        freeText: "",
        runDate: null,
        dropdowns: [],
        showOnlyProblems: true
    };
    dropdownFilterExtraSettings = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
    };
    dropdownFilterEvents = {};
    dropdownFilterManager;
    constructor(patientStatusUtils, officesService, contractTypeService, eligibilityChecksService, NgTableParams, $rootScope, createDropdownFilter, dateRangeDefaultOptions, toaster, dateUtils, $timeout, $filter, csvExportUtil) {
        this.patientStatusUtils = patientStatusUtils;
        this.officesService = officesService;
        this.contractTypeService = contractTypeService;
        this.eligibilityChecksService = eligibilityChecksService;
        this.NgTableParams = NgTableParams;
        this.$rootScope = $rootScope;
        this.createDropdownFilter = createDropdownFilter;
        this.dateRangeDefaultOptions = dateRangeDefaultOptions;
        this.toaster = toaster;
        this.dateUtils = dateUtils;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.csvExportUtil = csvExportUtil;
        this.table = new this.NgTableParams(eligibilityChecksTableSettings, { dataset: [] });
        this.dropdownFilterEvents = {
            onSelectionChanged: () => this.onFilterChange(),
        };
        this.dateRangeOptions = {
            ...dateRangeDefaultOptions,
            eventHandlers: {
                "apply.daterangepicker": () => this.loadTable(),
            },
        };
    }
    async $onInit() {
        await this.initFilters();
        this.loadTable();
    }
    async runEligibiltyCheckForPatient(patientId) {
        await this.eligibilityChecksService.runEligibilityCheck(patientId);
        this.loadTable();
    }
    getEligibilityStatusText(isEligible) {
        return this.eligibilityChecksService.getEligibilityStatusText(isEligible);
    }
    ;
    getEligibilityStatusColor(isEligible) {
        return this.eligibilityChecksService.getEligibilityStatusColor(isEligible);
    }
    ;
    onFilterChange() {
        this.setTable(this.eligibilityChecks);
    }
    ;
    openPatientModal(patientId) {
        this.$rootScope.openPatientModal(patientId);
    }
    ;
    async loadTable() {
        this.isLoading = true;
        let runDateFilter;
        if (this.filters.runDate !== null) {
            runDateFilter = this.dateUtils.ymdStringToLocalDate(moment(this.filters.runDate).format("YYYY-MM-DD"));
        }
        try {
            const eligibilityChecks = await this.eligibilityChecksService.getEligibiltyChecks(runDateFilter);
            this.eligibilityChecks = eligibilityChecks;
            this.setTable(eligibilityChecks);
        }
        catch {
            this.toaster.pop("error", "Something went wrong", "Could not get patients eligibility checks");
        }
        finally {
            this.$timeout(() => this.isLoading = false, 0);
        }
    }
    ;
    exportToCsv() {
        ;
        const csvRows = [];
        const titles = {
            'Patient': 'patientDisplayName',
            'Patient ID': 'patientDisplayId',
            'Patient Status': 'patientStatus',
            'Office': 'patientOfficeName',
            'Eligibility Status': 'eligibilityStatus',
            'Execution Time': 'createdAt',
            'Executed By': 'createdByName',
            'Error Message': 'message',
            'Response Payer': 'responsePayerName',
            'Patient Active Contract': 'patientActiveContractsNames',
            'Vendor Response': 'vendorResponse'
        };
        this.table.data.forEach(dataRow => {
            csvRows.push({
                patientDisplayName: dataRow.patientDisplayName,
                patientDisplayId: (dataRow.patientDisplayId ?? '').toString(),
                patientStatus: dataRow.patientStatus,
                patientOfficeName: dataRow.patientOfficeName,
                eligibilityStatus: this.getEligibilityStatusText(dataRow.isEligible),
                createdAt: this.$filter("instantStringToLocaleDateTime")(dataRow.createdAt),
                createdByName: dataRow.createdByName ?? '',
                message: (dataRow.message ?? ''),
                responsePayerName: (dataRow.responsePayerName ?? ''),
                patientActiveContractsNames: (dataRow.patientActiveContractsNames ? dataRow.patientActiveContractsNames.join(', ') : ''),
                vendorResponse: dataRow.responseMessage ? dataRow.responseMessage.replace(/(\r\n|\n+|\r|\s+|\t|&nbsp;)/gm, ' ') : ''
            });
        });
        this.csvExportUtil.exportCsv(titles, csvRows);
    }
    async initFilters() {
        this.filters = {
            showOnlyProblems: true,
            freeText: "",
            runDate: null,
            dropdowns: []
        };
        const offices = await this.officesService.getOffices({ onlyActives: true, refetch: false });
        this.filters.dropdowns.push({
            title: "Offices",
            entityPath: ['patientOfficeId'],
            options: offices.map(office => ({ id: office.id, label: office.name })),
            values: [],
        });
        const contracts = await this.contractTypeService.getContractTypes({ onlyActives: true, refetch: false });
        this.filters.dropdowns.push({
            title: "Contracts",
            entityPath: ['activeContractTypes'],
            options: contracts.map(contract => ({ id: contract.id, label: contract.name })),
            values: [],
        });
        this.dropdownFilterManager = this.createDropdownFilter({
            dropdowns: this.filters.dropdowns,
        });
    }
    setTable(eligibilityChecks) {
        const filteredEligibilityChecks = this.filterEligibiltyChecks(eligibilityChecks);
        this.table = new this.NgTableParams(eligibilityChecksTableSettings, { dataset: filteredEligibilityChecks });
    }
    filterEligibiltyChecks(eligibilityChecks) {
        const query = this.filters.freeText.toLowerCase();
        return eligibilityChecks.filter((record) => {
            return (this.dropdownFilterManager.filter(record)
                && this.filterEligibilityChecksByFreeText(record, query)
                && (!this.filters.showOnlyProblems || (this.filters.showOnlyProblems && (!record.isEligible || record.isEligibleOnDifferentPayer || record.message))));
        });
    }
    filterEligibilityChecksByFreeText(eligibilityCheck, lowerCaseQuery) {
        return (lowerCaseQuery === "" ||
            eligibilityCheck.patientDisplayName.toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.patientDisplayId?.toString().toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.responsePayerName?.toString().toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.patientActiveContractsNames?.toString().toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.patientOfficeName?.toString().toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.message?.toString().toLowerCase().includes(lowerCaseQuery) ||
            eligibilityCheck.createdByName?.toString().toLowerCase().includes(lowerCaseQuery));
    }
}
const EligibilityChecksPage = {
    controller: EligibilityChecksCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/eligibility-checks.html"
};
angular
    .module("dashboard")
    .component("eligibilityChecksPage", EligibilityChecksPage);
export {};
