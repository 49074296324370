angular
  .module("dashboard")
  .controller("visitCancelReasonModal", function (
    $scope,
    $uibModalInstance,
    DatabaseApi,
    SweetAlert,
    toaster
  ) {
    $scope.visit = $scope.$resolve.visit;
    $scope.onCancel = $scope.$resolve.onCancel;
    $scope.closeModal = closeModal;
    $scope.reasons = [];

    $scope.reasonRadio = {
      val: "",
      search: "",
    };
    $scope.checkCancelReason = function (reset) {
      $scope.reasonRadio.search = "";
      if (reset) {
        $scope.showCancelDiv = false;
        $scope.reasonRadio.val = "";
        $scope.reasonRadio.other = "";
        $scope.reasons = [
          {
            text: "Assigned over the phone (using Medflyt)",
            pick: true,
          },
          {
            text: "Assigned over the agency chat",
            pick: true,
          },
          {
            text: "Assigned over the visit chat",
            pick: true,
          },
          {
            text: "Case no longer available",
            external: true,
          },
          {
            text: "Wasn't assigned by Medflyt, was assigned outside of Medflyt",
            external: true,
          },
          {
            text: "Other",
            other: true,
          },
        ];
      }
    };
    $scope.checkCancelReason("reset");

    $scope.pickCaregiver = function (visit, caregiver, broadcast) {
      if (!caregiver)
        return toaster.pop(
          "error",
          "Something went wrong",
          "please reload/refresh page"
        );
      if (visit.status === "scheduled") {
        if (new Date(visit.startTime).getTime() < new Date().getTime()) {
          SweetAlert.swal({
            title: "The case has started already!",
            text:
              "Cannot remove the caregiver assigned to a case after it has started! \n In order to remove the caregiver, cancel the visit and create a new one to broadcast to available caregivers.",
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "OK",
            closeOnConfirm: true,
          });
        } else {
          changePopup(visit, caregiver, broadcast);
        }
      } else if (!visit.dayTimeParams) {
        var caregiverId = caregiver.caregiver
          ? caregiver.caregiver
          : caregiver.id;

        var url =
          "agencies/" +
          $rootScope.agencyId +
          "/agency_members/" +
          $rootScope.agencyMemberId +
          "/visits/" +
          visit.id +
          "/assigned_to";
        var body = {
          caregiverId: caregiverId,
          partialAssignDetails: null,
          flexibleVisitRequestParams: null,
        };
        if (caregiver.acceptedData) {
          body.flexibleVisitRequestParams = caregiver.acceptedData;
        }
        DatabaseApi.put(url, body).then(
          function (res) {
            if (res.data.assignedWithOvertime) {
              toaster.pop({
                  type: 'warning',
                  title: 'Warning',
                  body: `Caregiver was assigned with increased caregiver overtime`
              });
            } else {
              toaster.pop(
                "success",
                "Success",
                "Caregiver has assigned to visit"
              );
            }

            Analytics.event("visit-modal-assigned", {
              visitId: visit.id,
              status: "scheduled",
              newCaregiver: caregiverId,
              accepted: visit.acceptedBy.length,
            });

            $uibModalInstance.dismiss();
          },
          function (err) {
            var errText = "";
            if (err.data) errText = err.data.error ? err.data.error : "";

            if (err.status === 409)
              toaster.pop("error", "Assigning Conflict", errText);
            else
              toaster.pop("error", "Something went wrong", errText || err.data);
          }
        );
      } else {
        $scope.assignModal(visit, caregiver);
      }
    };

    this.$onInit = function () {
      function setCaregiversStatus() {
        var caregiversMap = DatabaseApi.caregivers() || {};
        if ($scope.visit.assignedCaregiver) {
          $scope.visit.assignedCaregiver =
            caregiversMap[$scope.visit.assignedCaregiver.id.toString()];
        }
        if ($scope.visit.acceptedBy) {
          $scope.visit.acceptedBy.forEach(function (a, i) {
            $scope.visit.acceptedBy[i] = angular.extend(
              $scope.visit.acceptedBy[i],
              caregiversMap[a.id.toString()]
            );
          });
        }
        if ($scope.visit.calledAgency) {
          $scope.visit.calledAgency.forEach(function (a, i) {
            $scope.visit.calledAgency[i] = angular.extend(
              $scope.visit.calledAgency[i],
              caregiversMap[a.id.toString()]
            );
          });
        }
        if ($scope.visit.moreDetailsViewedBy) {
          $scope.visit.moreDetailsViewedBy.forEach(function (a, i) {
            if (!a.caregiver) return;
            $scope.visit.moreDetailsViewedBy[i].caregiver =
              caregiversMap[a.caregiver.id.toString()];
          });
        }
        if ($scope.visit.previewedBy) {
          $scope.visit.previewedBy.forEach(function (a, i) {
            if (!a.caregiver) return;
            $scope.visit.previewedBy[i].caregiver =
              caregiversMap[a.caregiver.id.toString()];
          });
        }
        if ($scope.visit.broadcastedTo) {
          $scope.visit.broadcastedTo.forEach(function (a, i) {
            $scope.visit.broadcastedTo[i] = caregiversMap[a.id.toString()];
          });
        }
        if ($scope.visit.suggested) {
          $scope.visit.suggested.forEach(function (a, i) {
            if (!a.caregiver) return;
            $scope.visit.suggested[i].caregiver =
              caregiversMap[a.caregiver.id.toString()];
          });
        }
        $scope.caregiversMap = caregiversMap;
      }
      setCaregiversStatus();
    };

    function closeModal() {
      $uibModalInstance.dismiss();
    }
  });
