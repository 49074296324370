
"use strict";
app.controller('standardReportsCtrl', function ($scope, $rootScope, $state, $uibModal, $filter, NgTableParams, reportService, toaster, SweetAlert) {

    function initialize() {
        loadItems();
    }

    const loadItems = function () {
        reportService.getStandardReports().then(res => {
            $scope.standardReports = res.map(report => ({
                ...report,
                loadingReport: false,
            }));
            initTable($scope.standardReports);
        });
    }

    const initTable = function (items) {
        const options = {
            count: 25,
        };

        $scope.tableParams = new NgTableParams(options, {
            dataset: items,
        });
    };

    $scope.clickTableRow = (standardReportId) => {
        $state.go('app.reports.standard.standardReportView', { standardReportId: standardReportId });
    }

    $scope.handleNewReport = () => {
        const mySavedReportsNames = $scope.standardReports.map(report => report.name);
        $state.go('app.reports.reportTool', { mySavedReportsNames });
    }

    var universalBOM = "\uFEFF";


    $scope.handleManageAccess = (savedReport) => {
        const newScope = $scope.$new();
        newScope.standardReport = savedReport;
        $rootScope.openStandardReportPermissionsModal(newScope);
    }



    const getFileName = (filename) => {
        return `medflyt-report-${(filename ? filename + "-" : '')}${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }

    $scope.handleOpenReportEntityPermissionsModal = () => {
        $rootScope.openReportEntityPermissionsModal();
    }
    $scope.hideStandardReport = (standard_report_id) =>{

      reportService.updateStandardReportSettings(standard_report_id,{is_shown: false,standard_report_id: standard_report_id,agency_member_id:$rootScope.user.agencyMember.id}).then(()=>{
        $state.reload();
      });


    }
    $scope.showStandardReport = (standard_report_id) =>{
        reportService.updateStandardReportSettings(standard_report_id,{is_shown: true,standard_report_id: standard_report_id,agency_member_id:$rootScope.user.agencyMember.id}).then(()=>{

        $state.reload();
      });
    }
    $scope.manageStandardReportAccess = (standard_report_id,title) =>{
        // let newScope = angular.copy($scope)
        const newScope = $scope.$new();
        newScope.standardReport ={id:standard_report_id};
        newScope.title = title;
        $rootScope.openStandardReportPermissionsModal(newScope);
    }
    $scope.showHiddenReportsModal = ()=>{
        
        $rootScope.openStandardReportHiddenModal($scope);
    }

    initialize();

});
