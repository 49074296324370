"use strict";
app.directive("selectAutocomplete", function () {
  return {
    restrict: "E",
    templateUrl: "admin/views/select-autocomplete.html",
    scope: {
      typeName: "<",
      items: "<",
      preSelectedItem: "<",
      isDisabled: "<",
      showError: "<",
      allowCreateNew: "<",
      handleSelect: "&",
      handleDeselect: "&",
      handleCreateNewType: "&",
      displayProp: "<",
    },
    controller: function ($scope, $rootScope, $filter) {
      $scope.showAllResults = false;
      $scope.toggleAllResults = () => {
        $scope.showAllResults = !$scope.showAllResults;
      };
      $scope.onItemFilterChange = () => {
        $scope.showAllResults = false;
      };
      if (!$scope.displayProp) {
        $scope.displayProp = "text";
      }
      if ($scope.preSelectedItem) {
        $scope.selectedItem = $scope.preSelectedItem;
      } else {
        $scope.selectedItem = null;
      }

      $scope.itemFilter = { val: "" };
      $scope.placeholder = `Select ${$scope.typeName}`

      $scope.onItemSelect = function (item) {
        if ($scope.showError) {
          $scope.showError = false;
        }
        $scope.itemFilter.val = "";
        $scope.selectedItem = item[$scope.displayProp];
        $scope.handleSelect({ item: item });
      };

      $scope.onItemDeSelect = function () {
        $scope.itemFilter.val = "";
        $scope.selectedItem = null;
        $scope.selectedIndex = -1;
        $scope.handleDeselect();
      };

      $scope.createNewType = function(newType) {
        $scope.itemFilter.val = "";
        $scope.selectedItem = newType;
        $scope.handleCreateNewType({ newType });
      }

      $scope.selectedIndex = -1;

      $scope.searchKeyUp = function (event) {
        let resultsPreview = $filter("filter")("text"
        );
        const resultsAmount = resultsPreview.length;
        switch (event.key) {
          case "Enter": {
            if (
              $scope.selectedIndex > -1 &&
              $scope.selectedIndex < resultsAmount
            ) {
              $scope.onItemSelect(resultsPreview[$scope.selectedIndex]);
            }

            break;
          }
          case "Escape": {
            $scope.onItemDeSelect();
            break;
          }
          case "ArrowDown": {
            if ($scope.selectedIndex < resultsAmount - 1) {
              $scope.selectedIndex++;
            }
            break;
          }
          case "ArrowUp": {
            if ($scope.selectedIndex > -1) {
              $scope.selectedIndex--;
            }
            break;
          }
          default: {
            $scope.selectedIndex = -1;
            break;
          }
        }
      };

      $scope.$on("cleanSelection", function (event, data) {
        $scope.onItemDeSelect();
      });
    },
  };
});
