"use strict";

angular.module("dashboard").component("stateOfHomeCareSurvey", {
  controller: function ($rootScope, $uibModal, $window) {
    const surveyUrl = 'https://medflyt.typeform.com/to/tEDo82nD';

    const openStateOfCareSurveyModal = () => {
      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/state-of-home-care-survey.html",
        windowClass: 'state-of-home-care-survey-modal',
        size: "lg",
        allowOutsideClick: true,
        controller: ($scope, $uibModalInstance) => {
          $scope.goToSurvey = () => {
            localStorage.setItem("stateOfHomeCareSurvey", true);
            $window.open(surveyUrl);
            $uibModalInstance.close();
          }
          $scope.closeModal = () => {
            $uibModalInstance.close();
          }
        }
      });

      modalInstance.closed.then(() => {
        $rootScope.shouldDisplayStateOfHomeCareSurvey = false;
      })
    }

    this.$onInit = () => {
      const lastSurveyDate = moment().year(2021).month(4).endOf('month').endOf('d'); // END OF MAY 2021
      const isValidSurveyDate = moment(new Date()).isBefore(lastSurveyDate);
      const stateOfHomeCareSurvey = localStorage.getItem("stateOfHomeCareSurvey");

      if (isValidSurveyDate &&
        stateOfHomeCareSurvey !== "true" &&
        $rootScope.shouldDisplayStateOfHomeCareSurvey !== false) {
        $rootScope.shouldDisplayStateOfHomeCareSurvey = true;
        setTimeout(() => {
          openStateOfCareSurveyModal();
        }, 3000);
      }
    }
  }
});