"use strict";
app.directive('caregiverPatientSearch', function () {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/caregiver-patient-search.html',
        scope: {
            items: '<',
            preSelectedItem: '<',
            isDisabled: '<',
            isMultiple: '<?',
            showError: '<',
            enableCaregiverChat: '<',
            type: '@',
            chatPatientId: '<?',
            handleSelect: '&',
            handleDeselect: '&',
            showOnlyActive: '<?',
            showStatus: '<?',
            hideAvatar: '<?',
            hidePofilePicture: '<?'
        },
        controller: function ($scope, $rootScope, $filter) {
            $scope.selectedItem = null;
            $scope.selectedItems = [];
            if ($scope.preSelectedItem) {
                if($scope.isMultiple === true){
                    const preItems = [];
                    Object.values($scope.items).forEach((item)=>{
                        if($scope.preSelectedItem.indexOf(item.id)>=0){
                            preItems.push(item);
                        }
                    })
                    $scope.selectedItems = preItems;
                }else{
                    $scope.selectedItem = $scope.preSelectedItem;
                }
            }

            $scope.itemFilter = { val: '' };
            
            $scope.placeholder = $scope.type === 'caregiver' ?
                'Select caregiver...' : 'Select patient...';

            $scope.onItemSelect = function (item) {
                if ($scope.showError) {
                    $scope.showError = false;
                }
                if($scope.isMultiple === true){
                    const idx = $scope.selectedItems.indexOf(item);
                    if(idx !== -1){
                        $scope.selectedItems.splice(idx, 1);
                        return; 
                    }
                    $scope.selectedItems.push(item);
                    $scope.handleSelect({ item: $scope.selectedItems });
                }else{
                    $scope.itemFilter.val = '';
                    $scope.selectedItem = item;
                    $scope.handleSelect({ item: item });
                }
            }

            $scope.onItemDeSelect = function () {
                $scope.itemFilter.val = '';
                $scope.selectedItem = null;
                $scope.selectedIndex = -1;
                $scope.handleDeselect();
            }

            $scope.selectedIndex = -1;

            $scope.searchKeyUp = function (event) {
                let resultsPreview = $filter('filterByNameAndSize')($scope.items, $scope.itemFilter.val);
                resultsPreview = Object.values(resultsPreview);
                const resultsAmount = resultsPreview.length;
                switch (event.key) {
                    case 'Enter': {
                        if ($scope.selectedIndex > -1 && $scope.selectedIndex < resultsAmount) {
                            $scope.onItemSelect(resultsPreview[$scope.selectedIndex]);
                        }
                                
                        break;
                    }
                    case 'Escape': {
                        $scope.onItemDeSelect();
                        break;
                    }
                    case 'ArrowDown': {
                        if ($scope.selectedIndex < resultsAmount - 1) {
                            $scope.selectedIndex++;
                        }
                        break;
                    }
                    case 'ArrowUp': {
                        if ($scope.selectedIndex > -1) {
                            $scope.selectedIndex--;
                        }
                        break;
                    }
                    default: {
                        $scope.selectedIndex = -1;
                        break;
                    }
                }
            }

            $scope.openChat = (selectedItem) => {
                if ($scope.chatPatientId) {
                    selectedItem.fromList = true;
                    selectedItem.chatRoomType = {
                        caregiverId: selectedItem.id,
                        patientId: $scope.chatPatientId,
                    };
                    $rootScope.openChat(selectedItem);
                } else {
                    selectedItem.chatRoomType = {
                        agencyId: $rootScope.agencyId,
                        agencyMemberId: $rootScope.agencyMemberId,
                        caregiverId: selectedItem.id,
                        type: "AgencyMemberCaregiverChat"
                    };
                    $rootScope.openAgencyChat(selectedItem.id);
                }
            }

            $scope.$on('cleanSelection',function(event, data){
                $scope.onItemDeSelect();
            });
        }
    };
});