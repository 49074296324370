
app.controller('phoneModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, number){
  
  window.scrollTo(0,0);
  
  console.log('modal');

  $scope.pin = undefined;
  
  
 
  $scope.closeModal = function(res){
    $uibModalInstance.dismiss(res);
  };
  
  $scope.enterPin = function(pin){
    
    DatabaseApi.enterPhonePin(pin, number).then(function(res) {
           
      //$uibModalInstance.dismiss('cancel');
      toaster.pop('success', "Success", 'Phone nuber has been confirmed');
      
      $uibModalInstance.close(res.data);
    },function(err) {
      console.log(err);
      toaster.pop('error', "Incorrect PIN", 'Please try again');
    });
    
  };
  
  
});