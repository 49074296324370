'use strict'
app.controller("ptoApprovalsCtrl", function (
  $scope,
  $rootScope,
  $timeout,
  DatabaseApi,
  selectionLogic,
  NgTableParams,
  wildcard,
  FilterUtils,
  offices,
  ptoNotesModalService,
  $filter,
  itemSearchPageManager,
  $uibModal,
  toaster,
  $state,
) {

  function initialize() {
    $scope.tableParams = null;
    initFilters();
    initPageManager();
    initSelection();
    $scope.pageManager.resetSearchParamsByUrl();
    if ($scope.storageFilters !== undefined) {
      $scope.pageManager.setSearchParams($scope.storageFilters);
    }

    loadItemsIfAssetsAreReady();
  }

  const initFilters = () => {
    $scope.globalFilter = { val: "" };
    $scope.offices = offices;
    $scope.extraFilters = {
      payCodes: [],
      statuses: [{ id: "PENDING" }]
    };
    $scope.payCodesExtraSettings = {
      styleActive: true,
      displayProp: 'displayId',
      enableSearch: true,
      scrollable: true,
      scrollableHeight: "400px",
      
    };
    $scope.statuses = [
      { btnClass: 'danger', text: 'Declined', id: 'DECLINED' },
      { btnClass: 'warning', text: 'Pending', id: 'PENDING' },
      { btnClass: 'primary', text: 'Approved', id: 'APPROVED' }
    ];
    $scope.statusesExtraSettings = {
      styleActive: true,
      displayProp: 'text'
    };
    // Load cached filters
    $scope.storageFilters = FilterUtils.rootFilters.ptoApprovalsCtrl;
  };

  $scope.$on("$destroy", function () {
    const filters = $scope.pageManager.searchParams;
    FilterUtils.rootFilters.ptoApprovalsCtrl = angular.copy(filters);
  });

  const initPageManager = function () {
    const convertObjsArrToIdsStringSplitWithPipe = (objsArr) => objsArr.map(obj => obj.id).join('|');
    const convertIdsStringToObjsArr = (strCommasSeparated) => strCommasSeparated.split('|');

    $scope.pageManager = itemSearchPageManager.createSearchPageManager(
      "/caregiver_pto_instances"
    );

    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

    $scope.pageManager.initFromToDateParams();
    $scope.pageManager.initOfficeParam();
    $scope.pageManager.initSearchParamDeprecated("payCodes", $scope.extraFilters.payCodes, "payCodes",
      convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);
    $scope.pageManager.initSearchParamDeprecated("statuses", $scope.extraFilters.statuses, "statuses",
      convertObjsArrToIdsStringSplitWithPipe, convertIdsStringToObjsArr);
  };

  const initSelection = function () {
    $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
      $scope.selectionLogic.addItemToCollection(item);
    });
  };

  const findField = (array, field, id) => {
    const objFound = array.find(obj => obj.id === id);
    if (!objFound) {
      return "";
    }

    return objFound[field];
  }

  const mapItems = function (items) {
    items.forEach(function (item) {
      item.caregiver = $scope.caregiversMap[item.caregiverId];
      item.office = findField($scope.offices, 'name', item.visitInstance.officeId);
      item.labelText = findField($scope.caregiverPTOLabels, 'text', item.labelId);
      item.payrollCode = findField($scope.payrollCodes, 'displayId', item.visitInstance.payrollCodeId);
      if (item.pastPtoStatus) {
        item.pastPtoStatus.currentYearMinutesUsage = Math.round(item.pastPtoStatus.currentYearMinutesUsage);
        item.pastPtoStatus.currentYearMinutesPotential = Math.round(item.pastPtoStatus.currentYearMinutesPotential);
        item.pastPtoStatus.totalMinutesUsage = Math.round(item.pastPtoStatus.totalMinutesUsage);
        item.pastPtoStatus.totalMinutesPotential = Math.round(item.pastPtoStatus.totalMinutesPotential);
        item.isYearlyPtoTextDanger = item.pastPtoStatus.currentYearMinutesUsage + (item.status !== 'APPROVED' ? item.duration : 0)  > item.pastPtoStatus.currentYearMinutesPotential;
        item.isAllTimePtoTextDanger = item.pastPtoStatus.totalMinutesUsage + (item.status !== 'APPROVED' ? item.duration : 0) > item.pastPtoStatus.totalMinutesPotential;
      }
      $scope.selectionLogic.initItem(item);
    });
  };

  const initTable = function (items) {
    initSelection();

    const hasItems = items && items.length;
    if (hasItems) {
      mapItems(items);
    }

    const options = {
      count: 25,
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
  };

  $scope.loadItems = function () {
    $scope.globalFilter.val = "";
    $scope.pageManager.executeSearch().then(
      ({ data }) => {
        $scope.caregiverPtos = data.caregiverPtos;
        initTable($scope.caregiverPtos);
      },
      (error) => {
        toaster.pop("error", "Failed to load caregiver PTO approval data");
      }
    );
  };

  $scope.updateRowStatus = (id, status) => {
    const statuses = [{ id, status }];

    updateStatuses(statuses);
  }

  $scope.updateBulkStatuses = (newStatus) => {
    const selectedRows = $scope.selectionLogic.getSelectedItems();
    if (selectedRows.length === 0) {
      return;
    }

    const statuses = selectedRows.map(row => ({ id: row.id, status: newStatus }));

    updateStatuses(statuses);
  }

  const updateStatuses = (statuses) => {
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregiver_pto_instances",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );
    DatabaseApi.put(url, { statuses: statuses }
    ).then(
      function (res) {
        toaster.pop(
          "success",
          "Success",
          "Edited PTO statuses"
        );
        $scope.loadItems();
      }).catch(function (err) {
        toaster.pop(
          "error",
          "Something went wrong",
          "Failed to update PTO Statuses"
        );
      }
      );

  }

  $scope.openPTOInstanceGeneralNotes = ({ id, caregiver: { displayName }, visitInstance: { startTime } }) => {
    const modalTitle = displayName + "'s " + $filter('mfShortDate')(startTime, "UTC") + " PTO"
    const notesModalConfig = {
      profileId: id,
      profileName: modalTitle
    };
    ptoNotesModalService.handleNotesModalOpen(notesModalConfig);
    ptoNotesModalService.setHandlePostNote(() => {});
    ptoNotesModalService.setHandleDeleteNote(() => {});
  }

  $scope.applyGlobalSearch = function (term) {
    const filter = { $: term };
    if ($scope.tableParams) {
      angular.extend($scope.tableParams.filter(), filter);
      $timeout(() => $scope.selectionLogic.initItemsCollection($scope.tableParams.data));
    }
  };

  /* Open PTO edit modal */
  $scope.clickTableRow = function (row) {
    const newScope = $scope.$new();

    newScope.ptoEventForm = row;
    newScope.ptoEventForm.pto = row;
    newScope.ptoEventForm.visitInstance.durationInMinutes = row.duration;
    const startTime = newScope.ptoEventForm.visitInstance.startTime;
    const endTime = newScope.ptoEventForm.visitInstance.endTime;
    newScope.ptoEventForm.visitInstance.originalStart = moment(startTime.toJSON ? startTime.toJSON() : startTime);
    newScope.ptoEventForm.visitInstance.originalEnd = moment(endTime.toJSON ? endTime.toJSON() : endTime);
    newScope.ptoEventForm.label = { id: row.labelId, text: row.labelText };
    const patient = $scope.patientsMap[newScope.ptoEventForm.visitInstance.patientId];
    newScope.ptoEventForm.visitInstance.titleName = patient !== undefined ? patient.displayName : '';
    newScope.visitInstances = [newScope.ptoEventForm.visitInstance];
    newScope.viewModeOnly = true;

    newScope.caregiverId = row.caregiverId;

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/visit-instance-pto-modal.html",
      size: "md",
      controller: "visitInstancePTOModalCtrl",
      windowTopClass: "PTO-modal",
      scope: newScope,
    });
    modalInstance.result.then((res) => {
      if (res === "EDIT_SUCCESS" || res === "REMOVE_SUCCESS") {
        $scope.loadItems();
      }
    });
  };

  $scope.goToPayrollBatchPage = (payrollBatchId) => {
    $state.go('app.payroll.batch', { id: payrollBatchId });
  };

  const loadItemsIfAssetsAreReady = () => {
    if ($scope.areAssetsReady) {
      return;
    }
    checkAssets();
    if ($scope.areAssetsReady) {
      $scope.loadItems();
    }
  };
  
  const checkAssets = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.caregiversMap = DatabaseApi.caregivers();
    $scope.caregiverPTOLabels = DatabaseApi.caregiverPtoLabels();
    $scope.payrollCodes = DatabaseApi.activePayrollCodes();
    $scope.patientsMap = DatabaseApi.patients();
    const patientsState = $scope.initializeMap['patients'];
    const caregiversState = $scope.initializeMap['caregivers'];
    const caregiverPtoLabelsState = $scope.initializeMap['caregiverPtoLabels'];
    const payrollCodesState = $scope.initializeMap['payrollCodes'];
    if (patientsState && caregiversState && caregiverPtoLabelsState && payrollCodesState) {
      $scope.areAssetsReady = true;
    } else if (!caregiverPtoLabelsState) {
      DatabaseApi.getPtoLabels();
    }
  };

  $rootScope.$on("got_caregivers_data", () => {
    loadItemsIfAssetsAreReady();
  });
  
  $rootScope.$on("got_pto_labels", () => {
    loadItemsIfAssetsAreReady();
  });
  
  $rootScope.$on("got_payroll_codes", () => {
    loadItemsIfAssetsAreReady();
  });
  
  $rootScope.$on("got_patients_data", () => {
    loadItemsIfAssetsAreReady();
  });

  initialize();
});
