app.controller('I9ModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, modalData, subsection, caregiverId, DatabaseApi, $timeout, $window,$stateParams, toaster){
    $scope.i9object = {};

    $scope.subsection = subsection;

    $scope.subsection.questionGroups.forEach(function(qg) {
        if (qg.data.title === 'List A') {
            $scope.i9object.AquestionModel = qg.questions;
            var answer = $scope.i9object.AquestionModel[3].answer;
            if (answer !== '') {
                $scope.i9object.AquestionModel[3].answer = new Date(answer);
            }
        } else if (qg.data.title === 'List B') {
            $scope.i9object.BquestionModel = qg.questions;
            var answer = $scope.i9object.BquestionModel[3].answer;
            if (answer !== '') {
                $scope.i9object.BquestionModel[3].answer = new Date(answer);
            }
        } else if (qg.data.title === 'List C') {
            $scope.i9object.CquestionModel = qg.questions;
            var answer = $scope.i9object.CquestionModel[3].answer;
            if (answer !== '') {
                $scope.i9object.CquestionModel[3].answer = new Date(answer);
            }
        }
    });

    $scope.closeModal = (res) => {
        if (res === undefined) {
            res = 'cancel';
        }
        $uibModalInstance.close(res);
    };

    $scope.updateQuestion = function(question) {
        var questionUrl = 'hr/agencies/' + $rootScope.agencyId + '/caregivers/' + caregiverId + '/caregiver_application_answer_webapp';
        DatabaseApi.put(questionUrl, question).then(function(res){
            if (res.data.isSuccessful)
                toaster.pop('success', 'Question was updated successfully');
          }, function(err){
            toaster.pop('warning', 'something went wrong', '');
        });
    }
    
    $scope.submitI9Doc = function() {
        $scope.sendingI9 = true;
        const postUrl = `hr/agencies/${$rootScope.agencyId}/caregivers/${caregiverId}/send_i9_data`;
        if ($scope.i9object.listType === 'listA' || $scope.i9object.listType === 'listBAndC') {
            const body = {
                reHireDate: $scope.i9object.reHireDate ? formatDateObject($scope.i9object.reHireDate) : null,
                type: $scope.i9object.listType,
                subsection: $scope.subsection.data.id
            };
            DatabaseApi.post(postUrl, body).then((res) => {
                $window.open(res.data.url);
                $scope.sendingI9 = false;
                $scope.closeModal('Completed');
            }, (err) => {
                toaster.pop('warning', 'something went wrong', '');
                $scope.sendingI9 = false;
            });
        }
    };

    function formatDateObject(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var formattedDate = day + "/" + month + "/" + year;
        return formattedDate;
    }

});