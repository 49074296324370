"use strict";

angular.module("dashboard").component("patientWeeklyTemplate", {
  templateUrl: "admin/views/patient-weekly-template.html",
  bindings: {
    firstDayOfWeek: "<",
    patientId: "<",
    patientActive: "<"
  },
  controller: function ($rootScope, $scope, DatabaseApi) {
    const DEFAULT_FIRST_DAY_OF_WEEK = 0;

    this.$onChanges = (changes) => {
      if ("firstDayOfWeek" in changes) {
        state.firstDayOfWeek = this.firstDayOfWeek;
        state.daysOfWeek = getDaysOfWeek();
        fetchWeeklyTemplate();
      }

      if ("patientActive" in changes) {
        state.patientActive = this.patientActive;
      }
    };

    const state = {
      firstDayOfWeek: this.firstDayOfWeek || DEFAULT_FIRST_DAY_OF_WEEK,
      daysOfWeek: [],
      patientActive: this.patientActive,
      shifts: {
        isLoading: false,
        data: undefined,
        error: undefined,
      },
    };

    function fetchWeeklyTemplate() {
      state.shifts.isLoading = true;
      state.shifts.error = undefined;

      const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/weekly_template_shifts`
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":patientId", $scope.$ctrl.patientId);

      return DatabaseApi.get(url)
        .then(({ data }) => {
          state.shifts.data = mapWeeklyTemplateShifts(data);
          state.shifts.amount = state.shifts.data.length;
        })
        .catch(() => {
          state.shifts.error = "Network error";
        })
        .finally(() => {
          state.shifts.isLoading = false;
        });
    }

    /**
     * @param {number} firstDayOfWeek SUNDAY = 0, MONDAY = 1, ..., SATURDAY = 6
     * @returns {Array<object>}
     */
    function getDaysOfWeek() {
      const daysOfWeek = [
        JSJoda.DayOfWeek.SUNDAY,
        JSJoda.DayOfWeek.MONDAY,
        JSJoda.DayOfWeek.TUESDAY,
        JSJoda.DayOfWeek.WEDNESDAY,
        JSJoda.DayOfWeek.THURSDAY,
        JSJoda.DayOfWeek.FRIDAY,
        JSJoda.DayOfWeek.SATURDAY,
      ];

      for (let i = 0; i < state.firstDayOfWeek; i++) {
        daysOfWeek.push(daysOfWeek.shift());
      }

      return daysOfWeek;
    }

    function mapWeeklyTemplateShifts({ shifts: rawShifts }) {
      const shifts = [];

      for (let shiftIdx = 0; shiftIdx <= rawShifts.length-1; shiftIdx++) {
        const shift = {
          name: getNameOfShift(shiftIdx),
          dailyTemplates: [],
        };
        for (const dayOfWeek of state.daysOfWeek) {
          shift.dailyTemplates.push(
            nthIndexOf(
              rawShifts,
              shiftIdx,
              (x) => x.dayOfWeek === dayOfWeek.name()
            )
          );
        }
        if (shift.dailyTemplates.filter(Boolean).length > 0) {
          shifts.push(shift);
        }
      }

      return shifts;
    }

    function nthIndexOf(array, index, predicate) {
      return array.filter(predicate)[index];
    }

    function getNameOfShift(shiftIdx) {
      const shiftNum = shiftIdx + 1;
      switch (shiftNum % 10) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return `${shiftNum}th`;
      }
    }

    function handleClickRetry() {
      fetchWeeklyTemplate();
    }

    $scope.state = state;
    $scope.handleClickRetry = handleClickRetry;

    $rootScope.$on("refresh_visits", fetchWeeklyTemplate);
  },
});
