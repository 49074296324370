"use strict";
angular.module("dashboard").component("datePickerWithSideButtonsComponent", {
  templateUrl: "admin/views/date-picker-with-side-buttons-component.html",
  bindings: {
    date: "<",
    minDate: "<",
    startingDay: "<",
    sideButtons: "<",
    onChange: "&",
  },
  controller: function ($scope, $rootScope, generalUtils) {
    $scope.changeSources = {
      init: "init",
      input: "input",
      datepicker: "datepicker"
    };

    this.$onInit = () => {
      $scope.today = new Date();

      $scope.state = {
        show: false,
        model: $scope.$ctrl.date,
        sideButtons: $scope.$ctrl.sideButtons,
      };

      $scope.datepickerOptions = {
        minDate: $scope.$ctrl.minDate,
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek,
      };

      $scope.onChange($scope.changeSources.init);
    };

    const setInputModel = () => {
      $scope.state.inputModel = getDateAsString();
    };

    const setDatepickerModel = () => {
      $scope.state.model = $scope.state.inputModel && $scope.state.inputModel !== ""
        ? new Date($scope.state.inputModel)
        : null;
    };

    const setActiveSideButton = () => {
      $scope.state.sideButtons.forEach((btn) => (btn.active = false));

      if ($scope.state.model) {
        const activeSideButton =
        $scope.state.sideButtons.find((btn) => generalUtils.isSameDayNativeDate(btn.date, $scope.state.model))
            ?? $scope.state.sideButtons[$scope.state.sideButtons.length - 1];

        activeSideButton.active = true;
      }
    };

    const getDateAsString = () => {
      return $scope.state && $scope.state.model
        ? $scope.state.model.toLocaleDateString("en-US")
        : "";
    };

    $scope.toggle = () => {
      $scope.state.show = !$scope.state.show;
    };

    $scope.setDate = (date) => {
        $scope.state.model = date;
        $scope.onChange($scope.changeSources.datepicker);
    };

    $scope.onChange = (source) => {
      if (source === $scope.changeSources.input) {
        setDatepickerModel();
      } else {
        setInputModel();
      }
      
      if (source === $scope.changeSources.datepicker) {
        $scope.toggle();
      }

      
      setActiveSideButton();
      $scope.$ctrl.onChange()($scope.state.model);
    };

    this.$onChanges = (changedData) => {
      if (!$scope.state) return;
      if (!changedData || !changedData.date || !changedData.date.currentValue) return;
      
      $scope.state.model = changedData.date.currentValue;
      $scope.onChange($scope.changeSources.init);
    };
  },
});
