app.controller("walkthroughSwiperModalCtrl", function (
    $scope,
    modalData,
    $uibModalInstance,
    Storage,
    walkthroughsService
) {
    $scope.currentWalkthroughColumnName = modalData.walkthroughColumnName;
    $scope.currentElementPosServer = modalData.walkthroughElementPos;
    $scope.currentElementPosLocal = modalData.walkthroughElementPos;
    $scope.elements = modalData.walkthroughElements;

    $scope.hasSwiperReachEnd = false;
    if ($scope.currentElementPosLocal === $scope.elements.length - 1) {
        $scope.hasSwiperReachEnd = true;
    }

    $scope.moveToElementPos = (pos) => {
        if ($scope.currentElementPosLocal !== pos && pos <= $scope.currentElementPosServer)
            $scope.currentElementPosLocal = pos;
    }

    $scope.nextSlide = async () => {
        if ($scope.hasSwiperReachEnd) {
            //? done
            await $scope.updateElementPosition($scope.elements.length);
            $uibModalInstance.close();
        }
        else if ($scope.currentElementPosLocal > $scope.elements.length - 1) {
            return;
        }
        else if ($scope.currentElementPosLocal + 1 <= $scope.currentElementPosServer) {
            $scope.currentElementPosLocal++;
        }
        else {
            //? update db
            $scope.updateElementPosition($scope.currentElementPosServer + 1);
            $scope.currentElementPosServer++;
            $scope.currentElementPosLocal++;

            if ($scope.currentElementPosLocal === $scope.elements.length - 1) {
                $scope.hasSwiperReachEnd = true;
            }
        }
    }

    $scope.updateElementPosition = async (pos) => {
        await walkthroughsService.updateWalkthroughPosition($scope.currentWalkthroughColumnName, pos);
        let { walkthroughs } = Storage.getObject("walkthroughs");
        walkthroughs[$scope.currentWalkthroughColumnName] = pos;
        Storage.setObject("walkthroughs", { walkthroughs });
    }
});