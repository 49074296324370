app.controller('payerSetupModalCtrl', function ($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService, planOfCareHHAXService, hhaxIntegrationService) {
    const init = () => {
        if (data.id) {
            setPhoneAndFax(data, 'remove');
            initPlanOfCareHHAXMapping();
            hhaxIntegrationService.getHHAXIntegrationPayers().then(res => {
                $scope.isPayerIntegratedHHAX = res.payers.includes(data.id)
            });
        }

        $scope.fields = FormService.getFields(fields, data, $scope.viewModeOnly);
        $scope.form = {};
    }

    const initPlanOfCareHHAXMapping = () => {
        $scope.isLoadingPOC = true;
        planOfCareHHAXService.getMedflytPlanOfCareItems().then(res => {
            $scope.plansOfCare = res.planOfCares;
            $scope.selectedPOCTypeOfficeTabId = $scope.plansOfCare[0].officeId;
            planOfCareHHAXService.getPayerHHAXPlanOfCareItems(data.id).then(res => {
                $scope.hhaxPlanOfCareItems = res.items;
                $scope.hhaxPlanOfCareItemsOptions = $scope.hhaxPlanOfCareItems.map(item => ({ id: item.id, label: `${item.taskCode} - ${item.taskName}` }));
                $scope.plansOfCare = $scope.plansOfCare.map(planOfCareType => {
                    return {
                    ...planOfCareType, columns: planOfCareType.columns.map(pocItem => {
                        const hhaxItem = pocItem.hhaxItemsMapping.find(payerItem => payerItem.payerId === data.id);
                        return {...pocItem, hhaxItemId: hhaxItem === undefined ? {} : {id: hhaxItem.hhaxItemId}};
                    })}
                });
                $scope.isLoadingPOC = false;
            });
        });
    }

    $scope.submitForm = function () {
        if ($scope.form.data.$invalid) {
            return;
        }

        var newData = FormService.prepareFormData($scope.fields);
        newData.zipCode = newData.zipCode.toString();
        newData.addressComponents = null;
        setPhoneAndFax(newData, 'add');

        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payers/' + data.id, newData) //{[field.name]: field.value})
                .then(function (res) {
                    console.log(res);
                    toaster.pop('success', "Payer saved");
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change Payer settings")
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payers', newData).then(function (res) {
                console.log(res);
                toaster.pop('success', "Payer saved");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add Payer");
            });
        }
    };

    $scope.fieldChanged = function(item){
        if(item.type.type === "Boolean" && data.id){
            $scope.submitForm();
        }
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    function setPhoneAndFax(data, action) {
        var phonePrefix = new RegExp("^[+]1", "i");
        switch (action) {
            case 'add': {
                if(data.phone  && !phonePrefix.test(data.phone)) {
                    data.phone = "+1" + data.phone;
                }
                if(data.fax && !phonePrefix.test(data.fax)) {
                    data.fax = "+1" + data.fax;
                }
                break
            }

            case 'remove': {
                if(data.phone !== null && phonePrefix.test(data.phone)) {
                    data.phone = data.phone.substr(2);
                }
                if(data.fax !== null && phonePrefix.test(data.fax)) {
                    data.fax = data.fax.substr(2);
                }
                break
            }
            default: {
                break;
            }
        }
    }

    $scope.setCurrentPOCItems = (planOfCareType) => {
        $scope.selectedPOCTypeTab = planOfCareType;
    }

    const editPOCMapping = (medflytHHAXItemId) => {
        const mappingUpsertParams = {
            payerId: data.id,
            planOfCareTypeId: $scope.selectedPOCTypeTab.planOfCareTypeId,
            medflytPOCItemId: $scope.editableMedflytPOCItem.id,
            hhaxPOCItemId: medflytHHAXItemId
        };

        planOfCareHHAXService.createHHAXMedflytPOCMapping(mappingUpsertParams).then(() => {
          toaster.pop(
            "success",
            "Success",
            "Plan of care item mapping updated successfully"
          );
        }).catch((err) => {
            toaster.pop("error", "Failed to update Plan of care item mapping");
        });
      }

    $scope.mappingPOCEvents = {
        onItemSelect: (item) => {
          editPOCMapping(item.id);
        }
      }

      $scope.selectHHAXPOCSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true,
        showCheckAll: false,
        showUncheckAll: false,
        selectionLimit: 1,
        closeOnSelect: true,
        singleSelection: true,
        smartButtonMaxItems: 1
    };

    $scope.setSelectedMedflytPOCItem = (medflytPOCItem) => {
        $scope.editableMedflytPOCItem = medflytPOCItem;
    }

    init();
});
