app.controller('patientTreatmentModalCtrl', function($scope, $rootScope, Consts, $http, $state, $uibModalInstance, patient, contract, DatabaseApi, toaster){

    window.scrollTo(0,0);

    $scope.treatment = {
        contract: contract.id,
        startOfPeriod: null,
        endOfPeriod: null,
        accepted_services: [],
        experience_required_by_the_caregiver: [],
        auth_number: null,
        eligibility_sunday: 0,
        eligibility_monday: 0,
        eligibility_tuesday: 0,
        eligibility_wednesday: 0,
        eligibility_thursday: 0,
        eligibility_friday: 0,
        eligibility_saturday: 0,
        rn_assessment_done_by_admission_source: null,
        rn_assessment_required: null,
        rn_assessment_done: null,
        next_rn_assessment_required: null
    };

    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.exit = function(){
        $uibModalInstance.dismiss();
    };


    $scope.cal = {};

    $scope.setDates = function(id, date){

        if (date) {

            var localDateObj = JSJoda.LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
            $scope.treatment[id] = localDateObj.format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd"));

        } else {
            $scope.treatment[id] = null;
        }
        console.log($scope.treatment);
    };

/*
    $scope.setContractId = function(){
        $scope.treatment.id = parseInt($scope.treatment.contractObj);
    };
*/

    $scope.submit = function(){
        $scope.sending = true;
        console.log($scope.treatment);

        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + patient.id + '/contracts/' + contract.id + '/treatment', $scope.treatment).then(function (res) {
            $scope.closeModal();
        }, function (err) {
            $scope.sending = false;
            toaster.pop('error', "Something went wrong", "could not add treatment");
        });

    };




});
