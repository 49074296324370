app.controller('newComplianceItemModalCtrl', function($scope, $rootScope, Consts, $http, $state, $uibModalInstance, section, item, items, DatabaseApi, toaster){

    window.scrollTo(0,0);

    //$scope.


    $scope.section = section;
    $scope.item = item;
    $scope.items = items;

    $scope.itemsArr = [];

    if(!$scope.item){
        $scope.isNewItem = true;
        $scope.item = angular.copy(items);
        $scope.item.sectionLabel = section;
    }

    for(var key in items){
        if(key !== 'name' && key !== 'sectionLabel' && key !== 'removedAt') {
            $scope.itemsArr.push(key);
        }
    }

    console.log($scope.section);
    console.log($scope.item);
    console.log($scope.items);

    $scope.closeModal = function(){
        $uibModalInstance.close();
    };

    $scope.saveSettings = function(){
        $uibModalInstance.close($scope.item);
    };




});
