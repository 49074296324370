app.component("patientPoc", {
    templateUrl: "admin/views/patient-poc.html",
    bindings: {
        patientId: '<',
        isModal: '<',
        sendEmail: '&',
        patientDocumentVersionId: '<'
    },
    controller: function ($scope, $rootScope, DatabaseApi, toaster, $uibModal, $window, NgTableParams) {
        
        function initialize() {
            $scope.isLoading = true;
            $scope.getPatient();
        }
    
        $scope.checked = true;

        let hasAgencyMemberSignature;
    
        $scope.getPatient = () => {
            DatabaseApi.get(`agencies/${$rootScope.agencyId}/patients/${$scope.patientId}`).then((res) => {
                $scope.patient = res.data;
                $scope.getPlanOfCare();
            }, (err) => {
                toaster.pop('error', 'Oops! Something went wrong', 'Failed loading patient data');
                $scope.loading = false;
            });
        }
    
        $scope.getPlanOfCare = () => {
            $scope.isLoading = true;
            const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${$scope.patientId}/plans_of_care`;
            DatabaseApi.get(url).then((res) => {
                if (res.data.plansOfCare.length === 0) {
                    return loadHasAgencyMemberSignature();
                }

                if ($scope.patientDocumentVersionId) {
                    // SHOW SPECIFIC ONE
                    setRelevantPatientDocumentVersion(res.data.plansOfCare);
                } else {
                    $scope.planOfCare = res.data.plansOfCare[0];
                }
                $scope.mapPlanOfCare();
                initTable($scope.planOfCare.pocItemWithAnswerList);

            }, (err) => {
                toaster.pop('error', 'Oops! Something went wrong', 'Failed Loading plan of care');
            }).finally(() => ($scope.isLoading = false));
        }
    
        $scope.mapPlanOfCare = function () {
            if (!$scope.planOfCare) {
                return;
            }
    
            $scope.planOfCare.pocItemWithAnswerList = [];
            let currentTitle = '';
            $scope.planOfCare.docItems.forEach(docItem => {
                if (docItem.itemType === "bigHeader") currentTitle = docItem.label;
                else {
                    docItem.section = currentTitle;
                    if (docItem.itemType === "check") {
                        docItem.type = "Task";
                        $scope.planOfCare.pocItemWithAnswerList.push(docItem);
                    }
                    // else if (docItem.itemType === "smallHeader") docItem.type = "Attribute";
                    // else if (docItem.itemType === "yesNo") docItem.type = "Quality Of Care";
                }
            });

            $scope.planOfCare.pocItemWithAnswerList = $scope.planOfCare.pocItemWithAnswerList.map(item => {
                item.everyVisit = !item.days && !(item.frequencyPerWeek && item.visitPerWeek) && item.isRequired;
                item.asNeeded = !item.isRequired && !(item.frequencyPerWeek && item.visitPerWeek) && !item.days;
                if (item.isRequired && !item.days && item.frequencyPerWeek && item.visitPerWeek) {
                    item.frequency = `${item.frequencyPerWeek}/${item.visitPerWeek} days`;
                } else if (item.everyVisit) {
                    item.frequency = "Every visit";
                }
                const pocItemWithCode = $scope.planOfCare.planOfCareItems.find(x => x.id === item.id);
                item.code = pocItemWithCode && pocItemWithCode.code ? pocItemWithCode.code : "";
                return item;
            });
        };

        const setRelevantPatientDocumentVersion = (plansOfCare) => {
            const versionedPlanOfCare = plansOfCare.find(p => p.patientDocumentVersionId === $scope.patientDocumentVersionId);
            if(versionedPlanOfCare === undefined) {
                return toaster.pop("warning", "Could'nt find relevant version plan of care to present");
            }
            $scope.planOfCare = versionedPlanOfCare;
        };
    
        const initTable = (items) => {
            const oldTotal = $scope.tableParams?.total?.() || 0;
            const options = {
                count: 15
            };
            let page = false,
                filters = undefined,
                sorting = undefined;
            if ($scope.tableParams) {
                options.count = $scope.tableParams.count();
                page = $scope.tableParams.page();
                filters = $scope.tableParams.filter();
                sorting = $scope.tableParams.sorting();
            }
            if (filters) options.filter = filters;
    
            $scope.tableParams = new NgTableParams(options, {
                dataset: items,
                sorting: sorting,
                counts: [15, 25, 50, 100]
            });
    
            if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
        }
    
        $scope.viewPDF = (planOfCareId) => {
            const url =
                "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plan_of_care/:planOfCareId/generate_url"
                    .replace(":agencyId", $rootScope.agencyId)
                    .replace(":agencyMemberId", $rootScope.agencyMemberId)
                    .replace(":patientId", $scope.patientId)
                    .replace(":planOfCareId", planOfCareId);

            $scope.isLoadingPlanOfCarePDF = true;
            DatabaseApi.get(url).then((res) => {
                const fileUrl = res.data.fileUrl;
                if (fileUrl !== null) {
                    return window.open(fileUrl, "_blank");
                } else {
                    return toaster.pop("warning", "Could'nt preview plan of care pdf");
                }
            }).catch(error => {
                toaster.pop("error", "Something went wrong", "Failed to fetch plan of care pdf");
            }).finally(() => $scope.isLoadingPlanOfCarePDF = false);
        }
    
        $scope.newPoc = () => {
            if (!$scope.planOfCare) { // Creating a new plan of care
                if (!hasAgencyMemberSignature) {
                    toaster.pop('warning', 'Agency member signature is missing, please add it via Profile first');
                    return;
                }
            }

            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/new-plan-of-care-modal.html',
                size: 'lg',
                controller: 'newPlanOfCareModalCtrl',
                resolve: {
                    planOfCare: () => $scope.planOfCare,
                    patientId: () => $scope.patientId,
                    patientCurrentOfficeId: () => $scope.patient.currentOfficeId
                }
            });

            modalInstance.result.then((res) => {
                if (res === 'success') {
                    $scope.getPlanOfCare();
                    $rootScope.$emit('patient_poc_updated');
                }
            }, (e) => {
            });
        }

        function loadHasAgencyMemberSignature() {
            const url = 'agencies/' + $rootScope.agencyId + '/coordinators/' + $rootScope.agencyMemberId + '/signature';

            DatabaseApi.get(url).then(function(res) {
                hasAgencyMemberSignature = res && res.data && res.data.url;
            }, (err) => {
                hasAgencyMemberSignature = false;
            });
        }

        $scope.openPatientPocHistoryModal = () => {
            return $rootScope.openPatientPocHistoryModal($scope.patient);
        }

        this.$onInit = async function () {
            $scope.patientId = $scope.$ctrl.patientId;
            $scope.isModal = $scope.$ctrl.isModal;
            $scope.patientDocumentVersionId = $scope.$ctrl.patientDocumentVersionId;
            if ($scope.$ctrl.sendEmail) {
                $scope.sendEmail = (document, type) => {
                    $scope.$ctrl.sendEmail({document: document, type: type});
                }
            }
            initialize();
        }
    }
});