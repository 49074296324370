"use strict";

app.component("physicians", {
    templateUrl: "admin/views/physicians.html",
    bindings: {
    },
    controller: function (
        $scope,
        $rootScope,
        DatabaseApi,
        NgTableParams,
        toaster,
        SweetAlert,
        physiciansService,
        $uibModal
    ) {

        this.physiciansGlobalFilter = { val: "" };
        const initialize = () => {
            this.physicians = DatabaseApi.physicians();
            initPhysiciansTable();
        };

        const initPhysiciansTable = () => {
            const options = {
                count: 10,
                sorting: { id: "desc" }
            };

            this.physiciansTable = new NgTableParams(options, {
                counts: [],
                dataset: this.physicians
            });
        };

        this.physiciansGlobalFilter = () => {
            const filter = { $: this.physiciansGlobalFilter.val };
            if (this.physiciansTable) {
                angular.extend(this.physiciansTable.filter(), filter);
            }
        };

        this.openNewPhysicianModal = (physician) => {
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/new-physician-modal.html',
                size: 'lg',
                controller: 'newPhysicianModalCtrl',
                resolve: {
                    data: function () {
                        if (physician) return physician;
                        return undefined;
                    }
                }
            });
            modalInstance.result.then(function (res) {
                if (res.id) {
                    res.displayName = res.firstName + ' ' + res.lastName;
                    let existingPhysician = $scope.$ctrl.physicians.find(p => p.id == res.id);
                    if (existingPhysician) {
                        existingPhysician = res;
                    } else {
                        $scope.$ctrl.physicians.push(res);
                        DatabaseApi.storePhysicians($scope.$ctrl.physicians);
                    }

                    initPhysiciansTable();
                }
            }, function () {
            });
        };

        const deletePhysician = (physicianId) => {
            physiciansService.removePhysician(physicianId).then(function (res) {
                $scope.$ctrl.physicians = $scope.$ctrl.physicians.filter(p => p.id !== physicianId);
                initPhysiciansTable();
                toaster.pop('success', "Physician removed successfully");
                DatabaseApi.storePhysicians($scope.$ctrl.physicians);
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Failed to remove physician");
            });
        };

        this.handleClickDeletePhysician = (physicianId) => {
            SweetAlert.swal(
                {
                    title: "Remove Physician",
                    text: "Are you sure you want to remove this physician?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3898ff",
                    confirmButtonText: "Yes, remove",
                    closeOnConfirm: true
                },
                hasConfirmed => hasConfirmed && deletePhysician(physicianId)
            );
        };

        $rootScope.$on('got_physicians', function (event) {
            initialize();
        });

        this.$onInit = function () {
            initialize();
        };
    }
});