app.directive('profileCollapsibleSection', ['$rootScope', function ($rootScope) {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'admin/views/profile-collapsible-section.html',
        scope: {
            notesId: '@',
            sectionId: '@',
            notesAmount: '@',
            sectionTitle: '@',
            titleLabelText: '@',
            titleLabelClass: '@',
            defaultCollapsed: '<',
            onOpenNotesClicked: '&',
            buttons: '<', // Accepts array of button objects with title and action(method for ng-click) and show(for ng-show)
        },
        link: function ($scope, elem, attr) {
            $scope.onToggleCollapseClick = (itemId) => {
                $('#' + itemId + ' div.ibox-content').slideToggle();
                $('#' + itemId + ' .ibox-title .ibox-tools a.collapse-link i').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
            }
            $scope.onAddNote = (notesId) => {
                $scope.onOpenNotesClicked({ notesId, isNew: true });
            }
            $scope.openSectionNotes = (notesId) => {
                $scope.onOpenNotesClicked({ notesId });
            }
        }
    };
}]);