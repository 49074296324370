app.directive("pageTabs", function ($state) {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/page-tabs-template.html',
    scope: {
      tabs: '=',
    },
    link: function (scope) {
        if (!scope.tabs || !(scope.tabs instanceof Array)) {
            console.error("The 'tabs' input should be Array of objects with data to render the tabs (objects with 'route' and 'name')");
        }

        scope.tabStateArray = [];
        scope.tabs.forEach(function (tab) {
            if (!tab.route) {
                var errorMessage = "Missing 'route' for one or more of the tabs.";
                console.error(errorMessage);
                throw new Error(errorMessage);
            }
            if (!tab.name) {
                var errorMessage = "Missing 'name' for one or more of the tabs.";
                console.error(errorMessage);
                throw new Error(errorMessage);
            }

            var relatedState = $state.get(tab.route);
            if (!relatedState) {
                console.error("The state '" + route + "' does not exist");
            }
            
            var tabStateObject = {
                route: tab.route,
                tabName: tab.name,
                state: $state.get(tab.route),
            };
            scope.tabStateArray.push(tabStateObject);
        });

        scope.isTabSelected = function (tabState) {
            return $state.current.url.includes(tabState.state.url);
        };
    }
  };
});
