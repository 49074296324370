app.controller('MatchingCaregiversModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, visits, cluster, DatabaseApi, toaster, Socket, SweetAlert, $timeout, Analytics){

    window.scrollTo(0,0);
    console.log(visits);

    $scope.isLoading = false;
    $scope.engagements = cluster.engagements;

    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    $scope.goToProfile = function(id){
        //$uibModalInstance.close('cancel');
    };

    $scope.pickCaregiver = function(caregiver){

        if(!caregiver) return toaster.pop('error', 'Something went wrong', 'please reload/refresh page');

        var caregiverId = caregiver;

        //for(var i = 0 ; i < 2 ; i++) {

        clusterId = cluster.id;

            var url = 'agencies/' + $rootScope.agencyId +
                '/caregivers/' + caregiverId +
                '/clusters/' + clusterId +
                '/assign_cluster';
            DatabaseApi.post(url).then(function (res) {
                toaster.pop('success', "Success", 'Caregiver has assigned to visit');

                Analytics.event('cluster-modal-assigned', {
                    clusterId: clusterId,
                    status: 'scheduled',
                    newCaregiver: caregiverId,
                    broadcastedTo: cluster.engagements.broadcastedTo.length,
                    accepted: cluster.engagements.acceptedBy.length,
                    visits: [visits[0].id,visits[1].id]
                });

                //$rootScope.sweepScore.points += 100;
                $uibModalInstance.dismiss('cancel');

            }, function (err) {
                var errText = '';
                if (err.data) errText = err.data.error ? err.data.error : '';

                if (err.status === 409) toaster.pop('error', "Assigning Conflict", errText);
                else toaster.pop('error', "Something went wrong", errText || err.data);
            });
      //  }



    };



});