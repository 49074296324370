'use strict';
app.controller('patientPayRateModalCtrl', function (DatabaseApi, $rootScope, toaster, $scope, $uibModalInstance, SweetAlert, dateUtils) {
    $scope.isEditMode = $scope.$resolve.patientPayRateInitValues !== undefined;
    $scope.payrollCodes = DatabaseApi.payrollCodes()
       .filter((code) => !code.nonPayable && code.isActive)
       .map((code) => ({ id: code.id, label: code.displayId }));

    $scope.rate = { type: "FIXED_DIFF", payCode: {} };

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    const addNewPayRate = function () {
        const body = {
            type: $scope.rate.type,
            basedOnCaregiverRate: $scope.rate.basedOnCaregiverRate === undefined ? false : $scope.rate.basedOnCaregiverRate,
            hourly: $scope.rate.type === 'PERCENTAGE' ? $scope.rate.hourly / 100 : Number(($scope.rate.hourly * 100).toFixed(2)),
            daily: $scope.rate.type === 'PERCENTAGE' ? $scope.rate.daily / 100 : Number(($scope.rate.daily * 100).toFixed(2)),
            startDate: JSJoda.LocalDate.from(JSJoda.nativeJs($scope.rate.startDate)),
            endDate: JSJoda.LocalDate.from(JSJoda.nativeJs($scope.rate.endDate)),
            payCode: $scope.rate.payCode.id
        }
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + $scope.$resolve.patientId + '/pay_rates', body).then(function (res) {
            toaster.pop('success', "Patient pay rate updated successfully");
            $uibModalInstance.close('success');
        }, function () {
            toaster.pop('error', "Something went wrong", "Could not save patient pay rate");
        })
    }

    $scope.save = function () {
        if ($scope.rate.startDate && $scope.rate.endDate && ($scope.rate.hourly > 0 || $scope.rate.daily > 0)) {
            if ($scope.rate.daily === 0) {
                SweetAlert.swal(
                    {
                        title: `Daily rate is zero`,
                        type: "info",
                        text: `Are you sure you want to save daily rate as zero?`,
                        showCancelButton: true,
                        confirmButtonText: `Save as ${($scope.rate.hourly * 13).toFixed(2)} (hourly X 13)`,
                        cancelButtonText: "Save as 0"
                    },
                    hasConfirmed => {
                        if (hasConfirmed) {
                            $scope.rate.daily = ($scope.rate.hourly * 13).toFixed(2);
                        }
    
                        addNewPayRate();
                    }
                );
            } else {
                addNewPayRate();
            }
        } else {
            toaster.pop('error', "Something went wrong", "Dates and rates must be filled");
        }
    }

    $scope.payCodesMultiselectSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true,
        selectionLimit: 1,
        showUncheckAll: false,
        showCheckAll: false
    };

    $scope.cancelModal = function () {
        $uibModalInstance.dismiss('dismiss');
    }

    $scope.updateFromOptions = function () {
        $scope.endDatePickerOptions = { minDate: $scope.rate.startDate };
    }

    const initValues = () => {
        if ($scope.isEditMode) {
            $scope.patientPayRateInitValues = $scope.$resolve.patientPayRateInitValues;
            $scope.rate.type = $scope.patientPayRateInitValues.type;
            $scope.rate.basedOnCaregiverRate = $scope.patientPayRateInitValues.basedOnCaregiverRate;
            $scope.rate.payCode = { 
                id: $scope.patientPayRateInitValues.payCodeId,
                name: $scope.patientPayRateInitValues.payCodeName
            };
            $scope.rate.daily = $scope.patientPayRateInitValues.type === 'PERCENTAGE' ? $scope.patientPayRateInitValues.daily * 100 : Number(($scope.patientPayRateInitValues.daily / 100).toFixed(2));
            $scope.rate.hourly = $scope.patientPayRateInitValues.type === 'PERCENTAGE' ? $scope.patientPayRateInitValues.hourly * 100 : Number(($scope.patientPayRateInitValues.hourly / 100).toFixed(2));
            $scope.rate.startDate = dateUtils.ymdStringToDate($scope.patientPayRateInitValues.startDate);
            $scope.rate.endDate = dateUtils.ymdStringToDate($scope.patientPayRateInitValues.endDate);
        }
    }

    initValues();
});