/**
 * ORIGINAL FILE = caregiverAssignedTasks.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
const taskTableSettings = {
    count: 25,
    sorting: {
        "task.createdAt": "desc",
    },
};
class CaregiverAssignedTasksCtrl {
    tasksService;
    toaster;
    $rootScope;
    NgTableParams;
    dateUtils;
    dateRangeDefaultOptions;
    createDropdownFilter;
    tasks = [];
    isLoading;
    filters;
    table;
    dropdownFilterManager;
    dateRangeOptions;
    dropdownFilterEvents = {};
    constructor(tasksService, toaster, $rootScope, NgTableParams, dateUtils, dateRangeDefaultOptions, createDropdownFilter) {
        this.tasksService = tasksService;
        this.toaster = toaster;
        this.$rootScope = $rootScope;
        this.NgTableParams = NgTableParams;
        this.dateUtils = dateUtils;
        this.dateRangeDefaultOptions = dateRangeDefaultOptions;
        this.createDropdownFilter = createDropdownFilter;
        this.table = new this.NgTableParams(taskTableSettings, { dataset: [] });
        this.isLoading = false;
        this.filters = {
            dateRange: {
                startDate: null,
                endDate: null,
            },
            dropdowns: [
                {
                    title: "Task Status",
                    entityPath: ["status", "status"],
                    options: [],
                    values: [],
                },
                {
                    title: "Task Name",
                    entityPath: ["title"],
                    options: [],
                    values: [],
                },
            ],
        };
        this.dropdownFilterManager = this.createDropdownFilter({
            dropdowns: this.filters.dropdowns,
        });
        this.dropdownFilterEvents = {
            onSelectionChanged: () => this.onFilterChange(),
        };
        this.dateRangeOptions = {
            ...dateRangeDefaultOptions,
            opens: "right",
            eventHandlers: {
                "apply.daterangepicker": () => this.loadTable(),
            }
        };
    }
    // HACK FOR COMPONENT BINDING
    caregiverId = 0;
    dropdownFilterExtraSettings = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
    };
    $onInit = () => {
        this.loadTable();
    };
    loadTable = () => {
        const caregiverTaskFilters = {
            from: this.filters.dateRange.startDate !== null
                ? this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.startDate.format("YYYY-MM-DD"))
                : null,
            to: this.filters.dateRange.endDate !== null
                ? this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.endDate.format("YYYY-MM-DD"))
                : null,
        };
        this.isLoading = true;
        this.tasksService
            .getCaregiverTasks(this.caregiverId, caregiverTaskFilters)
            .then((tasks) => {
            this.tasks = tasks;
            this.setTable(tasks);
        })
            .catch(() => {
            this.toaster.pop("error", "Something went wrong", "Could not get caregiver tasks");
        })
            .finally(() => (this.isLoading = false));
    };
    resetDueDate = () => {
        this.filters.dateRange = {
            startDate: null,
            endDate: null,
        };
        this.loadTable();
    };
    onFilterChange = () => {
        this.setTable(this.tasks);
    };
    setTable = (tasks) => {
        const filteredTasks = this.filterTasks(tasks);
        this.table = new this.NgTableParams(taskTableSettings, { dataset: filteredTasks });
        this.dropdownFilterManager.setOptionsFromLocalData(tasks);
    };
    filterTasks(tasks) {
        return tasks.filter((task) => {
            return this.dropdownFilterManager.filter(task);
        });
    }
}
const CaregiverAssignedTasks = {
    controller: CaregiverAssignedTasksCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/caregiver-assigned-tasks.html",
    bindings: {
        caregiverId: "<",
    },
};
angular.module("dashboard").component("caregiverAssignedTasks", CaregiverAssignedTasks);
export {};
