"use strict";
app.component('mfTimeInput', {
    templateUrl: 'admin/views/mf-time-input.html',
    bindings: {
        id: '@',
        timeModel: '<',
        label: '@',
        isSmall: '@',
        onChange: '&',
    },
    controller: function ($scope) {

        this.handleChangeDateModel = (newVal) => {
            const timeMoment = moment()
                .set("hour", newVal.getHours())
                .set("minute", newVal.getMinutes());
            if ($scope.$ctrl.onChange) {
                $scope.$ctrl.onChange({ newVal: timeMoment });
            }
        }

        function updateTimeLabels() {
            $scope.dateModel = $scope.$ctrl.timeModel ? $scope.$ctrl.timeModel.toDate() : null;
        }

        this.$doCheck = () => {
            if ($scope.updatedTimeModel && $scope.$ctrl.timeModel && !$scope.updatedTimeModel.isSame($scope.$ctrl.timeModel, "minute")) {
                $scope.updatedTimeModel = $scope.$ctrl.timeModel.clone();
                updateTimeLabels();
            } else if (
                (!$scope.$ctrl.timeModel && $scope.dateModel)
                || ($scope.$ctrl.timeModel && !$scope.dateModel)
            ) {
                $scope.updatedTimeModel = $scope.$ctrl.timeModel ? $scope.$ctrl.timeModel.clone() : null;
                updateTimeLabels();
            }
        };

        this.$onInit = function () {
            $scope.updatedTimeModel = $scope.$ctrl.timeModel ? $scope.$ctrl.timeModel.clone() : null;
            updateTimeLabels();
        }
    }
});