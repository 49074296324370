app.controller('patientsUpcomingTasksCtrl', function ($scope, $rootScope, NgTableParams, toaster, DatabaseApi, $filter, $uibModal, itemSearchPageManager, generalUtils, $window, wildcard, mfModal, tasksService) {
    const MANUAL_SUBMISSION_STRING = "MANUAL_SUBMISSION_STRING";
    $scope.gotData = false;
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.patientsMap = DatabaseApi.patients();
    $scope.officesMap = DatabaseApi.offices() || {};
    $scope.agencyMembersMap = DatabaseApi.getAgencyMembers() || {};
    $scope.documentTypes = DatabaseApi.patientDocumentTypes();
    $scope.planOfCareTypes = DatabaseApi.plansOfCare();
    $scope.patientTaskTemplates = DatabaseApi.patientTaskTemplates();
    $scope.upcomingTasksGlobalFilter = { val: "" };
    $scope.upcomingTasksInitialDateRanges = {
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
    };
    $scope.upcomingTasksCertificationEndDates = {
        from: "",
        to: ""
    };

    $scope.upcomingTasksFilters = {
        documents: [],
        statuses: [],
        offices: [],
        taskContexts: [],
        cities: [],
        zipCodes: [],
        names: [],
        caregivers: [],
    };

    $scope.yesNoOptions = [
        { id: 0, label: 'Yes', value: true },
        { id: 1, label: 'No', value: false }
    ];

    $scope.upcomingTasksStatusMultiSelectOptions = tasksService.statuses;

    $scope.taskContextOptions = [
        { id: 0, label: "Regular", value: TaskTemplateContext.Regular },
        { id: 1, label: "Start of Care", value: TaskTemplateContext.StartOfCare },
        { id: 2, label: "Reassessment", value: TaskTemplateContext.Reassessment },
    ];

    $scope.taskStatusesMap = {};
    $scope.upcomingTasksStatusMultiSelectOptions.forEach(status => {
        $scope.taskStatusesMap[status.status] = status;
    }); 

    $scope.cityOptions = [];
    $scope.zipCodeOptions = [];
    $scope.taskNameOptions = [];
    $scope.caregiverOptions = [];

    $scope.assignMultiSelect = () => {
        const selectedTasks = $scope.upcomingTasks.filter(x => x.selected && ["FutureTask", "DraftTask"].includes(x.type));
        if (selectedTasks.length === 0) {
            return toaster.pop("warning", "No tasks selected");
        }
        const templateIds = [...new Set(selectedTasks.map(x => x.taskTemplateId))];
        const templates = $scope.patientTaskTemplates.filter(x => templateIds.includes(x.id)).map(x => x.allowedCertifications);
        const allowedCertifications = templates.reduce((acc, curr) => generalUtils.intersect(acc, curr));

        let newScope = $scope.$new();
        newScope.allowedCertifications = allowedCertifications

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-task-assign-multiple-modal.html',
            size: 'sm',
            controller: 'patientTaskAssignMultipleModal',
            scope: newScope,
            windowClass: "patient-task-assign-multiple-modal"
        });

        modalInstance.result.then(caregiverId => {
            const url = `agencies/:agencyId/agency_members/:agencyMemberId/assign_patient_tasks`
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);

            DatabaseApi.post(
                url,
                {
                    tasks: convertDocumentsArray(selectedTasks),
                    caregiverId: caregiverId,
                    isBroadcasting: false,
                    broadcastDistance: null,
                }
            ).then(() => loadItems());
        }, () => {});
    }

    let filterModalInstance;
    let upcomingTasksTableDateRangeChangedLoaded = false;

    function convertDocumentsArray(tasks) {
        return tasks.map(task => ({
            ...task,
            documents: task.documents === undefined
                ? undefined
                : task.documents.map(document => document.id)
                    .filter(documentId => Boolean(documentId))
        }));
    }

    function initialize() {
        if (Object.keys($scope.caregiversMap).length === 0) {
            return;
        }

        $scope.gotData = true;
        setPlanOfCareType();
        setOfficeMultiSelectOptions();
        setDocumentsMultiSelectOptions();
        initPageManager();
        loadItems();
    }

    function initPageManager() {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/patient_task_instances");
        $scope.pageManager.initFromToDateParams(false, true, "taskStartDate", "taskDueDate");
        $scope.pageManager.initFromToDateParams(false, false, "certificationPeriodEndFrom", "certificationPeriodEndTo");
        $scope.pageManager.updateSearchParamValue("taskStartDate", $scope.upcomingTasksInitialDateRanges.from);
        $scope.pageManager.updateSearchParamValue("taskDueDate", $scope.upcomingTasksInitialDateRanges.to);
        $scope.pageManager.updateSearchParamValue("certificationPeriodEndFrom", "");
        $scope.pageManager.updateSearchParamValue("certificationPeriodEndTo", "");
    };

    $scope.resetCertificationPeriodEndRange = () => {
        $scope.pageManager.updateSearchParamValue("certificationPeriodEndFrom", "");
        $scope.pageManager.updateSearchParamValue("certificationPeriodEndTo", "");
        $scope.hasValueCertificationPeriodEnd = false;
        loadItems();
    };

    function loadItems() {
        $scope.pageManager.executeSearch().then((res) => {
            mapUpcomingTasks(res.data.patientTaskInstances);
            setCityOptions();
            setZipCodeOptions();
            setTaskNameOptions();
            setCaregiverOptions();
            filterUpcomingTasksTable();
        }, (err) => {
            toaster.pop("error", "Failed to load upcoming tasks");
        });
    };

    function mapUpcomingTasks(tasks) {
        $scope.upcomingTasks = [];
        $scope.documentsOptionsMap = {};
        $scope.officeOptionsMap = {}
        tasks.forEach(function (task) {
            enrichPatientTask(task);
            $scope.upcomingTasks.push(task);
        });
    }

    function setCityOptions() {
        $scope.cityOptions = [
            ...new Set($scope.upcomingTasks.map(task => task.patientAddress.city))
        ].map(city => ({
            id: city,
            label: city
        }));
    }

    function setZipCodeOptions() {
        $scope.zipCodeOptions = [
            ...new Set($scope.upcomingTasks.map(task => task.patientAddress.zipCode))
        ].map(zipCode => ({
            id: zipCode,
            label: zipCode
        }));
    }

    function setTaskNameOptions() {
        $scope.taskNameOptions = [
            ...new Set($scope.upcomingTasks.map(task => task.title))
        ].map(taskName => ({
            id: taskName,
            label: taskName
        }));
    }

    function setCaregiverOptions() {
        $scope.caregiverOptions = [
            ...new Set($scope.upcomingTasks.map(task => task.caregiver?.displayName))
        ].map(caregiverName => ({
            id: caregiverName,
            label: caregiverName
        }));
    }

    const openPatientTaskInstanceDocument = (docId, patientId) => {
        if (!docId|| !patientId) {
            return;
        }

        const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
            + "/patients/" + patientId + '/scheduled_documents/' + docId + "/generate_url";

        DatabaseApi.get(url).then(function (res) {
            $window.open(res.data.fileUrl);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Failed to view document pdf');
        });
    }

    const openPatientTaskPlanOfCareDocument = (planOfCareId) => {
        if (!planOfCareId) {
            return;
        }

        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/plan_of_care/:planOfCareId/signed_url",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            planOfCareId
        );

        DatabaseApi.get(url).then(function (res) {
            $window.open(res.data.url);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Failed to view plan of care document pdf');
        });
    }

    const sendPatientTaskDocumentPDFEmail = (document, patientId, type) => {
        const patient = $scope.patientsMap[patientId];
        if (!patient) {
            return;
        }
        let docsType = type === 'planOfCare' ? 'planOfCare' : 'patient';
        $uibModal.open({
            templateUrl: 'admin/views/email-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return [document]; },
                patientId: function () { return patientId; },
                patientFullName: function () { return patient.displayName; },
                docsType: function () { return (docsType); }
            },
            controller: 'emailModalCtrl'
        });
    };

    const sendPatientTaskDocumentPDFEfax = (document, patientId) => {
        $uibModal.open({
            templateUrl: 'admin/views/fax-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return [document]; },
                patientId: function () { return patientId; }
            },
            controller: 'faxModalCtrl'
        });
    };

    const reopenPatientTaskDocumentForSubmission = (instance, document) => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":scheduledDocId", document.documentScheduleId);

        DatabaseApi.post(url).then(function (res) {
            document.submitted = null;
            document.classNames = 'label-error label-with-options-error';
            setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
            instance.completionDate = null;
            setTaskStatus(instance);
            toaster.pop('success', "Success", "Document is open for submission");
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not reopen for submission");
        });
    }

    const setPatientTaskInstanceDocumentsStatisticsAndOptions = (instance) => {
        instance.filledDocumentsAmount = instance.documents.filter(d =>
            d.submitted === true).length;
        instance.missingDocumentsAmount = instance.documents.filter(d =>
            d.submitted !== true
        ).length;

        instance.documents.forEach(doc => {
            if (doc.submitted) {
                doc.classNames = 'label-success label-with-options-success';
            } else {
                doc.classNames = 'label-error label-with-options-error'
            }

            let options = [];
            const documentObj = {
                id: doc.documentScheduleId,
                title: doc.title
            }

            if (doc.submitted && doc.fileUrl && doc.fileUrl !== MANUAL_SUBMISSION_STRING) {
                options.push({ displayText: 'View PDF', callback: openPatientTaskInstanceDocument, callbackParam1: doc.documentScheduleId, callbackParam2: instance.patientId });
                options.push({ displayText: 'Send PDF to e-mail', callback: sendPatientTaskDocumentPDFEmail, callbackParam1: documentObj, callbackParam2: instance.patientId });
                options.push({ displayText: 'Send PDF to fax', callback: sendPatientTaskDocumentPDFEfax, callbackParam1: documentObj, callbackParam2: instance.patientId });
            } 
            
            if (doc.submitted) {
                options.push({
                    displayText: 'Open for submission',
                    callback: reopenPatientTaskDocumentForSubmission,
                    callbackParam1: instance,
                    callbackParam2: doc
                });
                if (!doc.isScanned) {
                    options.push({
                        displayText: 'Review form',
                        callback: reviewForm,
                        callbackParam1: doc,
                        callbackParam2: instance.taskInstanceId,
                        callbackParam3: instance.patientName,
                        callbackParam4: instance.caregiver.displayName,
                        callbackParam5: instance.patientId
                    });
                }
            }
            else if (!doc.submitted) {
                options.push({
                    displayText: 'Submit manually',
                    callback: submitPatientTaskDocumentManually,
                    callbackParam1: instance,
                    callbackParam2: doc
                });
                options.push({
                    displayText: 'Mark as submitted',
                    callback: markPatientTaskDocumentAsSubmitted,
                    callbackParam1: instance,
                    callbackParam2: doc
                });
                options.push({
                    displayText: 'Review form',
                    callback: reviewForm,
                    callbackParam1: doc,
                    callbackParam2: instance.taskInstanceId,
                    callbackParam3: instance.patientName,
                    callbackParam4: instance.caregiver.displayName,
                    callbackParam5: instance.patientId
                });
            }

            if (!$scope.documentsOptionsMap[instance.patientTaskInstanceId]) {
                $scope.documentsOptionsMap[instance.patientTaskInstanceId] = {};
            }
            $scope.documentsOptionsMap[instance.patientTaskInstanceId][doc.documentTypeId] = options;
        });
    }

    async function prepareDocumentReplaceConstants(patientName, caregiverName) {
        var data = {};
        data.agencyName = $rootScope.user.agency.name;
        data.caregiverName = caregiverName;
        data.patientName = patientName;
        data.agencyAddress = $rootScope.user.agency.address;
        data.agencyPhone = $filter("americanphone")($rootScope.user.agency.officePhone);
        return data;
    }

    const reviewForm = (document, instanceId, patientName, caregiverName, patientId) => {
        let url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_instances/${instanceId}/patient_documents/${document.documentTypeId}`;
        DatabaseApi.get(url).then(function (res) {
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/views/edit-patient-doc-modal.html',
                size: 'lg',
                controller: 'editPatientDocModalCtrl',
                resolve: {
                    patientId: patientId,
                    document: res.data.document,
                    documentReplaceConstants: () => prepareDocumentReplaceConstants.bind({}, patientName, caregiverName)
                }
            });

            modalInstance.result.then(function (data) {
                if (data && data.fileUrl && data.date) {
                    document.submittedAt = data.date;
                    document.pdfUrl = data.fileUrl;
                }
            }, function () {});
        }, function () {
            toaster.pop('error', "Something went wrong");
        });
    };

    const submitPatientTaskDocumentManually = (instance, document) => {
        const url = "agencies/:agencyId/agency_member/:agencyMemberId/patient_task_instance/:patientTaskInstanceId/patient_document"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":patientTaskInstanceId", instance.taskInstanceId);
        
        $rootScope.openUploadDocumentPdfModal({
            url: url,
            document: document, successCallback: (result) => {
                if (result !== null) {
                    document.submitted = true;
                    document.documentScheduleId = result.data.id;
                    document.fileUrl = result.data.url;
                    setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
                }
            }
        });
    }

    const markPatientTaskDocumentAsSubmitted = (instance, document) => {
        const url = "agencies/:agencyId/agency_member/:agencyMemberId/patient_task_instance/:patientTaskInstanceId/patient_document"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":patientTaskInstanceId", instance.taskInstanceId);

        DatabaseApi.post(url,
            {
                base64: null,
                documentId: document.documentTypeId,
                documentVersion: null,
                documentTitle: null
            }
        ).then(function (res) {
            toaster.pop('success', "Manual submission successful", '', ' ');
            document.submitted = true;
            document.documentScheduleId = res.data.id;
            instance.completionDate = res.data.patientTaskInstanceCompletionDate;
            setPatientTaskInstanceDocumentsStatisticsAndOptions(instance);
            setTaskStatus(instance);
        }, function (err) {
            toaster.pop('error', 'Error', 'Manual submission failed');
        });
    }

    function enrichPatientTask(task) {
        let caregiver;
        task.selected = false;
        if (task.caregiverId) {
            caregiver = DatabaseApi.getCaregiverById(task.caregiverId);
            if (!caregiver) {
                return;
            }
            task.caregiver = caregiver;
        }

        let relevantDocs = [];
        if (task.planOfCareTypeId && $scope.planOfCare) {
            relevantDocs.push($scope.planOfCare.name)
        }
        const patient = $scope.patientsMap[task.patientId];
        task.area = patient && patient.address && patient.address.components && patient.address.components.neighborhood || null;
        task.patientAddress = {
            text: patient?.address?.text,
            city: patient?.address?.components?.formatedAddressDetails?.city,
            zipCode: patient?.address?.components?.formatedAddressDetails?.zip5 ?? patient?.address?.components?.formatedAddressDetails?.zip4,
        };

        if (task.documents.length > 0 && typeof task.documents[0] === "number") {
            task.documents = task.documents.map(documentId => ({
                ...$scope.documentTypes.find(d => d.id === documentId),
                documentTypeId: documentId
            }));
        }

        task.documents.forEach(doc => {
            if (doc.submitted) {
                doc.classNames = 'label-success label-with-options-success';
            } else {
                doc.classNames = 'label-error label-with-options-error';
            }

            let options = [];
            if (task.type === "RegularTask") {
                const documentObj = {
                    id: doc.documentScheduleId,
                    title: doc.title
                }

                if (doc.submitted && doc.fileUrl && doc.fileUrl !== MANUAL_SUBMISSION_STRING) {
                    options.push({ displayText: 'View PDF', callback: openPatientTaskInstanceDocument, callbackParam1: doc.documentScheduleId, callbackParam2: task.patientId });
                    options.push({ displayText: 'Send PDF to e-mail', callback: sendPatientTaskDocumentPDFEmail, callbackParam1: documentObj, callbackParam2: task.patientId });
                    options.push({ displayText: 'Send PDF to fax', callback: sendPatientTaskDocumentPDFEfax, callbackParam1: documentObj, callbackParam2: task.patientId });
                } 
                
                if (doc.submitted) {
                    options.push({
                        displayText: 'Open for submission',
                        callback: reopenPatientTaskDocumentForSubmission,
                        callbackParam1: task,
                        callbackParam2: doc
                    });
                    if (!doc.isScanned) {
                        options.push({
                            displayText: 'Review form',
                            callback: reviewForm,
                            callbackParam1: doc,
                            callbackParam2: task.taskInstanceId,
                            callbackParam3: task.patientName,
                            callbackParam4: task.caregiver ? task.caregiver.displayName : null,
                            callbackParam5: task.patientId
                        });
                    }
                }
                else if (!doc.submitted) {
                    options.push({
                        displayText: 'Submit manually',
                        callback: submitPatientTaskDocumentManually,
                        callbackParam1: task,
                        callbackParam2: doc
                    });
                    options.push({
                        displayText: 'Mark as submitted',
                        callback: markPatientTaskDocumentAsSubmitted,
                        callbackParam1: task,
                        callbackParam2: doc
                    });
                    options.push({
                        displayText: 'Review form',
                        callback: reviewForm,
                        callbackParam1: doc,
                        callbackParam2: task.taskInstanceId,
                        callbackParam3: task.patientName,
                        callbackParam4: task.caregiver ? task.caregiver.displayName : null,
                        callbackParam5: task.patientId
                    });
                }
                options.push({
                    displayText: 'Delete document',
                    callback: $scope.onClickRemovePatientScheduledDocument,
                    callbackParam1: doc,
                    callbackParam2: task
                });
            }

            if (task.patientTaskInstanceId === undefined || doc.documentTypeId === undefined) {
                task.patientTaskInstanceId = -1;
                doc.documentTypeId = -1;
            }

            if (!$scope.documentsOptionsMap[task.patientTaskInstanceId]) {
                $scope.documentsOptionsMap[task.patientTaskInstanceId] = {};
            }
            $scope.documentsOptionsMap[task.patientTaskInstanceId][doc.documentTypeId] = options;

            task.taskTemplateContextLabel = "Regular";
            if (task.taskTemplateContext === TaskTemplateContext.StartOfCare) {
                task.taskTemplateContextLabel = "Start of Care";
            } else if (task.taskTemplateContext === TaskTemplateContext.Reassessment) {
                task.taskTemplateContextLabel = "Reassessment";
            }
        });

        if (task.planOfCareTypeId) {
            if (task.planOfCare) {
                const pocClassNames = task.planOfCare.submitted ?
                    'label-success label-with-options-success' : 'label-error label-with-options-error';
                task.documents.push({
                    documentTypeId: -1,
                    title: "Plan Of Care",
                    classNames: pocClassNames,
                    submitted: task.planOfCare.submitted
                });

                if (task.planOfCare.submitted && task.planOfCare.fileUrl) {
                    const options = [];
                    options.push({ displayText: 'View PDF', callback: openPatientTaskPlanOfCareDocument, callbackParam1: task.planOfCare.planOfCareId });
                    options.push({
                        displayText: 'Send PDF to e-mail',
                        callback: sendPatientTaskDocumentPDFEmail,
                        callbackParam1: { id: task.planOfCare.planOfCareId },
                        callbackParam2: task.patientId,
                        callbackParam3: 'planOfCare'
                    });
                    
                    if (!$scope.documentsOptionsMap[task.patientTaskInstanceId]) {
                        $scope.documentsOptionsMap[task.patientTaskInstanceId] = {};
                    }
                    $scope.documentsOptionsMap[task.patientTaskInstanceId][-1] = options;
                }
            } else {
                task.documents.push({
                    documentTypeId: -1,
                    title: "Plan Of Care",
                    classNames: 'label-error label-with-options-error'
                });
            }
        }

        task.filledDocumentsAmount = task.documents.filter(d =>
            d.submitted === true).length;
        task.missingDocumentsAmount = task.documents.filter(d =>
            d.submitted !== true
        ).length;

        setTaskStatus(task);

        task.displayForms = false;
    }

    function setTaskStatus(task) {
       task.status = tasksService.getTaskStatus(task).status;
    }

    const upcomingTaskFilterByMethods = {
        taskHasDocuments: function (task, selectedDocuments, hasPlanOfCare) {
            return (task.documents.find(({ documentTypeId }) => selectedDocuments.indexOf(documentTypeId) !== -1) !== undefined)
                || (hasPlanOfCare ? typeof task.planOfCareTypeId === "number" : false);
        },
        taskHasStatus: (task, selectedStatuses) => selectedStatuses.indexOf(task.status) !== -1,
        taskIsInOffice: (task, selectedOfficeIds) => selectedOfficeIds.includes(task.patientOfficeId),
        taskIsInContexts: (task, selectedTaskContexts) => selectedTaskContexts.includes(task.taskTemplateContext),
    };

    function filterUpcomingTasksTable() {
        if (!$scope.upcomingTasks) return;

        let filters = [];


        const selectedPlanOfCareObj = $scope.upcomingTasksFilters.documents.find((doc) => doc.id === 0);
        // filter out plan of care
        const selectedDocuments = $scope.upcomingTasksFilters.documents.map((doc) => doc.id).filter(docId => docId !== 0);
        if (selectedDocuments.length > 0 || selectedPlanOfCareObj) {
            filters.push(task => upcomingTaskFilterByMethods.taskHasDocuments(task, selectedDocuments, selectedPlanOfCareObj ? true : false));
        }

        const selectedStatuses = $scope.upcomingTasksFilters.statuses.map((obj) =>
            $scope.upcomingTasksStatusMultiSelectOptions.find(option => option.id === obj.id).status);

        if (selectedStatuses.length > 0) {
            filters.push(task => upcomingTaskFilterByMethods.taskHasStatus(task, selectedStatuses));
        }

        const selectedOfficeIds = $scope.upcomingTasksFilters.offices.map(office => office.id);
        if (selectedOfficeIds.length > 0) {
            filters.push(task => upcomingTaskFilterByMethods.taskIsInOffice(task, selectedOfficeIds));
        }

        const selectedTaskContexts = $scope.upcomingTasksFilters.taskContexts.map(context =>
            $scope.taskContextOptions.find(option => option.id === context.id).value
        );
        if (selectedTaskContexts.length > 0) {
            filters.push(task => upcomingTaskFilterByMethods.taskIsInContexts(task, selectedTaskContexts));
        }

        if ($scope.upcomingTasksFilters.cities.length > 0) {
            filters.push(
                (task) => $scope.upcomingTasksFilters.cities.map((x) => x.id).includes(task.patientAddress.city)
            );
        }

        if ($scope.upcomingTasksFilters.zipCodes.length > 0) {
            filters.push(
                (task) => $scope.upcomingTasksFilters.zipCodes.map((x) => x.id).includes(task.patientAddress.zipCode)
            );
        }

        if ($scope.upcomingTasksFilters.names.length > 0) {
            filters.push(
                (task) => $scope.upcomingTasksFilters.names.map((x) => x.id).includes(task.title)
            );
        }

        if ($scope.upcomingTasksFilters.caregivers.length > 0) {
            filters.push(
                (task) => $scope.upcomingTasksFilters.caregivers.map((x) => x.id).includes(task.caregiver?.displayName)
            );
        }

        let filteredUpcomingTasks = $scope.upcomingTasks;
        if (filters.length > 0) {
            filteredUpcomingTasks = filteredUpcomingTasks.filter(function (task) {
                var isFiltered = true;
                for (var idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](task);
                }
                return isFiltered;
            });
        }

        initUpcomingTasksTable(filteredUpcomingTasks);
    }

    function initUpcomingTasksTable(items) {
        $scope.tableData = items;
        const options = {
            count: 25
        };
        $scope.upcomingTasksTable = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            getData: function (params) {
                if (!$scope.tableData || $scope.tableData.length === 0) {
                    return [];
                }

                let datesOnly = $scope.tableData.filter(a => a.createdAt !== null && a.createdAt !== undefined);
                let noDates = $scope.tableData.filter(a => a.createdAt === null || a.createdAt === undefined);

                if (params.orderBy().includes('+createdAt')) {
                    datesOnly.sort(function (a, b) {
                        return new Date(a.createdAt) - new Date(b.createdAt)
                    });
                } else {
                    datesOnly.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    });
                }
                let data = datesOnly.concat(noDates);
                data = $filter('filter')(data, params.filter());

                params.total(data.length);
                let orderedData = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                if (params.sorting()) {
                    orderedData = $filter('orderBy')(orderedData, params.orderBy());
                }
                
                return orderedData;
            }
        });

        if ($scope.upcomingTasksGlobalFilter.val) {
            $scope.applyUpcomingTasksGlobalSearch($scope.upcomingTasksGlobalFilter.val);
        }
    }

    $scope.applyUpcomingTasksGlobalSearch = (term) => {
        const filter = { $: term };
        if ($scope.upcomingTasksTable) {
            angular.extend($scope.upcomingTasksTable.filter(), filter);
        }
    };


    $scope.$watch('upcomingTasksFilters', function () {
        filterUpcomingTasksTable();
    }, true);

    $scope.onUpcomingTasksTableDateRangeChanged = (startDate, endDate, fieldName) => {
        if (!upcomingTasksTableDateRangeChangedLoaded && fieldName === "certificationPeriodEnd") {
            upcomingTasksTableDateRangeChangedLoaded = true;
            return;
        }
        if (!$scope.pageManager) {
            return;
        }
        if (fieldName === "taskDateRanges") {
            $scope.pageManager.updateSearchParamValue("taskStartDate", startDate ? new Date(startDate) : "");
            $scope.pageManager.updateSearchParamValue("taskDueDate", endDate ? new Date(endDate) : "");
        } else if (fieldName === "certificationPeriodEnd") {
            $scope.pageManager.updateSearchParamValue("certificationPeriodEndFrom", startDate ? new Date(startDate) : "");
            $scope.pageManager.updateSearchParamValue("certificationPeriodEndTo", endDate ? new Date(endDate) : "");
            $scope.hasValueCertificationPeriodEnd = startDate && endDate;
            $scope.upcomingTasksCertificationEndDates = { from: startDate, to: endDate };
        } else {
            return;
        }
        loadItems();
    }

    $scope.preOpenCaregiverModal = (row) => {
        if (!row.caregiverId) return;
        if (!$scope.caregiversMap) $scope.caregiversMap = DatabaseApi.caregivers() || {};
        const caregiver = $scope.caregiversMap[row.caregiverId];
        $rootScope.openCaregiverModal(row.caregiverId, caregiver);
    };

    $scope.preOpenPatientModal = (row) => {
        if (!row.patientId) return;
        $rootScope.openPatientModal(row.patientId);
    }

    $scope.handleAction = (task) => {
        const action = task.status === "Broadcasting"
            ? $scope.stopBroadcast
            : $scope.cancelTask;

        action(task);
    };

    $scope.stopBroadcast = (task) => {
        const modal = mfModal.create({
            subject: "Stop broadcast",
            message: `
                Are you sure you want to stop selected task broadcasts?
            `,
            cancelLabel: "Cancel",
            confirmLabel: "Stop selected broadcasts",
            variant: "danger",
            onConfirm: () => {
                modal.setLoading(true);
        
                const url = wildcard(
                    "agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances_broadcast/:taskInstanceId",
                    $rootScope.agencyId,
                    $rootScope.agencyMemberId,
                    task.taskInstanceId
                );

                DatabaseApi.delete(url).then((_) => {
                    toaster.pop("success", "Successfully stopped task broadcast");
                    $rootScope.$emit("patient_task_saved");
                    modal.close();
                    loadItems();
                }, (_) => {
                    toaster.pop("error", "Oops...", "Can't stop selected broadcasts");
                    modal.update({
                        isLoading: false,
                        message: "Something went wrong, please try again.",
                    });
                });
            }
        });
    };

    $scope.cancelTask = (task) => {
        let newScope = $scope.$new();
        newScope.task = task;
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-upcoming-task-cancel-modal.html',
            size: 'md',
            controller: 'patientUpcomigTaskCancelModalCtrl',
            scope: newScope,
        });

        modalInstance.result.then(() => {
            loadItems();
        }, function () { });
    }

    $scope.openNewPatientTaskModal = (task) => {
        let newScope = $scope.$new();
        newScope.task = null;
        newScope.type = null;
        
        const isNewTask = task === undefined;
        
        if (task) {
            newScope.task = task;
            newScope.task.documents = newScope.task.documents.filter(doc => doc !== undefined).map(doc => doc.id);
            newScope.type = task.type;
        }

        newScope.documentsMultiSelectOptions = newScope.documentsMultiSelectOptions.filter(doc => {
            if (doc.isPlanOfCare) {
                return true;
            }

            const document = newScope.documentTypes.find(d => d.id === doc.id);
            return document && document.versions && document.versions.find(v => v.isPublished) !== undefined;
        });

        // add plan of care to array with unique id '0'
        if ($scope.planOfCare) {
            newScope.documentsMultiSelectOptions.push({
                id: 0,
                label: $scope.planOfCare.name,
                isPlanOfCare: true
            });
        }

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/new-patient-task-modal.html',
            size: 'lg',
            controller: 'newPatientTaskModalCtrl',
            scope: newScope,
            windowClass: "new-patient-task-modal"
        });

        modalInstance.result.then(function (res) {
            if (res.taskId) {
                $scope.newRowPatientTaskInstanceId = res.patientTaskInstanceId;
                enrichPatientTask(res);

                if (isNewTask) {
                    $scope.upcomingTasks.push(res);
                } else {
                    const index = $scope.upcomingTasks.findIndex(task => task.taskId === res.taskId);
                    $scope.upcomingTasks[index] = res;
                }
                
                filterUpcomingTasksTable();
            }
        }, function () {
        });
    }

    $scope.selectUpcomingTasksDocumentsExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.selectOfficesExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.selectCitiesExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '500px',
        enableSearch: true
    };

    $scope.selectZipCodesExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '500px',
        enableSearch: true
    };

    $scope.selectTaskNameExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '500px',
        enableSearch: true
    };

    $scope.selectCaregiverExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '500px',
        enableSearch: true
    };

    $scope.selectUpcomingTasksStatusesExtraSettings = {
        styleActive: true,
        scrollable: true
    };

    $scope.selectUpcomingTaskSingleFiltersExtraSettings = {
        styleActive: true,
        selectionLimit: 1,
        singleSelection: true,
        smartButtonMaxItems: 1
    };

    $scope.selectUpcomingTasksContextsExtraSettings = {
        styleActive: true,
        smartButtonMaxItems: 3,
    };

    $scope.openFilterModal = () => {
        filterModalInstance = $uibModal.open({
            templateUrl: 'admin/views/patients-upcoming-tasks-filters-modal.html',
            size: 'md',
            scope: $scope,
            resolve: {},
            backdrop: true,
            backdropClass: 'transparent-backdrop',
            windowClass: "modal modal-slide-in-right uib-side-modal"
        });

        filterModalInstance.closed.then(() => upcomingTasksTableDateRangeChangedLoaded = false);

        return filterModalInstance;
    }

    $scope.closeFilterModal = () => {
        filterModalInstance?.close();
    };

    function setOfficeMultiSelectOptions() {
        if (!$scope.officesMap) {
            return;
        }

        $scope.officeTaskMultiSelectOptions = $scope.officesMap.map(office => ({id: office.id, label: office.name}));
    }

    function setDocumentsMultiSelectOptions() {
        if (!$scope.documentTypes) {
            return;
        }

        $scope.documentsMultiSelectOptions = $scope.documentTypes
            .filter(doc => doc.title !== "")
            .map(doc => ({ id: doc.id, label: doc.title }));

        // add plan of care to array with unique id '0'
        if ($scope.planOfCare) {
            $scope.documentsMultiSelectOptions.push({
                id: 0,
                label: $scope.planOfCare.name,
                isPlanOfCare: true
            })
        }
    }

    function setPlanOfCareType() {
        if (!$scope.planOfCareTypes || $scope.planOfCareTypes.length === 0) {
            return;
        }

        $scope.planOfCare = $scope.planOfCareTypes[0];
    }

    const handleConfirmRemoveScheduledDocument = (document, task) => {

        tasksService
        .deletePatientTaskDocument(task.patientId, task.taskInstanceId, document.documentTypeId)
        .then(() => {
            toaster.pop("success", "Document successfully deleted");
            task.documents = task.documents.filter(doc => doc.documentTypeId !== document.documentTypeId);
            task.missingDocumentsAmount -= 1;

            if (task.documents.length === 0) {
                const taskIndex = $scope.upcomingTasks.findIndex(t => t.taskInstanceId === task.taskInstanceId);
                $scope.upcomingTasks.splice(taskIndex, 1);
                filterUpcomingTasksTable();
            }
        })
        .catch(() => {
            toaster.pop("error", "Something went wrong", "Failed to delete document");
        })
    };

    $scope.onClickRemovePatientScheduledDocument = function (document, task) {

        const modal = mfModal.create({
            subject: `Delete ${document.title} Document`,
            message: "Are you sure you want to delete this document?",
            variant: "danger",
            confirmLabel: "Confirm",
            cancelLabel: "Cancel",
            onConfirm: () => modal.close() && handleConfirmRemoveScheduledDocument(document, task)
        });
    };

    $rootScope.$on("got_data", function (event) {
        if ($scope.gotData) return;
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        $scope.patientsMap = DatabaseApi.patients();
        $scope.agencyMembersMap = DatabaseApi.getAgencyMembers();
        initialize();
    });

    $rootScope.$on("got_patient_document_types", function (event) {
        $scope.documentTypes = DatabaseApi.patientDocumentTypes();
        setDocumentsMultiSelectOptions();
    });

    $rootScope.$on("got_offices", function (event) {
        $scope.officesMap = DatabaseApi.offices();
        setOfficeMultiSelectOptions();
    });

    $rootScope.$on("got_patient_task_templates", function (event) {
        $scope.patientTaskTemplates = DatabaseApi.patientTaskTemplates();
    });


    $rootScope.$on("got_plan_of_care_type", function (event) {
        $scope.planOfCareTypes = DatabaseApi.plansOfCare();
        setPlanOfCareType();;
    });

    initialize();
});
