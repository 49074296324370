app.controller('visitLabelsCtrl', function ($scope, $uibModal, DatabaseApi, $rootScope, toaster, $http, Consts, wildcard) {
  $scope.agencyVisitLabels = DatabaseApi.visitLabelsData();
  $scope.patientDocumentTypeVisitLabelAssocs = $scope.agencyVisitLabels !== undefined && $scope.agencyVisitLabels.patientDocsAssoc !== undefined && $scope.agencyVisitLabels.patientDocsAssoc.length > 0 ? $scope.agencyVisitLabels.patientDocsAssoc : [];
  $scope.visitLabelsCertificationAssocs = $scope.agencyVisitLabels !== undefined && $scope.agencyVisitLabels.certificationsAssoc !== undefined && $scope.agencyVisitLabels.certificationsAssoc.length > 0 ? $scope.agencyVisitLabels.certificationsAssoc : [];
  
  $scope.agencyVisitLabelsAsMulti = [];
  $scope.patientDocumentTypes = [];
  $scope.visitLabelCertifications = {};
  $scope.visitLabelPatientDocumentTypes = {};
  $scope.visitLabelCertificationEvents = {};
 
  $scope.planOfCareTypes = DatabaseApi.plansOfCare();
  $scope.agencyPatientDocumentTypes = DatabaseApi.patientDocumentTypes();
  if ($scope.agencyPatientDocumentTypes && $scope.agencyPatientDocumentTypes.length > 0) {
    $scope.agencyAllPatientDocumentTypes = $scope.agencyPatientDocumentTypes.map(x => { return { id: x.id, isPlanOfCare: false, label: x.title } });
  };
  // Const async
  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem) => certificationItem.certification);

  $scope.allCertificatoins = $scope.certifications.map((x, idx) => { return { id: idx, label: x } });
  init();

  $rootScope.$on('got_visit_labels', (event) => {
    // get agency visit labels
    $scope.agencyVisitLabels = DatabaseApi.visitLabelsData();
    $scope.patientDocumentTypeVisitLabelAssocs = $scope.agencyVisitLabels.patientDocsAssoc;
    $scope.visitLabelsCertificationAssocs = $scope.agencyVisitLabels.certificationsAssoc;
    init();
  });

  $rootScope.$on('got_plan_of_care_type', (event) => {
    $scope.planOfCareTypes = DatabaseApi.plansOfCare();

    init();
  })

  $rootScope.$on('got_patient_document_types', (event) => {
    $scope.agencyPatientDocumentTypes = DatabaseApi.patientDocumentTypes();
    if ($scope.agencyPatientDocumentTypes && $scope.agencyPatientDocumentTypes.length > 0) {
      $scope.agencyAllPatientDocumentTypes = $scope.agencyPatientDocumentTypes.map(x => { return { id: x.id, isPlanOfCare: false, label: x.title } });
    };
    init();
  })

  function init() {
    // assig nvisit labels
    if ($scope.agencyVisitLabels && $scope.agencyVisitLabels.labels && $scope.agencyVisitLabels.labels.length > 0) {
      $scope.agencyVisitLabelsAsMulti = $scope.agencyVisitLabels.labels.map(x => { return { id: x.id, name: x.name, color: x.color, isActive: x.isActive } });
      $scope.agencyVisitLabelsAsMulti.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }
    for (const vl of $scope.agencyVisitLabelsAsMulti) {
      $scope.visitLabelCertifications[vl.name] = [];
      $scope.visitLabelPatientDocumentTypes[vl.name] = [];
    }
    // assign certifications
    if ($scope.agencyVisitLabels && $scope.agencyVisitLabels.labels && $scope.agencyVisitLabels.labels.length > 0
      && $scope.visitLabelsCertificationAssocs && $scope.visitLabelsCertificationAssocs.length) {
      for (const visitLabelCert of $scope.visitLabelsCertificationAssocs) {
        const filtredVisitLabel = $scope.agencyVisitLabels.labels.filter(x => x.id === visitLabelCert.visitLabelId)[0];
        const filteredVertification = $scope.allCertificatoins.filter(f => f.label === visitLabelCert.certification)[0];
        if (filtredVisitLabel !== undefined && filteredVertification !== undefined) {
          $scope.visitLabelCertifications[filtredVisitLabel.name].push({ id: filteredVertification.id, label: visitLabelCert.certification });
        }
      }
    }

    // assign patient documents 
    if ($scope.agencyVisitLabels && $scope.agencyVisitLabels.labels && $scope.agencyVisitLabels.labels.length > 0
      && $scope.agencyAllPatientDocumentTypes && $scope.agencyAllPatientDocumentTypes.length > 0) {
      for (const assoc of $scope.patientDocumentTypeVisitLabelAssocs) {
        const filteredVisitLabel = $scope.agencyVisitLabels.labels.filter(x => x.id === assoc.visitLabelId)[0];
        const filtredAgencyDocumentType = $scope.agencyAllPatientDocumentTypes.filter(x => x.id === assoc.patientDocumentTypeId)[0];
        if (filteredVisitLabel !== undefined && filtredAgencyDocumentType !== undefined) {
          $scope.visitLabelPatientDocumentTypes[filteredVisitLabel.name].push({ id: filtredAgencyDocumentType.id, label: filtredAgencyDocumentType.label, isPlanOfCare: false });
        }
      }


      if ($scope.planOfCareTypes && $scope.planOfCareTypes.length
        && $scope.agencyAllPatientDocumentTypes && $scope.agencyAllPatientDocumentTypes.length > 0) {
        let id = 0;
        for (const patientDocs of $scope.agencyAllPatientDocumentTypes) {
          if (patientDocs.id > id) {
            id = patientDocs.id;
          }
        }
        id++;
        if ($scope.agencyAllPatientDocumentTypes.filter(x => x.label === $scope.planOfCareTypes[0].name)[0] === undefined) {
          $scope.agencyAllPatientDocumentTypes.push({ id: id, isPlanOfCare: true, label: "Plan Of Care" });
        }
      }

      // plan of care
      for (const visitLabel of $scope.agencyVisitLabels.labels) {
        if (visitLabel.hasPlanOfCare) {
          if ($scope.planOfCareTypes && $scope.planOfCareTypes.length) {
            const filteredPlanOfCare = $scope.agencyAllPatientDocumentTypes.filter(x => x.label === $scope.planOfCareTypes[0].name)[0];
            $scope.visitLabelPatientDocumentTypes[visitLabel.name].push({ id: filteredPlanOfCare.id, isPlanOfCare: true, label: $scope.planOfCareTypes[0].name })
          }
        }
      }
    }


  }


  $scope.currentVistLabel = undefined;
  // add events 
  $scope.visitLabelCertificationEvents = {
    onItemSelect: (item) => {
      updateVisitLabelCertification($scope.currentVistLabel, [item.id], "ASSOCIATE");
    },

    onItemDeselect: (item) => {
      updateVisitLabelCertification($scope.currentVistLabel, [item.id], "DISSOCIATE");
    },

    onSelectAll: () => {
      var certificationIds = $scope.allCertificatoins.map(certification => certification.id);
      updateVisitLabelCertification($scope.currentVistLabel, certificationIds, "ASSOCIATE");
    },

    onDeselectAll: () => {
      var certificationIds = $scope.allCertificatoins.map(certification => certification.id);
      updateVisitLabelCertification($scope.currentVistLabel, certificationIds, "DISSOCIATE");
    },
  }

  $scope.visitLabelPatientDocumentTypeEvents = {
    onItemSelect: (item) => {
      updateVisitLabelPatientDocumentType($scope.currentVistLabel, [item.id], "ASSOCIATE");
    },

    onItemDeselect: (item) => {
      updateVisitLabelPatientDocumentType($scope.currentVistLabel, [item.id], "DISSOCIATE");
    },

    onSelectAll: () => {
      var patientDocumentTypeIds = $scope.agencyAllPatientDocumentTypes.map(patientDocumentType => patientDocumentType.id);
      updateVisitLabelPatientDocumentType($scope.currentVistLabel, patientDocumentTypeIds, "ASSOCIATE");
    },

    onDeselectAll: () => {
      var patientDocumentTypeIds = $scope.agencyAllPatientDocumentTypes.map(patientDocumentType => patientDocumentType.id);
      updateVisitLabelPatientDocumentType($scope.currentVistLabel, patientDocumentTypeIds, "DISSOCIATE");
    },
  }

  $scope.visitLabelClicked = (visitLabelId) => {
    $scope.currentVistLabel = visitLabelId;
  }

  $scope.openSettingsModal = (type, data, viewModeOnly = true) => {
    var types = {
      'visit-label': {
        template: 'visit-label-setup-modal.html',
        controller: 'visitLabelSetupModalCtrl',
      }
    };

    var selected = types[type];

    if (!selected) {
      toaster.pop('warning', 'Something went wrong');
      return;
    }


    var newScope = $scope.$new();
    newScope.viewModeOnly = viewModeOnly;

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/' + selected.template,
      controller: selected.controller,
      scope: newScope,
      size: 'lg',
    });

    modalInstance.result.then((res) => {

      if (type === 'visit-label') {

        if (res.id != undefined) {
          const arrIndex = $scope.agencyVisitLabels.labels.findIndex(x => x.id === res.id);
          const arrAsMultiIndex = $scope.agencyVisitLabelsAsMulti.findIndex(x => x.id === res.id);
          if (arrIndex > -1) {
            $scope.agencyVisitLabels.labels[arrIndex] = res;
          } else {
            $scope.agencyVisitLabels.labels.push(res);
          }
          if (arrAsMultiIndex > -1) {
            $scope.agencyVisitLabelsAsMulti[arrIndex] = { id: res.id, label: res.name, color: res.color, isActive: res.isActive };
          } else {
            $scope.agencyVisitLabelsAsMulti.push({ id: res.id, label: res.name, color: res.color, isActive: res.isActive });
          }
          toaster.pop('success', "Success", "New Visit label Added updated successfully")
        }

      }

    }, function () {
    });

  }

  $scope.beforeUpdateVisitLabel = (oldValue) => {
    $scope.updateVisitLabelOldValue = oldValue;
  }

  $scope.updateVisitLabel = function (visitLabel) {
    if (!visitLabel.name || visitLabel.name.length === 0) {
      if ($scope.updateVisitLabelOldValue) {
        visitLabel.name = $scope.updateVisitLabelOldValue;
        $scope.updateVisitLabelOldValue = undefined;
      }
      return toaster.pop("error", "Please enter label name");
    }
    if (visitLabel.color.includes('rgb(')) {
      // change to rgba 
      visitLabel.color = visitLabel.color.replace('rgb', 'rgba');
      visitLabel.color = visitLabel.color.substring(0, visitLabel.color.length - 1);
      visitLabel.color += ',0.8)';
    }

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/visit_labels/:visitLabelId",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      visitLabel.id
    );
    DatabaseApi.put(url, visitLabel).then((res) => {
      toaster.pop('success', "Success", "Visit label updated successfully")
    }, (err) => {
      toaster.pop("error", "Failed to update visit label");
    }).finally(() => $scope.updateVisitLabelOldValue = undefined);
  }

  function updateVisitLabelCertification(visitLabelId, selectedCertificationsIds, associateType) {

    var reqObject = {
      associations: []
    };

    reqObject.associations = selectedCertificationsIds.map(certificationId => {
      const certificationObj = $scope.allCertificatoins.filter(x => x.id === certificationId)[0];
      return {
        visitLabelId: visitLabelId,
        certification: certificationObj.label,
        type: associateType
      }
    });

    $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_label_certification_assocs`, reqObject).then((res) => {
      
      DatabaseApi.updateVisitLabelCertificationAssocs();
      toaster.pop('success', "Success", "Visit label certification updated successfully")
    }).catch(err => {
      toaster.pop("error", "Failed to update visit label certifications");
    });
  }

  function handlePlanOfCareDocs(visitLabelId, docsIds, associateType) {
    let patientDocumentTypes = [];
    const filtredVisitLabel = $scope.agencyVisitLabels.labels.filter(x => x.id === visitLabelId)[0];
    let pocId = -1;
    let pocIndex = -1;
    let idx = -1;
    for (const docId of docsIds) {
      idx++;
      let currentDoc = $scope.agencyAllPatientDocumentTypes.filter(x => x.id === docId)[0];
      if (currentDoc !== undefined && currentDoc.isPlanOfCare) {
        pocId = currentDoc.id;
        pocIndex = idx;
        break;
      }
    }

    if (pocIndex > -1 && filtredVisitLabel !== undefined) {
      if (filtredVisitLabel !== undefined) {
        if (associateType === "ASSOCIATE") {
          filtredVisitLabel.hasPlanOfCare = true;
        } else if (associateType === "DISSOCIATE") {
          filtredVisitLabel.hasPlanOfCare = false;
        }
        $scope.updateVisitLabel(filtredVisitLabel);
      }
      docsIds.splice(pocIndex, 1);
    }
    patientDocumentTypes = docsIds;
    return patientDocumentTypes;
  }
  function updateVisitLabelPatientDocumentType(visitLabelId, selectedPatientdocumentTypeIds, associateType) {
    selectedPatientdocumentTypeIds = handlePlanOfCareDocs(visitLabelId, selectedPatientdocumentTypeIds, associateType);
    if (selectedPatientdocumentTypeIds.length > 0) {
      var reqObject = {
        associations: []
      };

      reqObject.associations = selectedPatientdocumentTypeIds.map(docTypeId => {
        return {
          visitLabelId: visitLabelId,
          patientDocumentTypeId: docTypeId,
          type: associateType
        }
      });

      $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_document_type_visit_label_assocs`, reqObject).then((res) => {
        DatabaseApi.updatePatientDocumentTypeVisitLabelAssocs();
        toaster.pop('success', "Success", "Visit label Patient Document updated successfully")
      }).catch(err => {
        toaster.pop("error", "Failed to update visit label Patient Document");
      });
    }
  }


});



