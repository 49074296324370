"use strict";

angular
  .module("dashboard")
  .controller(
    "patientPocHistoryCtrl",
    ($rootScope, $scope, $uibModal, $uibModalInstance, DatabaseApi) => {
      const plansOfCare = {
        isLoading: false,
        data: [],
        error: undefined,
      };

      const selectedPlanOfCare = {
        idx: undefined,
        value: undefined,
      };

      function onInit() {
        fetchPatientPlansOfCare();
      }

      function mapDataFromResponse(data) {
        return data
          .map((item, id) => {
            return ({
              revisionName: `Revision ${id + 1}`,
              ...item,
            })
          });
      }

      function fetchPatientPlansOfCare() {
        plansOfCare.isLoading = true;

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plans_of_care"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patient.id);

        DatabaseApi.get(url)
          .then(({ data }) => handleReceivePlansOfCare(data.plansOfCare))
          .catch((e) => {
            console.error(e);
            plansOfCare.error = "Failed to fetch the patient plans of care";
          })
          .finally(() => (plansOfCare.isLoading = false));
      }

      function handleReceivePlansOfCare(data) {
        plansOfCare.data = mapDataFromResponse(data);

        const totalPlansOfCare = plansOfCare.data.length;

        if (totalPlansOfCare === 0) {
          return;
        }

        if (selectedPlanOfCare.idx === undefined) {
          selectedPlanOfCare.idx = 0;
        }

        selectedPlanOfCare.value = plansOfCare.data[selectedPlanOfCare.idx];
      }

      function handleClickPlanOfCare(selectedPlanOfCareParam) {
        selectedPlanOfCare.idx = plansOfCare.data.indexOf(selectedPlanOfCareParam);
        selectedPlanOfCare.value = selectedPlanOfCareParam;
      }

      function handleClickSendEmail() {
        $uibModal.open({
          templateUrl: "admin/views/email-modal.html",
          size: "lg",
          resolve: {
            documents: () => buildDocuments(selectedPlanOfCare.value.poc),
            patientId: () => $scope.$resolve.patient.id,
            patientFullName: () => getFullName($scope.$resolve.patient),
            docsType: () => "planOfCare",
          },
          controller: "emailModalCtrl",
        });
      }

      function buildDocuments(document) {
        var documents;
        if (document !== undefined) {
          documents = [document];
        } else {
          documents = $scope.documentsTable.data.reduce(function (a, c) {
            if (c.selected) {
              a.push({ id: c.id, title: c.title });
            }
            return a;
          }, []);
        }
        return documents;
      }

      function getFullName({ firstName, middleName, lastName }) {
        return `${firstName || ""} ${middleName || ""} ${
          lastName || ""
        }`.trim();
      }

      function closeModal() {
        $uibModalInstance.close();
      }

      onInit();

      $rootScope.$on("patient_poc_updated", () => fetchPatientPlansOfCare());

      // Expose
      $scope.plansOfCare = plansOfCare;
      $scope.selectedPlanOfCare = selectedPlanOfCare;
      $scope.closeModal = closeModal;
      $scope.patient = $scope.$resolve.patient;
      $scope.onClickPlanOfCare = handleClickPlanOfCare;
      $scope.onClickSendEmail = handleClickSendEmail;
    }
  );
