angular
  .module("dashboard")
  .controller("dragFileModal", CaregiversDragFileComplianceCtrl);

function CaregiversDragFileComplianceCtrl($scope) {
  $scope.title = $scope.$resolve.title || "Drag a file";
  $scope.description = $scope.$resolve.description;
  $scope.accept = $scope.$resolve.accept || "*";
  $scope.placeholder =
    $scope.$resolve.placeholder || "Drop files here or click to upload";
  $scope.dragFile = $scope.$resolve.onDragFile;
  $scope.progress = $scope.$resolve.progress;
  $scope.file;
}
