app.controller("trainingCenterClassroomsCtrl", function (
  $scope,
  $rootScope,
  $state,
  $uibModal,
  NgTableParams,
  toaster,
  TrainingCenterClassroomService,
  officesService
) {
  $scope.isLoading = true;
  $scope.isRemoving = undefined;
  $scope.classrooms = undefined;

  this.$onInit = function () {
    TrainingCenterClassroomService.getClassrooms()
      .then(setTableData)
      .catch(() => toaster.pop("error", "Couldn't load classrooms"));
  };

  $scope.getOfficeName = function (officeId) {
    const office = $scope.offices.find(({ id }) => officeId === id);
    return office ? office.label : "No office";
  };
  $scope.getBundleNames = function (bundles) {
    return bundles.map(({ name }) => name);
  };

  $scope.officesComponentOptions = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: "250px",
    enableSearch: true,
  };
  $scope.filters = {
    offices: undefined,
  };
  const setDefaultOffices = () => {
    // agency member offices added to default filters
    if ($rootScope.user.agencyMember.officeIds) {
      $scope.filters.offices = $rootScope.user.agencyMember.officeIds
        .map(function (officeId) {
          return $scope.offices.find(function (obj) {
            return obj.id === officeId;
          });
        })
        .filter((office) => office !== undefined);
    } else {
      $scope.filters.offices = [];
    }
    $scope.offices.unshift({ id: -1, label: "Inactive Offices" });
  };

  const relevantOffices = (offices) =>
    offices
      .filter((office) => office.active)
      .map((office) => ({
        id: office.id,
        label: office.name,
      }));

  if (!officesService.offices) {
    officesService.getOffices().then((offices) => {
      $scope.offices = relevantOffices(offices);
      setDefaultOffices();
    });
  } else {
    $scope.offices = relevantOffices(officesService.offices);
    setDefaultOffices();
  }
  $scope.$watch(
    "filters",
    function () {
      setTableData($scope.classrooms);
    },
    true
  );

  $scope.createNewClassroom = () => {
    const modalInstance = $uibModal.open({
      templateUrl:
        "admin/views/training-center-classrooms-new-classroom-modal.html",
      size: "lg",
      controller: "trainingCenterClassroomNewClassroomModalCtrl",
    });

    modalInstance.result
      .then(({ status, id }) => {
        if (status !== "CREATED" || status === "CANCEL") return;
        $scope.goToClassroom(id);
      })
      .catch(({ status }) => {
        if (status !== "CREATED" || status === "CANCEL") return;
        toaster.pop("error", "Something went wrong");
      });
  };

  $scope.goToClassroom = function (classroomId) {
    $state.go("app.trainingCenterClassroomsEdit", {
      classroomId,
    });
  };

  $scope.removeClassroom = function (classroomId) {
    $scope.isRemoving = classroomId;

    TrainingCenterClassroomService.removeClassroom(classroomId)
      .then(() => {
        const newClassrooms = $scope.classrooms.filter(
          ({ id }) => id !== classroomId
        );
        setTableData(newClassrooms);
        toaster.pop("success", "Classroom removed");
      })
      .catch(() =>
        toaster.pop(
          "error",
          "Couldn't remove classroom",
          "Some of the caregivers completed the classroom"
        )
      )
      .finally(() => ($scope.isRemoving = undefined));
  };

  function applyFilters(classrooms) {
    if (classrooms === undefined) return;
    let filteredTableData = JSON.parse(JSON.stringify(classrooms));

    // Filter offices
    filteredTableData = filteredTableData.filter(({ officeId }) =>
      $scope.filters.offices.map(({ id }) => id).includes(officeId)
    );

    return filteredTableData;
  }

  function setTableData(classrooms) {
    $scope.classrooms = classrooms;
    $scope.classroomsFiltered = applyFilters(classrooms);

    const options = {
      count: 25,
      sorting: { createdAt: "desc" },
    };

    $scope.tableParams = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: $scope.classroomsFiltered,
    });
  }
});
