"use strict";

app.controller('hrSettingsCtrl', function ($scope, $rootScope, NgTableParams, DatabaseApi, toaster, $uibModal, $timeout, $window, $filter, SweetAlert, CopyCertificationSettingsService, generalUtils, complianceService) {

    $scope.settingsJson = {};

    window.scrollTo(0, 0);

    $scope.docTypes = [];

    $scope.selectors = {};

    $scope.docsForm = { requiredDocuments: '' };

    $scope.forms = {};

    $scope.form = {};
    $scope.newStage = {};

    var docsHide = [56, 57];

    const followupDocumentTypesIds = new Set();

    $scope.daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    $scope.hideQuestions = ['Signature', 'FreeText', 'AmericanTest'];

    $scope.capitalize = generalUtils.capitalize;

    $scope.documentTypesLoaded = false;

    const idToDoc = new Map();

    let followupTrees = [];

    var newComplianceItem = {
        name: 'New Item',
        comments: false,
        date: false,
        sentOutDate: false,
        receivedDate: false,
        completionDate: false,
        results: false,
        expires: false,
        score: false,
        docA: false,
        docB: false,
        reference1: false,
        reference2: false,
        trainingSchool: false,
        instructor: false,
        sectionLabel: false,
        degree: false,
        lastEmployment: false,
        startDate: false,
        endDate: false,
        certificationDate: false,
        verificationDate: false,
        onFile: false,
        removedAt: null
    };

    $scope.sectionLabels = ['General', 'Medical', 'Evaluations, Orientation, In-Service']

    $scope.newItems = { selected: {} };
    $scope.newItems[$scope.sectionLabels[0]] = [];
    $scope.newItems[$scope.sectionLabels[1]] = [];
    $scope.newItems[$scope.sectionLabels[2]] = [];


    var customComplianceItems = $scope.customComplianceItems = [
        {
            showSelect: false,
            name: 'I-9',
            sectionLabel: 'General',
            docA: true,
            docB: true,
            expires: true,
            comments: true,
            options: {
                docA: [
                    {
                        name: 'U.S. Passport or U.S. Passport Card',
                        needsB: false
                    }, {
                        name: 'Permanent Resident Card or Alien Registration Receipt Card',
                        needsB: false
                    }, {
                        name: 'Employment Authorization Document Card',
                        needsB: false
                    }, {
                        name: 'Foreign passport with Form I-94 or Form I-94A with Arrival-Departure Record, and containing an endorsement to work',
                        needsB: false
                    }, {
                        name: 'Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A',
                        needsB: false
                    }, {
                        name: 'Foreign passport containing a Form I-551 stamp or Form I-551 printed notation',
                        needsB: false
                    }, {
                        name: 'Driver\'s License',
                        needsB: true
                    }, {
                        name: 'ID Card',
                        needsB: true
                    }
                ], docB: [
                    {
                        name: 'U.S. Social Security account number card that is unrestricted'
                    }, {
                        name: 'Consular Report of Birth Abroad'
                    }, {
                        name: 'Certification of Birth Abroad issued by the U.S. Department of State'
                    }, {
                        name: 'Certification of Report of Birth issued by the U.S. Department of State'
                    }, {
                        name: 'Original or certified copy of a birth certificate issued by a state, county, municipal authority or outlying territory of the United States bearing an official seal'
                    }, {
                        name: 'Native American tribal document'
                    }, {
                        name: 'U.S. Citizen ID Card'
                    }, {
                        name: 'Identification Card for Use of Resident Citizen in the United States'
                    }, {
                        name: 'Employment authorization document issued by the Department of Homeland Security'
                    }
                ]
            }
        },
        {
            showSelect: false,
            name: 'References',
            sectionLabel: 'General',
            reference1: true,
            reference2: true,
            degree: true, ////
            comments: true
        },
        {
            showSelect: false,
            name: 'Criminal background',
            sectionLabel: 'General',
            sentOutDate: true,
            receivedDate: true,
            results: true,
            comments: true,
            options: {
                results: ['Submitted', 'ok', 'Temporary Denial', 'Final Denial', 'Resubmitted']
            }
        },
        {
            showSelect: false,
            name: 'Last employment agency',
            sectionLabel: 'General',
            lastEmployment: true, ////
            startDate: true, /////
            endDate: true, /////
            results: true,
            comments: true
        },
        {
            showSelect: false,
            name: 'Training School',
            sectionLabel: 'General',
            trainingSchool: true, ////
            certificationDate: true, /////
            instructor: true, /////
            verificationDate: true, ////
            onFile: true, /////
            comments: true
        },
        {
            name: 'Face mask Provided (2017)',
            sectionLabel: 'Medical',
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Flu Vaccine (2017)',
            sectionLabel: 'Medical',
            results: true,
            comments: true,
            options: {
                results: ['Completed (In-Office)',
                    'Completed (Outside of off',
                    'Exempt',
                    'Declined']
            }
        }, {
            name: 'Annual health Assessment',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Chest X- Ray',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }

        }, {
            name: 'Drug Screen',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Flu Shot',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Flu Vaccination',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Accepted',
                    'Declined']
            }
        }, {
            name: 'N1H1 Vaccination',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Accepted',
                    'Declined']
            }
        }, {
            name: 'PPD',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Pre employment physical',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Quantiferon',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: 'Rubella Immune',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Immune',
                    'Not Immune']
            }
        }, {
            name: 'Rubella (MMR)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Rubeola Immune',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Immune',
                    'Not Immune']
            }
        }, {
            name: 'Rubeola (MMR1)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'Rubeola (MMR2)',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Complete', 'Incomplete']
            }
        }, {
            name: 'TB - Screen',
            sectionLabel: 'Medical',
            expires: true,
            results: true,
            comments: true,
            options: {
                results: ['Positive',
                    'Negative']
            }
        }, {
            name: '3 month evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: '6 month evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Annual Evaluation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Orientation',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }, {
            name: 'Supervisory Visit',
            sectionLabel: 'Evaluations, Orientation, In-Service',
            date: true,
            expires: true,
            score: true,
            comments: true
        }

    ];

    customComplianceItems.forEach(function (value) {
        if (!$scope.newItems[value.sectionLabel]) $scope.newItems[value.sectionLabel] = [];
        $scope.newItems[value.sectionLabel].push(value);
    });

    initAgencyCertifications();

    $rootScope.$on("got_agency_certifications", (_) => {
        initAgencyCertifications();
    });

    function initAgencyCertifications() {
        const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
        $scope.tabList = activeAgencyCertifications
            .map(item => item.certification);

        $scope.certifications = $scope.tabList.map((cert, index) => ({ id: index, label: cert }));
    }

    $scope.complianceItemSettings = [
        'comments',
        'date',
        'sentOutDate',
        'receivedDate',
        'completionDate',
        'results',
        'expires',
        'score',
        'docA',
        'docB'
    ];

    $scope.activeTab = {};

    // Signature person
    $scope.selectedSignatureMember = { val: "" };
    $scope.signatureMembers = [];
    var getSignatureMembersIterations = 0;
    function getSignatureMembers() {
        getSignatureMembersIterations++;

        $scope.signatureMembers = DatabaseApi.getAgencyMembersArr();

        if ((!$scope.signatureMembers || $scope.signatureMembers.length === 0) && getSignatureMembersIterations < 20) {
            $timeout(getSignatureMembers, 500);
        }
    }
    getSignatureMembers();

    // function getSelectedSignaturePerson() {
    //     var url = 'agencies/' + $rootScope.agencyId +
    //         '/agency_member/' + $rootScope.agencyMemberId +
    //         '/signature_person';
    //     DatabaseApi.get(url).then(function (res) {
    //         if (res.data.hrSignaturePerson !== null) {
    //             $scope.selectedSignatureMember.val = res.data.hrSignaturePerson.toString();
    //         } else {
    //             $scope.selectedSignatureMember.val = null;
    //         }
    //     }, function (err) {
    //         toaster.pop('error', 'Something went wrong');
    //     });
    // }
    // getSelectedSignaturePerson();

    function getHRPayRates() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' +
            $rootScope.agencyMemberId + "/hr_certifications_payrates").then(function (res) {
                $scope.hrPayRates = res.data.certifications;
            }, function (err) {
                toaster.pop('error', 'failed to load hr certification pay rates');
            });
    }
    getHRPayRates();

    function checkIfCoordinatorIsMissingSignature(coordinatorId) {
        if (coordinatorId) {
            DatabaseApi.get('agencies/' + $rootScope.agencyId + '/coordinators/' + coordinatorId + '/signature').then(function (res) {
                if (res.data.url === null) {
                    toaster.pop('warning', 'Selected member is missing signature');
                }
            }, function (err) {
                toaster.pop('error', 'Something went wrong', "Couldn't check for agency member signature");
            });
        }
    }

    // $scope.signaturePersonChangeHandler = function (oldSignatureMemberId) {
    //     checkIfCoordinatorIsMissingSignature($scope.selectedSignatureMember.val);

    //     var url = 'agencies/' + $rootScope.agencyId +
    //         '/agency_member/' + $rootScope.agencyMemberId +
    //         '/signature_person';
    //     var body = {
    //         hrSignaturePerson: $scope.selectedSignatureMember.val ? parseInt($scope.selectedSignatureMember.val) : null
    //     };
    //     DatabaseApi.put(url, body).then(function (res) {
    //         toaster.pop('success', 'Signature person updated successfully');
    //     }, function (err) {
    //         $scope.selectedSignatureMember = oldSignatureMemberId;
    //         toaster.pop('error', 'Failed updating signature person');
    //     });
    // }

    $scope.subsectionSignaturePersonChangeHandler = function (oldSubsection, subsection) {
        checkIfCoordinatorIsMissingSignature(subsection.signaturePerson);

        var url = 'hr/agencies/' + $rootScope.agencyId +
            '/agency_member/' + $rootScope.agencyMemberId +
            '/subsection/' + subsection.id +
            '/signature_person';
        var body = {
            signaturePerson: subsection.signaturePerson ? parseInt(subsection.signaturePerson) : null
        };
        DatabaseApi.put(url, body).then(function (res) {
            toaster.pop('success', 'Signature person updated successfully');
        }, function (err) {
            subsection.signaturePerson = oldSubsection.signaturePerson ? oldSubsection.signaturePerson : "";
            toaster.pop('error', 'Failed updating signature person');
        });
    }

    // application question settings

    function parseApplication(data, savedSection, savedSubsection, savedSubsectionType) {

        var subSection, section, subsectionType;
        if ($scope.application) {
            if ($scope.application.section) section = $scope.application.section;
            if ($scope.application.subSection) subSection = $scope.application.subSection;
            if ($scope.application.subSectionType) subsectionType = $scope.application.subSectionType;
        }
        $scope.application = data;

        //$scope.application.subsections[0].type = 'test';
        //$scope.application.subsections[1].type = 'custom';
        //$scope.application.sections[2].isStop = true;


        //$timeout(function () {
        if (savedSection) {
            $scope.application.section = savedSection;
            $scope.application.subSection = savedSubsection;
            $scope.application.subSectionType = savedSubsectionType;
        } else {
            $scope.application.section = section;
            $scope.application.subSection = subSection;
            $scope.application.subSectionType = subsectionType;
        }
        //},0);

        $scope.application.subsections = $scope.application.subsections.map(function (subsection) {
            subsection.signaturePerson = subsection.signaturePerson ? subsection.signaturePerson.toString() : "";
            return subsection;
        });

        $scope.templatesIdToTitles = DatabaseApi.getHrSubsectionTemplatesIdsToTitles();
        $scope.selectedFromSubsection = {};
        $scope.templatesIdToTitlesForMulti = {};
        const currentSections = $scope.application.sections.filter(x => x.isActive);
        for (const section of currentSections) {
            $scope.selectedFromSubsection[section.id] = [];
            const currentSubsections = $scope.application.subsections.filter(x => x.section === section.id && x.isActive);
            $scope.templatesIdToTitlesForMulti[section.id] = $scope.templatesIdToTitles.map((x, count) => ({ id: count, subsectionId: x.id, label: x.title }));
            // now remove what we already have in this section
            $scope.templatesIdToTitlesForMulti[section.id] = $scope.templatesIdToTitlesForMulti[section.id].reduce((acc, curr) => {
                if (!currentSubsections || currentSubsections.length === 0 || !currentSubsections.map(x => x.name).includes(curr.label)) {
                    acc.push(curr);
                }
                return acc;
            }, [])
            $scope.selectedFromSubsection[section.id] = [];
        }

        $scope.chooseFromSubsectionExtraSettings = {
            closeOnSelect: true,
            singleSelection: true,
            selectionLimit: 1,
            allowSearchFilter: true,
            scrollableHeight: '200px',
            scrollable: true,
            enableSearch: true,
            styleActive: true,
            dynamicTitle: true,
            smartButtonTextConverter: function (itemText, originalItem) {
                return `Generating ${itemText}`;

            }
        };
    }

    $scope.currentSection = undefined;
    $scope.currentCertification = undefined;

    $scope.templatesIdToTitlesForMultiEvents = {
        onItemSelect: (item) => {
            $scope.subsectionTemplateSelected($scope.currentCertification, $scope.currentSection, item);
        },

        onItemDeselect: (item) => {
        },

        onSelectAll: () => {
        },

        onDeselectAll: () => {
        },
    }

    $scope.onPickTemplateClick = (section) => {
        $scope.currentSection = section;
        $scope.currentCertification = $scope.activeTab.val;
    }

    $scope.subsectionTemplateSelected = (certification, section, item) => {
        const filteredSubsection = $scope.templatesIdToTitles[item.id];
        if (filteredSubsection !== undefined) {
            const findSubsectionToUpdate = $scope.application.subsections.find(x =>
                x.templateId === filteredSubsection.id &&
                x.section === section.id &&
                x.isActive === false
            );
            let subsectionIdToUpdate = findSubsectionToUpdate === undefined ? undefined : findSubsectionToUpdate.id;
            const newCopyHrSubsectionTemplateParams = {
                subectionTemplateId: filteredSubsection.id,
                sectionIdTo: section.id,
                certification: certification,
                agencyId: $rootScope.agencyId,
                subsectionId: subsectionIdToUpdate
            }
            var url = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/subsections_templates/copy`;
            DatabaseApi.post(url, newCopyHrSubsectionTemplateParams).then((res) => {
                toaster.pop('success', `the template has been added successfully`);
                getApplication(undefined, undefined, undefined, () => {

                    setTimeout(() => {
                        $scope.goToItem(section.id, filteredSubsection.id);
                    }, 500)
                });
            }, (err) => {

            });
        }
    }


    $scope.goToItem = function (sectionId, subsectionId) {
        if (!sectionId && !subsectionId) {
            return;
        }
        const filteredTemaplateSubs = $scope.templatesIdToTitles.filter(x => x.id === subsectionId)[0];
        if (filteredTemaplateSubs !== undefined) {
            const currentSubsections = $scope.application.subsections.filter(x => x.section === sectionId);
            if (currentSubsections !== undefined) {

                const filtredSubs = currentSubsections.filter(x => x.name === filteredTemaplateSubs.title)[0];
                if (filtredSubs !== undefined) {
                    generalUtils.scrollToElement(`anchor-section-${sectionId}-subsection-${filtredSubs.id}`);
                }
            }
        }
    };



    $scope.generateExamplePdf = function (id) {

        var url = 'hr/agencies/' + $rootScope.agencyId + '/subsection/' + id + '/generate_doc_preview';
        DatabaseApi.post(url).then(function (res) {

            $scope.application.subsections.forEach(function (value) {
                value.loading = false;
            });
            if (res.data.url) $window.open(res.data.url);

        }, function (err) {

        });

    };


    $scope.getSettings = function (cert) {

        if (!$scope.settingsJson.settingsForCertification) return;
        if (!$scope.settingsJson.settingsForCertification[cert]) {
            $scope.settingsJson.settingsForCertification[cert] = { rates: {} };
        }


        $scope.activeTab.val = cert;
        $scope.loading = true;

        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certification/' + $scope.activeTab.val + '/document_types').then(function (res) {
            res.data.documentTypes.sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            $scope.docTypes = res.data.documentTypes;
        }, function (err) {

        });


        // DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certification/' + $scope.activeTab.val + '/required_docs').then(function (res) {
        //     $scope.docsForm.requiredDocuments = res.data.requiredDocs || '';

        // }, function (err) {

        // });

        // getComplianceItems();

        /*DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certification/' + $scope.activeTab.val + '/application_form')
        //DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/application_form')
            .then(function (res) {
                $scope.settings = res.data.sections;
                $scope.complianceQuestions = [];
                $scope.settings[0].subSections.forEach(function (sub) {
                    if(sub.data.id === 101){
                        sub.questionGroups.forEach(function (group) {
                            if(group.data.id === 107){

                            }
                        });
                    }
                });
                $scope.loading = false;
            }, function (err) {
                $scope.loading = false;
            });*/

        getApplication();

        // getHrSettings();

        getSectionStages();

        initHRPayRatesTable($scope.hrPayRates[$scope.activeTab.val]);
    };

    function initHRPayRatesTable(items) {
        var options = {
            count: 10,
            sorting: { id: "desc" }
        };

        $scope.hrCertificationPayRates = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function getSectionStages() {
        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages').then(function (res) {
            $scope.stages = res.data.sectionStages;
            $scope.loading = false;
        }, function (err) {
            toaster.pop('error', 'failed to load hr stages');
            $scope.loading = false;
        });
    }

    function getApplication(section, subsection, subsectionType, callback) {

        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/application_form')
            .then((res) => {
                $scope.application = res.data;

                parseApplication(res.data, section, subsection, subsectionType);
                $scope.loading = false;
                if (callback) {
                    callback();
                }
            }, function (err) {

                toaster.pop('error', 'Something went wrong');

                /*
                var data = {
                    sections: [
                        {
                            id: 1,
                            name: 'aaa',
                            isActive: true
                        }, {
                            id: 2,
                            name: 'bbb',
                            isActive: false
                        }, {
                            id: 3,
                            name: 'ccc',
                            isActive: true
                        }, {
                            id: 4,
                            name: 'ddd',
                            isActive: true
                        }, {
                            id: 5,
                            name: 'eee',
                            isActive: true
                        }
                    ],
                    subsections: [
                        {
                            id: 1,
                            name: 'abab fd  df m vjwk vjks vjks dvjk sjkdv jks vjk v',
                            section: 1
                        }, {
                            id: 2,
                            name: 'cdcd',
                            section: 1
                        }, {
                            id: 3,
                            name: 'efef',
                            section: 3
                        }, {
                            id: 4,
                            name: 'xxx',
                            section: null
                        }
                    ],
                    questions: [
                        {
                            id: 1,
                            name: 'qqa',
                            questionGroupName: 'group 2',
                            subSection: 1
                        }, {
                            id: 2,
                            name: 'qqee',
                            questionGroupName: 'group 1',
                            subSection: 1
                        }, {
                            id: 3,
                            name: 'qqttt',
                            questionGroupName: 'group 2',
                            subSection: 2
                        }, {
                            id: 4,
                            name: 'empt',
                            questionGroupName: 'group 2',
                            subSection: null
                        }
                    ]
                };

                parseApplication(data);

                */

                $scope.loading = false;
            });
    }


    function getComplianceItems() {


        var presetItems = [
            'I-9',
            'References',
            'Criminal background',
            'Last employment agency',
            'Training School'
        ];

        DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items').then(function (res) {
            res.data.complianceItems.sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            res.data.complianceItems.forEach(function (comp, i) {
                // value.sectionLabel = i % 2 === 0 ? 'General' : 'Medical';

                if (comp.removedAt) comp.isActive = false;
                else comp.isActive = true;

                if (presetItems.indexOf(comp.name) > -1) {
                    presetItems.splice(presetItems.indexOf(comp.name), 1);
                }

            });

            presetItems.forEach(function (pre, i) {
                // value.sectionLabel = i % 2 === 0 ? 'General' : 'Medical';

                customComplianceItems.forEach(function (comp) {
                    if (comp.name === pre) {
                        var body = angular.copy(newComplianceItem);

                        for (var key in comp) {
                            body[key] = comp[key];
                        }

                        res.data.complianceItems.unshift(body);
                    }
                })


            });

            console.log(res.data.complianceItems);

            $scope.complianceItems = res.data.complianceItems;

        }, function (err) {

            $scope.complianceItems = [
                {
                    id: 1,
                    name: 'Flu shot 2018',
                    comments: true,
                    date: true,
                    sentOutDate: false,
                    receivedDate: false,
                    completionDate: false,
                    results: false,
                    expires: true,
                    score: false,
                    docA: false,
                    docB: false
                }, {
                    id: 2,
                    name: 'I-9 form',
                    comments: true,
                    date: false,
                    sentOutDate: false,
                    receivedDate: false,
                    completionDate: false,
                    results: false,
                    expires: false,
                    score: false,
                    docA: true,
                    docB: true
                }, {
                    id: 3,
                    name: 'Criminal background',
                    comments: true,
                    date: false,
                    sentOutDate: true,
                    receivedDate: true,
                    completionDate: false,
                    results: true,
                    expires: false,
                    score: false,
                    docA: false,
                    docB: false
                }, {
                    id: 4,
                    name: 'Annual health assessment',
                    comments: true,
                    date: true,
                    sentOutDate: false,
                    receivedDate: false,
                    completionDate: false,
                    results: true,
                    expires: true,
                    score: false,
                    docA: false,
                    docB: false
                }
            ]
        });
    }


    function openSubsectionModal(data, section) {
        var resolve = {
            modalData: function () {
                return data || null;
            },
            certification: function () {
                return $scope.activeTab.val;
            },
            application: function () {
                return $scope.application;
            },
            sectionId: function () {
                return section || null;
            }

        };

        if (data) {
            resolve.subsectionId = function () {
                return data.id;
            }
        } else {
            resolve.subsectionId = function () {
                return null;
            }
        }


        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/new-subsection-modal.html',
            size: 'lg',
            controller: 'newSubSectionModalCtrl',
            resolve: resolve
        });

        modalInstance.result.then(function (res) {

            getApplication($scope.application.section, $scope.application.subSection, $scope.application.subSectionType);

        }, function () {
            getApplication($scope.application.section, $scope.application.subSection, $scope.application.subSectionType);
        });

    }

    // $scope.updateRequiredDocuments = function () {

    //     var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' + $scope.activeTab.val + '/required_docs';
    //     DatabaseApi.post(url, { requiredDocs: $scope.docsForm.requiredDocuments }).then(function (res) {

    //     }, function (err) {
    //         toaster.pop('error', 'Something went wrong');
    //     });

    // };


    $scope.addFileType = function (section) {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/add-file-type-modal.html',
            size: 'md',
            controller: 'addFileTypeModalCtrl',
            resolve: {
                section: function () { return section; },
                certification: function () { return $scope.activeTab.val; }
            }
        });
        modalInstance.result.then(function (res) {
            $scope.getSettings($scope.activeTab.val);
        }, function () {
            $scope.getSettings($scope.activeTab.val);
        });

    };


    $scope.addSection = function (isStop, name) {

        if (!name) return;

        var body;
        var urlEntity;

        urlEntity = 'section';
        body = { name: name, isStop: isStop };

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            $scope.activeTab.val + '/' + urlEntity;

        DatabaseApi.post(url, body).then(function (res) {

            console.log(res.data);
            if (res.data && res.data) parseApplication(res.data);

            $timeout(function () {
                scrollToRight(document.getElementById('app-scroll'), 100000);

                //document.getElementById('app-scroll').scrollLeft = 100000000;
            }, 1000);

        }, function (err) {
            console.log('err');
            console.log(err);
        });

        $scope.forms.newSection = '';

    };


    function scrollToRight(element, to) {

        var duration = 1000;
        var start = element.scrollLeft;
        var change = to - start;
        var increment = 20;
        var currentTime = 0;

        var animateScroll = function () {

            currentTime += increment;

            var val = easeInOutQuad(currentTime, start, change, duration);

            element.scrollLeft = val;

            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };

        animateScroll();
    }

    function easeInOutQuad(t, b, c, d) {

        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }


    /* $scope.selectorUpdated = function(type, val) {

        if(!val) return;
        var item = val;

        console.log(item);

        var body;
        var urlEntity;
        var newEntity = false;

        if(type === 'section') {
            urlEntity = 'section';
            body = { name: 'New section' };

            if(item === 'new'){
                newEntity = true;
            } else if(item === 'stop'){
                body.isStop = true;
                body.name = 'End of ..., please see HR.';
                newEntity = true;
            }
        } else if(type === 'subsection') {

            if(!$scope.application.section) return;
            urlEntity = 'subsection';
            body = {
                fatherSection: $scope.application.section,
                name: 'New subsection'
            };

            if(item === 'new') {

                body.type = 'REGULAR';
                body.name = 'New Sub section';
                newEntity = true;

            } if(item === 'TEST') {

                body.type = 'TEST';
                body.name = 'New Test';
                newEntity = true;

            } else if(item === 'CUSTOM') {

                body.type = 'CUSTOM';
                body.name = 'New Custom Section';
                newEntity = true;

            }

        }
        else if(type === 'question') {

            if(!$scope.application.subSection) return;
            urlEntity = 'question';
            body = {
                fatherSubSection: $scope.application.subSection
            };
        }

        if(newEntity){

            var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
                $scope.activeTab.val + '/' + urlEntity;

        } else {

            var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
                $scope.activeTab.val + '/' + urlEntity + '/' + item + '/activate';
        }

        DatabaseApi.post(url, body).then(function (res) {

            console.log(res.data);
            if(res.data && res.data) parseApplication(res.data);

            if(item === 'TEST' || item === 'CUSTOM') {

                handleSpecialSubsection(item);


            }

        }, function (err) {
            console.log('err');
            console.log(err);
        });

        $scope.selectors = {};
    }; */

    function handleSpecialSubsection(type, id) {
        var subs = angular.copy($scope.application.subsections);

        var data;

        if (id) {

            subs.forEach(function (sub) {
                if (sub.id === id) data = sub;
            });

        } else {
            subs.sort(function (a, b) {
                return b.id - a.id;
            });

            data = subs[0];
        }


        var questions = [];
        $scope.application.questions.forEach(function (question) {
            if (question.subSection === data.id) questions.push(question);
        });


        if (type === 'TEST') {

            data.questions = questions;


            //openSubsectionModal('TEST', 'Test Editor', data);

        } else if (type === 'CUSTOM') {

            data.question = questions[0] ? questions[0] : null;

            //openSubsectionModal('CUSTOM', 'Form Editor', data);
        }
    }

    $scope.getSectionName = function (section) {

        if (section === 'general') return 'Documents';
        else return section.charAt(0).toUpperCase() + section.substr(1);

    };

    $scope.createNewSubbsection = function (section) {
        openSubsectionModal(null, section);
    };

    $scope.arr = [];
    $scope.templatesIdToTitles = DatabaseApi.getHrSubsectionTemplatesIdsToTitles();


    $scope.chooseFromSubsectionExtraSettings = {
        selectionLimit: 1,
        singleSelection: true,
        allowSearchFilter: true,
        scrollableHeight: '200px',
        scrollable: true,
        enableSearch: true,
        styleActive: true
    };

    $scope.editSubsection = function (subsection) {

        /*if(id){
            $scope.application.subsections.forEach(function (sub) {
                if(sub.id === id) subsection = sub;
            });
        }*/

        openSubsectionModal(subsection);

        //handleSpecialSubsection(subsection.type, subsection.id);

    };


    $scope.pickApplicationSection = function (id) {

        if (!$scope.application || !$scope.application.sections.length) return;

        if ($scope.application.section === id) $scope.application.section = null;
        else $scope.application.section = id;

        $scope.application.subSection = null;

    };

    $scope.pickApplicationSubSection = function (id) {

        if (!$scope.application || !$scope.application.subsections.length) return;

        if ($scope.application.subSection === id) $scope.application.subSection = null;
        else $scope.application.subSection = id;

    };

    $scope.$watch('application.subSection', function () {
        if (!$scope.application) return;

        if (!$scope.application.subSection) {
            $scope.application.subSectionType = null;
        } else {
            $scope.application.subsections.forEach(function (sub) {
                if (sub.id === $scope.application.subSection) {
                    $scope.application.subSectionType = sub.type;
                }
            });
        }
    });

    $scope.moveItem = function (type, id1, direction) {

        if (!$scope.application || !$scope.application[type].length) return;

        var fatherName = 'isActive';
        if (type === 'subsections') fatherName = 'section';
        else if (type === 'questions') fatherName = 'subSection';

        var id2 = false, previousItem, itemFound;
        var itemFather = false;

        if (type === 'subsections' || type === 'questions') {
            $scope.application[type].forEach(function (item) {
                if (item.id === id1) {
                    itemFather = item[fatherName];
                }
            });
        } else {
            itemFather = true;
        }
        /*
                if($scope.application[type][0].id === id1 && direction === 'up'){
                    itemFound = true;
                } else if($scope.application[type][$scope.application[type].length-1].id && direction === 'up') {
                    itemFound = true;
                }*/

        if (direction === 'up') {
            $scope.application[type].forEach(function (item) {
                if (item.id === id1) {
                    console.log('found 1');
                    itemFound = true;
                }

                if (itemFound && item.isActive && item[fatherName] === itemFather && item.id !== id1) {
                    if (id2 === false) {
                        id2 = item.id;
                    }
                }
            });

        } else {
            $scope.application[type].forEach(function (item) {
                if (id2 === false && item.isActive && item[fatherName] === itemFather && item.id !== id1) {
                    previousItem = item;
                }

                if (item.id === id1) {
                    itemFound = true;
                    id2 = previousItem.id;
                }
            });
        }

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            $scope.activeTab.val + '/switch_' + type;

        var body = {};

        if (type === 'sections') {
            body.section1 = id1;
            body.section2 = id2;
        } else if (type === 'subsections') {
            body.subSection1 = id1;
            body.subSection2 = id2;
        } else if (type === 'questions') {
            body.question1 = id1;
            body.question2 = id2;
        }

        DatabaseApi.post(url, body).then(function (res) {

            console.log(res.data);
            if (res.data && res.data) parseApplication(res.data);

        }, function (err) {
            console.log(err);
        });


    };

    $scope.updateSubsectionSettings = function (subsection) {

        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' +
            $scope.activeTab.val + '/subsection/' + subsection.id;

        var body = {
            name: subsection.name,
            settings: subsection.settings
        };

        DatabaseApi.patch(url, body).then(function (res) {

        }, function (err) {

            toaster.pop('error', 'Something went wrong');
        });
    };

    $scope.editItem = function (type, item) {

        if (!$scope.application || !$scope.application[type].length) return;

        var urlEntity = 'section';
        if (type === 'subsections') {
            urlEntity = 'subsection';
        }
        else if (type === 'questions') {
            urlEntity = 'question';
            item.possibleAnswers = item.possibleAnswers || undefined;
        }

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            $scope.activeTab.val + '/' + urlEntity + '/' + item.id;

        var body = item;


        DatabaseApi.patch(url, body).then(function (res) {
            toaster.pop('success', 'section updated successfully');
            console.log(res.data);
            if (res.data && res.data) parseApplication(res.data);

        }, function (err) {
            toaster.pop('error', 'failed to update section');
            console.log(err);
        });


    };

    $scope.removeApplicationItem = function (type, item) {

        if (!$scope.application || !$scope.application[type].length) return;

        var label = 'Section';
        var urlEntity = 'section';
        var activeName = 'isActive';

        if (type === 'subsections') {
            //activeName = 'section';
            urlEntity = 'subsection';
            label = 'Subsection';
        }
        else if (type === 'questions') {
            //activeName = 'subSection';
            urlEntity = 'question';
        }

        var url = 'hr/agencies/' + $scope.agencyId + '/certification/' +
            $scope.activeTab.val + '/' + urlEntity + '/' + item.id + '/deactivate';

        var body = item;

        if (type === 'sections' || type === 'subsections') {
            SweetAlert.swal({
                title: "Remove " + label + '?',
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, remove",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    done();
                }
            });
        } else {
            done();
        }

        function done() {

            body[activeName] = false;

            DatabaseApi.post(url, body).then(function (res) {

                console.log(res.data);
                if (res.data && res.data) parseApplication(res.data);

            }, function (err) {
                console.log(err);
            });

        }

    };

    /*$scope.pickApplicationSubSection = function(idx, type, direction) {

        if(!$scope.application) return;


        //$http.

    };*/

    $scope.isShowComplianceItem = function (name, section) {
        var flag = true;
        $scope.complianceItems.forEach(function (item) {
            if (item.name === name) flag = false;
        });
        return flag;
    };

    $scope.removeComplianceItem = function (item, idx) {


        $scope.complianceItems.splice(idx, 1);

        DatabaseApi.delete('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items/' + item.id)
            .then(function (res) {

            }, function (err) {
                $scope.complianceItems.splice(idx, 0, item);
                toaster.pop('error', 'Something went wrong');
            });

    };


    $scope.addComplianceItem = function () {

        newComplianceItem.videoId = null;
        DatabaseApi.post('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items', newComplianceItem)
            .then(function (res) {
                var temp = angular.copy(newComplianceItem);
                temp.id = res.data.complianceId;

                $scope.complianceItems.unshift(temp);

            }, function (err) {
                toaster.pop('error', 'Something went wrong');
            });

    };

    $scope.editComplianceSettings = function (item) {


        var resolve = {

            section: function () {
                return item.sectionLabel;
            },
            item: function () {
                return item;
            },
            items: function () {
                return newComplianceItem;
            }
        };


        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/new-compliance-item-modal.html',
            size: 'md',
            controller: 'newComplianceItemModalCtrl',
            resolve: resolve
        });

        modalInstance.result.then(function (res) {

            if (res) {
                console.log(res);
                if (res.id) {

                    $scope.updateComplianceSettings(res);

                }
            }

        });


    };


    $scope.updateComplianceSettings = function (item) {

        if (item.isActive) item.removedAt = new Date();
        else item.removedAt = null;

        DatabaseApi.put('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items/' + item.id, item)
            .then(function (res) {

            }, function (err) {
                toaster.pop('error', 'Something went wrong');
            });

    };


    $scope.getItemSettings = function (item) {

        var arr = [];

        for (var key in newComplianceItem) {
            if (key !== 'name' && key !== 'sectionLabel') {
                var t = $filter('titlecase')(key);
                if (item[key]) arr.push(t);
            }
        }

        return arr;

    };

    $scope.isDocShow = function (doc) {

        if (!doc) return false;
        if (docsHide.indexOf(doc.id) > -1) return false;
        return true;

    };

    $scope.setActiveOfficeSettingsTab = (office) => {
        $scope.activeOfficeTab = office;
    }

    $scope.getActiveOfficeSettingsTab = () => {
        return $scope.activeOfficeTab;
    }

    $scope.officePayFrequencyOptions = [
        { id: 1, label: "Weekly" },
        { id: 2, label: "Biweekly" }
    ];

    $scope.officePaydayOptions = $scope.daysOfWeek.map((day, index) => {
        return { id: index, label: day };
    });

    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true
    };

    $scope.officePayFrequencyEvents = {
        onSelectionChanged: () => {
            const office = $scope.getActiveOfficeSettingsTab();
            $scope.onHROfficeSettingsChange(office, 'pay_weekly_or_biweekly');
        }
    }

    $scope.officePaydayEvents = {
        onSelectionChanged: () => {
            const office = $scope.getActiveOfficeSettingsTab();
            $scope.onHROfficeSettingsChange(office, 'payday');
        }
    }

    $scope.officeSignaturePersonEvents = {
        onSelectionChanged: () => {
            const office = $scope.getActiveOfficeSettingsTab();
            $scope.onHROfficeSettingsChange(office, 'signaturePerson');
        }
    }

    function getHrSettings() {

        // hr settings
        DatabaseApi.get("agencies/" + $rootScope.agencyId + "/hr_extended_settings").then(
            function (res) {
                if (!res.data.settingsForCertification)
                    res.data.settingsForCertification = {};

                res.data.hrOfficesSettings = res.data.hrOfficesSettings.map(office => {
                    if (office.pay_weekly_or_biweekly) {
                        const findFreq = $scope.officePayFrequencyOptions.find(obj => obj.label === office.pay_weekly_or_biweekly);
                        if (findFreq) {
                            office.pay_weekly_or_biweekly = { id: findFreq.id };
                        } else {
                            office.pay_weekly_or_biweekly = {};
                        }
                    } else {
                        office.pay_weekly_or_biweekly = {};
                    }
                    office.payday = office.payday ? { id: office.payday } : {};
                    office.signaturePerson = office.signaturePerson ? { id: office.signaturePerson } : {};
                    return office;
                });

                $scope.settingsJson = res.data;
            },
            function (err) { }
        );
    };

    getHrSettings();

    getSectionStages();

    function getNextStageOrder() {
        var stages = $scope.stages[$scope.activeTab.val];

        if (!stages || stages.length === 0) {
            return 1;
        } else {
            return Math.max.apply(Math, stages.map(function (stage) { return stage.order; })) + 1;
        }
    }

    $scope.createStage = function () {
        if (!$scope.newStage.labelColor || !$scope.newStage.labelColor) {
            toaster.pop('warning', 'please fill required fields');
            return;
        }

        var newStage = {
            label: $scope.newStage.label,
            order: getNextStageOrder(),
            labelColor: $scope.newStage.labelColor,
            certification: $scope.activeTab.val
        }

        DatabaseApi.post('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages', newStage)
            .then(function (res) {
                toaster.pop('success', 'A new stage was created');
                getSectionStages();
            }, function (err) {
                toaster.pop('error', 'failed to create a new stage');
            });
    }

    $scope.updateStages = function (stages) {
        var stagesEditRequest = { sectionStages: stages };
        var stageUpdateTerm = stages.length > 1 ? 's' : '';

        DatabaseApi.put('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages', stagesEditRequest)
            .then(function (res) {
                stages.forEach(function (stage) {
                    stage.showEdit = false;
                });
                toaster.pop('success', 'stage' + stageUpdateTerm + '  updated successfully');
            }, function (err) {
                toaster.pop('error', 'failed to update stages' + stageUpdateTerm);
            });
    }

    $scope.removeStage = function (stageId) {

        SweetAlert.swal({
            title: "Remove stage?",
            text: "",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                DatabaseApi.delete('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages/' + stageId)
                    .then(function (res) {
                        $scope.stages[$scope.activeTab.val] = $scope.stages[$scope.activeTab.val].filter(function (stage) {
                            return stage.id !== stageId;
                        });
                        toaster.pop('success', 'stage removed successfully');
                    }, function (err) {
                        toaster.pop('error', 'failed to remove stage');
                    });
            }
        });
    }

    $scope.onSectionStageChange = function () {
        DatabaseApi.put('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages', stagesEditRequest)
            .then(function (res) {
                toaster.pop('success', 'stage' + stageUpdateTerm + '  updated successfully');
            }, function (err) {
                toaster.pop('error', 'failed to update stages' + stageUpdateTerm);
            });
    }

    $scope.moveStageDirection = function (stage, direction) {
        var stages = $scope.stages[$scope.activeTab.val].sort(function (a, b) {
            return a.order - b.order;
        });

        var replacedStage;
        var currentStageOrder = stage.order;

        for (var i = 0; i < stages.length; i++) {
            if (stages[i].id === stage.id) {
                if (direction === 'up') {
                    stage.order = stages[i - 1].order;
                    stages[i - 1].order = currentStageOrder;
                    replacedStage = stages[i - 1];

                } else {
                    stage.order = stages[i + 1].order;
                    stages[i + 1].order = currentStageOrder;
                    replacedStage = stages[i + 1];
                }
                break;
            }
        }

        $scope.updateStages([stage, replacedStage])
    }


    $scope.updateIsShown = function (section, subSection, group, question, name) {
        if ($scope.settings[section].subSections[subSection].questionGroups[group].questions[question].isMandatory) {

            if (!$scope.settings[section].subSections[subSection].questionGroups[group].questions[question].isShown) {
                $scope.settings[section].subSections[subSection].questionGroups[group].questions[question].isShown = true;
                var id = name + 'IsShown';
                $timeout(function () {
                    document.getElementById(id).click();
                }, 10);
            }
        }
    };

    $scope.updateSettings = function (id, isShown, isMandatory, question) {

        question.certification = $scope.activeTab.val;

        DatabaseApi.put('hr/agencies/' + $rootScope.agencyId + '/application_question_settings', question).then(function (res) {

        }, function (err) {

        });

    };

    $scope.getExampleUrl = function (doc) {
        if (doc.exampleUrl) $window.open(doc.exampleUrl);
    };

    $scope.onHROfficeSettingsChange = function (office, field) {
        if (
            office.payday === undefined ||
            office.signaturePerson === undefined ||
            office.pay_weekly_or_biweekly === undefined
        ) {
            return;
        }

        if (field === 'signaturePerson' && office.signaturePerson.id) {
            checkIfCoordinatorIsMissingSignature(office.signaturePerson.id);
        }

        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices/${office.officeId}/hr_offices_settings`;
        const body = {
            payday: office.payday.id ? office.payday.id : null,
            signaturePerson: office.signaturePerson.id ? office.signaturePerson.id : null,
            pay_weekly_or_biweekly: office.pay_weekly_or_biweekly.id ? ($scope.officePayFrequencyOptions.find(obj =>
                obj.id === office.pay_weekly_or_biweekly.id
            ).label) : null,
            copyNotes: office.copyNotes ? office.copyNotes : null
        };

        DatabaseApi.put(url, body).then((res) => { }, (err) => {
            toaster.pop('error', 'Failed to update office settings');
        });
    };

    $scope.addComplianceItem = function (section) {
        console.log(section);
        console.log($scope.newItems.selected);
        if (!$scope.newItems.selected[section]) return;

        if ($scope.newItems.selected[section] === 'new') {
            var resolve = {
                section: function () {
                    return section;
                },
                item: function () {
                    return null;
                },
                items: function () {
                    return newComplianceItem;
                }
            };


            var modalInstance = $uibModal.open({
                templateUrl: 'admin/views/new-compliance-item-modal.html',
                size: 'md',
                controller: 'newComplianceItemModalCtrl',
                resolve: resolve
            });

            modalInstance.result.then(function (res) {
                if (res) {
                    console.log(res);
                    if (res.id) {
                        $scope.updateComplianceSettings(res);
                    } else {
                        res.videoId = null;
                        DatabaseApi.post('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items', res)
                            .then(function (res) {
                                var temp = angular.copy(res);
                                temp.id = res.data.complianceId;

                                // getComplianceItems();

                            }, function (err) {
                                toaster.pop('error', 'Something went wrong');
                            });
                    }
                }
            });
        } else {
            customComplianceItems.forEach(function (comp) {
                if (comp.name === $scope.newItems.selected[section]) {
                    var body = angular.copy(newComplianceItem);

                    for (var key in comp) {
                        body[key] = comp[key];
                    }

                    body.videoId = null;
                    DatabaseApi.post('hr/agencies/' + $rootScope.agencyId + '/certifications/' + $scope.activeTab.val + '/compliance_items', body)
                        .then(function (res) {
                            var temp = angular.copy(body);
                            temp.id = res.data.complianceId;

                            $scope.complianceItems.unshift(temp);

                        }, function (err) {
                            toaster.pop('error', 'Something went wrong');
                        });
                }
            });
        }
    }

    $scope.copyCertificationSettings = function (targetCert) {
        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/copy-certification-settings-modal.html",
            windowClass: "copy-certification-settings-modal",
            controller: "copyCertificationSettingsCtrl",
            resolve: {
                targetCert: function () {
                    return targetCert;
                },
                certifications: function () {
                    return $scope.tabList;
                },
            },
            backdrop: "static",
            keyboard: false,
        });

        modalInstance.result.then(
            function (result) {
                switch (result.status) {
                    case "CANCELED":
                        console.log("Canceled copy certification settings modal");
                        return;
                    case "SUCCESS":
                        console.log(
                            `Copied certification settings from: ${result.cert} to: ${targetCert}`
                        );
                        $scope.getSettings(targetCert);
                        break;
                    case "FAIL":
                        console.log(
                            `Failed copying certification settings: `,
                            result.message
                        );
                        toaster.pop(
                            "error",
                            `Failed to copy certification settings`,
                            result.message
                        );
                        break;
                    default:
                        break;
                }
            },
            function () {
                console.log("Canceled copy certification settings modal");
            }
        );
    };

    $scope.copyApplicationSettingsFromTemplate = function (targetCert) {
        SweetAlert.swal(
            {
                title: "Are you sure?",
                text:
                    "This will copy and add application settings from an exisiting template.",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, copy",
                closeOnConfirm: true,
            },
            async (hasConfirmed) => {
                if (hasConfirmed) {
                    console.log(
                        "Confirmed copy certification settings from template for:",
                        targetCert
                    );
                    $scope.isBeingCopiedFromTemplate = true;
                    await CopyCertificationSettingsService.copyApplicationSettingsFromTemplate({
                        agencyId: $rootScope.agencyId,
                        agencyMemberId: $rootScope.agencyMemberId,
                        targetCert,
                    })
                        .then(() => {
                            getApplication();
                        })
                        .catch((err) => {
                            console.log(`Failed copying application settings: `, err);

                            toaster.pop(
                                "error",
                                `Failed to copy application settings`,
                                err
                            );
                        })
                        .finally(() => $scope.isBeingCopiedFromTemplate = false);
                } else {
                    console.log(
                        "Declined copy certification settings from template for:",
                        targetCert
                    );
                }
            }
        );
    };

    ////////////////////////////////
    //// Documents & Compliance ////
    ////////////////////////////////

    $scope.documentTypes = [];
    $scope.selectCertificationsForCaregiverDocumentAndComplianceAgencySettingExtraSettings = {
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        smartButtonMaxItems: 3,
        scrollable: true,
        scrollableHeight: '200px'
    };
    $scope.documentTypeSingleFiltersExtraSettings = {
        styleActive: true,
        selectionLimit: 1,
        singleSelection: true,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    };
    $scope.documentTypeSingleFiltersTranslationTexts = {
        uncheckAll: 'Clear'
    };

    $scope.complianceFieldTypes = [
        { id: 1, label: "Certification Date", value: "requiresCertificationDate" },
        { id: 2, label: "Comments", value: "requiresComments" },
        { id: 3, label: "Completion Date", value: "requiresCompletionDate" },
        { id: 4, label: "Degree", value: "requiresDegree" },
        { id: 5, label: "Doc A", value: "requiresDocA" },
        { id: 6, label: "Doc B", value: "requiresDocB" },
        { id: 7, label: "End Date", value: "requiresEndDate" },
        { id: 8, label: "Instructor", value: "requiresInstructor" },
        { id: 9, label: "Last Employment", value: "requiresLastEmployment" },
        { id: 10, label: "On File", value: "requiresOnFile" },
        { id: 11, label: "Received Date", value: "requiresReceivedDate" },
        { id: 12, label: "Reference 1", value: "requiresReference1" },
        { id: 13, label: "Reference 2", value: "requiresReference2" },
        { id: 14, label: "Results", value: "requiresResults" },
        { id: 15, label: "Score", value: "requiresScore" },
        { id: 16, label: "Sent Out Date", value: "requiresSentOutDate" },
        { id: 17, label: "Start Date", value: "requiresStartDate" },
        { id: 18, label: "Training School", value: "requiresTrainingSchool" },
        { id: 19, label: "Verification Date", value: "requiresVerificationDate" },
    ];

    // $scope.selectCaregiverDocumentTypeEvents = {
    //     onItemSelect: function (item) {
    //         $scope.addNewDocumentType();
    //     }
    // }

    $scope.onClickDocumentItemDropdown = (document) => {
        $scope.edittingDocumentItem = document;
    }

    $scope.yesNoOptions = [
        { id: 0, label: 'Yes', value: true },
        { id: 1, label: 'No', value: false }
    ];

    $scope.categories = [];

    const initCategoriesOptions = (documentTypes) => {
        const categories = [...new Set(documentTypes.map(doc => doc.sectionLabelParsed))];
        $scope.categories = categories.map((cat, index) => {
            return {
                id: index,
                label: cat,
                value: $scope.categories.find(x => x.label === cat && x.value === true) !== undefined
            }
        });
    }

    const getDocumentsTypes = () => {
        const getDocumentTypesUrl = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/document_types`;
        DatabaseApi.get(getDocumentTypesUrl).then((response) => {
            $scope.documentTypesLoaded = true;
            $scope.documentTypes = response.data.documentTypes
                .filter(docType => docType.isActive)
                .map(docType => {
                    docType.documentTypeId = docType.id;
                    delete docType.id;
                    docType.certifications = docType.certifications.filter(c => $scope.tabList.includes(c));
                    docType.certifications = docType.certifications.map(cert =>
                        $scope.certifications.find(c => c.label === cert)
                    );
                    docType.fieldTypeIds = $scope.complianceFieldTypes
                        .filter(field => docType[field.value] === true);
                    docType.sectionLabelParsed = docType.sectionLabel.split("-")[0];
                    idToDoc.set(docType.documentTypeId, docType);
                    setDocumentFollowupIds(docType);
                    return docType;
                });
            followupTrees = setFollowupDocuments($scope.documentTypes);
            $scope.documentTypes = flattenFollowupDocuments(followupTrees);
            initCategoriesOptions($scope.documentTypes);
            sortDefaultDocumentTypes($scope.documentTypes);
            filterDocumentTypesTable();
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong', 'Failed loading documents types');
        });
    }

    function flattenFollowupDocuments(data, depth, root, parent) {
        const result = [];
        depth = depth || 0;

        data.forEach(doc => {
            const docDepth = depth + (doc.depth || 0);

            doc.root = root || doc.root || doc;
            doc.parent = parent || doc.parent || doc;

            result.push({...doc, depth: docDepth});

            if (doc.followupDocuments) {
                result.push(
                    ...flattenFollowupDocuments(
                        doc.followupDocuments,
                        docDepth + 1,
                        doc.root,
                        doc
                    )
                );
            }
        });

        return result;
    }

    function setDocumentFollowupIds(docType) {
        const dropdownFields = docType.complianceData?.fields?.filter((field) => field.type === "Dropdown");

        const documentTypeIdsGroup = dropdownFields.map((field) => field.possibleValues.map((option) => option.followupDocumentRequireData?.followupDocumentTypeId));
        
        const documentTypeIds = documentTypeIdsGroup.flat(1).filter(doc => doc);

        docType.followupDocumentsIds = Array.from(new Set(documentTypeIds));

        docType.followupDocumentsIds.forEach(id => followupDocumentTypesIds.add(id));
    }

    function setFollowupDocuments(data) {
        const followupParentsMap = new Map();
        const dataTree = [];

        data.forEach(doc => {
            doc.followupDocuments = [];

            doc.followupDocumentsIds.forEach(followupId => {
                followupParentsMap.set(followupId, [
                    ...(followupParentsMap.get(followupId) || []),
                    doc
                ]);
            });
        });

        data.forEach(doc => {
            const parents = followupParentsMap.get(doc.documentTypeId);

            if (parents && parents.length > 0) {
                parents.forEach((parent) => {
                    parent.followupDocuments = [
                        ...(parent.followupDocuments || []),
                        doc
                    ];
                });
            } else {
                dataTree.push(doc);
            }
        });
        
        return dataTree;
    }

    getDocumentsTypes();

    $scope.filterDocumentTypesBy = {
        certifications: [],
        isForCompliance: {},
        isForHr: {},
        isPassport: {},
        isMandatory: {},
        fields: [],
        categories: []
    };
    $scope.documentTypesglobalFilter = { val: "" };
    $scope.applyDocumentTypesGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.documentTypesTable) {
            angular.extend($scope.documentTypesTable.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    function sortDefaultDocumentTypes(items) {
        // Sort by: [Section, Name, Depth]
        items.sort((a, b) => a.root.sectionLabel.localeCompare(b.root.sectionLabel) ||
            a.root.name.localeCompare(b.root.name)) ||
            a.depth - b.depth;
    }

    const initDocumentTypesTable = (items, resetFirstPage) => {
        const oldTotal = $scope.documentTypesTable?.total?.() || 0;
        let page = false;
        let sorting;
        if (resetFirstPage) {
            sorting = $scope.documentTypesTable ? $scope.documentTypesTable.sorting() : {};
        } else if ($scope.documentTypesTable) {
            page = $scope.documentTypesTable.page();
            sorting = $scope.documentTypesTable.sorting();
        }

        const options = {
            count: 25,
            sorting: sorting
        };

        $scope.documentTypesTable = new NgTableParams(options, {
            counts: [],
            dataset: items,
            getData: (params) => complianceService.getData(params, items, "documentTypeId", "name"),
        });

        if ($scope.documentTypesglobalFilter.val) {
            $scope.applyDocumentTypesGlobalSearch($scope.documentTypesglobalFilter.val);
        }

        if (page && oldTotal === $scope.documentTypesTable.total()) $scope.documentTypesTable.page(page);
    }

    var documentTypesFilterByMethods = {
        documentHasCertification: (document, selectedCertifications) => {
            return selectedCertifications.find(certification => {
                return document.certifications.find(certObj => certObj.label === certification) !== undefined;
            });
        },
        documentHasCategory: (document, selectedCategories) => {
            return selectedCategories.find(category => {
                return document.sectionLabelParsed === category;
            });
        },
        documentHasIsForCompliance: (document, selectedIsForCompliance) => document.isCompliance === selectedIsForCompliance,
        documentHasIsForHr: (document, selectedIsForHr) => document.isShown === selectedIsForHr,
        documentHasIsPassport: (document, selectedIsPassport) => document.isPassport === selectedIsPassport,
        documentHasIsMandatory: (document, selectedIsMandatory) => document.isMandatory === selectedIsMandatory,
        documentHasFields: (document, selectedFields) => selectedFields.find(field => document[field] === true)
    };

    const filterDocumentTypesTable = (initPage) => {
        if (!$scope.documentTypes) return;

        const filters = [];

        const selectedCertifications = $scope.filterDocumentTypesBy.certifications.map((obj) => {
            return $scope.certifications.find((cert) => cert.id === obj.id).label;
        });
        if (selectedCertifications.length) {
            filters.push(document => documentTypesFilterByMethods.documentHasCertification(document, selectedCertifications));
        }

        const selectedCategories = $scope.filterDocumentTypesBy.categories.map(obj => {
            return $scope.categories.find(cat => cat.id === obj.id).label;
        });
        if (selectedCategories.length) {
            filters.push(document => documentTypesFilterByMethods.documentHasCategory(document, selectedCategories));
        }

        const selectedIsForComplianceObj = $scope.filterDocumentTypesBy.isForCompliance;
        if (Object.keys(selectedIsForComplianceObj).length > 0) {
            const selectedIsForCompliance = $scope.yesNoOptions.find(obj => obj.id === selectedIsForComplianceObj.id).value;
            filters.push(document => documentTypesFilterByMethods.documentHasIsForCompliance(document, selectedIsForCompliance));

            // Check for fields only if selected is compliance
            if (selectedIsForCompliance === true) {
                $scope.filteredIsForCompliance = true;
                const selectedFields = $scope.filterDocumentTypesBy.fields.map(field => {
                    return $scope.complianceFieldTypes.find(obj => obj.id === field.id).value;
                });
                if (selectedFields.length > 0) {
                    filters.push(document => documentTypesFilterByMethods.documentHasFields(document, selectedFields));
                }
            } else {
                $scope.filteredIsForCompliance = false;
            }
        } else {
            $scope.filteredIsForCompliance = false;
        }

        const selectedIsForHrObj = $scope.filterDocumentTypesBy.isForHr;
        if (Object.keys(selectedIsForHrObj).length > 0) {
            const selectedIsForHr = $scope.yesNoOptions.find(obj => obj.id === selectedIsForHrObj.id).value;
            filters.push(document => documentTypesFilterByMethods.documentHasIsForHr(document, selectedIsForHr));
        }

        const selectedIsPassportObj = $scope.filterDocumentTypesBy.isPassport;
        if (Object.keys(selectedIsPassportObj).length > 0) {
            const selectedIsPassport = $scope.yesNoOptions.find(obj => obj.id === selectedIsPassportObj.id).value;
            filters.push(document => documentTypesFilterByMethods.documentHasIsPassport(document, selectedIsPassport));
        }

        const selectedIsMandatoryObj = $scope.filterDocumentTypesBy.isMandatory;
        if (Object.keys(selectedIsMandatoryObj).length > 0) {
            const selectedIsMandatory = $scope.yesNoOptions.find(obj => obj.id === selectedIsMandatoryObj.id).value;
            filters.push(document => documentTypesFilterByMethods.documentHasIsMandatory(document, selectedIsMandatory));
        }

        let filteredDocumentTypes = $scope.documentTypes;

        if (filters.length > 0) {
            const filteredDocumentTypesRoots = filteredDocumentTypes.filter(function (document) {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](document);
                }
                return isFiltered;
            }).map(doc => doc.root);

            filteredDocumentTypes = filteredDocumentTypes.filter(doc => filteredDocumentTypesRoots.includes(doc.root));
        }

        initDocumentTypesTable(filteredDocumentTypes, initPage);
    }

    $scope.$watch('filterDocumentTypesBy', function () {
        filterDocumentTypesTable(true);
    }, true);

    $scope.openAddNewDocumentModal = (document) => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/add-hr-document-modal.html',
            windowClass: 'modal modal-slide-in-right uib-side-modal uib-side-modal-wide',
            size: 'md',
            backdrop: 'static',
            controller: 'addHrDocumentModalCtrl',
            resolve: {
                passedDocument: () => document,
                allDocumentTypes: () => $scope.documentTypes,
                agencyCertifications: () => $scope.certifications,
                followupTrees: () => followupTrees,
            },
        });
        modalInstance.result.finally(_ => {
            return getDocumentsTypes();
        });
    }

    $scope.toggleEditDocumentTypeName = (documentType) => {
        if (!documentType.editNameMode) {
            documentType.oldName = documentType.name;
        }
        if (documentType.editNameMode && documentType.oldName !== documentType.name) {
            const similarDocumentsArr = [];
            $scope.documentTypes.filter(x => x.documentTypeId !== documentType.documentTypeId).forEach(_documentType => {
                if (generalUtils.levenshtein(documentType.name.toLowerCase(), _documentType.name.toLowerCase()) < 65 * _documentType.name.length / 100) {
                    similarDocumentsArr.push(_documentType.name);
                }
            });
            if (similarDocumentsArr.length > 0) {
                return SweetAlert.swal({
                    title: `There are similar document name${similarDocumentsArr.length > 1 ? "s" : ""}`,
                    text: similarDocumentsArr.map((doc, index) => `${index + 1}. ${doc}<br>`).join("") + "Are you sure you want to change the name?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3077EB",
                    confirmButtonText: "Yes, change",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    html: true,
                    allowOutsideClick: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        documentType.editNameMode = !documentType.editNameMode;
                        return $scope.updateDocumentTypeSetting(documentType);
                    }
                });
            } else {
                $scope.updateDocumentTypeSetting(documentType);
            }
        }
        documentType.editNameMode = !documentType.editNameMode;
    }

    $scope.toggleEditDocumentTypeSection = (documentType) => {
        if (!documentType.editSectionMode) {
            documentType.sectionLabelParsedOld = documentType.sectionLabelParsed;
        }
        if (documentType.editSectionMode && documentType.sectionLabelParsedOld !== documentType.sectionLabelParsed) {
            $scope.updateDocumentTypeSetting(documentType);
            initCategoriesOptions($scope.documentTypes);
            filterDocumentTypesTable();
        }
        documentType.editSectionMode = !documentType.editSectionMode;
    }

    $scope.updateDocumentTypeSetting = (documentType) => {
        const url = `agencies/:agencyId/agency_members/:agencyMemberId/caregiver_document_type_agency_settings/:caregiverDocumentTypeId`
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":caregiverDocumentTypeId", documentType.documentTypeId);

        const body = {
            displayName: documentType.name,
            isMandatory: documentType.isMandatory,
            isForCompliance: documentType.isCompliance,
            isForHr: documentType.isShown,
            complianceUploadFromMobile: documentType.complianceUploadFromMobile,
            certifications: documentType.certifications.map(certification => $scope.tabList[certification.id]),
            sectionLabel: documentType.sectionLabelParsed.toLowerCase() + "-doc",
            activeDate: documentType.complianceData.activeDate,
            activeDateDynamic: documentType.complianceData.activeDateDynamic,
            expiryUnit: documentType.expiryUnit,
            expiryAmount: documentType.expiryAmount,
            expires: documentType.expires,
            requireReVerification: documentType.requireReVerification
        };

        DatabaseApi.put(url, body).then(() => {
            filterDocumentTypesTable();
            toaster.pop('success', 'Successfully updated document settings');
        }, () => {
            toaster.pop('error', 'Failed updating document settings');
        })
    }

    $scope.fieldTypesExtraSettings = {
        smartButtonMaxItems: 3,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: true,
        styleActive: true,
        scrollable: true,
        scrollableHeight: '200px'
    };

    $scope.fieldTypesEvents = {
        onSelectionChanged: function () {
            $scope.updateDocumentTypeSetting($scope.edittingDocumentItem);
        }
    };

    $scope.selectCaregiverDocumentTypeCertificationsEvents = {
        onSelectionChanged: function () {
            $scope.updateDocumentTypeSetting($scope.edittingDocumentItem);
        }
    };
    //////////////////////////////////
});
