"use strict";
angular.module("dashboard").component("settingsAgencyLogoUpload", {
  templateUrl: "admin/views/settings-agency-logo-upload.html",
  controller: function ($scope, toaster, AgencyLogoUploadService) {
    $scope.isLoadingHR = false;
    $scope.logoHR = undefined;
    $scope.logoHRURL = undefined;

    $scope.isLoadingApp = false;
    $scope.logoApp = undefined;
    $scope.logoAppURL = undefined;

    this.$onInit = function () {
      AgencyLogoUploadService.getLogos()
        .then(({ HR, App }) => {
          $scope.logoHRURL = HR;
          $scope.logoAppURL = App;
        })
        .catch(() =>
          toaster.pop(
            "error",
            "Something Went Wrong",
            "Couldn't get agency logos"
          )
        );
    };

    $scope.updateAppLogo = function (files) {
      const [file] = files;
      if (!file) return;

      $scope.logoApp = file;
      $scope.logoAppURL = URL.createObjectURL(file);

      $scope.isLoadingApp = true;
      AgencyLogoUploadService.updateLogo({
        type: "APP",
        file,
      })
        .then(() => toaster.pop("success", "Success", "Logo Uploaded"))
        .catch(() =>
          toaster.pop("error", "Something Went Wrong", "Please try again")
        )
        .finally(() => ($scope.isLoadingApp = false));
    };

    $scope.updateHRLogo = function (files) {
      const [file] = files;
      if (!file) return;

      $scope.logoHR = file;
      $scope.logoHRURL = URL.createObjectURL(file);

      $scope.isLoadingHR = true;
      AgencyLogoUploadService.updateLogo({
        type: "HR",
        file,
      })
        .then(() => toaster.pop("success", "Success", "Logo Uploaded"))
        .catch(() =>
          toaster.pop("error", "Something Went Wrong", "Please try again")
        )
        .finally(() => ($scope.isLoadingHR = false));
    };

    $scope.removeAppLogo = function () {
      AgencyLogoUploadService.deleteLogo("APP")
        .then(() => {
          toaster.pop("success", "Success", "Logo removed");
          $scope.logoApp = undefined;
          $scope.logoAppURL = undefined;
        })
        .catch(() =>
          toaster.pop("error", "Something Went Wrong", "Please try again")
        )
        .finally(() => {
          $scope.isLoadingApp = false;
        });
    };

    $scope.removeHRLogo = function () {
      AgencyLogoUploadService.deleteLogo("HR")
        .then(() => {
          toaster.pop("success", "Success", "Logo removed");
          $scope.logoHR = undefined;
          $scope.logoHRURL = undefined;
        })
        .catch(() =>
          toaster.pop("error", "Something Went Wrong", "Please try again")
        )
        .finally(() => {
          $scope.isLoadingHR = false;
        });
    };

    $scope.ignoreClick = function ($event) {
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        $("html").click();
      }
    };
  },
});
