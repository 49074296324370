"use strict";
angular
  .module("dashboard")
  .controller("visitCancelModal", function ($scope, $uibModalInstance) {
    $scope.showVisitInstances = { val: false };
    $scope.visit = $scope.$resolve.visit;
    $scope.visitInstances = $scope.$resolve.visitInstances;
    $scope.onRemoveAllClicked = $scope.$resolve.onRemoveAllClicked;
    $scope.onRemoveOccurrence = $scope.$resolve.onRemoveOccurrence;
    $scope.closeModal = closeModal;

    this.$onInit = function () {};

    $scope.preRemoveOccurrence = function (visit, visitInstance) {
      // Visually filter list..
      $scope.visitInstances = $scope.visitInstances.filter(
        (instance) => instance.id !== visitInstance.id
      );
      $scope.onRemoveOccurrence(visit, visitInstance);
    };

    function closeModal() {
      $uibModalInstance.dismiss();
    }
  });
