app.controller("trainingCenterClassroomNewClassroomModalCtrl", function (
  $scope,
  $rootScope,
  $uibModalInstance,
  $timeout,
  TrainingCenterClassroomService,
  officesService,
  DatabaseApi
) {
  this.$onInit = async function () {
    await initForm();

    setOffices();
    setCertificationOptions();
    setPaycodeOptions();
  };

  $scope.shouldFix = function (field) {
    return $scope.fieldsWithErrors.includes(field);
  };

  $scope.fixedField = function (fixedField) {
    $scope.fieldsWithErrors = $scope.fieldsWithErrors.filter(
      (field) => field !== fixedField
    );
  };

  $scope.removeCertification = function (removeCert) {
    delete $scope.form.paycodePerCertificationMap[removeCert];
    $scope.certPaycodePairs = $scope.certPaycodePairs.filter(
      ({ certification }) => certification !== removeCert
    );

    setCertificationOptions();
  };

  $scope.createClassroom = function () {
    if (!$scope.form) return;

    const { office } = $scope.form;
    if (!office.id) {
      $scope.fieldsWithErrors.push("office");
    }

    const { date, from, to } = $scope.form;
    if (!date) {
      $scope.fieldsWithErrors.push("date");
    }

    const newFrom = getDateTime(date, from);
    const newTo = getDateTime(date, to);
    if (date && !newFrom) {
      $scope.fieldsWithErrors.push("from");
    }
    if (date && !newTo) {
      $scope.fieldsWithErrors.push("to");
    }
    if (fromAfterTo(newFrom, newTo)) {
      $scope.fieldsWithErrors.push("to");
    }

    const { paycodePerCertificationMap } = $scope.form;
    if (Object.keys(paycodePerCertificationMap).length === 0) {
      $scope.fieldsWithErrors.push("certification");
    }

    const { bundles } = $scope.form;
    if (bundles.length === 0) {
      $scope.fieldsWithErrors.push("bundles");
    }

    if ($scope.fieldsWithErrors.length !== 0) return;

    const {
      language: { id: language },
      isOvertime,
    } = $scope.form;
    Object.keys(paycodePerCertificationMap).map(
      (key) =>
        (paycodePerCertificationMap[key] = paycodePerCertificationMap[key].id)
    );
    const form = {
      from: newFrom,
      to: newTo,
      officeId: office.id,
      language,
      isOvertime,
      notes: null,
      paycodePerCertificationMap,
      bundles: bundles.map(({ id }) => id),
    };

    TrainingCenterClassroomService.createClassroom(form).then((id) =>
      $scope.closeModal({ status: "CREATED", id })
    );
  };

  $scope.certificationSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("certification");

      setPaycodeOptions();

      $timeout(() => {
        $("#paycodeSelect").find(".dropdown-toggle").trigger('click');
      }, 50);

    },
  };

  $scope.paycodeSelectionEvents = {
    onItemSelect: function () {
      const paycode = $scope.paycodeOptions.find(
        ({ id }) => $scope.selectedPaycode.id === id
      );
      setCertificationPaycodePairs(paycode);

      $scope.selectedCertification = {};
      $scope.selectedPaycode = {};

      setCertificationOptions();
      setPaycodeOptions();
    },
  };

  $scope.officesSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("office");
    },
  };

  $scope.bundlesSelectionEvents = {
    onItemSelect: function () {
      $scope.fixedField("bundles");
    },
  };

  $scope.closeModal = function (reason) {
    $uibModalInstance.close(reason);
  };

  function fromAfterTo(from, to) {
    if (!from || !from) return;

    return from.getTime() > to.getTime();
  }

  function getDateTime(date, time) {
    if (!date || !time) return;

    const dateStr = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    const timeStr = `${time.getHours()}:${time.getMinutes()}:00`;
    return new Date(`${dateStr} ${timeStr}`);
  }

  function setOffices() {
    const mapOffices = (offices) =>
      offices.map((office) => ({
        id: office.id,
        label: office.name,
      }));

    const setDefaultOffice = () => {
      if ($scope.offices.length !== 1) return;
      const { id, label } = $scope.offices[0];
      $scope.form.office = {
        id,
        label,
      };
    };

    if (!officesService.offices) {
      officesService.getOffices().then((offices) => {
        $scope.offices = mapOffices(offices.filter((office) => office.active));

        setDefaultOffice();
      });
    } else {
      $scope.offices = mapOffices(
        officesService.offices.filter((office) => office.active)
      );

      setDefaultOffice();
    }
  }

  function setCertificationPaycodePairs({ id, label, certification }) {
    $scope.form.paycodePerCertificationMap[certification] = {
      id,
      label,
    };
    $scope.certPaycodePairs.push({ id, label, certification });
  }

  function setCertificationOptions() {
    if (!$scope.form || !$scope.form.paycodePerCertificationMap) return;

    $scope.certificationOptions = $scope.certifications.filter(
      ({ id: certificationId }) =>
        !Object.keys($scope.form.paycodePerCertificationMap).includes(
          certificationId
        ) &&
        $scope.paycodes
          .filter(({ certification }) => certificationId === certification)
          .filter(
            ({ id }) =>
              $scope.certPaycodePairs.find(
                (selectedPaycode) => selectedPaycode.id === id
              ) === undefined
          ).length > 0
    );
  }

  function setPaycodeOptions() {
    if (!$scope.selectedCertification) return;

    $scope.paycodeOptions = $scope.paycodes.filter(
      ({ id, certification }) =>
        $scope.selectedCertification.id === certification &&
        !$scope.certPaycodePairs.find(
          (selectedPaycode) => selectedPaycode.id === id
        )
    );
  }

  async function initForm() {
    $scope.fieldsWithErrors = []; // Names of fields, when validating fill this array with errorred fields

    $scope.offices = [];

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
      .map(certificationItem => ({
        id: certificationItem.certification,
        label: certificationItem.certification
      }));

    $scope.paycodes = [];

    $scope.languageOptions = $rootScope.allLanguages.map((lang) => ({
      id: lang,
      label: lang,
    }));
    $scope.certificationOptions = [];
    $scope.paycodeOptions = [];

    $scope.selectedCertification = {};
    $scope.selectedPaycode = {};

    $scope.certPaycodePairs = [];

    $scope.dateOptions = {
      minDate: new Date(),
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    const from = new Date();
    from.setHours(13, 0);
    const to = new Date();
    to.setHours(15, 0);

    $scope.form = {
      date: new Date(),
      from,
      to,
      office: {},
      language: { id: "English" },
      isOvertime: false,
      paycodePerCertificationMap: {},
      bundles: [],
    };

    $scope.paycodes = (await DatabaseApi.activePayrollCodes()).map(
      ({ id, certification, displayId }) => ({
        id,
        certification,
        label: displayId,
      })
    );

    $scope.bundleOptions = (
      await TrainingCenterClassroomService.getBundles()
    ).map(({ id, label }) => ({
      id,
      label,
    }));
  }
});
