angular
  .module("dashboard")
  .controller("caregiverTrainingCenterTotalWatchedModalCtrl", ($scope, $rootScope, DatabaseApi, toaster, wildcard) => {
    $scope.groupedSegments = undefined;
    $scope.bundleItem = $scope.$resolve.bundleItem;
    $scope.isFetching = {
        segments: false
    }

    function onInit() {
        fetchBundleItem($scope.$resolve.bundleItem.bundleItemId);
    }

    function fetchBundleItem() {
        const endpoint = wildcard(
            'agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/due_dates/:dueDateId/bundle_items/:bundleItemId/total_segments',
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.$resolve.caregiverId,
            $scope.$resolve.dueDateId,
            $scope.$resolve.bundleItem.bundleItemId
        );

        $scope.isFetching.segments = true;

        DatabaseApi.get(endpoint)
            .then(({ data }) => $scope.groupedSegments = _.groupBy(data.segments, "language"))
            .catch(() => toaster.pop("error", "Failed to load segments"))
            .finally(() => $scope.isFetching.segments = false);
    }

    onInit();
  });
