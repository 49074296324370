"use strict";

angular
  .module("dashboard")
  .controller("nursingQuestionStatisticsModalCtrl", function(
    $scope,
    NgTableParams,
    toaster,
    nursingMedicalStatisticsService,
    question
  ) {
    $scope.question = question;
    $scope.resetSelected = resetSelected;
    $scope.selectedAnswer = null;
    $scope.selectedQuestion = null;
    $scope.tableParams = null;
    $scope.isLoading = false;

    function initialize() {
      setTimeout(setChart, 0);
    }

    function setChart() {
      if (!window.Chart) {
        throw new Error(
          "Can't display Nursing Question charts because ChartJS is not defined"
        );
      }

      const canvas = document.getElementById(`nursing-question-modal-chart`);
      const ctx = canvas.getContext("2d");

      const chart = new Chart(
        ctx,
        nursingMedicalStatisticsService.getChartConfigByQuestion($scope.question)
      );
    }

    function importAndSetPatientsTable(answer, question) {
      $scope.isLoading = true;
      $scope.tableParams = null;
      return nursingMedicalStatisticsService.getPatients(question.id, answer.title)
        .then(response => populatePatientsTable(response.data.patients))
        .catch(() => toaster.pop("error", "Error", "Failed to fetch the desired patients"))
        .finally(() => {
          $scope.isLoading = false;
        });
    }

    function populatePatientsTable(patients) {
      $scope.tableParams = new NgTableParams(
        {
          count: 10
        },
        {
          dataset: patients
        }
      );
    }

    $scope.selectPatientsByAnswer = function(data) {
      $scope.selectedAnswer = data.answer;
      $scope.selectedQuestion = data.question;
      $scope.question = $scope.selectedQuestion;

      return importAndSetPatientsTable($scope.selectedAnswer, $scope.selectedQuestion);
    }

    function resetSelected() {
      $scope.selectedAnswer = null;
      $scope.selectedQuestion = null;
    }

    initialize();
  });
