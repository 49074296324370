'use strict'
app.controller("agencyPTOSettingModalCtrl", function (
  $scope,
  $rootScope,
  $uibModalInstance,
  data,
  wildcard,
  DatabaseApi,
  toaster
) {
  /*** HELPERS ***/
  $scope.hoursFieldChangeMinutes = (field) => {
    $scope.PTOform[field + "Minutes"] = $scope.PTOform[field + "Hours"] * 60;
  };

  $scope.minutesFieldToHours = (value) => {
    if (!value) {
      return "";
    }

    return value / 60;
  };

  $scope.getLabelTextById = (labelId) => {
    const labelFound = $scope.caregiverPTOLabels.find(
      (label) => label.id === labelId
    );
    if (labelFound === undefined) {
      return "";
    }

    return labelFound.text;
  };

  /*** INIT DATA ***/
  $scope.data = data;
  $scope.viewModeOnly = false;
  $scope.PTOform = {
    officeId: "",
    labels: [],
    caregiverCertifications: [],
    type: "FIXED",
    fixedPerYearMinutes: undefined,
    fixedPerYearHours: undefined,
    accumMinutes: undefined,
    accumHours: undefined,
    accumRewardMinutes: undefined,
    accumRewardHours: undefined,
  };
  $scope.calculationTypes = [
    { id: "FIXED", label: "Fixed Hours per calendar year" },
    { id: "ACCUM", label: "Accumulating Hours" },
  ];

  if ($scope.data.id !== undefined) {
    $scope.viewModeOnly = true;
    Object.keys($scope.data).forEach((key) => {
      const value = $scope.data[key];
      if (value !== undefined && value !== null) {
        switch (key) {
          case "officeId":
            $scope.PTOform[key] = value.toString();
            break;
          case "labels":
            $scope.PTOform[key] = value.map((labelId) => ({
              id: labelId,
            }));
            break;
          case "accumMinutes":
          case "accumRewardMinutes":
          case "fixedPerYearMinutes":
            $scope.PTOform[key] = value;
            const asHours = $scope.minutesFieldToHours(value);
            $scope.PTOform[key.replace("Minutes", "Hours")] = asHours;
            break;
          default:
            $scope.PTOform[key] = value;
            break;
        }
      }
    });
  }

  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
  $scope.certifications = activeAgencyCertifications
    .map((certificationItem, index) => ({
      id: index,
      label: certificationItem.certification
    }));

  $scope.caregiverPTOLabels = DatabaseApi.caregiverPtoLabels();
  $scope.activeCaregiverPtoLabels = $scope.caregiverPTOLabels.filter(label => label.active === true);

  /*** Form Submission ***/
  $scope.submitForm = function () {
    if ($scope.form.$invalid || $scope.formSending) {
      return;
    }
    if (
      (!$scope.viewModeOnly &&
        $scope.PTOform.caregiverCertifications.length === 0) ||
      $scope.PTOform.labels.length === 0
    ) {
      return;
    }

    $scope.formSending = true;

    const newData = angular.copy($scope.PTOform);

    newData.officeId =
      typeof newData.officeId === "string"
        ? Number.parseInt(newData.officeId)
        : newData.officeId;

    switch (newData.type) {
      case "FIXED":
        newData.accumMinutes = undefined;
        newData.accumHours = undefined;
        newData.accumRewardMinutes = undefined;
        newData.accumRewardHours = undefined;
        break;
      case "ACCUM":
        newData.fixedPerYearMinutes = undefined;
        newData.fixedPerYearHours = undefined;
        break;
    }
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregiver_pto_settings",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );
    newData.labels = newData.labels.map((label) =>
      label.id !== undefined ? label.id : label
    );
    if (data.id) {
      DatabaseApi.put(url, newData)
        .then(function (res) {
          toaster.pop("success", "PTO Settings saved");
          $scope.closeModal();
        })
        .catch(function (err) {
          toaster.pop(
            "error",
            "Something went wrong",
            "Could not change PTO settings"
          );
        })
        .finally(() => ($scope.formSending = false));
    } else {
      newData.caregiverCertifications = newData.caregiverCertifications.map(
        (cert) => (cert.id !== undefined ? cert.label : cert)
      );

      DatabaseApi.post(url, newData)
        .then(function (res) {
          toaster.pop("success", "PTO Settings created");
          $scope.closeModal();
        })
        .catch(function ({ status }) {
          if (status === 409) {
            toaster.pop(
              "error",
              "Conflict found",
              "PTO Setting for this office & certification & label already exists"
            );
          } else {
            toaster.pop(
              "error",
              "Something went wrong",
              "could not add PTO Setting"
            );
          }
        })
        .finally(() => ($scope.formSending = false));
    }
  };

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };

  $scope.exit = function () {
    $uibModalInstance.dismiss();
  };
});
