app.controller('clockLocationBoundCtrl', function ($scope, $rootScope, $uibModal, generalUtils) {
    $scope.checkInDistanceDiff = $rootScope.visitSettings.checkinMaxDistance || 10;
    $scope.isOutOfBound = isOutOfBound();
    $scope.outOfBoundText = "Clock " + $scope.clockType.toLowerCase() + " location is out of bound";

    $scope.openLocationClockMap = function ($event) {
        $event.stopPropagation();
        
        var newScope = $scope.$new();
        newScope.patientLocation = $scope.patientLocation;
        newScope.clockLocation = $scope.clockLocation;
        newScope.callTypeText = $scope.clockType.toLowerCase();
        newScope.checkInDistanceDiff = $scope.checkInDistanceDiff;

        $uibModal.open({
            templateUrl: 'admin/views/visit-instance-clock-type-map-modal.html',
            size: 'md',
            scope: newScope,
            controller: 'visitClockTypeMapModalCtrl'
        });
    }

    function isOutOfBound() {
        if($scope.patientLocation === null || $scope.clockLocation === null) {
            console.log($scope.id);
        }
        var d = generalUtils.distanceInKm($scope.patientLocation.lat, $scope.patientLocation.lng, $scope.clockLocation.lat, $scope.clockLocation.lng); // d = km
        return d * 3280.84 > $scope.checkInDistanceDiff ? true : false;
    }
});