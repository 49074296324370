/**
 * ORIGINAL FILE = patientDocumentService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
export const patientDocumentStatuses = [
    "OPEN_FOR_RESUBMISSION",
    "SENT",
    "COMPLETED",
    "SIGNED",
    "IN_PROGRESS",
    "MISSING",
];
export class PatientDocumentService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    constructor(DatabaseApi2, endpoint, $rootScope) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
    }
    documentFileUrlMap = new Map();
    /**
     * Get a list of all patient documents in the agency
     */
    getPatientsDocuments = (filters) => {
        const format = JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd");
        const queryParams = new URLSearchParams(filters.dueDate !== undefined
            ? {
                from: filters.dueDate.from.format(format),
                to: filters.dueDate.to.format(format),
            }
            : {});
        const queryParamsString = queryParams.toString();
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents?${queryParamsString}`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
            },
        });
        return this.DatabaseApi2.get(url).then((res) => {
            return res.data.documents;
        });
    };
    getDocumentPdfUrl = (documentId) => {
        const pdfUrl = this.documentFileUrlMap.get(documentId);
        if (pdfUrl !== undefined) {
            return Promise.resolve(pdfUrl);
        }
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:patientDocumentId/pdf_url`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientDocumentId: documentId,
            },
        });
        return this.DatabaseApi2.get(url).then((res) => {
            this.documentFileUrlMap.set(documentId, res.data.url);
            return res.data.url;
        });
    };
    getEditPatientDocModalData = (data) => {
        if (data.document.visitInstanceId !== null && data.document.type !== null) {
            const url = this.endpoint({
                path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_documents/:documentTypeId`,
                params: {
                    agencyId: this.$rootScope.agencyId,
                    agencyMemberId: this.$rootScope.agencyMemberId,
                    visitInstanceId: data.document.visitInstanceId,
                    documentTypeId: data.document.type.id,
                },
            });
            return this.DatabaseApi2.get(url).then((res) => ({
                document: {
                    id: res.data.id ?? undefined,
                    documentType: res.data.documentType,
                    title: res.data.title,
                    scheduledVisitId: res.data.scheduledVisitId ?? undefined,
                    patientTaskInstanceId: data.document.taskInstanceId ?? undefined,
                    content: res.data.content,
                    answers: res.data.answers,
                },
                patientId: data.patient.id,
                visitInstanceId: res.data.scheduledVisitId,
                taskInstanceId: null,
            }));
        }
        if (data.document.taskInstanceId !== null && data.document.type !== null) {
            const url = this.endpoint({
                path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances/:patientTaskInstanceId/patient_documents/:documentTypeId`,
                params: {
                    agencyId: this.$rootScope.agencyId,
                    agencyMemberId: this.$rootScope.agencyMemberId,
                    patientTaskInstanceId: data.document.taskInstanceId,
                    documentTypeId: data.document.type.id,
                },
            });
            return this.DatabaseApi2.get(url).then((res) => ({
                document: {
                    id: res.data.document.id ?? undefined,
                    documentType: res.data.document.documentType,
                    title: res.data.document.title,
                    scheduledVisitId: data.document.visitInstanceId ?? undefined,
                    patientTaskInstanceId: data.document.taskInstanceId ?? undefined,
                    content: res.data.document.content,
                    answers: res.data.document.answers,
                },
                patientId: data.patient.id,
                visitInstanceId: null,
                taskInstanceId: res.data.document.patientTaskInstanceId,
            }));
        }
        if (data.document.id === null) {
            throw new Error("patientDocumentScheduleId cannot be null");
        }
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document_scheduled/:patientDocumentScheduleId`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: data.patient.id,
                patientDocumentScheduleId: data.document.id,
            },
        });
        return this.DatabaseApi2.get(url).then((res) => ({
            document: {
                id: res.data.id ?? undefined,
                documentType: res.data.patientDocumentTypeId,
                title: res.data.title,
                scheduledVisitId: res.data.visitInstanceId ?? undefined,
                patientTaskInstanceId: res.data.taskInstanceId ?? undefined,
                content: res.data.content ?? [],
                answers: res.data.answers,
            },
            patientId: data.patient.id,
            visitInstanceId: res.data.visitInstanceId,
            taskInstanceId: res.data.taskInstanceId,
        }));
    };
    parseAgencyPatientDocument = (data) => ({
        patient: {
            id: data.patient.id,
            displayId: data.patient.displayId,
            name: data.patient.name,
            status: data.patient.status,
        },
        physician: {
            name: data.physician.name,
            phone: data.physician.phone,
        },
        document: {
            id: data.document.id,
            title: data.document.title,
            patient: {
                id: data.patient.id,
                name: data.patient.name,
            },
            type: data.document.type === null
                ? null
                : {
                    id: data.document.type.id,
                    title: data.document.type.title,
                },
            versionId: data.document.versionId,
            hasFile: data.document.hasFile,
            isScanned: data.document.isScanned,
            status: data.document.status,
            dueDate: data.document.dueDate === null
                ? null
                : JSJoda.LocalDate.parse(data.document.dueDate, JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd")),
            latestSentOutDate: data.document.latestSentOutDate === null
                ? null
                : JSJoda.LocalDate.parse(data.document.latestSentOutDate, JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd")),
            signedDate: data.document.signedDate === null
                ? null
                : JSJoda.LocalDate.parse(data.document.signedDate, JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd")),
            createdAt: data.document.createdAt === null ? null : JSJoda.Instant.parse(data.document.createdAt),
            createdAtDate: data.document.createdAt === null
                ? null
                : JSJoda.LocalDate.ofInstant(JSJoda.Instant.parse(data.document.createdAt)),
            createdByName: data.document.createdByName,
            visitInstanceId: data.document.visitInstanceId,
            taskInstanceId: data.document.taskInstanceId,
            submittedAt: data.document.submittedAt === null
                ? null
                : JSJoda.LocalDateTime.ofInstant(JSJoda.Instant.parse(data.document.submittedAt)),
            submittedByName: data.document.submittedByName,
            content: data.document.content,
            answers: data.document.answers,
            isOpenForResubmission: data.document.isOpenForResubmission,
            wasSentViaFax: data.document.wasSentViaFax,
            wasSentViaEmail: data.document.wasSentViaEmail,
        },
        assignedNurse: {
            id: data.assignedNurse.id,
            name: data.assignedNurse.name,
        },
    });
    parseDocument = (data) => ({
        id: data.document.id,
        documentType: data.document.type?.id ?? null,
        title: data.document.title,
        versionId: data.document.versionId,
        scheduledVisitId: data.document.visitInstanceId,
        scheduledDate: data.document.dueDate,
        submittedAt: data.document.submittedAt?.format(JSJoda.DateTimeFormatter.ofPattern("dd/MM/yy")) ?? null,
        submittedByName: data.document.submittedByName,
        content: data.document.content,
        answers: data.document.answers,
        pdfUrl: null,
        type: data.document.type?.title ?? null,
        isOpenForResubmission: data.document.isOpenForResubmission,
        wasSentViaFax: data.document.wasSentViaFax,
        wasSentViaEmail: data.document.wasSentViaEmail,
        physicianSignDate: data.document.signedDate,
    });
    openSubmission = (documentId) => {
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                scheduledDocId: documentId,
            },
        });
        return this.DatabaseApi2.post(url);
    };
    getSubmitManuallyEndpointURL = (visitInstanceId) => {
        return this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitInstanceId: visitInstanceId,
            },
        });
    };
    markAsSubmitted = (visitInstanceId, documentTypeId, documentVersionId) => {
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                visitInstanceId: visitInstanceId,
            },
        });
        return this.DatabaseApi2.post(url, {
            base64: null,
            documentId: documentTypeId,
            documentVersion: documentVersionId,
            documentTitle: null,
        });
    };
    deletePatientVisitDocument = (patientId, patientDocumentScheduleId) => {
        const url = this.endpoint({
            path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document/:patientDocumentId`,
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: patientId,
                patientDocumentId: patientDocumentScheduleId,
            },
        });
        return this.DatabaseApi2.delete(url);
    };
}
angular.module("dashboard").service("patientDocumentService", PatientDocumentService);
