app.controller('communicationCtrl', function ($scope, $rootScope, $state, DatabaseApi, $q, $stateParams, toaster, $filter, NgTableParams, SweetAlert, $location, $anchorScroll, $window, offices, $timeout, Analytics, Consts, $uibModal) {

    $scope.orderByArr = {
        val: ['-notViewedCounterBoolean', '-lastMessage'],
        unread: ['-notViewedCounterBoolean', '-lastMessage'],
        new: ['-lastMessage'],
        radio: false
    };

    $scope.languages = [
        "Albanian",
        "Arabic",
        "Armenian",
        "Bengali",
        "Bulgarian",
        "Cantonese",
        "Chinese",
        "Creole",
        "Danish",
        "Dutch",
        "English",
        "Estonian",
        "Farsi",
        "Filipino",
        "French",
        "Fukkianese",
        "Fula/Fulani",
        "Fuzhounese",
        "Georgian",
        "German",
        "Ghana",
        "Greek",
        "Hakka",
        "Hebrew",
        "Hindi",
        "Hungarian",
        "Italian",
        "Japanese",
        "Korean",
        "Krio",
        "Kyrgyz",
        "Mandarin",
        "Pashto",
        "Persian",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Romanian",
        "Russian",
        "Shanghainese",
        "Sign",
        "Soninke",
        "Spanish",
        "Swahili",
        "Tagalog",
        "Taishanese",
        "Taiwanese",
        "Turkish",
        "Twi",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Vietnamese",
        "Yiddish"
        //'English', 'Spanish', 'Chinese', 'Portuguese', 'French', 'Russian', 'Arabic'
    ];

    $scope.offices = offices ? offices.map(office => ({
        id: office.id,
        label: office.name
    })) : [];
    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem) => certificationItem.certification);

    $scope.statuses = ['ACTIVE', 'ON_HOLD', 'ON_LEAVE', 'PENDING', 'SUSPENDED', 'TERMINATED', 'QUIT'];


    var caregivers = {};
    var mapCoordinators = {};
    window.scrollTo(0, 0);
    $scope.state = $stateParams.view === '' ? 'chat' : $stateParams.view;
    $scope.reminderText = ''; // can be used to pre insert message text

    $scope.openCaregiverInformationModal = function (caregiver) {
        if (!caregiver) return;

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/caregiver-extra-info-modal.html',
            size: 'md',
            controller: 'CaregiverExtraInfoModalCtrl',
            resolve: {
                caregiver: function () { return caregiver }
            }

        });

    };

    $scope.getAgencyMemberNameById = function (id) {
        if (!id) return;
        var members = DatabaseApi.getAgencyMembers();
        var m = members[id.toString()];
        return m || '';
    };

    $scope.$watch('orderByArr.radio', function () {
        console.log($scope.orderByArr.radio);
        if (!$scope.orderByArr.radio) {
            $scope.orderByArr.val = angular.copy($scope.orderByArr.new);
        } else {
            $scope.orderByArr.val = angular.copy($scope.orderByArr.unread);
        }
    });



    $scope.chats = [];
    $scope.filteredChatsCount = 0;
    $scope.filter = {
        displayName: '',
        myMessagesOnly: false
    };

    $scope.$watch('filter.myMessagesOnly', function () {
        $scope.fuzzyFilter();
    });

    $scope.limitCount = 100;

    function initState() {
        $scope.available = [];
        $scope.availableCaregivers = [];
        $scope.availableAppInstalledAmount = 0;
        $scope.availableAppUninstalledAmount = 0;
        $scope.selectedIds = [];
        $scope.selected = [];
        $scope.form = {
            tab: 'type',
            types: {},
            language: {},
            office: {},
            name: '',
            text: ''
        };
        $scope.selectedCounties = [];
    }

    initState();
    var caregivers = DatabaseApi.caregivers();

    /*


     - need to have an array with all chats
     - need to manually add all caregivers with no chat
     - need to manually add all caregivers with no chat with the current user


     */
    var caregiversWithNoChatWithWithUser = [];

    for (var c in caregivers) {
        caregiversWithNoChatWithWithUser.push(caregivers[c.toString()].id);
    }


    $rootScope.$on('got_data', function (event) {
        //console.log('got all data');
        caregivers = DatabaseApi.caregivers();
        caregiversWithNoChatWithWithUser = [];
        for (var c in caregivers) {
            caregiversWithNoChatWithWithUser.push(caregivers[c.toString()].id);
        }

        init();
    });

    if (Object.keys(caregivers).length) {
        init();
    }


    function init() {

        if ($state.current.name !== 'app.communication') return;
        if (!Object.keys(caregivers).length) return;
        //console.log('start init', new Date().toTimeString());

        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members').then(function (res) {
            //console.log('got coordinators', new Date().toTimeString());

            $scope.coordinators = res.data.agencyMembers;
            //console.log($scope.coordinators);
            $scope.coordinators.forEach(function (coordinator, i) {
                mapCoordinators[coordinator.id.toString()] = i;
            });
            //console.log('done coordinators', new Date().toTimeString());
        });

        var d = JSON.stringify(new Date('1/1/1980')).slice(1, -1);

        //console.log('done map caregivers', new Date().toTimeString());
        //console.log(caregivers);
        //console.log(mapCaregivers);



        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/chat_rooms/' + $rootScope.agencyMemberId).then(function (res) {
            //if(flaggg) return;
            var chats = res.data.chatRooms;

            var temp = [];
            var existsIds = [];
            var lastMessage = JSON.stringify(new Date('1/1/1980')).slice(1, -1);
            var lastMessageIdx;


            //console.log('got chats', new Date().toTimeString());
            chats.forEach(function (chat) {
                if (chat.chatRoomType.type === "AgencyMemberCaregiverChat") {
                    if (caregivers[chat.chatRoomType.caregiverId]) {
                        var caregiverId = chat.chatRoomType.caregiverId;
                        var chatLine = angular.copy(caregivers[caregiverId]);
                        chatLine.chat = chat;

                        if (chat.chatRoomType.agencyMemberId === $rootScope.agencyMemberId && caregiversWithNoChatWithWithUser.indexOf(caregiverId) > -1) {
                            caregiversWithNoChatWithWithUser.splice(caregiversWithNoChatWithWithUser.indexOf(caregiverId), 1);
                        }

                        //caregivers[chat.chatRoomType.caregiverId].chat = chat;
                        if (chat.members) {
                            var lastViewed;
                            chat.viewed = null;
                            chat.members.forEach(function (member) {
                                if (member.user.type === 'AgencyMember') {
                                    if (member.lastViewed > lastViewed) lastViewed = member.lastViewed;
                                    else if (!lastViewed) lastViewed = member.lastViewed;
                                } else {
                                    chat.viewed = member.lastViewed;
                                }

                            });
                        }
                        var notViewedCounter = 0;
                        if (chat.messages && chat.messages.length) {
                            chatLine.lastMessage = chat.messages[chat.messages.length - 1].createdAt;
                            //console.log(lastMessage);
                            //console.log(chat.messages[chat.messages.length - 1].createdAt);
                            if (chat.messages[chat.messages.length - 1].createdAt > lastMessage) {
                                lastMessage = chat.messages[chat.messages.length - 1].createdAt;
                                lastMessageIdx = temp.length;
                            }

                            if (lastViewed) {
                                var messagesLength = chat.messages.length - 1;
                                for (var i = messagesLength; i >= 0; i--) {
                                    if (!chat.messages[i].broadcast) {
                                        if (chat.messages[i].createdAt > lastViewed) notViewedCounter++;
                                        else break;
                                    }
                                }
                            } else {
                                if (chat.messages[0].user.type === 'Caregiver') {
                                    notViewedCounter++;
                                }
                            }
                            chat.notViewedCounter = notViewedCounter || 0;
                            chat.notViewedCounterBoolean = notViewedCounter > 0;
                            chatLine.notViewedCounterBoolean = notViewedCounter > 0;
                            if (notViewedCounter) {
                                $rootScope.commPageChatCounterCache[chat.id] = true;
                                // chatLine.lastMessage = lastMessage;
                            } else delete $rootScope.commPageChatCounterCache[chat.id];
                        } else {
                            chatLine.lastMessage = d;
                        }
                        temp.push(chatLine);
                        existsIds.push(chat.chatRoomType.caregiverId.toString());
                    }
                }

            });



            $scope.filteredChatsCount = 0;
            caregiversWithNoChatWithWithUser.forEach(function (id) {
                var c = angular.copy(caregivers[id.toString()]);
                if (!c) return;
                c.lastMessage = d;
                c.chat = undefined;
                if ($scope.fuzzyFilter(c)) {
                    $scope.filteredChatsCount = $scope.filteredChatsCount + 1;
                }
                temp.push(c);
            });

            //console.log('done chats', new Date().toTimeString());
            /*temp.sort(function (a,b) {
              if(a.lastMessage > b.lastMessage) return -1;
              return 1;
            });*/
            //console.log('done sort', new Date().toTimeString());

            if ($rootScope.caregiverChatId) {
                if (caregivers[$rootScope.caregiverChatId]) {
                    for (var i = 0; i < temp.length; i++) {
                        if (temp[i].id === $rootScope.caregiverChatId) {
                            $scope.selectChat(temp[i]);
                            break;
                        }
                    }
                }
                $rootScope.caregiverChatId = undefined;
            } else if (!$scope.activeAgencyChat && temp[0]) {
                if (lastMessageIdx) $scope.selectChat(temp[lastMessageIdx]);
                else $scope.selectChat(temp[0]);
                //document.getElementById('text-line').focus();
            }

            $scope.chats = temp;

            //console.log('start', new Date().toTimeString());
            $scope.show = true;

            //console.log('chats');
            //console.log('===================');
            //console.log($scope.chats);
            //console.log(chats);

        }, function (err) {
            toaster.pop('error', "Something Went Wrong", 'Please try again');
        });

    }

    $scope.getCoordinatorName = function (id) {
        var index = mapCoordinators[id];
        if (index >= 0) return $scope.coordinators[index].firstName + ' ' + $scope.coordinators[index].lastName;
        return id;
    };

    $scope.countyMapOptions = {
        selectAll: function () { },
        deSelectAll: function () { },
        deSelectCounty: function (county) { },
        countiesAmount: 0
    }

    $scope.applyFilters = function (section) {
        var started = false;


        $scope.availableCaregivers = [];

        $scope.available = [];
        $scope.selected = [];
        $scope.selectedIds = [];


        $scope.available = [];
        $scope.availableAppInstalledAmount = 0;
        $scope.availableAppUninstalledAmount = 0;
        var tempAvailableCaregivers = [];

        if ($scope.form.name) {
            console.log('name');
            started = true;
            for (var key in caregivers) {
                var caregiver = caregivers[key];
                var lowerName = caregiver.displayName.toLowerCase();
                if (lowerName.search($scope.form.name.toLowerCase()) > -1) {
                    $scope.availableCaregivers.push(caregiver);
                }
            }

        }

        /// types
        var types = [];
        for (var t in $scope.form.types) {
            if ($scope.form.types[t]) types.push(t);
        }

        if (types.length) {
            console.log('types', started);

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    let foundMatch = false;
                    for (let i = 0; i < caregiver.certifications.length && !foundMatch; i++) {
                        if (types.indexOf(caregiver.certifications[i]) > -1) {
                            foundMatch = true;
                        }
                    }
                    if (foundMatch) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                });
            } else {
                started = true;
                for (var key in caregivers) {
                    var caregiver = caregivers[key];
                    let foundMatch = false;
                    for (let i = 0; i < caregiver.certifications.length && !foundMatch; i++) {
                        if (types.indexOf(caregiver.certifications[i]) > -1) {
                            foundMatch = true;
                        }
                    }
                    if (foundMatch) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                }
            }


        }

        /// language
        var types = [];
        for (var t in $scope.form.language) {
            if ($scope.form.language[t]) types.push(t);
        }
        if (types.length) {

            console.log('lang', started);

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    for (var filteredIn = false, i = 0; !filteredIn && i < caregiver.languages.length; i++) {
                        if (types.indexOf(caregiver.languages[i]) > -1) {
                            $scope.availableCaregivers.push(caregiver);
                            filteredIn = true;
                        }
                    }
                });
            } else {
                started = true;
                for (var key in caregivers) {
                    var caregiver = caregivers[key];
                    for (var filteredIn = false, i = 0; !filteredIn && i < caregiver.languages.length; i++) {
                        if (types.indexOf(caregiver.languages[i]) > -1) {
                            $scope.availableCaregivers.push(caregiver);
                            filteredIn = true;
                        }
                    }
                }
            }

        }

        /// Office
        var types = [];
        for (var t in $scope.form.office) {
            if ($scope.form.office[t]) types.push(Number(t));
        }
        if (types.length) {

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    let found = false;
                    types.forEach((officeId) => {
                        // At least one office is enough (then found = true)
                        if (!found && caregiver.officeIds.indexOf(officeId) > -1) {
                            $scope.availableCaregivers.push(caregiver);
                            found = true;
                        }
                    })
                });
            } else {
                started = true;

                for (var key in caregivers) {
                    var caregiver = caregivers[key];
                    let found = false
                    types.forEach((officeId) => {
                        // At least one office is enough (then found = true)
                        if (!found && caregiver.officeIds.indexOf(officeId) > -1) {
                            $scope.availableCaregivers.push(caregiver);
                            found = true;
                        }
                    })
                }
            }
        }

        /// status
        var types = [];
        for (var t in $scope.form.status) {
            if ($scope.form.status[t]) types.push(t);
        }
        if (types.length) {

            console.log('status', started);

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    if (types.indexOf(caregiver.status) > -1) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                });
            } else {
                started = true;

                for (var key in caregivers) {
                    var caregiver = caregivers[key];
                    if (types.indexOf(caregiver.status) > -1) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                }
            }


        }

        // zip
        if ($scope.form.zip) {
            var regex = new RegExp(/[ ]\d{5},/, 'g');

            console.log('zip', started);

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    var zip = caregiver.address.match(regex);
                    if (zip && zip[0]) zip = zip[0].replace(' ', '').replace(',', '');
                    if (zip === $scope.form.zip) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                });
            } else {
                started = true;
                for (var key in caregivers) {
                    var caregiver = caregivers[key];
                    var zip = caregiver.address.match(regex);
                    if (zip && zip[0]) zip = zip[0].replace(' ', '').replace(',', '');
                    if (zip === $scope.form.zip) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                }
            }


        }

        // counties
        var countiesEl = angular.element(document.getElementById("selectAllCountiesBtn")).scope();
        var selectedCounties = null;
        if (countiesEl) {
            selectedCounties = angular.element(document.getElementById("selectAllCountiesBtn")).scope().selectedCounties;
        }
        if (selectedCounties && selectedCounties.length > 0) {
            var selectedCountiesMap = {};
            for (var idx = 0; idx < selectedCounties.length; idx++) {
                selectedCountiesMap[selectedCounties[idx]] = true;
            }
            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                if (selectedCounties.length === $scope.countyMapOptions.countiesAmount) {
                    $scope.availableCaregivers = tempAvailableCaregivers;
                } else {
                    $scope.availableCaregivers = [];
                    tempAvailableCaregivers.forEach(function (caregiver) {
                        if (caregiver.addressCounty !== null) {
                            if (selectedCountiesMap[caregiver.addressCounty]) {
                                $scope.availableCaregivers.push(caregiver);
                            }
                        }
                    });
                }
            } else {
                started = true;
                if (selectedCounties.length === $scope.countyMapOptions.countiesAmount) {
                    $scope.availableCaregivers = Object.values(caregivers);
                } else {
                    for (var key in caregivers) {
                        var caregiver = caregivers[key];
                        if (caregiver.addressCounty !== null) {
                            if (selectedCountiesMap[caregiver.addressCounty]) {
                                $scope.availableCaregivers.push(caregiver);
                            }
                        }
                    }
                }
            }
        }

        // installations
        var types = [];
        for (var t in $scope.form.installation) {
            if ($scope.form.installation[t]) types.push(t);
        }
        if (types.length) {

            console.log('installation', started);

            if (started) {
                tempAvailableCaregivers = angular.copy($scope.availableCaregivers);
                $scope.availableCaregivers = [];
                tempAvailableCaregivers.forEach(function (caregiver) {
                    if (caregiver.appInstalled) {
                        caregiver.confirmed = "installed";
                    } else if (caregiver.appInstalledDate) {
                        caregiver.confirmed = "uninstalled";
                    } else {
                        caregiver.confirmed = "notinstalled";
                    }

                    if (types.indexOf(caregiver.confirmed) > -1) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                });
            } else {
                started = true;
                for (var key in caregivers) {
                    var caregiver = caregivers[key];

                    if (caregiver.appInstalled) {
                        caregiver.confirmed = "installed";
                    } else if (caregiver.appInstalledDate) {
                        caregiver.confirmed = "uninstalled";
                    } else {
                        caregiver.confirmed = "notinstalled";
                    }

                    if (types.indexOf(caregiver.confirmed) > -1) {
                        $scope.availableCaregivers.push(caregiver);
                    }
                }
            }

        }

        // gender
        const selectedGenders = ["female", "male"].filter(gender => 
            $scope.form.gender && $scope.form.gender[gender]
        ).map(gender => gender[0].toUpperCase()); // 'F', 'M'
        if (selectedGenders.length) {
            if (started) {
                $scope.availableCaregivers = $scope.availableCaregivers.filter(caregiver =>
                    selectedGenders.includes(caregiver.gender)
                );
            } else {
                started = true;
                $scope.availableCaregivers = Object.values(caregivers).filter(caregiver =>
                    selectedGenders.includes(caregiver.gender)
                );
            }
        }

        //console.log($scope.form);
        //console.log($scope.available);
        //console.log($scope.selected);

        $scope.available = angular.copy($scope.availableCaregivers);
        $scope.availableAppInstalledAmount = 0;
        $scope.availableAppUninstalledAmount = 0;
        for (var i = $scope.available.length - 1; i >= 0; i--) {
            if ($scope.available[i].appInstalled) {
                $scope.availableAppInstalledAmount++;
            } else {
                $scope.availableAppUninstalledAmount++;
            }
            $scope.selectCaregiver($scope.available[i], i);
        }

    };

    $scope.toggleSelectAll = function (section) {

        switch (section) {
            case 'types': {
                $scope.allCertifications = !$scope.allCertifications;
                if ($scope.allCertifications) {
                    if (!$scope.form.types) {
                        $scope.form.types = {};
                    }
                    $scope.certifications.forEach(function (cert) {
                        if ($scope.form.types[cert] !== true) {
                            $scope.form.types[cert] = true;
                        }
                    });
                } else {
                    $scope.form.types = {};
                }
                break;
            }

            case 'status': {
                $scope.allStatuses = !$scope.allStatuses;
                if ($scope.allStatuses) {
                    if (!$scope.form.status) {
                        $scope.form.status = {};
                    }
                    $scope.statuses.forEach(function (item) {
                        if ($scope.form.status[item] !== true) {
                            $scope.form.status[item] = true;
                        }
                    });
                } else {
                    $scope.form.status = {};
                }
                break;
            }

            case 'language': {
                $scope.allLanguages = !$scope.allLanguages;
                if ($scope.allLanguages) {
                    if (!$scope.form.language) {
                        $scope.form.language = {};
                    }
                    $scope.languages.forEach(function (lang) {
                        if ($scope.form.language[lang] !== true) {
                            $scope.form.language[lang] = true;
                        }
                    });
                } else {
                    $scope.form.language = {};
                }
                break;
            }

            case 'installation': {
                $scope.allInstallations = !$scope.allInstallations;
                if ($scope.allInstallations) {
                    if (!$scope.form.installation) {
                        $scope.form.installation = {};
                    }
                    $scope.form.installation = {
                        "installed": $scope.state !== 'sendDownload',
                        "uninstalled": true,
                        "notinstalled": true
                    }
                } else {
                    $scope.form.installation = {};
                }
                break;
            }

            case 'office': {
                $scope.allOffices = !$scope.allOffices;
                if ($scope.allOffices) {
                    if (!$scope.form.office) {
                        $scope.form.office = {};
                    }
                    $scope.offices.forEach(function (office) {
                        if ($scope.form.office[office.id] !== true) {
                            $scope.form.office[office.id] = true;
                        }
                    });
                } else {
                    $scope.form.office = {};
                }
                break;
            }

            case 'gender': {
                $scope.allGenders = !$scope.allGenders;
                $scope.form.gender = {};
                if ($scope.allGenders) {
                    $scope.form.gender = {
                        "female": true,
                        "male": true
                    };
                }
                break;
            }

            default: {
                break;
            }
        }
    }

    $scope.selectCaregiver = function (giver, i) {
        if ($scope.selectedIds.indexOf(giver.id) === -1) {
            $scope.available.splice(i, 1);
            $scope.selected.push(giver);
            $scope.selectedIds.push(giver.id);
        }
    };

    $scope.selectAll = function (option) {

        if ($scope.state === 'sendDownload') option = 'uninstalled';

        if (option === 'installed') {
            for (var i = $scope.available.length - 1; i >= 0; i--) {
                if ($scope.available[i].appInstalled) $scope.selectCaregiver($scope.available[i], i);
            }
        } else if (option === 'uninstalled') {
            for (var i = $scope.available.length - 1; i >= 0; i--) {
                if (!$scope.available[i].appInstalled) $scope.selectCaregiver($scope.available[i], i);
            }
        } else {
            for (var i = $scope.available.length - 1; i >= 0; i--) {
                $scope.selectCaregiver($scope.available[i], i);
            }
        }
    };

    $scope.deselectCaregiver = function (giver, i) {
        $scope.selected.splice(i, 1);
        $scope.selectedIds.splice(i, 1);
        $scope.available.push(giver);
        $scope.applyFilters('RESET');
    };

    $scope.boostModal = function () {
        var PRICE_PER_SMS = Math.ceil(Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT);

        Analytics.event("Boost clicked", { type: "DOWNLOAD_BOOST" });

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/boost-modal.html',
            size: 'lg',
            controller: 'boostModalCtrl',
            resolve: {
                textAmountToBroadcast: function () { return $scope.selectedIds.length },
                priceOverallCents: function () { return $scope.selectedIds.length * PRICE_PER_SMS },
                type: function () { return "DOWNLOAD_BOOST" }
            },
            windowTopClass: "boost-modal"

        });
        modalInstance.result.then(function (res) {
            if (res === "BOOST") {
                DatabaseApi.post('agency/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/boost', {
                    caregiverIds: $scope.selectedIds,
                    price: PRICE_PER_SMS,
                    type: "DOWNLOAD_BOOST",
                    visit: null
                }).then(function (result) {
                    $scope.sending = false;
                    Analytics.event("Boost done", { type: "DOWNLOAD_BOOST", boostSentAmount: $scope.selectedIds.length, pricePerSms: PRICE_PER_SMS });
                    toaster.pop('success', 'Boosted successfully', 'Allow 10 minutes for sms to be sent');
                }).catch(function (err) {
                    toaster.pop('error', 'Boost failed', err.data.error ? err.data.error : 'Please try again.');
                    $scope.sending = false;
                });
            }
        });
    };

    $scope.send = function (reminder) {
        // $scope.sending = true;

        if (reminder) {

            var data = {
                caregiverIds: $scope.selectedIds,
                text: $scope.downloadMessageText
            };

            DatabaseApi.post('chats/admin/' + $rootScope.agencyId + '/' + $rootScope.user.agencyMember.id + '/download_reminder', data).then(function (res) {
                initState();
                $scope.sending = false;
                window.scrollTo(0, 0);
                toaster.pop('success', "Success", "Reminder was sent successfully");

                Analytics.event('reminder-broadcast', {
                    count: data.caregiverIds.length
                });

            }, function (err) {
                $scope.sending = false;
                toaster.pop('error', "Something Went Wrong", 'Please try again');
            });

        } else {

            var data = {
                postChatMessages: [],
                caregiverIds: $scope.selectedIds
            };


            $scope.selectedIds.forEach(function (id) {
                data.postChatMessages.push({
                    requestId: makeid(),
                    payload: {
                        type: 'Message',
                        message: $scope.form.text
                    }
                });
            });





            var PRICE_PER_SMS = Math.ceil(Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT);

            var modalInstance = $uibModal.open({
                // backdrop: 'static',
                // keyborad: false,
                templateUrl: 'admin/views/boost-modal.html',
                size: 'lg',
                controller: 'boostModalCtrl',
                resolve: {
                    pushNotificationAmountToBroadcast: function () { return $scope.availableAppInstalledAmount },
                    textAmountToBroadcast: function () { return $scope.availableAppUninstalledAmount },
                    priceOverallCents: function () { return $scope.availableAppUninstalledAmount * PRICE_PER_SMS },
                    type: function () { return "MESSAGE_BOOST" }
                },
                windowTopClass: "boost-modal"
            });
            modalInstance.result.then(function (resModal) {
                if (resModal === "BOOST" || resModal === "BOOST_ONLY_PUSH") {
                    $scope.sending = true;
                    DatabaseApi.post(`chats/admin/ ${$rootScope.agencyId}/${$rootScope.user.agencyMember.id}/${resModal === "BOOST_ONLY_PUSH" ? "push_notification" : "push_and_sms"}`,
                        data).then(function (res) {
                            initState();
                            $scope.sending = false;
                            window.scrollTo(0, 0);
                            toaster.pop('success', "Success", (resModal === "BOOST_ONLY_PUSH" ? "Message was broadcasted successfully" : "Allow 10 minutes for sms to be sent"));

                            Analytics.event('chat-broadcast', {
                                count: data.caregiverIds.length
                            });

                            DatabaseApi.post("agency/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/boost",
                                {
                                    caregiverIds: $scope.selectedIds,
                                    price: $scope.availableAppUninstalledAmount * PRICE_PER_SMS,
                                    type: "MESSAGE_BOOST",
                                    visit: null
                                }
                            );

                            Analytics.event("Boost done", { type: "MESSAGE_BOOST", boostSentAmount: $scope.selectedIds.length, pricePerSms: PRICE_PER_SMS });
                            $scope.sending = false;

                        }, function (err) {
                            $scope.sending = false;
                            toaster.pop('error', "Something Went Wrong", 'Please try again');
                        });

                } else {
                    $scope.sending = false;
                }
            }, function () {
                // when clicking outside the modal
                $scope.sending = false;
            });
        }
    };

    $scope.reset = function () {
        initState();
    };


    ////////////////////////
    ////  chats
    ////////////////////////

    function fuzzy_match(str, pattern) {
        pattern = pattern.split("").reduce(function (a, b) {
            return a + '[^' + b + ']*' + b;
        });
        var regx;
        try {
            regx = new RegExp(pattern);
        } catch (e) {

        }
        if (regx) return regx.test(str);
        else return false;
    }

    $scope.fuzzyFilter = function (row) {
        if ($scope.filter.myMessagesOnly && (!row || !row.chat || row.chat.chatRoomType.agencyMemberId !== $rootScope.agencyMemberId)) return false;
        if (!$scope.filter.displayName && (!row || !row.chat || !row.chat.messages || !row.chat.messages.length)) return false;
        if (!$scope.filter || !$scope.filter.displayName) return true;
        if (fuzzy_match(row.displayName.toLocaleLowerCase(), $scope.filter.displayName.toLocaleLowerCase())) return true;
        if (row.caregiverCode && fuzzy_match(row.caregiverCode.toLocaleLowerCase(), $scope.filter.displayName.toLocaleLowerCase())) return true;
        if (row.displayId && fuzzy_match(row.displayId.toString().toLocaleLowerCase(), $scope.filter.displayName.toLocaleLowerCase())) return true;
        return false;
    };

    function makeid() {
        try {
            var NUM_BITS = 160;
            var numBytes = Math.floor(NUM_BITS / 8);
            var buf = new Uint8Array(numBytes);
            window.crypto.getRandomValues(buf);

            return Array.prototype.map.call(buf, function (x) {
                return ("00" + x.toString(16)).slice(-2);
            }).join("");

        } catch (e) {
            toaster.pop('error', 'Browser Not Supported', 'Please use chrome browser');
        }
        return false;

    }

    $scope.form = { message: '' };

    $scope.selectChat = function (chat) {
        console.log(chat);

        //$scope.filter.displayName = '';

        if (chat.chat) {
            //setChat();
            DatabaseApi.get('chats/agency_member_caregiver_chat_rooms/' + chat.chat.chatRoomType.agencyMemberId + '/' + chat.id).then(function (res) {
                chat.chat = res.data;
                setChat();
            });
        } else {
            DatabaseApi.get('chats/agency_member_caregiver_chat_rooms/' + $rootScope.agencyMemberId + '/' + chat.id).then(function (res) {
                chat.chat = res.data;
                setChat();
            });
        }

        function setChat() {
            //console.log('chat change');
            $rootScope.activeAgencyChat = chat.chat.id;
            delete $rootScope.commPageChatCounterCache[chat.id];
            chat.chat.notViewedCounter = 0;
            $scope.activeAgencyChat = chat;
            console.log($scope.activeAgencyChat);
            if (
                (!$rootScope.stealthMode ||
                    $scope.activeAgencyChat.chat.chatRoomType.agencyMemberId === $rootScope.agencyMemberId)
                &&
                $scope.activeAgencyChat.chat
                &&
                $scope.activeAgencyChat.chat.messages
                &&
                $scope.activeAgencyChat.chat.messages.length
            ) {
                var last = $scope.activeAgencyChat.chat.messages[$scope.activeAgencyChat.chat.messages.length - 1].createdAt;
                DatabaseApi.put('chats/' + $scope.activeAgencyChat.chat.id + '/viewed', { messageTime: last }).then(function (res) {
                });
            }
            $location.hash('bottom');
            $anchorScroll();
            $timeout(function () {
                //console.log('============= set input', $window.document.getElementById('text-line'));
                try {
                    $window.document.getElementById('text-line').focus();
                } catch (e) {

                }

            }, 100)

        }
    };

    $scope.sendMessage = function () {

        if (!$scope.form.message || $scope.form.message === '') return;
        $scope.sending = true;

        var idString = makeid();
        if (idString) {
            var message = {
                requestId: idString,
                payload: {
                    type: 'Message',
                    message: $scope.form.message
                }
            };
        } else {
            return;
        }

        //console.log(message);

        if ($scope.activeAgencyChat.chat) {
            // post - chats/:chatRoomId

            message.createdAt = new Date();
            message.user = { type: 'AgencyMember', agencyMemberId: $rootScope.user.agencyMember.id };
            $scope.form.message = '';
            $scope.activeAgencyChat.chat.messages.push(message);

            Analytics.event('agency-chat', {
                chatId: $scope.activeAgencyChat.chat.id
            });

            DatabaseApi.post('chats/' + $scope.activeAgencyChat.chat.id, message).then(function (res) {

                $scope.sending = false;
            });

        } else {

            DatabaseApi.get('chats/agency_member_caregiver_chat_rooms/' + $rootScope.agencyMemberId + '/' + $scope.activeAgencyChat.id).then(function (res) {
                //console.log(res.data);
                $scope.activeAgencyChat.chat = res.data;

                Analytics.event('agency-chat', {
                    chatId: $scope.activeAgencyChat.chat.id
                });

                DatabaseApi.post('chats/' + $scope.activeAgencyChat.chat.id, message).then(function (res) {
                    $scope.form.message = '';
                    $scope.sending = false;
                });

            });
        }
        //console.log(':', $scope.form.message);
        //$scope.form.message = '';
    };

    function getCountForchat(chat) {
        if (chat.members) {
            var lastViewed;
            chat.members.forEach(function (member) {
                if (member.user.type === 'AgencyMember') {
                    if (!lastViewed) lastViewed = member.lastViewed;
                    if (member.lastViewed > lastViewed) lastViewed = member.lastViewed;
                    else if (!lastViewed) lastViewed = member.lastViewed;
                }
            });
            //console.log(lastViewed);
        }

        //console.log(chat);
        var notViewedCounter = 0;
        if (chat.messages) {

            if (lastViewed) {
                var messagesLength = chat.messages.length - 1;
                //console.log(messagesLength);
                for (var i = messagesLength; i >= 0; i--) {
                    if (!chat.messages[i].broadcast) {
                        if (chat.messages[i].createdAt > lastViewed) notViewedCounter++;
                        else break;
                    }
                }
            } else {
                if (chat.messages[0].user.type === 'Caregiver') {
                    notViewedCounter++;
                }
            }
        }

        return notViewedCounter;

    }
    //chatRoomId
    /*
    var testChat = [];
    var rand = 1;
    $interval(function(){
      if(testChat[0] && testChat[1]) {
        var object = rand % 2 === 0 ? testChat[0] : testChat[1];
        rand++;
        console.log('rand');
        //$rootScope.$broadcast('chat_room_updated_com', object);
        //$rootScope.$broadcast('caregiver_changed');
      }
    },700);

    $interval(function(){
      console.log('caregivers');
      //$rootScope.$broadcast('caregiver_changed');
    },500);
    $interval(function(){
      console.log('got_data interval');
      flaggg = true;
      $rootScope.$broadcast('got_data');
    },10000);
    */

    $rootScope.$on('chat_room_updated_com', function (event, chatObject) {

        if ($state.current.name !== 'app.communication') return;


        //var res = chatObject.res;
        var data = chatObject.data;
        data.chatRoomId = data.id;

        var existsFlag = false;
        $scope.chats.forEach(function (chat) {
            if (chat.chat && chat.chat.id === data.chatRoomId) {

                if ($rootScope.activeAgencyChat !== chat.chat.id) {
                    var originalCounter = chat.chat.notViewedCounter;
                    //data.notViewedCounter = chat.chat.notViewedCounter + 1;

                    chat.chat = data;
                    chat.chat.notViewedCounter = getCountForchat(chat.chat);

                    if (!originalCounter && chat.chat.notViewedCounter) $rootScope.commPageChatCounterCache[chat.id] = true;
                    else if (originalCounter && !chat.chat.notViewedCounter) delete $rootScope.commPageChatCounterCache[chat.id];

                } else {
                    var newMessagesCount = data.messages.length - chat.chat.messages.length;

                    // Check if there are new messages in the current chat
                    if (newMessagesCount > 0 && $scope.activeAgencyChat.chat) {
                        var last = data.messages[data.messages.length - 1].createdAt;
                        DatabaseApi.put('chats/' + $scope.activeAgencyChat.chat.id + '/viewed', { messageTime: last }).then(function (res) {
                        });
                    }

                    chat.chat = data;
                    chat.chat.notViewedCounter = 0;
                }

                var viewed;
                chat.chat.members.forEach(function (member) {

                    if (member.user.type === "Caregiver") {
                        viewed = member.lastViewed;
                    }

                });
                chat.chat.viewed = viewed;
                chat.lastMessage = data.messages[data.messages.length - 1].createdAt;

                existsFlag = true;
            }
        });

        if (!existsFlag) {
            // add chat to list
            //console.log(chatObject);
            var caregiverId = data.chatRoomType.caregiverId;
            var chatLine = caregivers[caregiverId];
            if (chatLine) {
                chatLine.chat = data;
                $scope.chats.push(chatLine);
            }
        }

    });


    $rootScope.$on('caregiver_changed', function (event) {
        if ($state.current.name === 'app.communication') setCaregiversStatus();
    });

    function setCaregiversStatus() {

        var caregiversMap = DatabaseApi.caregivers() || {};
        $scope.chats.forEach(function (a, i) {
            $scope.chats[i].online = caregiversMap[a.id.toString()] ? caregiversMap[a.id.toString()].online : false;
        });
        if ($rootScope.activeAgencyChat && $rootScope.activeAgencyChat.id)
            $rootScope.activeAgencyChat.online = caregiversMap[$rootScope.activeAgencyChat.id.toString()].online;

    }

    setCaregiversStatus();


});


app.controller('CaregiverExtraInfoModalCtrl', function ($scope, $rootScope, $uibModalInstance, DatabaseApi, caregiver) {

    $scope.caregiver = caregiver;

    DatabaseApi.get('admin/caregivers/' + caregiver.id + '/cross-agency-information').then(function (res) {
        $scope.agencies = res.data.agencies;
    });

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };
});