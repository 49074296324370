app.controller("exportPatientProfileModal", function($scope, $rootScope, exportProfileService, toaster, wildcard, agencyId, agencyMemberId, patientId) {
    $scope.title = "Export Patient Profile";

    $scope.sections = {
        summary: {
            selected: true,
            title: "Summary"
        },
        calendar: {
            selected: true,
            title: "Calendar"
        },
        statusReview: {
            selected: true,
            title: "Status review"
        },
        emergency: {
            selected: true,
            title: "Emergency Preparedness"
        },
        caregivers: {
            selected: true,
            title: "Caregivers"
        },
        authorizations: {
            selected: true,
            title: "Authorizations"
        },
        diagnosesCodes: {
            selected: true,
            title: "Diagnoses codes"
        },
        planOfCare: {
            selected: true,
            title: "Plan of Care"
        },
    };

    $scope.isCalendarSelected = () => {
        const {caregivers, calendar} = $scope.sections;
        return caregivers.selected || calendar.selected;
    };

    $scope.setCalendarRange = (range) => {
        const { fromDate, toDate } = exportProfileService.getCalendarDatesRange(range);
        $scope.fromDate = fromDate;
        $scope.toDate = toDate;
    };

    $scope.setCalendarRange("last7Days");

    $scope.exportPDF = () => {
        $scope.isExportingProfile = true;

        const sectionsIncluded = Object
            .keys($scope.sections)
            .filter(key => $scope.sections[key].selected);

        const calendarPeriod = $scope.isCalendarSelected() ? {
            startDate: LocalDate.from(nativeJs(moment($scope.fromDate))),
            endDate: LocalDate.from(nativeJs(moment($scope.toDate)))
        } : null;

        const payload = { sectionsIncluded, calendarPeriod };

        return exportProfileService
            .exportPatientProfilePDF(agencyId, agencyMemberId, patientId, payload)
            .finally(() => $scope.isExportingProfile = false);
    };
});
