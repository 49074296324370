app.service("combinePdf", function() {
    /*
        fetchPromisesWithTypes: {
            fetchPromise: Promise,
            type: 'pdf' | 'png' | 'jpg' | 'jpeg'
        }[],
        {"byteArray" | "base64"} returnType;
    */
    return async (fetchPromisesWithTypes, returnType = "byteArray") => {
        try {
            const embedPdf = async (buffer) => {
                const sourcePdf = await PDFLib.PDFDocument.load(buffer);
                const embedPages = await pdfDoc.embedPages(sourcePdf.getPages());
                for (const embed of embedPages) {
                    const page = pdfDoc.addPage();
                    const dims = embed.scale(1);
                    const maxWidth = page.getWidth();
                    const maxHeight = page.getHeight();
                    let width = dims.width;
                    let height = dims.height;
                    if (width > maxWidth) {
                        height = height / width * maxWidth;
                        width = width / width * maxWidth;
                    } else if (height > maxHeight) {
                        width = width / height * maxHeight;
                        height = height / height * maxHeight;
                    }
                    page.drawPage(embed, {
                        width: width,
                        height: height
                    });
                }
                return;
            }
            
            const embedImage = async (buffer, embedder) => {
                const page = pdfDoc.addPage();
                const embed = await embedder(buffer);
                const dims = embed.scale(1);
                const maxWidth = page.getWidth() * 0.9;
                const maxHeight = page.getHeight() * 0.9;
                let width = dims.width;
                let height = dims.height;
                if (width > maxWidth) {
                    height = height / width * maxWidth;
                    width = width / width * maxWidth;
                } else if (height > maxHeight) {
                    width = width / height * maxHeight;
                    height = height / height * maxHeight;
                }

                const x = (page.getWidth() / 2) - (width / 2);
                const y = (page.getHeight() / 2) - (height / 2);
                return page.drawImage(embed, {
                    x: x >= 0 ? x : 0,
                    y: y >= 0 ? y : 0,
                    width: width,
                    height: height,
                });
            }

            const fetchPromises = fetchPromisesWithTypes.map(fetchPromiseWithType => fetchPromiseWithType.fetchPromise);
            const buffers = await Promise.all(fetchPromises);
            const pdfDoc = await PDFLib.PDFDocument.create();

            for (let i = 0; i < buffers.length; i++) {
                const buffer = buffers[i];
                const type = fetchPromisesWithTypes[i].type.toLowerCase();
                if (type === 'pdf') {
                    await embedPdf(buffer);
                } else if (type === 'png') {
                    await embedImage(buffer, pdfDoc.embedPng.bind(pdfDoc));
                } else if (type === 'jpg' || type === 'jpeg') {
                    await embedImage(buffer, pdfDoc.embedJpg.bind(pdfDoc));
                }
            }

            switch (returnType) {
                case "byteArray":
                    return pdfDoc.save();
                case "base64":
                    return pdfDoc.saveAsBase64();
                default:
                    return null;
            }
        } catch (e) {
            throw e;
        }
    };
});
