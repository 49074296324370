"use strict";

app.controller("callsCtrl", function (
    $scope,
    $state
) {
    $scope.goToState = (state) => {
        $state.go(state);
    }
});
