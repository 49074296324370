angular
  .module("dashboard")
  .controller(
    "trainingCenterDueDateModal",
    ($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) => {
      // Set default values
      $scope.resolvedBundleId = $scope.$resolve.bundleId;
      $scope.resolvedBundleDueDate = $scope.$resolve.bundleDueDate;
      $scope.dueDateYear = LocalDate.now().year();
      $scope.dueDateType = undefined;
      $scope.enforceDueDateWatchOrder = false;
      $scope.dueDates = {};

      // If there's a resolved bundle, then populate its data
      if ($scope.resolvedBundleDueDate) {
        const {
          year,
          dueDates,
          enforceWatchOrder,
        } = $scope.resolvedBundleDueDate;

        $scope.dueDateYear = parseInt(year, 10);
        $scope.dueDateType = dueDates.type;
        $scope.enforceDueDateWatchOrder = enforceWatchOrder;

        if (dueDates.type === "Dates") {
          $scope.dueDates = {
            startDate: new Date(dueDates.visibleDate),
            endDate: new Date(dueDates.completionDate),
          };
        }

        if (dueDates.type === "HireDateDerived") {
          $scope.dueDates = dueDates;
        }
      }

      $scope.onChangeForm = () => {
        if ($scope.dueDateType === "HireDateDerived") {
          $scope.dueDates = {
            visibleDaysAfterHireDateAnniversary: -100,
            completionDaysAfterHireDateAnniversary: 100,
          };
        }

        if ($scope.dueDateType === "Dates") {
          $scope.dueDates = {
            startDate: new Date(LocalDate.of($scope.dueDateYear, 4, 10)),
            endDate: new Date(LocalDate.of($scope.dueDateYear, 5, 10)),
          };
        }
      };

      $scope.onSubmitForm = () => {
        processDueDates();

        if ($scope.resolvedBundleDueDate) {
          return updateDueDate();
        }

        return createNewDueDate();
      };

      const processDueDates = () => {
        switch ($scope.dueDateType) {
          case "HireDateDerived":
            const { completionDaysAfterHireDateAnniversary } = $scope.dueDates;
            $scope.dueDates = {
              ...$scope.dueDates,

              // Visual not implemented on webapp
              complianceDaysAfterHireDateAnniversary: completionDaysAfterHireDateAnniversary,
            };
            return;

          case "Dates":
            $scope.dueDates = {
              visibleDate: LocalDate.from(nativeJs($scope.dueDates.startDate)),
              completionDate: LocalDate.from(nativeJs($scope.dueDates.endDate)),

              // Visual not implemented on webapp
              complianceDate: LocalDate.from(nativeJs($scope.dueDates.endDate)),
            };
            return;

          default:
            toaster.pop("error", "something went wrong");
            throw new Error(`Unexpected due date type "${$scope.dueDateType}"`);
        }
      };

      const updateDueDate = () => {
        $scope.isLoading = true;

        const url = "agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/due_dates/:trainingCenterBundleDueDateId/edit"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":trainingCenterBundleId", $scope.resolvedBundleId)
          .replace(
            ":trainingCenterBundleDueDateId",
            $scope.resolvedBundleDueDate.bundleDueDateId
          );

        const params = {
          year: $scope.dueDateYear.toString(),
          enforceWatchOrder: $scope.enforceDueDateWatchOrder,
          dueDates: {
            type: $scope.dueDateType,
            ...$scope.dueDates,
          },
        };

        DatabaseApi.post(url, params)
          .then(({ data }) => {
            toaster.pop("success", "Due date has been succesfully updated");
            $uibModalInstance.dismiss();
            $scope.$resolve.onBundleChange(data);
          })
          .catch(() => toaster.pop("error", "Something went wrong"))
          .finally(() => ($scope.isLoading = false));
      };

      const createNewDueDate = () => {
        $scope.isLoading = true;

        const url = "agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/create_due_date"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":trainingCenterBundleId", $scope.resolvedBundleId);

        const params = {
          year: $scope.dueDateYear.toString(),
          enforceWatchOrder: $scope.enforceDueDateWatchOrder,
          dueDates: {
            type: $scope.dueDateType,
            ...$scope.dueDates,
          },
        };

        DatabaseApi.post(url, params)
          .then(({ data }) => {
            toaster.pop("success", "Due date has been succesfully created");
            $uibModalInstance.dismiss();
            $scope.$resolve.onBundleChange(data);
          })
          .catch(() => toaster.pop("error", "Something went wrong"))
          .finally(() => ($scope.isLoading = false));
      };

      $scope.hireDateSliderOptions = {
        floor: -365,
        ceil: 365,
        showTicksValues: true,
        draggableRange: true,
        ticksArray: [-365, 0, 365],
        translate: function (value, sliderId, label) {
          return getDateFormatBySliderValueHireDate(label, value);
        },
      };

      $scope.dateRangePickerOptions = {
        alwaysShowCalendars: true,
        drops: "up",
        applyClass: "btn-primary",
        locale: {
          direction: "ltr date-range-picker-v2",
          format: "D MMM YYYY", //will give you 6  Jan 17
        },
        autoApply: true,
      };

      function getDateFormatBySliderValueHireDate(label, value) {
        const {
          visibleDaysAfterHireDateAnniversary: visibleDays,
          completionDaysAfterHireDateAnniversary: completionDays,
        } = $scope.dueDates;
        const daysDiff = completionDays - visibleDays;

        switch (label) {
          case "model": {
            if (completionDays === 0) {
              return "Visible from hire date";
            } else if (daysDiff > 0) {
              return "Visible " + daysDiff + " days before due date";
            }
          }
          case "high": {
            if (value < 0) {
              return "Completion " + value + " days before hire date";
            } else if (value > 0) {
              return "Completion " + value + " days after hire date";
            } else {
              return "Completion hire date";
            }
          }
          default:
            return value;
        }
      }
    }
  );
