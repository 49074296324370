app.controller("caregiverTrainingCenterBundleCtrl", function (
  $scope,
  $rootScope,
  $uibModal,
  CaregiverTrainingCenterService,
  SweetAlert,
  toaster
) {
  const service = CaregiverTrainingCenterService;

  this.$onChanges = function (changedData) {
    if (
      changedData.caregiver !== undefined &&
      changedData.caregiver.currentValue !== undefined
    ) {
      $scope.caregiver = changedData.caregiver.currentValue;
      $scope.caregiverId = $scope.caregiver.id;
    }
    if (
      changedData.bundle !== undefined &&
      changedData.bundle.currentValue !== undefined
    ) {
      $scope.bundle = changedData.bundle.currentValue;
    }
  };

  $scope.onClickAdd1TestAttempt = async function (
    bundleDueDateId,
    bundleItemId
  ) {
    let params = {
      numAdditionalAttempts: 1, // TODO: This should be an input field in a modal
      notes: "", // TODO: We should open a modal to allow the user to enter a reason for the additional attempts
    };

    await service
      .addTestAttempt({
        agencyId: $rootScope.agencyId,
        caregiverId: $scope.caregiverId,
        trainingCenterBundleDueDateId: bundleDueDateId,
        trainingCenterBundleItemId: bundleItemId,
        params,
      })
      .then(
        (bundles) =>
          ($scope.bundle = findBundleByDueDateId(bundles, bundleDueDateId))
      )
      .catch((err) => {
        toaster.pop("error", "Couldn't add test attempt");
      });
  };

  $scope.updateBundle = function (bundle) {
    $scope.bundle = bundle;
  };

  function findBundleByDueDateId(bundles, bundleDueDateId) {
    let updatedBundle = bundles.find(function (bundle) {
      let hasBundleDueDate = bundle.bundleDueDates.find(function (
        bundleDueDate
      ) {
        return bundleDueDate.bundleDueDateId === bundleDueDateId;
      });
      return hasBundleDueDate !== undefined;
    });
    return updatedBundle;
  }
});
