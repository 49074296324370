angular.module("dashboard").component("segmentsProgress", {
  templateUrl: "admin/views/segments-progress.html",
  bindings: {
    label: "@",
    segments: "<",
    lengthInSeconds: "=",
  },
  controller: function ($scope) {
    this.$onInit = function () {
      $scope.label = this.label;
      $scope.segments = this.segments;
      $scope.lengthInSeconds = this.lengthInSeconds;
    }
  }
});
