function createDropdownFilter(params) {
    const dropdowns = [...params.dropdowns];
    const getValue = (entity, item) => {
        let value = Object.assign(item, {});
        for (const key of entity.entityPath) {
            value = value[key];
        }
        return value ?? null;
    };
    const filter = (item) => {
        for (const filter of dropdowns) {
            const value = getValue(filter, item);
            if (filter.values.length > 0 && ((Array.isArray(value) && filter.values.map((val) => val.id).filter(val => value.includes(val)).length === 0)
                || (value === null || (!Array.isArray(value) && !filter.values.map((value) => value.id).includes(value))))) {
                return false;
            }
        }
        ;
        return true;
    };
    const setOptionsFromLocalData = (items) => {
        const sets = dropdowns.map(() => new Set());
        for (const item of items) {
            for (let i = 0; i < dropdowns.length; i++) {
                const entity = dropdowns[i];
                const value = getValue(entity, item);
                if (value !== null) {
                    sets[i].add(value);
                }
            }
        }
        for (let i = 0; i < dropdowns.length; i++) {
            const entity = dropdowns[i];
            entity.options = [...sets[i]].map((val) => ({ id: val, label: val }));
        }
    };
    return {
        getValue,
        setOptionsFromLocalData,
        filter,
    };
}
angular.module("dashboard").constant("createDropdownFilter", createDropdownFilter);
export {};
