"use strict";

class AddedBundleEmitter extends EventTarget {
  addBundle() {
    this.dispatchEvent(new Event("BundleAdded"));
  }
}
const eventEmitter = new AddedBundleEmitter();

app
  .factory(
    "caregiverTrainingCenterAddBundleModalService",
    ($rootScope, DatabaseApi, wildcard) => ({
      getPotentialManualBundles: (caregiverId) => {
        const url = wildcard(
          "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/bundles/manual",
          $rootScope.agencyId,
          $rootScope.agencyMemberId,
          caregiverId
        );

        return DatabaseApi.get(url);
      },

      addPotentialManualBundle: ({
        caregiverId,
        bundleId,
        dueDateId,
        visibleDate,
        completionDate,
      }) => {
        const url = wildcard(
          "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/bundles/manual",
          $rootScope.agencyId,
          $rootScope.agencyMemberId,
          caregiverId
        );

        return DatabaseApi.post(url, {
          bundleId,
          dueDateId,
          visibleDate,
          completionDate,
        });
      },

      getBundleAddedEmitter: () => {
        return eventEmitter;
      },
    })
  );
