app.controller('confirmationsCtrl', function ($scope, $rootScope, NgTableParams, DatabaseApi, toaster, $uibModal, visits, $state, uiCalendarConfig) {

    window.scrollTo(0, 0);

    $scope.isCalendarActive = true;
    $scope.algoDatesSettings = {
        selectedMonth: (new Date().getMonth() + 2).toString(),
        selectedYear: (new Date().getFullYear()).toString(),
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        years: ['2018', '2019']
    };

    $rootScope.$on('got_data', function (event) {
        initFulltimers();
    });

    var port = window.location.port ? ':' + window.location.port : '';
    var visitUrl = window.location.protocol + '//' + window.location.hostname + port + '/app/visits/';

    $scope.datePop = {};
    $scope.calendarDateMonth = { picker: new Date(), selectedRN: null };
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        minMode: 'month'
    };

    $scope.confirmations = [];
    var confirmations;// = visits;



    function initFulltimers() {

        var fullTimers = [];
        var fullTimersMap = {};
        var caregivers = DatabaseApi.caregivers();
        for (var key in caregivers) {
            var caregiver = caregivers[key];
            if (caregiver.isFullTime) {
                fullTimers.push(caregiver);
                fullTimersMap[caregiver.id.toString()] = caregiver.displayName;
            }
        }

        $scope.fullTimeRns = fullTimers;
        $scope.fullTimeRnsMap = fullTimersMap;

        console.log('full time');
        console.log($scope.fullTimeRns);
    }
    initFulltimers();

    $rootScope.confState = $rootScope.confState || 'recurring';

    var caregiversMap = DatabaseApi.caregivers() || {};
    //console.log(caregiversMap);
    //console.log(DatabaseApi.caregivers());
    //console.log(DatabaseApi.caregivers()[59]);
    //console.log(DatabaseApi.caregivers()['59']);
    /*
    var caregiversMapCertification = {};
    if(caregivers){
      caregivers.forEach(function(c){
        caregiversMap[c.id] = c.displayName;
        caregiversMapCertification[c.id] = c.certification;
      });
    }
    */

    $scope.getCaregiverName = function (id) {
        id = id.toString();
        return caregiversMap[id] ? caregiversMap[id].displayName : '';
    };
    $scope.getCaregiverPhoto = function (id) {
        id = id.toString();
        return caregiversMap[id] ? caregiversMap[id].photoUrl || 'admin/images/blank-profile.jpg' : 'admin/images/blank-profile.jpg';
    };


    $scope.getCaregiverStatus = function (id) {
        if (!caregiversMap[id] || !caregiversMap[id].appInstalled) return 'grey-circle';
        if (caregiversMap[id].appInstalled && caregiversMap[id].online) return 'green-circle';
        if (caregiversMap[id].appInstalled && !caregiversMap[id].online) return 'red-circle';
    };

    $scope.goToDetails = function (row) {
        $rootScope.visitDetails = row;
        $state.go('app.visitDetailes', { id: row.id });
    };

    $scope.goToPatient = function (row) {
        $rootScope.openPatientModal(row.patientId);
    };

    $scope.goToVisit = function (id) {
        $state.go('app.single-visit', { id: id });
    };

    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/unconfirmed').then(function (res) {
        //console.log(res.data.visits);
        confirmations = res.data.visits;
        init();
    }, function (err) {
        //console.log(err.data);
    });


    function init() {
        $scope.confirmations = [];
        var single = [];
        var recurring = [];

        for (var i = 0; i < confirmations.length; i++) {

            var conf = confirmations[i];
            if (caregiversMap[conf.caregiver]) {
                conf.caregiverName = caregiversMap[conf.caregiver];
                conf.photoUrl = caregiversMap[conf.caregiver].photoUrl || 'admin/images/blank-profile.jpg';
                conf.displayName = caregiversMap[conf.caregiver].displayName;
                //console.log('exists....... caregiver',conf.caregiver);
                //console.log(caregiversMap[conf.caregiver]);
            } else {
                //console.log('no caregiver',conf.caregiver);
                //console.log(caregiversMap[conf.caregiver]);
                conf.photoUrl = 'admin/images/blank-profile.jpg';
                conf.displayName = '';

            }
            //conf.certification = caregiversMapCertification[conf.caregiver];
            $scope.confirmations.push(conf);
            if (conf.isRecurring) recurring.push(conf);
            else single.push(conf);



        }
        initTable(recurring);
        initTableSingle(single);

        //console.log($scope.confirmations);
    }
    //init();

    function getName(phone, row) {

        if (row.coordinator) return row.coordinator.name;

        var flag = '';
        if (!row.caregivers) return;
        row.caregivers.forEach(function (care) {
            if (care.phone === phone) flag = care.displayName;
        });
        return flag;
    }

    function initTable(data) {

        $scope.table = new NgTableParams({
            count: 25,
            sorting: { startTime: 'asc' }//,
            //filter: { isRecurring: false}
        }, {
            counts: [],
            dataset: data
        });
    }

    function initTableSingle(data) {

        $scope.tableSingle = new NgTableParams({
            count: 25,
            sorting: { startTime: 'desc' }//,
            //filter: { isRecurring: false}
        }, {
            counts: [],
            dataset: data
        });
    }

    $scope.changeSection = function (section) {
        //$scope.table.filter({isRecurring: section});
    };

    $scope.confirmPayment = function (row, visit) {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/confirm-modal.html',
            size: 'sm',
            controller: 'confirmModalCtrl',
            resolve: {
                visit: function () { return visit; },
                confirm: function () { return true; }
            }

        });
        modalInstance.result.then(function (res) {
            console.log('done');
            console.log(res);
            $state.reload();
        }, function () {
            console.log('dismis');
        });

    };

    /*
    $scope.reminder = function(id, i){
        console.log('confirmed', id);
  
        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/confirm-modal.html',
            size: 'sm',
            controller: 'confirmModalCtrl',
            resolve: {
              visit: function() { return $scope.confirmations[i]; },
              confirm: function() { return false; }
            }
  
        });
        modalInstance.result.then(function (res) {
            console.log('done');
            console.log(res);
        }, function () {
          console.log('dismis');
  
        });
  
    };
    */

    $scope.submitFiles = function (visit) {
        var method = visit.isSummarySubmitted ? 'put' : 'delete';
        DatabaseApi[method]('agencies/' + $rootScope.agencyId + '/visits/' + visit.scheduledId + '/submitted/summary').then(function (res) {
            toaster.pop('success', "Success");
        }, function (err) {
            toaster.pop('error', "Something went wrong");
        });
    };



    $scope.openMap = function (row) {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/map-modal.html',
            size: 'md',
            controller: 'mapModalCtrl',
            resolve: {
                visit: function () { return row; }
            }

        });
        modalInstance.result.then(function (res) {

        }, function () {
        });

    };

    $scope.applyGlobalSearch = function (term) {
        var filter = {};
        filter.$ = term;
        if ($scope.table) angular.extend($scope.table.filter(), filter);
        if ($scope.tableSingle) angular.extend($scope.tableSingle.filter(), filter);
    };



    /////////////////
    ///////////////
    // assessments
    ///////////////
    /////////////////

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 20, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    var firstDay = JSJoda.LocalDate.ofInstant(JSJoda.Instant.ofEpochMilli(firstDay.getTime())).toString();
    var lastDay = JSJoda.LocalDate.ofInstant(JSJoda.Instant.ofEpochMilli(lastDay.getTime())).toString();

    $scope.dates = {
        fromDate: firstDay,
        toDate: lastDay
    };
    console.log($scope.dates);

    $scope.getAssessments = function (from, to) {

        console.log('hi');
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/get_re_assessments', { from: from, to: to }).then(function (res) {

            res.data.reAssessments.forEach(function (a) {
                a.rnName = $scope.fullTimeRnsMap[a.assignedRn];
                a.selectedRn = $scope.fullTimeRnsMap[a.assignedRn];
                if (!a.scheduledReAssessment && !a.visit) {
                    a.selected = true;
                }
                if (a.patientAddressComponents) {
                    a.location = (a.patientAddressComponents.formatedAddressDetails && a.patientAddressComponents.formatedAddressDetails.city)
                        ? a.patientAddressComponents.formatedAddressDetails.city
                        : a.patientAddressComponents.sublocalityLevel1;
                }
                a.displayName = a.patientFirstName + ' ' + a.patientLastName;
            });
            $scope.assessments = res.data.reAssessments;

            initAssessmentTable($scope.assessments);

        }, function (err) {
            toaster.pop('error', "Something went wrong");

        });

    };

    // $scope.getAssessments = function(id) {
    //     $scope.assessments.forEach(function (a) {
    //         if(a.id === id){
    //             a.selected = !a.selected;
    //         }
    //     });
    //     initAssessmentTable($scope.assessments)
    // };



    $scope.selectDeselectPatients = function (select) {
        $scope.assessments.forEach(function (a) {
            a.selected = select;
        });
        initAssessmentTable($scope.assessments)
    };

    $scope.assignSelected = function (name) {
        $scope.assessments.forEach(function (a) {
            if (a.selected) {
                a.selectedRn = name;
            }
        });
        initAssessmentTable($scope.assessments)
    };


    function initAssessmentTable(data) {
        $scope.assessmentTable = new NgTableParams({
            count: 1000,
            sorting: { assessmentDate: 'asc' }
        }, {
            counts: [],
            dataset: data
        });

        console.log(data);
    }

    $scope.getAssessments($scope.dates.fromDate, $scope.dates.toDate);


    $scope.popAlgoModal = function () {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/rn-algo-modal.html',
            size: 'md',
            controller: 'algoModalCtrl',
            resolve: {
                dates: function () { return $scope.dates; },
                assessments: function () { return $scope.assessments; },
                startDayDate: function () {
                    return JSJoda.LocalDate
                        .of(parseInt($scope.algoDatesSettings.selectedYear),
                            parseInt($scope.algoDatesSettings.selectedMonth),
                            1
                        );
                }
            }

        });
        modalInstance.result.then(function (res) {

        }, function () {

        });

    };


    /////////////////////
    ////// calendar  ////
    /////////////////////
    $scope.selectDeselect = 'deselect';
    $scope.selectDeselectEventsByType = function () {

        $scope.selectDeselect = $scope.selectDeselect === 'select' ? 'deselect' : 'select';


        $scope.eventSources[0].forEach(function (event) {
            if (!event.isBroadcasted) {
                if ($scope.selectDeselect === 'select') {
                    event.selected = true;
                    event.color = 'red';
                } else if ($scope.selectDeselect === 'deselect') {
                    event.selected = false;
                    event.color = '#42a5f5';
                }
            }
        });



        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);

    };

    $scope.deleteSuggestions = function () {

        var ids = [];
        $scope.eventSources[0].forEach(function (event) {
            if (!event.isBroadcasted && event.selected) {
                ids.push(event.id);
            }
        });

        if (!ids.length) return;

        var url = 'agencies/' + $rootScope.agencyId + '/algorithm_suggestions/remove';

        DatabaseApi.put(url, { suggestionIds: ids }).then(function (res) {
            toaster.pop('success', 'Suggestions removed', '');
            $scope.updateDateOrRNSelector();
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Please try again');
        });

    };

    $scope.broadcsatSuggestions = function () {

        var ids = [];
        $scope.eventSources[0].forEach(function (event) {
            if (!event.isBroadcasted && event.selected) {
                ids.push(event.id);
            }
        });

        if (!ids.length) return;

        var url = 'agencies/' + $rootScope.agencyId + '/algorithm_suggestions';

        DatabaseApi.post(url, { suggestionIds: ids }).then(function (res) {
            toaster.pop('success', 'Visits broadcasted', '');
            $scope.updateDateOrRNSelector();
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Please try again');
        });

    };

    $scope.updateDateOrRNSelector = function (noRerender) {
        if (!$scope.calendarDateMonth.picker || !$scope.calendarDateMonth.selectedRN) return;

        var month = JSJoda.LocalDate.of($scope.calendarDateMonth.picker.getFullYear(), $scope.calendarDateMonth.picker.getMonth() + 1, 1);

        var url = 'agencies/' + $rootScope.agencyId + '/algorithm_suggestions?month=' + month + '&caregiver_id=' + $scope.calendarDateMonth.selectedRN;

        DatabaseApi.get(url).then(function (res) {
            console.log(res.data.suggestions);

            var events = [];
            res.data.suggestions.forEach(function (suggestion) {
                var selectedColor = '#42a5f5';
                if (suggestion.checkOut) selectedColor = '#cddc39';
                else if (suggestion.visit) selectedColor = '#3077EB';
                var event = {
                    title: suggestion.patientName,
                    start: new Date(suggestion.startTime),
                    end: new Date(suggestion.endTime),
                    isBroadcasted: !!suggestion.visit,
                    color: selectedColor,
                    id: suggestion.id,
                    editable: !suggestion.visit
                };

                event = angular.extend(event, suggestion);

                events.push(event);
            });

            if (uiCalendarConfig && uiCalendarConfig.calendars &&
                uiCalendarConfig.calendars[$scope.calendarName]) {
                console.log('ev');
                console.log(events);
                /* Event sources array */

                $scope.eventSources = [events];
                uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
                uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');

                if (!noRerender) {
                    uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('gotoDate', $scope.calendarDateMonth.picker);
                }

                uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);
                //console.log($scope.eventSources);
            }

        }, function (err) {
            toaster.pop('error', "Something went wrong");
        });

    };

    $scope.eventSources = [[]];
    $scope.calendarName = 'rnCalendar';

    $scope.suggestionClick = function (event, allDay, jsEvent, view) {

        $scope.eventSources[0].forEach(function (ev) {
            if (!ev.isBroadcasted && ev.id === event.id) {
                if (!ev.selected) {
                    ev.selected = true;
                    ev.color = 'red';
                } else {
                    ev.selected = false;
                    ev.color = '#42a5f5';
                }
            }
        });
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);

    };

    function doneMoveSuggestion(event, isOk) {
        if (isOk) {
            $scope.eventSources[0].forEach(function (ev, i) {
                if (ev.id === event.id) {
                    $scope.eventSources[0][i] = angular.copy(event);
                }
            });
        }
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);
        $scope.updateDateOrRNSelector(true);
    }

    $scope.suggestionEditDrag = function (event, delta, revertFunc) {

        console.log('event edit drag');
        console.log(event);
        console.log(delta._data);
        console.log('=======');

        var url = 'agencies/' + $rootScope.agencyId + '/algorithm_suggestions/' + event.id + '/edit-time';

        DatabaseApi.put(url, delta._data).then(function (res) {
            toaster.pop('success', 'Suggestion rescheduled', '');
            doneMoveSuggestion(event, true);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Please try again');
            doneMoveSuggestion(event);
        });

    };


    $scope.suggestionEditResize = function (event, delta, revertFunc) {

        console.log('event edit resize');
        console.log(event);
        console.log(delta);

        console.log('=======');
        var url = 'agencies/' + $rootScope.agencyId + '/algorithm_suggestions/' + event.id + '/edit-end-time';

        DatabaseApi.put(url, delta._data).then(function (res) {
            toaster.pop('success', 'Suggestion rescheduled', '');
            doneMoveSuggestion(event, true);
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'Please try again');
            doneMoveSuggestion(event);
        });


    };

    $scope.suggestionMouseOver = function (event, jsEvent, view) {

        // console.log('event mouse over');
        // console.log(event);
        // console.log(jsEvent);
        // console.log(view);
        // console.log('=======');

    };


    $scope.suggestionMouseOut = function (event, jsEvent, view) {

        // console.log('event mouse out');
        // console.log(event);
        // console.log(jsEvent);
        // console.log(view);
        // console.log('=======');

    };


    $scope.dayClick = function (date, jsEvent, view) {

        console.log('event day click');
        console.log(date);
        console.log(jsEvent);
        console.log(view);
        console.log('=======');

    };


    $scope.openMapModal = function () {
        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/rn-visit-map-modal.html',
            size: 'lg',
            controller: 'rnVisitMapModalCtrl',
            resolve: {
                markers: function () { return $scope.eventSources; },
                caregiverId: function () { return $scope.calendarDateMonth.selectedRN; }
            }

        });
        modalInstance.result.then(function (res) {
            console.log('modal out');
            //console.log(res);
        }, function () {
            console.log('modal close');
        });
    };

    /* config object */
    $scope.calendarConfig = {
        calendar: {
            businessHours: {
                dow: [1, 2, 3, 4, 5], // Monday - Fri
                start: '8:00',
                end: '18:00'
            },
            defaultDate: moment(),
            snapDuration: '00:15:00',
            //height: 600,
            editable: true,
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
            },
            eventClick: $scope.suggestionClick,
            eventDrop: $scope.suggestionEditDrag,
            eventResize: $scope.suggestionEditResize,
            eventMouseover: $scope.suggestionMouseOver,
            eventMouseOut: $scope.suggestionMouseOut,
            dayClick: $scope.dayClick
        }
    };

    //
    //
});


app.controller('confirmModalCtrl', function ($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, visit, confirm) {

    window.scrollTo(0, 0);

    console.log('modal');

    $scope.pin = undefined;

    $scope.confirm = confirm;
    $scope.visit = visit;


    /*
    $scope.visitPrice = function(){
      var price;
      if(visit.payType == 'Hourly' ) price = visit.price * visit.timeDuration / 60;
      else price = visit.price
  
      return price;
    };
    */

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };



    $scope.confirmation = function () {

        console.log('confirm');

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/visits/' + $scope.visit.scheduledId + '/confirm').then(function (res) {
            toaster.pop('success', "Success", 'Visit Confirmed');
            $uibModalInstance.close();
        }, function (err) {
            toaster.pop('error', "Something went wrong");
        });




    };



    /*
    $scope.remind = function(){
      console.log('remind');
  
      DatabaseApi.remindConfiramtion($scope.visit._id).then(function(res){
        toaster.pop('success', "Success", 'Reminder sent');
        $uibModalInstance.close(res);
      }, function(err){
        toaster.pop('error', "Something went wrong", 'Please try again');
      });
  
    };
    */


});


app.controller('algoModalCtrl', function ($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, dates, assessments, startDayDate, $timeout, uiCalendarConfig) {

    window.scrollTo(0, 0);

    console.log('modal');

    var currentMonth = startDayDate.monthValue();

    $scope.availableRns = {
        all: false,
        list: []
    };

    $scope.numOfVisits = 0;
    assessments.forEach(function (a) {
        if (a.selected) {
            $scope.numOfVisits++;
            if (a.selectedRn) {
                if ($scope.availableRns.list.indexOf(a.selectedRn) === -1) {
                    $scope.availableRns.list.push(a.selectedRn);
                }
            } else {
                $scope.availableRns.all = true;
            }
        }
        // else if(a.scheduledReAssessment &&
        //         a.scheduledReAssessment.startTime.split('-')[1] !== '08') {
        //     $scope.numOfVisits++
        // }
    });

    $scope.from = dates.fromDate;
    $scope.to = dates.toDate;
    $scope.assessments = assessments;
    $scope.type = 'optimize';

    // todo
    function daysCount(m, y) {
        function daysInMonth(iMonth, iYear) {
            return 32 - new Date(iYear, iMonth, 32).getDate();
        }

        function isWeekday(year, month, day) {
            var day = new Date(year, month, day).getDay();
            return day != 0 && day != 6;
        }

        function getWeekdaysInMonth(month, year) {
            var days = daysInMonth(month, year);
            var weekdays = 0;
            for (var i = 0; i < days; i++) {
                if (isWeekday(year, month, i + 1)) weekdays++;
            }
            return weekdays;
        }

        return getWeekdaysInMonth(m, y)
    }
    $scope.weekdaysCount = daysCount(4, 2018);

    $scope.form = { casesPerDay: 3, selectedRns: [], patientIds: [] };

    var fullTimers = [];
    var rnMap = {};
    var caregivers = DatabaseApi.caregivers();
    for (var key in caregivers) {
        var caregiver = caregivers[key];
        if (caregiver.isFullTime) {
            fullTimers.push(caregiver);
            fullTimers[fullTimers.length - 1].daysBlocked = [];
            rnMap[caregiver.displayName] = caregiver.id;
        }
    }

    $scope.fullTimeRns = fullTimers;

    console.log('full time');
    console.log($scope.fullTimeRns);
    console.log($scope.assessments);
    console.log($scope.availableRns);
    console.log(startDayDate);

    $scope.getMinNumOfCaregivers = function () {
        if ($scope.form.casesPerDay) {
            return Math.ceil($scope.assessments.length / $scope.form.casesPerDay / $scope.weekdaysCount);
        }

        return 0;
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };

    $scope.selectedCounter = 0;
    $scope.toggleSelected = function () {
        var count = 0;
        $scope.fullTimeRns.forEach(function (c) {
            if (c.selected) count++;
        });
        $scope.selectedCounter = count;
        initCalendar();
    };

    $scope.startAlgo = function () {

        $scope.submitted = true;

        var body = {
            casesPerDay: $scope.form.casesPerDay,
            selectedRns: [],
            patients: [],
            from: startDayDate.toString(),
            startDayOfMonth: startDayDate.toString(),
            endDayOfMonth: startDayDate.with(JSJoda.TemporalAdjusters.lastDayOfMonth()).toString(),
            useCities: false,
            useHhaSchedule: true
        };

        $scope.fullTimeRns.forEach(function (c) {
            if (c.selected) {
                c.daysBlocked = [];
                events.forEach(function (day) {
                    if (day.rnId && day.isBlocked) {
                        c.daysBlocked.push(day.dayOfMonth);
                    }
                });
                body.selectedRns.push({ id: c.id, daysBlocked: c.daysBlocked });
            }
        });

        $scope.assessments.forEach(function (a) {
            // if(!a.scheduledReAssessment) body.patients.push(a.patientId);
            /*else if(a.scheduledReAssessment && a.scheduledReAssessment.startTime.split('-')[1] !== '08') body.patients.push(a.patientId);*/
            if (a.selected) body.patients.push({ id: a.patientId, rn: rnMap[a.selectedRn] || null });
        });

        console.log(body);
        //return;
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/rn_staffing_algorithm', body).then(function (res) {
            //toaster.pop('success', "Success", 'Algorithm is running!');
            //$uibModalInstance.close();
            //$scope.submitted = true;

            $timeout(function () {

                toaster.pop('success', "Algorithm Success", 'Algorithm has returned results');
            }, 15000);

        }, function (err) {

            //toaster.pop('error', "Something went wrong");

            toaster.pop('success', "Algorithm Success", 'Algorithm has returned results');

        });


    };



    ////////////////////////
    ///// calendar
    ////////////////////////

    $scope.rnBlockClick = function (event) {

        //console.log(event);

        $scope.eventSources[0].forEach(function (ev) {
            if (ev.id === event.id) {
                if (!ev.isBlocked) {
                    ev.isBlocked = true;
                    ev.color = '#ff0000';
                } else {
                    ev.isBlocked = false;
                    ev.color = '#3077EB';
                }
            }
        });
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);

    };

    /* config object */
    $scope.eventSources = [[]];
    $scope.calendarName = 'rnCalendar';
    $scope.calendarConfig = {
        calendar: {
            businessHours: {
                dow: [1, 2, 3, 4, 5], // Monday - Fri
                start: '8:00',
                end: '18:00'
            },
            defaultDate: moment(startDayDate),
            snapDuration: '00:15:00',
            //height: 600,
            editable: false,
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
            },
            eventClick: $scope.rnBlockClick
        }
    };


    var events = [];

    function initCalendar() {

        events = [];

        $scope.fullTimeRns.forEach(function (rn) {

            if (!currentMonth) return;

            if ((!$scope.availableRns.all &&
                $scope.availableRns.list.indexOf(rn.displayName) === -1) ||
                !rn.selected) {
                return;
            }

            //console.log('in', rn.id);

            var currentDayDate = angular.copy(startDayDate);

            while (currentDayDate.monthValue() === currentMonth) {

                var dayString = currentDayDate.toString();
                var isBlocked = false;

                if (currentDayDate.dayOfWeek().value() === 6 || currentDayDate.dayOfWeek().value() === 7) {
                    isBlocked = true;
                }

                var event = {
                    title: rn.displayName,
                    start: new Date(dayString),
                    isBlocked: isBlocked,
                    color: isBlocked ? '#ff0000' : '#3077EB',
                    allDay: true,
                    rnId: rn.id,
                    dayString: dayString,
                    dayOfMonth: currentDayDate.dayOfMonth(),
                    id: parseInt(currentDayDate.dayOfMonth() + '000' + rn.id)
                };


                events.push(event);

                currentDayDate = currentDayDate.plusDays(1);

            }

        });

        reRenderCalendar();
    }

    initCalendar();

    function reRenderCalendar() {

        if (uiCalendarConfig && uiCalendarConfig.calendars &&
            uiCalendarConfig.calendars[$scope.calendarName]) {
            //console.log('ev');
            //console.log(events);
            $scope.eventSources = [events];
            uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
            uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('removeEvents');
            uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('gotoDate', new Date(startDayDate.toString()));
            uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('addEventSource', $scope.eventSources[0]);
        }
    }



});