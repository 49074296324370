angular
  .module("dashboard")
  .factory(
    "TrainingCenterOverviewService",
    ($rootScope, DatabaseApi, wildcard) => ({
      /**
       * Get graphs data
       */
      getGeneralStatistics(filters) {
        const { agencyId, agencyMemberId } = $rootScope;

        const endpoint = appendUrlParams(
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/general_statistics",
            agencyId,
            agencyMemberId
          ),
          filters
        );

        return DatabaseApi.get(endpoint);
      },

      /**
       * Get table when no bundle is selected
       */
      getTableForAllBundles(filters) {
        const { agencyId, agencyMemberId } = $rootScope;

        const endpoint = appendUrlParams(
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/general_statistics_table",
            agencyId,
            agencyMemberId
          ),
          filters
        );

        return DatabaseApi.get(endpoint);
      },

      /**
       * Get caregiver detailed statistics
       */
      getCaregiverBundlesStats(filters, caregiverId) {
        const { agencyId, agencyMemberId } = $rootScope;

        const endpoint = appendUrlParams(
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/general_statistics_table/caregivers/:caregiverId",
            agencyId,
            agencyMemberId,
            caregiverId
          ),
          filters
        );

        return DatabaseApi.get(endpoint);
      },

      getStatisticsTableByBundleId(bundleId, filters) {
        const { agencyId, agencyMemberId } = $rootScope;

        const endpoint = appendUrlParams(
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/bundles/:bundleId/statistics_table",
            agencyId,
            agencyMemberId,
            bundleId
          ),
          filters
        );

        return DatabaseApi.get(endpoint);
      },

      getMaterialStatisticsTableByBundleId(bundleId) {
        const { agencyId, agencyMemberId } = $rootScope;

        const endpoint = 
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/bundles/:bundleId/material_statistics_table",
            agencyId,
            agencyMemberId,
            bundleId
          );

        return DatabaseApi.get(endpoint);
      },

      /**
       * Get a signed url to the exported file
       */
      getStatisticsTableExportFileUrl(filters, columns) {
        const { agencyId, agencyMemberId } = $rootScope;

        let endpoint = appendUrlParams(
          wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/training_center/general_statistics_table/export",
            agencyId,
            agencyMemberId
          ),
          filters,
          columns,
        );

        return DatabaseApi.get(endpoint).then(({ data }) => data.url);
      },
    })
  );

const appendUrlParams = (urlBase, filters, columns) => {
  let newUrl = "";
  switch (filters.filterDateType.id) {
    case "dueDate":
      const dueFrom = LocalDate.from(nativeJs(filters.dueDateRange.startDate));
      const dueTo = LocalDate.from(nativeJs(filters.dueDateRange.endDate));

      newUrl = `${urlBase}?from=${dueFrom}&to=${dueTo}&dateType=${filters.filterDateType.id}`;
      break;
    case "completionDate":
      const completionFrom = LocalDate.from(
        nativeJs(filters.completionDateRange.startDate)
      );
      const completionTo = LocalDate.from(
        nativeJs(filters.completionDateRange.endDate)
      );
      newUrl = `${urlBase}?from=${completionFrom}&to=${completionTo}&dateType=${filters.filterDateType.id}`;
      break;
  }

  if (newUrl === "") return "";

  if (
    filters.installationStatus.length &&
    filters.installationStatus.length < 2
  ) {
    newUrl += `&installation=${filters.installationStatus
      .map(({ id }) => id)
      .join(",")}`;
  }

  if (
    filters.complianceStatus.length &&
    filters.complianceStatus.length < 2
  ) {
    newUrl += `&compliance=${filters.complianceStatus
      .map(({ id }) => id)
      .join(",")}`;
  }

  if (filters.caregiverStatus.length) {
    newUrl += `&status=${filters.caregiverStatus
      .map(({ id }) => id)
      .join(",")}`;
  }

  if (filters.caregiverOffices.length) {
    newUrl += `&offices=${filters.caregiverOffices
      .map(({ id }) => id)
      .join(",")}`;
  }

  if (columns && columns.length) {
    newUrl += `&columns=${columns.join()}`;
  }

  return newUrl;
};
