app.component("caregiverPayroll", {
    templateUrl: "admin/views/caregiver-payroll.html",
    bindings: {
        caregiverId: '<',
        caregiverFullName: '<'
    },
    controller: function ($scope, $rootScope, DatabaseApi, NgTableParams, toaster, $q, $timeout) {
    
        $scope.visitPayrollTypesMap = {
            "PTO": "PTO",
            "ONLINE_TRAINING": "Online Training",
            "IN_CLASS": "In Class",
            "TRAVEL_TIME": "Travel Time",
            "SOH": "SOH",
            "VISIT": "Visit"
        }

        $scope.patientsMap = DatabaseApi.patients();
        
        const getPayrollBatches = async () => {
            const deferred = $q.defer();
            $scope.isLoading = true;

            DatabaseApi.get("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + 
                            "/caregivers/" + $scope.$ctrl.caregiverId + "/payroll")
            .then(function (res) {

                if(res === undefined) {
                    toaster.pop("warning", "You do not have permission to view this page, please contact your administrator");
                    throw new Error("No Permissions")
                }

                // const payroll = {
                //     payrollBatchs: [
                //         {
                //             payrollItems: [
                //                 {
                //                     patientId: 1,
                //                     startTime: "2021-12-01T12:00",
                //                     endTime: "2021-12-01T13:00",
                //                     type: "VISIT",
                //                     rate: 1400,
                //                     oldVisit: true,
                //                     overtime: false,
                //                     holiday: false,
                //                     visitInstanceId: 1,
                //                     total: 100,
                //                     seconds: 3600
                //                 },
                //                 {
                //                     patientId: 1,
                //                     startTime: "2021-12-01T12:00",
                //                     endTime: "2021-12-01T13:00",
                //                     type: "VISIT",
                //                     rate: 1700,
                //                     oldVisit: true,
                //                     overtime: true,
                //                     holiday: false,
                //                     visitInstanceId: 2,
                //                     total: 100,
                //                     seconds: 3600
                //                 }
                //             ],
                //             payrollBatch: {
                //                 displayId: "23456789",
                //                 date: "2021-12-01"
                //             }
                //         },
                //         {
                //             payrollItems: [
                //                 {
                //                     patientId: 1,
                //                     startTime: "2021-12-01T12:00",
                //                     endTime: "2021-12-01T13:00",
                //                     type: "VISIT",
                //                     rate: 1500,
                //                     oldVisit: true,
                //                     overtime: false,
                //                     holiday: true,
                //                     visitInstanceId: 3,
                //                     total: 100,
                //                     seconds: 3600
                //                 },
                //                 {
                //                     patientId: 1,
                //                     startTime: "2021-12-01T12:00",
                //                     endTime: "2021-12-01T13:00",
                //                     type: "VISIT",
                //                     rate: 1600,
                //                     oldVisit: true,
                //                     overtime: false,
                //                     holiday: false,
                //                     visitInstanceId: 4,
                //                     total: 100,
                //                     seconds: 3600
                //                 }
                //             ],
                //             payrollBatch: {
                //                 displayId: "9876543",
                //                 date: "2021-11-01"
                //             }
                //         }
                //     ]
                // }
                // res.data = payroll;
                deferred.resolve(res.data.payrollBatchs);
            }).catch(function (err) {
                toaster.pop("error", "Failed to load payroll data");
                deferred.reject(null);
            })
            .finally(function () {
                $timeout(() => $scope.isLoading = false);
            });

            return deferred.promise;
        }


        const initBatchesTable = (items) => {
            
            const visitTableOptions = {
                count: 25,
                sorting: { date: "desc" }
            };

            items.forEach(function (item) {
                item.activeUploadTab = null;

                
                
                item.visitsTable = new NgTableParams(visitTableOptions, {
                    counts: [],
                    dataset: item.payrollItems
                });

                item.payrollItems.forEach(function(visit){
                    const patient = $scope.patientsMap[visit.patientId];
                    visit.patient = patient;
                    visit.patientFullName = patient !== undefined ? patient.displayName : '';
                    visit.typeText = $scope.visitPayrollTypesMap[visit.type];
                })
            });


            const payrollBatchesTableOptions = {
                count: 10,
                sorting: { date: "desc" }
            };

            $scope.payrollBatchesTable = new NgTableParams(payrollBatchesTableOptions, {
                counts: [],
                dataset: items
            });

           

        }

        

        async function loadRecords(activeCaregiverDocumentTypeId, targetTab) {
            const payrollBatches = await getPayrollBatches();
            if (payrollBatches !== null) {
                $scope.payrollBatches = payrollBatches;
                initBatchesTable(payrollBatches);
            }
        }

        $scope.toggleUploadRow = (row) => {
            if (row.activeUploadTab === null) {
                row.activeUploadTab = 0;
            } else {
                row.activeUploadTab = null;
            }
        };

        $scope.preOpenPatientModal = function (patientId) {
            if (!patientId) return;
            $rootScope.openPatientModal(patientId);
        }

        $scope.handlePayrollItemClick = (item) => {
            switch(item.type) {
                case 'VISIT': {
                    $rootScope.openVisitInstanceModal(item.visitInstanceId)
                    break;
                }
                default: return;
            }
        }
    
        $rootScope.$on("got_patients_data", function () {
            if ($scope.gotPatientData) return;
            $scope.gotPatientData = true
            $scope.patientsMap = DatabaseApi.patients();
        });

        $scope.secondsToLabel = function (seconds) {
            if (!seconds && seconds !== 0) return '';
            const duration = moment.duration(Math.abs(seconds), 'seconds');
            const hours = Math.floor(duration.asHours());
            if (hours > 0) duration.subtract(hours, "hours");
            let minutes = Math.floor(duration.asMinutes());
            if (minutes < 10) minutes = '0' + minutes;
            const sign = Math.sign(seconds) === -1 ? '-' : '';
            return `${sign}${hours}:${minutes} hr`;
        }

        this.$onInit = async () => {
            await loadRecords();
        }
    }
});