app.controller('complianceItemEditModalCtrl', function ($scope, $rootScope, DatabaseApi, toaster, $sce, $uibModalInstance, generalUtils, $http) {
    $scope.document = {};

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    }
    
    loadComplianceItemRelevantImage();

    function loadComplianceItemRelevantImage() {
        const url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.caregiverId +
            '/compliance_instances/' + $scope.complianceItemRecord.id + '/preview';

        $scope.isLoading = true;
        generalUtils.getDocumentContentFromURL(url).then(res => {
            $scope.document = res;
            $scope.isLoading = false;
        }).catch(err => {
            toaster.pop('error', "Failed to present document");
            $scope.isLoading = false;
            $scope.closeModal();
        });
    }

    $scope.updateExpiryDate = () => {
        $scope.complianceItemRecord.daysToExpire = $scope.calculateDayToExpireAsText($scope.complianceItemRecord.expiryDate);
        $scope.complianceItemRecord.expirationStatus = !$scope.complianceItemRecord.daysToExpire || $scope.complianceItemRecord.daysToExpire.indexOf("ago") !== -1;
        $scope.updateLatestInstance();
    }

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };
});
