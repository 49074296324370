"use strict";

angular.module("dashboard").component("patientVisits", {
  templateUrl: "admin/views/patient-visits.html",
  bindings: {
    patientId: "<",
    visits: "<",
  },
  controller: patientVisitsCtrl,
});

function patientVisitsCtrl(
  $scope,
  $rootScope,
  NgTableParams,
  $state,
  $uibModal
) {
  this.$onInit = () => {
    initTable(this.visits);
  };

  this.$onChanges = (changedData) => {
    if (
      !changedData ||
      !changedData.visits ||
      !changedData.visits.currentValue ||
      !$scope.visitsTable
    )
      return;
    initTable(this.visits);
  };

  function initTable(data) {
    const options = {
      count: 25,
      sorting: { createdAt: "desc" },
    };

    $scope.visitsTable = new NgTableParams(options, {
      counts: [],
      dataset: data,
    });
  }

  $scope.goToVisitDetails = function (row) {
    $state.go("app.visitDetailes", { id: row.id, visit: row });
  };

  $scope.visitClick = function (i, row, details) {
    if (details === "view") {
      $state.go("app.single-visit", { id: row.id });
    } else if (row.status === "waiting") {
      $rootScope.openVisitModal({visit: row});
    } else if (row.status === "scheduled") {
      $rootScope.openVisitModal({visit: row});
    } else if (row.status === "pending") {
      $rootScope.openVisitModal({visit: row});
    } else {
      $state.go("app.single-visit", { id: row.id });
    }
  };
}
