app.controller("copyCertificationSettingsCtrl", function(
  $scope,
  $rootScope,
  $uibModal,
  CopyCertificationSettingsService,
  SweetAlert
) {
  this.$onInit = async function() {
    $scope.loading = false;

    $scope.targetCert = $scope.$resolve.targetCert;
    $scope.sourceCert = "";

    $scope.certifications = $scope.$resolve.certifications.filter(
      cert => cert !== $scope.targetCert
    );

    console.log("Copy certification settings modal for certificate:", $scope.targetCert);
  };

  $scope.select = cert => {
    $scope.sourceCert = cert;
  };

  $scope.copy = async () => {
    SweetAlert.swal(
      {
        title: "Are you sure?",
        text: "This will copy and add all settings from the certification you selected.",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3077EB",
        confirmButtonText: "Yes, copy",
        closeOnConfirm: true
      },
      async (hasConfirmed) => {
        if (hasConfirmed) {
          $scope.loading = true;

          await CopyCertificationSettingsService.copyCertificationSettings({
            agencyId: $rootScope.agencyId,
            agencyMemberId: $rootScope.agencyMemberId,
            targetCert: $scope.targetCert,
            sourceCert: $scope.sourceCert
          })
            .then(() =>
              $scope.$close({
                status: "SUCCESS",
                cert: $scope.sourceCert
              })
            )
            .catch(err => {
              console.log(err);

              $scope.$close({
                status: "FAIL",
                message: err
              });
            });
        }
      }
    );
  };

  $scope.cancel = () => {
    $scope.$close({
      status: "CANCELED"
    });
  };
});
