app.controller("agencyHolidayModalCtrl", function (
    $scope,
    $rootScope,
    $uibModalInstance,
    data,
    DatabaseApi,
    toaster
) {
    $scope.viewModeOnly = false;
    $scope.data = data;
    $scope.form = {};
    $scope.selectedPayCodes = [];
    $scope.selectedOffices = [];
    $scope.selectedCertifications = [];

    $scope.payrollCodes = DatabaseApi.activePayrollCodes()
        .map(pc => ({ id: pc.id, label: pc.displayId }));

    $scope.offices = DatabaseApi.offices().map(office => ({
        id: office.id,
        label: office.name
    }));

    $scope.agencyCertifications = DatabaseApi.agencyCertifications().filter(c => c.isActive === true)
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.multiSelectExtraOptions = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
        externalIdProp: ''
    };

    if (data && data.id) {
        const now = moment();
        const holidayDate = moment(data.date);

        // Allow edit only when a future holiday (view mode only on past events)
        $scope.viewModeOnly = holidayDate.diff(now, 'h') < 0;
        $scope.data = angular.copy(data);
        $scope.data.date = holidayDate.clone().toDate();

        $scope.selectedPayCodes = data.payrollCodes.map(pcId => $scope.payrollCodes.find(payrollCode => payrollCode.id === pcId));
        $scope.selectedOffices = data.officeIds.map(officeId => $scope.offices.find(office => office.id === officeId));
        $scope.selectedCertifications = data.certifications.map(cert => $scope.agencyCertifications.find(obj => obj.label === cert));
    } else {
        $scope.data = {};
        $scope.data.active = false;
        $scope.data.raiseType = 'PERCENTAGE';
    }

    $scope.dateOptions = {
        minDate: moment().subtract(1, 'week').startOf('isoWeek'), // use 'week' instead of 'isoWeek' to get Sunday.
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.handleRaiseTypeClick = (type) => {
        $scope.data.raiseType = type;
        if($scope.data.id) {
            $scope.data.rate = null;
        }
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid || $scope.formSending) {
            return;
        }

        let body = {
            name: $scope.data.name,
            date: LocalDate.from(nativeJs(moment($scope.data.date))),
            rate: $scope.data.rate,
            active: $scope.data.active,
            payrollCodes: $scope.selectedPayCodes.map(pc => pc.id),
            certifications: $scope.selectedCertifications.map(cert => cert.label),
            officeIds: $scope.selectedOffices.map(office => office.id),
            raiseType: $scope.data.raiseType
        }


        let url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/holiday";
        let method = DatabaseApi.post;

        if ($scope.data.id !== undefined) {
            // Add id to edit
            body.id = $scope.data.id;
            url += "/" + body.id;
            method = DatabaseApi.put;
        }
        method(
            url,
            body
        ).then(
            function (res) {
                toaster.pop("success", "Holiday saved");
                $scope.closeModal();
            },
            function (err) {
                toaster.pop("error", "Something went wrong", "could not add holiday");
            }
        );
    };

    $scope.closeModal = function () {
        $uibModalInstance.close("ok");
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };
});
