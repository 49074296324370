app.controller('coronaReportStatusModalCtrl', function ($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {
    $scope.updatedReport = angular.copy($scope.report);
    if ($scope.updatedReport.risk === true) {
        $scope.updatedReport.reportStatus = "Risk";
    } else if ($scope.updatedReport.risk === false) {
        $scope.updatedReport.reportStatus = "Done";
    } else {
        $scope.updatedReport.reportStatus = "Missing";
    }
    
    $scope.updateReportStatus = function () {
        var selectedStatus = $scope.updatedReport.reportStatus;
        var reportStatus = (selectedStatus === "Missing") ? null : angular.copy(selectedStatus);
        var url = 'agencies/' + $rootScope.agencyId +
                '/agency_members/' + $rootScope.agencyMemberId +
                '/caregiver/' + $scope.updatedReport.caregiverId +
                '/corona_reports';
        var data = {
            reportStatus: reportStatus,
            notes: $scope.updatedReport.reportNotes || null
        };
        if ($scope.updatedReport.reportId) {
            data.coronaReportId = $scope.updatedReport.reportId;
        } else {
            data.date = LocalDate.from(nativeJs(moment($scope.report.date)));
        }

        DatabaseApi.patch(url, data).then(function (res) {
            $scope.report.reportId = res.data.report.reportId;
            $scope.report.risk = res.data.report.risk;
            $scope.report.reportNotes = res.data.report.reportNotes;
            $scope.report.submittedAt = res.data.report.submittedAt;
            $scope.report.isManual = res.data.report.is_manual;

            toaster.pop('success', "Report status successfully updated");
            $scope.closeModal();
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not edit report status");
        });
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };
});
