angular.module('dateDirectives', [])

  /**
   * Should have a "ng-model" that is a stringified JSJoda.LocalDate.
   */
  .directive('editableLocalDateInput', function () {
    function link(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$render = function () {
        if (ngModelCtrl.$viewValue) {
          var localDateObj = JSJoda.LocalDate.parse(ngModelCtrl.$viewValue);

          scope.dateObj = new Date(localDateObj.year(), localDateObj.monthValue() - 1, localDateObj.dayOfMonth());
          scope.localDateFormatted = localDateObj.format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"));
        } else {
          scope.dateObj = null;
          scope.localDateFormatted = null;
        }
      }

      scope.calendarIsOpen = false;

      scope.internalOnaftersave = function () {
        if (scope.dateObj) {
          var localDateObj = JSJoda.LocalDate.of(scope.dateObj.getFullYear(), scope.dateObj.getMonth() + 1, scope.dateObj.getDate());
          scope.localDateFormatted = localDateObj.format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"));
          ngModelCtrl.$setViewValue(localDateObj.toString());
        } else {
          scope.localDateFormatted = null;
          ngModelCtrl.$setViewValue(null);
        }
        scope.onaftersave();
      };

      scope.toggleCalendar = function () {
        scope.calendarIsOpen = !scope.calendarIsOpen;
      };
    }

    return {
      require: 'ngModel',
      restrict: 'E',
      scope: {
        dateOptions: '<',
        alternateText: '<',
        onbeforesave: "&",
        onaftersave: "&"
      },
      templateUrl: 'admin/views/partials/widgets/editable-local-date-input.html',
      link: link
    }
  });

app.directive("dateFromToPickers", function ($rootScope) {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/date-from-to-pickers.html',
    scope: {
      from: '=',
      to: '=',
      applyMinMaxDates: '<',
      fromLabelText: '@',
      toLabelText: '@'
    },
    link: function (scope) {
      const agencyVisitStartOfWeekDay = $rootScope.visitSettings.calendarStartDayOfTheWeek;
      const startingDay = agencyVisitStartOfWeekDay ? agencyVisitStartOfWeekDay : 0; // DEFAULT TO SUNDAY

      scope.fromLabel = "From";
      scope.toLabel = "To";

      if(scope.fromLabelText) {
        scope.fromLabel = scope.fromLabelText;
      }

      if(scope.toLabelText) {
        scope.toLabel = scope.toLabelText;
      }

      scope.calendarObject = {
        from: false,
        to: false,
        fromOptions: {
          altInputFormats: [scope.format],
          startingDay: startingDay
        },
        toOptions: {
          altInputFormats: [scope.format],
          startingDay: startingDay
        },
      };
      if (scope.applyMinMaxDates) {
        scope.calendarObject.fromOptions.maxDate = scope.to;
        scope.calendarObject.toOptions.minDate = scope.from;
      }

      scope.updateFromOptions = function () {
        if (scope.to !== null && scope.from !== null) {
          if (typeof scope.from === "number") {// millis
            scope.from = new Date(scope.from);
          }
          if (scope.applyMinMaxDates) {
            scope.calendarObject.fromOptions.maxDate = scope.to;
            return;
          }
          const momentFrom = moment(scope.from);
          const momentTo = moment(scope.to);
          if (momentTo.isBefore(momentFrom)) {
            scope.from = scope.to;
          }
        }
      }

      scope.updateToOptions = function () {
        if (scope.from !== null && scope.to !== null) {
          if (typeof scope.to === "number") {// millis
            scope.to = new Date(scope.to);
          }
          if (scope.applyMinMaxDates) {
            scope.calendarObject.toOptions.minDate = scope.from;
            return;
          }
          const momentFrom = moment(scope.from);
          const momentTo = moment(scope.to);
          if (momentFrom.isAfter(momentTo)) {
            scope.to = scope.from;
          }
        }
      }

      scope.toggleCalendarObject = function (prop) {
        scope.calendarObject[prop] = !scope.calendarObject[prop];
      };
    }
  };
});

app.directive("dateRangePicker", function () {
  return {
    restrict: 'E',
    templateUrl: 'admin/views/date-range-picker.html',
    scope: {
      id: '@',
      initialDateRangeName: '@',
      onDateRangeSelected: '&',
      initialDates: '<',
      popoverPosition: '@',
      showResetDates: '<',
    },
    link: function (scope) {
      scope.elementId = ((scope.id && scope.id.trim()) || "dateRangePicker") + "_Input";

      scope.onSelected = function (from, to) {
        return scope.onDateRangeSelected({ startDate: from, endDate: to });
      };

      var initializeDatePicker = function () {
          var dateRanges = {
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 14 Days': [moment().subtract(13, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
              'This Year': [moment().startOf('year'), moment().endOf('year')]
          };

          let initialDateRange;
          if (scope.initialDateRangeName && dateRanges[scope.initialDateRangeName]) {
            initialDateRange = dateRanges[scope.initialDateRangeName];
          } else if (scope.initialDates && scope.initialDates.from && scope.initialDates.to) {
            initialDateRange = [
              moment(scope.initialDates.from),
              moment(scope.initialDates.to)
            ];
          } else if (scope.initialDates && scope.initialDates.from === "" && scope.initialDates.to === "") {
            initialDateRange = ["", ""];
          } else {
            initialDateRange = dateRanges['Last 30 Days'];
          }

          scope.datePickerCtrlData = {
              type: 'overview',
              datePicker: {
                  startDate: initialDateRange[0],
                  endDate: initialDateRange[1]
              },
              options: {
                  ranges: dateRanges,
                  opens: scope.popoverPosition ? scope.popoverPosition : 'right',
                  alwaysShowCalendars: true,
                  applyClass: 'btn-primary',
                  locale: {
                      applyLabel: "Apply",
                      fromLabel: "From",
                      format: "MM/DD/YYYY", //will give you 01/06/2017
                      // format: "DD/MM/YYYY", //will give you 06/01/2017
                      //format: "YYYY-MM-DD", //will give you 2017-01-06
                      // format: "D MMM YY", //will give you 6  Jan 17
                      //format: "D-MMMM-YY", //will give you 6-January-17
                      toLabel: "To",
                      cancelLabel: 'Cancel'
                  },
                  eventHandlers: {
                    "apply.daterangepicker": applyDateRangePicker
                  },
                  autoApply: true,
                  minDate: new Date('2018-01-01')
              }
          };

          function applyDateRangePicker (eventObject) {
            var datePicker = scope.datePickerCtrlData.datePicker;
            datePicker.startDate = eventObject.model.startDate;
            datePicker.endDate = eventObject.model.endDate;

            if (scope.onDateRangeSelected) {
              scope.onSelected(datePicker.startDate, datePicker.endDate);
            }
          };

          scope.onSelected(scope.datePickerCtrlData.datePicker.startDate
            , scope.datePickerCtrlData.datePicker.endDate);

          scope.resetDates = () => {
            scope.datePickerCtrlData.datePicker.startDate = '';
            scope.datePickerCtrlData.datePicker.endDate = '';
            $('#' + scope.elementId).val('');
            if (scope.onDateRangeSelected) {
              scope.onSelected(
                scope.datePickerCtrlData.datePicker.startDate,
                scope.datePickerCtrlData.datePicker.endDate
              );
            }
          };
      };
      initializeDatePicker();

      scope.openCalendar = function () {
          $('#' + scope.elementId).data('daterangepicker').show();
      };

    }
  };
});
