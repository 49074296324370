'use strict'
app.controller('billingAccountsReceivableCtrl', function ($scope, $rootScope, $filter, $timeout, DatabaseApi, toaster, NgTableParams,
    itemSearchPageManager, selectionLogic, generalUtils, $uibModal, FilterUtils, Storage, $state) {
    const universalBOM = "\uFEFF";
    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", function (event) {
        $scope.patientsMap = DatabaseApi.patients();
    });
    $rootScope.$on("got_agency_members", () => {
        $scope.loadAgencyMembers();
    });

    $scope.markBiggerThan = 500000;
    $scope.arType = 'Detailed';
    $scope.arTypeIndex = 0;
    $scope.availableSummaryDaysGroups = [];
    $scope.tableParams = null;
    $scope.globalFilter = { val: '' };
    $scope.offices = [];
    $scope.filterBy = {
        checkLine: "",
        contractOverdue: "",
        alertOverdue: "",
        notes: "",
        visitDateFrom: "",
        visitDateTo: "",
        invoiceDateFrom: "",
        invoiceDateTo: "",
        visitFollowUpDateFrom: "",
        visitFollowUpDateTo: "",
        assignedMembers: [],
        exportBatch: "",
        paid: "",
        reasons: [],
        collectionStatuses: [],
        claimStatuses: [],
        groupByVisitDate: "",
        ageing: "",
        ageingByDepositDate: "",
    }
    $scope.invoiceIdsSet = new Set();

    $scope.ageing = {
        isOpen: false,
        options: {
            altInputFormats: ["MM/dd/yyyy"],
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek || 0
        }
    };

    const initialize = () => {
        initPageManager();
        getOffices();
        $scope.pageManager.resetSearchParamsByUrl();

        if ($scope.agencyMemberNamesMap === undefined) {
            DatabaseApi.loadAgencyMembers();
        }
        $scope.loadAgencyMembers();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingAccountsReceivableCtrl;
        if (storageFilters !== undefined) {
            $scope.filterBy = storageFilters;
        }

        $scope.loadItems();
        $scope.globalFilter.val = '';
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.filterBy;
        FilterUtils.rootFilters.billingAccountsReceivableCtrl = angular.copy(filters);
    });

    function initTableColumns() {
        const columns = Storage.getObject("billingAccountsReceivableTableSettings");
        const defaultColumns = {
            "Patient": true,
            // "Caregiver": true,
            "Visit Date": true,
            "Schedule time": true,
            // "Recorded time": true,
            "Contract": true,
            "Office": true,
            "Invoice": true,
            "Invoice Date": true,
            "Units": true,
            "Amount": true,
            "Paid": true,
            "Balance": true,
            "Payment Status": true,
            "No Payment Reason": true,
            "Collection Status": true,
            "Claim Status": true,
            "Note": true,
            "Follow Up Date": true,
            "Follow Up For": true,
            "Note Created At": true,
            "Note Created By": true,
            "Check Numbers": true,
            "Claim Numbers": true,
            "Notes": true,
            "Action": true,
            "Location": true,
            "County":true
        };

        if (columns) {
            Object.keys(columns).forEach((t) => {
                if (defaultColumns[t] === undefined) {
                    delete columns[t];
                }
            });

            Object.keys(defaultColumns).forEach(t => {
                if (columns[t] === undefined) {
                    columns[t] = defaultColumns[t];
                }
            });

            $scope.tableColumns = columns;
        } else {
            $scope.tableColumns = defaultColumns;
        }

        Storage.setObject("billingAccountsReceivableTableSettings", $scope.tableColumns);
        $scope.$watch(
            "tableColumns",
            () => {
                if ($scope.tableColumns) {
                    Storage.setObject("billingAccountsReceivableTableSettings", $scope.tableColumns);
                    initTable($scope.invoicedVisits);
                }
            },
            true
        );
    }

    const getOffices = () => {
        $scope.offices = DatabaseApi.offices();
        if (!$scope.offices || $scope.offices.length === 0) {
            DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices`).then((res) => {
                $scope.offices = res.data.offices;
                DatabaseApi.storeOffices(res.data.offices);
            }, (err) => {
                toaster.pop('error', "Something went wrong", "could not get offices");
            });
        }
    }

    const convertDateTimeFromString = (dateTimeValue) => new Date(dateTimeValue);
    const convertObjsArrToIdsStringSplitWithComma = (objsArr) => objsArr.map(obj => obj.id).join(',');
    const convertIdsStringToObjsArr = (strCommasSeparated) => strCommasSeparated.split(',');

    const initPageManager = () => {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/ar_view");

        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

        $scope.pageManager.initSearchParam("has-check", $scope.filterBy.checkLine);
        $scope.pageManager.initSearchParam("contract-overdue", $scope.filterBy.contractOverdue);
        $scope.pageManager.initSearchParam("alert-overdue", $scope.filterBy.alertOverdue);
        $scope.pageManager.initSearchParam("has-notes", $scope.filterBy.notes);
        $scope.pageManager.initSearchParam("group-by-visit-date", $scope.filterBy.groupByVisitDate);
        $scope.pageManager.initSearchParam("ageing-by-deposit-date", $scope.filterBy.ageingByDepositDate);
        $scope.pageManager.initSearchParamDeprecated("visit-from", $scope.filterBy.visitDateFrom, "visit-from",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initSearchParamDeprecated("visit-to", $scope.filterBy.visitDateTo, "visit-to",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initSearchParamDeprecated("invoice-from", $scope.filterBy.invoiceDateFrom, "invoice-from",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initSearchParamDeprecated("invoice-to", $scope.filterBy.invoiceDateTo, "invoice-to",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initSearchParamDeprecated("follow-up-from", $scope.filterBy.visitFollowUpDateFrom, "follow-up-from",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initSearchParamDeprecated("follow-up-to", $scope.filterBy.visitFollowUpDateTo, "follow-up-to",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParamDeprecated("assigned-members", $scope.filterBy.assignedMembers, "assigned-members",
            convertObjsArrToIdsStringSplitWithComma, convertIdsStringToObjsArr);
        $scope.pageManager.initSearchParam("export-batch-display-id", $scope.filterBy.exportBatch, { placeholderValue: "", isEntity: true });
        $scope.pageManager.initSearchParam("paid", $scope.filterBy.paid);
        $scope.pageManager.initSearchParamDeprecated("reason", $scope.filterBy.reasons, "reason",
            convertObjsArrToIdsStringSplitWithComma, convertIdsStringToObjsArr);
        $scope.pageManager.initSearchParamDeprecated("collection-status", $scope.filterBy.collectionstatuses, "collection-status",
            convertObjsArrToIdsStringSplitWithComma, convertIdsStringToObjsArr);
        $scope.pageManager.initSearchParamDeprecated("claim-status", $scope.filterBy.claimStatuses, "claim-status",
            convertObjsArrToIdsStringSplitWithComma, convertIdsStringToObjsArr);
        $scope.pageManager.initSearchParamDeprecated("ageing", $scope.filterBy.ageing, "ageing",
            generalUtils.formatDateByFilter, convertDateTimeFromString);
    }

    const initSelection = function (items) {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        },
            // Hope this doesn't come back to bite me..
            "visitInstanceId");
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    };

    const mapItems = (items) => {
        if (!$scope.caregiversMap) {
            $scope.caregiversMap = DatabaseApi.caregivers() || {};
        }

        if (!$scope.patientsMap) {
            $scope.patientsMap = DatabaseApi.patients() || {};
        }

        items.forEach(function (item) {
            if (item.caregiverId) {
                item.caregiver = $scope.caregiversMap[item.caregiverId];
            }

            if (item.patientId) {
                item.patient = $scope.patientsMap[item.patientId];
            }
            $scope.selectionLogic.initItem(item);
        });
    }

    const initTable = (items) => {
        initSelection(items);

        const hasItems = items && items.length;
        if (hasItems) {
            mapItems(items);
        }
        $scope.arDetailedVisits = items;

        const options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    const updateSearchParams = () => {
        $scope.pageManager.updateSearchParamValue("has-check", $scope.filterBy.checkLine);
        $scope.filterBy.checkLine = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.checkLine;
        $scope.pageManager.updateSearchParamValue("contract-overdue", $scope.filterBy.contractOverdue);
        $scope.filterBy.contractOverdue = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.contractOverdue;
        $scope.pageManager.updateSearchParamValue("alert-overdue", $scope.filterBy.alertOverdue);
        $scope.filterBy.alertOverdue = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.alertOverdue;
        $scope.pageManager.updateSearchParamValue("has-notes", $scope.filterBy.notes);
        $scope.filterBy.notes = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.notes;
        $scope.pageManager.updateSearchParamValue("group-by-visit-date", $scope.filterBy.groupByVisitDate);
        $scope.filterBy.groupByVisitDate = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.groupByVisitDate;
        $scope.pageManager.updateSearchParamValue("ageing-by-deposit-date", $scope.filterBy.ageingByDepositDate);
        // Why is everyone checking exportBatch?
        $scope.filterBy.ageingByDepositDate = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.ageingByDepositDate;
        $scope.pageManager.updateSearchParamValue("visit-from", $scope.filterBy.visitDateFrom);
        $scope.filterBy.visitDateFrom = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.visitDateFrom;
        $scope.pageManager.updateSearchParamValue("visit-to", $scope.filterBy.visitDateTo);
        $scope.filterBy.visitDateTo = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.visitDateTo;
        $scope.pageManager.updateSearchParamValue("invoice-from", $scope.filterBy.invoiceDateFrom);
        $scope.filterBy.invoiceDateFrom = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.invoiceDateFrom;
        $scope.pageManager.updateSearchParamValue("invoice-to", $scope.filterBy.invoiceDateTo);
        $scope.filterBy.invoiceDateTo = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.invoiceDateTo;
        $scope.pageManager.updateSearchParamValue("follow-up-from", $scope.filterBy.visitFollowUpDateFrom);
        $scope.filterBy.visitFollowUpDateFrom = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.visitFollowUpDateFrom;
        $scope.pageManager.updateSearchParamValue("follow-up-to", $scope.filterBy.visitFollowUpDateTo);
        $scope.filterBy.visitFollowUpDateTo = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.visitFollowUpDateTo;
        $scope.pageManager.updateSearchParamValue("assigned-members", $scope.filterBy.assignedMembers);
        $scope.filterBy.assignedMembers = $scope.filterBy.exportBatch !== "" ? [] : $scope.filterBy.assignedMembers;
        $scope.pageManager.updateSearchParamValue("paid", $scope.filterBy.paid);
        $scope.filterBy.paid = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.paid;
        $scope.pageManager.updateSearchParamValue("reason", $scope.filterBy.reasons);
        $scope.filterBy.reasons = $scope.filterBy.exportBatch !== "" ? [] : $scope.filterBy.reasons;
        $scope.pageManager.updateSearchParamValue("collection-status", $scope.filterBy.collectionStatuses);
        $scope.filterBy.collectionStatuses = $scope.filterBy.exportBatch !== "" ? [] : $scope.filterBy.collectionStatuses;
        $scope.pageManager.updateSearchParamValue("claim-status", $scope.filterBy.claimStatuses);
        $scope.filterBy.claimStatuses = $scope.filterBy.exportBatch !== "" ? [] : $scope.filterBy.claimStatuses;
        $scope.pageManager.updateSearchParamValue("ageing", $scope.filterBy.ageing);
        $scope.filterBy.ageing = $scope.filterBy.exportBatch !== "" ? "" : $scope.filterBy.ageing;

        $scope.pageManager.updateSearchParamValue("export-batch-display-id", $scope.filterBy.exportBatch);
    }

    $scope.searchAccountsReceivableVisits = () => {
        updateSearchParams();
        $scope.pageManager.executeSearch().then((res) => {
            switch ($scope.arType) {
                case "Detailed":
                    $scope.invoicedVisits = res.data.invoicedVisits;
                    initTableColumns();
                    initTable($scope.invoicedVisits);
                    setInvoiceIdsSet();
                    break;
                case "Summary":
                    $scope.summaryTableData = mapSummaryData(res.data.contractSummaries);
                    break;
            }
        }, (err) => {
            toaster.pop("error", "Failed to load invoiced visits");
        });
    }

    function setInvoiceIdsSet() {
        $scope.invoiceIdsSet = new Set();

        $scope.invoicedVisits.forEach(iv => {
            $scope.invoiceIdsSet.add(iv.invoiceId);
        });
    }

    function mapSummaryData(items) {
        const resultItems = items.map((item) => {
            const contractType = $scope.contractTypesDataManager.getContractTypeById(
                item.contractTypeId
            );

            const groupedSummaries = _.groupBy(item.summaries, "daysPastGroup");

            // Get grouped summaries - singled objects arrays out
            Object.keys(groupedSummaries).map((daysPastGroupKey) => {
                if (angular.isArray(groupedSummaries[daysPastGroupKey])) {
                    groupedSummaries[daysPastGroupKey] =
                        groupedSummaries[daysPastGroupKey][0];
                }
            });

            return {
                ...item,
                contractTypeName:
                    (contractType && contractType.name) || item.contractId,
                ...groupedSummaries,
                total: getTotalItemSummaries(item.summaries),
            };
        });
        sortNumbersArray($scope.availableSummaryDaysGroups);
        updateGroupsTotals(resultItems);
        return resultItems;
    }

    const getTotalItemSummaries = (summaries) => {
        return summaries.reduce(
            (total, summary) => {
                if ($scope.availableSummaryDaysGroups.indexOf(summary.daysPastGroup) === -1) {
                    $scope.availableSummaryDaysGroups.push(summary.daysPastGroup);
                }
                total.visitCount += summary.visitCount;
                total.billedAmount += summary.billedAmount;
                total.paidAmount += summary.paidAmount;
                return total;
            },
            { visitCount: 0, billedAmount: 0, paidAmount: 0 }
        );
    };

    const updateGroupsTotals = (items) => {
        $scope.summaryGroupTotals = $scope.availableSummaryDaysGroups.map(
            (group) =>
                items.reduce(
                    (groupSum, item) => {
                        const groupFound = item.summaries.find(
                            (summary) => summary.daysPastGroup === group
                        );
                        if (groupFound !== undefined) {
                            groupSum.visitCount += groupFound.visitCount;
                            groupSum.billedAmount += groupFound.billedAmount;
                            groupSum.paidAmount += groupFound.paidAmount;
                        }
                        return groupSum;
                    },
                    { visitCount: 0, billedAmount: 0, paidAmount: 0 }
                )
        );

        //   Sum a total of all rows totals
        $scope.summaryGroupTotals.push(
            items.reduce(
                (sumTotal, item) => {
                    if (item.total !== undefined) {
                        sumTotal.visitCount += item.total.visitCount;
                        sumTotal.billedAmount += item.total.billedAmount;
                        sumTotal.paidAmount += item.total.paidAmount;
                    }
                    return sumTotal;
                },
                { visitCount: 0, billedAmount: 0, paidAmount: 0 }
            )
        );
    };

    const sortNumbersArray = (arr) => {
        return arr.sort((a, b) => {
            if (a > b) {
                return 1;
            } else if (a < b) {
                return -1;
            } else {
                return 0;
            }
        });
    };

    $scope.handleSummaryGroupClick = (row, groupFromDaysAgo, groupToDaysAgo) => {
        if (!row[groupToDaysAgo]) {
            console.error(`Invalid group: ${groupToDaysAgo}`);
            return;
        }

        const asOfDate = $scope.filterBy.ageing
            ? moment($scope.filterBy.ageing)
            : moment();

        const groupFromFilter = new Date(moment(asOfDate).subtract(groupFromDaysAgo - 1, "days")); // -1 since the query includes end date
        const groupToFilter = new Date(moment(asOfDate).subtract(groupToDaysAgo, "days"));

        const fromKey = $scope.filterBy.groupByVisitDate ? "visitDateFrom" : "invoiceDateFrom";
        const toKey = $scope.filterBy.groupByVisitDate ? "visitDateTo" : "invoiceDateTo";

        $scope.filterBy[fromKey] = groupFromFilter;
        $scope.filterBy[toKey] = groupToFilter;

        $scope.pageManager.searchParams.selectedContractTypes.value = [
            { id: row.contractTypeId },
        ];

        $scope.changeARType("Detailed");
        $scope.searchAccountsReceivableVisits();
    };

    $scope.$on('got_data', (event) => {
        $scope.loadItems();
    });

    $scope.$on("refresh_visits", function () {
        $scope.loadItems();
    })

    $scope.assignedMembersExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '400px',
        enableSearch: true
    };

    $scope.arSettingsExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '400px',
        enableSearch: true,
        displayProp: 'text'
    }

    $scope.loadAgencyMembers = () => {
        $scope.agencyMemberNamesMap = DatabaseApi.getAllAgencyMembersNames();
        $scope.assignedMembersOptions = DatabaseApi.getAgencyMembersArr().filter(member =>
            member.status === 'Active'
        );

        $scope.filteredAssignedMembersOptions = $scope.assignedMembersOptions.filter(agencyMember => {
            return agencyMember.officeIds.findIndex(agencyMemberOfficeId => $rootScope.user.agencyMember.officeIds.includes(agencyMemberOfficeId)) !== -1;
        }).sort(generalUtils.sortByDisplayName);
    }

    $scope.loadItems = (search) => {
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        getArClaimStatuses();
        getArReasons();
        getArCollectionStatuses();
        $scope.loadAgencyMembers();

        if (search) {
            $scope.searchAccountsReceivableVisits();
        }
    }

    $scope.applyGlobalSearch = (term) => {
        const filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const invoicedVisits = $filter('filter')($scope.arDetailedVisits, $scope.globalFilter.val);
            initSelection(invoicedVisits);
        }
    };

    // get arClaimStatuses
    const getArClaimStatuses = () => {
        DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_claim_status_refs`).then((res) => {
            $scope.arClaimStatuses = res.data.claimStatusRefs;
            DatabaseApi.storeArClaimStatuses($scope.arClaimStatuses);
            $scope.arClaimStatuses = $scope.arClaimStatuses.filter(obj => obj.active);
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get claim statuses");
        });
    }

    // get arReasons
    const getArReasons = () => {
        DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_reason_refs`).then((res) => {
            $scope.arReasons = res.data.arReasonRef;
            DatabaseApi.storeArReasons($scope.arReasons);
            $scope.arReasons = $scope.arReasons.filter(obj => obj.active);
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get reasons");
        });
    }

    // get arCollectionStatuses
    const getArCollectionStatuses = () => {
        DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_collection_status_refs`).then((res) => {
            $scope.arCollectionStatuses = res.data.collectionStatusRefs;
            DatabaseApi.storeArCollectionStatuses($scope.arCollectionStatuses);
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get collection statuses");
        });
    }

    $scope.toggleBtnGroupValue = (paramName, toggledButton) => {
        switch ($scope.filterBy[paramName]) {
            case "": {
                $scope.filterBy[paramName] = toggledButton;
                break;
            }
            case null: {
                $scope.filterBy[paramName] = !toggledButton;
                break;
            }
            default: {
                $scope.filterBy[paramName] = toggledButton === $scope.filterBy[paramName] ? "" : null;
                break;
            }
        }
    }

    $scope.clickTableRow = (row) => {
        $rootScope.openVisitInstanceModal(row.visitInstanceId);
    }

    $scope.openModalToAddNotes = (visitInstanceIds) => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/invoiced-visits-add-notes-modal.html',
            size: 'lg',
            controller: 'invoicedVisitsAddNotesModalCtrl',
            resolve: {
                visitInstanceIds: () => visitInstanceIds,
                arReasons: () => $scope.arReasons,
                arClaimStatuses: () => $scope.arClaimStatuses,
                arCollectionStatuses: () => $scope.arCollectionStatuses,
                agencyMembers: () => $scope.assignedMembersOptions
            }
        });

        modalInstance.result.then((res) => {
            if (res.success === 'success') {
                $scope.attachNotesLocally(res.data);
                // $scope.searchAccountsReceivableVisits();
            }
        });
    }

    $scope.attachNotesLocally = (note) => {
        const psuedoNote = {
            arReasonText: $scope.arReasons?.find(reason => reason.id === note.arReasonId)?.text || null,
            claimStatusText: $scope.arClaimStatuses?.find(status => status.id === note.claimStatusRefId)?.text || null,
            collectionStatusText: $scope.arCollectionStatuses?.find(status => status.id === note.collectionStatusRefId)?.text || null,
            createdAt: JSJoda.Instant.now().toString(),
            createdBy: $rootScope.agencyMemberId,
            followUpDate: note.followUpDate,
            followUpFor: note.followupFor,
            noteText: note.text,
            psuedoNote: true
        };
        const visitSet = new Set(note.visitInstanceIds);

        // Don't change this to selected. Notes can be attached to unselected.
        const allVisits = $scope.selectionLogic.data.items;

        for (const visit of allVisits) {
            if (visitSet.has(visit.visitInstanceId)) {
                visit.latestNote = psuedoNote;

                if (visit.notes === null) {
                    visit.notes = [psuedoNote];
                } else {
                    visit.notes.push(psuedoNote);
                }
            }
        }
    }

    $scope.openModalToAddNotesToSelection = () => {
        const visitInstanceIds = [];
        $scope.selectionLogic.getSelectedItems()
            .forEach((invoicedVisit) => {
                visitInstanceIds.push(invoicedVisit.visitInstanceId);
            });

        $scope.openModalToAddNotes(visitInstanceIds);
    }

    $scope.openModalToAddNoteByVisitId = (visitInstanceId) => {
        $scope.openModalToAddNotes([visitInstanceId]);
    }

    $scope.openModalViewNotesByVisitId = (visitInstanceId, notes) => {
        $uibModal.open({
            templateUrl: 'admin/views/invoiced-visit-notes-modal.html',
            size: 'lg',
            controller: 'invoicedVisitNotesModalCtrl',
            resolve: {
                visitInstanceId: () => visitInstanceId,
                agencyMembers: () => $scope.assignedMembersOptions,
                notes: () => notes
            }
        });
    };

    $scope.changeARType = (type) => {
        $scope.arType = type;
        switch (type) {
            case "Detailed":
                $scope.arTypeIndex = 0;
                $scope.pageManager.updateRelativeURL("/ar_view");
                break;
            case "Summary":
                $scope.arTypeIndex = 1;
                $scope.pageManager.updateRelativeURL("/ar_view_summary");
                break;
        }
    };

    $scope.exportTable = (arType) => {
        if (arType !== "Summary") {
            $scope.exportTableDetailed();
        } else {
            $scope.exportTableSummary();
        }
    }

    $scope.exportTableSummary = () => {
        $scope.loadingCSV = true;

        const rows = [];

        // This is a duplicate of the html page logic.

        const titles = ["Contract"];
        for (let i = 0; i < $scope.availableSummaryDaysGroups.length; i++) {
            titles.push(`${i * 30}-${((i + 1) * 30)} days`);
        }
        titles.push("Total");
        rows.push(titles);

        for (const dataRow of $scope.summaryTableData) {
            const row = [dataRow.contractTypeName];
            for (const group of $scope.availableSummaryDaysGroups) {
                row.push("\"" + $filter("centsToDollars")(dataRow[group] ? dataRow[group].billedAmount - dataRow[group].paidAmount : "") + "\"");
            }
            row.push("\"" + $filter("centsToDollars")(dataRow ? dataRow.total.billedAmount - dataRow.total.paidAmount : "") + "\"");

            rows.push(row);
        }

        const totalRow = [""];
        for (const groupTotal of $scope.summaryGroupTotals) {
            totalRow.push("\"" + $filter("centsToDollars")(groupTotal ? groupTotal.billedAmount - groupTotal.paidAmount : "") + "\"");
        }
        rows.push(totalRow);

        var csvContent = "";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    }

    $scope.exportTableDetailed = () => {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = [
            "Patient",
            // "Caregiver",
            "Visit Date",
            "Schedule Time",
            // "Recorded Time",
            "Contract",
            "Office",
            "Invoice",
            "Invoice Date",
            "Units",
            "Amount",
            "Paid",
            "Balance",
            "Payment Status",
            "No Payment Reason",
            "Collection Status",
            "Claim Status",
            "Check Numbers",
            "Claim Numbers",
            "Note",
            "Follow Up Date",
            "Follow Up For",
            "Note Created At",
            "Note Created By",
            "Check Numbers",
            "Claim Numbers",
            "Notes"
        ].filter(title => $scope.tableColumns[title]);
        rows.push(titles);

        // Filter table data by global filter
        const allAr = $filter('filter')($scope.invoicedVisits, $scope.globalFilter.val);

        allAr.forEach(dataRow => {
            const csvRow = [];
            titles.forEach(title => {
                if (!$scope.tableColumns[title]) {
                    return;
                }
                let toPush = "";
                switch (title) {
                    case "Patient":
                        toPush = dataRow.patientName || '';
                        break;
                    // case "Caregiver":
                    //     toPush = dataRow.caregiver.displayName || '';
                    //     break;
                    case "Visit Date":
                        toPush = $filter("mfShortDate")(dataRow.visitDate) || '';
                        break;
                    case "Schedule Time":
                        toPush = $filter("hourMinutesRange")(dataRow, 'startTime', 'endTime');
                        break;
                    // case "Recorded Time":
                    //     toPush = $filter("hourMinutesRange")(dataRow, 'clockinTime', 'clockoutTime');
                    //     break;
                    case "Contract":
                        toPush = dataRow.contractTypeName || '';
                        break;
                    case "Office":
                        toPush = dataRow.officeName ? dataRow.officeName : "";
                        break;
                    case "Invoice":
                        toPush = dataRow.invoiceDisplayId.replace("#", "") || '';
                        break;
                    case "Invoice Date":
                        toPush = $filter("mfShortDate")(dataRow.invoiceCreatedAt) || '';
                        break;
                    case "Units":
                        toPush = (dataRow.billingUnitsThousandths / 1000) || '';
                        break;
                    case "Amount":
                        toPush = $filter("centsToDollars")(dataRow.billedAmount) || 0;
                        break;
                    case "Paid":
                        toPush = $filter("centsToDollars")(dataRow.payment) || 0;
                        break;
                    case "Balance":
                        toPush = $filter("centsToDollars")(dataRow.balance) || 0;
                        break;
                    case "Payment Status":
                        toPush = dataRow.paymentStatus || '';
                        break;
                    case "No Payment Reason":
                        toPush = dataRow.latestNote ? dataRow.latestNote.arReasonText : "";
                        break;
                    case "Collection Status":
                        toPush = dataRow.latestNote ? dataRow.latestNote.collectionStatusText : "";
                        break;
                    case "Claim Status":
                        toPush = dataRow.latestNote ? dataRow.latestNote.claimStatusText : "";
                        break;
                    case "Check Numbers":
                        toPush = dataRow.checkNumbers ? dataRow.checkNumbers.map(c => c.checkNumber).join("\, ") : "";
                        break;
                    case "Claim Numbers":
                        toPush = dataRow.claimNumbers ? dataRow.claimNumbers.join() : "";
                        break;
                    case "Note":
                        toPush = dataRow.latestNote ? dataRow.latestNote.noteText : "";
                        break;
                    case "Follow Up Date":
                        toPush = dataRow.latestNote ? $filter("mfShortDate")(dataRow.latestNote.followUpDate) : "";
                        break;
                    case "Follow Up For":
                        toPush = dataRow.latestNote ? $scope.agencyMemberNamesMap[dataRow.latestNote.followUpFor] : "";
                        break;
                    case "Note Created At":
                        toPush = dataRow.latestNote ? $filter("mfShortTime")(dataRow.latestNote.createdAt, ["withDate"]) : "";
                        break;
                    case "Note Created By":
                        toPush = dataRow.latestNote ? $scope.agencyMemberNamesMap[dataRow.latestNote.createdBy] : "";
                        break;
                    case "Check Numbers":
                        toPush = dataRow.checkNumbers ? dataRow.checkNumbers.map(c => c.checkNumber).join(", ") : "-";
                        break;
                    case "Claim Numbers":
                        toPush = dataRow.claimNumbers ? dataRow.claimNumbers.join(", ") : "-";
                        break;
                    case "Notes":
                        if (!dataRow.notes || !Array.isArray(dataRow.notes) || dataRow.notes.length === 0) {
                            toPush = "-";
                        } else {
                            const amountText = `${dataRow.notes.length} note${dataRow.notes.length > 1 ? "s" : ""}`;
                            const notesContents = dataRow.notes.map(note => note.noteText).join(" | ");
                            toPush = `${amountText}: ${notesContents}`;
                        }
                        break;
                    default:
                        break;
                }

                csvRow.push("\"" + toPush + "\"");
            });

            rows.push(csvRow);
        });

        var csvContent = "";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    }

    var getExportedFileName = function () {
        return `medflyt-export-ar-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
    }

    // TABLE COLUMNS LOCAL STORAGE PREPARE

    // Patient
    // Caregiver
    // Visit Date
    // Schedule time
    // Recorded time
    // Contract
    // Office
    // Invoice
    // Invoice Date
    // Units
    // Amount
    // Paid
    // Balance
    // Payment Status
    // No Payment Reason
    // Collection Status
    // Claim Status
    // Follow Up Date
    // Notes
    // Action

    $scope.getSelectedSums = function () {
        let billedAmount = 0;
        let paidAmount = 0;

        const selection = $scope.selectionLogic.getSelectedItems();

        for (const selected of selection) {
            billedAmount += selected.billedAmount;
            paidAmount += selected.payment;
        }

        return {
            billedAmount,
            paidAmount,
            balance: billedAmount - paidAmount
        }
    }

    $scope.goToInvoicePage = (invoiceId) => {
        $rootScope.openInvoiceModal({ invoiceId });
    };

    $scope.goToCheckPage = (id) => {
        $state.go('app.billing.check', { id: id, check: null });
    };

    initialize();
});