app.controller('visitInstancesDeleteModalCtrl', function (
    $rootScope,
    $scope,
    DatabaseApi,
    $uibModalInstance,
    toaster,
    wildcard,
    $filter,
    VisitInstanceModalService,
    noteConsts,
    entityNoteService,
) {
    $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_MANUAL_REMOVE);
    $scope.modalState = {
        selectedOption: { id: undefined },
        manualRemoveNote: {},
    }

    $rootScope.$emit('open_delete_visit_modal');

    $scope.$on('modal.closing', function (event, data) {
        $rootScope.$emit('close_delete_visit_modal');
    });

    const visitsPreview = $scope.selectedVisits.map((visit, index) => {
        const startDateAndTime = $filter("mfShortTime")(visit.startTime, ['withDate']);
        const endTime = $filter("mfShortTime")(visit.endTime);
        return `${index + 1}. ${startDateAndTime} - ${endTime}`;
    }).join("\n");
    $scope.message = `Are you sure you want to delete selected visits?\n\n${visitsPreview}\n\n *All of your deleted visits will be saved on your calendar.`;

    const getDeleteVisitsBody = (deleteType, visits) => {
        const body = {
            removeMode: deleteType === "CURRENT_SHIFTS" ? "SingleVisits" : "WeeklyPattern",
            manualRemoveNote: entityNoteService.buildEntityNoteRequest(
                $scope.modalState.manualRemoveNote,
                noteConsts.NoteTypes.VISIT_MANUAL_REMOVE,
            ),
            visitInstanceIds: visits.map(visit => visit.visitInstanceId),
            shouldUpdateWeeklyTemplate: [
                "ENTIRE_SERIES",
                "GOING_FORWARD",
                "CHOSEN_DAYS"
            ].includes(deleteType)
        };

        const handledDeleteTypes = [
            "CURRENT_SHIFTS",
            "GOING_FORWARD",
            "UNTIL_DATE",
            "ENTIRE_SERIES",
            "CHOSEN_DAYS",
        ];

        if (!handledDeleteTypes.includes(deleteType)) {
            toaster.pop("warning", "Something went wrong", "Unhandled Delete Mode");
            return;
        }

        return body;
    }

    $scope.onClickDelete = () => {
        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
            $scope.modalState.manualRemoveNote,
            noteConsts.NoteTypes.VISIT_MANUAL_REMOVE
        );
        if (!isNoteValid) {
            $scope.noteValidations = { isPredefinedValid, isMessageValid };
            return;
        }

        $scope.isLoading = true;

        const body = getDeleteVisitsBody($scope.editShiftsParams.type, $scope.selectedVisits);

        const deleteVisitsUrl = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.patientId
        );

        DatabaseApi.patch(deleteVisitsUrl, body).then((res) => {
            const skippedVisitsData = res.data.skippedVisitsAndMissingPermissions;
            if (!skippedVisitsData) {
                toaster.pop("success", "Selected visits succesfully deleted");
            } else {
                VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: skippedVisitsData,
                    updatedVisitsAmount: res.data.deletedVisitInstanceIds.length,
                    action: "delete"
                });
            }
            $uibModalInstance.close({
                success: true
            });
        }, () => {
            toaster.pop("error", "Something went wrong", "Can't delete selected visits");
        }).finally(() => {
            $scope.isLoading = false;
        });
    }

    $scope.handleNoteChanges = (updatedNote) => {
        $scope.modalState.manualRemoveNote = updatedNote;
    };

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };
});