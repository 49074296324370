
// MOCK DATA for Calls Dashboard pages

app.service('mockCallsData', function (generalUtils) {

    var generateFakePhone = function (id) {
        return "917-530-900" + id.toString();
    };

    var createMockCallWithIssue = function (id, caregiverName, status) {
        var fakeCall = {
            id: id,
            patientName: "Patient " + id,
            caregiverId: id + 500,
            caregiverName: caregiverName,
            caregiverPhone: generateFakePhone(id),
            callTime: new Date(2019, 10, 27, id % 24),
            status: status,
            canBeApproved: !((id + 1) % 3),
            displayId: id.toString()
        };

        return fakeCall;
    };

    var createMockMissedCall = function (id, caregiverName, isMissedIn, isMissedOut) {
        var fakeCall = {
            id: id,
            patientName: "Patient " + id,
            caregiver: {
                caregiverId: id + 500,
                displayName: caregiverName,
            },
            coordinatorName: "Coordinator " + id,
            startTime: new Date(moment().startOf('hour')),
            endTime: new Date(moment().startOf('hour').add(2, 'hours')),
            isCheckedIn: !isMissedIn,
            isCheckedOut: !isMissedOut,
            canBeApproved: true,
            displayId: id.toString()
        };

        return fakeCall;
    };



    var mockData = {

        maintenance: [
            createMockCallWithIssue(1, "First", "Unfamiliar caregiver"),
            createMockCallWithIssue(2, "Second", "Unknown patient"),
            createMockCallWithIssue(3, "Third", "Duplicated call"),
            createMockCallWithIssue(4, "Fourth", "No visit in this date"),
            createMockCallWithIssue(5, "Fifth", "Unfamiliar caregiver"),
            createMockCallWithIssue(6, "Sixth", "Duplicated call"),
            createMockCallWithIssue(7, "Seventh", "Duplicated call"),
        ],

        missedIn: [
            createMockMissedCall(1, "First", true, false),
            createMockMissedCall(2, "Second", true, false),
            // createMockMissedCall(3, "Third", true, false),
            createMockMissedCall(4, "Fourth", true, false),
            // createMockMissedCall(5, "Fifth", true, false),
            // createMockMissedCall(6, "Sixth", true, false),
            // createMockMissedCall(7, "Seventh", true, false),
            // createMockMissedCall(8, "Eighth", true, false),
        ],

        missedOut: [
            // createMockMissedCall(1, "First", false, true),
            // createMockMissedCall(2, "Second", false, true),
            createMockMissedCall(3, "Third", false, true),
            // createMockMissedCall(4, "Fourth", false, true),
            // createMockMissedCall(5, "Fifth", false, true),
            // createMockMissedCall(6, "Sixth", false, true),
            createMockMissedCall(7, "Seventh", false, true),
            // createMockMissedCall(8, "Eighth", false, true),
        ],

        missedBoth: [
            // createMockMissedCall(1, "First", true, true),
            // createMockMissedCall(2, "Second", true, true),
            // createMockMissedCall(3, "Third", true, true),
            // createMockMissedCall(4, "Fourth", true, true),
            createMockMissedCall(5, "Fifth", true, true),
            createMockMissedCall(6, "Sixth", true, true),
            // createMockMissedCall(7, "Seventh", true, true),
            createMockMissedCall(8, "Eighth", true, true),
        ]

    };

    return mockData;

});
