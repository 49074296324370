"use strict";
app.controller('standardReportPermissionsModalCtrl', function($scope, reportService, $uibModalInstance, agencyPermissionsRolesService, DatabaseApi) {
  
  $scope.loadRolesAccess = () => {
    return reportService.getStandardReportRolesAccess($scope.standardReport.id);
  }
  $scope.loadAgencyMembersAccess = () => {
    return reportService.getStandardReportAgencyMembersAccess($scope.standardReport.id);
  }

  $scope.handleAddRoleAccess = (rolesArr) => {
    
    let rolesIds = rolesArr.map((role)=>role.id);
    return reportService.addStandardReportRoleAccess($scope.standardReport.id, {
      rolesIds
    }).then(()=>{
      initialize();
    });
  }
  $scope.handleAddAgencyMemberAccess = (agencyMemberIdsArr) => {
    let agencyMemberIds = agencyMemberIdsArr.map((role)=>role.id);
    return reportService.addStandardReportAgencyMembersAccess($scope.standardReport.id, {
      agencyMemberIds
    }).then(()=>{
      initialize();
      
    });
  }
  $scope.handleDeleteRoleAccess = (roleId) => {


    return reportService.deleteStandardReportRoleAccess($scope.standardReport.id, roleId
    ).then(()=>{
      initialize();
    });
  }
  $scope.handleDeleteAgencyMemberAccess = (agencyMemberId) => {

console.log("agency member id",agencyMemberId);
    return reportService.deleteStandardReportAgencyMemberAccess($scope.standardReport.id, agencyMemberId
    ).then(()=>{
      initialize();
    });
  }
  

  function rolesMap() {
    return $scope.allRoles.reduce((acc, cur) => ({
      ...acc,
      [cur.id]: cur.name
    }), {});
  }


  function membersMap() {
    return $scope.agencyMembers.reduce((acc, cur) => ({
      ...acc,
      [cur.id]: cur.displayName
    }), {});
  }

  function initSuggestedRoles() {
    const existRolesAccessIds = $scope.rolesAccess.map(role => role.roleId);
    $scope.suggestedRoles = $scope.allRoles.filter(role => !existRolesAccessIds.includes(role.id));
  }

  function initSuggestedAgencyMemebers() {
    const existMemberAccessIds = $scope.agencyMembersAccess.map(role => role.id);
    $scope.suggestedAgencyMembers = $scope.agencyMembers.filter(member=>!existMemberAccessIds.includes(member));
  }

  function loadAllAgencyMembers() {
    $scope.agencyMembers = DatabaseApi.getAgencyMembersArr();
      $scope.agencyMemersMap = membersMap();
      initSuggestedAgencyMemebers()
  }


  $scope.handleRemoveAccess = (roleId) => {
    return reportService.deleteSavedReportRoleAccess($scope.standardReport.id, roleId);
  }

  function initialize() {
    $scope.loadRolesAccess().then(res => {
      $scope.rolesAccess = res;
      loadAllRoles().then(() => {
        initSuggestedRoles();
      });
    });
    $scope.loadAgencyMembersAccess().then(res=>{
    $scope.agencyMembersAccess = res;  
    loadAllAgencyMembers()
    })
    
  }

  function loadAllRoles() {
    return agencyPermissionsRolesService.getAgencyRoles().then(res => {
      $scope.allRoles = res;
      $scope.allRolesMap = rolesMap();
    });
  }


  $scope.closeModal = () => {
    $uibModalInstance.dismiss();
  };
  initialize();

});
