const { Duration, LocalTime, LocalDate, LocalDateTime, nativeJs } = JSJoda;

app.controller("masterWeekCtrl", function (
  $scope,
  $rootScope,
  $uibModal,
  MasterWeekService,
  SweetAlert
) {
  this.$onChanges = async function (changedData) {
    $scope.patient = changedData.patient.currentValue;
    console.log("Patient:", $scope.patient);
    if ($scope.patient.id) {
      $scope.masterWeeks = [];
      $scope.masterWeeks = await MasterWeekService.getMasterWeeks({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        patientId: $scope.patient.id
      });

      console.log("Loaded weeks:", $scope.masterWeeks);
    }
  };

  $scope.addWeek = async () => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/master-week-modal.html",
      windowClass: "master-week-modal",
      controller: "masterWeekModalCtrl",
      resolve: {
        patient: function () {
          return $scope.patient;
        }
      },
      backdrop: "static",
      keyboard: false
    });
    modalInstance.result.then(
      async function (result) {
        if (result === "CANCELED") {
          console.log("Canceled add week");
          return;
        }
        if (result) {
          console.log("Added master week:", result);
          $scope.masterWeeks = await MasterWeekService.getMasterWeeks({
            agencyId: $rootScope.agencyId,
            agencyMemberId: $rootScope.agencyMemberId,
            patientId: $scope.patient.id
          });
          $rootScope.$broadcast('refresh_visits');
        }
      },
      function () {
        console.log("modal close");
      }
    );
  };

  $scope.editWeek = (weekToEdit, index) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/master-week-modal.html",
      windowClass: "master-week-modal",
      controller: "masterWeekModalCtrl",
      resolve: {
        patient: function () {
          return $scope.patient;
        },
        masterWeek: function () {
          return weekToEdit;
        }
      },
      backdrop: "static",
      keyboard: false
    });
    modalInstance.result.then(
      function (result) {
        if (result === "CANCELED") {
          console.log("Canceled edit week");
          return;
        }
        if (result === "DELETED") {
          console.log("Deleted master week:", weekToEdit);
          $scope.masterWeeks.splice(index, 1);
          $rootScope.$broadcast('refresh_visits');
        }
        if (result) {
          console.log("Edited master week:", result);
          $scope.masterWeeks.splice(index, 1, result);
          $rootScope.$broadcast('refresh_visits');
        }
      },
      function () {
        console.log("modal close");
      }
    );
  };

  $scope.deleteWeek = async (masterWeek, index) => {
    SweetAlert.swal(
      {
        title: "Remove master week",
        text: "Are you sure you want to remove this master week?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3077EB",
        confirmButtonText: "Remove",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      async function (confirmed) {
        if (confirmed) {
          $scope.savingWeek = true;
          await MasterWeekService.deleteMasterWeek(masterWeek.week.masterWeekId, {
            agencyId: $rootScope.agencyId,
            agencyMemberId: $rootScope.agencyMemberId,
            patientId: $scope.patient.id
          });

          $scope.savingWeek = false;
          $scope.masterWeeks.splice(index, 1);
          $scope.$apply();
          $rootScope.$broadcast('refresh_visits');
        }
      }
    );
  };

  $scope.weekHeaderString = masterWeek => {
    let result = "From » To";
    if (
      masterWeek.week &&
      masterWeek.week.periodDisplay &&
      masterWeek.week.periodDisplay.startDate &&
      masterWeek.week.periodDisplay.endDate &&
      masterWeek.days &&
      masterWeek.week.contract
    ) {
      result = `${masterWeek.week.contract.name}: ${new Date(
        masterWeek.week.periodDisplay.startDate
      ).toDateString()} » ${new Date(
        masterWeek.week.periodDisplay.endDate
      ).toDateString()}`;
    }
    return result;
  };
});
