app.controller('arCollectionStatusSetupModalCtrl', function($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService){

    $scope.fields = FormService.getFields(fields, data);

    $scope.form = {};

    $scope.fieldChanged = (item) => {
        if (item.type.type === "Boolean" && data.id) {
            $scope.submitForm();
        }
    }

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid) return;

        const body = FormService.prepareFormData($scope.fields);
        
        if (data.id) {
            delete body.text;
            DatabaseApi.put(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_collection_status_ref/${data.id}` , body)
                .then((res) => {
                    toaster.pop('success', "Collection status saved");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop('error', "Something went wrong", "Could not change collection status settings")
                });
        } else {
            DatabaseApi.post(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_collection_status_ref`, body).then((res) => {
                toaster.pop('success', "Collection status saved");
                $scope.closeModal();
            }, (err) => {
                toaster.pop('error', "Something went wrong", "could not create collection status");
            });
        }
    };

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

});
