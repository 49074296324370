"use strict";
app.controller("ptoApprovalNotesModalCtrl", function (
  $scope,
  $rootScope,
  DatabaseApi,
  ptoNotesModalService,
  toaster,
  $timeout,
  wildcard,
  entityNoteService,
  noteConsts
) {
  $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.CAREGIVER_PTO);

  const initialize = () => {
    $scope.modalData = ptoNotesModalService.notesData;
    // Impossible, but handled
    if ($scope.modalData === undefined) {
      console.error("Notes modal data not available");
      $timeout(() => {
        toaster.pop("error", "Something went wrong", "Please try again");
        ptoNotesModalService.closeModal();
      });
      return;
    }
  
    $scope.note = { text: "" };
    $scope.agencyMembers = DatabaseApi.getAgencyMembersWithPhoto();
    $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
    $scope.editorOptions = {};
    fetchNotes();
  };

  $scope.onReady = () => {
    if (CKEDITOR.instances["note-editor"]) {
      CKEDITOR.instances["note-editor"].destroy();
    }

    const noteEditor = CKEDITOR.replace("note-editor", {
      extraPlugins: "emoji,basicstyles,undo,link,toolbar",
      removePlugins: "elementspath, showborders",
      resize_enabled: false,
      language: "en",
      contentsCss: [
        "/admin/styles/ckeditor.css",
        "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700",
      ],
      font_names: "Open Sans",
      allowedContent: true,
      toolbar: [
        {
          name: "document",
          items: ["Undo", "Redo"],
        },
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Strike"],
        },
        {
          name: "links",
          items: ["EmojiPanel", "Link", "Unlink"],
        },
      ],
      height: 150
    });

    noteEditor.on("change", function () {
      $scope.note.text = this.getData();
    });
    noteEditor.on("contentDom", function () {
      noteEditor.document.on("keyup", function (event) {
        if (event.data.$.ctrlKey && event.data.$.code === "Enter") {
          try {
            $scope.createNote();
          } catch {
            //Catching so the createNote() will run next time.
            console.err("An error occured while submmiting note using enter");
          }
        }
      });
    });

    // When finished - add custom class
    $timeout(() => {
      CKEDITOR.instances["note-editor"].element.$.classList.add("active-note-editor")
    });
  };

  const fetchNotes = () => {
    $scope.isLodaing = true;
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregiverPtoInstances/:caregiverPtoInstanceId/notes",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.modalData.profileId
    );
    DatabaseApi.get(url)
      .then(({ data }) => {
        // Mapping agency memebers names
        $scope.notes = data.notes.map((note) => {
          note.noteRichText = note.predefinedAnswerText && note.noteRichText ?
          entityNoteService.setRichNoteText(note.noteRichText) : note.noteRichText;
          return {
            ...note,
            createdBy:
              $scope.agencyMembers[note.createdBy] !== undefined
                ? $scope.agencyMembers[note.createdBy].name
                : ($scope.agencyMembersNames[note.createdBy] !== undefined
                  ? ($scope.agencyMembersNames[note.createdBy] + " (Inactive)")
                  : "Deleted Coordinator"),
            createdByPhotoURL:
              $scope.agencyMembers[note.createdBy] !== undefined
                ? $scope.agencyMembers[note.createdBy].photoUrl
                : undefined,
          }
        });
      })
      .catch(() => toaster.pop("error", "Failed to fetch notes"))
      .finally(() => ($scope.isLodaing = false));
  };

  $scope.createNote = () => {
    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote($scope.newPTONote, noteConsts.NoteTypes.CAREGIVER_PTO);
    if (!isNoteValid) {
      $scope.noteValidations = { isPredefinedValid, isMessageValid };
      return;
    }

    const asHTML = document.createRange().createContextualFragment($scope.note.text);
    const mentionedAgencyMemberIds = [];
    for (const mentionElement of asHTML.querySelectorAll("span.mention")) {
      const agencyMemberId = mentionElement.getAttribute("data-agency-member-id");
      mentionedAgencyMemberIds.push(parseInt(agencyMemberId, 10));
    }

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiverPtoInstances/:caregiverPtoInstanceId/add_note",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.modalData.caregiverId,
      $scope.modalData.profileId
    );

    const noteActors = {
      caregiverId: $scope.modalData.caregiverId
    };

    const body = entityNoteService.buildEntityNoteRequest(
      $scope.newPTONote,
      noteConsts.NoteTypes.CAREGIVER_PTO,
      noteActors
    );

    DatabaseApi.post(url, body)
      .then(() => {
        fetchNotes();
        $scope.note.text = "";
        CKEDITOR.instances["note-editor"].setData("");
        ptoNotesModalService.onPostNote();
        toaster.pop("success", "Note created successfully");
      })
      .catch((err) => {
        toaster.pop("warning", "something went wrong", "");
      });
  };

  $scope.deleteNote = (noteId) => {
    const url = wildcard(
      "agencies/:agencyId/agency_member/:agencyMemberId/notes/:noteId",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      noteId
    );

    DatabaseApi.delete(url)
      .then(() => {
        fetchNotes();
        ptoNotesModalService.onDeleteNote();
        toaster.pop("success", "Note removed successfully");
      })
      .catch((err) => {
        toaster.pop("warning", "something went wrong", "Failed to remove note");
      });
  };

  $scope.goToPayrollBatchPage = (payrollBatchId) => {
    $scope.closeModal();
    $rootScope.closeCaregiverModal();
    $timeout(() => $state.go('app.payroll.batch', { id: payrollBatchId }));
  };

  $scope.closeModal = () => {
    ptoNotesModalService.closeModal();
  };

  $scope.handleNoteChanges = (updatedNote) => {
    if (!$scope.newPTONote) {
      $scope.newPTONote = {};
    }

    $scope.newPTONote = updatedNote;
  };

  initialize();
});
