'use strict';
app.controller('loginCtrl', function ($scope, $rootScope, $state, DatabaseApi, Storage, Socket, Analytics, $http, Consts, ClientSurvey) {

  $scope.forgotPass = false;
  $scope.email = undefined;
  $scope.password = undefined;
  $scope.twoFactorCode = undefined;
  $scope.twoFactorCodeRequired = false;
  
  var counter = 0;
  $scope.showPop = false;
  $scope.popCode = '';
  $scope.popInput = function(){
    if(counter >= 5) $scope.showPop = true;
    counter++;
  };
  
  $scope.insertCode = function(code){
    if (!$scope.popCode) return;
    Storage.set('code', code);
    counter = 0;
    $scope.showPop = false;
    $scope.popCode = '';
  };
  
  $scope.onForgotPassClick = function () {
    $scope.forgotPass = !$scope.forgotPass;
    $scope.disable = false;
    $scope.error = false;
  }
  
  $scope.login = function () {

    $scope.sending = true;

    var email = $scope.email;
    var pass = $scope.password;
    var twoFactorCode = $scope.twoFactorCode;
    

    //console.log($scope.forgotPass);
    if ($scope.forgotPass) return resetPassword(email);

    if(email && pass){
      var body = {
        email: email.toLowerCase(),
        password: pass,
        twoFactorCode: twoFactorCode,
        code: Storage.get('code') || undefined
      };
    } else {
      $scope.sending = false;
      $scope.disable = false;
      return;
    }
    
    //console.log(body);
    $http.post(Consts.api + 'auth/login', body)
      .then(function (res) {
          console.log('user');
          //console.log(res.data);
          if(res.data.loginData) {
              res.data.agency = res.data.loginData.agency;
              res.data.agencyMember = res.data.loginData.agencyMember;
              res.data.coordinator = res.data.loginData.coordinator;
              res.data.type = res.data.loginData.type;
              res.data.permissions = res.data.loginData.permissions;
          }
          email = email.toLowerCase();
          Storage.set('userEmail', email);
          Storage.setObject('user', res.data);
          //console.log(Storage);

          $rootScope.token = res.data.authToken;
          $rootScope.userId = res.data.userId;
          $rootScope.agencyMemberId = res.data.agencyMember ? res.data.agencyMember.id : undefined;
          if (res.data.agency) {
            $rootScope.agencyId = res.data.agency.id;
          }
          $rootScope.user = res.data;

          Socket.init(res.data);
          ClientSurvey.init();

          //DatabaseApi.connect('only-score');
          DatabaseApi.connect();
          DatabaseApi.GetSettings();
          if (true || $rootScope.user.verified) {
            if ($rootScope.user.coordinator) $rootScope.user.displayName = $rootScope.user.coordinator.firstName + ' ' + $rootScope.user.coordinator.lastName;
            if (res.data.agency) $rootScope.setCompanyType(res.data.agency.organizationType);
            $rootScope.user.email = email;//'asca@sdcsd.com';
            $rootScope.user.verified = true;
            $rootScope.user.admin = $rootScope.user.superuser ? true : res.data.coordinator.jobTitle === 'Admin';
            //DatabaseApi.GetSettings();
            
            Analytics.event('login');

            setTimeout(function () {
              $state.go('app.dashboard');
            }, 1200);
            //$state.go('app.visits.dashboard');
            //$state.go('app.Fite');
            //$state.go('app.confirmations');
            //$state.go('app.new-visit');
            //$state.go('app.professionals.caregivers');
            //$state.go('app.communication');
          } else {
            $scope.sending = false;
            $scope.disable = false;
            $scope.error = "Please Verify Email";
          }
        },
        function (error) {
          var errorMsg;
          if (error.status === 401) {
            errorMsg = "Incorrect Credentials";
            $scope.twoFactorCode = undefined;
            $scope.twoFactorCodeRequired = false;
          } else if (error.status === 403) {
            if ($scope.twoFactorCodeRequired) {
              errorMsg = "Incorrect Code";
            }
            $scope.twoFactorCodeRequired = true;
          } else if (error.status >= 500 && error.status <= 599) {
            errorMsg = "Server Error " + error.status;
          } else if (error.status === -1) {
            errorMsg = "Internet Connection Error";
          } else if (error.status === 423) {
            alert("User login blocked, too many consecutive login attempts.");
          } else if (error.status === 403) {
            alert("User login blocked, either user or associated agency is inactive."); 
          } else {
            errorMsg = "Uknown Error";
            alert("Uknown Error:\n" + JSON.stringify(error));
          }

          $scope.sending = false;
          console.log('error');
          console.log(error);
          $scope.disable = false;
          $scope.error = errorMsg
      
          if (!$scope.twoFactorCodeRequired) {
            Analytics.event('login-failed', {
              email: body.email
            });
         }
        });
  };

  function resetPassword(email) {
    DatabaseApi.post('auth/forgot_password', {email: email}).then(function (res) {
      $scope.sending = false;
      $scope.disable = true;
      $scope.emailSent = true;

    }, function (err) {
      //toaster.pop('error', "Something went wrong", 'Please try again');
      $scope.error = 'Email unknown, Please try again.';
      $scope.sending = false;
      $scope.disable = false;
    });
  }


});
