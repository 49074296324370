app.factory('Storage', ['$window', function($window) {
  return {
    set: function(key, value) {
      $window.localStorage[key] = value;
    },
    get: function(key, defaultValue) {
      return $window.localStorage[key] || defaultValue;
    },
    setObject: function(key, value) {
      $window.localStorage[key] = JSON.stringify(value);
    },
    getObject: function(key) {
      return JSON.parse($window.localStorage[key] || '{}');
    },
    delete: function(key) {
      $window.localStorage.removeItem(key);
    },
    clearAll: function() {
      $window.localStorage.clear();
    },
    clearExcept: function(keys) {
      // $window.localStorage.clear();
      var localKeys = Object.keys(localStorage);
      for (var key in localKeys) {
        var localKey = localKeys[key];
        if (keys.indexOf(localKey) < 0) {
          localStorage.removeItem(localKey);
        }
      }
    },
    softClear: function() {
      this.clearExcept([
        'stealthMode',
        'code',
        'showVideoNewVisitForm',
        'caregiversTableSettings',
        'trainingTableSettings',
        'pendingApplicationTableSettings',
        'coronaReportsTableSettings',
        'medflytPassportTableSettings',
        'billingAccountsReceivableTableSettings',
        'savedAssignedCoordinators',
        'faxComment',
        'localDistHash',
        'hide-sweep-vegas',
        'hide-reset-attempts-video',
        'hide-corona-banner',
        'trainingCenterStatisticsTableColumn',
        'trainingCenterOverallStatisticsTableColumn',
        'searchFiltersSettings',
        'visitsDashboardTableSettings',
        'stateOfHomeCareSurvey',
        'patientCalendarShowDeletedVisits',
        'caregiverCalendarShowDeletedVisits',
        'ClientSurveyNextDate'
      ])
    },
  }
}])