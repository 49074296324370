
'use-strict'
app.directive("billingCalendar", ["uiCalendarConfig", "$rootScope", "DatabaseApi", "$compile", "toaster", "$state", "SweetAlert", "$filter", "$uibModal", function (uiCalendarConfig, $rootScope, DatabaseApi, $compile, toaster, $state, SweetAlert, $filter, $uibModal) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/billing-calendar.html',
        scope: {
            patientId: '=',
            caregiverId: '=',
            onNewVisitInstanceClick: '&',
            onNewNoteClick: '&',
            onNotesClick: '&'
        },
        link: async function (scope, element) {
            scope.calendarName = 'myCalendar';
            scope.eventsData = {};
            scope.vacationsData = {};
            scope.eventSources = [[]];
            scope.hoveredDay = null;
            scope.selectedDays = {};
            scope.caregiverPTOLabels = DatabaseApi.caregiverPtoLabels();
            scope.notes = [];
            scope.noteDates = {}; 

            scope.hoverDay = function (jsonDate) {
                scope.hoveredDay = jsonDate;
            }

            scope.dishoverDay = function (jsonDate) {
                if (scope.hoveredDay === jsonDate) {
                    scope.hoveredDay = null;
                }
            }

            scope.getAgencyHolidays = async () => {
                return await DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/holidays').then(function (res) {
                    return res.data.agencyHolidays;
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "could not get agency holidays");
                });
            }

            scope.agencyHolidays = [];
            if (DatabaseApi.agencyHolidays() !== undefined) {
                scope.agencyHolidays = DatabaseApi.agencyHolidays();
            } else {
                scope.agencyHolidays = await scope.getAgencyHolidays();
            }

            scope.dayRender = async function (date, el, view) {
                var startOfView = angular.copy(view.intervalStart);
                var endOfView = angular.copy(view.intervalEnd);
                if (date.isAfter(startOfView.subtract(1, "days")) && !date.isAfter(endOfView.subtract(1, "days"))) {
                    if (view.intervalUnit === "month") {
                        var day = `<span class="calendar-day-number">${date.date()}</span>`;
                        var jsonDate = date.toJSON();
                        var allDayBox = document.querySelector(`.fc-day[data-date="${jsonDate}"]`);
                        var dayHeader = document.querySelector(`.fc-day-number[data-date="${jsonDate}"]`);

                        var htmlPopoverMenu = '<span class="pointer pull-right" ' +
                            'uib-popover-template="\'popover-calendar-' + jsonDate + '.html\'" ' +
                            'popover-class="popover-calendar" ' +
                            'popover-placement="bottom-right" ' +
                            'popover-trigger="\'outsideClick\'" ' +
                            'popover-append-to-body="true">' +
                            '<i class="material-icons calendar-options-btn">more_vert</i></span>' +
                            '<script type="text/ng-template" id="popover-calendar-' + jsonDate + '.html"><div class="calendar-day-options">' +
                            '<div ng-click="newVisitInstance(\'' + jsonDate + '\')"><h4>New Visit</h4></div>' +
                            '<div ng-click="newNote(\'' + jsonDate + '\')"><h4>New Note</h4></div>' +
                            '</div></script>';

                        var htmlCheckBox = '<div class="calendar-checkbox-container checkbox checkbox-success"' +
                            ' ng-show="selectedDays[\'' + jsonDate + '\'] || hoveredDay === \'' + jsonDate + '\'">' +
                            '<input type="checkbox" ng-model="selectedDays[\'' + jsonDate + '\']"><label></label></div>';

                        
                        var vacationIcon = '';
                        if (scope.vacationsData[jsonDate]) {
                            vacationIcon = '<img class="calendar-vacation-icon" src="admin/images/icons/tree.svg">';
                            allDayBox.style.backgroundColor = '#e8f7f4';
                        }

                        let noteIcon = '';
                        if (scope.noteDates[jsonDate]) {
                            noteIcon = '<i class="fa fa-sticky-note calendar-note-icon"' +
                                              'ng-click="openNotes(\'' + jsonDate + '\')"></i>';
                        }

                        let htmlDayTitle = '';

                        const holiday = scope.agencyHolidays.find(holiday => holiday.date === jsonDate);
                        if (holiday !== undefined) {
                            // Got holiday
                            htmlDayTitle = '<span class="font-bold pull-left text-left holiday-day-title">' + holiday.name + '</span>'
                            allDayBox.style.backgroundImage = "url('/admin/images/american-holiday.png')";
                            allDayBox.style.backgroundRepeat = "no-repeat";
                            allDayBox.style.backgroundSize = "cover";
                        }

                        // var mouseEvents = ' ng-mouseenter="hoverDay(\'' + jsonDate + '\')" ng-mouseleave="dishoverDay(\'' + jsonDate + '\')"';
                        dayHeader.addEventListener('mouseover', function (e) {
                            if (scope.hoveredDay !== jsonDate) {
                                scope.hoverDay(jsonDate);
                                scope.$digest();
                            }
                        });
                        dayHeader.addEventListener('mouseout', function (e) {
                            if (scope.hoveredDay === jsonDate) {
                                scope.dishoverDay(jsonDate);
                                scope.$digest();
                            }
                        });

                        const header = 
                            `<div>
                                ${htmlCheckBox}
                                ${htmlDayTitle}
                                ${htmlPopoverMenu}
                                ${day}
                            </div>`;

                        const icons = 
                            `<div>
                                ${noteIcon}
                                ${vacationIcon}
                            </div>`;

                        var dayHtml = '<div class="calendar-day-box">' +
                                header + icons
                            '</div>';

                        dayHeader.innerHTML = dayHtml;
                        $compile(dayHeader)(scope);
                    }
                } else {
                    el.html("");
                }
            }

            scope.getSelectedDays = function () {
                return Object.keys(scope.selectedDays).filter(function (key) {
                    return scope.selectedDays[key];
                });
            }

            scope.resetSelectedDays = function () {
                scope.selectedDays = {};
            }

            scope.viewRender = function (view) {
                scope.currView = view;
                if (scope.reloadCalendarOnGotData) {
                    scope.reloadCalendarOnGotData.view = view;
                }
                view.intervalEnd = view.intervalEnd.subtract(1, 'days');
                scope.selectedDays = {};
                prepCalendar(true, view);
            }

            scope.onEventClick = function (event, allDay, view) {
                if (!event.visitId) {
                    toaster.pop('error', 'Oops! something went wrong');
                } else {
                    if (!event.isPTOEvent) {
                        var modalInstance = $rootScope.openVisitInstanceModal(event.visitId);
                        modalInstance.result.then(function () {
                            prepCalendar(true, view);
                        }, function () {
                            prepCalendar(true, view);
                        });
                    } else {
                        // Open pto modal
                        scope.openCaregiverPTOModal(event);
                    }
                }
            };

            scope.eventRender = function (event, element, view) {
                const startOfView = angular.copy(view.intervalStart);
                const endOfView = angular.copy(view.intervalEnd);

                if (event.start.isBefore(startOfView) || event.start.isAfter(endOfView)) {
                    const elemEventContainer = element.find('.fc-event-container');
                    elemEventContainer.context.style.opacity = 0.4;
                }

                event.id = event.visitId ? event.visitId : new Date().getTime();
                if (event.visitId) {
                    event.id = event.visitId;
                } else {
                    var randomTime = new Date().getTime();
                    while (scope.eventsData[randomTime]) randomTime = new Date().getTime();
                    event.id = randomTime;
                }
                scope.eventsData[event.id] = event;

                var title = element.find('.fc-title, .fc-list-item-title');
                title.addClass('calendar-title');
                var elemEvent = element.find('.fc-event');
                elemEvent.context.classList.add('calendar-event');
                var strStart = event.originalStart && event.originalStart !== null ? new Date(event.originalStart).toLocaleTimeString() : '';
                var strEnd = event.originalEnd && event.originalEnd !== null ? new Date(event.originalEnd).toLocaleTimeString() : '';
                var strClockIn = event.clockIn && event.clockIn !== null ? new Date(event.clockIn).toLocaleTimeString() : '';
                var strClockOut = event.clockOut && event.clockOut !== null ? new Date(event.clockOut).toLocaleTimeString() : '';
                var eventMoment = moment(event.originalStart);
                strStart = cutLocaleTimeString(strStart, eventMoment);
                strEnd = cutLocaleTimeString(strEnd, eventMoment);
                strClockIn = cutLocaleTimeString(strClockIn, eventMoment);
                strClockOut = cutLocaleTimeString(strClockOut, eventMoment);
                var strTotalSchedule = (event.originalStart && event.originalEnd) ? scope.durationLabel(event.originalStart, event.originalEnd) : '';
                var strTotalClock = (event.clockIn && event.clockOut) ? scope.durationLabel(moment(event.clockIn), moment(event.clockOut)) : '';
                var strBilled = event.billed && event.billed !== null ? event.billed : '';
                var strPaid = event.paid && event.paid !== null ? event.paid : '';
                
                if (!event.isPTOEvent && event.ptoStatus !== null && event.ptoStatus !== 'DECLINED' ) {
                    elemEvent.context.style.borderWidth = "2px";
                }

                // var htmlPopoverMenu = '<span class="fa-stack pointer pull-right"' +
                //     'uib-popover-template="\'popover-patient-calendar-visit-' + event.id + '.html\'"' +
                //     'onClick="event.stopPropagation()"' +
                //     'popover-class="popover-calendar-visit"' +
                //     'popover-placement="bottom-right"' +
                //     'popover-trigger="\'outsideClick\'"' +
                //     'popover-append-to-body="true">' +
                //     '<i class="material-icons calendar-options-btn">more_vert</i></span>' +
                //     '<script type="text/ng-template" id="popover-patient-calendar-visit-' + event.id + '.html"><div class="calendar-visit-options">' +
                //     '<div ng-click="newVisitInstance(' + event.id + ')"><h4>New Visit</h4></div>' +
                //     '<div ng-click="deleteVisit(' + event.id + ')"><h4>Delete Visit</h4></div>' +
                //     '</div></script>';

                var htmlEventTitle;
                if (event.deleted) {
                    var date = $filter("mfShortTime")(event.deleted, ['withDate']);
                    htmlEventTitle = '<span class="calendar-visit-title" style="color: #E0E0E0">' + 'Deleted' + '<br>' + date + '</span>';
                } else if (event.titleName && event.titleId) {
                    htmlEventTitle = '<span class="calendar-visit-title" onClick="event.stopPropagation()">' +
                        '<span ng-click="onTitleClick(' + event.id + ', false)">' + event.titleName + '</span>' +
                        '<i style="margin-left: 5px;" class="fa fa-external-link" aria-hidden="true" ng-click="onTitleClick(' + event.id + ', true)"></i>' +
                        '</span>';
                } else if (event.isPTOEvent) {
                    htmlEventTitle = '<span class="calendar-visit-title" style="color: #777">' +
                        '<span>' + event.titleName + '</span>' +
                        '</span>';
                } else {
                    htmlEventTitle = '<span class="calendar-visit-title" style="color: #f44">No caregiver</span>';
                }

                var htmlDash = '<span style="margin-left:2px; margin-right:2px;">-</span>';
                var flexGrowStyle = ' style="flex-grow: 1"';
                var htmlPartiallyAssignedIcon = event.ptoStatus === "APPROVED"
                    ? '<span uib-popover="Visit is not fully assigned, due to PTO of the caregiver!" popover-append-to-body="true" popover-class="calendar-partially-assigned-visit-warning" popover-trigger="\'mouseenter\'" style="margin-left:16px"><i class="material-icons" style="font-size: 16px">warning</i></span>' : '';

                var scheduleflexGrow = '';
                if (strEnd === '') {
                    scheduleflexGrow = flexGrowStyle;;
                }
                var htmlSchduleStart = '<span class="calendar-time-sections" ' + scheduleflexGrow + '>' + strStart + '</span>';
                var htmlSchduleEnd = '<span class="calendar-time-sections">' + strEnd + '</span>';
                var htmlTotalScheduleTime = strTotalSchedule != '0h' ? '<span class="calendar-time-sections">' + strTotalSchedule + '</span>' : '';
                var htmlScheduleDash = strEnd === '' ? '' : htmlDash;
                var htmlSchduleTime = '<div class="calendar-time-sections-container" style="margin-bottom: 5px"><img src="admin/images/icons/calendar.svg"><strong class="calendar-times">' +
                    htmlSchduleStart + htmlScheduleDash + htmlSchduleEnd +
                    '</strong>' + htmlTotalScheduleTime + htmlPartiallyAssignedIcon + '</div>';

                // var htmlClockInMethod = getClockMethodIcon(event.clockInMethod);
                // var htmlClockOutMethod = getClockMethodIcon(event.clockOutMethod);
                var clockFlexGrow = '';
                if (strClockOut === '') {
                    clockFlexGrow = flexGrowStyle;
                }
                var htmlClockIn = '<span class="calendar-time-sections">' + strClockIn + '</span>';
                var htmlClockOut = '<span class="calendar-time-sections"' + clockFlexGrow + '>' + strClockOut + '</span>';
                var htmlTotalClockTime = strTotalClock != '0h' ? '<span class="calendar-time-sections">' + strTotalClock + '</span>' : '';
                var htmlClockDash = strClockOut === '' ? '' : htmlDash;
                var htmlClockInClockOut = '<div class="calendar-time-sections-container"><img src="admin/images/icons/clock.svg"><strong class="calendar-times">' +
                    htmlClockIn + htmlClockDash + htmlClockOut +
                    '</strong>' + htmlTotalClockTime + '</div>';

                var htmlTime = '<div style="margin: 4px 0; padding: 0px 6px;">' + htmlSchduleTime + htmlClockInClockOut + '</div>';
                var htmlBilledAndPaid = $rootScope.showBillingFeature ? '<div class="billed-paid-sections-container">' +
                    (strBilled !== '' ? '<span class="calendar-billed-paid-sections">Billed: ' + strBilled + '</span>' : '') +
                    (strPaid !== '' ? '<span class="calendar-billed-paid-sections">Paid: ' + strPaid + '</span>' : '') +
                    '</div>' : '';

                var isVisitDateOnDayBeforeIconSpan = '';
                if (event.isVisitDateOnDayBefore) {
                    isVisitDateOnDayBeforeIconSpan = '<span style="font-size: 1.8rem;"class="m-l-sm glyphicon glyphicon-arrow-right" data-toggle="tooltip" title="This visit belongs to the following day"> </span>'
                }
                var visitHtml = "";

                if (event.deleted) {
                    visitHtml = '<div id="' + event.id + '" class="calendar-visit-box">' + htmlEventTitle + isVisitDateOnDayBeforeIconSpan + '</div>';
                } else if (event.isPTOEvent) {
                    const htmlDuration = '<div class="calendar-time-sections-container">' +
                        '<img src="admin/images/icons/clock.svg" style="margin-right: 5px">' +
                        ($filter("minutesToClock")(event.duration) || 0) + "h" + '</div>'
                    visitHtml = '<div id="' + event.id + '" class="calendar-visit-box">' + htmlEventTitle + isVisitDateOnDayBeforeIconSpan + htmlDuration + '</div>';
                } else {
                    visitHtml = '<div id="' + event.id + '" class="calendar-visit-box">' + htmlEventTitle + isVisitDateOnDayBeforeIconSpan + htmlTime + htmlBilledAndPaid + '</div>';
                }
                title.html(visitHtml);
                $compile(title)(scope);
            }

            scope.durationLabel = function (start, end) {
                var duration = moment.duration(end.diff(start));
                return getDurationLabel(duration);
            };

            var getDurationLabel = function (duration) {
                if (duration <= 0) duration.add(1, 'days');
                var hours = duration.get('hours');
                if (duration.get('days')) {
                    hours += 24;
                }
                var minutes = duration.get('minutes');
                if (duration.get('seconds') > 30) {
                    minutes++;
                    if (minutes === 60) {
                        hours++;
                        minutes = 0;
                    }
                }
                if (minutes) return hours + ':' + minutes + 'h';
                if (hours) return hours + 'h';
                return '0h';
            }

            function cutLocaleTimeString(strLocalTimeString, eventMoment) {
                if (!strLocalTimeString) {
                    // var nowMoment = moment();
                    // var htmlDatePassed = '<b style="color: #ef5350">X</b>';
                    // var htmlDateToCome = '<b style="color: transparent; text-decoration-line: line-through; text-decoration-color: grey;">---</b>';
                    // if (eventMoment.isBefore(nowMoment)) return htmlDatePassed;
                    // if (eventMoment.isAfter(nowMoment)) return htmlDateToCome;
                    // return strLocalTimeString;
                    return '';
                }

                // from '10:30:00 AM' to '10:30a'
                var spacePos = strLocalTimeString.indexOf(' ');
                return strLocalTimeString.slice(0, spacePos - 3) + strLocalTimeString.slice(spacePos + 1, spacePos + 2).toLocaleLowerCase();
            }

            // function getClockMethodIcon (clockMethod) {
            //     switch (clockMethod) {
            //         case 'mobile': return '<i class="fa fa-mobile" aria-hidden="true"></i>&nbsp;';
            //         case 'telephone': return '<i class="fa fa-phone" aria-hidden="true"></i>&nbsp;';
            //         case 'manual': return '<i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;';
            //         default: return '';
            //     }
            // }

            scope.prosMap = DatabaseApi.caregivers() || {};
            scope.patientsMap = DatabaseApi.patients() || {};
            function isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            }

            function refresh(force) {
                console.log("refresh calendar");
                scope.prosMap = DatabaseApi.caregivers() || {};
                scope.patientsMap = DatabaseApi.patients() || {};

                if (scope.reloadCalendarOnGotData) {
                    prepCalendar(scope.reloadCalendarOnGotData.isCalendarloaded, scope.reloadCalendarOnGotData.view).then(function () {
                        document.querySelector('.fc-prev-button').click();
                        document.querySelector('.fc-next-button').click();
                    });
                } else if (force) {
                    prepCalendar(true, scope.currView).then(function () {
                        document.querySelector('.fc-prev-button').click();
                        document.querySelector('.fc-next-button').click();
                    });
                }
            }

            scope.$on('got_data', function (event) {
                refresh();
            });
            scope.$on('refresh_visits', function (event) {
                refresh(true);
            });
            scope.$on('refresh_calendar_notes', function (event) {
                refresh(true);
            });

            scope.newVisitInstance = function (jsonDate) {
                scope.onNewVisitInstanceClick()([moment(jsonDate)]);
            }

            scope.newNote = (jsonDate) => {
                scope.onNewNoteClick()(jsonDate);
            }

            scope.openNotes = (jsonDate) => {
                scope.onNotesClick()(jsonDate);
            }

            scope.onTitleClick = function (eventId, inNewTab) {
                if (scope.patientId) {
                    if (inNewTab) {
                        $rootScope.openCaregiverNewTab(scope.eventsData[eventId].titleId);
                    } else {
                        $rootScope.openCaregiverModal(scope.eventsData[eventId].titleId);
                    }
                } else if (scope.caregiverId) {
                    if (inNewTab) {
                        $rootScope.openPatientNewTab(scope.eventsData[eventId].titleId);
                    } else {
                        $rootScope.openPatientModal(scope.eventsData[eventId].titleId);
                    }
                }
            }

            scope.newMultipleVisitsModal = function () {
                const startDays = scope.getSelectedDays().map(key => moment(key));
                scope.onNewVisitInstanceClick()(startDays);
            }

            scope.updatePatientVacations = function () {
                if (!scope.patientId) {
                    return;
                }

                var getVacationsUrl = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/patients/' + scope.patientId +
                    '/patient_vacations';
                DatabaseApi.get(getVacationsUrl).then(async function (res) {
                    var vacations = res.data.patientVacations;
                    var newVacationsRanges = [];
                    var deleteVacationsIds = [];
                    var isInsertVacations;

                    for (var dayMoment = moment(scope.currView.intervalStart); !dayMoment.isAfter(scope.currView.intervalEnd); dayMoment.add(1, 'days')) {
                        var strDate = dayMoment.toJSON().slice(0, 10);
                        if (scope.selectedDays[strDate] === true) {
                            if (isInsertVacations === undefined) {
                                isInsertVacations = !(scope.vacationsData[strDate] === true);
                            } else {
                                if (scope.vacationsData[strDate] === true && isInsertVacations === true || scope.vacationsData[strDate] !== true && isInsertVacations === false) {
                                    SweetAlert.swal({
                                        title: "Conflict error",
                                        text: "Both vacations and non-vacations days were selected",
                                        type: "error",
                                        showCancelButton: false,
                                        confirmButtonColor: "#DD6B55",
                                        confirmButtonText: "Ok",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    }, function () {
                                    });
                                    isInsertVacations = undefined;
                                    break;
                                }
                            }

                            if (isInsertVacations === true) {
                                if (newVacationsRanges.length === 0) {
                                    newVacationsRanges.push({ start: strDate, end: strDate });
                                } else {
                                    var yesterday = angular.copy(dayMoment).subtract(1, "days");
                                    if (newVacationsRanges[newVacationsRanges.length - 1].end == yesterday.toJSON().slice(0, 10)) {
                                        newVacationsRanges[newVacationsRanges.length - 1].end = strDate;
                                    } else {
                                        newVacationsRanges.push({ start: strDate, end: strDate });
                                    }
                                }
                            } else if (isInsertVacations === false) {
                                var vacation = vacations.find((vacation) => vacation.date === strDate);
                                deleteVacationsIds.push(vacation.id);
                            }

                        }
                    }

                    if (isInsertVacations === true) {
                        var error = false
                        newVacationsRanges.forEach(function (range) {
                            if (scope.addPatientVacation(range.start, range.end, scope.patientId) === false) {
                                error = true;
                            }
                        });
                        if (error) {
                            toaster.pop('error', 'Some vacations failed to create');
                        } else {
                            scope.selectedDays = {};
                            await prepCalendar(true, scope.currView);
                            document.querySelector('.fc-prev-button').click();
                            document.querySelector('.fc-next-button').click();
                            toaster.pop('success', "Vacations created successfully");
                        }
                    } else if (isInsertVacations === false) {
                        var error = false;
                        deleteVacationsIds.forEach(function (vacationId) {
                            scope.deletePatientVacation(vacationId, scope.patientId);
                        });
                        if (error) {
                            toaster.pop('error', 'Some vacations failed to delete');
                        } else {
                            scope.selectedDays = {};
                            scope.vacationsData = {};
                            await prepCalendar(true, scope.currView);
                            document.querySelector('.fc-prev-button').click();
                            document.querySelector('.fc-next-button').click();
                            toaster.pop('success', "Vacations deleted successfully");
                        }
                    }
                }, function (err) {
                    toaster.pop('error', 'Ooops! Something went wrong');
                });
            }

            scope.addPatientVacation = function (start, end, id) {
                if (!id) return;

                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/patients/' + id +
                    '/patient_vacations';
                var body = {
                    startTime: start,
                    endTime: end
                };
                DatabaseApi.post(url, body).then(function (res) {
                    return true;
                }, function (err) {
                    return false;
                });
            }

            scope.deletePatientVacation = function (vacationId, id) {
                if (!id) return;

                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/patients/' + id +
                    '/patient_vacations/' + vacationId;
                DatabaseApi.delete(url).then(function (res) {
                    return true;
                }, function (err) {
                    return false;
                });
            }

            scope.openCaregiverPTOModal = function (ptoEvent) {
                const caregiver = scope.prosMap[scope.caregiverId];
                if (caregiver.hireDate === null) {
                    SweetAlert.swal({
                        title: "Caregiver must have hire date in order to add PTO",
                        type: "error",
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "OK",
                        closeOnConfirm: true
                    });
                    return;
                }
                const selectedDays = scope.getSelectedDays();
                if (!ptoEvent && selectedDays.length === 0) {
                    return;
                }
                let selectedEvents = [];
                const newScope = scope.$new();

                if (!ptoEvent) {
                    Object.keys(scope.eventsData).forEach((eventId) => {
                        const eventData = scope.eventsData[eventId];

                        selectedDays.forEach((eventDate) => {
                            const momentDate = moment(eventDate);
                            if (momentDate.isSame(eventData.start, "day")) {
                                selectedEvents.push(eventData);
                            }
                        });
                    });

                    if (selectedEvents.length === 0) {
                        return SweetAlert.swal(
                            {
                                title: "Can't add PTO to date",
                                text: "PTO day must have visit assigned on the same day",
                                type: "warning",
                                confirmButtonColor: "#3077EB",
                                confirmButtonText: "OK",
                                closeOnConfirm: true,
                            },
                            () => { }
                        );
                    }

                    newScope.visitInstances = selectedEvents;
                } else {
                    newScope.ptoEventForm = ptoEvent;
                    newScope.ptoEventForm.id = ptoEvent.pto.id;
                    const startTime = newScope.ptoEventForm.visitInstance.startTime;
                    const endTime = newScope.ptoEventForm.visitInstance.endTime;
                    newScope.ptoEventForm.visitInstance.originalStart = moment(startTime.toJSON ? startTime.toJSON() : startTime);
                    newScope.ptoEventForm.visitInstance.originalEnd = moment(endTime.toJSON ? endTime.toJSON() : endTime);
                    const patient = scope.patientsMap[newScope.ptoEventForm.visitInstance.patientId];
                    newScope.ptoEventForm.visitInstance.titleName = patient !== undefined ? patient.displayName : '';
                    newScope.visitInstances = [newScope.ptoEventForm.visitInstance];
                    newScope.viewModeOnly = true;
                }

                newScope.caregiverId = scope.caregiverId;

                const modalInstance = $uibModal.open({
                    templateUrl: "admin/views/visit-instance-pto-modal.html",
                    size: "md",
                    controller: "visitInstancePTOModalCtrl",
                    windowTopClass: "PTO-modal",
                    scope: newScope,
                });
                modalInstance.result.then(function () {
                    scope.resetSelectedDays();
                    prepCalendar(true, scope.currView);
                }, () => {
                    scope.resetSelectedDays();
                });
            };

            var prepCalendar = function (isCalendarloaded, inputView) {
                scope.eventSources = [];
                var view = angular.copy(inputView);
                view.intervalStart.subtract(8, "days");
                view.intervalEnd.add(15, "days");

                if ((scope.patientId || scope.caregiverId) && (isCalendarloaded || uiCalendarConfig && uiCalendarConfig.calendars && uiCalendarConfig.calendars[scope.calendarName])) {
                    if (scope.patientId && isEmpty(scope.prosMap)) {
                        scope.reloadCalendarOnGotData = {
                            isCalendarloaded: isCalendarloaded,
                            view: inputView // passing the original dates
                        };
                        return;
                    }

                    var events = [];
                    var eventsMap = {};

                    scope.COLORS = {
                        VALID: '#e3f1fd',
                        NO_CAREGIVER: '#e3f1fd',
                        INVALID: '#fef3f3',
                        MISSED: '#d4d2d2',
                        REMOVED: '#C62828',
                        INVALID_AUTHORIZATION: '#f7c780',
                        NO_AUTHORIZATION: '#ffc0cb',
                        PTO: '#e3bfff'
                    };

                    var startDate = view.intervalStart.toISOString().slice(0, 10);
                    var endDate = view.intervalEnd.toISOString().slice(0, 10);
                    var url = '';
                    if (scope.patientId) {
                        scope.patientCalendar = true;
                        url = 'agencies/' + $rootScope.agencyId +
                            '/agency_members/' + $rootScope.agencyMemberId +
                            '/patients/' + scope.patientId +
                            '/visit_instances_billing';
                    } else if (scope.caregiverId) {
                        scope.caregiverCalendar = true;
                        url = 'agencies/' + $rootScope.agencyId +
                            '/agency_members/' + $rootScope.agencyMemberId +
                            '/caregivers/' + scope.caregiverId +
                            '/visit_instances_billing';
                    }

                    return new Promise(function (resolve, reject) {
                        DatabaseApi.get(url + '?from=' + startDate + '&to=' + endDate).then(function (res) {
                            res.data.visitInstances.forEach(function (visitInstance) {
                                visitInstance.visit.virtualStartTime = visitInstance.visit.startTime;
                                visitInstance.visit.virtualEndTime = visitInstance.visit.endTime;

                                if (visitInstance.visit.isVisitDateOnDayBefore) {
                                    visitInstance.visit.virtualStartTime = moment(new Date(visitInstance.visit.startTime)).subtract(1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 59 }).format("YYYY-MM-DDTHH:mm");
                                    visitInstance.visit.virtualEndTime = moment(new Date(visitInstance.visit.endTime)).subtract(1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 59 }).format("YYYY-MM-DDTHH:mm");
                                }
                                var strDateId = visitInstance.visit.virtualStartTime.slice(0, 10);
                                if (!eventsMap[strDateId]) {
                                    eventsMap[strDateId] = [];
                                }
                                var titleName, titleId, color, billed, paid;
                                if (scope.patientId) {
                                    titleId = visitInstance.visit.caregiverId;
                                    if (visitInstance.visit.removedAt) {
                                        color = scope.COLORS.REMOVED;
                                    }
                                    else if (!visitInstance.visit.caregiverId) {
                                        titleName = 'No Caregiver';
                                        color = visitInstance.visit.missedVisit ? scope.COLORS.MISSED : scope.COLORS.NO_CAREGIVER;
                                    } else {
                                        titleName = scope.prosMap[visitInstance.visit.caregiverId].displayName;
                                        if (visitInstance.visit.missedVisit) {
                                            titleName = scope.prosMap[visitInstance.visit.caregiverId].displayName;
                                            color = scope.COLORS.MISSED;
                                        } else {
                                            color = scope.COLORS.VALID;
                                        }
                                    }
                                } else if (scope.caregiverId) {
                                    titleId = visitInstance.visit.patientId
                                    titleName = visitInstance.visit.patientName ? visitInstance.visit.patientName : 'Patient Name Error';
                                    if (visitInstance.visit.removedAt) {
                                        color = scope.COLORS.REMOVED;
                                    } else if (visitInstance.visit.missedVisit) {
                                        color = scope.COLORS.MISSED;
                                    } else {
                                        color = scope.COLORS.VALID;
                                    }
                                }

                                // todo nir
                                billed = (visitInstance.billedSeconds) ? getDurationLabel(moment.duration(1000 * visitInstance.billedSeconds)) : null;
                                paid = (visitInstance.paidSeconds) ? getDurationLabel(moment.duration(1000 * visitInstance.paidSeconds)) : null;

                                if (!visitInstance.authorizationValid && !visitInstance.visit.missedVisit && $rootScope.showBillingFeature) {
                                    color = scope.COLORS.INVALID_AUTHORIZATION;
                                }

                                if (visitInstance.missingAuth && !visitInstance.visit.missedVisit && $rootScope.showBillingFeature) {
                                    color = scope.COLORS.NO_AUTHORIZATION;
                                }

                                eventsMap[strDateId].push({
                                    title: 'visit ' + visitInstance.visit.id,
                                    color: color,
                                    borderColor: visitInstance.ptoStatus !== null && visitInstance.ptoStatus !== 'DECLINED' ? scope.COLORS.PTO : '#00000000',
                                    ptoStatus: visitInstance.ptoStatus,
                                    editable: false,
                                    titleName: titleName,
                                    titleId: titleId,
                                    start: moment(visitInstance.visit.virtualStartTime),
                                    end: moment(visitInstance.visit.virtualStartTime).add(15, 'minutes'),
                                    allDay: false,
                                    clockIn: visitInstance.visit.clockinTime,
                                    clockOut: visitInstance.visit.clockoutTime,
                                    clockInMethod: visitInstance.visit.clockinType,
                                    clockOutMethod: visitInstance.visit.clockoutTime,
                                    billed: billed,
                                    paid: paid,
                                    authorizationValid: visitInstance.authorizationValid,
                                    visitId: visitInstance.visit.id,
                                    isVisitDateOnDayBefore: visitInstance.visit.isVisitDateOnDayBefore,
                                    originalStart: moment(visitInstance.visit.startTime),
                                    originalEnd: moment(visitInstance.visit.endTime),
                                    deleted: visitInstance.visit.removedAt
                                });
                            });

                            if (res.data.ptoInstances) {
                                res.data.ptoInstances.caregiverPtos.forEach(pto => {
                                    pto.label = scope.caregiverPTOLabels.find(label => label.id === pto.labelId);
                                    pto.visitInstance.virtualStartTime = pto.visitInstance.startTime;
                                    pto.visitInstance.virtualEndTime = pto.visitInstance.endTime;

                                    if (pto.visitInstance.isVisitDateOnDayBefore) {
                                        pto.visitInstance.virtualStartTime = moment(new Date(pto.visitInstance.startTime)).subtract(1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 59 }).format("YYYY-MM-DDTHH:mm");
                                        pto.visitInstance.virtualEndTime = moment(new Date(pto.visitInstance.endTime)).subtract(1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 59 }).format("YYYY-MM-DDTHH:mm");
                                    }
                                    var strDateId = pto.visitInstance.virtualStartTime.slice(0, 10);
                                    if (!eventsMap[strDateId]) {
                                        eventsMap[strDateId] = [];
                                    }

                                    pto.visitInstance.durationInMinutes = pto.duration;
                                    const statusString = '(' + $filter("camelCase")(pto.status) + ')';
                                    eventsMap[strDateId].push({
                                        title: 'pto ' + pto.caregiverPTOInstanceId,
                                        color: scope.COLORS.PTO,
                                        borderColor: '#00000000',
                                        editable: false,
                                        titleName: pto.label.text + " " + statusString,
                                        label: pto.label,
                                        pto: pto,
                                        status: pto.status,
                                        start: moment(pto.visitInstance.virtualStartTime),
                                        end: moment(pto.visitInstance.virtualStartTime).add(15, 'minutes'),
                                        allDay: false,
                                        isPTOEvent: true,
                                        duration: pto.duration,
                                        visitId: pto.visitInstance.id,
                                        visitInstance: pto.visitInstance,
                                        isVisitDateOnDayBefore: pto.visitInstance.isVisitDateOnDayBefore,
                                        originalStart: moment(pto.visitInstance.startTime),
                                        originalEnd: moment(pto.visitInstance.endTime)
                                    });
                                });
                            }

                            if (res.data.patientVacations) {
                                res.data.patientVacations.forEach(function (patientVacation) {
                                    var strDateId = patientVacation.date;
                                    scope.vacationsData[strDateId] = true;
                                });
                            }

                            if (res.data.notes) {
                                scope.notes = res.data.notes;
                                res.data.notes.forEach(note => {
                                    scope.noteDates[note.calendarDate] = true;
                                })
                            }

                            for (var dayMoment = moment(view.intervalStart); !dayMoment.isAfter(view.intervalEnd); dayMoment.add(1, 'days')) {
                                var strDate = dayMoment.toJSON().slice(0, 10);
                                if (eventsMap[strDate]) {
                                    eventsMap[strDate].forEach(function (event) {
                                        events.push(event);
                                    });
                                }
                            }

                            scope.eventSources = [events];
                            uiCalendarConfig.calendars[scope.calendarName].fullCalendar('removeEvents');
                            uiCalendarConfig.calendars[scope.calendarName].fullCalendar('addEventSource', events);
                            resolve(true);
                        }, function () {
                            scope.eventSources = [events];
                            uiCalendarConfig.calendars[scope.calendarName].fullCalendar('removeEvents');
                            uiCalendarConfig.calendars[scope.calendarName].fullCalendar('addEventSource', events);

                            if (isCalendarloaded) {
                                $(".fc-today-button:first").click();
                            }
                            toaster.pop('error', 'Error loading calendar visits');
                            reject(false);
                        });
                    });
                }
            }

            const startDayOfTheWeek = $rootScope.visitSettings.calendarStartDayOfTheWeek ? parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek) : 0;

            var calendarLoad = setInterval(function () {
                if (uiCalendarConfig && uiCalendarConfig.calendars && uiCalendarConfig.calendars[scope.calendarName]) {
                    var startOfMonthM = moment();
                    var endOfMonthM = moment();
                    var view = {
                        intervalStart: startOfMonthM.set("date", 1),
                        intervalEnd: endOfMonthM.set("date", endOfMonthM.daysInMonth())
                    };
                    prepCalendar(true, view).then(() => {
                        scope.uiConfig = {
                            calendar: {
                                height: 600,
                                header: {
                                    left: '',
                                    center: 'prev,next title prevYear,nextYear',
                                    right: 'today month,agendaWeek,agendaDay'
                                },
                                buttonText: {
                                    today: 'Today',
                                    month: 'Month',
                                    week: 'Week',
                                    day: 'Day',
                                    prev: '< Month',
                                    next: 'Month >',
                                    prevYear: '< Year',
                                    nextYear: 'Year >'
                                },
                                firstDay: startDayOfTheWeek,
                                displayEventTime: false,
                                eventClick: scope.onEventClick,
                                viewRender: scope.viewRender,
                                eventRender: scope.eventRender,
                                dayRender: scope.dayRender
                            }
                        };
                    });
                    
                    clearInterval(calendarLoad);
                }
            }, 1000);
        }
    };
}]);