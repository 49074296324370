"use strict";

angular.module("dashboard").controller("staffingIssuesCtrl", function (
  $scope,
  $rootScope,
  NgTableParams,
  itemSearchPageManager,
  FilterUtils,
  toaster,
  dateUtils,
  $filter,
  DatabaseApi,
  visitConsts,
) {

  const initialize = () => {
    $scope.issuesTable = null;
    $scope.globalFilter = { val: "" };
    $scope.issuesCounters = {};
    initAssets();
    if (!$scope.gotVisitSettings) {
      return;
    }
    $scope.firstDayOfWeek = parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek || 0);
    initPageManager();
    $scope.pageManager.resetSearchParamsByUrl();
    initDateRange();
    initDateRangePresets();
    initIssuesTemplates();
    initFilters();
  };

  $scope.singleSelectionExtraSettings = {
    styleActive: true,
    singleSelection: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1,
    closeOnSelect: true,
    showCheckAll: false,
    showUncheckAll: false,
  };

  $scope.dateRangePresetsEvents = {
    onSelectionChanged: () => {
      handleChangeDateRangeIssueSelection($scope.selectedDateRangePreset.id);
    },
  };

  const initPageManager = () => {
    $scope.pageManager = itemSearchPageManager.createSearchPageManager("/staffing_issues");
    $scope.validatorsDataManager = $scope.pageManager.getValidatorsDataManager("STAFFING_ISSUES");
    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
    $scope.pageManager.initContractTypesParam();
    $scope.pageManager.initOfficeParam();
    $scope.pageManager.initFromToDateParams(false, false, "startDate", "endDate");
    $scope.pageManager.initValidatorsParam("issues");
  };

  const initDateRange = () => {
    $scope.datePickerOptions = {
      startDate: {
        startingDay: $scope.firstDayOfWeek,
        dateDisabled: ({date}) => {
          return date.getDay() !== $scope.firstDayOfWeek;
        }
      },
      endDate: {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek,
        dateDisabled: ({date}) => {
          let lastDayOfWeek = $scope.firstDayOfWeek + 6;
          if (lastDayOfWeek > 6) {
            lastDayOfWeek -= 7;
          }
          return date.getDay() !== lastDayOfWeek;
        }
      }
    };

    $scope.datesChangeHandlers = {
      startDate: () => {
        const newStartDate = $scope.pageManager.searchParams.startDate.value;
        if (!newStartDate || newStartDate.getDay() !== $scope.firstDayOfWeek) {
          $scope.pageManager.updateSearchParamValue("startDate", "");
          return;
        }
        const oldEndDate = $scope.pageManager.searchParams.endDate.value;
        const localDateNewStartDate = dateUtils.dateToLocalDate(newStartDate);
        const localDateOldEndDate = oldEndDate === "" ? "" : dateUtils.dateToLocalDate(oldEndDate)
        if (oldEndDate === "" || localDateOldEndDate.isBefore(localDateNewStartDate)) {
          const newEndDate = dateUtils.localDateToDate(localDateNewStartDate.plusDays(6));
          $scope.pageManager.updateSearchParamValue("endDate", newEndDate);
        }
        $scope.datePickerOptions.endDate.minDate = newStartDate;
        const localDateNewEndDate = dateUtils.dateToLocalDate($scope.pageManager.searchParams.endDate.value);
        setPresetByLocalDates(localDateNewStartDate, localDateNewEndDate);
      },
      endDate: () => {
        const newEndDate = $scope.pageManager.searchParams.endDate.value;
        if (!newEndDate || newEndDate.getDay() !== $scope.firstDayOfWeek - 1 && newEndDate.getDay() !== $scope.firstDayOfWeek + 6) {
          $scope.pageManager.updateSearchParamValue("endDate", "");
          return;
        }
        const oldStartDate = $scope.pageManager.searchParams.startDate.value;
        const localDateNewEndDate = dateUtils.dateToLocalDate(newEndDate);
        const localDateOldStartDate = oldStartDate === "" ? "" : dateUtils.dateToLocalDate(oldStartDate);
        if (oldStartDate === "" || localDateOldStartDate.isAfter(localDateNewEndDate)) {
          const newStartDate = dateUtils.localDateToDate(localDateNewEndDate.minusDays(6));
          $scope.pageManager.updateSearchParamValue("startDate", newStartDate);
        }
        $scope.datePickerOptions.startDate.maxDate = newEndDate;
        const localDateNewStartDate = dateUtils.dateToLocalDate($scope.pageManager.searchParams.startDate.value);
        setPresetByLocalDates(localDateNewStartDate, localDateNewEndDate);
      }
    };
  };

  const setPresetByLocalDates = (startDate, endDate) => {
    const matchingPreset = $scope.dateRangePresetsOptions.find(preset =>
      preset.startDate.equals(startDate) &&
      preset.endDate.equals(endDate)
    );
    $scope.selectedDateRangePreset = {};
    if (matchingPreset) {
      $scope.selectedDateRangePreset.id = matchingPreset.id;
    }
  };

  const getStartOfWeek = () => {
    const nowLocalDate = JSJoda.LocalDate.now();
    let nowDayOfWeek = nowLocalDate.dayOfWeek().value(); // 1 [MONDAY] - 7 [SUNDAY]
    if (nowDayOfWeek === 7) nowDayOfWeek = 0; // SUNDAY
    let sub = nowDayOfWeek - $scope.firstDayOfWeek; // firstDayOfWeek (0 [SUNDAY] - 6 [SATURDAY])
    if (sub < 0) sub += 7;
    const startOfWeekLocalDate = nowLocalDate.minusDays(sub);
    return startOfWeekLocalDate;
  };

  const initDateRangePresets = () => {
    const startOfThisWeek = getStartOfWeek();
    $scope.dateRangePresetsOptions = [
      { id: 1, label: "Last 4 weeks", startDate: startOfThisWeek.minusWeeks(4), endDate: startOfThisWeek.minusDays(1) },
      { id: 2, label: "Last 2 weeks", startDate: startOfThisWeek.minusWeeks(2), endDate: startOfThisWeek.minusDays(1) },
      { id: 3, label: "Last Week", startDate: startOfThisWeek.minusWeeks(1), endDate: startOfThisWeek.minusDays(1) },
      { id: 4, label: "This Week", startDate: startOfThisWeek, endDate: startOfThisWeek.plusWeeks(1).minusDays(1) },
      { id: 5, label: "Next Week", startDate: startOfThisWeek.plusWeeks(1), endDate: startOfThisWeek.plusWeeks(2).minusDays(1) },
      { id: 6, label: "Next 2 weeks", startDate: startOfThisWeek.plusWeeks(1), endDate: startOfThisWeek.plusWeeks(3).minusDays(1) },
      { id: 7, label: "Next 4 weeks", startDate: startOfThisWeek.plusWeeks(1), endDate: startOfThisWeek.plusWeeks(5).minusDays(1) },
    ];
    $scope.selectedDateRangePreset = {id : 4};
    handleChangeDateRangeIssueSelection(4);
  };

  const handleChangeDateRangeIssueSelection = (dateRangePresetId) => {
    const dateRangePreset = $scope.dateRangePresetsOptions.find(preset =>
      preset.id === dateRangePresetId
    );
    if (dateRangePreset) {
      $scope.pageManager.updateSearchParamValue("startDate", dateUtils.localDateToDate(dateRangePreset.startDate));
      $scope.pageManager.updateSearchParamValue("endDate", dateUtils.localDateToDate(dateRangePreset.endDate));
    }
  };

  const initIssuesTemplates = () => {
    $scope.issuesTemplates = [
      {
        id: 1,
        label: "Patients",
        issues: visitConsts.staffingIssuesValidatorIds
          .filter(issue => issue.template === "PATIENTS")
          .map(issue => issue.id)
      },
      {
        id: 2,
        label: "Caregivers",
        issues: visitConsts.staffingIssuesValidatorIds
          .filter(issue => issue.template === "CAREGIVERS")
          .map(issue => issue.id)
      }
    ];
    $scope.selectedIssuesTemplate = { id: 1 };
    $scope.issuesTemplatesEvents = {
      onSelectionChanged: () => {
        handleChangeIssuesTemplateSelection($scope.selectedIssuesTemplate.id);
      },
    };
  };

  const handleChangeIssuesTemplateSelection = (issuesTemplateId) => {
    const templateSettings = $scope.issuesTemplates.find(template =>
      template.id === issuesTemplateId
    );
    if (templateSettings !== undefined) {
      initValidatorsFromTemplate(templateSettings);
    }
  };

  const initFilters = () => {
    const storageFilters = FilterUtils.rootFilters.staffingIssuesCtrl;
    if (storageFilters !== undefined) {
      $scope.pageManager.setSearchParams(storageFilters);
      initValidatorsFilterFromStorage(storageFilters);
      setPresetByLocalDates(
        dateUtils.dateToLocalDate(storageFilters.startDate.value),
        dateUtils.dateToLocalDate(storageFilters.endDate.value)
      );
    } else if ($scope.selectedIssuesTemplate.id) {
      handleChangeIssuesTemplateSelection($scope.selectedIssuesTemplate.id);
    }
  };

  const initValidatorsFromTemplate = (issuesTemplate) => {
    const validators = $scope.validatorsDataManager.getAllValidators();
    validators.forEach((validator) => {
      const shouldBeSelected = issuesTemplate.issues.includes(validator.id);
      if (validator.isSelected !== shouldBeSelected) {
        $scope.validatorsDataManager.toggleSelection(validator);
      }
    });
    $scope.pageManager.updateSearchParamValue("issues", validators);
  };

  const initValidatorsFilterFromStorage = (storageFilters) => {
    const validatorsToDeselectMap = {};
    storageFilters.issues.value.forEach(validator => {
      if (validator.isSelected === false) {
        validatorsToDeselectMap[validator.id] = true;
      }
    });
    const validators = $scope.validatorsDataManager.getAllValidators();
    validators.forEach((validator) => {
      if (validator.isSelected && validatorsToDeselectMap[validator.id]) {
        $scope.validatorsDataManager.toggleSelection(validator);
      }
    });
    $scope.pageManager.updateSearchParamValue("issues", validators);
  };

  $scope.areAllValidatorsSelected = () => {
    if (!$scope.validatorsDataManager) {
      return false;
    }
    const allValidators = $scope.validatorsDataManager.getAllValidators();
    const selectedValidators = allValidators.filter(v => v.isSelected);
    return selectedValidators.length === allValidators.length;
  };

  $scope.toggleSelectAllValidators = () => {
    const selection = !$scope.areAllValidatorsSelected();
    $scope.validatorsDataManager.getAllValidators().forEach((validator) => {
      if (validator.isSelected !== selection) {
        $scope.validatorsDataManager.toggleSelection(validator)
      }
    });
  };

  $scope.searchStaffingIssues = () => {
    if (!$scope.gotAssets) {
      return;
    }
    $scope.globalFilter.val = "";
    $scope.pageManager.executeSearch().then((res) => {
      $scope.staffingIssues = mapItems(res.data.issues);
      initTable();
    }, (error) => {
      console.error(error);
      toaster.pop("error", "Failed to load staffing issues");
      $scope.staffingIssues = [];
      $scope.issuesCounters = {};
      initTable();
    });
  };

  const mapItems = (items) => {
    $scope.issuesCounters = Object.fromEntries(
      visitConsts.staffingIssuesValidatorIds.map(issue => [issue.id, 0])
    );
    return items.map(item => {
      if (item.patientId !== undefined) {
        item.patientDisplayId = item.patientDisplayId === -1 ? "" : item.patientDisplayId;
        const patient = $scope.patients[item.patientId];
        if (patient) {
          item.patientFirstLastName = `${patient.firstName} ${patient.lastName}`;
          if (patient.middleName && patient.middleName !== "") {
            item.patientFirstMiddleLastName = `${patient.firstName} ${patient.middleName} ${patient.lastName}`;
            item.patientLastMiddleFirstName = `${patient.lastName} ${patient.middleName} ${patient.firstName}`;
          }
        }
      }
      if (item.caregiverId !== undefined) {
        item.caregiverDisplayId = item.caregiverDisplayId === -1 ? "" : item.caregiverDisplayId;
        const caregiver = $scope.caregivers[item.caregiverId];
        if (caregiver) {
          item.caregiverFirstLastName = `${caregiver.firstName} ${caregiver.lastName}`;
          if (caregiver.middleName && caregiver.middleName !== "") {
            item.caregiverFirstMiddleLastName = `${caregiver.firstName} ${caregiver.middleName} ${caregiver.lastName}`;
            item.caregiverLastMiddleFirstName = `${caregiver.lastName} ${caregiver.middleName} ${caregiver.firstName}`;
          }
        }
      }
      if ($scope.issuesCounters[item.issue] !== undefined) {
        $scope.issuesCounters[item.issue]++;
      }
      return item;
    });
  };

  const initTable = () => {
    const options = {
      count: 25
    };
    $scope.issuesTable = new NgTableParams(options, {
      dataset: $scope.staffingIssues
    });
  };

  $scope.applyGlobalSearch = (term) => {
    const filter = { $: term };
    if ($scope.issuesTable) {
      angular.extend($scope.issuesTable.filter(), filter);
    }
  };

  $scope.exportTable = () => {
    const tableData = $filter('filter')($scope.staffingIssues, $scope.globalFilter.val);
    if (tableData.length <= 0) {
      toaster.pop('warning', 'Nothing to export', 'The table is empty');
      return;
    }

    $scope.loadingCSV = true;

    const rows = [];
    const titles = [
      "Issue",
      "Patient Name",
      "Patient ID",
      "Caregiver Name",
      "Caregiver ID",
      "Visit ID",
      "Visit Date"
    ];

    rows.push(titles);

    tableData.forEach(dataRow => {
      const csvRow = [];

      titles.forEach((title) => {
        if (title === "Issue") csvRow.push(`"${dataRow.issueDisplayText || ""}"`);
        else if (title === "Patient Name") csvRow.push(`"${(dataRow.patientName || "").replace(/(\r\n|\n|\r)/gm, "")}"`);
        else if (title === "Patient ID") csvRow.push(`"${dataRow.patientDisplayId || ""}"`);
        else if (title === "Caregiver Name") csvRow.push(`"${(dataRow.caregvierName || "").replace(/(\r\n|\n|\r)/gm, "")}"`);
        else if (title === "Caregiver ID") csvRow.push(`"${dataRow.caregiverDisplayId || ""}"`);
        else if (title === "Visit ID") csvRow.push(`"${dataRow.visitId || ""}"`);
        else if (title === "Visit Date") csvRow.push(`"${$filter("mfShortDate")(dataRow.visitDate)}"`);
        else csvRow.push("");
      });

      rows.push(csvRow);
    });

    let csvContent = "";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const universalBOM = "\uFEFF";
    const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `medflyt-export-staffing-issues-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`);
    document.body.appendChild(link);

    link.click();
    $scope.loadingCSV = false;
  };

  const initAssets = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.patients = DatabaseApi.patients() || {};
    $scope.caregivers = DatabaseApi.caregivers() || {};
    checkAssets();
  };

  const checkAssets = () => {
    const patientsState = $scope.initializeMap['patients'];
    const caregiversState = $scope.initializeMap['caregivers'];
    $scope.gotVisitSettings = $scope.initializeMap['visitSettings'];
    $scope.gotAssets = patientsState && caregiversState;
  };
  
  $scope.getIssueCounterSuffix = (issue) => {
    if (!$scope.issuesCounters[issue]) {
      return "";
    }
    return ` (${$scope.issuesCounters[issue]})`;
  };

  $rootScope.$on("got_caregivers_data", () => {
    $scope.caregivers = DatabaseApi.caregivers();
    checkAssets();
  });

  $rootScope.$on("got_patients_data", () => {
    $scope.patients = DatabaseApi.patients();
    checkAssets();
  });

  $rootScope.$on("got_visit_settings", () => {
    initialize();
  });

  $scope.$on("$destroy", () => {
    const filters = $scope.pageManager.searchParams;
    FilterUtils.rootFilters.staffingIssuesCtrl = angular.copy(filters);
  });

  initialize();
});