'use strict'
app.controller('billingPayrollDetailsCtrl', function ($scope, $rootScope, NgTableParams, $stateParams, DatabaseApi, toaster, selectionLogic, wildcard, $uibModal, mfModal) {
    const universalBOM = "\uFEFF";

    $scope.isLoadingPayrollItems = false;
    $scope.payrollBatch = null;
    $scope.caregiversVisits = {};
    $scope.patientsMap = DatabaseApi.patients();
    $scope.contracts = DatabaseApi.contractTypes();
    $scope.serviceCodes = DatabaseApi.activeServiceCodes();
    $scope.serviceCodesOptions = $scope.serviceCodes.map(sc => ({ id: sc.id, label: sc.code }));

    $scope.visitPayrollTypesMap = {
        "PTO": "PTO",
        "ONLINE_TRAINING": "Online Training",
        "IN_CLASS": "In Class",
        "TRAVEL_TIME": "Travel Time",
        "SOH": "SOH",
        "VISIT": "Visit"
    }
    $scope.visitTypeOptions = Object.keys($scope.visitPayrollTypesMap)
        .map((key, index) => ({ id: index, label: $scope.visitPayrollTypesMap[key], type: key }));

    $scope.billingPayrollDetailsFilters = {
        serviceCodes: [],
        visitTypes: [],
        isOldVisit: "",
        isOvertimeVisit: ""
    };

    const initialize = function () {
        if (!Object.keys($scope.patientsMap).length) {
            return;
        }

        // DECIDE WHETHER DRAFT OR SELECTED VISIT
        if ($stateParams.isDraft === true) {
            $scope.isDraft = true;
            if ($stateParams.payrollBatchWithVisits) {
                $scope.payrollBatch = $stateParams.payrollBatchWithVisits.payrollBatch;
                $scope.payrollItems = $stateParams.payrollBatchWithVisits.payrollItems;
                initSelectionLogic($scope.payrollItems);
                filterBillingPayrollTable();
            } else {
                $scope.payrollBatch = $stateParams.payrollBatch;
                loadCaregiverPayrolls($stateParams.id);
            }
        } else {
            if ($stateParams.id) {
                loadCaregiverPayrolls($stateParams.id);
            }
        }
    }

    const initSelectionLogic = function (data) {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        });

        data.forEach(pv => {
            $scope.selectionLogic.initItem(pv);
        });

        $scope.selectionLogic.data.areAllItemsSelected = true;
        $scope.selectionLogic.toggleAllItems();
    };

    function loadCaregiverPayrolls(payrollBatchId) {
        $scope.isLoadingPayrollItems = true;
        const url = 'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/payroll_batches/' + payrollBatchId;
        DatabaseApi.get(url).then(function (res) {
            $scope.payrollBatch = res.data.payrollBatch;
            $scope.payrollItems = res.data.payrollItems;
            $scope.isDraft = $scope.payrollBatch.isDraft;
            initSelectionLogic($scope.payrollItems);
            initBillingPayrollTable($scope.payrollItems);
            $scope.isLoadingPayrollItems = false;
        }, function (err) {
            $scope.payrollItems = [];
            $scope.isLoadingPayrollItems = false;
            toaster.pop('error', 'Something went wrong');
        });
    }

    $scope.toggleBtnGroupValue = (paramName, toggledButton) => {
        switch ($scope.billingPayrollDetailsFilters[paramName]) {
            case "": {
                $scope.billingPayrollDetailsFilters[paramName] = toggledButton;
                break;
            }
            case null: {
                $scope.billingPayrollDetailsFilters[paramName] = !toggledButton;
                break;
            }
            default: {
                $scope.billingPayrollDetailsFilters[paramName] = toggledButton === $scope.billingPayrollDetailsFilters[paramName] ? "" : null;
                break;
            }
        }
    }

    const filterByMethods = {
        payrollVisitHasServiceCode: (payrollVisit, selectedServiceCodes) => selectedServiceCodes.indexOf(payrollVisit.serviceCodeId) !== -1,
        payrollVisitHasVisitType: (payrollVisit, selectedVisitTypes) => selectedVisitTypes.indexOf(payrollVisit.type) !== -1,
        payrollVisitIsNewOrOld: (payrollVisit, isOldVisit) => payrollVisit.oldVisit === isOldVisit,
        payrollVisitIsOvertime: (payrollVisit, isOvertimeVisit) => payrollVisit.overtime === isOvertimeVisit
    };

    function filterBillingPayrollTable() {
        if (!$scope.payrollItems) return;
        let filters = [];

        const selectedServiceCodes = $scope.billingPayrollDetailsFilters.serviceCodes.map((sc) => sc.id);
        if (selectedServiceCodes && selectedServiceCodes.length > 0) {
            filters.push(function (payrollVisit) { return filterByMethods.payrollVisitHasServiceCode(payrollVisit, selectedServiceCodes); });
        }

        const selectedVisitTypes = $scope.billingPayrollDetailsFilters.visitTypes.map((obj) => {
            return $scope.visitTypeOptions.find(function (type) { return type.id === obj.id; }).type;
        });
        if (selectedVisitTypes && selectedVisitTypes.length > 0) {
            filters.push(function (payrollVisit) { return filterByMethods.payrollVisitHasVisitType(payrollVisit, selectedVisitTypes); });
        }

        const isOldVisit = $scope.billingPayrollDetailsFilters.isOldVisit;
        if (isOldVisit !== null && isOldVisit !== "") {
            filters.push(function (payrollVisit) { return filterByMethods.payrollVisitIsNewOrOld(payrollVisit, isOldVisit); });
        }

        const isOvertimeVisit = $scope.billingPayrollDetailsFilters.isOvertimeVisit;
        if (isOvertimeVisit !== null && isOvertimeVisit !== "") {
            filters.push(function (payrollVisit) { return filterByMethods.payrollVisitIsOvertime(payrollVisit, isOvertimeVisit); });
        }

        let filteredPayrollItems = $scope.payrollItems;
        if (filters.length > 0) {
            filteredPayrollItems = filteredPayrollItems.filter(function (pv) {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](pv);
                }
                return isFiltered;
            });
        }

        initBillingPayrollTable(filteredPayrollItems);
    }

    function initBillingPayrollTable(items) {
        items.forEach(pv => {
            const contract = $scope.contracts.find(c => c.id === pv.contractTypeId);
            if (contract) {
                pv.contractName = contract.name;
            }

            const serviceCode = $scope.serviceCodes.find(s => s.id === pv.serviceCodeId);
            if (serviceCode) {
                pv.serviceCodeName = serviceCode.code;
            }

            const patient = $scope.patientsMap[pv.patientId];
            pv.patient = patient;
            pv.patientFullName = patient !== undefined ? patient.displayName : '';
            pv.typeText = $scope.visitPayrollTypesMap[pv.type];
            const caregiver = $scope.caregiversMap[pv.caregiverId];
            pv.caregiver = caregiver;
        });

        const options = {
            count: 25
        };

        $scope.billingPayrollTable = new NgTableParams(options, {
            counts: [10, 25, 50, 100],
            dataset: items
        });
    };

    $scope.applyBillingPayrollGlobalSearch = function (term) {
        const filter = { $: term };
        if ($scope.billingPayrollTable) {
            angular.extend($scope.billingPayrollTable.filter(), filter);
        }
    };

    $scope.$watch('billingPayrollDetailsFilters', function () {
        filterBillingPayrollTable();
    }, true);

    const visitsServiceSet = new Set(["HOURLY", "LIVE-IN"]);

    function getTempRateV4(cPayrolls) {
        if (visitsServiceSet.has(cPayrolls.service) && cPayrolls.regularOvertime === "OVERTIME") {
            return (cPayrolls.rate / 1.5).toFixed(2);
        }
        return cPayrolls.rate;
    }

    function getRegHoursV4(cPayrolls) {
        if (visitsServiceSet.has(cPayrolls.service) && cPayrolls.regularOvertime === "REGULAR") {
            return cPayrolls.hoursAmount;
        }
        return "";
    }

    function getOTHoursV4(cPayrolls) {
        if (visitsServiceSet.has(cPayrolls.service) && cPayrolls.regularOvertime === "OVERTIME") {
            return cPayrolls.hoursAmount;
        }
        return "";
    }

    function getHours3CodeV4(cPayrolls) {
        if (visitsServiceSet.has(cPayrolls.service)) {
            return "";
        }
        return cPayrolls.service;
    }

    function getHours3AmountV4(cPayrolls) {
        if (visitsServiceSet.has(cPayrolls.service)) {
            return "";
        }
        return cPayrolls.hoursAmount;
    }

    $scope.downloadExcel = function (exportFileType) {
        $scope.isLoadingDownloadExcel = true;
        let caregiverPayrolls;
        let url = 'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/payroll_batches/' + $scope.payrollBatch.id +
            '/payroll_report_file';
        DatabaseApi.get(url).then(function (res) {
            const lines = res.data.lines;
            let csvContent = "";
            lines.forEach(function (row) {
                csvContent += row + "\r\n";
            });
            const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            if (exportFileType === "csv") {
                link.setAttribute("download", "medflyt-payroll-visits-export.csv");
            } else if (exportFileType === "xls") {
                link.setAttribute("download", "medflyt-payroll-visits-export.xls");
            }
            document.body.appendChild(link);

            link.click();

            $scope.isLoadingDownloadExcel = false;
        }, function (err) {
            $scope.isLoadingDownloadExcel = false;
            toaster.pop('error', 'Something went wrong');
        });
    }

    $scope.onCaregiverClick = function (caregiverId, modal) {
        $rootScope.openCaregiverModal(caregiverId);
    }

    $scope.preOpenPatientModal = function (patientId) {
        if (!patientId) return;
        $rootScope.openPatientModal(patientId);
    }

    $scope.secondsToLabel = function (seconds) {
        if (!seconds && seconds !== 0) return '';
        const duration = moment.duration(Math.abs(seconds), 'seconds');
        const hours = Math.floor(duration.asHours());
        if (hours > 0) duration.subtract(hours, "hours");
        let minutes = Math.floor(duration.asMinutes());
        if (minutes < 10) minutes = '0' + minutes;
        const sign = Math.sign(seconds) === -1 ? '-' : '';
        return `${sign}${hours}:${minutes} hr`;
    }

    $scope.getSelectedCount = () => {
        if (!Boolean($scope.selectionLogic)) {
            return "Loading...";
        }
        return $scope.selectionLogic.countSelected();
    }

    $scope.confirmPayrollBatch = () => {
        const modal = mfModal.create({
            subject: "Payroll Batch",
            message: `Are you sure you want to confirm current payroll batch?`,
            cancelLabel: "Cancel",
            confirmLabel: "Yes, Confirm",
            onConfirm: () => {
                modal.setLoading(true);

                const selectedItems = $scope.selectionLogic.getSelectedItems().map(i => i.id);
                const exludedItems = $scope.payrollItems
                    .filter(pv => !selectedItems.includes(pv.id));
                const body = {
                    excludedPayrollItems: exludedItems
                }

                const url = wildcard(
                    "agencies/:agencyId/agency_members/:agencyMemberId/payroll_batches/:payrollBatchId",
                    $rootScope.agencyId,
                    $rootScope.agencyMemberId,
                    $scope.payrollBatch.id
                );

                $scope.isConfirmLoading = true;
                DatabaseApi.put(url, body).then(function (res) {
                    loadCaregiverPayrolls($scope.payrollBatch.id);
                    $scope.isConfirmLoading = false;
                    clearFilters();
                    toaster.pop("success", "Payroll batch confirmed successfully");
                    modal.close();
                }, function (err) {
                    $scope.isConfirmLoading = false;
                    toaster.pop('error', 'Failed to confirm payroll batch');
                }).finally(() => {
                    modal.update({ isLoading: false });
                });
            }
        });
    }

    $scope.handlePayrollItemClick = (item) => {
        switch(item.type) {
            case 'VISIT': {
                $rootScope.openVisitInstanceModal(item.visitInstanceId)
                break;
            }
            case 'PTO': {
                const url = wildcard(
                    "agencies/:agencyId/agency_members/:agencyMemberId/payroll_pto/:payrollPtoId",
                    $rootScope.agencyId,
                    $rootScope.agencyMemberId,
                    item.id // AS payroll_pto_id
                  );
                DatabaseApi.get(url).then(function (res) {
                    const ptoInstance = res.data;

                    const patient = $scope.patientsMap[ptoInstance.visitInstance.patientId];
                    const ptoLabel = $scope.caregiverPTOLabels.find(ptoLabel => ptoLabel.id === ptoInstance.labelId);
                    const newScope = $scope.$new(true);

                    newScope.ptoEventForm = ptoInstance;
                    newScope.ptoEventForm.pto = ptoInstance;
                    newScope.ptoEventForm.visitInstance.durationInMinutes = ptoInstance.duration;
                    const startTime = newScope.ptoEventForm.visitInstance.startTime;
                    const endTime = newScope.ptoEventForm.visitInstance.endTime;
                    newScope.ptoEventForm.visitInstance.originalStart = moment(startTime.toJSON ? startTime.toJSON() : startTime);
                    newScope.ptoEventForm.visitInstance.originalEnd = moment(endTime.toJSON ? endTime.toJSON() : endTime);
                    newScope.ptoEventForm.label = ptoLabel ? { id: ptoLabel.id, text: ptoLabel.labelText } : '';
                    newScope.ptoEventForm.visitInstance.titleName = patient !== undefined ? patient.displayName : '';
                    newScope.visitInstances = [ptoInstance.visitInstance];
                    newScope.viewModeOnly = true;
                    newScope.caregiverId = ptoInstance.caregiverId;

                    const modalInstance = $uibModal.open({
                      templateUrl: "admin/views/visit-instance-pto-modal.html",
                      size: "md",
                      controller: "visitInstancePTOModalCtrl",
                      windowTopClass: "PTO-modal",
                      scope: newScope,
                    });
                }, function (err) {
                    toaster.pop('error', 'Something went wrong', 'Could not open caregiver pto instance');
                });
                break;
            }
            default: return;
        }
    }

    $rootScope.$on("got_patients_data", function (event) {
        if ($scope.gotPatientData) return;
        $scope.gotPatientData = true
        $scope.patientsMap = DatabaseApi.patients();
        initialize();
    });


    $rootScope.$on("got_caregivers_data", function (event) {
        if ($scope.gotCaregiverData) return;
        $scope.gotCaregiverData = true
        $scope.caregiversMap = DatabaseApi.caregivers();
        initialize();
    });

    $rootScope.$on("got_contract_types", function (event) {
        $scope.contracts = DatabaseApi.contractTypes();
    });

    $rootScope.$on("got_service_codes", function (event) {
        $scope.serviceCodes = DatabaseApi.activeServiceCodes();
        $scope.serviceCodesOptions = $scope.serviceCodes.map(sc => ({ id: sc.id, label: sc.code }));
    });

    $rootScope.$on("got_pto_labels", function (event) {
        $scope.caregiverPTOLabels = DatabaseApi.caregiverPtoLabels();
    });

    $scope.caregiversMap = DatabaseApi.caregivers();
    $scope.patientsMap = DatabaseApi.patients();
    $scope.caregiverPTOLabels = DatabaseApi.caregiverPtoLabels();

    const clearFilters = () => {
        $scope.billingPayrollDetailsFilters = {
            serviceCodes: [],
            visitTypes: [],
            isOldVisit: "",
            isOvertimeVisit: ""
        };
    }

    initialize();
});