app.controller('orientationItemPreviewModalCtrl', function($scope, $rootScope, Consts, $http, $state, $uibModalInstance, $timeout, $sce, $interval, videoData, type, bundleData, itemData, lang, DatabaseApi, toaster, forSelection, existingGroups){

    //console.log('modal');
    //console.log(videoData);
    //console.log(type);
    //console.log(bundleData);
    //console.log(itemData);
    //console.log(lang);
    //console.log(existingGroups);
    //console.log('modal x');

    $scope.forSelection = forSelection;
    $scope.selecting = false;
    $scope.lang = lang;
    $scope.type = type;
    $scope.bundleData = bundleData;
    $scope.existingGroups = existingGroups;

    $scope.groupAlreadyExists = $scope.existingGroups.filter(function(group) {         
        return group.translatedTitles[lang] == $scope.bundleData.translatedTitles[lang];
    }).length > 0;

    $scope.closeModal = function(){
        $uibModalInstance.close('ok');
    };

    $scope.selectItem = function(data, newBundle) {
        $scope.selecting = true;

        var url = 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/orientation_library_item/' + itemData.id + '/copy';

        var body = {
            bundleId: newBundle ? null : data.id
        };

        DatabaseApi.post(url, body).then(function(res){
            $scope.selecting = true;
            toaster.pop('success','Item Selected',"");
            $scope.closeModal();
        }, function (err) {
            $scope.selecting = true;
            toaster.pop('error','Something went wrong', err.data);
        });
    };

    //console.log(videoData);
    $scope.start = false;


    if(type === 'video') {

        if (videoData.videoUrl && typeof videoData.videoUrl === 'string'){
            var srcUrl = videoData.videoUrl;
            $scope.poster = $sce.trustAsResourceUrl(videoData.imageUrl);
        } else {

            if (videoData.videoUrl && videoData.videoUrl[0]) {
                var srcUrl = videoData.videoUrl[0].url;
            }
            if (videoData.thumbnailUrl && videoData.thumbnailUrl[0]) {
                $scope.poster = $sce.trustAsResourceUrl(videoData.thumbnailUrl[0]);
            }
        }
    } else if(type === 'youtube') {
        if (videoData.videoUrl && typeof videoData.videoUrl === 'string'){
            var url = videoData.videoUrl.split('v=')[1];
            $scope.youtubeKey = url;
        } else {


            if (videoData.videoUrl && videoData.videoUrl[0]) {
                var url = videoData.videoUrl[0].url.split('v=')[1];
                $scope.youtubeKey = url;

            }
        }
    } else if(type === 'vimeo') {
        if (videoData.videoUrl && typeof videoData.videoUrl === 'string'){
            var parts = videoData.videoUrl.split('/');
            var url = parts[parts.length-1];
            $scope.vimeoKey = url;
        } else {

            if (videoData.videoUrl && videoData.videoUrl[0]) {
                var parts = videoData.videoUrl[0].url.split('/');
                var url = parts[parts.length-1];
                $scope.youtubeKey = url;

            }
        }
    } else if(itemData.showDocIframe) {
        if (videoData.videoUrl && typeof videoData.videoUrl === 'string') {
            $scope.embedSource = videoData.videoUrl;
           
        } else {
            if (videoData.videoUrl && videoData.videoUrl[0]) {
                $scope.embedSource = videoData.videoUrl[0].url;
            }
        }

        $scope.start = true;

        $http.get($scope.embedSource,     
            {responseType:'arraybuffer'})
            .success(function (response) {
                var file = new Blob([(response)], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                $scope.content = $sce.trustAsResourceUrl(fileURL);
            });
    }


    $scope.title = videoData.title || 'Preview';

    $scope.videoData = videoData;
    $scope.itemData = itemData;
    var interval = $interval(timer, 100);

    function loadCss(callback){

        var css = document.createElement("link");
        css.rel = "stylesheet";
        css.onload=callback;
        document.getElementsByTagName("head")[0].appendChild(css);
        css.href = 'https://unpkg.com/plyr@3.4.8/dist/plyr.css';
    }

    $scope.loadedCss = false;
    loadCss(function () {
        $scope.loadedCss = true;
    });

    function timer() {
        if($scope.start){
            stopInterval();
            return;
        }
        if (window.Plyr && window.Hls && $scope.loadedCss) {
            $scope.start = true;

            $timeout(function () {

                if(type === 'video') {
                    var video = document.getElementById('video-preview');


                    var player = new window.Plyr(video, {captions: {active: true, update: true, language: 'en'}});

                    if (!window.Hls.isSupported()) {
                        toaster.pop('error', 'Cannot play video');
                    } else {
                        var hls = new window.Hls();
                        hls.loadSource(srcUrl);
                        hls.attachMedia(video);
                        window.hls = hls;
                    }
                } else if(type === 'youtube'){
                    var player = new window.Plyr('#video-preview-youtube', {});
                } else if(type === 'vimeo'){
                    var player = new window.Plyr('#video-preview-vimeo', {});
                }

            }, 1000);
            stopInterval();
        }
    }
    function stopInterval() {
        $interval.cancel(interval);
    }

});
