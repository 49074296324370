app.directive('globalSearch', ['$rootScope', '$state', 'DatabaseApi', 'Storage', '$uibModal', function ($rootScope, $state, DatabaseApi, Storage, $uibModal) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/global-search.html',
        link: function ($scope, elem, attr) {
            $scope.searchVal = '';
            $scope.results = {
                patients: [],
                caregivers: [],
                visits: [],
                invoices: []
            };
            $scope.resultsPreview = [];
            $scope.isSearching = false;
            $scope.searchDisplayFieldSelection = { val: "" };
            $scope.patientDisplayFieldsOptions = [
                "id",
                "first_name",
                "middle_name",
                "last_name",
                "address",
                "phone_numbers",
                "ssn",
                "member_id",
                "medicaid_number",
                "medicare_number",
                "office"
            ];
            $scope.caregiverDisplayFieldsOptions = [
                "id",
                "first_name",
                "middle_name",
                "last_name",
                "address",
                "ssn",
                "phone_numbers",
                "offices"
            ];
            $scope.defaultSearchFilters = {
                filters: {
                    patient: {
                        id: true,
                        first_name: true,
                        middle_name: true,
                        last_name: true,
                        address: true,
                        phone_numbers: true,
                        ssn: true,
                        member_id: true,
                        medicaid_number: true,
                        medicare_number: true,
                    },
                    caregiver: {
                        id: true,
                        first_name: true,
                        middle_name: true,
                        last_name: true,
                        address: true,
                        phone_numbers: true,
                        ssn: true,
                    },
                },
                displayFields: {
                    patient: ["first_name", "last_name", "office"],
                    caregiver: ["first_name", "last_name", "offices"],
                },
        };
        $scope.searchFilters = angular.copy($scope.defaultSearchFilters);

        const filtersFromStorage = Storage.getObject("searchFiltersSettings");
        if (Object.keys(filtersFromStorage).length) {
            $scope.searchFilters = JSON.parse(filtersFromStorage);
        }

        $scope.$watch(
            "searchFilters",
            function () {
                if ($scope.searchFilters) {
                    Storage.setObject("searchFiltersSettings", JSON.stringify($scope.searchFilters));
                }
            },
            true
        );

        $scope.resetAllSearchFilters = () => {
            $scope.searchFilters = angular.copy($scope.defaultSearchFilters);
            Storage.delete("searchFiltersSettings");
        };

        $scope.displayFieldSelected = (filterType) => {
            if ($scope.searchDisplayFieldSelection.val !== "") {
                $scope.searchFilters.displayFields[filterType].push($scope.searchDisplayFieldSelection.val);
                $scope.searchDisplayFieldSelection.val = "";
            }
        };

        $scope.removeDisplayField = (filterType, displayField) => {
            const filteredDisplayFields = $scope.searchFilters.displayFields[filterType].filter((field) => field !== displayField);
            $scope.searchFilters.displayFields[filterType] = filteredDisplayFields;
        };

        $scope.navbarSearchFocus = function () {
            $scope.isNavbarSearchFocused = true;
        };

        $scope.navbarSearchBlur = function () {
            $scope.isNavbarSearchFocused = false;
        };

        $scope.selectedIndex = -1;
        $scope.resultsPreviewAmount = 7;

        $scope.searchKeyUp = function (event) {
            var resultsAmount = $rootScope.showBillingFeature ? $scope.resultsPreview.length : $scope.resultsPreview.length - $scope.results.invoices.length;
            switch (event.key) {
                case 'Enter': {
                    if ($scope.selectedIndex === -1 ||
                        resultsAmount < $scope.resultsPreviewAmount && $scope.selectedIndex === resultsAmount ||
                        resultsAmount >= $scope.resultsPreviewAmount && $scope.selectedIndex === $scope.resultsPreviewAmount) {
                        $scope.searchFullResults($scope.searchVal);
                    } else {
                        $scope.resultPreviewClick($scope.resultsPreview[$scope.selectedIndex]);
                    }

                    break;
                }
                case 'Escape': {
                    $scope.searchVal = '';
                    event.target.blur();
                    $scope.selectedIndex = -1;
                    break;
                }
                case 'ArrowDown': {
                    if ($scope.selectedIndex < $scope.resultsPreviewAmount && $scope.selectedIndex < resultsAmount) {
                        $scope.selectedIndex++;
                    }
                    break;
                }
                case 'ArrowUp': {
                    if ($scope.selectedIndex > -1) {
                        $scope.selectedIndex--;
                    }
                    break;
                }
                default: {
                    $scope.selectedIndex = -1;
                    break;
                }
            }
        };

        $scope.searchChange = function () {
            if ($scope.searchVal.length < 3) {
                $scope.resultsPreview = [];
            } else {
                search();
            }
        };
        $scope.getResultPerviewId = (resultPreview) => {
            switch (resultPreview.category) {
                case 'Patients':
                    return resultPreview.displayId;
                case 'Caregivers':
                    return resultPreview.displayId;
                case 'Visits':
                    return resultPreview.id;
                default:
                    return '';
            }
        };

        const getSearchQueryOptions = (filtersOptions) => {
            const result = [];
            Object.keys(filtersOptions).map(option => {
                const filterOption = filtersOptions[option];
                Object.keys(filterOption).map(filter => {
                    const filterValue = filterOption[filter];
                    if (filterValue) {
                        result.push(`${option}_${filter}=${filterValue}`);
                    }
                })
            });
            if (result.length === 0) {
                return "";
            }
            return "&" + result.join("&")
        }

        const search = function () {
            const searchQueryOptions = getSearchQueryOptions($scope.searchFilters.filters);
            $scope.isSearching = true;
            const url = `agencies/${$rootScope.agencyId}/search?text=${$scope.searchVal}${searchQueryOptions}`;
            DatabaseApi.get(url).then(function (res) {
                $scope.results = res.data;
                $scope.resultsPreview = $scope.results.patients.map(function (p) {
                    p.category = 'Patients';
                    p.displayValue = getResultDisplayValue('Patients', p);

                    return p;
                }).concat($scope.results.caregivers.map(function (c) {
                    c.category = 'Caregivers';
                    c.displayValue = getResultDisplayValue('Caregivers', c);
                    return c;
                })).concat($scope.results.visits.map(function (v) {
                    v.category = 'Visits';
                    v.displayValue = v.id;
                    return v;
                })).concat($scope.results.invoices.map(function (i) {
                    i.category = 'Payment claim';
                    i.displayValue = i.displayId;
                    return i;
                }));
                $scope.isSearching = false;
            }, function (err) {
                $scope.isSearching = false;
            });
        };

        const getResultMiddleName = function (result) {
            return result.middleName ?
                result.firstName + ' ' + result.middleName + ' ' + result.lastName :
                result.firstName + ' ' + result.lastName;
        };

        const getResultDisplayValue = (resultType, resultProfile) => {
            switch (resultType) {
                case "Caregivers":
                    const caregiverFields = $scope.searchFilters.displayFields.caregiver;
                    if (caregiverFields.length) {
                        return (caregiverFields.map((field) => {
                            switch (field) {
                                case "first_name":
                                    return resultProfile.firstName;
                                case "middle_name":
                                    return resultProfile.middleName;
                                case "last_name":
                                    return resultProfile.lastName;
                                case "phone_numbers":
                                    return resultProfile.phoneNumbers;
                                case "id":
                                    return resultProfile.displayId;
                                case "offices":
                                    return `(${resultProfile.officeNames.join(", ")})`;
                                case "address":
                                case "ssn":
                                default:
                                    return resultProfile[field];
                            }
                        })
                        .filter((field) => Boolean(field))
                        .join(" - ")
                    );
                    } else {
                        return ((resultProfile.displayId ? resultProfile.displayId + " - " : "") + getResultMiddleName(resultProfile) + ` (${resultProfile.officeNames.join(", ")})`);
                    }
                case "Patients":
                    const patientFields = $scope.searchFilters.displayFields.patient;
                    if (patientFields.length) {
                        return (patientFields.map((field) => {
                            switch (field) {
                                case "first_name":
                                    return resultProfile.firstName;
                                case "middle_name":
                                    return resultProfile.middleName;
                                case "last_name":
                                    return resultProfile.lastName;
                                case "phone_numbers":
                                    return resultProfile.phoneNumbers;
                                case "id":
                                    return resultProfile.displayId;
                                case "member_id":
                                    return resultProfile.memberId;
                                case "medicaid_number":
                                    return resultProfile.medicaIDNumber;
                                case "medicare_number":
                                    return resultProfile.medicareNumber;
                                case "office":
                                    return `(${resultProfile.officeName})`;
                                case "address":
                                case "ssn":
                                default:
                                    return resultProfile[field];
                            }
                        })
                        .filter((field) => Boolean(field))
                        .join(" - "));
                    } else {
                        return ((resultProfile.displayId ? resultProfile.displayId + " - " : "") + getResultMiddleName(resultProfile) + ` (${resultProfile.officeName})`);
                    }
                default:
                    return "";
            }
        };

        $scope.searchFullResults = function () {
            $state.go('app.globalSearch', {
                searchText: $scope.searchVal,
                results: $scope.results,
                resultsPreview: $scope.resultsPreview
            });
            $scope.resetSearch();
        }

        $scope.resetSearch = function () {
            $scope.searchVal = '';
            $scope.resultsPreview = [];
            $scope.results = {
                patients: [],
                caregivers: [],
                visits: [],
                invoices: []
            };
        };

        $scope.getPermissionKeyByCategory = (category) => {
            switch (category) {
                case 'Patients':
                    return 'view_patient_search_result';
                case 'Caregivers':
                    return 'view_caregiver_search_result';
                case 'Visits':
                case 'Payment claim':
                default:
                    return '';
            }
        };

        $scope.getIconSrcByCategory = function (category) {
            switch (category) {
                case 'Patients':
                    return 'admin/images/icons/patient.svg';
                case 'Caregivers':
                    return 'admin/images/icons/caregiver.svg';
                case 'Visits':
                    return 'admin/images/icons/visit.svg';
                case 'Payment claim':
                    return 'admin/images/icons/payment-claim.svg';
                default: return '';
            }
        };

        $scope.resultPreviewClick = function (resultPreview) {
            // $scope.resetSearch();
            switch (resultPreview.category) {
                case 'Patients': {
                    var forceModal = true;
                    $rootScope.openPatientModal(resultPreview.id, forceModal);
                    break;
                }
                case 'Caregivers': {
                    var forceModal = true;
                    $rootScope.openCaregiverModal(
                        resultPreview.id,
                        undefined,
                        forceModal
                    );
                    break;
                }
                case 'Visits': {
                    var url = 'agencies/' + $rootScope.agencyId +
                        '/coordinator/' + $rootScope.agencyMemberId +
                        '/visits/' + resultPreview.id;
                    DatabaseApi.get(url).then(function (res) {
                        const visit = res.data;
                        $rootScope.openVisitModal({ visit });
                    }, function (err) {

                    });
                    break;
                }
                case 'Payment claim': {
                    var url = 'agencies/' + $rootScope.agencyId +
                        '/agency_members/' + $rootScope.agencyMemberId +
                        '/invoices/' + resultPreview.id;
                    DatabaseApi.get(url).then(function (res) {
                        $rootScope.openInvoiceModal({ invoiceId: resultPreview.id });
                    }, function (err) {

                    });
                    break;
                }
                default: break;
            }

            $scope.navbarSearchBlur();
        };

        $('#global-search').on({
            focus: function () {
                $('.navbar-search').addClass('global-search-focused');
        },

            blur: function () {
                $('.navbar-search').removeClass('global-search-focused');
            },
        });

        $scope.$on('showBillingFeature_updated', function () {
            if ($rootScope.showBillingFeature) {
                $scope.showBillingFeature = true;
            }
        });

        if (elem[0].firstElementChild !== null) {
            elem[0].firstElementChild.addEventListener('animationend', function () {
                if ($rootScope.isNavbarLoaded !== true) {
                    $rootScope.isNavbarLoaded = true;
                }
            });
            }
        }
    };
  }
]);
