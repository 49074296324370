"use strict";
angular.module("dashboard").component('mfNumberRange', {
    templateUrl: 'admin/views/mf-number-range.html',
    bindings: {
        descriptionText: '@',
        min: '<',
        max: '<',
        value: '=',
        valueFilter:'@'
    },
    controller:  function($scope, $filter) {
        this.customFilter = (input) => {
            return  $scope.$ctrl.valueFilter ? $filter($scope.$ctrl.valueFilter)(input) : input;
        };
    }
});