"use strict";
angular.module("dashboard").component("entityCustomFieldValueComponent", {
  templateUrl: "admin/views/entity-custom-field-value-component.html",
  bindings: {
    customField: "<",
    initData: "<",
  },
  controller: function ($rootScope, DatabaseApi) {
    this.initOptions = () => {
      if (this.initData) {
        this.caregiversMap = DatabaseApi.caregivers();
        this.patientsMap = DatabaseApi.patients();

        if (this.customField) {
          switch (this.customField.fieldType) {
            case "TEXT":
            case "NUMBER":
            case "ADDRESS":
            case "PHONE_NUMBER":
            case "ENUM":
            case "BOOLEAN":
              this.customField.value = this.customField.value;
              break;
            case "DATE":
              this.customField.date = new Date(
                moment(this.customField.value).format("MM/DD/YYYY")
              );
              break;
            case "DATE_RANGE":
              if (!this.customField.range) {
                this.customField.range = {};
              }
              this.customField.from = new Date(
                moment(this.customField.from).format("MM/DD/YYYY")
              );
              this.customField.to = new Date(
                moment(this.customField.to).format("MM/DD/YYYY")
              );
              this.customField.range.startDate = this.customField.from;
              this.customField.range.endDate = this.customField.to;
              break;
            case "CAREGIVER":
              if (this.customField.value.displayName === undefined && Object.keys(this.caregiversMap).length > 0) {
                this.customField.value = this.caregiversMap[
                  this.customField.value
                ];
              }
              break;
            case "PATIENT":
              if (this.customField.value.displayName === undefined && Object.keys(this.patientsMap).length > 0) {
                this.customField.value = this.patientsMap[
                  this.customField.value
                ];
              }
              break;
            default:
              break;
          }
        }
      }
    };

    this.$onInit = () => {
      this.initOptions();
    };

    $rootScope.$on("got_data", () => {
      this.initOptions();
    });
  },
});
