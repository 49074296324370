"use strict";

angular.module("dashboard").component("entityNewVisitComponent", {
	templateUrl: "admin/views/new-visit/entity-new-visit.html",
	bindings: {
		isEditMode: '<'
	},
	controller: function (
		$scope,
		$rootScope,
		entityNewVisitModalService,
		DatabaseApi,
		GoogleAddressService,
		toaster,
		SweetAlert,
		Storage,
		$uibModal,
		visitConsts,
		NgTableParams,
		wildcard,
		mfModal,
		$q,
		$filter,
		$timeout,
		VisitInstanceModalService,
		generalUtils
	) {
		const SPECIFIC_DAYS_MULTISHIFTS_FEATURE = false; // mobile is not supporting yet

		function initialize() {
			$rootScope.isNewVisitSideModalOpen = true;
			$rootScope.$emit("open_new_visit_modal");
			initDefaultVisitForm();
			$scope.caregiversMap = DatabaseApi.caregivers() || {};
			checkCaregiversState();
			initCoordinators();

			$scope.editModeToggle = {
				contractAndPayment: false,
				contract: false,
				serviceCode: false,
				payrollCode: false,
				certification: false,
				label: false,
				requiredForms: false,
				price: false,
				gender: false,
				language: false,
				patientInfo: false,
				patientPhoneNumber: false,
				patientAlternativePhone: false,
				patientAddress: false,
				generalNotes: false,
				caseDetails: false,
				assignedCoordinators: false
			};
			if ($scope.$ctrl.isEditMode) {
				initEditModeData();
			} else {
				initNewVisitModeData();
			}
		}

		const initNewVisitModeData = () => {
			$scope.modalData = entityNewVisitModalService.newVisitData;
			initPatient();
			$scope.calendarDate = $scope.modalData.calendarDate;

			$scope.form.disableDays = false;

			initCertifications();
			initPatientInfo();
			initForms();
			initLabels();
			initTimeSpan();
			initLanguages();
			initMap();
			$scope.updateEnableCaregivers();

			initPatientVisitForm();
		};

		const initEditModeData = () => {
			initEditModeDataFromService();

			getSelectedVisits();
			initPatient();
			initCertifications();
			initPatientInfo();
			initForms();
			initLabels();
			initTimeSpan();
			initLanguages();
			initMap();
			initPatientVisitForm();

			$scope.serviceCodes = DatabaseApi.activeServiceCodes().map(sc => ({
				...sc,
				label: `${sc.code} (${sc.certification})`
			}));

			$scope.payrollCodes = DatabaseApi.payrollCodes();

			if ($scope.visitInstances) {
				initMultipleValues();
			} else {
				fetchVisitInstances();
			}
		};

		const initPatient = () => {
			$scope.patient = DatabaseApi.getPatientById($scope.modalData.patientId);
			$scope.patient.contracts = $scope.modalData.patientContracts;
			$scope.patient.mainLanguage = $scope.modalData.patientMainLanguage;
			$scope.patient.secondaryLanguage = $scope.modalData.patientSecondaryLanguage;
			$scope.patient.phoneNumbers = $scope.modalData.patientPhoneNumbers;
			$scope.patient.vacations = $scope.modalData.patientVacations;
			$scope.patient.authorizations = $scope.modalData.patientAuthorizations;
		};

		const initDefaultVisitForm = () => {
			$scope.form = {
				contract: {},
				serviceCode: {},
				payrollCode: {},
				certifications: [],
				labels: [],
				forms: [],
				price: undefined,
				priceType: null,
				genders: [],
				languages: [],
				patientName: "",
				patientPhone: "",
				patientAlternativePhone: "",
				patientAddressComponents: null,
				newGoogleAddressComponents: null,
				patientAddress: "",
				patientAddress2: null,
				patientAddress2Model: null,
				generalNotes: "",
				caseDetails: "",
				assignedCoordinatorsModel: [],
				visitType: "Single",
				datePicker: {
					date: {
						startDate: new Date(),
						endDate: new Date()
					}
				},
				daysOfTheWeek: {},
				isDifferentDayTimes: false,
				shifts: 1,
				shiftsPerDays: SPECIFIC_DAYS_MULTISHIFTS_FEATURE ? [] : undefined,
				weeksDiff: 0,
				daysDiff: 0,
				disableDays: false,
				totalTimePerWeek: "4H",
				authorizations: [],
				useAuthorizationDates: false,
				showPatientAuthorization: false,
				usedAuthorizationRow: null,
				caregiver: null,
				enableCaregivers: {
					shifts: false,
					footer: false,
					broadcast: false,
					footerCanAssign: false,
					shiftsCanAssign: false,
				},
			};
		};

		const initVisitFormAsssignedCoordinators = () => {
			if (!$scope.coordinatorsOptions) return;

			$scope.form.assignedCoordinatorsModel = $scope.coordinatorsOptions.filter(coord =>
				(coord.id === $scope.patient.assignedCoordinator) ||
				(coord.id === $rootScope.agencyMemberId)
			);
		};

		const initPatientInfo = () => {
			$scope.form.patientName = $scope.patient.firstName + " " + $scope.patient.lastName;

			if (!$scope.patient.phoneNumbers) {
				return;
			}

			const homePhone = $scope.patient.phoneNumbers.find(p => p.type === "TEL");
			const mobilePhone = $scope.patient.phoneNumbers.find(p => p.type === "MOBILE");
			if (homePhone && homePhone.phonenumber) {
				$scope.form.patientPhone = homePhone.phonenumber;
			}
			if (mobilePhone && mobilePhone.phonenumber) {
				$scope.form.patientAlternativePhone = mobilePhone.phonenumber;
			}
		};

		const initPatientVisitForm = () => {
			const langs = [];
			if ($scope.patient.mainLanguage) {
				langs.push($scope.patient.mainLanguage);
			}
			if (
				$scope.patient.secondaryLanguage &&
				$scope.patient.secondaryLanguage !== $scope.patient.mainLanguage
			) {
				langs.push($scope.patient.secondaryLanguage);
			}
			if (langs.length > 0) {
				$scope.form.languages = langs;
			} else {
				$scope.form.languages = ['English'];
			}

			$scope.form.genders = ["Female", "Male"];

			setVisitAddress($scope.patient.address);
			$scope.form.newGoogleAddressComponents = $scope.patient.address.text;
			if ($scope.patient.address2) {
				$scope.form.patientAddress2 = $scope.patient.address2;
				$scope.form.patientAddress2Model = $scope.patient.address2;
			}

			$scope.form.generalNotes = $scope.patient.generalNotes || "";
			$scope.form.caseDetails = $scope.patient.medicalNotes || "";

			initVisitFormAsssignedCoordinators();
		};

		const initCertifications = () => {
			const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
			$scope.certifications = activeAgencyCertifications
				.map(certificationItem => certificationItem.certification);
			initCertificationsOptions($scope.certifications);
		};

		const initCertificationsOptions = (certifications) => {
			$scope.certificationsOptions = [...certifications];
			$scope.form.certifications = [];
			if ($scope.certificationsOptions.length === 1) {
				$scope.form.certifications.push(certifications[0]);
				$scope.updateLabelsAndFormsOptions();
			}

			setRelevantCaregivers();
		};

		const initLabels = () => {
			$scope.labels = [];
			$scope.displayedLabels = [];
			$scope.visitLabelsData = DatabaseApi.visitLabelsData(false, true);
			if ($scope.visitLabelsData) {
				initVisitLabelData();
			}
		};

		const initForms = () => {
			if (!$scope.documentsOptions || $scope.documentsOptions.length === 0) {
				$scope.documentsOptions = $scope.getDocumentsOptions();
			}
			$scope.plansOfCareOptions = $scope.getPlansOfCare();
			$scope.formsOptions = $scope.documentsOptions.concat($scope.plansOfCareOptions);

			$scope.formsExtraSettings = {
				styleActive: true,
			};
		};

		const initTimeSpan = () => {
			initTimeSpanTabs();
			if ($scope.calendarDate) {
				const dateUtc = new Date(
					$scope.calendarDate.getUTCFullYear(),
					$scope.calendarDate.getUTCMonth(),
					$scope.calendarDate.getUTCDate()
				);
				$scope.form.datePicker.date.startDate = dateUtc;
				$scope.form.datePicker.date.endDate = dateUtc;
			}

			$scope.form.advancedTimeShiftsSingle = [
				$scope.getDefaultShift(0),
				$scope.getDefaultShift(1)
			];

			$scope.form.advancedTimeShiftsPermanent = [
				{ "Everyday": $scope.getDefaultShift(0) },
				{ "Everyday": $scope.getDefaultShift(1) }
			];

			$scope.advancedDays = visitConsts.advancedDays;
			$scope.times = visitConsts.times;

			if ($scope.form.shiftsPerDays !== undefined) {
				$scope.form.shiftsPerDays["Everyday"] = 1;
				$scope.advancedDays.forEach(day => $scope.form.shiftsPerDays[day.fullName] = 1);
			}

			const getAuthorizationsUrl = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/authorizations/:patientId",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				$scope.patient.id
			);
			DatabaseApi.get(getAuthorizationsUrl).then(res => {
				let authorizations = res.data.authorizations;
				if (!authorizations) authorizations = [];
				const todayEpochDay = LocalDate.now().toEpochDay();
				const getFormattedAuthDate = (authDate) => {
					const yearMonthDate = authDate.split("-");
					return `${yearMonthDate[1]}/${yearMonthDate[2]}/${yearMonthDate[0]}`;
				};
				authorizations = authorizations.map(auth => {
					auth.startDate = getFormattedAuthDate(auth.startDate);
					auth.endDate = getFormattedAuthDate(auth.endDate);
					$scope.advancedDays.forEach(day => {
						const dayMinutes = auth[day.fullName.toLowerCase() + "Minutes"];
						auth[day.fullName + "Hours"] = dayMinutes ? (dayMinutes / 60) : "X";
					});
					auth.hours = auth.minutes ? (auth.minutes / 60) : "X";
					auth.isUsed = false;
					return auth;
				}).filter(auth => {
					const endDateSplit = auth.endDate.split("/");
					const authEndDate = LocalDate.of(
						Number(endDateSplit[2]),
						Number(endDateSplit[0]),
						Number(endDateSplit[1])
					);
					return authEndDate.toEpochDay() >= todayEpochDay;
				});
				$scope.form.authorizations = authorizations;
				initAuthorizationsTable($scope.form.authorizations);
			}, () => {
				toaster.pop('error', "Something went wrong", "Could not get authorizations");
			});
		};

		const initLanguages = () => {
			$scope.languages = visitConsts.languages;
			$scope.showAllLangs = false;
		};

		const initMap = () => {
			$scope.map = { center: { latitude: 40.00, longitude: -99.00 }, zoom: 17 };
			$scope.marker = {
				id: 0,
				coords: {
					latitude: 40.00,
					longitude: -99.00,
				},
				options: { draggable: false }
			};
		};

		const initCoordinators = () => {
			$scope.coordinatorsExtraSettings = {
				styleActive: true,
				scrollable: true,
				scrollableHeight: "180px",
				enableSearch: true
			};

			$scope.coordinatorsEvents = {
				onSelectionChanged: function () {
					const coordsIds = $scope.form.assignedCoordinatorsModel
						.filter(coord => coord.id)
						.map(coord => coord.id);

					$scope.form.assignedCoordinatorsModel = $scope.form.assignedCoordinatorsModel
						.map(coordModel =>
							$scope.coordinatorsOptions.find(coord => coord.id === coordModel.id)
						);

					Storage.setObject("savedAssignedCoordinators", { list: coordsIds });
				}
			};

			initCoordinatorsOptions();
		};

		const initCoordinatorsOptions = () => {
			if (!$scope.gotAgencyMembers) {
				DatabaseApi.loadAgencyMembers();
				return;
			}

			const agencyMembers = angular.copy(DatabaseApi.getAllAgencyMembersArr());
			$scope.coordinatorsOptions = agencyMembers.filter(coord => coord.status === "Active");
		};

		$scope.isStartDateBeforeToday = () => {
			return moment($scope.form.datePicker.date.startDate).isBefore(
				moment().format('YYYY-MM-DD')
			);
		};

		$scope.toggleFormButton = (formField, clickedValue) => {
			if ($scope.form[formField].includes(clickedValue)) {
				$scope.form[formField] = $scope.form[formField].filter(val => val !== clickedValue);
			} else {
				$scope.form[formField].push(clickedValue);
			}

			switch (formField) {
				case "certifications":
					$scope.handleCertificationsChanged();
					break;
				case "labels":
					filterForms($scope.form.labels);
					break;
				default: break;
			}
		};

		const initVisitLabelData = () => {
			if ($scope.visitLabelsData === undefined || !$scope.visitLabelsData.activeLabels) {
				return;
			}

			$scope.labels = $scope.visitLabelsData.activeLabels.map(label => {
				const certifications = $scope.visitLabelsData.certificationsAssoc
					.filter(curr => curr.visitLabelId === label.id)
					.map(curr => curr.certification);

				let documents = [];
				if ($scope.formsOptions !== undefined) {
					documents = $scope.visitLabelsData.patientDocsAssoc.reduce((acc, curr) => {
						if (label.id === curr.visitLabelId) {
							const filteredDocument = $scope.formsOptions.filter(f =>
								f.id === curr.patientDocumentTypeId
							)[0];
							if (filteredDocument !== undefined) {
								acc.push({
									id: filteredDocument.id,
									title: filteredDocument.label
								});
							}
						}
						return acc;
					}, []);
				}

				return {
					id: label.id,
					name: label.name,
					color: label.color,
					hasPlanOfCare: label.hasPlanOfCare,
					certifications: certifications,
					documents: documents
				};
			});

			// init certification to visit labels
			$scope.certificationLabelMap = {};
			$scope.certifications.forEach(certification => {
				$scope.certificationLabelMap[certification] = $scope.labels.reduce((acc, curr) => {
					if (curr.certifications.includes(certification)) {
						acc.push({
							id: curr.id,
							name: curr.name,
							color: curr.color.substring(0, curr.color.length - 4) + '0.2)'
						});
					}
					return acc;
				}, []);
			});
		};

		$scope.handleCertificationsChanged = () => {
			$scope.updateLabelsAndFormsOptions();
			setRelevantCaregivers();
		};

		const getSelectedVisits = () => {
			$scope.selectedVisits = angular.copy(entityNewVisitModalService.selectedItems.visits);
			if (
				$scope.selectedVisits.length === 0 ||
				!$scope.$ctrl.isEditMode ||
				!$rootScope.isNewVisitSideModalOpen
			) {
				$scope.closeParentModal();
				return;
			}
			const lastVisit = $scope.selectedVisits[$scope.selectedVisits.length - 1];
			$scope.editUntilMinDate = moment(lastVisit.startTime);
			$scope.editUntilMaxDate = moment(lastVisit.visitBatchMaxEndDate);
			$scope.updateEnableCaregivers();
		};

		$scope.selectedShiftChanged = (shift, key, value) => {
			const visitIndex = $scope.selectedVisits.findIndex(v => v.key === shift.key);
			if (visitIndex >= 0) {
				$scope.selectedVisits[visitIndex][key] = value;
			}
		};

		const initTimeSpanTabs = () => {
			if ($scope.$ctrl.isEditMode === true) {
				if ($scope.editShiftsParams.type === 'CURRENT_SHIFTS') {
					$scope.activeTimeSpanTab = 0;
					$scope.singleTabDisabled = false;
					$scope.permanentTabDisabled = true;
				} else {
					$scope.activeTimeSpanTab = 1;
					$scope.singleTabDisabled = true;
					$scope.permanentTabDisabled = false;
				}
			} else {
				$scope.activeTimeSpanTab = 0;
				$scope.singleTabDisabled = false;
				$scope.permanentTabDisabled = false;
			}

			$rootScope.$broadcast('time-span-tabs-changes');
		};

		const getUniqueTime = (shifts, startend) => {
			let result = [];
			let hashes = [];

			shifts.forEach(shift => {
				const hash = shift[startend].format('ddddHH:mm');

				if (!hashes.includes(hash)) {
					hashes.push(hash);
					result.push(shift);
				}
			});

			return result;
		};

		const getUniqueValues = (items) => {
			let result = [];
			let hash = [];

			items.forEach(item => {
				const hashed = JSON.stringify(item);
				if (!hash.includes(hashed)) {
					result.push(item);
					hash.push(hashed);
				}
			});

			return result;
		};

		const getUniqueByKey = (obj, key) => {
			return getUniqueValues(obj.map(i => i[key]));
		};

		const getUniqueData = (visits) => {
			return {
				certifications: getUniqueByKey(visits, 'certifications'),
				visit_labels: getUniqueByKey(visits, 'visit_labels'),
				patient_contract_id: getUniqueByKey(visits, 'patient_contract_id'),
				service_code_id: getUniqueByKey(visits, 'service_code_id'),
				payroll_code_id: getUniqueByKey(visits, 'payroll_code_id'),
				gender: getUniqueByKey(visits, 'gender'),
				patient_phone_number: getUniqueByKey(visits, 'patient_phone_number'),
				patient_alternative_phone_number: getUniqueByKey(visits, 'patient_alternative_phone_number'),
				patient_address: getUniqueByKey(visits, 'patient_address'),
				patient_address2: getUniqueByKey(visits, 'patient_address2'),
				price: getUniqueByKey(visits, 'price'),
				payment_type: getUniqueByKey(visits, 'payment_type'),
				languages: getUniqueByKey(visits, 'languages'),
				general_notes: getUniqueByKey(visits, 'general_notes'),
				case_details: getUniqueByKey(visits, 'case_details'),
				assigned_coordinators: getUniqueByKey(visits, 'assigned_coordinators'),
				patient_document_ids: getUniqueByKey(visits, 'patient_document_ids'),
				plan_of_care_type_id: getUniqueByKey(visits, 'plan_of_care_type_id'),
				shiftsStartTimes: _.groupBy(getUniqueTime(visits.map(visit => {
					const mStartTime = moment(visit.start_time);
					return {
						dayOfWeek: mStartTime.format('dddd'),
						start_time: mStartTime
					};
				}), "start_time"), "dayOfWeek"),
				shiftsEndTimes: _.groupBy(getUniqueTime(visits.map(visit => {
					return {
						dayOfWeek: moment(visit.start_time).format('dddd'),
						end_time: moment(visit.end_time)
					};
				}), "end_time"), "dayOfWeek"),
				caregivers: getUniqueCaregivers(visits),
				hide_end_date_from_caregiver: getUniqueByKey(visits, 'hide_end_date_from_caregiver'),
			};
		};

		const getUniqueCaregivers = (visits) => {
			let result = {};
			for (const visit of visits) {
				const dayOfWeek = moment(visit.start_time).format('dddd');

				if (!(dayOfWeek in result)) {
					result[dayOfWeek] = [];
				}

				if (!result[dayOfWeek].includes(visit.caregiver_id)) {
					result[dayOfWeek].push(visit.caregiver_id);
				}
			}

			return result;
		};

		const fillFormDatesAndCaregivers = (form, mixedContent) => {
			form.datePicker = {
				date: {
					startDate: moment($scope.visitInstances[0].start_time).toDate(),
					endDate: moment($scope.visitInstances[$scope.visitInstances.length - 1].start_time).toDate(),
				},
			};

			form.caregiver = null;
			if ($scope.editShiftsParams.type === 'CURRENT_SHIFTS') {
				const caregivers = getUniqueValues($scope.selectedVisits.map(x => x.caregiver ? x.caregiver.id : null));
				if (caregivers.length === 1 && caregivers[0] !== null) {
					$scope.selectedVisits = $scope.selectedVisits.map(x => ({
						...x,
						caregiver: null,
					}));

					if ($scope.caregiversMap) {
						const caregiver = $scope.caregiversMap[caregivers[0]];
						if (caregiver) {
							form.caregiver = caregiver;
						}
					}
				}
			} else {
				$scope.selectedVisits = $scope.selectedVisits.map(x => ({
					...x,
					caregiver: null,
				}));
				const daysOfTheWeek = [...new Set([
					...Object.keys(mixedContent.shiftsStartTimes),
					...Object.keys(mixedContent.shiftsEndTimes)
				])];
				form.daysOfTheWeek = {};
				let caregivers = [];
				for (const dow of daysOfTheWeek) {
					form.daysOfTheWeek[dow] = true;

					form.advancedTimeShiftsPermanent[0][dow] = angular.copy($scope.getDefaultShift(0));

					let start = null, end = null;
					if (mixedContent.shiftsStartTimes[dow].length === 1) {
						start = mixedContent.shiftsStartTimes[dow][0].start_time;
						form.advancedTimeShiftsPermanent[0][dow].startTime = start.clone();
					} else {
						form.advancedTimeShiftsPermanent[0][dow].startTime = null;
					}
					if (mixedContent.shiftsEndTimes[dow].length === 1) {
						end = mixedContent.shiftsEndTimes[dow][0].end_time;
						form.advancedTimeShiftsPermanent[0][dow].endTime = end.clone();
					} else {
						form.advancedTimeShiftsPermanent[0][dow].endTime = null;
					}

					if (start && end) {
						const startMinutes = start.hour() * 60 + start.minute();
						const endMinutes = end.hour() * 60 + end.minute();
						let totalMinutes = endMinutes - startMinutes;
						let isOverNight = false;
						if (totalMinutes < 0) {
							totalMinutes += 24 * 60;
							isOverNight = true;
						}
						const hours = Math.floor(totalMinutes / 60);
						const minutes = totalMinutes - hours * 60;
						const hoursStr = hours > 0 ? `${hours}H` : '';
						const minutesStr = minutes > 0 ? `${minutes}M` : '';
						const splitter = hoursStr !== '' && minutesStr !== '' ? ':' : '';
						const durationStr = `${hoursStr}${splitter}${minutesStr}`;

						form.advancedTimeShiftsPermanent[0][dow].isOverNight = isOverNight;
						form.advancedTimeShiftsPermanent[0][dow].duration = durationStr;
						form.advancedTimeShiftsPermanent[0][dow].durationHours = hours;
						form.advancedTimeShiftsPermanent[0][dow].durationMinutes = minutes;
					} else {
						form.advancedTimeShiftsPermanent[0][dow].isOverNight = false;
						form.advancedTimeShiftsPermanent[0][dow].duration = "";
						form.advancedTimeShiftsPermanent[0][dow].durationHours = 0;
						form.advancedTimeShiftsPermanent[0][dow].durationMinutes = 0;
					}

					if (
						dow in mixedContent.caregivers
						&& mixedContent.caregivers[dow].length === 1
						&& mixedContent.caregivers[dow][0]
						&& $scope.caregiversMap
					) {
						const caregiver = Object.values($scope.caregiversMap).find(c => c.id === mixedContent.caregivers[dow][0]);
						if (caregiver) {
							form.advancedTimeShiftsPermanent[0][dow].caregiver = angular.copy(caregiver);
						}
					}

					caregivers.push(
						form.advancedTimeShiftsPermanent[0][dow].caregiver
						? form.advancedTimeShiftsPermanent[0][dow].caregiver.id
						: null
					);

					form.advancedTimeShiftsPermanent[0][dow].mixedContent = {
						startTimes: mixedContent.shiftsStartTimes[dow].length > 1,
						endTimes: mixedContent.shiftsEndTimes[dow].length > 1,
						caregivers: mixedContent.caregivers[dow].length > 1
					};
					form.advancedTimeShiftsPermanent[0][dow].editModeToggle = {
						startTime: false,
						endTime: false,
						caregiver: false,
					};
				}
				caregivers = getUniqueValues(caregivers);
				form.shifts = 1;

				if (caregivers.length === 1 && caregivers[0] !== null) {
					for (const dow of daysOfTheWeek) {
						form.advancedTimeShiftsPermanent[0][dow].caregiver = null;
					}

					if ($scope.caregiversMap) {
						const caregiver = Object.values($scope.caregiversMap).find(c => c.id === caregivers[0]);
						if (caregiver) {
							form.caregiver = caregiver;
						}
					}
				}

				form.advancedTimeShiftsPermanent[0]['Everyday'] = angular.copy(form.advancedTimeShiftsPermanent[0][daysOfTheWeek[0]]);

				let timesHashes = [];
				for (const dow of daysOfTheWeek) {
					const day = form.advancedTimeShiftsPermanent[0][dow];
					const startTime = day.startTime ? day.startTime.format('HH:mm') : "null";
					const endTime = day.endTime ? day.endTime.format('HH:mm') : "null";
					const caregiver = day.caregiver ? day.caregiver.id : 0
					const timesHash = `${startTime}-${endTime}-${caregiver}`;

					if (!timesHashes.includes(timesHash)) {
						timesHashes.push(timesHash);
					}
				}

				form.isDifferentDayTimes = timesHashes.length > 1 || ["CHOSEN_DAYS", "UNTIL_DATE"].includes($scope.editShiftsParams.type);
			}

			return form;
		};

		const fillFormCertifications = (form, mixedContent) => {
			if (mixedContent.certifications.length === 1 && mixedContent.certifications[0]) {
				form.certifications = mixedContent.certifications[0];
			} else {
				form.certifications = [];
			}

			return form;
		};

		const fillFormLabels = (form, mixedContent) => {
			if (mixedContent.visit_labels.length === 1 && mixedContent.visit_labels[0] && $scope.labels) {
				const labels = [];
				mixedContent.visit_labels[0].forEach(l => {
					const label = $scope.labels.find(i => i.name === l.text && i.color === l.color);
					if (label) {
						labels.push(label.id);
					}
				});
				form.labels = labels;
			} else {
				form.labels = [];
			}

			return form;
		};

		const fillFormContract = (form, mixedContent) => {
			if (mixedContent.patient_contract_id.length === 1 && mixedContent.patient_contract_id[0] && $scope.patient && $scope.patient.contracts) {
				const contract = $scope.patient.contracts.find(i => i.id === mixedContent.patient_contract_id[0]);
				if (contract) {
					form.contract = contract;
				} else {
					form.contract = {};
				}
			} else {
				form.contract = {};
			}

			return form;
		};

		const fillFormServiceCode = (form, mixedContent) => {
			if (mixedContent.service_code_id.length === 1 && mixedContent.service_code_id[0] && $scope.serviceCodes) {
				const serviceCode = $scope.serviceCodes.find(i => i.id === mixedContent.service_code_id[0]);
				if (serviceCode) {
					form.serviceCode = serviceCode;
				} else {
					form.serviceCode = {};
				}
			} else {
				form.serviceCode = {};
			}

			return form;
		};

		const fillFormPayrollCode = (form, mixedContent) => {
			if (mixedContent.payroll_code_id.length === 1 && mixedContent.payroll_code_id[0] && $scope.payrollCodes) {
				const payrollCode = $scope.payrollCodes.find(i => i.id === mixedContent.payroll_code_id[0]);
				if (payrollCode) {
					form.payrollCode = payrollCode;
				} else {
					form.payrollCode = {};
				}
			} else {
				form.payrollCode = {};
			}

			return form;
		};

		const fillFormRequiredForms = (form, mixedContent) => {
			if (
				mixedContent.patient_document_ids.length === 1
				&& mixedContent.patient_document_ids[0]
				&& $scope.formsOptions
				&& mixedContent.plan_of_care_type_id.length === 1
			) {
				const documents = [];
				mixedContent.patient_document_ids[0].forEach(i => {
					const document = $scope.formsOptions.find(o => o.id === i);
					if (document) {
						documents.push({ id: document.id });
					}
				});
				if (mixedContent.plan_of_care_type_id[0]) {
					const document = $scope.formsOptions.find(i => i.id === (-1 * mixedContent.plan_of_care_type_id[0]));
					if (document) {
						documents.push({ id: document.id });
					}
				}

				form.forms = documents;
			} else {
				form.forms = [];
			}

			return form;
		};

		const fillFormGender = (form, mixedContent) => {
			if (mixedContent.gender.length === 1 && typeof mixedContent.gender[0] !== 'undefined') {
				let genders = ['Female', 'Male'];
				if (mixedContent.gender[0] === 'M') {
					genders = ['Male'];
				} else if (mixedContent.gender[0] === 'F') {
					genders = ['Female'];
				}

				form.genders = genders;
			} else {
				form.genders = [];
			}

			return form;
		};

		const fillFormPatientInfo = (form, mixedContent) => {
			if (mixedContent.patient_phone_number.length === 1 && mixedContent.patient_phone_number[0]) {
				form.patientPhone = mixedContent.patient_phone_number[0].replace("+1", "");
			} else {
				form.patientPhone = "";
			}

			if (mixedContent.patient_alternative_phone_number.length === 1 && mixedContent.patient_alternative_phone_number[0]) {
				form.patientAlternativePhone = mixedContent.patient_alternative_phone_number[0].replace("+1", "");
			} else {
				form.patientAlternativePhone = "";
			}

			if (mixedContent.patient_address.length === 1 && mixedContent.patient_address[0]) {
				form.patientAddress = mixedContent.patient_address[0];
			} else {
				form.patientAddress = "";
			}

			if (mixedContent.patient_address2.length === 1 && mixedContent.patient_address2[0]) {
				form.patientAddress2 = mixedContent.patient_address2[0];
			} else {
				form.patientAddress2 = null;
			}

			return form;
		};

		const fillFormPrice = (form, mixedContent) => {
			if (mixedContent.price.length === 1 && mixedContent.price[0]) {
				form.price = mixedContent.price[0] / 100;
			} else {
				form.price = undefined;
			}

			if (mixedContent.payment_type.length === 1 && mixedContent.payment_type[0]) {
				form.priceType = mixedContent.payment_type[0] === 'Hourly' ? 'perHour' : 'perVisit';
			} else {
				form.priceType = null;
			}

			return form;
		};

		const fillFormLanguages = (form, mixedContent) => {
			if (mixedContent.languages.length === 1 && mixedContent.languages[0]) {
				form.languages = mixedContent.languages[0];
			} else {
				form.languages = [];
			}

			return form;
		};

		const fillFormGeneralNotes = (form, mixedContent) => {
			if (mixedContent.general_notes.length === 1 && mixedContent.general_notes[0]) {
				form.generalNotes = mixedContent.general_notes[0];
			} else {
				form.generalNotes = "";
			}

			return form;
		};

		const fillFormCaseDetails = (form, mixedContent) => {
			if (mixedContent.case_details.length === 1 && mixedContent.case_details[0]) {
				form.caseDetails = mixedContent.case_details[0];
			} else {
				form.caseDetails = "";
			}

			return form;
		};

		const fillFormAssignedCoordinators = (form, mixedContent) => {
			if (mixedContent.assigned_coordinators.length === 1 && mixedContent.assigned_coordinators[0] && $scope.coordinatorsOptions) {
				const assignedCoordinatorsModel = [];
				mixedContent.assigned_coordinators[0].forEach(i => {
					const item = $scope.coordinatorsOptions.find(o => o.id === i);
					if (item) {
						assignedCoordinatorsModel.push(item);
					}
				})
				form.assignedCoordinatorsModel = assignedCoordinatorsModel;
			} else {
				form.assignedCoordinatorsModel = [];
			}

			return form;
		};

		const getMixedContent = (uniqueData) => {
			return {
				contracts: uniqueData.patient_contract_id.length > 1,
				serviceCodes: uniqueData.service_code_id.length > 1,
				payrollCodes: uniqueData.payroll_code_id.length > 1,
				certifications: uniqueData.certifications.length > 1,
				labels: uniqueData.visit_labels.length > 1,
				requiredForms: (
					uniqueData.patient_document_ids.length > 1 ||
					uniqueData.plan_of_care_type_id.length > 1
				),
				price: (
					uniqueData.price.length > 1 ||
					uniqueData.payment_type.length > 1
				),
				gender: uniqueData.gender.length > 1,
				languages: uniqueData.languages.length > 1,
				patientPhoneNumber: uniqueData.patient_phone_number.length > 1,
				patientAlternativePhone: uniqueData.patient_alternative_phone_number.length > 1,
				patientAddress: (
					uniqueData.patient_address.length > 1 ||
					uniqueData.patient_address2.length > 1
				),
				generalNotes: uniqueData.general_notes.length > 1,
				caseDetails: uniqueData.case_details.length > 1,
				assignedCoordinators: uniqueData.assigned_coordinators.length > 1,
			};
		};

		const fetchVisitInstances = () => {
			entityNewVisitModalService.fetchAllVisits(
				$scope.patient.id,
				$scope.editShiftsParams,
				$scope.selectedVisits
			).then(({data}) => {
				$scope.visitInstances = data.visits;
				initMultipleValues();
			});
		};

		const initMultipleValues = () => {
			const uniqueData = getUniqueData($scope.visitInstances);
			$scope.mixedContent = getMixedContent(uniqueData);

			if (!Array.isArray($scope.visitInstances) || $scope.visitInstances.length === 0) {
				return;
			}

			let form = angular.copy($scope.form);
			let isAddressChanged = false;

			form = fillFormDatesAndCaregivers(form, uniqueData);
			form = fillFormCertifications(form, uniqueData);
			form = fillFormLabels(form, uniqueData);
			form = fillFormContract(form, uniqueData);
			form = fillFormServiceCode(form, uniqueData);
			form = fillFormPayrollCode(form, uniqueData);
			form = fillFormRequiredForms(form, uniqueData);
			form = fillFormGender(form, uniqueData);
			form = fillFormPatientInfo(form, uniqueData);
			form = fillFormPrice(form, uniqueData);
			form = fillFormLanguages(form, uniqueData);
			form = fillFormGeneralNotes(form, uniqueData);
			form = fillFormCaseDetails(form, uniqueData);
			form = fillFormAssignedCoordinators(form, uniqueData);

			form.newGoogleAddressComponents = null;
			if (form.patientAddress && form.patientAddress !== "") {
				form.newGoogleAddressComponents = form.patientAddress;
				isAddressChanged = true;
			}

			form.patientAddress2Model = null;
			if (form.patientAddress2 && form.patientAddress2 !== "") {
				form.patientAddress2Model = form.patientAddress2;
			}

			if (uniqueData.hide_end_date_from_caregiver.length === 1 && uniqueData.hide_end_date_from_caregiver[0]) {
				form.hideEndDateFromCaregiver = uniqueData.hide_end_date_from_caregiver[0];
			}

			$scope.form = form;
			$scope.handleCertificationsChanged();
			$scope.updateEnableCaregivers();
			if (isAddressChanged) {
				setVisitAddress($scope.form.patientAddress);
				$scope.form.locationError = false;
			}
		};

		$scope.removeSelectedShift = (visitInstanceId) => {
			const newSelectedVisits = entityNewVisitModalService.selectedItems.visits.filter(shift =>
				shift.visitInstanceId !== visitInstanceId
			);
			entityNewVisitModalService.setSelectedItems({ visits: newSelectedVisits });
		};

		$scope.clearShiftTimes = (shiftId, dayFullName) => {
			if ($scope.$ctrl.isEditMode && $scope.form.visitType === "Permanent") {
				const shift = $scope.form.advancedTimeShiftsPermanent[shiftId][dayFullName];
				if (shift.mixedContent.startTimes) {
					const newStartTime = null;
					$scope.handleUpdateTimesData(shiftId, dayFullName, "startTime", newStartTime);
				}
				if (shift.mixedContent.endTimes) {
					const newEndTime = null;
					$scope.handleUpdateTimesData(shiftId, dayFullName, "endTime", newEndTime);
				}
				$scope.updateTotalTimePerWeek();
			}
		};

		$scope.applyShiftTimesToAll = (selectedShift) => {
			const startTime = selectedShift.startTime;
			const endTime = selectedShift.endTime;

			if ($scope.form.visitType === "Single") {
				$scope.selectedVisits.forEach(shift => {
					if (shift.visitInstanceId !== selectedShift.visitInstanceId) {
						shift.startTime = shift.startTime.clone().hours(startTime.hours()).minutes(startTime.minutes());
						shift.endTime = shift.endTime.clone().hours(endTime.hours()).minutes(endTime.minutes());
					}
				});
			} else if ($scope.form.visitType === "Permanent") {
				$scope.form.advancedTimeShiftsPermanent.forEach((shift, shiftId) => {
					if (shiftId >= $scope.form.shifts) {
						return;
					}
					const newStartTime = startTime ? moment().hours(startTime.hours()).minutes(startTime.minutes()) : null;
					const newEndTime = endTime ? moment().hours(endTime.hours()).minutes(endTime.minutes()) : null;
					Object.keys(shift).forEach(dayFullName => {
						if ($scope.form.daysOfTheWeek[dayFullName]) {
							$scope.handleUpdateTimesData(shiftId, dayFullName, "startTime", newStartTime);
							$scope.handleUpdateTimesData(shiftId, dayFullName, "endTime", newEndTime);
						}
					});
				});
				$scope.updateTotalTimePerWeek();
			}
		};

		$scope.applyShiftCaregiverToAll = (selectedShift) => {
			if ($scope.form.visitType === "Single") {
				$scope.selectedVisits.forEach(shift => {
					shift.caregiver = selectedShift.caregiver;
				});
				$scope.updateEnableCaregivers();
			} else if ($scope.form.visitType === "Permanent") {
				$scope.form.advancedTimeShiftsPermanent.forEach((shift, shiftId) => {
					if (shiftId >= $scope.form.shifts) {
						return;
					}
					const daysEntries = Object.entries(shift);
					daysEntries.forEach(dayEntry => {
						const [dayFullName, day] = dayEntry;
						if ($scope.form.daysOfTheWeek[dayFullName]) {
							day.caregiver = selectedShift.caregiver;
							if (day.mixedContent && day.mixedContent.caregivers) {
								day.editModeToggle.caregiver = true;
							}
						}
						$scope.form.advancedTimeShiftsPermanent[shiftId][dayFullName] = day;
					});
				});
			}
		};

		$scope.setDisplayedLabelsByCertifications = (certifications) => {
			$scope.displayedLabels = Object.keys($scope.certificationLabelMap)
				.reduce((acc, curr) => {
					if (certifications.includes(curr)) {
						$scope.certificationLabelMap[curr].forEach(lblObj => {
							const existingDisplayedLabel = acc.find(displayedLabel =>
								displayedLabel.id === lblObj.id
							);
							if (existingDisplayedLabel === undefined) {
								acc.push(lblObj);
							}
						});
					}
					return acc;
				}, []).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		};

		const getSelectedLabelsByCertificationsChange = (
			selectedCertifications,
			selectedLabelIds
		) => {
			const certificationsLabelsOptions = selectedCertifications
				.map(cert => $scope.certificationLabelMap[cert]);
			return certificationsLabelsOptions.reduce((acc, labelOptions) => {
				const newSelectedLabelId = selectedLabelIds.find(labelId =>
					labelOptions.find(labelOption => labelOption.id === labelId) && acc.indexOf(labelId) === -1
				);
				if (newSelectedLabelId !== undefined) {
					acc.push(newSelectedLabelId);
				}
				return acc;
			}, []);
		};

		$scope.updateLabelsAndFormsOptions = () => {
			$scope.setDisplayedLabelsByCertifications($scope.form.certifications);
			$scope.form.labels = getSelectedLabelsByCertificationsChange(
				$scope.form.certifications,
				$scope.form.labels
			);
			filterForms($scope.form.labels);
		};

		const filterForms = (labelIds) => {
			let labelsDocs = [];
			let isRelatedToPlanOfCare = false;
			labelIds.forEach(labelId => {
				const currentLabel = $scope.labels.find(curr => curr.id === labelId);
				currentLabel.documents.forEach(doc => {
					if (labelsDocs.find(d => d.id === doc.id) === undefined) {
						labelsDocs.push(doc);
					}
				});

				if (
					!isRelatedToPlanOfCare
					&& currentLabel.hasPlanOfCare !== undefined
					&& currentLabel.hasPlanOfCare === true
				) {
					isRelatedToPlanOfCare = true;
				}
			});

			if (isRelatedToPlanOfCare) {
				labelsDocs = labelsDocs.concat($scope.plansOfCareOptions);
			}

			labelsDocs.forEach(doc => {
				const findForm = $scope.form.forms.find(form => form.id === doc.id);
				if (findForm === undefined) {
					$scope.form.forms.push({ id: doc.id })
				}
			});
		};

		$scope.handlePatientAddressChange = async (newGoogleAddressComponents) => {
			try {
				const newAddress = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(
					newGoogleAddressComponents
				);
				setVisitAddress({
					components: newAddress,
					text: newAddress.formatedAddressDetails.fullAddress
				});
			}
			catch (e) {
				console.error(e, newGoogleAddressComponents);
				toaster.pop('error', 'Invalid address: ' + e.message);
			}
		};

		$scope.handlePatient2AddressChange = async (patientAddress2Model) => {
			try {
				if (patientAddress2Model === "" || patientAddress2Model === null) {
					$scope.form.patientAddress2 = null;
				} else {
					const newAddress = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(
						patientAddress2Model
					);
					$scope.form.patientAddress2 = newAddress.formatedAddressDetails.fullAddress;
				}
			}
			catch (e) {
				console.error(e, patientAddress2Model);
				toaster.pop('error', 'Invalid address: ' + e.message);
			}
		};

		const setVisitAddress = (newAddress) => {
			if (!$scope.map) {
				return;
			}

			$scope.form.locationError = false;
			if (!newAddress.components || !newAddress.components.location) {
				$scope.form.locationError = true;
			} else {
				$scope.form.patientAddressComponents = newAddress.components;
				const map = {
					latitude: newAddress.components.location.lat,
					longitude: newAddress.components.location.lng
				};
				$scope.map.center = map;
				$scope.marker.coords = map;
				$scope.form.patientAddressLoc = {
					lat: $scope.map.center.latitude,
					lng: $scope.map.center.longitude
				};

				$scope.form.addressGeoLocation = {
					lat: $scope.map.center.latitude,
					lng: $scope.map.center.longitude
				};

				if (newAddress.components.formatedAddressDetails) {
					$scope.form.city = newAddress.components.formatedAddressDetails.city
				}
				else if (newAddress.components.neighborhood) {
					$scope.form.city = newAddress.components.neighborhood
				}
				else if (newAddress.components.sublocalityLevel1) {
					$scope.form.city = newAddress.components.sublocalityLevel1
				}
				else if (newAddress.components.locality) {
					$scope.form.city = newAddress.components.locality
				}
				else {
					$scope.form.city = '';
				}

				$scope.form.timezone = !$scope.patient.timezone
					? 'America/New_York'
					: $scope.patient.timezone;
				$scope.form.zip = newAddress.components.formatedAddressDetails
					? newAddress.components.formatedAddressDetails.zip
					: '';
				$scope.form.state = newAddress.components.formatedAddressDetails
					? newAddress.components.formatedAddressDetails.state
					: newAddress.components.administrativeAreaLevel1;
				$scope.form.patientAddress = newAddress.text;
				mapChange();
				if (
					!$scope.form.state
					|| !$scope.form.city
					|| !$scope.form.timezone
					|| !$scope.form.patientAddress
				) {
					$scope.form.locationError = true;
				}
			}
		};

		$scope.$watch('map.center', () => {
			mapChange();
		}, true);

		const mapChange = () => {
			if (!$scope.map || !$scope.map.center || !$scope.editModeToggle.patientInfo) {
				return;
			}
			if ($scope.geoMap) {
				$scope.geoMap.setView(
					[$scope.map.center.latitude, $scope.map.center.longitude],
					$scope.map.zoom
				);
				$scope.geoMarker.setLatLng([
					$scope.marker.coords.latitude,
					$scope.marker.coords.longitude
				]);

			} else {
				$scope.geoMap = L.map('map', {
					center: [$scope.map.center.latitude, $scope.map.center.longitude],
					zoom: $scope.map.zoom
				});
				L.tileLayer('https://d5nz7zext4ylq.cloudfront.net/{z}/{x}/{y}.png', {
					tileSize: 512,
					zoomOffset: -1,
					minZoom: 5,
					attribution: '',
					crossOrigin: true
				}).addTo($scope.geoMap);
				if ($scope.marker && $scope.marker.coords) {
					$scope.geoMarker = L.marker(
						[$scope.marker.coords.latitude, $scope.marker.coords.longitude]
					).addTo($scope.geoMap);
				}
			}
		};

		$scope.handleGeneralNotesChange = () => {
			if ($scope.form && $scope.form.generalNotes) {
				validateGeneralNotes($scope.form.generalNotes);
			}
		};

		const validateGeneralNotes = (generalNotes) => {
			const addressRegex = new RegExp(/[\d]+[a-z\|#\|A-Z]|[a-z\|#\|A-Z]+[\d]|#|\brd\b|\broad\b|\bway\b|\bst\b|\bstr\b|\bstreet\b|\bave\b|\bav\b|\bavenue\b|\bBoulevard\b|\bblvd\b|\blane\b|\bln\b|\bterrace\b|\bTer\b|\bCourt\b|\bct\b|\bdrive\b|\bdr\b|\bapt\b|\bapartment\b|\bap\b|\bfl\b|\bflr\b|\bfloor\b/ig);
			if (generalNotes.match(addressRegex)) {
				SweetAlert.swal({
					title: "Error",
					text: "Patient address is not allowed in general notes",
					type: "warning",
					showCancelButton: false,
					confirmButtonColor: "#DD6B55",
					confirmButtonText: "Ok",
					closeOnConfirm: true,
					closeOnCancel: true
				});
			}
		};

		$scope.handleAssignedCoordinatorClick = (coordinatorToRemove) => {
			if (coordinatorToRemove.id !== $scope.patient.assignedCoordinator) {
				$scope.form.assignedCoordinatorsModel = $scope.form.assignedCoordinatorsModel
				.filter(coord => coord.id !== coordinatorToRemove.id);	
			}
		};

		$scope.toggleEditMode = (section) => {
			$scope.editModeToggle[section] = !$scope.editModeToggle[section];

			if (section === "certification" && $scope.mixedContent && $scope.mixedContent.labels && !$scope.editModeToggle["certification"]) {
				$scope.editModeToggle["label"] = false;
			}
			else if (section === "patientInfo") {
				mapChange();
			}
			clearMultipleValuesChanges(section);
		};

		const clearMultipleValuesChanges = (section) => {
			switch (section) {
				case "contract":
					if (!$scope.editModeToggle[section]) {
						$scope.form.contract = {};
						$scope.editModeToggle["serviceCode"] = false;
						$scope.form.serviceCode = {};
						$scope.editModeToggle["payrollCode"] = false;
						$scope.form.payrollCode = {};
						if ($scope.mixedContent && $scope.mixedContent.certifications && $scope.editModeToggle.certification) {
							$scope.toggleEditMode("certification");
						}
					}
					break;
				case "serviceCode":
					if (!$scope.editModeToggle[section]) {
						$scope.form.serviceCode = {};
						$scope.editModeToggle["payrollCode"] = false;
						$scope.form.payrollCode = {};
					}
					break;
				case "payrollCode":
					if (!$scope.editModeToggle[section]) {
						$scope.form.payrollCode = {};
					}
					break;
				case "certification":
					if ($scope.mixedContent && $scope.mixedContent.certifications) {
						$scope.form.certifications = [];
						$scope.handleCertificationsChanged();
					}
					break;
				default:
					break;
			}
		};

		$scope.handleUpdateTimesData = (shiftId, dayName, key, newVal) => {
			if (
				$scope.form.advancedTimeShiftsPermanent &&
				$scope.form.advancedTimeShiftsPermanent[shiftId] &&
				$scope.form.advancedTimeShiftsPermanent[shiftId][dayName]
			) {
				const day = angular.copy($scope.form.advancedTimeShiftsPermanent[shiftId][dayName]);
				day[key] = newVal;
				const start = day.startTime;
				const end = day.endTime;
				if (start && end) {
					const startMinutes = start.hour() * 60 + start.minute();
					const endMinutes = end.hour() * 60 + end.minute();
					let isOverNight = false;
					let totalMinutes = endMinutes - startMinutes;
					if (totalMinutes < 0) {
						totalMinutes += 24 * 60;
						isOverNight = true;
					}
					const hours = Math.floor(totalMinutes / 60);
					const minutes = totalMinutes - hours * 60;
					const hoursStr = hours > 0 ? `${hours}H` : '';
					const minutesStr = minutes > 0 ? `${minutes}M` : '';
					const durationStr = `${hoursStr}${hoursStr !== '' && minutesStr !== '' ? ':' : ''}${minutesStr}`;

					day.isOverNight = isOverNight;
					day.duration = durationStr;
					day.durationHours = hours;
					day.durationMinutes = minutes;
				} else {
					day.isOverNight = false;
					day.duration = "";
					day.durationHours = 0;
					day.durationMinutes = 0;
				}
				if (day.mixedContent && day.mixedContent.startTimes) {
					day.editModeToggle.startTime = (start !== null);
				}
				if (day.mixedContent && day.mixedContent.endTimes) {
					day.editModeToggle.endTime = (end !== null);
				}
				$scope.form.advancedTimeShiftsPermanent[shiftId][dayName] = day;
			}
		};

		$scope.updateTotalTimePerWeek = () => {
			const durationsToSum = [];
			$scope.form.advancedTimeShiftsPermanent.forEach((shift, shiftId) => {
				if ($scope.form.shiftsPerDays !== undefined || shiftId < $scope.form.shifts) {
					const daysEntries = Object.entries(shift);
					if ($scope.form.isDifferentDayTimes) {
						daysEntries.forEach(dayEntry => {
							const [dayFullName, day] = dayEntry;
							if (
								$scope.form.daysOfTheWeek[dayFullName] &&
								(
									$scope.form.shiftsPerDays === undefined ||
									shiftId < $scope.form.shiftsPerDays[dayFullName]
								)
							) {
								durationsToSum.push({
									hours: day.durationHours,
									minutes: day.durationMinutes
								});
							}
						});
					} else if (
						$scope.form.shiftsPerDays === undefined ||
						shiftId < $scope.form.shiftsPerDays["Everyday"]
					) {
						const everyday = daysEntries.find(entry => entry[0] === "Everyday")[1];
						const everydayShiftDuration = {
							hours: everyday.durationHours,
							minutes: everyday.durationMinutes
						};
						Object.values($scope.form.daysOfTheWeek).forEach(isDaySelected => {
							if (isDaySelected) {
								durationsToSum.push(everydayShiftDuration);
							}
						});
					}
				}
			});

			let totalHours = 0;
			let totalMinutes = 0;
			durationsToSum.forEach(duration => {
				totalHours += duration.hours;
				totalMinutes += duration.minutes;
			});
			if (totalMinutes >= 60) {
				totalHours = Math.floor(totalHours + (totalMinutes / 60));
				totalMinutes = totalMinutes % 60;
			}

			$scope.form.totalTimePerWeek = getDurationStringByHoursAndMinutes(totalHours, totalMinutes);
		};

		const getDurationStringByHoursAndMinutes = (hours, minutes) => {
			let durationString = "";
			if (hours > 0) durationString += hours + "H";
			if (minutes > 0) {
				if (durationString !== "") durationString += ":";
				durationString += minutes + "M";
			}

			return durationString;
		};

		const toFlatArray = (obj, excludeEveryday) => {
			let result = [];
			for (const entry of obj) {
				if (excludeEveryday) {
					delete entry['Everyday'];
					result = [
						...result,
						...Object.values(entry),
					];
				} else {
					result = [
						...result,
						entry['Everyday'],
					];
				}
			}

			return result;
		};

		$scope.updateEnableCaregivers = () => {
			const hasFooterCaregiver = $scope.form.caregiver !== null;
			const hasPermanentTabCaregiver = (
				$scope.form.advancedTimeShiftsPermanent !== undefined
				&& toFlatArray($scope.form.advancedTimeShiftsPermanent, $scope.form.isDifferentDayTimes).find(x => !!x.caregiver) !== undefined
			);
			if ($scope.$ctrl.isEditMode && $scope.editShiftsParams) {
				const hasSelectedShiftsCaregiver = $scope.selectedVisits.find(x => x.caregiver !== null) !== undefined;

				$scope.form.enableCaregivers.broadcast = (
					hasSelectedShiftsCaregiver !== true
					&& hasFooterCaregiver !== true
					&& hasPermanentTabCaregiver !== true
				);
				$scope.form.enableCaregivers.footer = (
					hasSelectedShiftsCaregiver !== true
					&& hasPermanentTabCaregiver !== true
				);
				$scope.form.enableCaregivers.shifts = true;
				$scope.form.enableCaregivers.shiftsCanAssign = hasFooterCaregiver !== true;
			} else {
				if ($scope.form.caregiver !== null) {
					$scope.form.enableCaregivers.broadcast = false;
				} else {
					$scope.form.enableCaregivers.broadcast = true;
				}
				$scope.form.enableCaregivers.footer = (hasPermanentTabCaregiver !== true);
				$scope.form.enableCaregivers.shifts = false;
				$scope.form.enableCaregivers.shiftsCanAssign = hasFooterCaregiver !== true;
			}
		};

		$scope.handleCaregiverSelect = (target, caregiver) => {
			target.caregiver = caregiver;
			$scope.updateEnableCaregivers();
		};

		$scope.handleCaregiverDeselect = (target) => {
			target.caregiver = null;
			$scope.updateEnableCaregivers();
		};

		$scope.handleSeeAllSuggestions = (target) => {
			$uibModal.open({
				templateUrl: "admin/views/new-visit/new-visit-sugggested-caregivers-modal.html",
				controller: 'newVisitSuggestedCaregiversModalCtrl',
				size: "lg",
				resolve: {
					caregivers: () => $scope.relevantVisitCaregivers,
					handleCaregiverSelect: () => (selectedCaregiver) => $scope.handleCaregiverSelect(target, selectedCaregiver)
				}
			});
		};

		const checkCaregiversState = () => {
			if (Object.keys($scope.caregiversMap).length > 0) {
				$scope.caregiversAreReady = true;
			}
		};

		const setRelevantCaregivers = () => {
			const patientOfficeId = $scope.patient.currentOfficeId;
			$scope.relevantVisitCaregivers = [];

			$scope.relevantVisitCaregivers = Object.values($scope.caregiversMap)
				.filter(caregiver => caregiver.status === 'ACTIVE' &&
					caregiver.officeIds.includes(patientOfficeId) &&
					caregiver.certifications.some(c => $scope.form.certifications.includes(c)) &&
					!caregiver.blacklisted.includes($scope.patient.id)
				);
		};

		$scope.getDefaultShift = (shiftId) => {
			const tempShift = shiftId === 0 ?
				visitConsts.defaultFirstShift :
				visitConsts.defaultSecondShift;
			return {
				startTime: tempShift.startTime.clone(),
				endTime: tempShift.endTime.clone(),
				isOverNight: tempShift.isOverNight,
				duration: tempShift.duration,
				durationHours: tempShift.durationHours,
				durationMinutes: tempShift.durationMinutes,
				caregiver: null,
			}
		};

		const initAuthorizationsTable = (authorizations, usedAuthorizationId) => {
			if (usedAuthorizationId) {
				authorizations = authorizations.map(auth => {
					auth.isUsed = (auth.id === usedAuthorizationId);
					return auth;
				});
			}
			$scope.form.patientAuthorizationsTable = new NgTableParams(
				{ sorting: { endDate: "asc" } }, {
					counts: [],
					dataset: authorizations
				}
			);
		};

		$scope.handleUseAuthorizationDates = (authorizationRow) => {
			if (
				$scope.form.usedAuthorizationRow === null ||
				authorizationRow.id !== $scope.form.usedAuthorizationRow.id
			) {
				$scope.form.usedAuthorizationRow = authorizationRow;
				initAuthorizationsTable($scope.form.authorizations, authorizationRow.id);
				$scope.form.datePicker.date = {
					// These dates are returned as mm/dd/yyyy
					startDate: new Date(authorizationRow.startDate),
					endDate: new Date(authorizationRow.endDate)
				};
			} else {
				$scope.form.usedAuthorizationRow = null;
			}
		};

		$scope.getDocumentsOptions = () => {
			return DatabaseApi.patientDocumentTypes().filter((form) => {
				return form.versions.filter(ver => ver.isPublished).length !== 0
			}).map(form => ({ id: form.id, label: form.title }));
		};

		$scope.getPlansOfCare = () => {
			const patientOfficePOC = DatabaseApi.plansOfCare().find(poc => poc.officeId === $scope.patient.currentOfficeId);
			if (patientOfficePOC === undefined) {
				return [];
			}

			return [{
				id: (0 - patientOfficePOC.planOfCareTypeId),
				label: patientOfficePOC.name
			}];
		};

		const dateWithMomentToLocalDateTime = (dateDate, momentTime) => {
			return JSJoda.LocalDateTime.of(
				dateDate.getFullYear(),
				dateDate.getMonth() + 1,
				dateDate.getDate(),
				momentTime.get("hours"),
				momentTime.get("minutes")
			);
		};

		const momentToLocalTime = (momentTime) => {
			return JSJoda.LocalTime.of(
				momentTime.get("hours"),
				momentTime.get("minutes")
			);
		};

		const getSingleVisitParams = (momentDate) => {
			const sortedTimes = [];
			let date = new Date(momentDate);
			const shiftsLimit = $scope.form.shiftsPerDays === undefined ?
				$scope.form.shifts :
				$scope.form.shiftsPerDays["Everyday"];
			for (let shiftId = 0; shiftId < shiftsLimit; shiftId++) {
				const startTime = $scope.form.advancedTimeShiftsSingle[shiftId].startTime;
				const endTime = $scope.form.advancedTimeShiftsSingle[shiftId].endTime;
				if (startTime.isSame(endTime)) {
					return { errorMessage: "Invalid shift duration, please extend time" };
				}
				sortedTimes.push(dateWithMomentToLocalDateTime(date, startTime));
				if ($scope.form.advancedTimeShiftsSingle[shiftId].isOverNight) {
					date.setDate(date.getDate() + 1);
				}
				sortedTimes.push(dateWithMomentToLocalDateTime(date, endTime));
			}

			if (sortedTimes.length !== 2 && sortedTimes.length !== 4) {
				return { errorMessage: "Invalid times" };
			}

			if (sortedTimes.length === 4) {
				for (let i = 0; i < sortedTimes.length - 2; i++) {
					if (sortedTimes[i + 1].isBefore(sortedTimes[i])) {
						return { errorMessage: "Shifts overlapse" };
					}
				}

				if (Duration.between(sortedTimes[0], sortedTimes[3]).toMinutes() > 1440) {
					return { errorMessage: "24 hours shifts overflow" };
				}
			}

			const strVisitDate = momentDate.format("YYYY-MM-DD");
			if ($scope.patient.vacations.find(vacation => vacation.date === strVisitDate)) {
				return { errorMessage: "Can't create visit on a vacation day" };
			}

			if (sortedTimes.length === 2) {
				return {
					errorMessage: null,
					type: "SingleVisit",
					startDateTime: sortedTimes[0],
					endDateTime: sortedTimes[1]
				};
			}

			return {
				errorMessage: null,
				type: "MultipleShifts",
				shiftsTimes: [
					{
						startDateTime: sortedTimes[0],
						endDateTime: sortedTimes[1]
					},
					{
						startDateTime: sortedTimes[2],
						endDateTime: sortedTimes[3]
					}
				]
			};
		};

		const modalVacationConflictResult = (result, vacationShiftConflictDates) => {
			const deferred = $q.defer();
			const modal = mfModal.create({
				subject: "Vacation conflict",
				message: `
					Some of the visits conflict with patient vacations:
					${vacationShiftConflictDates.join(", ")}.
					Do you want to continue and skip conflicted vacations?
				`,
				cancelLabel: "Cancel",
				confirmLabel: "Yes, skip vacations",
				variant: "danger",
				onCancel: () => {
					result.cancelSkipConflictedVacations = true;
				},
				onComplete: () => {
					modal.close();
					deferred.resolve(result);
				}
			});

		  	return deferred.promise;
		};

		const getPermanentVisitParams = async () => {
			if (Object.values($scope.form.daysOfTheWeek).filter(x => x === true).length === 0) {
				return { errorMessage: `Please select days` };
			}
			const sortedShifts = getSortedShiftsForPermanent();

			for (let i = 0; i < sortedShifts.length - 1; i++) {
				const shift1 = sortedShifts[i];
				const shift2 = sortedShifts[i + 1];
				const days = $scope.advancedDays.map(x => x.fullName);

				if (shift1.day === shift2.day) {
					const dayDuration = Duration.between(
						shift1.startLocalDateTime,
						shift2.endLocalDateTime
					);
					if (dayDuration.toMinutes() > 1440) {
						return { errorMessage: `24 hours shifts overflow on ${shift1.day}` };
					}
				}

				if (shift2.startLocalDateTime.isBefore(shift1.endLocalDateTime)) {
					let errorMessage = "";
					if (shift1.day === shift2.day) {
						errorMessage = "Shifts overlapse";
						if ($scope.form.advancedTimeShiftsPermanent[0]["Everyday"] === undefined) {
							errorMessage += ` on ${shift1.day}`;
						}
					} else {
						let shiftsDaysMargin = days.indexOf(shift2.day) - days.indexOf(shift1.day);
						if (shiftsDaysMargin < 0) shiftsDaysMargin += 7;
						if (shiftsDaysMargin === 1) {
							errorMessage = "Shifts overlapse between "
								+ shift1.day
								+ " and "
								+ shift2.day;
						}
					}
					if (errorMessage && errorMessage !== "") {
						return { errorMessage: errorMessage };
					}
				}
			}

			const dayTimeParams = [];
			sortedShifts.forEach(shift => {
				const shiftTimesToPush = {
					startTime: shift.startLocalDateTime.toLocalTime(),
					endTime: shift.endLocalDateTime.toLocalTime()
				};
				if (
					dayTimeParams.length === 0 ||
					dayTimeParams[dayTimeParams.length - 1].day !== shift.day.toUpperCase()
				) {
					dayTimeParams.push({
						day: shift.day.toUpperCase(),
						times: [shiftTimesToPush],
						caregiverId: shift.caregiver ? shift.caregiver.id : null
					});
				} else {
					dayTimeParams[dayTimeParams.length - 1].times.push(shiftTimesToPush)
				}
			});
			if (dayTimeParams.length === 0) {
				return { errorMessage: "Please select visit times" };
			}

			const startLocalDateTime = sortedShifts[0].startLocalDateTime;
			const endLocalDateTime = JSJoda.LocalDateTime.of(
				$scope.form.datePicker.date.endDate.getFullYear(),
				$scope.form.datePicker.date.endDate.getMonth() +1,
				$scope.form.datePicker.date.endDate.getDate(),
				sortedShifts[sortedShifts.length - 1].endLocalDateTime.hour(),
				sortedShifts[sortedShifts.length - 1].endLocalDateTime.minute()
			);
			const startLocalDate = startLocalDateTime.toLocalDate();
			const endLocalDate = endLocalDateTime.toLocalDate();

			const formatter = JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy");
			const shiftsDays = dayTimeParams.map(x => x.day);
			const vacationShiftConflictDates = $scope.patient.vacations.filter(vacation => {
				const vacationLocalDate = LocalDate.parse(vacation.date);
				return (
					!vacationLocalDate.isBefore(startLocalDate) &&
					!vacationLocalDate.isAfter(endLocalDate) &&
					shiftsDays.includes(vacationLocalDate.dayOfWeek().toString())
				);
			}).map(vacation => LocalDate.parse(vacation.date).format(formatter));

			const result = {
				errorMessage: null,
				type: "PermanentVisit",
				startDateTime: startLocalDateTime,
				endDateTime: endLocalDateTime,
				dayTimeParams: dayTimeParams,
				cancelSkipConflictedVacations: false
			};
			if (vacationShiftConflictDates.length > 0) {
				return await modalVacationConflictResult(result, vacationShiftConflictDates);
			} else {
				return result;
			}
		};

		const getSortedShiftsForPermanent = () => {
			const getDayDate = (startDate, dayName) => {
				// Not sure why wrap it inside new Date if it's already a Date?
				let date = new Date(startDate);
				const startDateDay = moment(date).format("dddd");
				const days = $scope.advancedDays.map(d => d.fullName);
				let daysMargin = days.indexOf(dayName) - days.indexOf(startDateDay);
				if (daysMargin < 0) daysMargin += 7;
				date.setDate(date.getDate() + daysMargin);
				return date;
			};

			const everydayShifts = [
				$scope.form.advancedTimeShiftsPermanent[0]["Everyday"],
				$scope.form.advancedTimeShiftsPermanent[1]["Everyday"]
			];

			const sortedShifts = [];
			$scope.advancedDays.forEach(day => {
				if ($scope.form.daysOfTheWeek[day.fullName] !== true) {
					return;
				}
				const date = getDayDate($scope.form.datePicker.date.startDate, day.fullName);
				const shiftsLimit = $scope.form.shiftsPerDays === undefined ?
					$scope.form.shifts :
					$scope.form.shiftsPerDays[day.fullName];
				for (let shiftId = 0; shiftId < shiftsLimit; shiftId++) {
					let shift;
					if (everydayShifts[shiftId] === undefined) {
						shift = $scope.form.advancedTimeShiftsPermanent[shiftId][day.fullName];
					} else {
						shift = angular.copy(everydayShifts[shiftId]);
					}
					const endShiftDate = new Date(date);
					if (shift.isOverNight) {
						endShiftDate.setDate(date.getDate() + 1);
					}
					shift.day = day.fullName;
					shift.startLocalDateTime = dateWithMomentToLocalDateTime(
						date,
						shift.startTime
					);
					shift.endLocalDateTime = dateWithMomentToLocalDateTime(
						endShiftDate,
						shift.endTime
					);
					sortedShifts.push(shift);
				}
			});

			const endOfWeek = sortedShifts[sortedShifts.length - 1].startLocalDateTime;
			if (
				sortedShifts.length >= 2 &&
				sortedShifts[0].startLocalDateTime.isAfter(endOfWeek)
			) {
				for (let i = 0; i < sortedShifts.length - 1; i++) {
					if (sortedShifts[0].startLocalDateTime.isAfter(endOfWeek)) {
						sortedShifts.push(sortedShifts[0]);
						sortedShifts.splice(0, 1);
					}
				}
			}

			return sortedShifts;
		};

		const getCreateVisitBody = async (broadcastParams) => {
			const body = {};

			// Contract and payment
			if (!$scope.form.contract || $scope.form.contract.id === undefined) {
				return { errorMessage: "Please select contract" };
			}
			body.patientContractId = $scope.form.contract.id;
			if (!$scope.form.serviceCode || $scope.form.serviceCode.id === undefined || $scope.form.serviceCode.id === null) {
				if ($rootScope.showBillingFeature) {
					return { errorMessage: "Please select service code" };
				} else {
					return { errorMessage: "Please contact Medflyt" };
				}
			}
			body.serviceCodeId = $scope.form.serviceCode.id;
			if ($rootScope.showBillingFeature && (!$scope.form.payrollCode || $scope.form.payrollCode.id === undefined)) {
				return { errorMessage: "Please select payroll code" };
			}
			body.payrollCodeId = $scope.form.payrollCode && $scope.form.payrollCode.id ?
				$scope.form.payrollCode.id !== -1 ? $scope.form.payrollCode.id : null
				: null;
			body.payrollAdjustmentSeconds = null;
			body.billable = true;

			// Certification
			if (!$scope.form.certifications || !$scope.form.certifications.length) {
				$scope.form.certifications = ['HHA', 'PCA'];
				$scope.handleCertificationsChanged();
			}
			body.certifications = $scope.form.certifications;

			// Label
			body.visitLabels = $scope.form.labels.map(labelId => {
				const labelObj = $scope.labels.find(l => l.id === labelId);
				if (labelObj === undefined) {
					body.errorMessage = "Invalid labels";
					return {};
				}
				return {
					text: labelObj.name,
					color: labelObj.color
				};
			});
			if (body.errorMessage) {
				return body;
			}

			// Time span
			if (
				!$scope.form.datePicker ||
				!$scope.form.datePicker.date ||
				!$scope.form.datePicker.date.startDate ||
				!$scope.form.datePicker.date.endDate
			) {
				return { errorMessage: "Invalid dates" };
			}

			// Not sure what's the need for `new Date` here since these values are already Date...
			$scope.form.datePicker.date.startDate = new Date($scope.form.datePicker.date.startDate);
			$scope.form.datePicker.date.endDate = new Date($scope.form.datePicker.date.endDate);
			const momentStartDate = moment($scope.form.datePicker.date.startDate);

			switch ($scope.form.visitType) {
				case "Single":
					const singleVisitParams = getSingleVisitParams(momentStartDate);
					if (singleVisitParams.errorMessage !== null) {
						return { errorMessage: singleVisitParams.errorMessage };
					}
					body.type = singleVisitParams.type;
					if (singleVisitParams.type === "SingleVisit") {
						// SingleVisit
						body.startDateTime = singleVisitParams.startDateTime;
						body.endDateTime = singleVisitParams.endDateTime;
					} else {
						// MultipleShifts (Another Shift)
						body.shiftsTimes = singleVisitParams.shiftsTimes
					}
					break;
				case "Permanent":
					const permanentVisitParams = await getPermanentVisitParams();
					if (permanentVisitParams.errorMessage !== null) {
						return { errorMessage: permanentVisitParams.errorMessage };
					}
					if (permanentVisitParams.cancelSkipConflictedVacations) {
						return { cancelSkipConflictedVacations: true };
					}
					body.type = permanentVisitParams.type;
					body.startDateTime = permanentVisitParams.startDateTime;
					body.endDateTime = permanentVisitParams.endDateTime;
					body.dayTimeParams = permanentVisitParams.dayTimeParams;
					break;
				default:
					return { errorMessage: "Invalid visit type" };
			};

			// Required forms
			const docsAndPocIds = $scope.form.forms.map(x => x.id);
			body.requiredPatientDocuments = docsAndPocIds.filter(x => x >= 0);
			const findPocId = docsAndPocIds.find(x => x < 0);
			body.planOfCareTypeId = findPocId !== undefined ? (0 - findPocId) : null;

			// Price
			body.price = $scope.form.price ? $scope.form.price * 100 : null;
			body.paymentType = ($scope.form.priceType === "perVisit") ? "Visit" : "Hourly";

			// Patient's requested gender
			if (!$scope.form.genders.length || $scope.form.genders.length === 0) {
				return { errorMessage: "Missing gender, please select one or both" };
			}
			body.gender = $scope.form.genders.length === 1 ? $scope.form.genders[0][0] : null;

			// Language
			if (!$scope.form.languages || !$scope.form.languages.length) {
				$scope.form.languages = ['English'];
			}
			body.languages = $scope.form.languages;

			// Patient Info
			if ($scope.form.patientPhone === undefined) {
				return { errorMessage: "Invalid home phone number" };
			}
			body.patientPhone = $scope.form.patientPhone ?
				`+1${$scope.form.patientPhone}` : null;
			if ($scope.form.patientAlternativePhone === undefined) {
				return { errorMessage: "Invalid mobile phone number" };
			}
			body.patientAlternativePhone = $scope.form.patientAlternativePhone ?
				`+1${$scope.form.patientAlternativePhone}`: null;
			if (!$scope.form.assignedCoordinatorsModel) {
				$scope.form.assignedCoordinatorsModel = [];
			}
			if (
				$scope.form.locationError ||
				!$scope.form.timezone ||
				!$scope.form.state ||
				!$scope.form.patientAddress ||
				!$scope.form.patientAddressComponents
			) {
				return { errorMessage: "Please enter valid address" };
			}
			if (!$scope.form.patientAddress2) {
				$scope.form.patientAddress2 = null;
			}
			body.address = {
				address: $scope.form.patientAddress,
				address2: $scope.form.patientAddress2,
				timezone: $scope.form.timezone,
				state: $scope.form.state,
				addressComponents: $scope.form.patientAddressComponents
			};
			body.address.countyName = (
				$scope.form.patientAddressComponents.formatedAddressDetails &&
				$scope.form.patientAddressComponents.formatedAddressDetails.county
			) ?
				$scope.form.patientAddressComponents.formatedAddressDetails.county :
				$scope.form.patientAddressComponents.administrativeAreaLevel2;
			if (!body.address.countyName) {
				return { errorMessage: "Please enter valid address" };
			}

			// General Notes
			if (typeof $scope.form.generalNotes !== "string") {
				return { errorMessage: "Invalid general notes" };
			}
			body.generalNotes = $scope.form.generalNotes;

			// Case Details
			if (typeof $scope.form.caseDetails !== "string") {
				return { errorMessage: "Invalid case details" };
			}
			body.caseDetails = $scope.form.caseDetails;

			// Assigned Coordinators
			const coordinatorsIds = $scope.form.assignedCoordinatorsModel.map(c => c.id);
			if (coordinatorsIds.indexOf($rootScope.agencyMemberId) === -1) {
				coordinatorsIds.push($rootScope.agencyMemberId);
			}
			body.assignedCoordinators = coordinatorsIds;

			// Footer
			if ($scope.form.caregiver && !$scope.form.caregiver.id) {
				return { errorMessage: "Invalid caregiver" };
			}
			body.caregiverId = $scope.form.caregiver ? $scope.form.caregiver.id : null;
			body.broadcast = broadcastParams.isBroadcast;
			body.assignableCaregiverRadius = broadcastParams.radius;
			body.hideEndDateFromCaregiver = broadcastParams.hideEndDateFromCaregiver;

			return body;
		};

		async function getWeeklyTemplateAction(dayTimeParams) {
			if (!$scope.patientWeeklyTemplates) {
				await loadPatientWeeklyTemplate();
			}

			const overlapsWithExistingWeeklyTemplate = (() => {
				for (const dayTimeParam of dayTimeParams) {
					const templates = $scope.patientWeeklyTemplates.filter(x => x.dayOfWeek === dayTimeParam.day);
					for (const time of dayTimeParam.times) {
						for (const template of templates) {
							if (overlapTimes(time, template)) {
								return true;
							}
						}
					}
				}
				return false;
			})();

			const message = "What would you like to do?"
			const options = [];
			let subject = '';
			if ($scope.patientWeeklyTemplates.length > 0){
				options.push(
					{ id: 2, action: "NO_EFFECT", label: "Add visits without any change to the existing Weekly Template" });
				if (!overlapsWithExistingWeeklyTemplate) {
					options.push(
						{ id: 3, action: "ADD", label: "Add to the existing Weekly Template"}
					)
				}
				options.push({
					id: 1,
					action: "REPLACE",
					label: "Replace the existing Weekly Template",
					tooltipText: "Days with visits will be replaced\r\nDays without visits will be added"
				})
				subject = `This patient already has an active Weekly Template`;
			}else{
				options.push(
					{ id: 2, action: "NO_EFFECT", label: "Add visits without creating a new Weekly Template" },
					{ id: 3, action: "ADD", label: "Add visits and create a new Weekly Template"});
				subject = `This patient doesn't have an active Weekly Template`;
			}
			return new Promise((resolve, reject) => {
				const modal = mfModal.create({
					subject,
					options: options,
					message: message,
					layoutOrder: ["message", "options"],
					confirmLabel: "Submit",
					hideCancelButton: false,
					preventClose: true,
					onConfirm: ({ selectedOption }) => {
						modal.setLoading(true);
						if (selectedOption) {
							resolve(options.find(x => x.id === selectedOption).action);
							return;
						}

						// No selected option
						modal.update({
							isLoading: false,
							message: "Please select option"
						});
					},
					onCancel: () => reject(),
					onComplete: () => modal.close()
				});
			});
		}

		async function loadPatientWeeklyTemplate() {
			const url = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/weekly_template_shifts",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				$scope.patient.id
			);
			const res = await DatabaseApi.get(url);
			$scope.patientWeeklyTemplates = res.data.shifts;
		}

		function overlapTimes(
			newTimes,
			existingTimes
		) {
			const dummyNow = JSJoda.LocalDate.now();
			const newShiftStartDateTime = dummyNow.atTime(JSJoda.LocalTime.of(newTimes.startTime._hour, newTimes.startTime._minute));
			let newShiftEndDateTime = dummyNow.atTime(JSJoda.LocalTime.of(newTimes.endTime._hour, newTimes.endTime._minute));
			if (!newShiftStartDateTime.isBefore(newShiftEndDateTime)) {
				newShiftEndDateTime = newShiftEndDateTime.plusDays(1);
			}
			const shiftStartDateTime = dummyNow.atTime(JSJoda.LocalTime.parse(existingTimes.startTime));
			let shiftEndDateTime = dummyNow.atTime(JSJoda.LocalTime.parse(existingTimes.endTime));
			if (!shiftStartDateTime.isBefore(shiftEndDateTime)) {
				shiftEndDateTime = shiftEndDateTime.plusDays(1);
			}
			if (
				newShiftEndDateTime.isBefore(shiftStartDateTime) ||
				newShiftEndDateTime.isEqual(shiftStartDateTime) ||
				newShiftStartDateTime.isAfter(shiftEndDateTime) ||
				newShiftStartDateTime.isEqual(shiftEndDateTime)
			) {
				return false;
			}
			return true;
		}

		$scope.handleBroadcast = async (broadcastParams) => {
			let showWarning = false;
			if ($scope.selectedVisits) {
				$scope.selectedVisits.forEach(selectedVisit => {
					if (selectedVisit.startTime.isBefore(moment().format('YYYY-MM-DD'))) {
						showWarning = true;
					}
				});
			} else if (
				$scope.form.visitType.toLowerCase() === 'permanent' &&
				$scope.isStartDateBeforeToday()
			) {
				showWarning = true;
			}

			if (showWarning) {
				const modal = mfModal.create({
					subject: "Broadcast",
					message: `
						Some of the visits you are trying to broadcast have already passed so they will not be broadcasted. Do you wish to continue?
					`,
					cancelLabel: "Back to edit",
					confirmLabel: "Yes",
					variant: "danger",
					onConfirm: () => {
						$scope.handleSaveVisit(broadcastParams);
						modal.close();
					}
				});
			} else {
				$scope.handleSaveVisit(broadcastParams);
			}
		};

		$scope.handleSaveVisit = (broadcastParams) => {
			const hideEndDateFromCaregiver = !!$scope.form.hideEndDateFromCaregiver;
			broadcastParams = {
				...broadcastParams,
				hideEndDateFromCaregiver,
			};

			if (broadcastParams.radius !== null) {
				if (broadcastParams.radius < 1) {
					broadcastParams.radius = 1;
				}
				// Miles to Meters
				broadcastParams.radius *= 1609;
			}

			if ($scope.$ctrl.isEditMode) {
				updateExistingVisits(broadcastParams);
			} else {
				createNewVisit(broadcastParams);
			}
		};

		const showNewVisitSuccessMessages = (data) => {
			if (data.visitInstanceIds.length > 0) {
				const messageSuffix = data.visitInstanceIds.length > 1 ? "visits were created" : "visit was created";
				const message = `${data.visitInstanceIds.length} ${messageSuffix}`;
				if (data.assignWithIncreasedCaregiverOvertime) {
					toaster.pop({
						type: 'warning',
						title: 'Warning',
						body: `${message} with increased caregiver overtime`
					});
				} else {
					toaster.pop("success", "Success", `${message} successfully`);
				}
			}

			if (data.overlappingCaregiver.length > 0) {
				const messagePrefix = data.overlappingCaregiver.length > 1 ? "visits were" : "visit was";
				const visitTimes = data.overlappingCaregiver.map((visit, index) => `Visit ${index + 1} - ${$filter("mfShortTime")(visit.startTime, ['withDate'])}`).join("<br />");
				toaster.pop({
					type: 'warning',
					title: 'Warning',
					bodyOutputType: "trustedHtml",
					body: `${data.overlappingCaregiver.length} ${messagePrefix} not added due to overlap with other visits of the same caregiver as following:<br />${visitTimes}`,
					timeout: 7000
				});
			}

			if (data.overlappingPatient.length > 0) {
				const sentencePart = data.overlappingPatient.length > 1 ? "visits were" : "visit was";
				const visitTimes = data.overlappingPatient.map((visit, index) => `Visit ${index + 1} - ${$filter("mfShortTime")(visit.startTime, ['withDate'])}`).join("<br />");
				toaster.pop({
					type: 'warning',
					title: 'Warning',
					bodyOutputType: "trustedHtml",
					body: `${data.overlappingPatient.length} ${sentencePart} not added due to overlap with other visits of the same patient as following:<br />${visitTimes}`,
					timeout: 7000
				});
			}
		}

		const validateCreateForVisitsOverlaps = (body) => {
			const checkOverlapsUrl = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits/check_overlapping",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				$scope.patient.id
			);
			return new Promise((resolve, reject) => {
				DatabaseApi.post(checkOverlapsUrl, body).then(({data}) => {
					let showPopup = false;
					let confirmButtonText = "";
					let cancelButtonText = "";
					let hideCancelButton = true;
					if (data.willCreateVisits) {
						confirmButtonText = "Yes, create visit(s)";
						cancelButtonText = "No, keep editing";
						hideCancelButton = false;
					} else {
						confirmButtonText = "keep editing";
						hideCancelButton = true;
					}
					let caregiverOverlappingText = "";
					let patientOverlappingText = "";
					if (data.overlappingCaregiver.length > 0) {
						showPopup = true;
						const messagePrefix = data.overlappingCaregiver.length > 1 ? "visits" : "visit";
						const visitTimes = data.overlappingCaregiver.map((visit, index) => `Visit ${index + 1} - ${$filter("mfShortTime")(visit.startTime, ['withDate'])}`).join("\n");
						caregiverOverlappingText = `${data.overlappingCaregiver.length} ${messagePrefix} cannot be added due to overlap with another visit of the same caregiver:\n${visitTimes}\n`;
					}

					if (data.overlappingPatient.length > 0) {
						showPopup = true;
						const sentencePart = data.overlappingPatient.length > 1 ? "visits" : "visit";
						const visitTimes = data.overlappingPatient.map((visit, index) => `Visit ${index + 1} - ${$filter("mfShortTime")(visit.startTime, ['withDate'])}`).join("\n");
						patientOverlappingText = `${data.overlappingPatient.length} ${sentencePart} cannot be added due to overlap with another visit of the same patient:\n${visitTimes}\n`
					}
					if (showPopup){
						const modal = mfModal.create({
							subject: "Are you sure?",
							message: `${caregiverOverlappingText}${patientOverlappingText}${data.willCreateVisits
									? `\nExcept for the above visits, there are other visits which will be created if you wish to proceed`
									: ""}
							`,
							layoutOrder: ["message"],
							cancelLabel: cancelButtonText,
							confirmLabel: confirmButtonText,
							hideCancelButton: hideCancelButton,
							onConfirm: () => hideCancelButton ? reject() : resolve(),
							onCancel: () =>  reject(),
							onComplete: () => modal.close()
						});
					} else {
						// should create because no overlaps
						return resolve();
					}
				});
			});
		}

		const sendCreateVisitRequest = (body, resetVisits) => {
			const createVisitUrl = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				$scope.patient.id
			);
			return new Promise((resolve, _) => {
				DatabaseApi.post(createVisitUrl, body).then((res) => {
					if (resetVisits) {
						DatabaseApi.setVisits(undefined);
					}

					const dataCreated = res.data.visitInstanceIds.length > 0;

					if (dataCreated) {
						$rootScope.$emit("refresh_visits");
						$scope.closeParentModal();
						generalUtils.scrollToElement('scroll-calendar');
					}

					showNewVisitSuccessMessages(res.data);
				}, (err) => {
					let errorMessage = `Can't create visit`;
					if (err.data) {
						if (err.data.error === "Not permitted to increase caregiver overtime") {
							errorMessage = err.data.error;
						}
						else if (err.data.length > 0 && err.data[0] && err.data[0].message) {
							errorMessage = err.data[0].message;
						} else if (err.data.details && err.data.details.length > 0) {
							errorMessage = err.data.details[0];
							try {
								const messageFromJson = JSON.parse(errorMessage)[0].message;
								errorMessage = messageFromJson;
							} catch {
								if (errorMessage.includes("dataPath")) {
									errorMessage = `Can't create visit`;
								}
							}
						}
					}

					toaster.pop("error", "Something went wrong", errorMessage);
				}).finally(() => {
					return resolve();
				});
			});
		}

		const createNewVisit = async (broadcastParams) => {
			const body = await getCreateVisitBody(broadcastParams);

			if (body.errorMessage) {
				$timeout(() => toaster.pop("error", body.errorMessage), 0);
				return;
			}

			if (body.cancelSkipConflictedVacations) {
				return;
			}

			try {
				await validateCreateForVisitsOverlaps(body);
				if (body.type === "PermanentVisit") {
					body.weeklyTemplateAction = await getWeeklyTemplateAction(body.dayTimeParams);
				}
				await sendCreateVisitRequest(body, broadcastParams.isBroadcast);
			} finally {
				$timeout(() => {
					if (broadcastParams.isBroadcast) {
						$scope.isBroadcasting = false;
					} else {
						$scope.isSaving = false;
					}
				}, 0);
				return;
			}
		};

		const getEditVisitsBody = (broadcastParams) => {
			const body = {
				broadcast: broadcastParams.isBroadcast,
				assignableCaregiverRadius: broadcastParams.radius,
				hideEndDateFromCaregiver: broadcastParams.hideEndDateFromCaregiver
			};

			// Contract and payment
			if (!$scope.mixedContent.contracts || $scope.editModeToggle.contract) {
				if (!$scope.form.contract || $scope.form.contract.id === undefined) {
					return { errorMessage: "Please select contract" };
				}
				body.patientContractId = $scope.form.contract.id;

				if (!$scope.mixedContent.serviceCodes || $scope.editModeToggle.serviceCode) {
					if ($rootScope.showBillingFeature && (!$scope.form.serviceCode || $scope.form.serviceCode.id === undefined)) {
						return { errorMessage: "Please select service code" };
					}
					body.serviceCodeId = $scope.form.serviceCode && $scope.form.serviceCode.id ? $scope.form.serviceCode.id : null;

					if (!$scope.mixedContent.payrollCodes || $scope.editModeToggle.payrollCode) {
						if ($rootScope.showBillingFeature && (!$scope.form.payrollCode || $scope.form.payrollCode.id === undefined)) {
							return { errorMessage: "Please select payroll code" };
						}
						body.payrollCodeId = $scope.form.payrollCode && $scope.form.payrollCode.id ?
							$scope.form.payrollCode.id !== -1 ? $scope.form.payrollCode.id : null
							: null;
					}
				}
			}

			// Certification
			if (!$scope.mixedContent.certifications || $scope.editModeToggle.certification) {
				if (!$scope.form.certifications || !$scope.form.certifications.length) {
					if ($scope.mixedContent.certifications) {
						return { errorMessage: "Please select certifications or keep original multiple values" };
					}
					$scope.form.certifications = ['HHA', 'PCA'];
					$scope.handleCertificationsChanged();
				}
				body.certifications = $scope.form.certifications;
			}

			// Label
			if (!$scope.mixedContent.labels || body.certifications) {
				body.visitLabels = $scope.form.labels.map(labelId => {
					const labelObj = $scope.labels.find(l => l.id === labelId);
					if (labelObj === undefined) {
						body.errorMessage = "Invalid labels";
						return {};
					}
					return {
						text: labelObj.name,
						color: labelObj.color
					};
				});
				if (body.errorMessage) {
					return body;
				}
			}

			// Required forms
			if (!$scope.mixedContent.requiredForms || $scope.editModeToggle.requiredForms) {
				const docsAndPocIds = $scope.form.forms.map(x => x.id);
				body.requiredPatientDocuments = docsAndPocIds.filter(x => x >= 0);
				const findPocId = docsAndPocIds.find(x => x < 0);
				body.planOfCareTypeId = findPocId !== undefined ? (0 - findPocId) : null;
			}

			// Price
			if (!$scope.mixedContent.price || $scope.editModeToggle.price) {
				if ($scope.mixedContent.price && (!$scope.form.price || !$scope.form.priceType)) {
					return { errorMessage: "Please choose price and payment type or keep original multiple values" };
				}
				body.price = $scope.form.price ? $scope.form.price * 100 : null;
				body.paymentType = ($scope.form.priceType === "perVisit") ? "Visit" : "Hourly";
			}

			// Patient's requested gender
			if (!$scope.mixedContent.gender || $scope.editModeToggle.gender) {
				if (!$scope.form.genders.length || $scope.form.genders.length === 0) {
					return {
						errorMessage: $scope.mixedContent.gender ?
							"Missing gender, please select one or both or keep original multiple values" :
							"Missing gender, please select one or both"
					};
				}
				body.gender = $scope.form.genders.length === 1 ? $scope.form.genders[0][0] : null;
			}

			// Language
			if (!$scope.mixedContent.languages || $scope.editModeToggle.language) {
				if (!$scope.form.languages || !$scope.form.languages.length) {
					if ($scope.mixedContent.languages) {
						return { errorMessage: "Please select languages or keep original multiple values" };
					}
					$scope.form.languages = ['English'];
				}
				body.languages = $scope.form.languages;
			}

			// Patient Info
			if (!$scope.mixedContent.patientPhoneNumber || $scope.editModeToggle.patientPhoneNumber) {
				if ($scope.form.patientPhone === undefined) {
					return { errorMessage: "Invalid home phone number" };
				}
				body.patientPhone = $scope.form.patientPhone ?
					`+1${$scope.form.patientPhone}` : null;
			}
			if (!$scope.mixedContent.patientAlternativePhone || $scope.editModeToggle.patientAlternativePhone) {
				if ($scope.form.patientAlternativePhone === undefined) {
					return { errorMessage: "Invalid mobile phone number" };
				}
				body.patientAlternativePhone = $scope.form.patientAlternativePhone ?
					`+1${$scope.form.patientAlternativePhone}`: null;
			}
			if (!$scope.mixedContent.patientAddress || $scope.editModeToggle.patientAddress) {
				if (
					$scope.form.locationError ||
					!$scope.form.patientAddress ||
					!$scope.form.timezone ||
					!$scope.form.state ||
					!$scope.form.patientAddressComponents
				) {
					return {
						errorMessage: $scope.mixedContent.patientAddress ?
							"Please enter valid address or keep original multiple values" :
							"Please enter valid address"
					};
				}
				if (!$scope.form.patientAddress2) {
					$scope.form.patientAddress2 = null;
				}
				body.address = {
					address: $scope.form.patientAddress,
					address2: $scope.form.patientAddress2,
					timezone: $scope.form.timezone,
					state: $scope.form.state,
					addressComponents: $scope.form.patientAddressComponents
				};
				body.address.countyName = (
					$scope.form.patientAddressComponents.formatedAddressDetails &&
					$scope.form.patientAddressComponents.formatedAddressDetails.county
				) ?
					$scope.form.patientAddressComponents.formatedAddressDetails.county :
					$scope.form.patientAddressComponents.administrativeAreaLevel2;
				if (!body.address.countyName) {
					return {
						errorMessage: $scope.mixedContent.patientAddress ?
							"Please enter valid address or keep original multiple values" :
							"Please enter valid address"
					};
				}
			}

			// General Notes
			if (!$scope.mixedContent.generalNotes || $scope.editModeToggle.generalNotes) {
				if (typeof $scope.form.generalNotes !== "string") {
					return { errorMessage: "Invalid general notes" };
				}
				body.generalNotes = $scope.form.generalNotes;
			}

			// Case Details
			if (!$scope.mixedContent.caseDetails || $scope.editModeToggle.caseDetails) {
				if (typeof $scope.form.caseDetails !== "string") {
					return { errorMessage: "Invalid case details" };
				}
				body.caseDetails = $scope.form.caseDetails;
			}

			// Assigned Coordinators
			if (!$scope.mixedContent.assignedCoordinators || $scope.editModeToggle.assignedCoordinators) {
				if (!$scope.form.assignedCoordinatorsModel) {
					$scope.form.assignedCoordinatorsModel = [];
				}
				const coordinatorsIds = $scope.form.assignedCoordinatorsModel.map(c => c.id);
				if ($scope.mixedContent.assignedCoordinators && coordinatorsIds.length === 0) {
					return { errorMessage: "Please select assigned coordinators or keep original multiple values" };
				}
				if (coordinatorsIds.indexOf($rootScope.agencyMemberId) === -1) {
					coordinatorsIds.push($rootScope.agencyMemberId);
				}
				body.assignedCoordinators = coordinatorsIds;
			}

			switch ($scope.editShiftsParams.type) {
				case "CURRENT_SHIFTS":
					const shiftsToUpdate = $scope.selectedVisits.map(visit => ({
						visitInstanceId: visit.visitInstanceId,
						startTime: JSJoda.LocalTime.of(visit.startTime.hours(), visit.startTime.minutes()),
						endTime: JSJoda.LocalTime.of(visit.endTime.hours(), visit.endTime.minutes()),
						caregiverId: visit.caregiver ? visit.caregiver.id : $scope.form.caregiver ? $scope.form.caregiver.id : null,
					}));

					body.shifts = shiftsToUpdate;
					body.editMode = "SingleVisits";
					break;

				case "UNTIL_DATE":
					if ($scope.editShiftsParams.untilDate === undefined) {
						return { errorMessage: "Please choose date to edit shifts until" };
					}

				case "UNTIL_DATE":
				case "ENTIRE_SERIES":
				case "GOING_FORWARD":
				case "CHOSEN_DAYS":
					body.editMode = "WeeklyPattern";
					body.editParams = getEditParams();
					if (body.editParams.errorMessage) {
						return { errorMessage: body.editParams.errorMessage };
					}
					body.shouldUpdateWeeklyTemplate = [
						"ENTIRE_SERIES",
						"GOING_FORWARD",
						"CHOSEN_DAYS"
					].includes($scope.editShiftsParams.type);
					break;

				default:
					return { errorMessage: "Oops... Wrong Edit Mode" };
			};

			return body;
		}

		const getEditParams = () => {
			const visitsGroupedByDayOfWeek = _.groupBy(
				$scope.visitInstances,
				(visit) => moment(visit.start_time).format("dddd")
			);

			const editParams = [];
			const visitsGroupedByDayOfWeekKeys = Object.keys(visitsGroupedByDayOfWeek);
			for (let idx = 0; idx < visitsGroupedByDayOfWeekKeys.length; idx++) {
				const dayOfWeek = visitsGroupedByDayOfWeekKeys[idx];
				const visits = visitsGroupedByDayOfWeek[dayOfWeek];
				const dayFullName = $scope.form.isDifferentDayTimes ? dayOfWeek : "Everyday";
				const shift = $scope.form.advancedTimeShiftsPermanent[0][dayFullName];
				const startTime = shift.startTime;
				const endTime = shift.endTime;
				const caregiver = shift.caregiver;
				const footerCaregiver = $scope.form.caregiver;

				const params = {
					dayOfWeek: dayOfWeek.toUpperCase(),
					visitInstanceIds: visits.map(visit => visit.visit_instance_id),
				};

				if (!shift.mixedContent.startTimes || shift.editModeToggle.startTime) {
					if (!moment.isMoment(startTime)) {
						if (shift.mixedContent.startTimes) {
							return { errorMessage: `Please enter start time for ${dayFullName} or keep original multiple values` };
						} else {
							return { errorMessage: `Please enter start time for ${dayFullName}` };
						}
					}
					params.startTime = JSJoda.LocalTime.of(startTime.hours(), startTime.minutes());
				}

				if ((!shift.mixedContent.endTimes || shift.editModeToggle.endTime) && endTime) {
					if (!moment.isMoment(endTime)) {
						if (shift.mixedContent.endTimes) {
							return { errorMessage: `Please enter end time for ${dayFullName} or keep original multiple values` };
						} else {
							return { errorMessage: `Please enter end time for ${dayFullName}` };
						}
					}
					params.endTime = JSJoda.LocalTime.of(endTime.hours(), endTime.minutes());
				}

				if (!shift.mixedContent.caregivers || shift.editModeToggle.caregiver) {
					if (shift.mixedContent.caregivers && $scope.form.enableCaregivers.shiftsCanAssign === false) {
						return { errorMessage: `Please enter caregiver for ${dayFullName} or keep original multiple values` };
					}
					params.caregiverId = caregiver ?
						caregiver.id :
						footerCaregiver ? footerCaregiver.id : null;
				}

				editParams.push(params);
			}
			return editParams;
		};

		const updateExistingVisits = (broadcastParams) => {
			const body = getEditVisitsBody(broadcastParams);

			if (body.errorMessage) {
				toaster.pop("error", body.errorMessage);
				return;
			}

			// require note visits are visits that are either paid, billed or on payroll draft
			const requireNoteVisits = $scope.selectedVisits.filter(visit => visit.isBilled || visit.isPaid || visit.isOnPayrollDraft);
			if (requireNoteVisits.length === 0) {
				sendUpdateVisitsRequest(broadcastParams, body);
			} else {
				SweetAlert.swal({
					title: "Are you sure you want to save changes?",
					text: `Some of the selected visit instances are billed, paid or on payroll draft. Please post a note for the change.`,
					type: "input",
					showCancelButton: true,
					closeOnConfirm: true,
					animation: "slide-from-top",
					inputPlaceholder: "Type note content",
					html: true,
					allowOutsideClick: false,
					confirmButtonColor: "#3077EB",
					confirmButtonText: "Yes, save changes and post note",
				}, function (noteText) {
					if (!noteText) {
						toaster.pop("warning", "Note canceled, changes not saved");
						return;
					}
					body.approvalNote = noteText;
					sendUpdateVisitsRequest(broadcastParams, body);
				});
			}
		};

		const sendUpdateVisitsRequest = (broadcastParams, body) => {
			const url = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				$scope.patient.id
			);

			if (broadcastParams.isBroadcast) {
				$scope.isBroadcasting = true;
			} else {
				$scope.isSaving = true;
			}
			DatabaseApi.put(url, body).then((res) => {
				const hasSkippedVisitsMissingPermissions = res.data.skippedVisitsAndMissingPermissions !== null;
				const hasSkippedBroadcastForAssigned = (
					res.data.skippedBroadcastAssignedVisitInstnaces &&
					res.data.skippedBroadcastAssignedVisitInstnaces.length > 0
				);
				if (res.data.visitInstanceIds.length > 0) {
					if (
						!hasSkippedVisitsMissingPermissions &&
						res.data.overlappingIds.length === 0 &&
						!hasSkippedBroadcastForAssigned
					) {
						if (res.data.assignWithIncreasedCaregiverOvertime) {
							toaster.pop({
								type: 'warning',
								title: 'Warning',
								body: `Selected shifts succesfully updated with increased caregiver overtime`
							});
						} else {
							toaster.pop("success", "Selected shifts succesfully updated");
						}
					}
					$rootScope.$emit("refresh_visits");
					generalUtils.scrollToElement(entityNewVisitModalService.editVisitsData.targetElementId);
					$scope.closeParentModal();
				}

				if (
					hasSkippedVisitsMissingPermissions ||
					hasSkippedBroadcastForAssigned ||
					res.data.overlappingIds.length > 0 ||
					res.data.caregiversWithNoValidHireDate.length > 0
				) {
					VisitInstanceModalService.showSkippedVisitsErrorModal({
						skippedVisitsAndMissingPermissions: res.data.skippedVisitsAndMissingPermissions,
						updatedVisitsAmount: res.data.visitInstanceIds.length,
						action: broadcastParams.isBroadcast ? "broadcast" : "update",
						overlappingAmount: res.data.overlappingIds.length,
						caregiversWithNoValidHireDate: res.data.caregiversWithNoValidHireDate,
						skippedBroadcastAssignedVisitInstnaces: res.data.skippedBroadcastAssignedVisitInstnaces
					});
				}
			}, (err) => {
				let errorMessage = `Can't update existing shifts`;
				if (err.data && err.data.error === "Not permitted to increase caregiver overtime") {
					errorMessage = err.data.error;
				}
				toaster.pop("error", "Something went wrong", errorMessage);
			}).finally(() => {
				if (broadcastParams.isBroadcast) {
					$scope.isBroadcasting = false;
				} else {
					$scope.isSaving = false;
				}
			});
		};

		$scope.closeParentModal = () => {
			$rootScope.isNewVisitSideModalOpen = false;
			$scope.$parent.$ctrl.changeState(undefined);
			$rootScope.$emit("close_new_visit_modal");
		};

		const initEditModeDataFromService = () => {
			$scope.modalData = entityNewVisitModalService.editVisitsData;

			$scope.editShiftsParams = { type: $scope.modalData.editShiftsParams || "CURRENT_SHIFTS" };
			if ($scope.modalData.editShiftsParamsUntilDate) {
				$scope.editShiftsParams.untilDate = $scope.modalData.editShiftsParamsUntilDate;
			}
			$scope.editShiftsOptions = $scope.modalData.editShiftsOptions;
			$scope.visitInstances = $scope.modalData.visitInstancesDetails;
		};

		entityNewVisitModalService.registerObserverCallback(
			"visits",
			"entityNewVisitComponent",
			() => {
				if ($scope.editShiftsParams === undefined) {
					return;
				}
				const oldType = $scope.editShiftsParams.type;
				initEditModeDataFromService();
				const newType = $scope.editShiftsParams.type;
				getSelectedVisits();
				if (oldType !== newType) {
					initTimeSpanTabs();
					initMultipleValues();
				}
			}
		);

		$rootScope.$on('patient_profile_updated', () => {
			initPatientInfo();
			setVisitAddress($scope.patient.address);
		});

		const $offVisitLabels = $rootScope.$on("got_visit_labels", () => {
			$scope.visitLabelsData = DatabaseApi.visitLabelsData(false, true);
			initVisitLabelData();
		});
		const $offPlan = $rootScope.$on("got_plan_of_care_type", () => {
			$scope.planOfCareTypes = DatabaseApi.plansOfCare();
			initVisitLabelData();
		});
		const $offAgencyMembers = $rootScope.$on("got_agency_members", () => {
			$scope.gotAgencyMembers = true;
			initCoordinatorsOptions();
			initVisitFormAsssignedCoordinators();
		});
		const $offCaregiversData = $rootScope.$on("got_caregivers_data", function (event) {
			$scope.caregiversMap = DatabaseApi.caregivers();
			checkCaregiversState();
		});
		const $offData = $rootScope.$on("got_data", () => {
			initForms();
			initVisitLabelData();
			$scope.updateLabelsAndFormsOptions();
		});
		const $offCalendarNewVisit = $rootScope.$on("calendar_new_visit", initialize);
		const $offResetEditVisits = $rootScope.$on("reset_edit_visits", initialize);

		this.$onInit = () => {
			initialize();
		};

		this.$onDestroy = () => {
			entityNewVisitModalService.unregisterObserverCallback("visits", "entityNewVisitComponent");
			$offVisitLabels();
			$offPlan();
			$offAgencyMembers();
			$offCaregiversData();
			$offData();
			$offCalendarNewVisit();
			$offResetEditVisits();
		};
	}
});