/**
 * ORIGINAL FILE = payerService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
export class PayerService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    payers = undefined;
    constructor(DatabaseApi2, endpoint, $rootScope) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
    }
    /**
     * Get a list of agency payers
     */
    async getPayers(params = {
        onlyActives: false,
        refetch: false
    }) {
        const url = this.endpoint({
            path: "agencies/:agencyId/payers",
            params: {
                agencyId: this.$rootScope.agencyId
            },
        });
        if (!params.refetch && this.payers !== undefined) {
            return params.onlyActives ? this.payers.filter(row => row.isActive) : this.payers;
        }
        return this.DatabaseApi2.get(url).then((res) => {
            this.payers = res.data.payers;
            return params.onlyActives ? this.payers.filter(row => row.isActive) : this.payers;
        });
    }
    ;
}
angular.module("dashboard").service("payerService", PayerService);
