app.controller('visitDetailsCtrl', function ($scope, $rootScope, $http,  $window, Consts, NgTableParams, DatabaseApi, toaster, $uibModal, visit, $stateParams, SweetAlert, generalUtils) {

  window.scrollTo(0, 0);

  $scope.goBack = function() {
      $window.history.back();
  };

    $scope.modalOpen = function (sheet) {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/duty-sheet-modal.html',
            controller: 'dutySheetModalCtrl',
            resolve: {
              sheet: function () { return sheet; }
            },
            size: 'md'
        });
        modalInstance.result.then(function (res) {
            console.log( 'good' , res);
        }, function () {
            console.log('modal close');
        });

    };
    /*
$scope.getSheetPercent = function (sheet) {

        var total = 0;

    sheet.items.forEach(function (t) {
        if(t.asRequestedByPatient)
        if(t.completed)
    });

    };*/

  if($stateParams.visit && $stateParams.visit.id) {
      $scope.visit = $stateParams.visit;
      console.log('ccc');
      console.log(visit);
  };

  if(visit && visit.config && visit.data) {
      visit = visit.data;
      $scope.visit = visit;
      console.log('vvv');
      console.log(visit);
  };

  var settings = $rootScope.visitSettings;
  console.log(settings);
  console.log($scope.visit);

  if ($scope.visitDetails) {
    var visitData = $scope.visitDetails.visitsDetails;
  };

  if($rootScope.visitDetails){

      var a = angular.copy($rootScope.visitDetails);
      console.log('a');
      console.log(a);
      $scope.visit = angular.copy(visit);
  }
  $scope.visitDetails = $rootScope.visitDetails;


  if ($scope.visitDetails) $scope.visitDetails = $scope.visitDetails.visitsDetails;

  if(!$scope.visitDetails){
      $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + /visit/ + $stateParams.id + '/unconfirmed').then(function (res) {
          $scope.visitDetails = res.data.visits[0].visitsDetails;
          init();
      }, function (err) {
          toaster.pop('error', "Something went wrong", "could not get visit details");
      });
  }

  console.log($rootScope.visitDetails);
  function init() {

    initTable($scope.visitDetails);

  }

  if ($scope.visitDetails) {
    init();
  }


  function initTable(data) {
    $scope.table = new NgTableParams({
      count: 25,
      sorting: {startTime: 'asc'}
    }, {
      counts: [],
      dataset: data
    });
  }

  $scope.openMap = function (row) {

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/map-modal.html',
      size: 'md',
      controller: 'mapModalCtrl',
      resolve: {
        visit: function () {
          return row;
        }
      }

    });
    modalInstance.result.then(function (res) {

    }, function () {
    });

  };
  
  var clockDiff = settings.checkinMaxTime || 15;
  $scope.clockDiff = settings.checkinMaxTime || 15;
  $scope.isLessThan = function (a, b) {
      if(a < b) return true;
      return false
  }
  $scope.alertClockDiff = function (row, type) {

    //console.log($scope.clockDiff);
    //console.log(row);
    var schedule = type === 'in' ? row.scheduledStartTime : row.scheduledEndTime;
    var checked = type === 'in' ? row.checkinTime : row.checkoutTime;
    

    //if(!checked || !schedule) return 0;
    
    schedule = moment(schedule);
    checked = moment(checked);
    
    //console.log(checked.diff(schedule, 'minutes')) // 1
    
    return checked.diff(schedule, 'minutes');
    
  };

  $scope.isStarted = function (row) {

    var schedule = new Date(row.scheduledStartTime).getTime();
    var now = new Date().getTime();
    var diff = clockDiff * 60 * 1000;

    if(now >= schedule) return true;
    if(now + diff >= schedule) return true;


  };
  $scope.isEnded = function (row) {

    var schedule = new Date(row.scheduledEndTime).getTime();
    var now = new Date().getTime();
    var diff = clockDiff * 60 * 1000;

    if($scope.isStarted(row) &&  now - diff >= schedule) return true;

  };

  var distanceDiff = settings.checkinMaxDistance || 10;
  $scope.distanceDiff = settings.checkinMaxDistance || 10;
  $scope.alertDistanceDiff = function (row, type) {

    if(!row || !$scope.visit) return;
    var patient = $scope.visit.patientAddressLoc;
    var checked = type === 'in' ? row.checkinLocation : row.checkoutLocation;
    
    if(checked && patient){
      var pLat = patient.lat;
      var pLng = patient.lng;
      var cLat = checked.addressComponents.location.lat;
      var cLng = checked.addressComponents.location.lng;
      var d = generalUtils.distanceInKm(pLat,pLng,cLat,cLng); // d = km
      return d * 3280.84 > $scope.distanceDiff ? true : false;
    }

  };

  $scope.clickCancelScheduledVisit = function (scheduledId, tableIndex) {
    SweetAlert.swal({
      title: "Cancel This Scheduled Visit?",
      text: "Note!! All visit coordinators and visit caregiver will be notified.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, cancel visit",
      closeOnConfirm: true,
      closeOnCancel: true
  }, function (isConfirm) {
      if (isConfirm) {
        DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/agencyMember/' + $rootScope.agencyMemberId + '/scheduled_visits/' + scheduledId).then(function(res){
          $scope.visitDetails.splice(tableIndex, 1);
          $scope.table.reload();
        }, function(err){
          toaster.pop('error', "Something went wrong", 'Please try again');
        });
      }
  });
  }

  $rootScope.$on('caregiver_changed', function (event) {
    if ($scope.visit && $scope.visit.assignedCaregiver) {
      setCaregiversStatus();
    }
  });

  function setCaregiversStatus() {
    var caregiversMap = DatabaseApi.caregivers() || {};
    if ($scope.visit && $scope.visit.assignedCaregiver) {
      $scope.visit.assignedCaregiver = caregiversMap[$scope.visit.assignedCaregiver.id.toString()];
    }
  }
  setCaregiversStatus();
});
