app.controller('countiesModalCtrl', function ($scope, DatabaseApi, $rootScope, toaster, $timeout,) {
    $scope.newGroupName = '';

    $scope.addLocationGroup = function () {
        for(let locationgroup of DatabaseApi.counties()){
            if(locationgroup.name.toLowerCase() === $scope.newGroupName.toLowerCase()){
                toaster.pop('error', `Failed to add new location group in name ${$scope.newGroupName} Already exist`);
                return;
            }
        }
        DatabaseApi.addLocationGroup({ name: $scope.newGroupName }).then(function (res) {
            toaster.pop('success', "Location gorup added successfuly");
            DatabaseApi.getLocationGroupsAndCounties();
            $scope.locationGroups.push(res.data);
        }, function (err) {
            toaster.pop('error', "Failed to add new location group");
        });
    }

    $scope.updateCountyLocationGroup = function (countyId, locationGroupId) {
        DatabaseApi.updateCountyLocationGroup(countyId, { locationGroupId: locationGroupId }).then(function (res) {
            DatabaseApi.getLocationGroupsAndCounties();
            toaster.pop('success', "Location group was updated");
        }, function (err) {
            toaster.pop('error', "Failed to update location group for current county");
        });
    }

    function updateLocationOffices(officeIds, actionType) {
        const officeIdsObject = {
            officeIds: officeIds,
            type: actionType
        }
        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_member/' + $rootScope.agencyMemberId
            + '/location_group/' + $scope.editableLocationGroupId, officeIdsObject).then(function (res) {
                DatabaseApi.getLocationGroupsAndCounties();
                toaster.pop('success', "Location Offices updated successfully");
            }, function (err) {
                toaster.pop('error', "Could not update location offices");
            });
    }

    $scope.setSelectedLocationGroupId = function (locationGroupId) {
        if ($scope.editableLocationGroupId === locationGroupId) {
            return;
        }
        $scope.editableLocationGroupId = locationGroupId;
    }

    $scope.locationOfficesEvents = {
        onItemSelect: function (item) {
            updateLocationOffices([item.id], "ASSOCIATE");
        },

        onItemDeselect(item) {
            updateLocationOffices([item.id], "DISSOCIATE");
        },

        onSelectAll() {
            const officeIds = $scope.offices.map(office => office.id)
            updateLocationOffices(officeIds, "ASSOCIATE");
        },
        onDeselectAll() {
            const officeIds = $scope.offices.map(office => office.id)
            updateLocationOffices(officeIds, "DISSOCIATE");
        }
    }
});