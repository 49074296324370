'use strict'
app.controller('callsMissedBothCtrl', function ($scope, NgTableParams, itemSearchPageManager, toaster) {

    $scope.tableParams = null;

    function initialize() {
        initPageManager();
        $scope.loadItems();
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/missed_clocks");

        $scope.pageManager.initSearchParam("type", "BOTH");
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.updateSearchParamValue("from", new Date(JSJoda.LocalDate.now().minusMonths(1).format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
        $scope.pageManager.updateSearchParamValue("to", new Date(JSJoda.LocalDate.now().format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    };

    var initTable = function (items) {
        var options = {
            count: 25,
            sorting: { id: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {

            var calls = response.data.visitInstances;
            initTable(calls);
        }, function (error) {

            toaster.pop("error", "Failed to load visits for calls with missed clock-in and clock-out");
        });
    };

    initialize();
});