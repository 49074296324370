"use strict";

angular
  .module("dashboard")
  .controller(
    "certificationPeriodModal",
    function ($rootScope, $scope, $uibModalInstance, DatabaseApi, toaster, apiErrors, dateUtils, mfModal) {
      const allCertificationPeriods = $scope.$resolve.allCertificationPeriods ?? [];
      const certificationPeriod = $scope.$resolve.certificationPeriod ?? null;
      const patientId = $scope.$resolve.patientId ?? null;
      const patientStartOfCareDate = $scope.$resolve.patientStartOfCareDate;

      $scope.durationOptions = [
        { label: "Custom", days: null },
        { label: "30 Days", days: 30 },
        { label: "60 Days", days: 60 },
        { label: "90 Days", days: 90 },
        { label: "120 Days", days: 120 },
        { label: "180 Days", days: 180 },
      ];

      $scope.mode = certificationPeriod?.id ? "Edit" : "Create";
      $scope.form = (() => {
        const latestCertificationPeriod =
        [...allCertificationPeriods].sort((a, b) => a.endDate > b.endDate)[0] ?? null;
        let startDate = null;
        let endDate = null;

        if (latestCertificationPeriod !== null) {
          const startDateAsLocalDate = dateUtils
            .ymdStringToLocalDate(latestCertificationPeriod.endDate)
            .plusDays(1);

          startDate = dateUtils.localDateToDate(startDateAsLocalDate);
          endDate = dateUtils.localDateToDate(startDateAsLocalDate.plusDays(180));
        }

        return {
          type: { id: "REASSESSMENT" },
          startDate: startDate,
          endDate: endDate,
          duration: null,
          ...certificationPeriod,
        };
      })();

      if (certificationPeriod?.type) {
        $scope.form.type = { id: certificationPeriod.type };
      }

      if (certificationPeriod?.id) {
        $scope.form.startDate = dateUtils.ymdStringToDate(certificationPeriod.startDate);
        $scope.form.endDate = dateUtils.ymdStringToDate(certificationPeriod.endDate);
      }

      $scope.isLoading = false;
      $scope.isSubmitting = false;
      $scope.anomalies = null;
      $scope.validationErrors = null;
      $scope.allowedCertificationPeriodTypes = [
        { id: "START_OF_CARE", label: "Start of Care" },
        { id: "REASSESSMENT", label: "Re-assessment" },
      ];

      $scope.$watchGroup(["form.type.id", "form.startDate", "form.endDate"], () => {
        if ($scope.form.startDate === null || $scope.form.endDate === null) {
          return;
        }

        $scope.checkAnomalies().then((anomalies) => {
          $scope.anomalies = anomalies;
        });

        const startDate = dateUtils.dateToLocalDateTime($scope.form.startDate);
        const endDate = dateUtils.dateToLocalDateTime($scope.form.endDate);
        const duration = Duration.between(startDate, endDate).toDays();

        $scope.validationErrors = (() => {
          if (duration < 1) {
            return "Duration between start date and end date should be at least 1 day.";
          }

          if ($scope.mode === "Edit" && $scope.form.type.id === "REASSESSMENT") {
            const previousStartOfCare = allCertificationPeriods.find((x) => {
              return (
                x.type === "START_OF_CARE" &&
                x.id !== certificationPeriod.id &&
                dateUtils.dateToLocalDateTime(dateUtils.ymdStringToDate(x.startDate)).isBefore(startDate)
              );
            });

            if (previousStartOfCare === undefined) {
              return "Can't update this period to Re-assessment since there should be at least one Start of Care period before this period.";
            }
          }

          return null;
        })();

        $scope.isFormValid =
          $scope.form.startDate && $scope.form.endDate && $scope.validationErrors === null;

        $scope.form.duration =
          $scope.durationOptions.find((x) => x.days === duration)?.days ?? null;
      });

      $scope.checkAnomalies = () => {
        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods/check_anomalies"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", patientId);

        const body = {
          startDate: dateUtils.dateToLocalDate($scope.form.startDate),
          endDate: dateUtils.dateToLocalDate($scope.form.endDate),
          patientId: patientId,
          certificationPeriodId: certificationPeriod?.id ?? null,
        };

        $scope.isLoading = true;

        const promise = DatabaseApi.post(url, body);

        promise
          .catch((e) => {
            console.error(e);
            toaster.pop(
              "error",
              "Something went wrong",
              apiErrors.format(e, "We have failed to check certification period anomalies")
            );
          })
          .finally(() => ($scope.isLoading = false));

        return promise.then(({ data }) => data);
      };

      $scope.setDuration = (days) => {
        if (days === null) {
          return;
        }

        const startDate = dateUtils.dateToLocalDateTime($scope.form.startDate);
        const endDate = startDate.plusDays(days);

        $scope.form.endDate = JSJoda.convert(endDate).toDate();
      };

      $scope.setCompleted = (value) => {
        $scope.form.isCompleted = value;
        $scope.form.isCompletedManually = value;
      };

      $scope.submit = () => {
        const request = (() => {
          switch ($scope.mode) {
            case "Create":
              return $scope.submitCreate();
            case "Edit":
              return $scope.submitEdit();
            default:
              throw new Error(`unexpected mode "${mode}"`);
          }
        })();

        $scope.isSubmitting = true;

        return request
          .then(({ data }) => {
            $scope.$resolve.onSubmitted?.call(null, data);
            toaster.pop("success", "Saved");
            $uibModalInstance.close();
          })
          .catch((e) => {
            console.error(e);
            toaster.pop("error", "Save failed", apiErrors.format(e));
          })
          .finally(() => ($scope.isSubmitting = false));
      };

      $scope.submitCreate = () => {
        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods/"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", patientId);

        const body = {
          type: $scope.form.type.id,
          startDate: dateUtils.dateToLocalDate($scope.form.startDate),
          endDate: dateUtils.dateToLocalDate($scope.form.endDate),
        };

        return DatabaseApi.post(url, body);
      };

      $scope.submitEdit = () => {
        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods/:certificationPeriodId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", patientId)
            .replace(":certificationPeriodId", certificationPeriod.id);

        const body = {
          type: $scope.form.type.id,
          startDate: dateUtils.dateToLocalDate($scope.form.startDate),
          endDate: dateUtils.dateToLocalDate($scope.form.endDate),
          isCompleted: $scope.form.isCompleted,
        };

        return DatabaseApi.put(url, body);
      };

      $scope.handleSubmitClick = () => {
        if ($scope.form.type.id !== "START_OF_CARE") {
          return $scope.submit();
        }

        if (moment($scope.form.startDate).isAfter(moment(patientStartOfCareDate))) {
          const modal = mfModal.create({
            subject: "Start Of Care Update",
            variant: "info",
            message: `Please note, this will also update the patient start of care date.`,
            hideCancelButton: false,
            confirmLabel: "Yes, confirm",
            onConfirm: () => modal.close() && $scope.submit(),
          });
        } else {
          $scope.submit();
        }
      }
    }
  );
