app.controller('rnVisitMapModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, DatabaseApi, toaster, Socket, SweetAlert, $timeout, $interval, markers, caregiverId){

    window.scrollTo(0,0);


    console.log(markers);
    console.log(caregiverId);

    $scope.picked;

    prosMap = DatabaseApi.caregivers() || {};

    $scope.closeModal = function(){
        $uibModalInstance.close();
    };


    $scope.pickCaregiver = function (caregiver) {
        console.log(caregiver);
        $scope.picked = caregiver;

        if(caregiver.caregiver.geoLocation) {
            $scope.picked.geo = {
                latitude: caregiver.caregiver.geoLocation.lat,
                longitude: caregiver.caregiver.geoLocation.lng
            };
        } else {
            $scope.picked.geo = {
                latitude: caregiver.visits[0].patientGeoLocation.lat,
                longitude: caregiver.visits[0].patientGeoLocation.lng
            };
        }

    };

    if(markers && markers.length){

        var cc = prosMap[caregiverId];
        if(!cc) return;
        var data = [ {caregiver:cc} ];


        data[0].visits = markers[0];

        console.log(data);


        data.forEach(function (caregiver) {

            caregiver.dates = {};

            console.log(caregiver.visits)
            caregiver.visits.forEach(function (visit) {
console.log('visit')
console.log(visit)
                visit.geo = {latitude: visit.patientGeoLocation.lat, longitude: visit.patientGeoLocation.lng};
                var day = visit.startTime.split('T')[0];
                if (!caregiver.dates[day]) caregiver.dates[day] = [];
                caregiver.dates[day].push(visit);

            });

        });

        console.log(data);

        $scope.rns = data;

        $scope.pickCaregiver($scope.rns[0]);


    } else {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/rn_visits').then(function (res) {

            var data = res.data.rnVisits;

            data.forEach(function (caregiver) {

                caregiver.dates = {};

                caregiver.visits.forEach(function (visit) {

                    visit.geo = {latitude: visit.geoLocation.lat, longitude: visit.geoLocation.lng};
                    var day = visit.startTime.split('T')[0];
                    if (!caregiver.dates[day]) caregiver.dates[day] = [];
                    caregiver.dates[day].push(visit);

                });

            });

            console.log(data);

            $scope.rns = data;

            $scope.isLoading = false;
        }, function (err) {
            toaster.pop('error', 'Something went wrong');


            var data = [
                {
                    caregiver: {
                        id: 1,
                        firstName: 'Nora',
                        lastName: 'Jones',
                        photoUrl: '',
                        geoLocation: {"lat": 40.6869869, "lng": -73.94508100000002}
                    },
                    visits: [
                        {
                            id: 1,
                            patientName: 'patient 1',
                            geoLocation: {"lat": 40.7534655, "lng": -73.97002359999999},
                            startTime: '2018-04-04T16:30'
                        }, {
                            id: 2,
                            patientName: 'patient 1',
                            geoLocation: {"lat": 40.7534655, "lng": -73.97002359999999},
                            startTime: '2018-04-05T16:30'
                        }, {
                            id: 3,
                            patientName: 'patient 1',
                            geoLocation: {"lat": 40.7534655, "lng": -73.97002359999999},
                            startTime: '2018-04-06T16:30'
                        }, {
                            id: 4,
                            patientName: 'patient 1',
                            geoLocation: {"lat": 40.7534655, "lng": -73.97002359999999},
                            startTime: '2018-04-06T16:30'
                        }
                    ]
                }
            ];


            data.forEach(function (caregiver) {

                caregiver.dates = {};

                caregiver.visits.forEach(function (visit) {

                    visit.geo = {latitude: visit.geoLocation.lat, longitude: visit.geoLocation.lng};
                    var day = visit.startTime.split('T')[0];
                    if (!caregiver.dates[day]) caregiver.dates[day] = [];
                    caregiver.dates[day].push(visit);

                });

            });

            data.push(data[0]);
            data.push(data[0]);
            data.push(data[0]);

            console.log(data);

            $scope.rns = data;

        });
    }



    $scope.pickDay = function (day) {
        console.log(day);
        $scope.picked.day = day;
        if (day[0].geoLocation) {
            $scope.picked.day.center = {latitude: day[0].geoLocation.lat, longitude: day[0].geoLocation.lng};

        } else {
            $scope.picked.day.center = {latitude: day[0].patientGeoLocation.lat, longitude: day[0].patientGeoLocation.lng};
        }
    };
});