app.controller('sweepCtrl', function($scope, $rootScope, $state, DatabaseApi, $q, $stateParams, toaster, $filter, NgTableParams, SweetAlert, $interval){
  
  
  window.scrollTo(0,0);
  
  
  DatabaseApi.get('agencies/' + $rootScope.agencyId + '/lottery_sweepstakes_top_ten').then(function(res){
    res.data.scores.forEach(function (v) {
        v.tickets = Math.floor(v.points/1000);
    });
    $scope.top = res.data.scores;
    console.log($scope.top);
  }, function(err){
    toaster.pop('error', "Something went wrong", 'Please try again');
  });
  
  

  

});