app.component("caregiversTrainingCenterMaterialStatsTable", {
    templateUrl: "admin/views/caregivers-training-center-material-stats-table.html",
    bindings: {
        bundle: '<',
    },
    controller: function ($scope, TrainingCenterOverviewService, NgTableParams, toaster) {
        const bundleTypeMapping = {
            VIDEO: "Video",
            PDF: "PDF",
            TEST_ONLY: "Test Only",
        }

        this.initMaterialStatisticsTable = () => {
            this.materialStatisticsData.forEach(item => {
                item.type = bundleTypeMapping[item.itemType];
            });
            const options = {
                count: 25
            };

            this.bundleMaterialStatisticsTable = new NgTableParams(options, {
                counts: [],
                dataset: this.materialStatisticsData
            });
        }

        this.$onChanges = function (changedData) {
            const previousValue = changedData.bundle.previousValue;
            if (!changedData.bundle || Object.keys(previousValue).length === 0)
                return;

            this.getMaterialStatistics();
        }

        this.getMaterialStatistics = function () {
            $scope.isFetching = true;
            TrainingCenterOverviewService.getMaterialStatisticsTableByBundleId(
                $scope.$ctrl.bundle.bundleId)
                .then(({ data }) => {
                    this.materialStatisticsData = data.rows;
                    this.initMaterialStatisticsTable();
                })
                .catch(() => toaster.pop("error", "Failed to fetch bundle material statistics"))
                .finally(() => this.isFetching = false);
        }

        this.$onInit = function () {
            this.getMaterialStatistics();
        }
    }
});