export class DatabaseApi2 {
    $http;
    Consts;
    constructor($http, Consts) {
        this.$http = $http;
        this.Consts = Consts;
    }
    get = (route) => {
        return this.$http.get(this.Consts.api + route);
    };
    post = (route, body) => {
        return this.$http.post(this.Consts.api + route, body);
    };
    put = (route, body) => {
        return this.$http.put(this.Consts.api + route, body);
    };
    patch = (route, body) => {
        return this.$http.patch(this.Consts.api + route, body);
    };
    delete = (route) => {
        return this.$http.delete(this.Consts.api + route);
    };
}
angular.module("dashboard").service("DatabaseApi2", DatabaseApi2);
