"use strict";

app.controller('newPatientPhysicianModalCtrl', function (
    $scope,
    DatabaseApi,
    physiciansService,
    $uibModal,
    $uibModalInstance,
    toaster,
    patientId,
    patientPhysicians
) {
    $scope.patientId = patientId;
    $scope.patientPhysicians = patientPhysicians;

    function initialize() {
        $scope.form = {};
        $scope.data = {
            physicianId: null,
            isPrimary: true
        }
    }

    function cleanPhysicianSelection() {
        setTimeout(() => {
            $scope.selectedPhysician = null;
            $scope.data.physicianId = null;
        }, 0);
    }

    $scope.physiciansutoCompleteOptions = {
        minimumChars: 1,
        noMatchTemplateEnabled: false,
        data: function (searchText) {
            return physiciansService.getPhysiciansBySearchString(searchText)
        },
        renderItem: function (item) {
            const text = item.displayName + ' - ' + item.address + ' - ' + item.npi;
            return {
                value: item.displayName,
                label: "<p class='auto-complete'>" + text + "</p>"
            };
        },
        itemSelected: function (e) {
            $scope.selectedPhysician = e.item;
            const existingPhysician = $scope.patientPhysicians.find(ep => ep.physicianId === $scope.selectedPhysician.id);
            if (existingPhysician) {
                cleanPhysicianSelection();
                toaster.pop('error', "Patient already have selected physician");
            }
        }
    }

    $scope.openNewPhysicianModal = () => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/new-physician-modal.html',
            size: 'lg',
            controller: 'newPhysicianModalCtrl',
            resolve: {
                data: () => undefined
            }
        });

        modalInstance.result.then(function (res) {
            if (res.id) {
                const physicians = DatabaseApi.physicians();
                const newPhysician = res;

                newPhysician.displayName = newPhysician.firstName + ' ' + newPhysician.lastName;
                physicians.push(newPhysician);
                DatabaseApi.storePhysicians(physicians);
                $scope.selectedPhysician = newPhysician;
                $scope.data.physicianId = $scope.selectedPhysician.displayName;
            }
        }, function () {
        });
    };

    $scope.handleOnClickRemoveSelectedPhysician = () => {
        cleanPhysicianSelection();
    }

    $scope.submitForm = function () {
        if ($scope.form.$invalid) {
            return;
        }
        const body = {
            physicianId: $scope.selectedPhysician.id,
            isPrimary: $scope.data.isPrimary
        };

        physiciansService.createPatientPhysician($scope.patientId, body)
            .then(function (res) {
                toaster.pop('success', "Patient physician created successfully");
                $uibModalInstance.close(res.data);
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not create a new physician for a patient");
            });
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    initialize();
});
