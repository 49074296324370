app.controller("authorizationUtilizationModalCtrl", function ($scope, $rootScope, $uibModalInstance, DatabaseApi, NgTableParams, toaster, authorization) {

    $scope.isLoading = true;
    $scope.hasData = false;

    DatabaseApi.get("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/authorization_utilization/" + authorization.id).then(function (res) {
        $scope.isLoading = false;
        $scope.hasData = true;

        initTable(res.data.periodUtilizations);
    }, function (err) {
        $scope.isLoading = false;
        $scope.hasData = false;
        toaster.pop("error", "Something went wrong", "could not load contracts and service codes");
    });

    function initTable(items) {
        let options = {
            count: 25
        };

        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    }

    $scope.closeModal = function () {
        $uibModalInstance.close("ok");
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };
});