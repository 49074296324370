"use strict";
app.controller('reportEntityPermissionsModalCtrl', function ($scope, $uibModalInstance, agencyPermissionsRolesService, reportService, toaster) {
    $scope.allRoles = [];
    $scope.entityRoles = {};
    
    function initialize() {
        loadAllRoles().then(res => {
            loadEntityRoles();
        });
    }

    function loadAllRoles() {
        return agencyPermissionsRolesService.getAgencyRoles().then(res => {
            $scope.allRoles = res;
            $scope.allRolesMap = rolesMap();
        });
    }

    function loadEntityRoles() {
        reportService.getReportEntityRoles().then(res => {
            for (const entityRole of res) {
                $scope.entityRoles[entityRole.entity] = entityRole.roles.map(roleId => ({
                    id: roleId,
                    name: $scope.allRolesMap[roleId]
                }))
            }
        })
    }

    $scope.handleSelectEntityRole = (entityLabel, roleId) => {
        reportService.editReportEntityRole({entityLabel, roleId, type: "ASSOCIATE"}).then(res => {
            toaster.pop("success", "Success", "Added role succeffuly");
        }).catch(err => {
            toaster.pop("error", "Something went wrong", "Failed to add role")
        });
    }

    $scope.handleRemoveEntityRole = (entityLabel, roleId) => {
        reportService.editReportEntityRole({entityLabel, roleId, type: "DISSOCIATE"}).then(res => {
            toaster.pop("success", "Success", "Removed role succeffuly");
        }).catch(err => {
            toaster.pop("error", "Something went wrong", "Failed to remove role")
        });
    }

    function rolesMap() {
        return $scope.allRoles.reduce((acc, cur) => ({...acc, [cur.id]: cur.name}), {});
    }

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };

    initialize();
});