app.controller('invoicedVisitNotesModalCtrl', function ($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {

    const loadNotes = () => {
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_instance_ar_notes/${$scope.$resolve.visitInstanceId}`;
        DatabaseApi.get(url).then((res) => {
            $scope.notes = res.data.notes;
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get visit's notes");
        });
    }
    if ($scope.$resolve.notes === undefined) {
        loadNotes();
    } else {
        $scope.notes = $scope.$resolve.notes;
    }

    $scope.getAgencyMemberDisplayNameById = (agencyMemberId) => {
        const agencyMember = $scope.$resolve.agencyMembers.find(agencyMember => agencyMember.id === agencyMemberId);
        return agencyMember ? agencyMember.displayName : "";
    }

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };
});