'use strict'

app.controller(
  "calendarNewVisitModalCtrl",
  function (
    $scope,
    $uibModalInstance,
    DatabaseApi,
    $rootScope,
    toaster,
    moment
  ) {
    $scope.data = $scope.$resolve.data;

    const contracts = $scope.data.contracts || [];
    const startDay = $scope.data.startDays[0];
    $scope.upToDateContracts = contracts.filter(
      (contract) =>
        contract.endDate === null ||
        startDay.diff(moment(contract.endDate)) < 0
    );

    $scope.caregiversMap = DatabaseApi.caregivers() || {};

    $rootScope.$on('caregiver_changed', function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers();
    });

    $scope.caregiverFilter = { val: '' };

    $scope.setCaregiver = function (line) {
        $scope.caregiverFilter.val = '';
        $scope.form.caregiver = line;
    }

    $scope.clearRn = function () {
        $scope.caregiverFilter.val = '';
        $scope.form.caregiver = undefined;
    }

    $scope.onlyUniqueCertifications = function (arr) {
        return arr.map(function (item) { return (item.certification) }).filter(function (value, index, self) { return (self.indexOf(value) === index) });
    }

    $scope.getCurrentContract = function () {
        return ($scope.data.contracts.find(function (curr) { return (curr.id === $scope.form.patientContractId) }));
    };

    $scope.updateServiceCodeOptions = function () {
        if ($scope.form.certification !== undefined) {
            $scope.form.serviceCodeOptions = DatabaseApi.activeServiceCodes().filter(function (currServiceCode) {
                return ($scope.billingRates.some(function (currBillingRate) {
                    return (currBillingRate.serviceCode === currServiceCode.id &&
                        currServiceCode.certification === $scope.form.certification);
                }));
            });

            if ($scope.form.serviceCodeOptions.length === 1) {
                $scope.form.serviceCode = $scope.form.serviceCodeOptions[0];
                $scope.updatePayrollOptions();
            }
        } else {
            $scope.form.serviceCodeOptions = [];
            $scope.form.serviceCode = undefined;
            $scope.updatePayrollOptions();
        }
    };

    $scope.updatePayrollOptions = function () {
        if ($scope.form.serviceCode !== undefined) {
            $scope.form.payrollCodeOptions = DatabaseApi.activePayrollCodes().filter(function (curr) {
                return ($scope.form.serviceCode.payrollCodes.some(function (currPayrollId) { return (curr.id === currPayrollId); }));
            });

            if ($scope.form.payrollCodeOptions.length === 1) {
                $scope.form.payrollCodeId = $scope.form.payrollCodeOptions[0].id;
            }
        } else {
            $scope.form.payrollCodeOptions = [];
            $scope.form.payrollCodeId = undefined;
        }
    };

    $scope.updateCertificationOptions = function () {
        var currentContract = $scope.getCurrentContract();

        function showLoader() {
            var certificationLoader = document.querySelectorAll(".certification-loader");

            certificationLoader.forEach(function (curr) {
                curr.style = "display: block;";
            });
        }

        function hideLoader() {
            var certificationLoader = document.querySelectorAll(".certification-loader");

            certificationLoader.forEach(function (curr) {
                curr.style = "display: none;";
            });
        }

        if (currentContract !== undefined) {
            var contractType = DatabaseApi.contractTypes().find(function (curr) { return (curr.name === currentContract.contractTypeName) });
            $scope.form.certificationOptions = [];
            $scope.form.serviceCode = undefined;
            showLoader();
            DatabaseApi.getBillingRates(contractType.id).then(function (data) {
                hideLoader();
                $scope.billingRates = data.data.billingRates.filter(function (curr) {
                    let sortedDays = $scope.data.startDays.sort(function (a, b) {
                        return Date.parse(a) > Date.parse(b);
                    });
                    let earliestVisitDay = sortedDays[0];
                    let latestVisitDay = sortedDays[sortedDays.length - 1];
                    return (earliestVisitDay.isAfter(curr.startDate) &&
                        latestVisitDay.isBefore(curr.endDate));
                });

                var serviceCodeOptions = DatabaseApi.activeServiceCodes().filter(function (currServiceCode) {
                    return ($scope.billingRates.some(function (currBillingRate) {
                        return (currBillingRate.serviceCode === currServiceCode.id);
                    }));
                });

                $scope.form.uniqueCertifications = $scope.onlyUniqueCertifications(serviceCodeOptions);

                if ($scope.form.uniqueCertifications.length === 1) {
                    $scope.form.certification = $scope.form.uniqueCertifications[0];
                    $scope.updateServiceCodeOptions();
                }
            });
        } else {
            $scope.form.uniqueCertifications = [];
            $scope.billingRates = [];
            $scope.form.certification = undefined;
            $scope.updateServiceCodeOptions();
        }
    };

    // make hours intervals
    var interval = 15; //minutes interval
    var times = []; // time array
    var times24 = []; // time array
    var tt = 0; // start time
    for (var i = 0; tt < 24 * 60; i++) {
        var clock = ' AM';
        var hh = Math.floor(tt / 60);
        var realHH = Math.floor(tt / 60); // getting hours of day in 0-24 format
        if (hh === 0) hh = 12;
        if (times.length > 47) {
            if (hh !== 12) hh -= 12;
            clock = ' PM';
            //console.log('pm')
        }
        var mm = (tt % 60); // getting minutes of the hour in 0-55 format
        times[i] = {
            label: hh + ':' + ("0" + mm).slice(-2) + clock,
            value: ("0" + realHH).slice(-2) + ':' + ("0" + mm).slice(-2),
            valueTrimmed: realHH + ':' + ("0" + mm).slice(-2)
        };
        if (i) {
            times24[i] = realHH + ':' + ("0" + mm).slice(-2);
        }
        tt = tt + interval;
        //console.log(i);
    }
    times24.push('24:00');
    $scope.times = times;

    $scope.toggleAmPm = function (time, day, startend, shift) {
        if (!time || !startend) return;

        if (!day || !day[startend]) return;

        var timePart = time.split(' ')[0];
        var ampmPart = time.split(' ')[1] === 'AM' ? 'PM' : 'AM';

        // Shift overlap warning
        if ($scope.form.shifts > 1) {
            var tempShift = Object.assign({}, day);

            tempShift[startend] = timePart + ' ' + ampmPart;

            shiftsOverlap(tempShift, day, shift);
        }

        day[startend] = timePart + ' ' + ampmPart;
    };

    $scope.selectTimePicker = function (time, day, startend, shift) {
        if (!time || !startend) return;

        if (!day || !day[startend]) return;

        var currentAmPm = day[startend].split(' ')[1];
        var timePart = time.split(' ')[0];

        // Shift overlap warning
        if ($scope.form.shifts > 1) {
            var tempShift = Object.assign({}, day);

            tempShift[startend] = timePart + ' ' + currentAmPm;

            shiftsOverlap(tempShift, day, shift);
        }

        day[startend] = timePart + ' ' + currentAmPm;
    };

    $scope.form = {
        startDays: $scope.data.startDays.map(date => date.format("MMM Do YY")).join(', '),
        patientContractId: $scope.data.contracts.length === 1 ? $scope.data.contracts[0].id : undefined,
        shouldBroadcast: false,
        day: {
            day: 0,
            startTime: '9:00 AM',
            endTime: '1:00 PM'
        }
    };

    $scope.updateCertificationOptions();

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

    $scope.getTime24base = function (time) {
        return moment(time, ["h:mm A"]).format("HH:mm");
    }

    $scope.submit = function () {
        let reqBody = { newVisits: [] };
        var startTimeSplit = $scope.getTime24base($scope.form.day.startTime).split(':');
        var endTimeSplit = $scope.getTime24base($scope.form.day.endTime).split(':');

        const genericVisit = {
            patientId: $scope.data.patientId,
            caregiverId: $scope.form.shouldBroadcast ? null : ($scope.form.caregiver ? $scope.form.caregiver.id : null),
            clockinTime: null,
            clockoutTime: null,
            clockinLocation: null,
            clockoutLocation: null,
            clockinType: null,
            clockoutType: null,
            masterWeekId: null,
            patientContractId: $scope.form.patientContractId,
            serviceCodeId: $scope.form.serviceCode.id,
            payrollCodeId: $scope.form.payrollCodeId || null,
            payrollAdjustmentSeconds: null,
            missedVisit: false,
            billable: true,
            address: {
                address: $scope.data.address.text,
                timezone: 'America/New_York',
                state: ($scope.data.address.components.formatedAddressDetails && $scope.data.address.components.formatedAddressDetails.state)
                    ? $scope.data.address.components.formatedAddressDetails.state
                    : $scope.data.address.components.administrativeAreaLevel1,
                countyName: ($scope.data.address.components.formatedAddressDetails && $scope.data.address.components.formatedAddressDetails.county)
                    ? $scope.data.address.components.formatedAddressDetails.county
                    : $scope.data.address.components.administrativeAreaLevel2
            }
        };

        $scope.data.startDays.forEach(day => {
            const startCopy = angular.copy(day);
            const startDateTime = day.set('hour', startTimeSplit[0]).set('minute', startTimeSplit[1]);
            const endDateTime = startCopy.set('hour', endTimeSplit[0]).set('minute', endTimeSplit[1]);
            if (startDateTime.isAfter(endDateTime)) {
                endDateTime.add(1, 'day');
            }

            const newVisit = { ...genericVisit };
            newVisit.startTime = convertDate2(startDateTime);
            newVisit.endTime = convertDate2(endDateTime);
            if (newVisit.endTime._time === newVisit.startTime._time) {
                newVisit.endTime._date = newVisit.endTime._date.plusDays(1);
            }

            reqBody.newVisits.push(newVisit);
        });

        DatabaseApi.post(
            'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/visit_instances',
            reqBody
        ).then(function (res) {
            if (res.data.visitInstances.length > 0) {
                if (res.data.assignWithIncreasedCaregiverOvertime) {
                    toaster.pop({
                        type: 'warning',
                        title: 'Warning',
                        body: `Visit${caregiverChangeBlockIds.length > 0 ? "s were" : " was"} created with increased caregiver overtime`
                    });
                } else {
                    let successMessage = 'Visit' + ((res.data.visitInstances.length > 1) ? 's' : '') + ' created';
                    toaster.pop('success', "Success", successMessage, ' ');
                }
            }

            if (res.data.overlappingCaregiver.length > 0) {
                const sentencePart = res.data.overlappingCaregiver.length > 1 ? "visits were" : "visit was";
                const visitTimes = data.overlappingCaregiver.map((visit, index) => `Visit ${index + 1} - ${moment(visit.startTime).format("MM/DD/YYYY h:mm a")}`).join("<br />");
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: `${res.data.overlappingCaregiver.length} ${sentencePart} not added due to overlap with other visits of the same caregiver as following:<br />${visitTimes}`,
                    timeout: 7000
                });
            }

            if (res.data.overlappingPatient.length > 0) {
                const sentencePart = res.data.overlappingPatient.length > 1 ? "visits were" : "visit was";
                const visitTimes = data.overlappingPatient.map((visit, index) => `Visit ${index + 1} - ${moment(visit.startTime).format("MM/DD/YYYY h:mm a")}`).join("<br />");
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: `${res.data.overlappingPatient.length} ${sentencePart} not added due to overlap with other visits of the same patient as following:<br />${visitTimes}`,
                    timeout: 7000
                });
            }

            $uibModalInstance.close("ok");
        }, function (err) {
            if (err.data && err.data.error === "Not permitted to increase caregiver overtime") {
                toaster.pop('error', 'Error', err.data.error);
            } else {
                toaster.pop('error', 'Error', 'Visit creation failed');
            }
            console.error(err);
            $uibModalInstance.close(err);
        });
    };
});

function convertDate(date) {
    var d = new Date(date);
    var jodaDate;

    jodaDate = JSJoda.LocalDateTime.of(d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds() * 1000000);

    return jodaDate;
}

function convertDate2(date) {
    var d = new Date(date);
    var jodaDate;

    jodaDate = JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), 0, 0);

    return jodaDate;
}


function convertDateNoUTC(date) {
    var d = new Date(date);
    var jodaDate;

    jodaDate = JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds() * 1000000);

    return jodaDate;
}