app.controller('videoSessionsModalCtrl', function ($scope, $rootScope, NgTableParams, DatabaseApi) {
    $scope.video = $scope.$resolve.video;
    $scope.isLoading = true;
 
    var url = 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/due_date/' + $scope.video.dueDateId + '/sessions';
    DatabaseApi.get(url).then(function (res) {
        $scope.video.sessions = res.data.sessions;
        $scope.isLoading = false;

        $scope.videosTable = new NgTableParams(options, {
            counts: [],
            dataset: $scope.video.sessions
        });
    });

    var options = {
     count: 200,
     sorting: {displayName: "desc"}
    };

    $scope.formatSeconds = function(seconds) {
        var secondsWatchedMinutes = Math.floor(seconds / 60);
        if(secondsWatchedMinutes < 10) secondsWatchedMinutes = '0' + secondsWatchedMinutes;
        var secondsWatchedSeconds = Math.floor(seconds % 60);
        if(secondsWatchedSeconds < 10) secondsWatchedSeconds = '0' + secondsWatchedSeconds;

        return (secondsWatchedMinutes + ':' + secondsWatchedSeconds);
    };

    $scope.tooltip = function () {$('[data-toggle="tooltip"]').tooltip({container: '#session-tooltip'})};
    setTimeout($scope.tooltip, 500);
 });