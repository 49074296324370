angular
  .module("dashboard")
  .controller(
    "clockinClockoutBulkLinkModalCtrl",
    (
      $rootScope,
      $scope,
      $uibModalInstance,
      ClockinClockoutService,
      NgTableParams,
      tableData,
      tableColumns,
      clockinClockoutRecord,
      selectionLogic,
      mfModal,
      toaster
    ) => {
      initialize();
      $scope.CallTypes = ['IN', 'OUT'];

      function initialize() {
        $scope.clockinClockoutRecord = clockinClockoutRecord;
        $scope.isProcessing = false;
        $scope.tableData = tableData;
        $scope.tableColumns = tableColumns;
        initSelection(tableData);
        initTable();
      }

      function initTable() {
        const options = {
          count: 10,
          sorting: { createdAt: "desc" },
        };
        $scope.tableParams = new NgTableParams(options, {
          dataset: tableData,
        });
      };

      function initSelection(items) {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
          $scope.selectionLogic.addItemToCollection(item);
        });

        if (items) {
          items.forEach((item) => {
            $scope.selectionLogic.initItem(item);
          });
        }
      };

      $scope.promptLinkAlert = () => {
        const modal = mfModal.create({
          subject: "Link Calls",
          variant: "info",
          message: "Are you sure you want to link these calls?",
          hideCancelButton: false,
          confirmLabel: "Yes, link",
          onConfirm: () => linkCalls(),
          onComplete: () => modal.close()
        });
      };

      $scope.promptRejectAlert = () => {
        const modal = mfModal.create({
          subject: "Reject Calls",
          variant: "warning",
          message: "Are you sure you want to reject these calls?",
          hideCancelButton: false,
          confirmLabel: "Yes, reject",
          onConfirm: () => rejectCalls(),
          onComplete: () => modal.close()
        });
      };

      $scope.handleChangeCallType = (call) => {
        const oppositeCallType = call.callType === "IN" ? "OUT" : "IN";
        const modal = mfModal.create({
          subject: "Change call type",
          variant: "info",
          message: `Are you sure you want to change the call type from ${oppositeCallType} to ${call.callType}?`,
          hideCancelButton: false,
          confirmLabel: "Yes, change",
          onConfirm: () => sendUpdateCallType(call),
          onComplete: () => modal.close(),
          onCancel: () => {
            call.callType = oppositeCallType;
          },
        });
      };

      function sendUpdateCallType(call) {
        $scope.isProcessing = true;
        return ClockinClockoutService.update(call.id, {
          agencyId: $rootScope.agencyId,
          agencyMemberId: $rootScope.agencyMemberId,
          data: {
            callType: call.callType
          }
        })
          .then(() => onSuccessAction({ type: "success", action: "update" }))
          .catch((error) => onFailAction({ type: "fail", action: "update", error }))
          .finally(() => ($scope.isProcessing = false));
      }

      function linkCalls() {
        $scope.isProcessing = true;
        return ClockinClockoutService.linkCalls($rootScope.agencyId, $rootScope.agencyMemberId,
          $scope.selectionLogic.getSelectedItems().map(call => ({ clockinClockoutRecordId: call.id, visitInstanceId: call.potentialLinks[0].visitInstance.id })))
          .then(() => onSuccessAction({ type: "success", action: "link", linkedCalls: $scope.selectionLogic.getSelectedItems() }))
          .catch((error) => onFailAction({ type: "fail", action: "link", error }))
          .finally(() => ($scope.isProcessing = false));
      }

      function rejectCalls() {
        $scope.isProcessing = true;
        return ClockinClockoutService.rejectCalls({
          records: $scope.selectionLogic.getSelectedItems().map(record => record.id),
          agencyId: $rootScope.agencyId,
          agencyMemberId: $rootScope.agencyMemberId
        })
          .then(() => onSuccessAction({ type: "success", action: "reject", linkedCalls: $scope.selectionLogic.getSelectedItems() }))
          .catch((error) => onFailAction({ type: "fail", action: "reject", error }))
          .finally(() => ($scope.isProcessing = false));
      }

      function onSuccessAction(response) {
        toaster.pop("succes", "Success", `Calls ${response.action} has done successfully`);
        response.action !== "update" && $uibModalInstance.close(response);
      }

      function onFailAction({ type: _type, action: _action, error }) {
        console.error(error);
        toaster.pop("error", "Something went wrong", `Could not ${_action} calls`);
      }

      $scope.closeModal = () => {
        $uibModalInstance.close({ type: "close" });
      }
    }
  );
