app.controller('patientNewQuestionTypeModalCtrl', function ($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster, SweetAlert) {
    $scope.modalStatus = $scope.$resolve.modalStatus;
    $scope.nursingQuestion = $scope.$resolve.nursingQuestion;
    $scope.allowedVBPQuestionTypes = $scope.$resolve.allowedVBPQuestionTypes;
    $scope.title = $scope.modalStatus === "EDITABLE_ALERTS" ? "Edit Alerts" : "New question type";

    $scope.closeModal = function (isSuccess) {
        $uibModalInstance.close(isSuccess);
    };

    $scope.answerTypesOptions = [
        { id: 1, label: "Radio", value: "radio" },
        { id: 2, label: "Multiple Select (check)", value: "check" },
        { id: 3, label: "Drop Down", value: "dropDown" },
        { id: 4, label: "Yes/No", value: "yesNo" },
        { id: 5, label: "Short Text", value: "textShort" },
        { id: 6, label: "Long Text", value: "textLong" },
        { id: 7, label: "Number", value: "number" },
        { id: 8, label: "Time", value: "time" },
        { id: 9, label: "Date", value: "date" },
        { id: 10, label: "Blood Pressure", value: "bloodPressure" }
    ];

    $scope.allowedAnswerTypesToEditShowOnAgency = ['radio', 'check', 'dropDown'];
    $scope.multipleAnswersQuestionTypes = ['radio', 'check', 'dropDown'];

    const lockedAnswerType = $scope.answerTypesOptions.find(type => type.value === $scope.$resolve.questionType);

    $scope.form = {
        questionText: {
            data: "",
            error: false,
        },
        answerType: {
            data: lockedAnswerType ? { id: lockedAnswerType.id } : {},
            error: false,
            disabled: !!lockedAnswerType
        },
        possibleAnswers: {
            data: [],
            newPossibleAnswer: {
                text: "",
                isTriggeringAlert: false
            },
            error: false
        },
        showOnPatient: {
            data: false
        },
        showOnAgency: {
            data: false,
            disabled: !$scope.allowedAnswerTypesToEditShowOnAgency.includes($scope.$resolve.questionType)
        },
        isVBP: {
            data: false
        },
    };

    $scope.answerTypesEvents = {
        onSelectionChanged: () => {
            const selectedAnswerType = $scope.answerTypesOptions.find(obj => obj.id === $scope.form.answerType.data.id);
            $scope.selectedAnswerTypeText = selectedAnswerType.value;
            if (!selectedAnswerType || !$scope.allowedVBPQuestionTypes.includes(selectedAnswerType.value)) {
                $scope.form.isVBP.data = false;
            }
            if (!selectedAnswerType || !$scope.allowedAnswerTypesToEditShowOnAgency.includes(selectedAnswerType.value)) {
                $scope.form.showOnAgency.data = false;
                $scope.form.showOnAgency.disabled = true;
                $scope.resetPossbileAnswers();
            } else {
                $scope.form.showOnAgency.disabled = false;
            }
        }
    }

    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1
    };

    $scope.addPossibleAnswer = () => {
        if ($scope.form.possibleAnswers.newPossibleAnswer.text.length > 0) {
            $scope.form.possibleAnswers.data.push($scope.form.possibleAnswers.newPossibleAnswer);
            $scope.form.possibleAnswers.newPossibleAnswer = {
                text: "",
                isTriggeringAlert: false
            };
        }
    }

    $scope.removePossibleAnswer = (index) => {
        if (index < $scope.form.possibleAnswers.data.length) {
            $scope.form.possibleAnswers.data.splice(index, 1);
        }
    }

    $scope.resetPossbileAnswers = () => {
        $scope.form.possibleAnswers.data = [];
        $scope.form.possibleAnswers.newPossibleAnswer = {
            text: "",
            isTriggeringAlert: false
        };
    }

    $scope.isQuestionTypeForPossibleAnswers = () => {
        const selectedQuestionType = $scope.answerTypesOptions.find(obj => obj.id === $scope.form.answerType.data.id);
        if (!selectedQuestionType) {
            return false;
        }

        return $scope.multipleAnswersQuestionTypes.includes(selectedQuestionType.value);
    }

    $scope.resetError = (field) => {
        $scope.form[field].error = false;
    };

    $scope.submitForm = () => {
        $scope.formSending = true;
        let errorMessage = '';

        const selectedAnswerType = $scope.answerTypesOptions.find(obj => obj.id === $scope.form.answerType.data.id);
        if (!$scope.form.questionText.data || !$scope.form.questionText.data.length) {
            errorMessage = 'Missing Question text';
            $scope.form.questionText.error = true;
        } else if (selectedAnswerType === undefined) {
            errorMessage = 'Missing Answer type';
            $scope.form.answerType.error = true;
        } else if (
            $scope.multipleAnswersQuestionTypes.includes(selectedAnswerType.value) &&
            $scope.form.possibleAnswers.data.length === 0
        ) {
            errorMessage = 'Missing Possible answers';
            $scope.form.possibleAnswers.error = true;
        }
        
        if (errorMessage !== '') {
            toaster.pop('error', errorMessage);
            $scope.formSending = false;
            return;
        }

        if (
            $scope.multipleAnswersQuestionTypes.includes(selectedAnswerType.value) &&
            $scope.form.possibleAnswers.newPossibleAnswer.text !== ""
        ) {
            SweetAlert.swal({
                title: "Are you sure?",
                text: "New possible answer has not saved yet",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, submit without ",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $scope.sendForm(selectedAnswerType.value);
                } else {
                    $scope.formSending = false;
                }
            });
        } else {
            $scope.sendForm(selectedAnswerType.value);
        }
    }

    $scope.sendForm = (answerType) => {
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/nursing_questions_settings`;
        const body = {
            questionText: $scope.form.questionText.data,
            answerType: answerType,
            possibleAnswers: $scope.form.possibleAnswers.data,
            showOnPatient: $scope.form.showOnPatient.data,
            showOnAgency: $scope.form.showOnAgency.data,
            isVBP: $scope.form.isVBP.data
        }
        DatabaseApi.post(url, body).then((res) => {
            toaster.pop("success", "Question type successfully created");
            $scope.formSending = false;
            $scope.closeModal(true);
        }, (err) => {
            toaster.pop("error", "Something went wrong", "Could not add question type");
            $scope.formSending = false;
        });
    }

    $scope.updateQuestionPossibleAnswer = (oldPossibleAnswers) => {
        $scope.$resolve.updateQuestionType($scope.nursingQuestion, 'possibleAnswers', oldPossibleAnswers);
    }

});