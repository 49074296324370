"use strict";

app.service('DatabaseApi', function (Consts, $q, $http, $rootScope, wildcard, $timeout, toaster, Notifications, Storage, noteConsts) {

    var getCaregiversDict = false;
    var getCaregiversPending = false;
    var host = Consts.api;
    var localServer = Consts.localServer; //'https://medflytserver-stg.herokuapp.com/';
    //var localServer = 'http://localhost:3002/';

    $rootScope.commPageChatCounterCache = {};

    var bell = new Audio('../admin/sounds/bell.mp3');

    var db = {
        agencyChats: [],
        visitChats: [],
        patientChats: [],
        visitLabelsData: {},
        HRSubsectionTemplatesIdsToTitlesData: [],
        entitiesInitializeMap: {
            caregivers: false,
            patients: false,
            agencyMembers: false,
            visits: false,
            contractTypes: false,
            serviceCodes: false,
            payrollCodes: false,
            offices: false,
            caregiverPtoLabels: false,
            visitSettings: false,
        }
    };

    var started = false;
    var activeRequestVisits = false;
    var activeRequestCaregivers = false;
    var activeRequestChats = false;
    var activeAgencyBillingIssuesRequest = false;

    this.isStarted = function () {
        return started;
    };

    function generateRequestId() {
        try {
            var NUM_BITS = 160;
            var numBytes = Math.floor(NUM_BITS / 8);
            var buf = new Uint8Array(numBytes);
            window.crypto.getRandomValues(buf);

            return Array.prototype.map.call(buf, function (x) {
                return ("00" + x.toString(16)).slice(-2);
            }).join("");

        } catch (e) {
            toaster.pop('error', 'Browser Not Supported', 'Please use chrome browser');
        }
        return false;
    }

    this.get = function (route, reactTo403) {
        return $http.get(host + route).catch((err) => {
            if (err.status !== 403 || reactTo403) {
                throw err;
            }
        });
    };

    this.getFromLocalServer = function (route) {
        return $http.get(localServer + route);
    };

    this.post = function (route, data, reactTo403) {
        return $http.post(host + route, data).catch((err) => {
            if (err.status !== 403 || reactTo403) {
                throw err;
            }
        });
    };

    this.put = function (route, data, reactTo403) {
        return $http.put(host + route, data).catch((err) => {
            if (err.status !== 403 || reactTo403) {
                throw err;
            }
        });
    };

    this.patch = function (route, data, reactTo403) {
        return $http.patch(host + route, data).catch((err) => {
            if (err.status !== 403 || reactTo403) {
                throw err;
            }
        });
    };

    this.delete = function (route, data, reactTo403) {
        return $http.delete(host + route).catch((err) => {
            if (err.status !== 403 || (err.status === 403 && reactTo403)) {
                throw err;
            }
        });
    };

    this.caregiversChanged = function (id, data, pending) {
        return getCaregivers(id, data, pending);
    };

    this.setCaregiverOnline = function (caregiverId) {
        if (db.caregivers && db.caregivers[caregiverId]) {
            db.caregivers[caregiverId].online = true;
            db.caregivers[caregiverId].lastSeen = JSON.stringify(new Date()).slice(1, -1);
        }
    };

    this.setCaregiverOffline = function (caregiverId) {
        if (db.caregivers && db.caregivers[caregiverId]) {
            db.caregivers[caregiverId].online = false;
            db.caregivers[caregiverId].lastSeen = JSON.stringify(new Date()).slice(1, -1);
        }
    };

    this.setCaregiverProperty = (caregiverId, propName, propValue) => {
        if (db.caregivers && db.caregivers[caregiverId]) {
            if (db.caregivers[caregiverId].hasOwnProperty(propName)) {
                db.caregivers[caregiverId][propName] = propValue;
            }
        }
    }

    function getCaregiverById(caregiverId) {
        if (db.caregivers) return db.caregivers[caregiverId];
        return false;
    }

    this.getCaregiverById = function (id) {
        return getCaregiverById(id)
    }

    this.getPatientById = function (id) {
        if (db.patients) return db.patients[id];
        return false;
    }

    this.updatePatient = function name(id, body) {
        return this.patch('agencies/' + $rootScope.agencyId + '/patients/' + id, body).then(function (res) {
            const patient = db.patients[id];
            if (patient) {
                Object.assign(patient, body);
            }
            return res;
        });
    }

    this.checkCaregiversBlacklist = function (id, body) {
        return this.post(
            'agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/check_caregivers_blacklist'
                .replace(':agencyId', $rootScope.agencyId)
                .replace(':agencyMemberId', $rootScope.agencyMemberId)
                .replace(':patientId', id),
            body
        ).then(function (res) {
            const patient = db.patients[id];
            if (patient) {
                Object.assign(patient, {
                    blacklisted: body.ids,
                });
            }

            return res;
        });
    }

    this.saveCaregiversBlacklist = function (id, toDelete, toInsert, unassignVisits) {
        return this.post(
            'agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/update_caregivers_blacklist'
                .replace(':agencyId', $rootScope.agencyId)
                .replace(':agencyMemberId', $rootScope.agencyMemberId)
                .replace(':patientId', id),
            {
                toDelete,
                toInsert,
                unassignVisits,
            }
        );
    }

    this.getHrSubsectionTemplatesIdsToTitles = function () {
        if (db.HRSubsectionTemplatesIdsToTitlesData && db.HRSubsectionTemplatesIdsToTitlesData.length) {
            return db.HRSubsectionTemplatesIdsToTitlesData;
        } else {
            $http.get(`${host}hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/subsections_templates/view`).then(function (res) {
                db.HRSubsectionTemplatesIdsToTitlesData = res.data.hrSubsectionTemplatesIdsToTitle;
            });
            return [];
        }
    }

    this.visitUpdated = function (data) {
        if (!db.visits || !db.visits.broadcasts) return;

        var visitId = data.visitId;
        var visit = data.visitData;

        var flag = false;
        for (var i = 0; i < db.visits.broadcasts.length; i++) {
            if (visitId === db.visits.broadcasts[i].id) {

                // if the change is new visit request from caregiver
                if (db.visits.broadcasts[i].acceptedBy.length < visit.acceptedBy.length) {
                    toaster.pop('success', "New Visit Request", 'There is a new visit request for ' + visit.patientName);
                    Notifications.desktop("New Visit Request", 'There is a new visit request for ' + visit.patientName);
                    try {
                        bell.play().then(function (value) { bell.play(); });
                    } catch (e) { }
                }

                db.visits.broadcasts[i] = visit;
                flag = true;
                break;
            }

        }

        if (!flag) {
            db.visits.broadcasts.unshift(visit);

            if (db.oldVisits) {
                for (var i = 0; i < db.oldVisits.length; i++) {

                    if (visitId === db.oldVisits[i].id) {
                        db.oldVisits[i] = visit;
                        flag = true;
                        break;
                    }

                }
                if (!flag) {
                    db.oldVisits.unshift(visit);
                }
            }

        }
        $rootScope.$broadcast("got_visits");
        $rootScope.$broadcast('visit_changed', data);

    };

    this.getVisits = function (filters = {}) {
        var deferred = $q.defer();

        if (activeRequestVisits) {
            deferred.reject("activeRequestVisits already active");
        };

        activeRequestVisits = true;

        let getVisitsUrl = host + wildcard(
            "agencies/:agencyId/coordinator/:agencyMemberId/visits",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );
        let paramPrefix = "?"
        for (const key of Object.keys(filters)) {
            let val = filters[key];
            if (val !== undefined) {
                if (key === "agency_members") {
                    val = val.join(",");
                }
                getVisitsUrl += paramPrefix + key + "=" + val;
                paramPrefix = "&";
            }
        }

        $http.get(getVisitsUrl).then((resVisits) => {
            activeRequestVisits = false;
            db.visits = {
                broadcasts: resVisits.data.visits,
                unstaffed: resVisits.data.unstaffedVisits
            };
            db.entitiesInitializeMap['visits'] = true;

            if (db.caregivers && db.chats) started = true;
            if (started) $rootScope.$broadcast('got_data');
            $rootScope.$broadcast("got_visits");

            deferred.resolve(db);

        }, function (err) {
            activeRequestVisits = false;
            deferred.reject(err);
        });

        return deferred.promise;
    }

    this.visitsResolve = function () {
        var deferred = $q.defer();
        if (db.visits && db.visits.broadcasts && db.visits.broadcasts.length) {
            deferred.resolve(db.visits.broadcasts);
        } else {
            const getVisitsUrl = host + wildcard(
                "agencies/:agencyId/coordinator/:agencyMemberId/visits",
                $rootScope.agencyId,
                $rootScope.agencyMemberId
            );
            $http.get(getVisitsUrl).then((v) => {
                db.visits = {
                    broadcasts: v.data.visits,
                    unstaffed: v.data.unstaffedVisits
                };
                if (db.caregivers && db.chats) started = true;
                if (started) $rootScope.$broadcast('got_data');
                deferred.resolve(db.visits.broadcasts);
            }, (err) => {
                deferred.reject(err);
            });
        }

        return deferred.promise;
    };

    this.visitsArchiveResolve = function () {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/archived_visits?limit=25&offset=0&created_by=' + $rootScope.agencyMemberId).then(function (v) {
            deferred.resolve(v.data.visits);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    };

    var getPatients = function () {
        var deferred = $q.defer();
        var patientsMap = {};

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/patients').then(function (res) {
            res.data.patients.forEach(function (patient) {
                patient.displayName = patient.firstName + ' ' + patient.lastName;
                patientsMap[patient.id] = patient;
            });
            db.patients = patientsMap;
            db.entitiesInitializeMap['patients'] = true;
            $rootScope.$broadcast('got_data');
            $rootScope.$emit('got_patients_data');
            deferred.resolve(db);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    }

    this.visitLabels = function (refresh) {
        if (db.visitLabels && !refresh) return db.visitLabels;
        getVisitLabels();
        return [];
    };

    this.visitLabelsData = function (refresh, activeOnly) {
        if (db.visitLabelsData && !refresh) {
            if (activeOnly && db.visitLabelsData.labels) {
                db.visitLabelsData.activeLabels = db.visitLabelsData.labels.filter(x => x.isActive === true);
            }
            return db.visitLabelsData;

        };
        getVisitLabels();
        return undefined;
    };

    var getVisitLabels = function () {
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_labels_data`).then(function (res) {
            db.visitLabels = res.data.visitLabels;
            db.visitLabelsData.labels = res.data.visitLabels;
            db.visitLabelsData.certificationsAssoc = res.data.visitLabelCertificationAssocs
            db.visitLabelsData.patientDocsAssoc = res.data.patientDocumentTypeVisitLabelAssocs
            $rootScope.$broadcast('got_visit_labels', {});
            deferred.resolve(db);
        }, function () {
            db.visitLabels = [];
        });
    }

    this.patientDocumentTypeVisitLabelAssocs = function () {
        if (db.patientDocumentTypeVisitLabelAssocs) return db.patientDocumentTypeVisitLabelAssocs;
        getPatientDocumentTypeVisitLabelAssocs();
        return [];
    }

    var getPatientDocumentTypeVisitLabelAssocs = function (change) {
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_document_type_visit_label_assocs`).then(function (res) {
            db.patientDocumentTypeVisitLabelAssocs = res.data.patientDocumentTypeVisitLabelAssocs;
            db.visitLabelsData.patientDocumentTypeVisitLabelAssocs = res.data.patientDocumentTypeVisitLabelAssocs;
            if (!change) {
                $rootScope.$broadcast('got_patient_document_type_visit_label_assocs');
            }
            deferred.resolve(db);
        }, function () {
            db.patientDocumentTypeVisitLabelAssocs = [];
        });
    }

    this.updatePatientDocumentTypeVisitLabelAssocs = function () {
        getPatientDocumentTypeVisitLabelAssocs(true);
    }

    this.visitLabelsCertificationAssocs = function () {
        if (db.visitLabelCertificationAssocs) return db.visitLabelCertificationAssocs;
        getVisitLabelsCertificationAssocs();
        return [];
    };

    var getVisitLabelsCertificationAssocs = function (change) {
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_label_certification_assocs`).then(function (res) {
            db.visitLabelCertificationAssocs = res.data.visitLabelCertificationAssocs;
            db.visitLabelsData.visitLabelCertificationAssocs = res.data.visitLabelCertificationAssocs;
            if (!change) {
                $rootScope.$broadcast('got_visit_labels_certification_assocs');
            }
            deferred.resolve(db);
        }, function () {
            db.visitLabelCertificationAssocs = [];
        });
    }


    this.updateVisitLabelCertificationAssocs = function () {
        getVisitLabelsCertificationAssocs(true);
    }

    const getAgencyBillingIssues = () => {
        activeAgencyBillingIssuesRequest = true;
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/agency_billing_issues"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        this.get(url).then((res) => {
            activeAgencyBillingIssuesRequest = false;
            const issuesSettings = {};
            res.data.contractTypeBillingIssues.forEach(issuesSetting => {
                if (!issuesSettings[issuesSetting.officeId]) {
                    issuesSettings[issuesSetting.officeId] = {}
                }

                issuesSettings[issuesSetting.officeId][issuesSetting.contractTypeId] = issuesSetting;
            });
            db.agencyBillingIssues = issuesSettings;
            return db.agencyBillingIssues;
        }, (err) => {
            activeAgencyBillingIssuesRequest = false;
            console.log(err);
            toaster.pop("error", "Something went wrong", "Failed to retrieve billing issues");
        });
    };

    var getCaregivers = function (change, data, pending, retry) {
        var deferred = $q.defer();
        activeRequestCaregivers = true;

        if (!change) {
            if (getCaregiversPending) return;
            getCaregiversPending = true;

            $http.get(host + 'agencies/' + $rootScope.agencyId + '/caregivers_pending').then(function (v) {
                v.data.caregivers.forEach(function (caregiver) {
                    caregiver.displayName = caregiver.firstName + ' ' + caregiver.lastName;
                    caregiver.timeStamp = new Date(caregiver.createdAt).getTime();
                });
                $rootScope.pendingCaregivers = v.data.caregivers;
                db.pendingCaregivers = v.data.caregivers;
                getCaregiversPending = false;
            }, function () {
                $rootScope.pendingCaregivers = [];
                db.pendingCaregivers = [];
                getCaregiversPending = false;
            });
        }

        if (change && !pending && db.caregivers) {
            data.displayName = data.firstName + ' ' + data.lastName;
            db.caregivers[change.toString()] = data;
            activeRequestCaregivers = false;
            deferred.resolve(db);
        } else if (change && pending && db.pendingCaregivers) {
            data.displayName = data.firstName + ' ' + data.lastName;
            var exists = false;
            db.pendingCaregivers.forEach(function (caregiver) {
                if (caregiver.id === change) {
                    exists = true;
                    caregiver = data;
                }
            });

            if (!exists) db.pendingCaregivers.push(data);

            activeRequestCaregivers = false;
            deferred.resolve(db);
        } else {
            if (getCaregiversDict) return;
            getCaregiversDict = true;
            const endpoint = retry ? '/caregivers_dict' : '/caregivers_dict2';
            $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_member/' +  $rootScope.agencyMemberId + endpoint).then(function (res) {
                var started = false;
                db.caregivers = res.data;
                activeRequestCaregivers = false;
                if (db.visits && db.visits.broadcasts && db.chats) started = true;
                if (started) {
                    $rootScope.$broadcast('got_data');
                }
                db.entitiesInitializeMap['caregivers'] = true;
                $rootScope.$broadcast('got_caregivers_data');
                deferred.resolve(db);
                getCaregiversDict = false;
            }, function (err) {
                activeRequestCaregivers = false;
                getCaregiversDict = false;
                if( err.status === 400) {
                    getCaregivers(change, data, pending, true)
                } else {
                    deferred.reject(err);
                }

            });
        }

        return deferred.promise;
    };

    this.updateAgencyMembersByRole = function (agencyMembers) {
        var agencyMembersByRole = {
            admins: [],

            coordinators: [],
            coordinatorsSeniors: [],
            coordinatorsHeads: [],

            salesReps: [],
            salesRepsSeniors: [],
            salesRepsHeads: [],

            intakes: [],
            intakesSeniors: [],
            intakesHeads: [],

            Dpss: [],
            DpssSeniors: [],
            DpssHeads: [],

            hrs: [],
            hrsSeniors: [],
            hrsHeads: []
        };

        agencyMembers.forEach(function (member, i) {
            member.label = member.firstName + ' ' + member.lastName;
            member.displayName = member.label;
            if (member.jobTitle) {
                if (member.jobTitle === 'Admin') {
                    agencyMembersByRole.admins.push(member);
                } else if (member.jobTitle === 'Coordinator') {
                    agencyMembersByRole.coordinators.push(member);
                } else if (member.jobTitle === 'SeniorCoordinator') {
                    agencyMembersByRole.coordinatorsSeniors.push(member);
                } else if (member.jobTitle === 'HeadOfCoordination') {
                    agencyMembersByRole.coordinatorsHeads.push(member);
                } else if (member.jobTitle === 'SalesRep') {
                    agencyMembersByRole.salesReps.push(member);
                } else if (member.jobTitle === 'SeniorSalesRep') {
                    agencyMembersByRole.salesRepsSeniors.push(member);
                } else if (member.jobTitle === 'HeadOfSalesRep') {
                    agencyMembersByRole.salesRepsHeads.push(member);
                } else if (member.jobTitle === 'Intake') {
                    agencyMembersByRole.intakes.push(member);
                } else if (member.jobTitle === 'SeniorIntake') {
                    agencyMembersByRole.intakesSeniors.push(member);
                } else if (member.jobTitle === 'HeadOfIntake') {
                    agencyMembersByRole.intakesHeads.push(member);
                } else if (member.jobTitle === 'DPS') {
                    agencyMembersByRole.Dpss.push(member);
                } else if (member.jobTitle === 'SeniorDPS') {
                    agencyMembersByRole.DpssSeniors.push(member);
                } else if (member.jobTitle === 'HeadOfDPS') {
                    agencyMembersByRole.DpssHeads.push(member);
                } else if (member.jobTitle === 'HR') {
                    agencyMembersByRole.hrs.push(member);
                } else if (member.jobTitle === 'SeniorHR') {
                    agencyMembersByRole.hrsSeniors.push(member);
                } else if (member.jobTitle === 'HeadOfHR') {
                    agencyMembersByRole.hrsHeads.push(member);
                }
            }
            db.agencyMembersByRole = agencyMembersByRole;
        });
    };

    var getChats = function () {
        activeRequestChats = true;
        var deferred = $q.defer();

        // $http.get(host + 'chats/internal_agency_chat_rooms/' + $rootScope.agencyId).then(function (v) {
        //     $rootScope.internalChatCounter = 0;
        //     var myLastView;

        //     $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members').then(function (res) {
        //         db.allAgencyMembersNameMap = {};
        //         db.agencyMembers = res.data.agencyMembers.filter(function (member) {
        //             db.allAgencyMembersNameMap[member.id.toString()] = member.firstName + ' ' + member.lastName;
        //             return member.status === "Active";
        //         }).sort(function (a, b) {
        //             if (a.firstName < b.firstName) { return -1; }
        //             if (a.firstName > b.firstName) { return 1; }
        //             return 0;
        //         });
        //         db.allAgencyMembersArr = res.data.agencyMembers;

        //         db.releventMemberIds = [$rootScope.agencyMemberId];
        //         db.releventMembers = [];
        //         var agencyMembersMap = {};
        //         const agencyMembersWithPhotoMap = {};

        //         // agencyMembersMap and releventMembers are only active members
        //         db.agencyMembers.forEach(function (member, i) {

        //             agencyMembersMap[member.id.toString()] = member.firstName + ' ' + member.lastName;
        //             agencyMembersWithPhotoMap[member.id.toString()] = {
        //                 photoUrl: member.photoUrl,
        //                 name: member.firstName + " " + member.lastName,
        //             };

        //             //["Admin", "Coordinator", "HR", "SeniorCoordinator"]
        //             if ($rootScope.user.agencyMember && $rootScope.user.agencyMember.jobTitle === 'Admin') {
        //                 db.releventMemberIds.push(member.id);
        //                 db.releventMembers.push(member);

        //             } else if ($rootScope.user.agencyMember && $rootScope.user.agencyMember.jobTitle === 'SeniorCoordinator' &&
        //                 member.supervisor === $rootScope.agencyMemberId) {
        //                 db.releventMemberIds.push(member.id);
        //                 db.releventMembers.push(member);
        //             }
        //         });
        //         db.agencyMembersMap = agencyMembersMap;
        //         db.agencyMembersWithPhotoMap = agencyMembersWithPhotoMap;
        //         self.updateAgencyMembersByRole(res.data.agencyMembers);

        //         if (v.data.messages.length) {

        //             db.agencyMembersMap = agencyMembersMap;
        //             db.agencyMembersWithPhotoMap = agencyMembersWithPhotoMap;

        //             v.data.members.forEach(function (member, i) {
        //                 if (member.user.agencyMemberId === $rootScope.agencyMemberId) myLastView = member.lastViewed;
        //             });
        //             v.data.messages.forEach(function (message, i) {
        //                 if (!myLastView || message.createdAt > myLastView) {
        //                     $rootScope.internalChatCounter++;
        //                 }
        //                 message.name = agencyMembersMap[message.user.agencyMemberId.toString()];
        //             });
        //         }
        //     });
        //     db.internalChat = v.data;
        // });

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/chat_rooms/' + $rootScope.agencyMemberId).then(function (v) {
            db.chats = v.data.chatRooms;
            db.agencyChats = [];
            db.visitChats = [];
            db.patientChats = [];

            var mainPageChatCounter = 0;
            db.chats.forEach(function (chat) {
                if (chat.chatRoomType.type === 'CaregiverVisitChat') db.visitChats.push(chat);
                if (chat.chatRoomType.type === 'AgencyMemberCaregiverChat') {
                    db.agencyChats.push(chat);
                    if (chat.members) {
                        var lastViewed;
                        chat.members.forEach(function (member) {
                            if (member.user.type === 'AgencyMember') {
                                if (member.lastViewed > lastViewed) lastViewed = member.lastViewed;
                                else if (!lastViewed) lastViewed = member.lastViewed;
                            }
                        });
                    }
                    var notViewedCounter = 0;
                    if (chat.messages) {

                        if (lastViewed) {
                            var messagesLength = chat.messages.length - 1;
                            for (var i = messagesLength; i >= 0; i--) {
                                if (!chat.messages[i].broadcast && chat.messages[i].createdAt > lastViewed) notViewedCounter++;
                                else break;
                            }
                        }
                        chat.notViewedCounter = notViewedCounter || 0;
                        if (notViewedCounter) $rootScope.commPageChatCounterCache[chat.id] = true;
                        else delete $rootScope.commPageChatCounterCache[chat.id];
                    }
                }
                if (chat.chatRoomType.type === "CaregiverPatientChat") {
                    db.patientChats.push(chat);
                }
            });

            activeRequestChats = false;
            if (db.caregivers && db.visits && db.visits.broadcasts) started = true;
            if (started) $rootScope.$broadcast('got_data');
            deferred.resolve(db);
        }, function (err) {
            activeRequestChats = false;
            deferred.reject(err);
        });
        return deferred.promise;

    };

    this.getChatCounter = function (type) {
        return db[type] ? db[type].length : '';
    };

    this.getAgencyMembers = function () {
        return db.agencyMembersMap || {};
    };

    this.getAgencyMembersWithPhoto = function () {
        return db.agencyMembersWithPhotoMap || {};
    };

    /**
     * Returns all agency members names (also inactive agency members)
     */
    this.getAllAgencyMembersNames = function () {
        return db.allAgencyMembersNameMap || {};
    };

    this.getAgencyMembersArr = function () {
        return db.agencyMembers || [];
    };

    this.getAllAgencyMembersArr = function () {
        return db.allAgencyMembersArr || [];
    };

    this.getReleventMemberIds = function () {
        return db.releventMemberIds || [];
    };

    this.getReleventMemberIdsForVisit = function (visitId) {
        var arr = [];
        var found = false;
        if (db.visits && db.visits.broadcasts) {
            db.visits.broadcasts.forEach(function (visit) {
                if (visit.id == visitId && visit.assignedCoordinators) {
                    found = true;
                    arr = visit.assignedCoordinators;
                    var createdBy = visit.createdBy.id;

                    if (arr.indexOf(createdBy) === -1) {
                        arr.push(createdBy);
                    }
                }
            });
        }

        if (!found && db.oldVisits && db.oldVisits.broadcasts) {
            db.oldVisits.broadcasts.forEach(function (visit) {
                if (visit.id == visitId && visit.assignedCoordinators) {
                    arr = visit.assignedCoordinators;
                    var createdBy = visit.createdBy.id;

                    if (arr.indexOf(createdBy) === -1) {
                        arr.push(createdBy);
                    }
                }
            });
        }

        return arr;
    };

    this.getReleventMembers = function () {
        return db.releventMembers || [];
    };

    this.getAgencyMembersByRole = function () {
        return db.agencyMembersByRole || {};
    };
    this.setReleventMemberIds = function (id, remove) {
        if (remove) {
            var idx = -1;
            db.getRreleventMemberIds.forEach(function (m, i) {
                if (m.id === id) idx = i;
            });
            if (idx > -1) db.getRreleventMemberIds.splice(idx, 1);
        } else {
            var idx = false;
            db.getRreleventMemberIds.forEach(function (m, i) {
                if (m.id === id) idx = true;
            });
            if (!idx) db.getRreleventMemberIds.push(m.id);
        }
    };

    this.getInternalChat = function () {
        return db.internalChat || {};
    };

    this.setInternalChat = function (data) {
        var agencyMembersMap = {};
        db.agencyMembers.forEach(function (member, i) {
            agencyMembersMap[member.id.toString()] = member.firstName + ' ' + member.lastName;
        });
        data.messages.forEach(function (message, i) {
            if (message.user && message.user.agencyMemberId) {
                message.name = agencyMembersMap[message.user.agencyMemberId.toString()];
            }
        });
        db.agencyMembersMap = agencyMembersMap;
        return db.internalChat = data;
    };

    this.postMessage = function (caregiverId, visitId, message, chatId) {

        var stringId = generateRequestId();

        if (!stringId) return;
        message = {
            requestId: generateRequestId(),
            payload: {
                type: 'Message',
                message: message
            }
        };

        message.createdAt = new Date();
        message.user = { type: 'AgencyMember', agencyMemberId: $rootScope.user.agencyMember.id };

        $http.post(host + 'chats/' + chatId, message).then(function (res) { });

    };

    //GET /caregivers/:caregiverId/visits/:visitId/chat
    this.getMessagesByIds = function (id, data, from) {
        var deferred = $q.defer();
        if (data) {
            if (db.releventMemberIds && data.chatRoomType.type === "AgencyMemberCaregiverChat") {

                if (db.releventMemberIds.indexOf(data.chatRoomType.agencyMemberId) > -1) {
                    done(data);
                } else {
                    deferred.reject();
                }
            } else {
                done(data);
            }
        } else {
            $http.get(host + 'agencies/' + $rootScope.agencyId +'/chats/' + id).then(function (res) {
                done(res.data);
            }, function (err) {
                deferred.reject();
            });
        }

        function done(data) {
            if (db.chats) {
                var flag = false;
                var newMessageFlag = true;
                db.chats.forEach(function (c, i) {
                    if (c.id.toString() === id.toString()) {
                        flag = true;

                        if (c.messages.length === data.messages.length) {
                            newMessageFlag = false;
                        }
                        db.chats[i] = data;
                    }
                });
                db.visitChats.forEach(function (c, i) {
                    if (c.id.toString() === id.toString()) {
                        db.visitChats[i] = data;
                    }
                });

                if (!flag) {
                    db.chats.push(data);
                    if (data.chatRoomType.type === 'CaregiverVisitChat') db.visitChats.push(data);
                }

                if (!newMessageFlag) {
                    data.onlyView = true;
                    deferred.resolve(data);
                } else {
                    data.onlyView = false;
                    deferred.resolve(data);
                }
            } else {
                deferred.reject();
            }
        }
        return deferred.promise;
    };

    this.visitPendingEditRequests = function () {
        if (db.visitPendingEditRequests) return db.visitPendingEditRequests;
        return false;
    };

    this.visitArchivedEditRequests = function () {
        if (db.visitArchivedEditRequests) return db.visitArchivedEditRequests;
        return false;
    }

    this.getVisitPendingEditRequests = function () {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/scheduled_visit_edit_requests/pending_response').then(function (res) {
            db.visitPendingEditRequests = res.data.requests;
            $rootScope.pendingVisitEditRequests = db.visitPendingEditRequests.length;
            deferred.resolve();
        }, function (err) {
            deferred.reject(err);
        });
        return deferred.promise;
    }

    this.getVisitArchivedEditRequests = function () {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/scheduled_visit_edit_requests/archive').then(function (res) {
            db.visitArchivedEditRequests = res.data.requests;
            deferred.resolve();
        }, function (err) {
            deferred.reject(err);
        });
        return deferred.promise;
    }

    this.setChat = function (chat) {
        if (!db.chats) return;
        db.chats.forEach(function (c, i) {
            if (c.id == chat.id) {
                c[i] = chat;
            }
        });
    }

    this.setVisits = function (newVisits) {
        db.visits = newVisits;
    }


    this.visits = function () {
        if (db.visits && db.visits.broadcasts && db.visits.unstaffed) {
            return db.visits;
        }
        return {};
    };

    this.patients = function (refresh) {
        if (db.patients && !refresh) return db.patients;
        return getPatients();
    }

    this.caregivers = function (refresh) {
        if (db.caregivers && !refresh) return db.caregivers;
        if (!activeRequestCaregivers) getCaregivers();
        return {};
    };

    this.getMatchingCaregivers = function (visitId) {
        const url = wildcard(
            "agencies/:agencyId/visits/:visitId/broadcasted_to",
            $rootScope.agencyId,
            visitId
        );
        return this.get(url).then((res) => res.data.caregivers.filter(Boolean));
    }

    this.getPendingCaregivers = function () {
        if (db.pendingCaregivers) return db.pendingCaregivers;
        getCaregivers();
        return [];
    };

    this.getCaregiversPendingApplication = function () {
        var caregiversPendingApplication = [];
        return caregiversPendingApplication;
    };

    this.updateCaregiversDict = function (errCb, retry) {
        var deferred = $q.defer();
        if (activeRequestCaregivers) {
            return;
        } else {
            activeRequestCaregivers = true;
            getCaregiversDict = true;
            const endpoint = retry ? '/caregivers_dict' : '/caregivers_dict2';
            $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_member/' +  $rootScope.agencyMemberId + endpoint).then(function (res) {
                var started = false;
                db.caregivers = res.data;
                activeRequestCaregivers = false;
                if (db.visits && db.visits.broadcasts && db.chats) started = true;
                if (started) $rootScope.$broadcast('got_data');
                deferred.resolve(db);
                getCaregiversDict = false;
            }, function (err) {
                activeRequestCaregivers = false;
                getCaregiversDict = false;
                if( err.status === 400) {
                    self.updateCaregiversDict(errCb, true)
                } else {
                    if (errCb) errCb();
                    deferred.reject(err);
                }

            });
        }
    }

    this.reloadPendingCaregivers = function (cb) {
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/caregivers_pending').then(function (v) {
            v.data.caregivers.forEach(function (caregiver) {
                caregiver.displayName = caregiver.firstName + ' ' + caregiver.lastName;
                caregiver.timeStamp = new Date(caregiver.createdAt).getTime();
            });
            $rootScope.pendingCaregivers = v.data.caregivers;
            db.pendingCaregivers = v.data.caregivers;
            if (cb) cb();
        }, function () {

        });
    };

    this.chats = function (refresh, type) {
        if (db.chats && !refresh) return db[type] ? db[type] : db.chats;
        else {
            if (!activeRequestChats) getChats();
            return [];
        }
    };

    this.formFields = function (prop) {
        if (!db) return {};
        else if (prop) return db.formFields[prop];
        return db.formFields;
    };

    this.offices = function () {
        if (!db) return [];
        return db.offices || [];
    };

    this.activeOffices = function () {
        if (!db || !db.offices) return [];
        return db.offices.filter(office => office.active === true) || [];
    };

    this.contractTypes = function () {
        if (!db) return [];
        return db.contractTypes || [];
    };

    this.activeContractTypes = function () {
        if (!db || !db.contractTypes) return [];
        return db.contractTypes.filter(contractType => contractType.active === true) || [];
    };

    this.serviceCodes = function () {
        if (!db) return [];
        return db.serviceCodes || [];
    };

    this.activeAgencyCertifications = function () {
        if (!db || !db.agencyCertifications) return [];
        return db.agencyCertifications.filter(certification => certification.isActive === true) || [];
    };

    this.agencyCertifications = function () {
        if (!db) return [];
        return db.agencyCertifications || [];
    };

    this.agencyFeatures = function () {
        if (!db) return {};
        return db.agencyFeatures || {};
    };

    this.activeServiceCodes = function () {
        if (!db || !db.serviceCodes) return [];
        return db.serviceCodes.filter(serviceCode => serviceCode.isActive === true) || [];
    };

    this.payers = function () {
        if (!db) return [];
        return db.payers || [];
    };

    this.activePayers = function () {
        if (!db) return [];
        return db.payers.filter(payer => payer.isActive === true) || [];
    };

    this.payrollCodes = function () {
        if (!db) return [];
        return db.payrollCodes || [];
    };

    this.payrollSetups = function () {
        if (!db) return [];
        return db.payrollSetups || [];
    };

    this.activePayrollCodes = function () {
        if (!db || !db.payrollCodes) return [];
        return db.payrollCodes.filter(payrollCode => payrollCode.isActive === true) || [];
    };

    this.physicians = function () {
        if (!db) return [];
        return db.physicians || [];
    };

    this.patientDocumentTypes = function () {
        if (!db) return [];
        return db.documentTypes || [];
    };

    this.patientQuestionTypes = function () {
        if (!db) return [];
        return db.patientQuestionTypes || [];
    }

    this.patientTaskTemplates = function () {
        if (!db) return [];
        return db.patientTaskTemplates || [];
    }

    this.plansOfCare = function () {

        if (!db) return [];
        return db.plansOfCareTypes || [];
    };

    this.defaultPocSignaturePerson = function () {
        if (!db) return null;
        return db.defaultPocSignaturePerson || null;
    };

    this.setDefaultPocSignaturePerson = function (id) {
        if (!db) return null;
        return db.defaultPocSignaturePerson = id;
    };

    this.counties = function () {

        if (!db) return [];
        return db.countyGroups || [];
    };

    this.payRates = function () {
        if (!db) return [];
        return db.payRates || [];
    };

    this.agencyHolidays = function () {
        if (!db) return [];
        return db.agencyHolidays || [];
    };

    this.statusChangeReasons = function () {
        if (!db) return [];
        return db.statusChangeReasons || [];
    };

    this.statusChangeTos = function () {
        if (!db) return [];
        return db.statusChangeTo || [];
    };

    this.arClaimStatuses = function () {
        if (!db) return [];
        return db.arClaimStatuses || [];
    };

    this.arReasons = function () {
        if (!db) return [];
        return db.arReasons || [];
    };

    this.arCollectionStatuses = function () {
        if (!db) return [];
        return db.arCollectionStatuses || [];
    };

    this.caregiverPtoLabels = function () {
        if (!db) return [];
        return db.caregiverPtoLabels || [];
    };

    this.agencyBranches = function () {
        if (!db) return [];
        return db.agencyBranches || [];
    }

    this.agencyPatientStatuses = function () {
        if (!db) return [];
        return db.agencyPatientStatuses || [];
    }

    this.agencyPatientActiveStatuses = function () {
        if (!db) return [];
        return db.agencyPatientActiveStatuses || [];
    }

    this.agencyCustomFields = () => {
        if (!db) return [];
        return db.agencyCustomFields || [];
    }

    this.cityTaxCodes = () => {
        if (!db) return [];
        return db.cityTaxCodes || [];
    }

    this.locationGroupsAndCounties = function () {
        if (!db) return [];
        var obj = {};
        obj.locationGroups = db.locationGroups || [];
        obj.counties = db.counties || [];

        return obj;
    };

    this.agencyTeams = function () {
        if (!db) return [];
        return db.agencyTeams || [];
    };

    this.getVisit = async function (id) {
        const byId = (v) => v.id.toString() === id.toString();
        let visit;
        if (db && db.visits && db.visits.broadcasts) {
            visit = db.visits.broadcasts.find(byId)
        }
        if (!visit && db.oldVisits && db.oldVisits.broadcasts) {
            visit = db.oldVisits.broadcasts.find(byId)
        }

        return visit || this.get('agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/visits/' + id)
            .then((response) => response.data);
    };

    this.getPro = function (id) {
        if (!db || !db.caregivers) return;
        return db.caregivers[id];
    };

    this.agencyNoteSubjects = function () {
        if (!db) return [];
        return db.agencyNoteSubjects || [];
    };

    this.agencyNoteSubjectsWithNoteTypes = function (showOnlyActive) {
        if (!db) return [];
        const subjects = showOnlyActive === true ? this.activeAgencyNoteSubjects() : this.agencyNoteSubjects();

        let subjectsWithTypes = [...subjects];
        this.agencyEntityNoteSettings().forEach(item => {
            item.text = noteConsts.NoteTypesTranslations[item.noteType];
            subjectsWithTypes.push({
                id: `nt_${item.noteType}`,
                text: noteConsts.NoteTypesTranslations[item.noteType],
                noteType: item.noteType,
                showOnCaregiverProfile: item.showOnCaregiverProfile,
                showOnPatientProfile: item.showOnPatientProfile
            });
        });

        subjectsWithTypes = subjectsWithTypes.sort((a, b) => a.text.localeCompare(b.text));

        return subjectsWithTypes;
    };

    this.agencyEntityNoteSettings = function () {
        if (!db) return [];
        return db.agencyEntityNotes || [];
    }

    this.eBillingProviders = function () {
        if (!db) return [];
        return db.eBillingProviders || [];
    }

    this.agencyBillingIssues = function () {
        if (db && db.agencyBillingIssues) return db.agencyBillingIssues;
        if (!activeAgencyBillingIssuesRequest) return getAgencyBillingIssues();
        return {};
    };

    this.activeAgencyNoteSubjects = function () {
        if (!db) return [];
        return db.agencyNoteSubjects.filter(noteSubject => noteSubject.active === true) || [];
    };

    var getVisitSettings = function () {
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/settings').then(function (res) {
            //$http.get('http://localhost:3000/visit-settings').then(function(res){
            $rootScope.visitSettings = res.data;

            const calendarStartDayOfTheWeek = $rootScope.visitSettings.calendarStartDayOfTheWeek;
            $rootScope.visitSettings.calendarStartDayOfTheWeek = calendarStartDayOfTheWeek ? calendarStartDayOfTheWeek : 0; // DEFAULT TO SUNDAY
            $rootScope.visitSettings.isMilitaryTime = !!$rootScope.visitSettings.isMilitaryTime;
            db.entitiesInitializeMap['visitSettings'] = true;
            $rootScope.$broadcast("got_visit_settings");
        }, function (err) {
        });
    };

    $rootScope.sweepScoreChanged = true;
    $rootScope.sweepScore = false;
    var getSweepScore = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        var url = 'agencies/' + $rootScope.agencyId + '/coordinators/' + $rootScope.agencyMemberId + '/lottery_sweepstakes_score';
        $http.get(host + url).then(function (res) {
            if ($rootScope.sweepScore && res.data !== $rootScope.sweepScore.points) {

                $timeout(function () {
                    $rootScope.sweepScoreChanged = true;
                    try {
                        bell.play().then(function (value) { bell.play(); });
                    } catch (e) {

                    }
                    $timeout(function () {
                        $rootScope.sweepScoreChanged = false;
                    }, 1000);
                }, 2000);

            }
            $rootScope.sweepScore = res.data || { points: 0 };
            if ($rootScope.sweepScore < 0) $rootScope.sweepScore = 0;
        }, function () {
            $rootScope.sweepScore = { points: 0 };
        });
    };

    const loadAgencyMembers = async function () {
        if (
            !$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.user.agencyMember
        )
            return;

        const { data } = await $http.get(
            host + "agencies/" + $rootScope.agencyId + "/agency_members"
        );
        db.allAgencyMembersNameMap = {};
        db.agencyMembers = data.agencyMembers
            .filter(function (member) {
                db.allAgencyMembersNameMap[member.id.toString()] =
                    member.firstName + " " + member.lastName;
                return member.status === "Active";
            })
            .sort(function (a, b) {
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            });
        db.allAgencyMembersArr = data.agencyMembers;

        db.releventMemberIds = [$rootScope.agencyMemberId];
        db.releventMembers = [];
        const agencyMembersMap = {};
        const agencyMembersWithPhotoMap = {};

        // agencyMembersMap and releventMembers are only active members
        db.agencyMembers.forEach(function (member) {
            agencyMembersMap[member.id.toString()] =
                member.firstName + " " + member.lastName;
            agencyMembersWithPhotoMap[member.id.toString()] = {
                photoUrl: member.photoUrl,
                name: member.firstName + " " + member.lastName,
            };

            //["Admin", "Coordinator", "HR", "SeniorCoordinator"]
            if (
                $rootScope.user.agencyMember &&
                $rootScope.user.agencyMember.jobTitle === "Admin"
            ) {
                db.releventMemberIds.push(member.id);
                db.releventMembers.push(member);
            } else if (
                $rootScope.user.agencyMember &&
                $rootScope.user.agencyMember.jobTitle === "SeniorCoordinator" &&
                member.supervisor === $rootScope.agencyMemberId
            ) {
                db.releventMemberIds.push(member.id);
                db.releventMembers.push(member);
            }
        });
        db.agencyMembersMap = agencyMembersMap;
        db.agencyMembersWithPhotoMap = agencyMembersWithPhotoMap;
        self.updateAgencyMembersByRole(data.agencyMembers);
        db.entitiesInitializeMap['agencyMembers'] = true;
        $rootScope.$broadcast('got_agency_members');
    };
    this.loadAgencyMembers = loadAgencyMembers;

    var getRefferalCode = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'user/ref_code').then(function (res) {
            $rootScope.coordinatorRefferalCode = res.data.refCode;
        });
    };

    var getFormFields = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/get_form_structure').then(function (res) {
            db.formFields = res.data;
        });

    };

    this.getOffices = function () {
        if (
            !$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.user.agencyMember
        )
            return;
        const deferred = $q.defer();

        $http
            .get(
                host +
                `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices`
            )
            .then(function (res) {
                db.offices = res.data.offices;
                db.entitiesInitializeMap['offices'] = true;
                $rootScope.$emit('got_offices');

                deferred.resolve();
            })
            .catch(function (err) {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    this.storeOffices = function (data) {
        db.offices = data;
    };



    var getServiceCodes = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/service_codes').then(function (res) {
            db.serviceCodes = res.data.serviceCodes;
            db.entitiesInitializeMap['serviceCodes'] = true;
            $rootScope.$broadcast('got_service_codes');
        });

    };

    this.storeServiceCodes = function (data) {
        db.serviceCodes = data;
    };

    const getAgencyCertifications = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' +
            $rootScope.agencyMemberId + '/certifications_settings').then(function (res) {
                db.agencyCertifications = res.data.certifications;
                $rootScope.$broadcast('got_agency_certifications');
            });
    };

    const getAgencyFeatures = function () {
        if (
            !$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.user.agencyMember
        )
            return;
        $http
            .get(host + "agencies/" + $rootScope.agencyId + "/features")
            .then(function (res) {
                db.agencyFeatures = res.data;
                $rootScope.$broadcast("got_agency_features");
            });
    };

    this.storeAgencyCertifications = function (data) {
        db.agencyCertifications = data;
    };

    this.getContractTypes = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/contract_types').then(function (res) {
            db.contractTypes = res.data.contractTypes;
            db.entitiesInitializeMap['contractTypes'] = true;
            $rootScope.$broadcast('got_contract_types');
        });

    };

    this.storeContractTypes = function (data) {
        db.contractTypes = data;
    };

    var getPayers = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/payers').then(function (res) {
            db.payers = res.data.payers;
        });
    };

    var getPayrollCodes = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_codes').then(function (res) {
            db.payrollCodes = res.data.payrollCodes;
            db.entitiesInitializeMap['payrollCodes'] = true;
            $rootScope.$emit('got_payroll_codes');
        });
    }
    this.getPayrollCodesAsync = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        return $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_codes').then(function (res) {
            return res.data.payrollCodes;
        });
    };

    var getPayrollSetups = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_setups').then(function (res) {
            db.payrollSetups = res.data.payrollSetups;
            $rootScope.$emit('got_payroll_setups');
        });
    }

    var getPayRates = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/pay_rates').then(function (res) {
            db.payRates = res.data.payRates;
        });
    }

    var getAgencyHolidays = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/holidays').then(function (res) {
            db.agencyHolidays = res.data.agencyHolidays;
        });
    }

    const getAgencyBranches = function () {
        if (!$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.agencyMemberId
        ) {
            return;
        }

        $http.get(
            `${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/branches`
        ).then(res => db.agencyBranches = res.data.list.filter(branch => branch.active));
    };
    this.getAgencyBranches = getAgencyBranches;

    const getAgencyPatientStatuses = function () {
        if (!$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.agencyMemberId
        ) {
            return;
        }

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_statuses"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);

        $http.get(
            `${host}${url}`
        ).then(res => {
            db.agencyPatientStatuses = res.data.list;
            db.agencyPatientActiveStatuses = res.data.list.filter(patientStatus => patientStatus.active);
            $rootScope.$emit('got_patients_statuses');
        });
    };
    this.getAgencyPatientStatuses = getAgencyPatientStatuses;

    const getAgencyCustomFields = function () {
        if (!$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.agencyMemberId
        ) {
            return;
        }

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/custom_fields"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);

        $http.get(
            `${host}${url}`
        ).then(res => db.agencyCustomFields = res.data.list.filter(customField => customField.active));
    };
    this.getAgencyCustomFields = getAgencyCustomFields;

    var getAgencyTeams = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/teams').then(function (res) {
            db.agencyTeams = res.data.agencyTeams;
            $rootScope.$emit('got_agency_teams');
        });
    }

    this.storePayers = function (data) {
        db.payers = data;
    };

    this.storePayrollCodes = function (data) {
        db.payrollCodes = data;
    };

    this.storePayrollSetups = function (data) {
        db.payrollSetups = data;
    };

    this.storePayRates = function (data) {
        db.payRates = data;
    };

    this.storeAgencyHolidays = function (data) {
        db.agencyHolidays = data;
    };

    this.storeAgencyBranches = function (data) {
        db.agencyBranches = data;
    };

    this.storeAgencyPatientStatuses = function (data) {
        db.agencyPatientStatuses = data;
        db.agencyPatientActiveStatuses = data.filter(status => status.active);
    }

    this.storeAgencyCustomFields = function (data) {
        // Store only active items
        db.agencyCustomFields = data.filter(customField => customField.active);
    }

    this.storeCityTaxCodes = function (data) {
        // Store only active items
        db.cityTaxCodes = data.filter(cityTaxCode => cityTaxCode.active);
    }

    this.storeAgencyTeams = function (data) {
        db.agencyTeams = data;
    };

    this.getStatusChangeReasons = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/status_change_reasons`).then((res) => {
            db.statusChangeReasons = res.data.statusChangeReasons;
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get status change to");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.storeStatusChangeReasons = function (data) {
        db.statusChangeReasons = data;
    };

    this.getStatusChangeTo = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/status_change_tos`).then((res) => {
            db.statusChangeTo = res.data.statusChangeTos;
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get status change to");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.storeStatusChangeTo = function (data) {
        db.statusChangeTo = data;
    };

    this.getArClaimStatuses = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_claim_status_refs`).then((res) => {
            db.arClaimStatuses = res.data.claimStatusRefs;
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get claim statuses");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.storeArClaimStatuses = function (data) {
        db.arClaimStatuses = data;
    };

    this.getArReasons = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_reason_refs`).then((res) => {
            db.arReasons = res.data.arReasonRef;
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get reasons");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.storeArReasons = function (data) {
        db.arReasons = data;
    };

    this.getArCollectionStatuses = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/ar_collection_status_refs`).then((res) => {
            db.arCollectionStatuses = res.data.collectionStatusRefs;
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get collection statuses");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.getPtoLabels = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        var deferred = $q.defer();
        $http.get(`${host}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregiver_pto_labels`).then((res) => {
            db.caregiverPtoLabels = res.data.caregiverPtoLabels;
            db.entitiesInitializeMap['caregiverPtoLabels'] = true;
            $rootScope.$emit('got_pto_labels');
            deferred.resolve()
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get PTO labels");
            deferred.reject(err)
        });

        return deferred.promise;
    };

    this.storeArCollectionStatuses = function (data) {
        db.arCollectionStatuses = data;
    };

    this.storeCaregiverPtoLabels = function (data) {
        db.caregiverPtoLabels = data;
    };

    this.storePhysicians = function (data) {
        db.physicians = data;
    };

    this.getPayrollCodesForServiceCode = function (serviceCodeId) {
        if (
            !$rootScope.agencyId ||
            !$rootScope.user ||
            !$rootScope.user.agencyMember
        ) {
            return;
        }
        return $http
            .get(
                host +
                "agencies/" +
                $rootScope.agencyId +
                "/agency_members/" +
                $rootScope.user.agencyMember.id +
                "/service_codes/" +
                serviceCodeId +
                "/payroll_codes"
            )
            .then(res => res.data.payrollCodes);
    };

    var getPhysicians = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/physicians').then(function (res) {
            db.physicians = res.data.physicians;
            db.physicians.forEach(function (physician) {
                physician.displayName = physician.firstName + ' ' + physician.lastName;
            });
            $rootScope.$broadcast('got_physicians');
        });
    };

    var getPatientDocumentLastUpdateDate = function (documentType) {
        var lastVersion = documentType.versions.sort(function (ver1, ver2) { return ver2.id - ver1.id })[0];
        return (lastVersion.createdAt || lastVersion.updatedAt)
    };

    var getPatientDocumentTypes = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/patient_document_types').then(function (res) {
            db.documentTypes = res.data.documents;
            db.documentTypes.forEach(function (doc) {
                doc.lastUpdateDate = getPatientDocumentLastUpdateDate(doc);
            });
            $rootScope.$broadcast('got_patient_document_types');
        });
    };

    var getPatientQuestionTypes = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/nursing_questions_settings').then(function (res) {
            db.patientQuestionTypes = res.data.questions;
            $rootScope.$broadcast('got_data');
        });
    };

    var getPatientTaskTemplates = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' +
            $rootScope.agencyMemberId + '/patient_task_templates').then(function (res) {
                db.patientTaskTemplates = res.data.patientTaskTemplates;
                $rootScope.$broadcast('got_patient_task_templates');
            });
    }

    this.setPatientTaskTemplates = function () {
        getPatientTaskTemplates();
    };

    this.setPatientDocumentTypes = function () {
        getPatientDocumentTypes();
    };

    this.setPatientQuestionTypes = function () {
        getPatientQuestionTypes();
    };

    var getPlansOfCare = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        $http.get(host + "agencies/" + $rootScope.agencyId + "/coordinator/" + $rootScope.agencyMemberId + "/plan_of_care_settings").then(function (res) {
            db.plansOfCareTypes = res.data.planOfCares;
            db.defaultPocSignaturePerson = res.data.defaultPocSignaturePerson;
            $rootScope.$broadcast('got_plan_of_care_type');
        });
    };

    var getCounties = function () {
        if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
        $http.get(host + "agencies/" + $rootScope.agencyId + "/agency_member/" + $rootScope.agencyMemberId + "/location_county_groups").then(function (res) {
            db.countyGroups = res.data.countyGroups;
        });
    };

    var getWalkthroughs = function () {
        if (!$rootScope.agencyId || !$rootScope.agencyMemberId) return;
        $http.get(host + "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/get_agency_member_walkthroughs")
            .then(function (res) {
                Storage.setObject("walkthroughs", { walkthroughs: res.data });
            })
            .catch((e) => console.error(e)
            );
    };

    this.getAgencyNoteSettings = () => {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/agency_members/' +
            $rootScope.agencyMemberId + '/notes_settings').then(function (res) {
                db.agencyNoteSubjects = res.data.agencyNoteSubjects.sort((a, b) => a.text.localeCompare(b.text));
                db.agencyEntityNotes = res.data.agencyEntityNotes;
                $rootScope.$broadcast('got_notes_settings');
            });

        return deferred.promise;
    };

    this.getEBillingProviders = () => {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/eBillingProviders').then(function (res) {
                db.eBillingProviders = res.data.eBillingProviders;
            });

        return deferred.promise;
    };

    var self = this;
    this.connect = function (mode) {
        if (!$rootScope.coordinatorRefferalCode) getRefferalCode();
        if (mode === 'onlyScore') {
            getSweepScore();
        } else if (mode === 'onlyPatients') {
            getPatients();
        } else if (mode === 'onlyVisits') {
            self.getVisits();
        } else if (mode === 'onlyCaregivers') {
            getCaregivers();
        } else {
            getAgencyFeatures();
            getAgencyCertifications();
            getCounties();
            getPatientDocumentTypes();
            getPatientQuestionTypes();
            getPatientTaskTemplates();
            getPlansOfCare();
            getFormFields();
            getServiceCodes();
            getPayers();
            getPhysicians();
            self.getVisits();
            getCaregivers();
            getPatients();
            getVisitSettings();
            getVisitLabels();
            getPatientDocumentTypeVisitLabelAssocs();
            getVisitLabelsCertificationAssocs();
            getChats();
            getSweepScore();
            getPayRates();
            getAgencyHolidays();
            getAgencyBranches();
            getAgencyPatientStatuses();
            getAgencyCustomFields();
            self.getOffices();
            self.getContractTypes();
            self.getStatusChangeReasons();
            self.getStatusChangeTo();
            self.getArClaimStatuses();
            self.getArReasons();
            self.getArCollectionStatuses();
            self.getPtoLabels();
            getPayrollCodes();
            getPayrollSetups();
            // Removed Walkthroughs DEC 2020 due to new notes feature
            // getWalkthroughs();
            getAgencyTeams();
            getAgencyBillingIssues();
            self.getVisitPendingEditRequests();
            self.getVisitArchivedEditRequests();
            self.getHrSubsectionTemplatesIdsToTitles();
            self.getAgencyNoteSettings();
            self.loadAgencyMembers();
            self.getEBillingProviders();
        }
    };

    this.GetSettings = function () {
        getVisitSettings();
    };

    this.addVisit = function (visit, edit) {
    };

    this.addCaregiver = function (caregiver) {
    };

    this.sendMessage = function (visitId, caregiverId, caregiverName, message) {
    };

    this.clearChatCount = function (visitId) {
    };

    this.updateProfile = function (data) {
        return $http.put('https://medflytserver-stg.herokuapp.com/user/' + $rootScope.user._id, data);
    };

    this.changePhone = function (phone) {
        return $http.post('https://medflytserver-stg.herokuapp.com/user/verify_phone/', { phone: phone });
    };

    this.enterPhonePin = function (pin, phone) {
        return $http.post('https://medflytserver-stg.herokuapp.com/user/verify_pin/', { phone: phone, pin: pin });
    };

    this.remindConfiramtion = function (id) {
        return $http.post('https://medflytserver-stg.herokuapp.com/send_reminder/' + id);
    };

    this.getLocationGroupsAndCounties = function () {
        var deferred = $q.defer();
        var groupsAndCounties = {};
        groupsAndCounties.locationGroups = [];
        groupsAndCounties.counties = [];

        $http.get(host + 'agencies/' + $rootScope.agencyId + '/location_groups').then(function (res) {
            groupsAndCounties.locationGroups = res.data.locationGroups;
            db.locationGroups = res.data.locationGroups;
            //get counties list
            $http.get(host + 'agencies/' + $rootScope.agencyId + '/location_group_counties').then(function (res) {
                groupsAndCounties.counties = res.data.locationGroupCounties;
                db.counties = res.data.locationGroupCounties;
                deferred.resolve(groupsAndCounties);
            }, function (err) {
                deferred.reject(groupsAndCounties);
            });

        }, function (err) {
            deferred.reject(groupsAndCounties);
        });

        return deferred.promise;
    };

    this.addLocationGroup = function (data) {
        var deferred = $q.defer();
        $http.post(host + 'billing_rate/' + $rootScope.agencyId + '/location_group', data).then(function (res) {
            deferred.resolve(res);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    };

    this.updateCountyLocationGroup = function (countyGroupId, locationGroup) {
        var deferred = $q.defer();
        $http.put(host + 'agencies/' + $rootScope.agencyId + '/location_group_counties/' + countyGroupId, locationGroup).then(function (res) {
            deferred.resolve(res);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    };

    this.getBillingRates = function (contractId) {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/contracts/' + contractId + "/billing_rates").then(function (res) {
            deferred.resolve(res);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    };

    this.getVisitBroadcastCaregiverOvertime = function (visitBroadcastId) {
        var deferred = $q.defer();
        $http.get(host + 'agencies/' + $rootScope.agencyId + '/visits/' + visitBroadcastId + "/broadcast_overtime").then(function (res) {
            deferred.resolve(res);
        }, function (err) {
            deferred.reject(err);
        });

        return deferred.promise;
    }


    this.getCssGradient = function (a, b) {
        var colors = {
            a: '#005aa7',
            b: '#da4453',
            c: '#fffde4',
            d: '#89216b',
            e: '#11998e',
            f: '#38ef7d',
            g: '#fc5c7d',
            h: '#6a82fb',
            i: '#cac531',
            j: '#f3f9a7',
            k: '#fc4a1a',
            l: '#f7b733',
            m: '#7f00ff',
            n: '#e100ff',
            o: '#0cebeb',
            p: '#fc354c',
            q: '#29ffc6',
            r: '#1a2a6c',
            s: '#b21f1f',
            t: '#fdbb2d',
            u: '#fffc00',
            v: '#ffffff',
            w: '#fc00ff',
            x: '#00dbde',
            y: '#fdfc47',
            z: '#24fe41'
        };

        var overide;
        if (a === 'REFERRAL' || a === 'ON_HOLD' || a === 'HOSPITALIZED' || a === 'VACATION') {
            a = '#FFEB3B';
            b = '#FFF59D';
            overide = true;
        } else if (a === 'ACCEPTED' || a === 'ACTIVE') {
            a = '#03A9F4';
            b = '#81D4FA';
            overide = true;
        } else if (a === 'ELIGIBLE') {
            a = '#8BC34A';
            b = '#C5E1A5';
            overide = true;
        } else if (a === 'PENDING_FILES' || a === 'INACTIVE') {
            a = '#FF9800';
            b = '#FFCC80';
            overide = true;
        }

        if (!a || !b) return 'linear-gradient(to right, #005aa7, #fffde4)';

        a = a.toLowerCase();
        b = b ? b.toLowerCase() : null;

        if (overide) return 'linear-gradient(120deg, ' + a + ', ' + b + ')';
        return 'linear-gradient(120deg, ' + colors[a] + ', ' + colors[b] + ')';
    }

    this.entitiesInitializeMap = function() {
        return db.entitiesInitializeMap;
    };
});
