app.controller("caregiverTrainingCenterCtrl", function (
  $scope,
  $rootScope,
  CaregiverTrainingCenterService,
  caregiverTrainingCenterAddBundleModalService,
  toaster
) {
  const service = CaregiverTrainingCenterService;
  const bundleAddedEmitter = caregiverTrainingCenterAddBundleModalService.getBundleAddedEmitter();

  this.$onChanges = function (changedData) {
    bundleAddedEmitter.removeEventListener("BundleAdded", getBundles, true);

    if (
      changedData.caregiver === undefined ||
      changedData.caregiver.currentValue === undefined
    )
      return;

    $scope.bundles = undefined;

    bundleAddedEmitter.addEventListener("BundleAdded", getBundles, true);

    $scope.caregiver = changedData.caregiver.currentValue;
    $scope.caregiverId = $scope.caregiver.id;

    getBundles();
  };

  function getBundles() {
    service
      .getBundlesSummary({
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId,
        caregiverId: $scope.caregiverId,
      })
      .then((bundles) => ($scope.bundles = bundles))
      .catch(() => {
        toaster.pop("error", "Error loading Caregiver Training Center");
      });
  }

  $scope.scrollToTop = function () {
    scroll(0, 0);
  };
});
