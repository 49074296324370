"use strict";
app.controller('statusChangeToSetupModalCtrl', function($scope, $rootScope, Consts, $http, $state, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService){

    const parseData = (data) => {
        return {
          ...data,
          statuses: data.statuses !== undefined ? data.statuses.map((item) => ({ id: item, label: item })) : [],
        };
    };

    $scope.data = parseData(angular.copy(data));

    $scope.fields = FormService.getFields(fields, $scope.data);

    $scope.form = {};

    $scope.fieldChanged = (item) => {
        if (item.type.type === "Boolean" && $scope.data.id) {
            $scope.submitForm();
        }
    }

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid) return;

        var newData = FormService.prepareFormData($scope.fields);
        newData.agency = $rootScope.agencyId;

        if (Array.isArray(newData.statuses)) {
            newData.statuses = newData.statuses.map(status => status.id !== undefined ? status.id : status);
        } else {
            newData.statuses = [newData.statuses];
        }

        if (data.id) {
            DatabaseApi.put(`agencies/${$rootScope.agencyId}/status_change_to/${$scope.data.id}` , newData)
                .then((res) => {
                    toaster.pop('success', "Status Change To saved");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop('error', "Something went wrong", "Could not change status change to settings")
                });
        } else {
            DatabaseApi.post(`agencies/${$rootScope.agencyId}/status_change_to`, newData).then((res) => {
                toaster.pop('success', "Status Change To saved");
                $scope.closeModal();
            }, (err) => {
                toaster.pop('error', "Something went wrong", "could not add status change to");
            });
        }
    };

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

});
