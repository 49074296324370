app.controller("billingVisitAdjustmentApprovalsCtrl", function (
    $scope,
    $rootScope,
    wildcard,
    DatabaseApi,
    toaster,
    NgTableParams,
    selectionLogic,
    $timeout,
    mfModal,
    $filter,
    itemSearchPageManager,
    adjustmentApprovalsConsts,
    FilterUtils,
) {
    $scope.caregiversMap = DatabaseApi.caregivers();
    $scope.patientsMap = DatabaseApi.patients();
    $scope.contracts = DatabaseApi.contractTypes();
    $scope.offices = DatabaseApi.offices();
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();
    $scope.globalFilter = { val: "" };
    $scope.filterBy = {
        approvalStatus: "PENDING",
        changes: [],
        requirePerfectMatch: false
    };
    $scope.statuses = adjustmentApprovalsConsts.statuses;
    $scope.diffsMap = adjustmentApprovalsConsts.diffsMap;
    $scope.diffsOptions = adjustmentApprovalsConsts.diffsOptions;
    $scope.diffsExtraSettings = adjustmentApprovalsConsts.diffsExtraSettings;

    function initialize() {
        initPageManager();
        getOffices();
        if (Object.keys($scope.agencyMembers).length === 0) {
            DatabaseApi.loadAgencyMembers();
        }

        // Load filters
        const storageFilters = FilterUtils.rootFilters.visitAdjustmentApprovals;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
    }

    $scope.$on("$destroy", function () {
      const filters = $scope.pageManager.searchParams;
      FilterUtils.rootFilters.visitAdjustmentApprovals = angular.copy(filters);
    });

    const getOffices = () => {
        $scope.offices = DatabaseApi.offices();
        if (!$scope.offices || $scope.offices.length === 0) {
            const url = wildcard(
                "agencies/:angecyId/agency_members/:agencyMemberId/offices",
                $rootScope.agencyId,
                $rootScope.agencyMemberId
            );
            DatabaseApi.get(url).then((res) => {
                $scope.offices = res.data.offices;
                DatabaseApi.storeOffices(res.data.offices);
            }, (err) => {
                toaster.pop('error', "Something went wrong", "could not get offices");
            });
        }
    }

    const initPageManager = () => {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/adjustment_approvals");
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.teamDataManager = $scope.pageManager.getTeamDataManager();
        $scope.coordinatorDataManager = $scope.pageManager.getCoordinatorDataManager({onlyActive: true});
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initTeamParam();
        $scope.pageManager.initCoordinatorParam();
    };

    $scope.loadItems = () => {
        $scope.pageManager.executeSearch().then((res) => {
            $scope.allAdjustments = res.data.adjustments;
            mapItems($scope.allAdjustments);
            filterTable();
        }, (err) => {
            toaster.pop("error", "Failed to load adjustments");
        });
    };

    const mapItems = (items) => {
        items.forEach(item => {
            if (item.approvalStatus === null) {
                item.approvalStatus = "PENDING";
            }
            if (item.caregiverId) {
                item.caregiver = $scope.caregiversMap[item.caregiverId];
            }
            if (item.patientId) {
                item.patient = $scope.patientsMap[item.patientId];
            }
            const contract = $scope.contracts.find(c => c.id === item.contractTypeId);
            if (contract) {
                item.contractName = contract.name;
            }
            const office = $scope.offices.find(o => o.id === item.officeId);
            if (office) {
                item.officeName = office.name;
            }
            item.diffsValues = [];
            item.diffsTexts = [];
            Object.entries(item.diffs).forEach(([key, val]) => {
                if (val && $scope.diffsMap[key]) {
                    item.diffsValues.push(key);
                    item.diffsTexts.push($scope.diffsMap[key]);
                }
            });
        });
    };

    const initSelection = (items) => {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        }, "visitInstanceId");
        items.forEach(item => $scope.selectionLogic.initItem(item))
    };

    $scope.clearAllSelection = () => {
        initSelection([...$scope.allAdjustments]);
    };

    $scope.filterByApprovalStatus = (approvalStatus) => {
        if ($scope.filterBy.approvalStatus !== approvalStatus) {
            $scope.filterBy.approvalStatus = approvalStatus;
        }
    };

    const filterTable = () => {
        if (!$scope.allAdjustments) return;

        const selectedChangesIds = $scope.filterBy.changes.map(d => d.id);
        const changeMap = new Map();

        for (const opt of $scope.diffsOptions) {
            changeMap.set(opt.value, selectedChangesIds.includes(opt.id));
        }
        const selectedChanges = $scope.diffsOptions.filter(opt => selectedChangesIds.includes(opt.id)).map(opt => opt.value);

        let items = [...$scope.allAdjustments].filter((item) => {
                const isStatusMatch = $scope.filterBy.approvalStatus ? item.approvalStatus === $scope.filterBy.approvalStatus : true;

                // I don't know if this is the best way but it's simple enough?
                const isDifferencesMatch =
                    $scope.filterBy.requirePerfectMatch
                    ? $scope.diffsOptions.every(opt => item.diffs[opt.value] === changeMap.get(opt.value))
                    : selectedChangesIds.length === 0 ? true : $scope.diffsOptions.some(opt => item.diffs[opt.value] && changeMap.get(opt.value));

                return isStatusMatch && isDifferencesMatch;
            });

        initTable(items);
    };

    const initTable = (items) => {
        const oldCount = $scope.tableParams?.total?.() || 0;
        initSelection(items);
        if (!items) {
            toaster.pop("error", "Oops... Something went wrong");
            return;
        }
        let page = false;
        let sorting = {};
        let count = 25;
        if ($scope.tableParams) {
            page = $scope.tableParams.page();
            sorting = $scope.tableParams.sorting();
            count = $scope.tableParams.count();
        }
        const options = {
            sorting: sorting,
            count: count
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
        if ($scope.globalFilter.val) {
            $scope.applyGlobalSearch($scope.globalFilter.val);
        }
        if (page && oldCount === $scope.tableParams.total()) $scope.tableParams.page(page);
    };

    $scope.applyGlobalSearch = (term) => {
        const filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const filteredAdjustments = $filter("filter")($scope.allAdjustments, term);
            $timeout(() => initSelection(filteredAdjustments));
        }
    };


    $scope.clickTableRow = (item) => {
        if (!item) return;
        $rootScope.openVisitInstanceModal(item.visitInstanceId);
    };

    $scope.goToInvoicePage = (invoiceId) => {
        $rootScope.openInvoiceModal({ invoiceId });
    };

    $scope.updateBulkStatuses = (newStatus) => {
        const selectedItems = $scope.selectionLogic.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            $scope.updateStatuses(selectedItems, newStatus);
        }
    };

    $scope.updateStatuses = (selectedItems, newStatus) => {
        if (!selectedItems || !selectedItems.length) {
            toaster.pop("error", "Something went wrong", "No selected items found to update");
            return;
        }
        // if (newStatus !== "APPROVED") {
        //     updateStatuses(selectedItems, newStatus, false);
        //     return;
        // }
        const selectedVisitsRemovedFromInvoice = selectedItems.filter(item =>
            item.billed && (item.billingIssues || item.diffs.contractChange)
        );
        // if (selectedVisitBilledWithIssues.length === 0) {
        //     updateStatuses(selectedItems, newStatus, false);
        //     return;
        // }

        let messageDescription = "";
        if (newStatus === "APPROVED" && selectedVisitsRemovedFromInvoice.length > 0) {
            const issueText = " billed and currently has billing issues or a changed billing contract";
            if (selectedVisitsRemovedFromInvoice.length === 1 && selectedItems.length === 1) {
                messageDescription = `The selected visit is ${issueText}. Approving changes will remove the visit from its invoice.\n`;
            } else if (selectedVisitsRemovedFromInvoice.length === 1 && selectedItems.length > 1) {
                messageDescription = `One of the selected visits is ${issueText}. Approving changes will remove this visit from its invoice.\n`;
            } else {
                messageDescription = `Some of the selected visits are ${issueText}. Approving changes will remove these visits from their invoice.\n`;
            }
        }

        const numberVisits = selectedItems.length === 1 ? "1 visit" : `${selectedItems.length} visits`;
        const newStatusText = $scope.statuses.find(statusObj => statusObj.id === newStatus).pastText.toLowerCase();

        const modal = mfModal.create({
            subject: "Are you sure?",
            message: `${messageDescription}${numberVisits} will be ${newStatusText}`, // approved / rejected / ingored
            cancelLabel: "Cancel",
            confirmLabel: "Yes",
            variant: "danger",
            onConfirm: () => {
                modal.setLoading(true);
                updateStatuses(selectedItems, newStatus, true, modal);
            }
        });
    };

    const updateStatuses = (items, newStatus, confirmRemoveFromInvoice, modal = null) => {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/adjustment_approval_by_visit",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );
        const body = {
            confirmRemoveFromInvoice: confirmRemoveFromInvoice,
            visits: items.map(item => ({
                visitInstanceId: item.visitInstanceId,
                latestChangeId: item.latestChangeEventId
            })),
            approvalStatus: newStatus
        };
        $scope.isUpdatingStatuses = true;
        const visitInstanceIds = items.map(item => item.visitInstanceId);
        DatabaseApi.patch(url, body).then((res) => {
            $scope.allAdjustments.forEach(item => {
                if (visitInstanceIds.indexOf(item.visitInstanceId) !== -1) {
                    item.approvalStatus = newStatus;
                }
            });
            filterTable();
            toaster.pop("success", "Success");
        }, (err) => {
            toaster.pop("error", "Something went wrong", "Failed to update adjustments statuses");
        }).finally(() => {
            $scope.isUpdatingStatuses = false
            if (modal !== null) {
                modal.close();
                modal.update({isLoading: false});
            }
        });
    };

    const getExportedFileName = function () {
        const filename = "medflyt-adjustment-approvals"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = [
            'Patient',
            'Caregiver',
            'Date',
            'Scheduled Time',
            'Recorded Time',
            'Contract',
            'Office',
            'Invoice',
            'Billing Units From',
            'Billing Units To',
            'Payment Hours From',
            'Payment Hours To',
            'Billing Rate From',
            'Billing Rate To',
            'Pay Rate From',
            'Pay Rate To',
            "Changes Summary",
            'Has Billing Issues',
            'Has Payroll Issues'
        ];
        rows.push(titles);

        // Filter table data by global filter
        $scope.tableParams.data.forEach(dataRow => {
            const csvRow = [];

            csvRow.push('"' + dataRow.patient.displayName + '"');
            csvRow.push('"' + dataRow.caregiver.displayName + '"');
            csvRow.push($filter("mfShortDate")(dataRow.visitDate));
            csvRow.push($filter("hourMinutesRange")(dataRow, 'startTime', 'endTime'));
            csvRow.push($filter("hourMinutesRange")(dataRow, 'clockinTime', 'clockoutTime'));
            csvRow.push('"' + dataRow.contractName + '"');
            csvRow.push(dataRow.officeName);
            csvRow.push(dataRow.invoiceData ? '"' + dataRow.invoiceData.displayId + '"': '');
            csvRow.push(dataRow.billingUnitsThousandths.before / 1000);
            csvRow.push(dataRow.billingUnitsThousandths.after / 1000);
            csvRow.push($filter("secondsToLabel")(dataRow.paymentSeconds.before));
            csvRow.push($filter("secondsToLabel")(dataRow.paymentSeconds.after));
            csvRow.push($filter("centsToDollars")(dataRow.billingRate.before));
            csvRow.push($filter("centsToDollars")(dataRow.billingRate.after));
            csvRow.push($filter("centsToDollars")(dataRow.payRate.before));
            csvRow.push($filter("centsToDollars")(dataRow.payRate.after));
            csvRow.push('"' + dataRow.diffsTexts.join(", ") + '"');
            csvRow.push(dataRow.billingIssues);
            csvRow.push(dataRow.payrollIssues);
            rows.push(csvRow);
        });

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const universalBOM = "\uFEFF";
        const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    $scope.$watch('filterBy', filterTable, true);

    $rootScope.$on("got_caregivers_data", () => {
        $scope.caregiversMap = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", () => {
        $scope.patientsMap = DatabaseApi.patients();
    });

    $rootScope.$on("got_contract_types", () => {
        $scope.contracts = DatabaseApi.contractTypes();
    });

    $rootScope.$on("got_offices", () => {
        $scope.offices = DatabaseApi.offices();
    });

    $rootScope.$on("got_agency_members", () => {
        $scope.agencyMembers = DatabaseApi.getAgencyMembers();
    });

    initialize();
});
