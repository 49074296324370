app.controller('emailModalCtrl', function($scope, DatabaseApi, $rootScope, toaster) {
  $scope.postDocumentsSuffixes = {
    patient: "/send_patient_documents_by_email",
    planOfCare: "/send_plan_of_care_documents_by_email"
  };
  $scope.result = {};
  $scope.isLoading = false;
  $scope.documents = $scope.$resolve.documents;
  let regarding = 'patient';

  $scope.result.subject = `You received ${$scope.documents.length} new file${$scope.documents.length > 1 ? "s" : ""} regarding ${regarding} ${$scope.$resolve.patientFullName}, please review`;

  $scope.send = () => {
    if ($scope.result !== undefined) {
      if ($scope.result.body === undefined) {
        $scope.result.body = "";
      }

      if ($scope.result.subject === undefined) {
        $scope.result.subject = "";
      }

      const documentIds = $scope.documents.map(function(doc) { return doc.id;});
      $scope.isLoading = true;

      let sendDocsUrl = `agencies/${$rootScope.agencyId}`;

      if($scope.$resolve.docsType === 'notes'){
        $scope.result.noteIds = documentIds;
        sendDocsUrl = sendDocsUrl + '/agency_members/' + $rootScope.agencyMemberId;
        const notesUrl = `${$scope.$resolve.patientId}/send_notes_by_email`;
        if($scope.$resolve.isCaregiver){
          sendDocsUrl = `${sendDocsUrl}/caregivers/${notesUrl}`;
        } else {
          sendDocsUrl = `${sendDocsUrl}/patients/${notesUrl}`;
        }
      } else {
        $scope.result.documentIds = documentIds;
        sendDocsUrl = sendDocsUrl + "/" + $rootScope.agencyMemberId;

        const suffix = $scope.postDocumentsSuffixes[$scope.$resolve.docsType];
        if(suffix){
          sendDocsUrl += suffix;
        }
      }

      DatabaseApi.post(sendDocsUrl, $scope.result).then((res) => {
        $scope.$resolve?.onSuccess?.();
        $scope.$close();
      }, (err) => {
        toaster.pop('error', 'Failed, please try again');
        $scope.isLoading = false;
      });
    }
  };

  $scope.getDocumentTitle = (item) => {
    if($scope.$resolve.docsType === 'planOfCare') { 
      return 'Plan of care id_' + item.id;
    } else {
      return item.title;
    }
  }


  $scope.copyHistory = (row) => {
    $scope.result.email = row.email;
    $scope.result.name = row.name;
  };

  if($scope.$resolve.docsType === 'patient') {
    const getHistoryUrl = "agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_documents_sending_history/" + $scope.$resolve.patientId + "/EMAIL";
    DatabaseApi.get(getHistoryUrl).then((res) => {
      $scope.history = res.data.history;
    });
  }
});
