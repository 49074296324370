"use strict";
app.controller("createSimpleNoteModalCtrl", function ($scope, $uibModalInstance, noteService, toaster, entityNoteService, noteConsts) {
  $scope.title = $scope.title || 'New Note';
  $scope.description = $scope.description || '';
  $scope.okButton = $scope.okButton || 'Post note';
  $scope.cancelButton = $scope.cancelButton || 'Cancel';
  $scope.skipSaving = $scope.skipSaving || false;

  $scope.createNote = function () {
    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote($scope.note, $scope.note.noteType);
    if (!isNoteValid) {
        $scope.noteValidations = { isPredefinedValid, isMessageValid };

        return;
    }
    
    if($scope.skipSaving === true){
      return $uibModalInstance.close($scope.note);
    }

    if ($scope.note.entitiesIds) {
        return handleCreateBulkVisitInstanceNotes();
    } else {
        return handleCreateSingleNote();
    }
    
  };

  function handleCreateBulkVisitInstanceNotes() {
    // ONLY HERE WE CREATE MultipleNoteCreateParams
    // ONLY PLACE WHERE SENT AS WHOLE OBJCET
    const createManyParams = {
      type: $scope.note.type,
      entitiesIds: $scope.note.entitiesIds
    };

    const body = entityNoteService.buildEntityNoteRequest(
      $scope.note,
      $scope.note.noteType
    );
    Object.assign(createManyParams, body);

    return noteService.createBulkVisitInstanceNotes(createManyParams).then(function () {
        toaster.pop("success", "Notes created successfully");
        $scope.note.noteRichText = "";
        $uibModalInstance.close("OK");
    })
    .catch(function (err) {
        toaster.pop("warning", "something went wrong", "");
    });
  }

  function handleCreateSingleNote() {

    const actors = {
      patientId: $scope.note.patientId ? $scope.note.patientId : undefined,
      caregiverId: $scope.note.caregiverId ? $scope.note.caregiverId : undefined
    };

    const body = entityNoteService.buildEntityNoteRequest(
      $scope.note,
      $scope.note.noteType,
      actors
    );

    return noteService.createNote(body).then(function () {
        toaster.pop("success", "Note created successfully");
        $scope.note.noteRichText = "";
        $uibModalInstance.close("OK");
    })
    .catch(function (err) {
        toaster.pop("warning", "something went wrong", "");
    });
  }

  $scope.handleNoteChanges = (updatedNote) => {
    if (!$scope.note) {
      $scope.note = {};
    }

    Object.assign($scope.note, updatedNote);
  };

  $scope.closeModal = () => {
    $uibModalInstance.close();
  };
});
