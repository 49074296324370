angular
  .module("dashboard")
  .controller(
    "trainingCenterMediaLibraryBundleItemModal",
    function($scope, $state, $uibModalInstance, $sce) {
      $scope.showBundles = { val: false };
      $scope.agencyBundles = $scope.$resolve.agencyBundles;
      $scope.isProcessing = $scope.$resolve.isProcessing;
      $scope.onChooseBundle = $scope.$resolve.onChooseBundle;
      $scope.onChooseNewBundle = $scope.$resolve.onChooseNewBundle;
      $scope.bundleItem = $scope.$resolve.bundleItem;
      $scope.createdBundleItem = $scope.$resolve.createdBundleItem;
      $scope.selectedBundleId = $scope.$resolve.selectedBundleId;
      $scope.goToItem = goToItem;
      $scope.closeModal = closeModal;

      this.$onInit = function () {
        $scope.agencyBundles.forEach(bundle => {
          bundle.__displayData = _.keyBy(bundle.bundleDisplayData, "language")
        });
      }

      function goToItem() {
        $state.go("app.trainingCenterBundleItemSettings", {
          bundleId: $scope.selectedBundleId.val,
          bundleItemId: $scope.createdBundleItem.val.bundleItemId,
        });
        closeModal();
      }

      function closeModal() {
        $uibModalInstance.dismiss();
      }
    }
  );
