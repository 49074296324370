app.controller('assignModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, visit, caregiver, DatabaseApi, toaster, Socket, SweetAlert, $timeout, Analytics){

    window.scrollTo(0,0);

    $scope.caregiver = caregiver;
    $scope.visit = visit;

    $scope.from = $scope.visit.startTime;
    $scope.to = $scope.visit.endTime;
    $scope.from = moment($scope.from).add(1, 'days');
    $scope.to = moment($scope.to).subtract(1, 'days');

    $scope.partially = {

    };

    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    $scope.switchInfoText = function(type){
        if(type === 'both'){
            if($scope.partially.startDate && $scope.partially.endDate) return true;
            return false;

        } else if(type === 'to'){
            if(!$scope.partially.startDate && $scope.partially.endDate) return true;
            return false;

        } else if(type === 'from'){
            if($scope.partially.startDate && !$scope.partially.endDate) return true;
            return false;
        }
    };

    console.log(visit);
    console.log(caregiver);

    function getNowToLocalTime(dt) {
        if(dt){
            //dt = new Date(dt)//.toISOString();
            var now = JSJoda.LocalDateTime.of(dt.getFullYear(), dt.getMonth() + 1, dt.getDate(), 0,0,0,0).toString().split('.')[0];

        } else {
            var now = JSJoda.ZonedDateTime.now(JSJoda.ZoneId.of($scope.visit.timezone)).toString().split('.')[0];

        }
        var date = now.split('T')[0];
        var time = now.split('T')[1];
        time = time.split(':')[0] + ':' + time.split(':')[1];
        var dateTime = date + 'T' + time;
        //now = JSJoda.LocalDateTime.parse(dateTime);
        //now = new Date(now).getTime();
        return dateTime;
    }
/*

    function getTimeFromJodaLocalTime(dt) {
        var now = JSJoda.LocalDateTime.parse(dt);
        now = new Date(now).getTime();
        return now;
    }

    function getLocalTimeFromTimestamp(dt) {
        var date = new Date(dt).toISOString();

    }
*/

//[
// {"day": 0, "endTime": "17:00", "startTime": "16:00"},
// {"day": 1, "endTime": "05:00", "startTime": "04:00"},
// {"day": 3, "endTime": "09:00", "startTime": "08:00"}
//]
    function getNextOccurrenceFromParams(from, nowLocalTime, nowString, days){

        var availableDays = [];
        var mapDays = {};
        days.forEach(function (day) {
            availableDays.push(day.day);
            mapDays[day.day.toString()] = day;
        });

        //console.log(availableDays);
        var d = moment(from);

        for(var i = 0 ; i < 14 ; i++){
            var dayOfWeek = d.day();

            if(availableDays.indexOf(dayOfWeek) > -1
                &&
                mapDays[dayOfWeek.toString()]
                &&
                (mapDays[dayOfWeek.toString()].startTime > nowLocalTime || i > 0 || nowString < from)
            ){
                var formatedDate =  d.format();
                var date = formatedDate.split('T')[0];
                var time = formatedDate.split('T')[1];
                time = time.split(':')[0] + ':' + time.split(':')[1];
                return date + 'T' + mapDays[dayOfWeek.toString()].startTime;
            }
            d.add(1, 'd');
        }

        return 'error';

    }

    $scope.getNextShift = function(visit){
        var start;

        if($scope.partially.startDate || $scope.partially.endDate){
            $scope.disableAssign = true;
        } else {
            $scope.disableAssign = false;
        }
        if($scope.partially.startDate){
            start = getNowToLocalTime($scope.partially.startDate);
        } else {
            start = visit.startTime;
        }
        var now = getNowToLocalTime();


        var startTime = now > start ? now : start;
        var recurringParams = visit.dayTimeParams ? visit.dayTimeParams : null;
        var caregiverAcceptedData = $scope.caregiver.acceptData ? $scope.caregiver.acceptData : null;

        var localTime = now.split('T')[1];

        var nextDate;
        if(caregiverAcceptedData){
            nextDate = getNextOccurrenceFromParams(startTime, localTime, now, caregiverAcceptedData.dayTimes);
        } else {
            nextDate = getNextOccurrenceFromParams(startTime, localTime, now, recurringParams.dayTimes);
        }

        return nextDate;

    };

    $scope.assign = function(from, to){



        if(!$scope.caregiver || (!$scope.caregiver.id && !$scope.caregiver.caregiver )) return toaster.pop('warning', "Something went wrong", '');

        var caregiverId = caregiver.caregiver ? caregiver.caregiver : caregiver.id;


        //if(!from && !to) return toaster.pop('warning', "Missing dates", '');

        var datesData = {
            originalStartTime: visit.startTime,
            originalEndTime: visit.endTime
        };

        var LocalDateTime = JSJoda.LocalDateTime;
        /*var d = moment();
        d = new Date(d);
        var date = JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds() * 1000000);
        console.log(LocalDateTime.from(date).toLocalDate());
        console.log(LocalDateTime.from(date).toLocalDate().toString());*/

        if(from && to){
            datesData.type = 'StartAndEndDate';
            //LocalDateTime.from(moment(from).endOf("day"));
            //LocalDateTime.from(new Date()).toLocalDate();

            //var LocalDate = JSJoda.LocalDate;

            var start = new Date(moment(from).endOf("day"));
            var end = new Date(moment(to).endOf("day"));

            var startJoda = JSJoda.LocalDateTime.of(start.getFullYear(), start.getMonth() + 1, start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds(), start.getMilliseconds() * 1000000);

            var endJoda = JSJoda.LocalDateTime.of(end.getFullYear(), end.getMonth() + 1, end.getDate(), end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds() * 1000000);

            datesData.startAt = LocalDateTime.from(startJoda).toLocalDate().toString();//JSON.stringify(moment(from).endOf("day")).slice(1,-1);
            datesData.endAt = LocalDateTime.from(endJoda).toLocalDate().toString();//JSON.stringify(moment(to).endOf("day")).slice(1,-1);
            if(from >= to) return toaster.pop('warning', "Bad dates", '');
        } else if(from){
            var start = new Date(moment(from).endOf("day"));
            var startJoda = JSJoda.LocalDateTime.of(start.getFullYear(), start.getMonth() + 1, start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds(), start.getMilliseconds() * 1000000);

            datesData.type = 'StartDate';
            datesData.startAt = LocalDateTime.from(startJoda).toLocalDate().toString();//JSON.stringify(moment(from).endOf("day")).slice(1,-1);

        } else if(to){
            var end = new Date(moment(to).endOf("day"));
            var endJoda = JSJoda.LocalDateTime.of(end.getFullYear(), end.getMonth() + 1, end.getDate(), end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds() * 1000000);


            datesData.type = 'EndDate';
            datesData.endAt = LocalDateTime.from(endJoda).toLocalDate().toString();//JSON.stringify(moment(to).endOf("day")).slice(1,-1);
        } else {
            datesData = null;
        }


        var url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/visits/" + visit.id  + "/assigned_to";
        var body = {
            caregiverId: caregiverId,
            partialAssignDetails: datesData,
            flexibleVisitRequestParams: null
        };

        DatabaseApi.put(url, body).then(function(res){
            if (res.data.assignedWithOvertime) {
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: `Caregiver was assigned with increased caregiver overtime`
                });
            } else {
              toaster.pop("success", "Success", "Caregiver has assigned to visit");
            }

            Analytics.event('visit-modal-assigned', {
                visitId: visit.id,
                status: 'scheduled',
                newCaregiver: caregiverId,
                accepted: visit.acceptedBy.length
            });

            //$rootScope.sweepScore.points += 100;
            $uibModalInstance.close('cancel');

        }, function(err){
            var errText = '';
            if(err.data) errText = err.data.error ? err.data.error : '';

            if(err.status === 409) toaster.pop('error', "Assigning Conflict", errText);
            else toaster.pop('error', "Something went wrong", errText || err.data );
        });
    }

});