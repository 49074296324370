app.controller('uploadMultipleDocModalCtrl', function ($scope, $rootScope, $uibModalInstance, docType, toaster, Consts, $http, proId, combinePdf) {
    window.scrollTo(0, 0);
    $scope.docType = docType;
    $scope.uploading = false;
    $scope.files = null;
    $scope.newFiles = null;
  
    $scope.closeModal = () => {
        $uibModalInstance.dismiss();
    };
  
    $scope.popup1 = { opened: false };
    $scope.open1 = () => {
        $scope.popup1.opened = true;
    };
  
    $scope.uploadFiles = async (expirationDate) => {
        if (!$scope.docType.documentTypeId || !$scope.files || $scope.files.length === 0) {
            toaster.pop('error', 'No file(s) selected');
            return
        }
        $scope.uploading = true;

        try {
            const fetchPromisesWithTypes = [];
            for (const file of $scope.files) {
                const fetchCurrentFilePromise = getBase64(file);
                if (file.type === 'application/pdf') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'pdf' });
                } else if (file.type === 'image/png') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'png' });
                } else if (file.type === 'image/jpg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpg' });
                } else if (file.type === 'image/jpeg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpeg' });
                }
            }
            const bytesPdf = await combinePdf(fetchPromisesWithTypes);
            const blob = new Blob([bytesPdf], {type: "application/pdf"});
            const combinedPdfFile = new File([blob], 'Combined.pdf');

            const url = `${Consts.api}hr/agencies/${$rootScope.agencyId}/coordinator/${$rootScope.agencyMemberId}/caregiver/${proId}/document_upload`;
            if (expirationDate) {
                expirationDate = JSON.stringify(new Date(expirationDate)).slice(1, -1);
            }
            const formData = new FormData();
            formData.append('file', combinedPdfFile, combinedPdfFile.name);
            formData.append('documentTypeId', $scope.docType.documentTypeId);
            if (expirationDate) {
                formData.append('expirationDate', expirationDate);
            }
            await $http({
                url: url,
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': undefined }
            }).then((res) => {
                toaster.pop('success', "Success", "File(s) Uploaded");
                $uibModalInstance.close(true);
            }).catch((err) => {
                $scope.newFiles = null;
                toaster.pop('error', "Failed uploading document");
            }).finally(() => {
                $scope.uploading = false;
            });
        } catch (e) {
            $scope.newFiles = null;
            $scope.uploading = false;
            toaster.pop('error', "Something Went Wrong", 'Please try again');
            console.error(e);
        }
    };

    $scope.removeFile = (fileIndex) => {
        if (!$scope.files || !$scope.files[fileIndex]) {
            toaster.pop('error', 'File not found')
            return;
        }
        $scope.files.splice(fileIndex, 1);
        $scope.filesNames.splice(fileIndex, 1);
    }
  
    $scope.$watch('newFiles', () => {
        if (!$scope.newFiles || $scope.newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        }
        const newFiles = $scope.files ? $scope.newFiles.filter(file => !$scope.files.find(f => f.name === file.name)) : $scope.newFiles;
        if (!newFiles || newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        };
        const allFiles = $scope.files ? $scope.files.concat(newFiles) : newFiles;
        newFiles.forEach(file => {
            let fileType = file.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (['pdf', 'png', 'jpg', 'jpeg'].indexOf(fileType) === -1) {
                toaster.pop('warning', "Unsupported file type", '');
            }
        });
        $scope.filesNames = allFiles.map(file => file.name);
        if ($scope.files !== allFiles) $scope.files = allFiles;
        $scope.newFiles = null;
    });

    $scope.$watch('files', () => {
        if (!$scope.files || $scope.files.length === 0) {
            $scope.filesNames = [];
            return;
        }
        $scope.filesNames = $scope.files.map(file => file.name);
    });

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                toaster.pop('error', "Something Went Wrong", 'Please try again');
                reject();
            };
        })
    }
});