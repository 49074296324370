app.controller('videosModalCtrl', function ($scope, NgTableParams) {
   $scope.caregiver = $scope.$resolve.caregiverWithVideos;

   var options = {
    count: 200,
    sorting: {displayName: "desc"}
   };

    $scope.langs = [
        "English",
        "Spanish",
        "Russian",
        "Chinese",
        "French",
        "Arabic"
    ];

    $scope.lang = {val: "English"};

    $scope.videosTable = new NgTableParams(options, {
        counts: [],
        dataset: $scope.caregiver.videos
    });
});