"use strict";

app.component("patientPhysicians", {
    templateUrl: "admin/views/patient-physicians.html",
    bindings: {
        patientId: "<"
    },
    controller: function (
        $scope,
        $rootScope,
        DatabaseApi,
        physiciansService,
        NgTableParams,
        toaster,
        $uibModal,
        SweetAlert
    ) {
        const initialize = () => {
            this.physicians = DatabaseApi.physicians();
            getPatientPhysicians();
        };

        const mapPatientPhysicians = () => {
            $scope.$ctrl.patientPhysicians.forEach(p => {
                const agencyPhysician = this.physicians.find(item => item.id === p.physicianId);
                if (agencyPhysician) {
                    p.displayName = agencyPhysician.displayName;
                    p.npi = agencyPhysician.npi;
                    p.address = agencyPhysician.address;
                    p.phone = agencyPhysician.phone;
                    p.fax = agencyPhysician.fax;
                }
            });

            initPatientPhysiciansTable();
        }

        const getPatientPhysicians = () => {
            physiciansService.getPatientPhysicians(this.patientId).then(function (res) {
                $scope.$ctrl.patientPhysicians = res.physicians;
                mapPatientPhysicians();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Couldn't get patient physicians");
            });
        };

        const initPatientPhysiciansTable = () => {
            const options = {
                count: 10,
                sorting: { id: "desc" }
            };

            this.patientPhysiciansTable = new NgTableParams(options, {
                counts: [],
                dataset: this.patientPhysicians
            });
        };

        this.handleToggleIsPrimary = (row) => {
            const body = {
                isPrimary: row.isPrimary
            };

            physiciansService.updatePatientPhysician(this.patientId, row.id, body)
                .then(function (res) {
                    toaster.pop('success', "Patient physician updated successfully");
                    if (row.isPrimary === true) {
                        const primaryPhysician = $scope.$ctrl.patientPhysicians
                            .find(p => p.isPrimary === true && p.id !== row.id);
                        if (primaryPhysician) {
                            primaryPhysician.isPrimary = false;
                        }
                    }
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Failed to update patient physician");
                });
        }

        const deletePatientPhysician = (patientPhysicianId) => {
            physiciansService.removePatientPhysician(this.patientId, patientPhysicianId).then(function (res) {
                $scope.$ctrl.patientPhysicians = $scope.$ctrl.patientPhysicians.filter(p => p.id !== patientPhysicianId);
                initPatientPhysiciansTable();
                toaster.pop('success', "Patient physician removed successfully");
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Failed to remove patient physician");
            });
        };

        this.handleClickDeletePatientPhysician = (physicianId) => {
            SweetAlert.swal(
                {
                    title: "Remove Patient Physician",
                    text: "Are you sure you want to remove this physician for the current patient?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3898ff",
                    confirmButtonText: "Yes, remove",
                    closeOnConfirm: true
                },
                hasConfirmed => hasConfirmed && deletePatientPhysician(physicianId)
            );
        };

        this.openNewPatientPhysicianModal = () => {
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/new-patient-physician-modal.html',
                size: 'lg',
                controller: 'newPatientPhysicianModalCtrl',
                resolve: {
                    patientId: () => this.patientId,
                    patientPhysicians: () => this.patientPhysicians
                }
            });
            modalInstance.result.then(function (res) {
                if (res.id) {
                    if (res.isPrimary === true) {
                        const primaryPhysician = $scope.$ctrl.patientPhysicians.find(p => p.isPrimary === true);
                        if (primaryPhysician) {
                            primaryPhysician.isPrimary = false;
                        }
                    }

                    $scope.$ctrl.patientPhysicians.push(res);
                    mapPatientPhysicians();
                }
            }, function () {
            });
        };

        $rootScope.$on('got_physicians', function (event) {
            initialize();
        });

        this.$onInit = function () {
            initialize();
        };
    }
});
