"use strict";

angular.module("dashboard").component("progressBar", {
  templateUrl: "admin/views/progress-bar.html",
  controller: () => {},
});

angular.module("dashboard").component("masterWeek", {
  templateUrl: "admin/views/master-week.html",
  controller: "masterWeekCtrl",
  bindings: {
    patient: "<",
  },
});

angular.module("dashboard").component("caregiverTrainingCenter", {
  templateUrl:
    "admin/views/caregiverTrainingCenter.html",
  controller: "caregiverTrainingCenterCtrl",
  bindings: {
    caregiver: "<",
  },
});

angular.module("dashboard").component("caregiverTrainingCenterBundle", {
  templateUrl:
    "admin/views/caregiverTrainingCenterBundle.html",
  controller: "caregiverTrainingCenterBundleCtrl",
  bindings: {
    caregiver: "<",
    bundle: "<",
  },
});

angular.module("dashboard").component("caregiverTrainingCenterDueDate", {
  templateUrl:
    "admin/views/caregiverTrainingCenterDueDate.html",
  controller: "caregiverTrainingCenterDueDateCtrl",
  bindings: {
    caregiver: "<",
    bundle: "<",
    dueDate: "<",
  },
});

angular.module("dashboard").component("caregiverTrainingCenterInfoBundleDueDateHeader", {
  templateUrl:
    "admin/views/caregiverTrainingCenterInfoBundleDueDateHeader.html",
  controller: "caregiverTrainingCenterInfoBundleDueDateHeaderCtrl",
  bindings: {
    caregiver: "<",
    bundle: "<",
    dueDate: "<",
  },
});

angular.module("dashboard").component("nursingQuestion", {
  templateUrl: "admin/views/nursing-question.html",
  controller: function (nursingMedicalStatisticsService, $timeout) {
    this.getPercentage = function (question, answer) {
      const total = this.getTotalCount(question);

      return Math.round((answer.count / total) * 100);
    };

    this.getTotalCount = function (question) {
      let sum = 0;

      for (const answer of question.answerStats) {
        sum += answer.count;
      }

      return sum;
    };

    this.$onInit = function () {
      var that = this;
      // show chart only if its a sub-question
      if (this.showChart) {
        $timeout(function () {
          that.setChart();
        });
      }
    };

    this.setChart = function () {
      if (!window.Chart) {
        throw new Error(
          "Can't display Nursing Question Charts because ChartJS is not defined"
        );
      }

      const canvas = document.getElementById(
        `nursing-question-modal-chart-` + this.question.id
      );
      const ctx = canvas.getContext("2d");

      const chart = new Chart(
        ctx,
        nursingMedicalStatisticsService.getChartConfigByQuestion(this.question)
      );
    };
  },
  bindings: {
    question: "=",
    isInitialQuestion: "=",
    selectPatientsByAnswer: "&",
    showChart: "=",
  },
});
