'use strict';

app.directive("validateDateInput", function () {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;

  return {
      restrict: 'A',
      link: function(_, element) {
          element.on("input", function () {
              const input = element.find('input');
              const btn = element.find('.btn');
              const value = input[0].value;
              const valid = regex.test(value);
              if (valid) {
                input.removeClass('error');
                btn.removeClass('error');
              } else {
                input.addClass('error');
                btn.addClass('error');
              }
          })
      }
  }  
});
