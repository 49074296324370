/**
 * ORIGINAL FILE = patientsDocumentsCtrl.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
const tableSettings = {
    count: 25,
    sorting: {
        "document.createdAt": "desc",
    },
};
class PatientsDocumentsCtrl {
    patientDocumentService;
    NgTableParams;
    toaster;
    $rootScope;
    dateUtils;
    dateRangeDefaultOptions;
    assertNever;
    createDropdownFilter;
    patientStatusUtils;
    documents = [];
    dropdownFilterManager;
    dateRangeOptions;
    table;
    isLoading = false;
    filters = {
        freeText: "",
        dateRange: {
            dueDate: {
                startDate: null,
                endDate: null,
            },
        },
        dropdowns: [],
    };
    dropdownFilterExtraSettings = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
    };
    dropdownFilterEvents = {};
    constructor(patientDocumentService, NgTableParams, toaster, $rootScope, dateUtils, dateRangeDefaultOptions, assertNever, createDropdownFilter, patientStatusUtils) {
        this.patientDocumentService = patientDocumentService;
        this.NgTableParams = NgTableParams;
        this.toaster = toaster;
        this.$rootScope = $rootScope;
        this.dateUtils = dateUtils;
        this.dateRangeDefaultOptions = dateRangeDefaultOptions;
        this.assertNever = assertNever;
        this.createDropdownFilter = createDropdownFilter;
        this.patientStatusUtils = patientStatusUtils;
        this.table = new this.NgTableParams(tableSettings, { dataset: [] });
        this.dropdownFilterEvents = {
            onSelectionChanged: () => this.onFilterChange(),
        };
        this.dateRangeOptions = {
            ...dateRangeDefaultOptions,
            eventHandlers: {
                "apply.daterangepicker": () => this.loadTable(),
            },
        };
        this.filters = {
            freeText: "",
            dateRange: {
                dueDate: {
                    startDate: null,
                    endDate: null,
                },
            },
            dropdowns: [
                {
                    title: "Patient status",
                    entityPath: ["patient", "status"],
                    options: [],
                    values: [],
                },
                {
                    title: "Document type",
                    entityPath: ["document", "type", "title"],
                    options: [],
                    values: [],
                },
                {
                    title: "Document status",
                    entityPath: ["document", "status"],
                    options: [],
                    values: [],
                },
                {
                    title: "Assigned nurse",
                    entityPath: ["assignedNurse", "name"],
                    options: [],
                    values: [],
                },
            ],
        };
        this.dropdownFilterManager = this.createDropdownFilter({
            dropdowns: this.filters.dropdowns
        });
        this.loadTable();
    }
    onFilterChange = () => {
        this.setTable(this.documents);
    };
    getDocumentStatusLabelColor = (status) => {
        switch (status) {
            case "OPEN_FOR_RESUBMISSION":
            case "IN_PROGRESS":
                return "orange";
            case "SENT":
            case "COMPLETED":
            case "SIGNED":
                return "blue";
            case "MISSING":
                return "red";
            default:
                this.assertNever(status);
        }
    };
    openPatientModal = (patientId) => {
        this.$rootScope.openPatientModal(patientId);
    };
    resetDueDate = () => {
        this.filters.dateRange.dueDate = {
            startDate: null,
            endDate: null,
        };
        this.loadTable();
    };
    loadTable = () => {
        this.isLoading = true;
        const filters = {
            dueDate: this.filters.dateRange.dueDate.startDate !== null &&
                this.filters.dateRange.dueDate.endDate !== null
                ? {
                    from: this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.dueDate.startDate.format("YYYY-MM-DD")),
                    to: this.dateUtils.ymdStringToLocalDate(this.filters.dateRange.dueDate.endDate.format("YYYY-MM-DD")),
                }
                : undefined,
        };
        this.patientDocumentService
            .getPatientsDocuments(filters)
            .then((documents) => {
            const parsed = documents.map(this.patientDocumentService.parseAgencyPatientDocument);
            this.documents = parsed;
            this.setTable(parsed);
        })
            .catch(() => {
            this.toaster.pop("error", "Something went wrong", "Could not get patients documents list");
        })
            .finally(() => (this.isLoading = false));
    };
    setTable(documents) {
        const filteredDocuments = this.filterDocuments(documents);
        this.table = new this.NgTableParams(tableSettings, { dataset: filteredDocuments });
        this.dropdownFilterManager.setOptionsFromLocalData(documents);
    }
    filterDocuments(documents) {
        const query = this.filters.freeText.toLowerCase();
        return documents.filter((document) => {
            return (this.dropdownFilterManager.filter(document) && this.filterDocumentByFreeText(document, query));
        });
    }
    filterDocumentByFreeText(document, lowerCaseQuery) {
        return (lowerCaseQuery === "" ||
            document.patient.name.toLowerCase().includes(lowerCaseQuery) ||
            document.patient.displayId?.toString() === lowerCaseQuery);
    }
}
angular.module("dashboard").controller("patientsDocumentsCtrl", PatientsDocumentsCtrl);
export {};
