app.controller('caregiverDocumentSendPdfModalCtrl', function ($scope, DatabaseApi, $rootScope, toaster, $uibModalInstance) {

    $scope.closeModal = () => {
        $uibModalInstance.close();
    };
    
    $scope.sendPdf = () => {
        $scope.isLoading = true;
        const url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/caregiver/' + $scope.caregiverId +
                    '/send_document';
        const encodedPdf = $scope.type === 'email' ? $scope.encodedPdf.split("base64,")[1] : $scope.encodedPdf;
        const reqObj = Object.assign($scope.req, {
            type: $scope.type,
            encodedPdf: encodedPdf
        });
        DatabaseApi.post(url, reqObj).then(function () {
            toaster.pop('success', "Success", "Document sent successfully");
            $scope.closeModal();
        }, function () {
            toaster.pop('error', 'Something went wrong', 'could not send document');
            $scope.isLoading = false;
        });
    }
});
