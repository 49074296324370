app.controller('exclusionListModalCtrl', function($scope, $rootScope, NgTableParams, $state, $stateParams, $uibModalInstance, DatabaseApi, toaster, $window){

    $scope.caregiverId = $scope.$resolve.caregiverId;
    $scope.isLoading = {
      check: false,
      list: false,
    };
    $scope.history = [];

    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    $scope.openUrl = function(url){
        $window.open(url);
    };

    function getHistory() {
        $scope.isLoading.list = true;
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.caregiverId + '/exclusion_history').then(function (res) {

            $scope.history = res.data.exclusions
              .map(mapHistory);
            $scope.isLoading.list = false;
        }, function (err) {
            toaster.pop('error', "Something went wrong", "");
            $scope.isLoading.list = false;
        });
    }

    getHistory();

    $scope.initiateExclusionTest = function () {

        $scope.isLoading.check = true;
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.caregiverId + '/queue_exclusion_search').then(function (res) {

            $scope.isLoading.check = false;
            $scope.history = res.data.exclusions
              .map(mapHistory);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "");
            $scope.isLoading.check = false;
        });
    };

    $scope.shouldShowMore = function ({ longEnoughToHide, showMoreState }) {
      return longEnoughToHide && !showMoreState;
    };

    function getStatus({flagged, noMatch}) {
      if (noMatch) return "Un-flagged";
      return flagged ? "Flagged" : "Un-flagged";
    }

    function getStatusColor({flagged, noMatch}) {
      if (noMatch) return "green";
      return flagged ? "red" : "green";
    }

    function getOriginalRowFormatted(json) {
      if (!json) return;
      const originalRow = json.v;
      if (!originalRow) return;

      const keys = Object.keys(originalRow);
      const keyValuePairs = keys.map((key) => ({
        key,
        value: originalRow[key],
      }));

      return keyValuePairs;
    }

    function mapHistory(exclusion) {
        const originalRowFormatted = getOriginalRowFormatted(exclusion.rowJson);
        const newExclusion = {
            ...exclusion,
            ...exclusion.data,
            showMore: false,
            status: getStatus(exclusion),
            color: getStatusColor(exclusion),
            originalRowFormatted,
            longEnoughToHide: !originalRowFormatted ? false : originalRowFormatted.length > 4,
        };

        return newExclusion;
    }
});