app.controller('uploadComplianceInstanceMultipleDocModalCtrl', function ($scope, $rootScope, $uibModalInstance, complianceInstanceId, toaster, Consts, $http, proId, combinePdf) {
    window.scrollTo(0, 0);
    $scope.complianceInstanceId = complianceInstanceId;
    $scope.uploading = false;
    $scope.files = null;
    $scope.newFiles = null;
  
    $scope.closeModal = () => {
        if ($scope.uploading) {
            return;
        }
        $uibModalInstance.dismiss();
    };
  
    $scope.popup1 = { opened: false };
    $scope.open1 = () => {
        $scope.popup1.opened = true;
    };
  
    $scope.uploadFiles = async () => {
        if (!$scope.complianceInstanceId || !$scope.files || $scope.files.length === 0) {
            toaster.pop('error', 'No file(s) selected');
            return;
        }
        $scope.uploading = true;

        try {
            const fetchPromisesWithTypes = [];
            for (const file of $scope.files) {
                const fetchCurrentFilePromise = getBase64(file);
                const type = getFileTypeByMimeType(file.type);
                if (type === null) {
                    continue;
                }
                fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: type });
            }
            const base64 = await combinePdf(fetchPromisesWithTypes, "base64");
            const url = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}
                        /caregivers/${proId}/complaince_instances/${$scope.complianceInstanceId}/attach_file`;
            const body = {
                base64: base64
            };
            await $http({
                url: url,
                method: 'POST',
                data: body
            }).then((res) => {
                toaster.pop('success', "Success", "File(s) Uploaded");
                $uibModalInstance.close(true);
            }).catch((err) => {
                $scope.newFiles = null;
                toaster.pop('error', "Failed uploading document");
            }).finally(() => {
                $scope.uploading = false;
            });
        } catch (e) {
            $scope.newFiles = null;
            $scope.uploading = false;
            toaster.pop('error', "Something Went Wrong", 'Please try again');
        }
    };

    $scope.removeFile = (fileIndex) => {
        if (!$scope.files || !$scope.files[fileIndex]) {
            toaster.pop('error', 'File not found')
            return;
        }
        $scope.files.splice(fileIndex, 1);
        $scope.filesNames.splice(fileIndex, 1);
    }
  
    $scope.$watch('newFiles', () => {
        if (!$scope.newFiles || $scope.newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        }
        const newFiles = $scope.files ? $scope.newFiles.filter(file => !$scope.files.find(f => f.name === file.name)) : $scope.newFiles;
        if (!newFiles || newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        };
        const allFiles = $scope.files ? $scope.files.concat(newFiles) : newFiles;
        newFiles.forEach(file => {
            let fileType = file.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (['pdf', 'png', 'jpg', 'jpeg'].indexOf(fileType) === -1) {
                toaster.pop('warning', "Unsupported file type", '');
            }
        });
        $scope.filesNames = allFiles.map(file => file.name);
        if ($scope.files !== allFiles) $scope.files = allFiles;
        $scope.newFiles = null;
    });

    $scope.$watch('files', () => {
        if (!$scope.files || $scope.files.length === 0) {
            $scope.filesNames = [];
            return;
        }
        $scope.filesNames = $scope.files.map(file => file.name);
    });

    function getFileTypeByMimeType(mimeType) {
        switch (mimeType) {
            case "application/pdf":
                return "pdf";
            case "image/png":
                return "png";
            case "image/jpg":
                return "jpg";
            case "image/jpeg":
                return "jpeg";
            default:
                return null;
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                toaster.pop('error', "Something Went Wrong", 'Please try again');
                reject();
            };
        })
    }
});