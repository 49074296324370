angular.module("dashboard").factory("nursingMedicalStatisticsService", ($http, $rootScope, Consts, wildcard) => ({
    get() {
        return $http.get(wildcard(Consts.api + "agencies/:agencyId/agency_members/:agencyMemberId/nursing_questions", $rootScope.agencyId, $rootScope.agencyMemberId));
    },

    getPatients(questionId, answer) {
        return $http.get(
            wildcard(Consts.api + "agencies/:agencyId/agency_members/:agencyMemberId/nursing_questions/:nursingQuestionId/answers/:answer/patients",
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                questionId,
                answer
            ));
    },

    getChartConfigByQuestion(question) {
        const colorsArray = [
            "#46A8FF", // BLUE_COLOR
            "#FF5684", // RED_COLOR
            "#FF9A31", // ORANGE_COLOR
            "#FFC006", // YELLOW_COLOR
            "#3077db", // GREEN_COLOR
        ];

        if (question.answerStats.length > 5) {
            for (let index = 5; index <= question.answerStats.length; index++) {
                colorsArray.push('#' + (co('')));
            }
        }

        return {
            type: "pie",
            data: {
                datasets: [
                    {
                        data: question.answerStats.map(answer => answer.count),
                        backgroundColor: colorsArray,
                        label: question.questionText
                    }
                ],
                labels: question.answerStats.map(answer => answer.title)
            },
            options: {
                maintainAspectRatio: false,
                defaultFontColor: "#FFFFFF",
                defaultFontFamily: "Open Sans",
                defaultFontSize: 13,
                legend: {
                    padding: 30,
                    position: "bottom",
                    labels: {
                        usePointStyle: true,
                        fontColor: "#53606C",
                        fontFamily: "Open Sans",
                        fontSize: 13
                    }
                }
            }
        };
    }
}));

function co(lor) {
    return (
        lor += [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f'][Math.floor(Math.random() * 16)])
        && (lor.length == 6) ? lor : co(lor);
}