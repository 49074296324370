/**
 * ORIGINAL FILE = missedVisitService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
export class MissedVisitService {
    DatabaseApi2;
    $rootScope;
    constructor(DatabaseApi2, $rootScope) {
        this.DatabaseApi2 = DatabaseApi2;
        this.$rootScope = $rootScope;
    }
    missVisits = (body) => {
        const missVisitsUrl = `agencies/${this.$rootScope.agencyId}/agency_members/${this.$rootScope.agencyMemberId}/bulk_edit_visit_instance`;
        return this.DatabaseApi2.post(missVisitsUrl, body).then((res) => {
            return res.data;
        });
    };
}
angular.module("dashboard").service("missedVisitService", MissedVisitService);
