app.component("patientDutySheet", {
    templateUrl: "admin/views/patient-duty-sheet.html",
    bindings: {
        patientId: '<',
        patientFullName: '<',
        patientCurrentOfficeId: '<'
    },
    controller: function ($scope, $rootScope, DatabaseApi, toaster, $uibModal, wildcard) {
        $scope.dutyAnswerIconTypesMap = {
            MOBILE: 'admin/images/icons/caregiver.svg',
            IVR: 'admin/images/icons/call.svg',
            COORDINATOR: 'admin/images/icons/operator.svg'
        }

        $scope.caregivers = DatabaseApi.caregivers();
        $scope.payrollSetups = DatabaseApi.payrollSetups();
        $scope.caregiversDisplayManagement = {};
        $scope.showOptionalTaskRows = false;

        $scope.openExportDutySheetDataModal = () => {
            const patientExtraDetails = {
                'Patient Name': $scope.$parent.patient.firstName + " " + $scope.$parent.patient.lastName,
                'Patient Address': $scope.$parent.patient.address.text,
                'Patient Date Of Birth': moment($scope.$parent.patient.dateOfBirth).format('MM/DD/YYYY')
            }
            
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/export-duty-sheet-data-modal.html',
                size: 'md',
                controller: 'exportDutySheetDataModal',
                resolve: {
                    fromDate: () => $scope.startOfWeekDate,
                    patientId: () => $scope.$ctrl.patientId,
                    patientExtraDetails: () => patientExtraDetails,
                    patientDocumentVersionId: () => $scope.selectedPlanOfCareWithDutySheets.planOfCare.patientDocumentVersionId
                }
            });

            modalInstance.result.then((res) => {
                if (res === 'success') {
                    $scope.getPlanOfCare();
                    $rootScope.$emit('patient_poc_updated');
                }
            }, (e) => {
            });
        };

        $scope.updateAnswerForTask = (duty) => {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/duty_sheets/answer"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", duty.visitInstanceId);

            const body = {
                dutyId: parseInt(duty.originalId, 10),
                completed: !duty.isDone
            };

            duty.typeIcon = $scope.dutyAnswerIconTypesMap.COORDINATOR;
            DatabaseApi.post(url, body).then(() => {
                toaster.pop("success", "Task updated successfully");
                updateVisitInstanceDutyAnswer(duty, body);
            }).catch (() => {
                toaster.pop("error", "Something went wrong");
            });
        };
        
        function updateVisitInstanceDutyAnswer(duty) {
            const visitInstanceIdx = $scope.selectedPlanOfCareWithDutySheets.dutySheets.findIndex(v => v.visitInstanceId === duty.visitInstanceId);
            if (visitInstanceIdx > -1) {
                const answerIndex = $scope.selectedPlanOfCareWithDutySheets.dutySheets[visitInstanceIdx].answers.findIndex(a => a.questionId === duty.id);
                if (answerIndex > -1) {
                    let answer = $scope.selectedPlanOfCareWithDutySheets.dutySheets[visitInstanceIdx].answers[answerIndex].answer;
                    if (duty.isDone) {
                        answer.push("Done")
                    } else {
                        const doneIndex = answer.indexOf("Done");
                        if (doneIndex > -1) {
                            answer.splice(doneIndex, 1);
                        }
                    }
                    $scope.selectedPlanOfCareWithDutySheets.dutySheets[visitInstanceIdx].answers[answerIndex].answer = answer;
                } else {
                    $scope.selectedPlanOfCareWithDutySheets.dutySheets[visitInstanceIdx].answers.push({
                        questionId: parseInt(duty.originalId, 10),
                        answer: ["Done"],
                        type: "COORDINATOR"
                    });
                }
            }
        }

        $scope.updateNotesForTask = (duty, oldNotes, event) => {
            if (typeof duty.notes !== "string") duty.notes = "";
            duty.notes = duty.notes.replace(/(\r\n|\n|\r)/gm,"");
            if (event) {
                if (event.keyCode !== 13) return;
                else return event.target.blur();
            }

            const url = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/duty_sheets/answer"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", duty.visitInstanceId);

            const body = {
                // SERVER WILL SAVE WITH DUTY ORIGINAL ID + 1000 AS ANSWER NOTE
                dutyId: parseInt(duty.originalId, 10),
                notes: duty.notes !== '' ? duty.notes : null
            };

            duty.typeIcon = $scope.dutyAnswerIconTypesMap.COORDINATOR;
            DatabaseApi.post(url, body).then(() => {
                toaster.pop("success", "Task updated successfully");
            }).catch (() => {
                duty.notes = oldNotes;
                toaster.pop("error", "Something went wrong");
            })
        };

        function getWeeklyPatientDutySheets() {
            const fromParam = LocalDate.from(nativeJs($scope.startOfWeekDate.toDate()));

            let url = 'agencies/' + $rootScope.agencyId + "/patients/" + $scope.$ctrl.patientId + '/duty_sheets';
            url = `${url}?startOfWeek=${fromParam}`;

            DatabaseApi.get(url).then(function (res) {
                if (res.data == null) {
                    $scope.emptyDuties = true;
                } else {
                    
                    $scope.patientHasPlanOfCare = res.data.patientHasPlanOfCare;
                    $scope.plansOfCareWithDutySheets = res.data.plansOfCareWithDutySheets;
                    $scope.plansOfCareWithDutySheets.forEach(p => p.versionCounter = 0);
                    
                    if ($scope.plansOfCareWithDutySheets.length === 0) {
                        $scope.emptyDuties = true;
                    }

                    if ($scope.plansOfCareWithDutySheets.length === 1) {
                        setSelectedPlanOfCareWithDutySheets($scope.plansOfCareWithDutySheets[0]);
                    }
                }

                $scope.isLoading = false;
            }, function (err) {
                $scope.isLoading = false;

                if (err.data.error) {
                    return toaster.pop({
                        type: 'warning',
                        title: 'Warning',
                        body: err.data.error,
                        timeout: 5000
                    });
                }

                return toaster.pop('error', 'Something went wrong', 'could not get patient duty sheet');
            });
        }

        function cleanTaskRows() {
            $scope.taskRows = [];
            $scope.caregiversDisplayManagement = {};
            $scope.selectedPlanOfCareWithDutySheets = null;
            $scope.plansOfCareWithDutySheets = [];
            $scope.planOfCare = null;
            $scope.dutySheets = null;
            $scope.emptyDuties = false;
        }

        function setSelectedPlanOfCareWithDutySheets(planOfCareWithDutySheets) {
            planOfCareWithDutySheets.isShown = true;
            $scope.caregiversDisplayManagement = {};

            $scope.selectedPlanOfCareWithDutySheets = planOfCareWithDutySheets;
            $scope.planOfCare = planOfCareWithDutySheets.planOfCare;
            $scope.dutySheets = planOfCareWithDutySheets.dutySheets;
            $scope.emptyDuties = $scope.dutySheets.length === 0;
            initWeekTaskList();
        }

        // will be updated also from the navigation
        function onUpdateDutySheetTable() {
            cleanTaskRows();
            $scope.isLoading = true;
            $scope.endOfWeekDate = $scope.startOfWeekDate.clone().add(6, 'day').endOf('day');
            prepareWeekDaysArray($scope.startOfWeekDate, $scope.endOfWeekDate);
            getWeeklyPatientDutySheets();
        }

        function initWeekTaskList() {
            // 1. create duties map
            let dutiesIdsMap = {};
            $scope.planOfCare.planOfCareItems.filter(item => item.type === "Task").forEach(item => {
                if (!dutiesIdsMap[item.id]) {
                    dutiesIdsMap[item.id] = {
                        title: item.label,
                        section: item.section,
                        code: item.code
                    }
                }
            });

            const pocDocItemsIds = $scope.planOfCare.docItems.map(x => x.id);

            // 2. create the task by days array
            $scope.taskRows = [];
            const emptyDaysArray = [1,2,3,4,5,6,7].map(x => []);

            Object.keys(dutiesIdsMap).forEach((dutyId) => {
                let taskRow = {
                    dutyId: dutyId,
                    title: dutiesIdsMap[dutyId].title,
                    section: dutiesIdsMap[dutyId].section,
                    code: dutiesIdsMap[dutyId].code,
                    days: angular.copy(emptyDaysArray),
                    isOptional: pocDocItemsIds.indexOf(parseInt(dutyId)) === -1
                };

                // 3. for each duty id, iterate days array, filter by day and attach relevant duties
                for (const [idx, day] of $scope.weekHeaders.entries()) {
                    const dayVisits = $scope.dutySheets.filter(v => v.startDate === day.date);

                    for (const caregiverDutySheet of dayVisits) {
                        let pocVersionItem = $scope.planOfCare.docItems.find(item => item.id == dutyId);
                        let dutyItem = pocVersionItem !== undefined ? pocVersionItem : dutiesIdsMap[dutyId];
                        
                        let dayIndexesToInsert = [];
                        // THIS IS BECAUSE DAYS ARRAY IS ACCORDING TO THE PAYROLLSETUP RELEVANT TO THE OFFICE
                        dayIndexesToInsert.push($scope.weekHeaders.findIndex((d) => d.dayOfWeek === day.dayOfWeek));

                        let relevantCaregiverDuty = Object.assign({}, dutyItem);
                        if (pocVersionItem !== undefined) {
                            const frequencyPerWeek = relevantCaregiverDuty.frequencyPerWeek;
                            const everyVisit = !relevantCaregiverDuty.days && !(frequencyPerWeek && relevantCaregiverDuty.visitPerWeek) && relevantCaregiverDuty.isRequired;
                            const hasDays = relevantCaregiverDuty.days && relevantCaregiverDuty.days.includes(day.dayOfWeek);
                            const asNeeded = relevantCaregiverDuty.asNeeded = !relevantCaregiverDuty.isRequired && !(frequencyPerWeek && relevantCaregiverDuty.visitPerWeek) && !relevantCaregiverDuty.days;
                            if (everyVisit || asNeeded || hasDays || frequencyPerWeek) {
                                relevantCaregiverDuty.isAccordingToPOC = true
                            }
                        }

                        let relevantCaregiverDutyAnswer;

                        for (const dayIndexToPush of dayIndexesToInsert) {
                            relevantCaregiverDuty.caregiverId = caregiverDutySheet.caregiverId;
                            const caregiver = $scope.caregivers[relevantCaregiverDuty.caregiverId];
                            relevantCaregiverDuty.caregiverName = caregiver.displayName;
                            relevantCaregiverDuty.dutyUniqueId = dutyId + "-" + idx + "-" + relevantCaregiverDuty.caregiverId;

                            if (caregiver) {
                                relevantCaregiverDuty.caregiverThumbnail = caregiver.photoUrl;
                            }
                            if ($scope.caregiversDisplayManagement[relevantCaregiverDuty.caregiverId] === undefined) {
                                $scope.caregiversDisplayManagement[relevantCaregiverDuty.caregiverId] = {
                                    caregiverName: caregiver.displayName,
                                    photoUrl: caregiver.photoUrl,
                                    isShown: true
                                };
                            }

                            relevantCaregiverDutyAnswer = caregiverDutySheet.answers.find(a => a.questionId == dutyId);
                            const dutyNotes = caregiverDutySheet.answers.find(a => a.questionId == 1000 + parseInt(dutyId, 10));
                            if (dutyNotes !== undefined) {
                                relevantCaregiverDuty.notes = dutyNotes.answer;
                            }
                            if (relevantCaregiverDutyAnswer) {
                                relevantCaregiverDuty.isDone = false;
                                if (typeof relevantCaregiverDutyAnswer.answer === "object" && relevantCaregiverDutyAnswer.answer.includes("Done")) {
                                    relevantCaregiverDuty.isDone = true;
                                }
                                relevantCaregiverDuty.typeIcon = $scope.dutyAnswerIconTypesMap[relevantCaregiverDutyAnswer.type];
                            }
                            relevantCaregiverDuty.visitInstanceId = caregiverDutySheet.visitInstanceId;
                            relevantCaregiverDuty.patientDocumentTypeId = caregiverDutySheet.patientDocumentTypeId;
                            relevantCaregiverDuty.originalId = dutyId;

                            taskRow.days[dayIndexToPush].push(relevantCaregiverDuty);
                        }
                    }
                }
                $scope.taskRows.push(taskRow);
            });

            // 4. enrich headers
            $scope.weekHeaders.forEach(day => {
                if (day.duties.length > 1) {
                    let uniqueCaregivers = new Map();
                    for (let obj of day.duties) {
                        uniqueCaregivers.set(obj.caregiverId, obj);
                    }
                    day.uniqueCaregivers = [...uniqueCaregivers.values()];

                    // check unique caregivers per day
                    if (day.uniqueCaregivers.length === 1) {
                        const caregiver = $scope.caregivers[day.duties[0].caregiverId];
                        if (caregiver) {
                            day.uniqueCaregivers = [caregiver];
                            day.caregiverThumbnail = caregiver.photoUrl;
                        }
                    }
                }
            });
        }

        function prepareWeekDaysArray(startDate, endDate) {
            // prepare currentWeekTitle
            $scope.currentWeekTitle = startDate.format('M.D.Y') + " - " + endDate.format('M.D.Y');

            // set days array
            let diff = endDate.diff(startDate, 'days');
            $scope.daysArray = []
            for (let i = 0; i <= diff; i++) {
                $scope.daysArray.push(moment(startDate).add(i, 'days'))
            }

            // prepare table headers
            $scope.weekHeaders = [];
            $scope.daysArray.forEach(day => {
                $scope.weekHeaders.push({
                    dayOfWeek: day.format('dddd').toUpperCase(),
                    title: day.isSame($scope.currentDay, 'day') ? "Today" : day.format('dd D'),
                    date: day.format('YYYY-MM-DD'),
                    duties: []
                });
            });
        }

        function setEntityWeekFromPayrollSetup() {
            // SET Entity Payroll setup, and relevant start/end of week dates

            const relevantPayrollSetup = $scope.payrollSetups
                .find(ps => ps.isActive === true && ps.officeId === $scope.$ctrl.patientCurrentOfficeId);
            if (relevantPayrollSetup) {
                $scope.selectedPayrollSetup = relevantPayrollSetup;

                const endOfWeekDay = $scope.selectedPayrollSetup.endOfWeekDay;
                const startOfWeekDay = endOfWeekDay === 6 ? 0 : endOfWeekDay + 1;
                $scope.selectedPayrollSetup.startOfWeekDay = startOfWeekDay;
                $scope.startOfWeekDate = $scope.startOfWeekDate.clone()
                    .subtract(1, 'weeks').day(startOfWeekDay).startOf('day');
                onUpdateDutySheetTable();

            } else {
                $scope.startOfWeekDate = moment().startOf('isoWeek');
                onUpdateDutySheetTable();
            }
        }

        $scope.onSelectPlanOfCareWithDutySheets = (planOfCareWithDutySheets) => {
            if ($scope.plansOfCareWithDutySheets.length === 1) {
                return;
            }

            if ($scope.selectedPlanOfCareWithDutySheets && 
                $scope.selectedPlanOfCareWithDutySheets.planOfCare.patientDocumentVersionId === planOfCareWithDutySheets.planOfCare.patientDocumentVersionId) {
                return;
            }

            $scope.plansOfCareWithDutySheets.forEach(p => {
                p.isShown = false;
                p.versionCounter = 0;
            });

            setSelectedPlanOfCareWithDutySheets(planOfCareWithDutySheets);
        };

        $scope.onClickVersionCounter = (planOfCareWithDutySheets) => {
            planOfCareWithDutySheets.versionCounter++;
        };

        $scope.updateDutySheetWeek = (weekDirection) => {
            if (weekDirection === 'previous') {
                $scope.startOfWeekDate = $scope.startOfWeekDate.subtract(7, 'day');
            } else {
                $scope.startOfWeekDate = $scope.startOfWeekDate.add(7, 'day');
            }

            onUpdateDutySheetTable($scope.startOfWeekDate);
        };

        $scope.toggleCaregiverDisplay = (caregiverId) => {
            $scope.caregiversDisplayManagement[caregiverId].isShown = !$scope.caregiversDisplayManagement[caregiverId].isShown;
        };

        $scope.focusDutyNotesInput = (notesElementId) => {
            if ($scope.focusedNotesElementId !== notesElementId) {
                $scope.focusedNotesElementId = notesElementId;
                document.getElementById(notesElementId).focus();
            } else {
                $scope.focusedNotesElementId = undefined;
            }
        };

        $rootScope.$on("patient_poc_updated", function (event) {
            onUpdateDutySheetTable();
        });

        $rootScope.$on("got_caregivers_data", function (event) {
            $scope.caregiversMap = DatabaseApi.caregivers();
        });

        $rootScope.$on("got_payroll_setups", function (event) {
            $scope.payrollSetups = DatabaseApi.payrollSetups();
            setEntityWeekFromPayrollSetup();
        });

        this.$onInit = async function () {
            $scope.currentDay = moment();
            $scope.startOfWeekDate = moment().startOf('isoWeek');
            setEntityWeekFromPayrollSetup();
        }
    }
});