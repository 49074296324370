app.controller('TrainingCenterSettingsCtrl', function ($scope, $rootScope, toaster, onlyUnique, TrainingCenterBundleService) {
    var DEFAULT_LANGUAGE = 'English';

    $scope.isDevelopmentMode = window.appEnv === "development";

    $scope.isMediaLibrarySuperuser = $rootScope.agencyId === 1;
    
    function processBundles(bundles) {
      return bundles.map((bundle) => ({
        ...bundle,
        __certificationsArray: bundle.bundleParams.caregiverCertifications
          .map(({ certifications }) => certifications)
          .flat()
          .filter(onlyUnique),
      }));
    }

    $scope.refreshBundles = function () {
        TrainingCenterBundleService.get()
        .then(({ data }) => $scope.bundles = processBundles(data.bundles))
        .catch(() => toaster.pop("error", "Failed to refresh bundles"));
    };

    $scope.refreshBundles();

    $scope.bundleEnglishTitle = function (bundle) {
        var displayData = bundle.bundleDisplayData.find(function (obj) {
            return obj.language === DEFAULT_LANGUAGE;
        }).data;

        return displayData.title;
    };

    $scope.getBundleThumbnailUrl = function (bundle) {
        var displayData = bundle.bundleDisplayData.find(function (obj) {
            return obj.language === 'English';
        });
        return displayData.data.thumbnailUrl;
    }

    $scope.createNewBundle = () => {
        $scope.isCreatingNewBundle = true;

        TrainingCenterBundleService.createBundleWithDefaults()
            .then(({ data }) => {
                toaster.pop("success", "A new bundle has been successfully created");
                $scope.bundles = data.bundles;
            })
            .catch(() => toaster.pop("error", "Something went wrong"))
            .finally(() => $scope.isCreatingNewBundle = false);
    }
});
