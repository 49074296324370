app.controller('paymentCtrl', function($scope, $rootScope, $state, NgTableParams, $http, stripe, $timeout, DatabaseApi, toaster){
  
  Stripe.setPublishableKey('pk_live_dBuGzg1Hz7h4X1qvZ25CDIrM');
  //Stripe.setPublishableKey('pk_test_hOITfF65bEyJ1GLl7TmjblHy'); // test

  $scope.pricingInfo = {};
  $scope.billingMethod = {val: 'card'};

  window.scrollTo(0,0);
  var discount = 1;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  //$scope.couponText = 'Coupon redeemed! First 3 months at $500 per month, than $770 per month.';
  $scope.sending = false;
  $scope.sent = false;
  
  function init(){
    $scope.form = {
      email: $rootScope.user.email,
      plan: 'coupon'
    };
    $scope.name = '';
    $scope.number = '';
    //$scope.expiry = '';
    $scope.cvc = '';
  }
  init();
  
  function getPrice(num, price){
    
    if(price) {
      if(num) return price;
      else return numberWithCommas(price);
    }
    else if($scope.form.plan === 'yearly') {
      if(num) return 14400;
      else return '14,400';
      
    } else if($scope.form.plan === 'quarterly') {
      if(num) return 4050;
      else return '4,050';
    
    } else if($scope.form.plan === 'monthly') {
      if(num) return 1500;
      else return '1,500';
    }
                                       
  }
  
  $scope.applyCoupon = function(){
    $scope.sendingCoupon = true;
    $http.get('https://medflytserver-stg.herokuapp.com/coupons/' + $scope.coupon).then(function(response){
    //$http.get('http://localhost:3002/coupons/' + $scope.coupon).then(function(response){

      var coupon = response.data;
      $scope.couponText = coupon.couponText;
      $scope.couponPrice = coupon.price;
      $scope.couponCode = coupon.coupon;
      $scope.plan = coupon.plan;
      
    }, function(response){
      $scope.sendingCoupon = false;
    });
  };
  
  
  $scope.startSendind = function(){
    if(!$scope.sent) $scope.sending = true;
  };
  
  $scope.handleStripe = function(status, response){
    
    if($scope.sent) return;
    
    if(response.error) {
      $scope.sending = false;
      
    } else {
      
      $scope.form.address = $scope.form.address.formatted_address;
      var data = { 
        stripeToken : response.id,
        //price: getPrice(true, $scope.couponPrice),
        //email: $rootScope.user.email,
        form: $scope.form,
        coupon: $scope.couponCode,
        plan: $scope.plan ? $scope.plan : $scope.form.plan
      };

      $http.post('https://medflytserver-stg.herokuapp.com/charge', data ).then(function(response){
      //$http.post('http://localhost:3002/charge', data ).then(function(response){

         console.log(response);
        $scope.sending = false;
        $scope.sent = true;
        $scope.paymentForm.$setPristine();
        init();
      }, function(response){
        $scope.sending = false;
        //$scope.couponText = false;
        //$scope.couponPrice = false;
        //$scope.couponCode = false;
        //$scope.plan = false;

         console.log(response);
      });
      
    }
  };
  
  
  $scope.change = function(){
    console.log($scope.paymentForm);
    console.log($scope.form);
  };
    
  $scope.getPrice = function(){
    var monthly = $scope.pricingInfo.monthly || 0;
    var discount = $scope.pricingInfo.discount || 0;
    var setup = $scope.pricingInfo.setup || 0;
    return '$' + (monthly - discount) + ' + ' + '$' + setup + ' setup fee';
  };


  $scope.lockPricing = function(){
    $scope.pricingInfo.locking = true;
    var text = 'Payment plan - ';

    if($scope.pricingInfo.months) {
      text += '$' + ($scope.pricingInfo.monthly - $scope.pricingInfo.discount)  +
          '/month for the first ';
      text += $scope.pricingInfo.months > 1 ? $scope.pricingInfo.months + ' months' : 'month';
      text += ', than ';

    }
    text += '$' + $scope.pricingInfo.monthly + '/month.';

    if($scope.pricingInfo.setup){
      text += ' Setup fee: $' + $scope.pricingInfo.setup + '.';
    }


    $timeout(function(){
        $scope.pricingInfo.text = text;
        $scope.pricingInfo.locking = false;
    }, 1500);

  };


  $scope.startedPlaid = false;

  $scope.openPlaidLink = function(){

      if ($scope.startedPlaid) { 
        return;
      }

      $scope.startedPlaid = true;

      var linkHandler = Plaid.create({
          env: 'development', // 'production', 'sandbox', 'development'
          clientName: 'Medflyt',
          key: '6695268c3fc755a85309ee889151c3',
          product: ['auth'],
          selectAccount: true,
          onSuccess: function(public_token, metadata) {

              var body = {
                publicToken: public_token,
                accountId: metadata.account_id,
                metaData: {
                    email: $rootScope.user.email,
                    name: $rootScope.user.displayName,
                    agencyName: $rootScope.agencyName
                }
              };
              DatabaseApi.post('admin/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/add-ach-account', body).then(function (res) {
                $scope.startedPlaid = false;
                if(res.data.success) {
                    toaster.pop('success', 'Success', 'Account added');
                } else {
                    toaster.pop('error', 'Something went wrong', res.data.message);
                }
              }, function (err){
                  toaster.pop('error', 'Something went wrong', '');
              });
          },
          onExit: function(err, metadata) {
              $scope.startedPlaid = false;
              // The user exited the Link flow.
              if (err != null) {
                  // The user encountered a Plaid API error prior to exiting.
                  console.log(err);
              } else {
                console.log(metadata);
              }
          },
      });

      linkHandler.open();

  };


    /*
    var handler = StripeCheckout.configure({
      key: 'pk_live_dBuGzg1Hz7h4X1qvZ25CDIrM',
      image: 'http://app.medflyt.com/admin/images/logo.svg',
      locale: 'auto',
      token: function(token) {
        console.log(token);

        var data = {
          stripeToken : token.id,
          price: 80000,
          email: $rootScope.user.email
        };
        $http.post('https://medflytserver-stg.herokuapp.com/charge', data ).then(function(response){
             console.log(response);
          }, function(response){
             console.log(response);
          });

      }
    });



    var buttonData = {
        name: 'Medflyt LLC',
        description: 'Medflyt Platform',
        zipCode: true
    };

    document.getElementById('customStripeMonth').addEventListener('click', function(e) {
      $http.get('https://medflytserver-stg.herokuapp.com/coupons/' + $scope.coupon).then(function(response){
        discount = 1 - response.data;
        buttonData.amount = 150000 * discount;
        popup();
      }, function(response){
        buttonData.amount = 150000;
        popup();
    });

    });


    document.getElementById('customStripeQuarter').addEventListener('click', function(e) {

      $http.get('https://medflytserver-stg.herokuapp.com/coupons/' + $scope.coupon).then(function(response){
        discount = 1 - response.data;
        buttonData.amount = 135000 * 3 * discount;
       popup();
      }, function(response){
        buttonData.amount = 135000 * 3;
        popup();
      });
    });

    document.getElementById('customStripeYear').addEventListener('click', function(e) {

      $http.get('https://medflytserver-stg.herokuapp.com/coupons/' + $scope.coupon).then(function(response){
        discount = 1 - response.data;
        buttonData.amount = 120000 * 12 * discount;
        popup();
      }, function(response){
        buttonData.amount = 120000 * 12;
        popup();
      });

    });


    function popup(price){
      handler.open(buttonData);
      e.preventDefault();
    }
    // Close Checkout on page navigation:
    window.addEventListener('popstate', function() {
      handler.close();
    });
    */
  
  
  //
  //
});