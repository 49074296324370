"use strict";

angular
  .module("dashboard")
  .controller(
    "planOfCareTemplateModalCtrl",
    function ($scope, $rootScope, $uibModalInstance, plan, DatabaseApi, toaster, apiErrors) {
      $scope.plan = plan;

      $scope.form = {
        showPatientSignature: $scope.plan.showPatientSignature,
      };

      $scope.patchPlanOfCareType = () => {
        const endpoint = "agencies/:agencyId/agency_members/:agencyMemberId/plan_of_care_type/:planOfCareTypeId"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":planOfCareTypeId", $scope.plan.planOfCareTypeId);

        const body = {
          showPatientSignature: $scope.form.showPatientSignature,
        };

        DatabaseApi.patch(endpoint, body)
          .then(({ data }) => {
            $scope.$resolve.onPlanOfCarePatched?.(data);
            toaster.pop("success", "Success", "Plan of care settings updated");
          })
          .catch((error) => {
            console.error(error);
            toaster.pop(
              "error",
              "Something went wrong",
              apiErrors.format(error, "Failed to update plan of care settings")
            );
          });
      };

      $scope.closeModal = function () {
        $uibModalInstance.close("ok");
      };

      $scope.exit = function () {
        $uibModalInstance.dismiss();
      };
    }
  );
