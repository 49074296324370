'use strict';
app.directive("caregiverPayRates", ["NgTableParams", "$uibModal", "toaster", "DatabaseApi", "$rootScope", "rangesCalculateService", function (NgTableParams, $uibModal, toaster, DatabaseApi, $rootScope, rangesCalculateService) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/caregiver-pay-rates.html',
        scope: {
            caregiverId: '=',
            certifications: '='
        },
        link: function (scope) {
            scope.openCaregiverPayRateModal = (caregiverPayRateInitValues) => {
                const modalInstance = $uibModal.open({
                    templateUrl: 'admin/views/caregiver-pay-rate-modal.html',
                    size: 'lg',
                    controller: 'caregiverPayRateModalCtrl',
                    resolve: {
                        caregiverId: () => scope.caregiverId,
                        certifications: () => scope.certifications,
                        caregiverPayRateInitValues: () => caregiverPayRateInitValues
                    }
                });

                modalInstance.result.then((res) => {
                    if (res === 'success') {
                        fetchData();
                    }
                });
            }
            scope.getTypeText = (type) => {
                switch (type) {
                    case ('FIXED_AMOUNT'):
                        return "Fixed rate"
                    case ('FIXED_DIFF'):
                        return "Fixed rate raise (+)"
                    case ('PERCENTAGE'):
                        return "Percentage rate raise (%)"
                }
            }

            scope.openPatientModal = $rootScope.openPatientModal;

            scope.getPatientName = (patientId) => {
                if (!patientId) return '';
                if (scope.patients[patientId].displayId) {
                    return `${scope.patients[patientId].displayName} (${scope.patients[patientId].displayId})`;
                }
                return scope.patients[patientId].displayName;
            };

            scope.deletePayRate = (id) => {
                DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/caregivers/' + scope.caregiverId + '/pay_rates/' + id).then(function (res) {
                    fetchData();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not delete caregiver pay rate");
                })
            }

            const fetchData = () => {
                scope.patients = DatabaseApi.patients();
                DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/caregivers/' + scope.caregiverId + '/pay_rates').then(function (res) {
                    let payCodes = new Map();
                    res.data.caregiverPayRates.forEach(caregiverPayRate => {
                        caregiverPayRate.payCodeName = DatabaseApi.payrollCodes().find(payCode => caregiverPayRate.payCodeId === payCode.id).displayId;
                        payCodes.set(caregiverPayRate.payCodeId, caregiverPayRate.payCodeName);
                    })
                    
                    payCodes = [...payCodes].map(([id, name]) => ({ id, name }));
                    let rangesDisplay = [];
                    payCodes.forEach(payCode => {
                        const payCodePayRates = res.data.caregiverPayRates.filter(payRate => payRate.payCodeId === payCode.id);
                        const uniquePatients = new Set(payCodePayRates.map(payRate => payRate.patientId));
                        
                        uniquePatients.forEach(patientId => {
                            rangesDisplay = [...rangesDisplay, 
                                            ...rangesCalculateService.calculateRanges(payCodePayRates.filter(payRate => payRate.patientId === patientId))];
                        });
                    });
                    
                    initCaregiverPayRatesTable(rangesDisplay);
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not get caregiver pay rates");
                })
            }
            const initCaregiverPayRatesTable = (data) => {
                if (!data) return;
                var options = {
                    count: 10,
                    sorting: { startDate: "desc" }
                };

                scope.caregiverPayRatesTable = new NgTableParams(options, {
                    counts: [],
                    dataset: data
                });
            }

            fetchData();
        }
    };
}]);