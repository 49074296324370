app.directive("spinner", function () {
  return {
    restrict: 'E',
    transclude: true,
    templateUrl: 'admin/views/spinner.html',
    scope: {
      isLoading: '=',
      hasData: '@',
      loadingMessage: '@',
      noDataMessage: '@'
    },
    link: function (scope) {
      if (!scope.loadingMessage) {
        scope.loadingMessage = "Loading Data";
      }

      if (!scope.noDataMessage) {
        scope.noDataMessage = "No data to show";
      }
    }
  };
});
