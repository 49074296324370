app.controller('buyCaregiverModalCtrl', function($scope, $rootScope, $state, $uibModalInstance, caregiver, DatabaseApi, toaster){

    $scope.caregiver = caregiver;
    $scope.working = false;

    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    console.log(caregiver);

    $scope.approve = function(){
        var url = 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/recruitment/select_caregiver/' + caregiver.id;
        DatabaseApi.post(url).then(function(res){
            toaster.pop('success', "Success", 'Caregiver profile is available');

            $scope.working = true;
            $uibModalInstance.close('ok');

        }, function(err){
            $scope.working = false;
            toaster.pop('error', "Something went wrong", 'Please try again' );
        });
    }

});