app.controller('newVisitSuggestedCaregiversModalCtrl', function ($scope, $uibModalInstance, NgTableParams, toaster) {
    $scope.suggestedCaregiversGlobalFilter = { val: "" };

    const init = () => {
        initSuggestedCaregiversTable();
    }

    const initSuggestedCaregiversTable = () => {
        const items = $scope.$resolve.caregivers;
        let options = {
            count: 10
        };

        $scope.suggestedCaregiversTable = new NgTableParams(options, {
            dataset: items
        });
    }

    $scope.applysuggestedCaregiversGlobalSearch = () => {
        const filter = { $: $scope.suggestedCaregiversGlobalFilter.val };
        if ($scope.suggestedCaregiversTable) {
            angular.extend($scope.suggestedCaregiversTable.filter(), filter);
        }
    };

    $scope.handleSelect = (selectedCaregiver) => {
        if (!selectedCaregiver || !selectedCaregiver.id) {
            return toaster.pop("error", "Something went wrnog", "Chosen caregiver is invalid");
        }
        $scope.$resolve.handleCaregiverSelect(selectedCaregiver);
        $scope.closeModal();
    }

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };

    init();
});
