app.controller('imageViewerModalCtrl', function ($scope, $http, $uibModalInstance, toaster, DatabaseApi, $sce, generalUtils) {
    $scope.isLoading = true;

    if ($scope.document.imageUrl) {
        $scope.isLoading = false;
    } else {
        DatabaseApi.get($scope.url, {}).then(function (res) {
                $scope.document.imageUrl = res.data.fileUrl;
                $scope.document.fileExtension = generalUtils.getUrlExtension($scope.document.imageUrl);
                if ($scope.document.fileExtension === 'pdf') {
                    $http.get($scope.document.imageUrl,
                        { responseType: 'arraybuffer' })
                        .then(function (response) {
                            var file = new Blob([response.data], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            $scope.document.content = $sce.trustAsResourceUrl(fileURL);
                            $scope.isLoading = false;
                        });
                } else {
                    $scope.isLoading = false;
                }
            }, function (err) {
                toaster.pop('error', "Failed to present document");
                $scope.isLoading = false;
                $scope.closeModal();
            });
    }

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };
});