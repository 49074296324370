"use strict";
app.controller('savedReportPermissionsModalCtrl', function ($scope, reportService, $uibModalInstance) {
    $scope.loadRolesAccess = () => {
        return reportService.getSavedReportRolesAccess($scope.savedReport.id);
    }

    $scope.handleAddAccess = (rolesIds, accessType) => {
        return reportService.addSavedReportRolesAccess($scope.savedReport.id, {rolesIds, accessType});
    }

    $scope.handleEditAccess = (roleId, accessType) => {
        return reportService.editSavedReportRoleAccess($scope.savedReport.id, {roleId, accessType});
    }

    $scope.handleRemoveAccess = (roleId) => {
        return reportService.deleteSavedReportRoleAccess($scope.savedReport.id, roleId);
    }

    $scope.closeModal = () => {
        $uibModalInstance.dismiss();
    };
});