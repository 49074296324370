app.controller('flyersCtrl', function ($scope, $rootScope, DatabaseApi, toaster) {
    $scope.isLoading = false;
    $scope.isExampleShown = false;
    $scope.currentImage = '';

    $scope.downloadDocument = (flyerType, flyerLang) => {
        $scope.isLoading = true;
        DatabaseApi.get(
            `agencies/${$rootScope.agencyId}/flyers/${flyerType}/lang/${flyerLang}`
        )
            .then(res => {                
                const downloadLink = document.createElement("a");
                downloadLink.setAttribute("target", "blank");
                downloadLink.href = res.data.url;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }).catch(() => {
                toaster.pop('error', 'Downloading Failed', "Couldn't find the requested file");
            }).finally(() => {
                $scope.isLoading = false;
            });
    };

    $scope.setCurrentImage = (imageName) => {
        $scope.currentImage = imageName;
        $scope.isExampleShown = true;
    }

    $scope.closeExampleShown = () => {
        $scope.isExampleShown = false;
    }
});