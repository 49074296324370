'use strict'
app.controller("eTimeSheetApprovalCtrl", function (
  $rootScope,
  $scope,
  NgTableParams,
  DatabaseApi,
  generalUtils,
  selectionLogic,
  toaster,
  itemSearchPageManager,
  officesService,
  Storage,
  $filter,
  FilterUtils,
  mfModal,
  timesheetsService,
) {
  const initialize = () => {
    initOffices();
    initCoordinators();
    initStatuses();
    initAssets();
    if (!$scope.initializeMap['patients'] || !$scope.initializeMap['caregivers']) {
      return;
    }
    
    $scope.filters = {
      offices: [],
      coordinators: [],
      teams: [],
      statuses: [{ id: "PENDING" }],
    };

    initTableColumns();
    initPageManager();

    // Load filters
    let storageFilters;
    storageFilters = FilterUtils.rootFilters.eTimeSheetApprovalCtrl;

    if (storageFilters !== undefined) {
      $scope.pageManager.setSearchParams(storageFilters);
    }

    $scope.loadItems();
  };

  const initAssets = () => {
    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.patientsMap = DatabaseApi.patients() || {};
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
  };

  $scope.$on("$destroy", () => {
    const filters = $scope.pageManager.searchParams;
    FilterUtils.rootFilters.eTimeSheetApprovalCtrl = angular.copy(filters);
  });

  const initTableColumns = () => {
    const columns = Storage.getObject("timesheetApprovalCallsTableSettings");
    if (columns && Object.keys(columns).length) {
      $scope.tableColumns = columns;

      if ($scope.tableColumns["Status"] === undefined) {
        $scope.tableColumns["Status"] = true
      }
    } else {
      $scope.tableColumns = {
        "ID": false,
        "Patient": true,
        "Caregiver": true,
        "Visit Date": true,
        "Schedule Time": true,
        "Clock Time": true,
        "Submitted Time": true,
        "Duties": true,
        "Caregiver Signature": true,
        "Patient Signature": true,
        "Status": true,
      };
    }

    $scope.$watch("tableColumns", () => {
        if ($scope.tableColumns) {
          Storage.setObject("timesheetApprovalCallsTableSettings", $scope.tableColumns);
        }
      },
      true
    );
  };

  const initOffices = () => {
    if (Array.isArray($scope.offices) && $scope.offices.length > 0) {
      return;
    }
    if (!officesService.offices) {
      officesService.getOffices().then((offices) => {
        $scope.offices = offices;
      });
    } else {
      $scope.offices = officesService.offices
    }
  };

  const initCoordinators = async () => {
    if (Array.isArray($scope.coordinators) && $scope.coordinators.length > 0) {
      return;
    }
    $scope.coordinatorsDropdownOptions = {
      enableSearch: true,
      displayProp: "displayName",
      styleActive: true,
      scrollable: true,
    };

    await DatabaseApi.loadAgencyMembers();
    const agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole();
    $scope.coordinators = [
      ...agencyMembersByRoles.coordinatorsSeniors,
      ...agencyMembersByRoles.coordinators,
      ...agencyMembersByRoles.admins,
    ].filter(coordinator => coordinator.hasCasesNotDischarged).sort(generalUtils.sortByDisplayName);
  };

  const initStatuses = () => {
    if (Array.isArray($scope.timesheetStatuses) && $scope.timesheetStatuses.length > 0) {
      return;
    }
    $scope.timesheetStatuses = [
      { id: "PENDING", label: "Pending", actionLabel: null, statusClass: "orange", btnClass: 'warning', singleActionCallback: null, bulkActionCallback: null },
      { id: "APPROVED", label: "Approved", actionLabel: "Approve", statusClass: "green", btnClass: 'primary', singleActionCallback: approveSingleTimesheet, bulkActionCallback: approveSelectedTimesheets },
      { id: "DECLINED", label: "Declined", actionLabel: "Decline", statusClass: "red", btnClass: 'danger', singleActionCallback: declineSingleTimesheet, bulkActionCallback: declineSelectedTimesheets },
    ];
    $scope.timesheetStatusesMap = {};
    $scope.timesheetStatuses.forEach(status => {
      $scope.timesheetStatusesMap[status.id] = status;
    });

    $scope.statusesExtraSettings = {
      styleActive: true,
      smartButtonMaxItems: 3,
    };
  };

  const initSelection = (items) => {
    $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
      $scope.selectionLogic.addItemToCollection(item);
    }, "timesheetId");

    if (items) {
      items.forEach((item) => {
        $scope.selectionLogic.initItem(item);
      });
    }
  };

  const initPageManager = () => {
    $scope.pageManager = itemSearchPageManager.createSearchPageManager("/time_sheet_approval" );

    $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
    $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
    $scope.teamDataManager = $scope.pageManager.getTeamDataManager();

    $scope.pageManager.initFromToDateParams();
    $scope.pageManager.initOfficeParam();
    $scope.pageManager.initTeamParam();
    $scope.pageManager.initCoordinatorParam();
    $scope.pageManager.initSearchParam("selectedStatuses", $scope.filters.statuses, {
      queryName: "statuses",
      toQueryConverter: (statuses) => $filter("pipeItems")(statuses, "id"),
      placeholderValue: [],
    });
    $scope.pageManager.updateSearchParamValue("from", new Date(JSJoda.LocalDate.now().minusDays(1).format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    $scope.pageManager.updateSearchParamValue("to", new Date(JSJoda.LocalDate.now().format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    $scope.pageManager.initSearchParam("dateByTimesheet", true, {
      placeholderValue: true,
      queryName: "dateByTimesheet",
    });

    $scope.initialQueryRecords = [];
    $scope.filteredQueryRecords = [];
  };

  const populateTable = (items) => {
    const oldTotal = $scope.tableParams?.total?.() || 0;
    const defaultSorting = { createdAt: "desc" };
    const sorting = $scope.tableParams
      ? $scope.tableParams.sorting()
      : defaultSorting;
    const page = $scope.tableParams ? $scope.tableParams.page() : null;
    const options = {
      count: 25,
      sorting,
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
    if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    if ($scope.globalFilter) {
      $scope.applyGlobalSearch($scope.globalFilter.val);
    }
    initSelection(items);
  }

  $scope.loadItems = () => {
    $scope.pageManager
      .executeSearch()
      .then(onSuccessLoadItems)
      .catch(onErrorLoadItems);
  };

  const approveSingleTimesheet = (row) => {
    sendApproveRequest([{
      timesheetId: row.timesheetId,
      caregiverId: row.caregiver.id,
      patientId: row.patient.id,
      visitInstanceId: row.visitInstanceId
    }]);
  };

  const declineSingleTimesheet = (row) => {
    sendDeclineRequest([{
      timesheetId: row.timesheetId,
      caregiverId: row.caregiver.id,
      patientId: row.patient.id,
      visitInstanceId: row.visitInstanceId
    }]);
  };

  const getSelectedTimesheetsForAction = () => {
    const selectedItems = $scope.selectionLogic.getSelectedItems();
    if (selectedItems.length === 0) {
      return { errorMessage: "Please select items" };
    }
    if (selectedItems.find(item => item.status !== "PENDING")) {
      return { errorMessage: "Please select only pending items" };
    }
    return selectedItems.map((item) => ({
      timesheetId: item.timesheetId,
      caregiverId: item.caregiver.id,
      patientId: item.patient.id,
      visitInstanceId: item.visitInstanceId
    }));
  };
  
  const approveSelectedTimesheets = () => {
    const timesheets = getSelectedTimesheetsForAction();
    if (timesheets.errorMessage) {
      return mfModal.createSimple({
        variant: "danger",
        subject: "Error",
        message: timesheets.errorMessage
      });
    }
    sendApproveRequest(timesheets);
  };

  const declineSelectedTimesheets = () => {
    const timesheets = getSelectedTimesheetsForAction();
    if (timesheets.errorMessage) {
      return mfModal.createSimple({
        variant: "danger",
        subject: "Error",
        message: timesheets.errorMessage
      });
    }
    sendDeclineRequest(timesheets);
  };

  $scope.exportTable = () => {
    $scope.loadingCSV = true;
    const rows = [];
    const titles = [
      'ID',
      'Patient',
      'Patient Display ID',
      'Patient Office',
      'Caregiver',
      'Caregiver Display ID',
      'Visit Date',
      'Submitted Time',
      'Schedule Time',
      'Clock Time',
      'Duties',
      'Status',
    ];

    rows.push(titles);
    $scope.tableParams.data.forEach(dataRow => {
      const csvRow = [];
   
      titles.forEach((title) => {
        if (title === 'ID') csvRow.push(dataRow.timesheetId || '');
        else if (title === 'Patient') csvRow.push(dataRow.patient.fullName || '');
        else if (title === 'Patient Display ID') csvRow.push(dataRow.patient.displayId || '');
        else if (title === 'Patient Office') csvRow.push(dataRow.patient.currentOfficeName || '');
        else if (title === 'Caregiver') csvRow.push(dataRow.caregiver.fullName || '');
        else if (title === 'Caregiver Display ID') csvRow.push(dataRow.caregiver.displayId || '');
        else if (title === 'Visit Date') csvRow.push($filter("mfShortDate")(dataRow.visitDate) || '');
        else if (title === 'Submitted Time') csvRow.push($filter("mfShortTime")(dataRow.submittedAt, ['withDate']) || '');
        else if (title === 'Schedule Time') csvRow.push($filter("mfShortTime")(dataRow.ScheduleStartTime) + "-" + $filter("mfShortTime")(dataRow.ScheduleEndTime) || '');
        else if (title === 'Clock Time') csvRow.push($filter("mfShortTime")(dataRow.clockInTime) + "-" + $filter("mfShortTime")(dataRow.clockOutTime) || '');
        else if (title === 'Duties') csvRow.push(dataRow.selectedDuties.length || '');
        else if (title === 'Status') csvRow.push(dataRow.status || '');
      });

      rows.push(csvRow);
    });

    let csvContent = "";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const universalBOM = "\uFEFF";
    const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", getExportedFileName());
    document.body.appendChild(link);

    link.click();
    $scope.loadingCSV = false;
  };

  const getExportedFileName = () => {
    const fromDate = $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd");
    const toDate = $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd");
    return `timesheet-approval-${fromDate}-to-${toDate}.csv`;
  };

  const sendApproveRequest = (timesheets) => {
    timesheetsService.editTimesheetsStatus({
      timesheets: timesheets,
      onSuccess: (res) => onSuccessAction(res, "approved"),
      onCatch: (err) => onFailAction(err, "approve"),
      onFinally: () => $scope.isProcessing = false
    }, "APPROVED");
  };

  const sendDeclineRequest = (timesheets) => {
    timesheetsService.editTimesheetsStatus({
      timesheets: timesheets,
      onSuccess: (res) => onSuccessAction(res, "declined"),
      onCatch: (err) => onFailAction(err, "decline"),
      onFinally: () => $scope.isProcessing = false
    }, "DECLINED");
  };

  const onSuccessLoadItems = (response) => {
    $scope.initialQueryRecords = response.data.timeSheets.map(parseRecord.bind(this));

    populateTable($scope.initialQueryRecords);
    $scope.onFiltersChange();
  };

  const onErrorLoadItems = (error) => {
    toaster.pop("error", "Failed to load time sheets");

    throw error;
  };

  const onSuccessAction = (response, strActed) => {
    toaster.pop("success", "Success", `Successfully ${strActed}`);
    $scope.loadItems();
  };

  const onFailAction = (error, strAction) => {
    toaster.pop("error", "Something went wrong", `Could not ${strAction} timesheet(s)`);
  };

  const parseRecord = (record) => {
    const caregiver = DatabaseApi.getCaregiverById(record.caregiverId);
    if (caregiver) {
      record.caregiver = angular.copy(caregiver);
      record.caregiver.id = record.caregiverId;
      record.caregiver.fullName = getFullName(caregiver);
      record.caregiver.displayId = caregiver.displayId;
      record.caregiver.address = caregiver.address;
      record.caregiver.certifications = caregiver.certifications;
    }

    const patient = DatabaseApi.getPatientById(record.patientId);
    if (patient) {
        record.patient = angular.copy(patient);
        record.patient.id = record.patientId;
        record.patient.fullName = getFullName(patient);
        record.patient.displayId = patient.displayId;
        record.patient.address = patient.address ? patient.address.text : null;

        const patientCurrentOffice = $scope.offices.find(office => office.id === patient.currentOfficeId);
        if (patientCurrentOffice) {
            record.patient.currentOfficeName = patientCurrentOffice.name;
        }
    }

    let div = '<ul class="tooltip-duties">';
    record.selectedDuties.forEach((duty) => {
        div += `<li>${duty.label} (${duty.code})</li>`
    });
    div += "</ul> ";

    record.duties = record.selectedDuties.length > 0 ? div : "";
    
    return record;
  };

  const getFullName = ({ firstName, middleName, lastName }) => {
    return [firstName, middleName, lastName].filter(namePart => namePart !== null).join(' ');
  };

  $scope.onFiltersChange = () => {
    $scope.filteredQueryRecords = Array.from($scope.initialQueryRecords);
    
    $scope.pageManager.updateSearchParamValue("selectedOffices", $scope.filters.offices);
    $scope.pageManager.updateSearchParamValue("selectedTeams", $scope.filters.teams);
    $scope.pageManager.updateSearchParamValue("selectedCoordinators", $scope.filters.coordinators);
    $scope.pageManager.updateSearchParamValue("selectedStatuses", $scope.filters.statuses);

    // Offices
    if ($scope.filters.offices.length) {
      const selectedOfficeIds = $scope.filters.offices.map(
        (office) => office.id
      );
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          let relevantOfficeIds = [];

          const caregiver = DatabaseApi.getCaregiverById(record.caregiver.id);
          if (caregiver) {
            relevantOfficeIds.push(...caregiver.officeIds);
          }

          const patient = DatabaseApi.getPatientById(record.patient.id);
          if (patient) {
            relevantOfficeIds.push(patient.currentOfficeId);
          }

          return relevantOfficeIds.some(relevantOfficeId => selectedOfficeIds.includes(relevantOfficeId));
        }
      );
    }

    // Coordinators
    if ($scope.filters.coordinators.length) {
      const selectedCoordinatorIds = $scope.filters.coordinators.map(
        (coordinator) => coordinator.id
      );
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          const patientIds = (() => {
            if (record.patient.id) {
                return [record.patient.id]
            }

            return record.potentialLinks.map(x => x.patient.id)
          })();

          const patients = patientIds.map(DatabaseApi.getPatientById);

          return patients.some(patient => patient.assignedCoordinator && selectedCoordinatorIds.includes(patient.assignedCoordinator));
        }
      );
    }

    // Teams
    if ($scope.filters.teams.length) {
      const selectedTeamIds = $scope.filters.teams.map((team) => team.id);
      $scope.filteredQueryRecords = $scope.filteredQueryRecords.filter(
        (record) => {
          if (!record.patient.id) {
            return false;
          }
          const patient = DatabaseApi.getPatientById(record.patient.id);
          return selectedTeamIds.indexOf(patient.agencyTeamId) !== -1
        }
      );
    }

    populateTable($scope.filteredQueryRecords);
  };

  $scope.multiSelectEvents = {
    onSelectionChanged() {
      $scope.onFiltersChange();
    }
  };

  $scope.applyGlobalSearch = async () => {
    const filter = { $: $scope.globalFilter.val };
    if ($scope.tableParams) {
      await angular.extend($scope.tableParams.filter(), filter);
      $scope.selectionLogic.initItemsCollection($scope.tableParams.data)
    }
  };

  $scope.getCountSelected = () => {
    const selected = $scope.selectionLogic?.countSelected?.();
    if (!selected) {
      return "";
    }
    return ` (${selected} selected)`;
  };

  $rootScope.$on("refresh_timesheets", $scope.loadItems);
  $rootScope.$on("got_caregivers_data", initialize);
  $rootScope.$on("got_patients_data", initialize);

  initialize();
});

