angular
  .module("dashboard")
  .controller(
    "newPhonenumberModalCtrl",
    function ($scope, $rootScope, DatabaseApi) {
      const types = [
        { id: "TEL", label: "Home" },
        { id: "MOBILE", label: "Mobile" },
        { id: "OTHER", label: "Other" },
      ];

      const form = {
        fields: {
          type: {},
          phonenumber: undefined,
          label: undefined,
          description: undefined,
        },
        valid: false,
        loading: false,
      };

      function denormalizePhonenumber(value) {
        return `+1${value.substr(value.length - 10, 10)}`;
      }

      function submit() {
        form.loading = true;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/phonenumbers`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patient.id);

        const data = {
          type: form.fields.type.id,
          label: form.fields.label || null,
          description: form.fields.description || null,
          phonenumber: denormalizePhonenumber(form.fields.phonenumber),
        };

        return DatabaseApi.post(url, data)
          .then(({ data }) => $scope.$resolve.onSuccess(data.phoneNumber))
          .catch((e) => $scope.$resolve.onFail(e))
          .finally(() => (form.loading = false));
      }

      $scope.$watchGroup(
        [
          "form.fields.type.id",
          "form.fields.phonenumber",
          "form.fields.label",
          "form.fields.description",
        ],
        () => {
          const isTypeValid = form.fields.type.id !== undefined;
          const isPhonenumberValid = form.fields.phonenumber !== undefined;
          const isLabelValid =
            form.fields.type.id !== "OTHER" ||
            (form.fields.type.id === "OTHER" && form.fields.label);
          const isDescriptionValid = form.fields.description !== undefined;

          form.valid = isTypeValid && isPhonenumberValid && isLabelValid && isDescriptionValid;
        }
      );

      $scope.submit = submit;
      $scope.types = types;
      $scope.form = form;
    }
  );
