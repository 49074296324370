
app.controller('liveEventModalCtrl', function ($scope, $http, $uibModalInstance, $interval, DatabaseApi, $sce, generalUtils) {

    $scope.isCameraActive = false;
    $scope.maxLearners = 10;
    $scope.learners = 0;
    let learnerInterval;
    let intervalDuration = 1;


    const countDownDate = moment().add(24, 'minutes');

    const updateCountdown = () => {
        let diff = countDownDate.diff(moment());

        if (diff <= 0) {
            $interval.cancel(countDownInterval);
            // If the count down is finished, write some text 
            $scope.toggleCamera();
        } else
            $scope.countDownText = moment.utc(diff).format("HH:mm:ss");
    }
    let countDownInterval = $interval(updateCountdown, 1000);



    const increaseLearners = () => {
        if ($scope.learners === $scope.maxLearners) {
            $interval.cancel(learnerInterval);
        }
        $scope.learners += 1;
        $interval.cancel(learnerInterval);
        intervalDuration += 1;
        learnerInterval = $interval(increaseLearners, intervalDuration * 1000);
    }

    $scope.toggleCamera = () => {
        $scope.isCameraActive = !$scope.isCameraActive;

        if ($scope.isCameraActive) {
            // activate learners interval
            learnerInterval = $interval(increaseLearners, intervalDuration * 1000);
        } else {
            $scope.learners = 0;
        }
    }

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };
});