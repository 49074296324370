app.controller('tutorialCtrl', function($scope, $rootScope, $state){
  
  window.scrollTo(0,0);    
      
  $scope.goToDash = function(){ 
    $state.go('app.dashboard');
  };
    
  //
  //
});