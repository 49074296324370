app.controller('recruitmentCtrl', function($scope, $rootScope, DatabaseApi, $uibModal, toaster, $filter, $window, $sce){

    console.log('r');

    $scope.limitSize = 10;
    $scope.offset = 0;

    $scope.pageOverrideNumber = $scope.offset === 0 ? 1 : $scope.offset / $scope.limitSize + 1;

    $scope.isMedflyt = $rootScope.agencyId === 7;

    $scope.statusType = $scope.isMedflyt ? 'new' : 'exposed';

    $scope.switchStatusType = function(statusType){
        $scope.statusType = statusType;
        $scope.offset = 0;
        reloadData();
    };

    $scope.pagination = function(direction){
        if($scope.offset === 0 && direction < 0 ) return;
        if($scope.recruitmentData.length < $scope.limitSize && direction > 0 ) return;
        $scope.offset += direction * $scope.limitSize;
        $scope.pageOverrideNumber = $scope.offset / $scope.limitSize + 1;
        reloadData();
    };

    $scope.pageOverride = function(){
        $scope.offset = ($scope.pageOverrideNumber -1) * $scope.limitSize;
        reloadData();
    };

    $scope.recruitmentData = null;
    $scope.loadingData = true;
    $scope.noData = false;

    function reloadData() {

        $scope.loadingData = true;
        $scope.recruitmentData = null;
        var qs = '?limit=10&offset=' + $scope.offset + '&status=' + $scope.statusType;
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/recruitment/all' + qs).then(function (res) {

            $scope.loadingData = false;
            /*res.data.caregivers.forEach(function (caregiver) {
                caregiver.createdAt = caregiver.date;
            })*/
            $scope.recruitmentData = res.data.caregivers;

        }, function (err) {
            $scope.loadingData = false;
            if (err.status === 404) {
                $scope.noData = true;
            }
            $scope.recruitmentData = [];
            toaster.pop('error', 'something went wrong');

        });
    }
    reloadData();

    $scope.exposeCaregiver = function (caregiver, idx) {
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/recruitment/expose_caregiver/' + caregiver).then(function (res) {

            toaster.pop('success', 'Success');

            $scope.recruitmentData[idx].exposed = true;
            $scope.recruitmentData[idx].ignored = false;
            // reloadData();

        }, function (err) {
            toaster.pop('error', 'something went wrong');
        });
    };

    $scope.ignoreCaregiver = function (caregiver, idx) {
        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/recruitment/ignore_caregiver/' + caregiver).then(function (res) {

            toaster.pop('success', 'Success');

            $scope.recruitmentData[idx].exposed = false;
            $scope.recruitmentData[idx].ignored = true;

            // reloadData();

        }, function (err) {
            toaster.pop('error', 'something went wrong');
        });
    };

    $scope.addNoteToCaregiver = function (caregiver) {

        var body = {
            note: caregiver.notes
        };

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/recruitment/notes_caregiver/' + caregiver.id, body).then(function (res) {

        }, function (err) {
            toaster.pop('error', 'something went wrong');
        });
    };

    $scope.buyProfilePop = function (caregiver) {

        if(caregiver.selected === true) {
            var c = DatabaseApi.getCaregiverById(caregiver.id);
            if(c) {
                $rootScope.openCaregiverModal(caregiver.id);

            }
            return;
        }
        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/buy-caregiver-modal.html',
            size: 'md',
            controller: 'buyCaregiverModalCtrl',
            resolve: {
                caregiver: function() { return caregiver; }
            }
        });
        modalInstance.result.then(function (res) {
            console.log(res);

            reloadData();
        }, function () {

        });

    };

    $scope.openExternal = function (url) {
      $window.open($sce.trustAsResourceUrl(url));
    };

    $scope.getQuestionText = function (questionId, question, questions) {
        if(!question) return;
        if(question.type === 'Document'){
            if(question.id === 6) return 'HHA Certificate - scan';
            if(question.id === 18) return 'Physical assessment - scan';
            if(question.id === 20) return 'Driver\'s license - scan';
        }
        return question.questionText;
    };

    $scope.getQuestionAnswerText = function (questionId, question, questions) {
        if(!question) return;
        if(question.type === 'FullDate'){
            return $filter("mfShortDate")(question.answer);
        } else if(question.type === 'MonthYearDate' ){
            return $filter("date")(question.answer + '-01', 'MMMM yyyy');
        } else if(question.type === 'Radio'){
            var arr = question.possibleAnswers.filter(function(a){
                return a.id === question.answer;
            });
            return arr[0] ? arr[0].text : JSON.stringify(question);
        } else if(question.type === 'Checkbox'){
            var arr = question.possibleAnswers.filter(function(a){
                return question.answer.indexOf(a.id) > -1;
            });
            return arr.map(function (a) {
                return a.text;
            }).join(', ');
        } else if(question.type === 'Text' || question.type === 'LongText' || question.type === 'Numeric'){
            return question.answer;
        } else if(question.type === 'Document'){
            return question.answer;
        }
    };

});
