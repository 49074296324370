//'use strict';
app.factory('authInterceptor', function ($q , $injector, $rootScope,$window, Storage, Consts) {
  return {
    
    request: function (config) {

      config.headers = config.headers || {};
      if(config.url.search(Consts.api) > -1 || config.url.search(Consts.localServer) > -1){
        config.headers.Authorization = 'Token ' + $rootScope.token || undefined;
      } else {
        if(config && config.headers) config.headers.Authorization = undefined;
      }

      if(config.url.search('amazonaws') > -1){
        console.log('amazon');
          if(config && config.headers) delete config.headers.Authorization;
      }
        //console.log(config);
        //Storage.set('user', {'authToken': })
       // $localstorage.set('Token', $rootScope.token || undefined);
      return config;
      
    },
    
    responseError: function(rejection) {
      if (rejection && rejection.status === 403) {
        //Storage.clear();

        //console.log('403 - need to go to login');
        //$injector.get('$state').go('login');
        //$rootScope.user = {};
        ///$window.location.reload();
      }
      return $q.reject(rejection);
    }
  };
  
});
