"use strict";
app.component('newVisitCaregiverSearch', {
    templateUrl: 'admin/views/new-visit-caregiver-search.html',
    bindings: {
        caregivers: '<',
        isEditMode: '<',
        preSelectedItem: '<',
        isDisabled: '<',
        showError: '<',
        emptyCaregiversMessage: '<',
        handleSelect: '&',
        handleDeselect: '&',
        onSeeAllClick: '&',
        hideIcon: '<'
    },
    controller: function ($scope, $filter) {
        $scope.resultsLength = 0;
        $scope.itemFilter = { val: '' };

        $scope.onItemSelect = function (item) {
            $scope.itemFilter.val = '';
            $scope.resultsLength = 0;
            $scope.selectedItem = item;
            $scope.$ctrl.handleSelect({ caregiver: item });
        }

        $scope.onItemDeSelect = function () {
            $scope.itemFilter.val = '';
            $scope.selectedItem = null;
            $scope.selectedIndex = -1;
            $scope.resultsLength = 0;
            $scope.$ctrl.handleDeselect();
        }

        $scope.selectedIndex = -1;

        $scope.searchKeyUp = function (event) {
            let resultsPreview = $filter('filterByNameAndCode')($scope.$ctrl.caregivers, $scope.itemFilter.val);
            resultsPreview = Object.values(resultsPreview);
            $scope.resultsLength = resultsPreview.length;
            const resultsAmount = resultsPreview.length;
            switch (event.key) {
                case 'Enter': {
                    if ($scope.selectedIndex > -1 && $scope.selectedIndex < resultsAmount) {
                        $scope.onItemSelect(resultsPreview[$scope.selectedIndex]);
                    }

                    break;
                }
                case 'Escape': {
                    $scope.onItemDeSelect();
                    break;
                }
                case 'ArrowDown': {
                    if ($scope.selectedIndex < resultsAmount - 1) {
                        $scope.selectedIndex++;
                    }
                    break;
                }
                case 'ArrowUp': {
                    if ($scope.selectedIndex > -1) {
                        $scope.selectedIndex--;
                    }
                    break;
                }
                default: {
                    $scope.selectedIndex = -1;
                    break;
                }
            }
        }

        this.$onChanges = (changes) => {
            if ("preSelectedItem" in changes) {
                $scope.selectedItem = $scope.$ctrl.preSelectedItem;
            }
        };

        this.$onInit = function () {
            if ($scope.$ctrl.preSelectedItem) {
                $scope.selectedItem = $scope.$ctrl.preSelectedItem;
            } else {
                $scope.selectedItem = null;
            }
        }
    }
});