app.component("patientDiagnosisCodes", {
  templateUrl: "admin/views/patient-diagnosis-codes.html",
  bindings: {
    patient: "<",
    isAnswer: "<",
    questionId: "<",
    answer: "<",
    saveAnswer: "<",
    readonly: "<",
  },
  controller: function($scope, $rootScope, $uibModal, $filter, DatabaseApi, toaster) {
    const vm = this;
    $scope.isLoadingCodes = false;

    vm.$onInit = function() {
      $scope.patient = vm.patient;
      $scope.isAnswer = vm.isAnswer;
      $scope.answer = vm.answer;

      $scope.onChangeAnswer = (answer) => {
        const instantNow = JSJoda.Instant.now();
        return vm.saveAnswer(vm.questionId, -1, answer, instantNow);
      };

      $scope.getPatientContractDiagnosisCode();

      if ($scope.isAnswer) {
        $scope.patientContractDiagnosisCodes = $scope.answer;
      }
    };

    vm.$onChanges = function(changes) {
      if ("patient" in changes) {
        if (
          Array.isArray(changes.patient.previousValue.contracts) &&
          Array.isArray(changes.patient.currentValue.contracts) &&
          changes.patient.previousValue.contracts.length !== changes.patient.currentValue.contracts.length
        ) {
          $scope.patient = vm.patient;
        }
      }
    };

    function checkNotEqualAnswerWithCodes() {
      const notEqualAnswerWithCodes = !isEqualCodesLists($scope.patientContractDiagnosisCodes, $scope.patientProfileDiagnosisCodes);
      $scope.notEqualAnswerWithCodes = notEqualAnswerWithCodes;
    }

    $scope.getPatientContractDiagnosisCode = async function() {
      if (!$rootScope.agencyId || !$rootScope.user || !$rootScope.user.agencyMember) return;
      $scope.isLoadingCodes = true;

      const url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/patients/" + vm.patient.id + "/patient_contract_diagnosis_codes";

      DatabaseApi.get(url).then(function(res) {
        $scope.isLoadingCodes = false;
        $scope.patientProfileDiagnosisCodes = res.data.patientContractDiagnosisCodes.map(diagnosisItem => {
          var patientContract = $filter("filter")(vm.patient.contracts, { id: diagnosisItem["patientContractId"] });
          if (patientContract[0] !== undefined) {
            diagnosisItem.patientContractName = patientContract[0].contractTypeName;
          }
          return diagnosisItem;
        });

        if ($scope.isAnswer) {
          if (!$scope.answer) {
            $scope.answer = $scope.patientProfileDiagnosisCodes;
            $scope.patientContractDiagnosisCodes = $scope.patientProfileDiagnosisCodes;
          }
          checkNotEqualAnswerWithCodes();
        } else {
          $scope.patientContractDiagnosisCodes = $scope.patientProfileDiagnosisCodes;
        }
        
      }, function(err) {
        toaster.pop("error", "Something went wrong", "Could not get patient diagnosis list");
      });
    };

    $scope.resetAnswerToProfileIcds = function() {
      if ($scope.patientProfileDiagnosisCodes) {
        $scope.patientContractDiagnosisCodes = $scope.patientProfileDiagnosisCodes;
        $scope.answer = $scope.patientProfileDiagnosisCodes;
        $scope.onChangeAnswer($scope.answer);
        checkNotEqualAnswerWithCodes();
      }
    }

    $scope.openAddPatientDiagnosisModal = function(data) {
      const newScope = $scope.$new();
      newScope.patientId = vm.patient.id;
      newScope.getPatientContractDiagnosisCode = $scope.getPatientContractDiagnosisCode;

      newScope.deleteAnswerCode = (removedCodeId) => {
        $scope.answer = $scope.answer.filter(code => code.id !== removedCodeId);

        $scope.patientContractDiagnosisCodes = $scope.answer;
        $scope.onChangeAnswer($scope.answer);
        checkNotEqualAnswerWithCodes();
      }

      newScope.saveAnswerCode = (changedCode, prevId) => {
        if (changedCode.type && changedCode.type !== "OTHER") {
          const otherSameTypeCodes = $scope.answer.filter(
              a => a.id !== changedCode.id 
                && a.type === changedCode.type
          );
          if (otherSameTypeCodes.length > 0) {
            throw `${changedCode.type} code already exists!`;
          }
        }

        if (changedCode && changedCode.id) {
          const idToLookup = prevId || changedCode.id;
          const found = $scope.answer.filter(code => code.id === idToLookup);

          if (found.length) {
            $scope.answer = $scope.answer
              .map(code => code.id === idToLookup
                ? ({ ...code, ...changedCode, updatedAt: JSJoda.Instant.now() })
                : code
              );
          } else {
            const codes = $scope.answer || [];
            $scope.answer = [...codes, fillAnswerCodeFields(changedCode)];
            $scope.answer = JSON.parse(JSON.stringify($scope.answer));
          }

          $scope.answer = JSON.parse(JSON.stringify($scope.answer));
        } else {
          const codes = $scope.answer || [];
          const newCode = createAnswerCode(changedCode, codes);
          $scope.answer = [...codes, newCode];
          $scope.answer = JSON.parse(JSON.stringify($scope.answer));
        }

        $scope.patientContractDiagnosisCodes = $scope.answer;
        $scope.onChangeAnswer($scope.answer);
        checkNotEqualAnswerWithCodes();
      };

      $uibModal.open({
        templateUrl: "admin/views/patient-contract-diagnosis-code-setup-modal.html",
        controller: "patientContractDiagnosisCodeSetupModalCtrl",
        scope: newScope,
        size: "lg",
        resolve: {
          isAnswer: function() {
            return $scope.isAnswer;
          },
          data: function() {
            if (data) return data;
            return undefined;
          }
        }
      });
    };
  }
});

function isEqualCodes(codeA, codeB) {
  return codeA.id === codeB.id
    && codeA.startDate === codeB.startDate
    && codeA.endDate === codeB.endDate;
}

function isEqualCodesLists(codesListA, codesListB) {
  const findEqualCode = (codeToFind, codes) => {
    for (const icdCode of (codes || [])) {
      if (isEqualCodes(codeToFind, icdCode)) {
        return icdCode;
      }
    }

    return null;
  };

  const areAllCodesEqual = (listA, listB) => {
    for (const icdCode of (listA || [])) {
      const sameCode = findEqualCode(icdCode, listB);
      if (!sameCode) {
        return false;
      }
    }
    return true;
  };
  
  return areAllCodesEqual(codesListA, codesListB)
    && areAllCodesEqual(codesListB, codesListA);
}

function createAnswerCode(code, diagnosisCodes) {
  const minId = diagnosisCodes
    .filter((code) => code.id < 0)
    .reduce((min, code) => ((code.id < min) ? code.id : min), 0);

  const nextAnswerDiagnosisId = minId - 1;

  const newCode = {
    id: nextAnswerDiagnosisId,
    ...fillAnswerCodeFields(code)
  };

  return newCode;
}

function fillAnswerCodeFields(code) {
  return {
    createdAt: JSJoda.Instant.now(),
    createdBy: -1,
    updatedAt: null,
    updatedBy: null,
    isForBilling: false,
    ...code
  }
}