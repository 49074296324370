"use strict";

angular.module("dashboard").controller("switchEnv", function($scope, $rootScope) {
    const activeEnv = (() => {
        try {
            const rawOverride = localStorage.getItem("apiOverride");
            if (rawOverride === null) {
                return {
                    apiBaseUrl: apiUrl ?? null,
                    socketIOServerUrl: socketUrl ?? null
                }
            }

            const { overrideApiUrl, overrideSocketUrl } = JSON.parse(rawOverride);

            if (!overrideApiUrl || !overrideSocketUrl) {
                resetEnv();
            }

            return { apiBaseUrl: overrideApiUrl, socketIOServerUrl: overrideSocketUrl };
        } catch {
            localStorage.removeItem("apiOverride");
            console.error(`localStorage apiOverride was corrupted. It has been removed`);
        
            return {
                apiBaseUrl: apiUrl ?? null,
                socketIOServerUrl: socketUrl ?? null
            }
        }
    })();

    const setActiveEnv = ({ apiBaseUrl, socketIOServerUrl }) => {
        if (apiBaseUrl && socketIOServerUrl) {
            activeEnv.apiBaseUrl = apiBaseUrl;
            activeEnv.socketIOServerUrl = socketIOServerUrl;
            localStorage.removeItem("user");
            localStorage.removeItem("userEmail");
            return localStorage.setItem("apiOverride", JSON.stringify({
                overrideApiUrl: apiBaseUrl,
                overrideSocketUrl: socketIOServerUrl
            }));
        }
    }

    const predefinedEnvs = {
        production: {
            apiBaseUrl: "https://api.medflyt.com/",
            socketIOServerUrl: "https://ws.medflyt.com/"
        },
        staging: {
            apiBaseUrl: "https://medflyt-api-staging.herokuapp.com/",
            socketIOServerUrl: "https://ws-stg.medflyt.com/"
        },
        local: {
            apiBaseUrl: "http://localhost:3000/",
            socketIOServerUrl: "http://localhost:3005/"
        },
        demo1: {
            apiBaseUrl: "https://medflyt-api-demo1.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo1.medflyt.com/"
        },
        demo2: {
            apiBaseUrl: "https://medflyt-api-demo2.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo2.medflyt.com/"
        },
        demo3: {
            apiBaseUrl: "https://medflyt-api-demo3.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo3.medflyt.com/"
        },
        demo4: {
            apiBaseUrl: "https://medflyt-api-demo4.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo4.medflyt.com/"
        },
        demo5: {
            apiBaseUrl: "https://medflyt-api-demo5.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo5.medflyt.com/"
        },
        demo10: {
            apiBaseUrl: "https://medflyt-api-demo10.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-demo10.medflyt.com/"
        },
        provider: {
            apiBaseUrl: "https://medflyt-api-provider.us-east-1.elasticbeanstalk.com/",
            socketIOServerUrl: "https://ws-provider.medflyt.com/"
        }
    }

    const onSelectPredefinedEnv = (env) => {
        setActiveEnv(env);
        $scope.shouldReload = true;
    }

    const onSubmitCustomEnv = () => {
        setActiveEnv(activeEnv);
        $scope.shouldReload = true;
    }

    const onResetEnv = () => {
        $rootScope.resetApiOverride();
    }


    $scope.reloadPage = () => window.location.reload();
    $scope.onResetEnv = onResetEnv;
    $scope.activeEnv = activeEnv;
    $scope.predefinedEnvs = predefinedEnvs;
    $scope.onSelectPredefinedEnv = onSelectPredefinedEnv;
    $scope.onSubmitCustomEnv = onSubmitCustomEnv;
});

  angular.module("dashboard").filter("displayEnvUrl", function () {
    return (env) => {
        if (env.apiBaseUrl === null || env.socketIOServerUrl=== null) {
            return window.location.origin;
        }
        
        return `${window.location.origin}/?overrideApiUrl=${env.apiBaseUrl}&overrideSocketUrl=${env.socketIOServerUrl}`
    };
  });