"use strict";
app.component('visitSectionToggleEdit', {
    templateUrl: 'admin/views/new-visit/visit-section-toggle-edit.html',
    bindings: {
        areMultipleValues: '<',
        isEditMode: '<',
        onClickToggleEditMode: '&',
        showWithoutMixedContent: '<',
    }
});
