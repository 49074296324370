app.component("caregiverAttendance", {
    templateUrl: "admin/views/caregiver-attendance.html",
    bindings: {
        caregiverId: '<',
    },
    controller: function ($scope, $rootScope, DatabaseApi, toaster, NgTableParams) {

        const initialize = () => {
            if (!$rootScope.isPermittedByKey("view_caregiver_page_attendance")) {
                return;
            }
            const currentYear = JSJoda.LocalDate.now().year();
            $scope.selectedYear = { val: currentYear };
            $scope.years = getYears();
        };

        const initAttendanceData = (year) => {
            if (!Number.isInteger(year)) {
                return;
            }
            $scope.isLoading = true;
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/attendance"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":caregiverId", $scope.caregiverId)
                + `?year=${year}`;
            DatabaseApi.get(url).then((res) => {
                $scope.caregiverPtosOverall = res.data.caregiverPtos.overall;
                $scope.caregiverPtosBreakdown = res.data.caregiverPtos.breakdown;
                $scope.missedVisits = res.data.missedVisits;
                $scope.missedClockInOuts = res.data.missedClockInOuts;
                initCaregiverPtos();
                initMissedVisits();
                initMissedClockInOuts();
            }, (err) => {
                toaster.pop("error", "Failed to fetch caregiver attendance data");
            }).finally(() => {
                $scope.isLoading = false;
            });
        };

        const initCaregiverPtos = () => {
            initCaregiverPtosOverallTable($scope.caregiverPtosOverall);
            initCaregiverPtosBreakdownTable($scope.caregiverPtosBreakdown);
        };

        const initMissedVisits = () => {
            mapMissedVisits();
            initMissedVisitsBreakdownTable($scope.missedVisits);
        };

        const initMissedClockInOuts = () => {
            mapMissedClockInOuts();
            initMissedClockInOutsBreakdownTable($scope.missedClockInOutsBreakdown);
        };

        const mapMissedVisits = () => {
            $scope.missedVisitsOverall = {
                totalVisitsAmount: 0,
                totalDurationMinutes: 0,
            };
            $scope.missedVisits.forEach(missedVisit => {
                $scope.missedVisitsOverall.totalVisitsAmount++;
                $scope.missedVisitsOverall.totalDurationMinutes += missedVisit.durationMinutes;
            });
        };

        const mapMissedClockInOuts = () => {
            $scope.missedClockInOutsOverall = {
                missedClockIns: 0,
                missedClockOuts: 0,
            };
            $scope.missedClockInOutsBreakdown = $scope.missedClockInOuts.map(missedClock => {
                let scheduledDateTime;
                if (missedClock.missedType === "IN") {
                    $scope.missedClockInOutsOverall.missedClockIns++;
                    scheduledDateTime = missedClock.endTime;
                } else {
                    $scope.missedClockInOutsOverall.missedClockOuts++;
                    scheduledDateTime = missedClock.startTime;
                }
                return {
                    ...missedClock,
                    scheduledDateTime: scheduledDateTime
                };
            });
        };

        const initCaregiverPtosOverallTable = (items) => {
            const oldTotal = $scope.caregiverPtosOverallTable?.total?.() || 0;
            let page = false;
            let count = 10;
            let sorting = { usageMinutes: "desc" };
            if ($scope.caregiverPtosOverallTable) {
                sorting = $scope.caregiverPtosOverallTable.sorting();
                page = $scope.caregiverPtosOverallTable.page();
                count = $scope.caregiverPtosOverallTable.count();
            }

            const options = {
                count: count,
                sorting: sorting
            };

            $scope.caregiverPtosOverallTable = new NgTableParams(options, {
                dataset: items,
                counts: [10, 25, 50, 100]
            });
            if (page && oldTotal === $scope.caregiverPtosOverallTable.total()) {
                $scope.caregiverPtosOverallTable.page(page);
            }
        };

        const initCaregiverPtosBreakdownTable = (items) => {
            const oldTotal = $scope.caregiverPtosBreakdownTable?.total?.() || 0;
            let page = false;
            let count = 10;
            let sorting = {};
            if ($scope.caregiverPtosBreakdownTable) {
                sorting = $scope.caregiverPtosBreakdownTable.sorting();
                page = $scope.caregiverPtosBreakdownTable.page();
                count = $scope.caregiverPtosBreakdownTable.count();
            }

            const options = {
                count: count,
                sorting: sorting
            };

            $scope.caregiverPtosBreakdownTable = new NgTableParams(options, {
                dataset: items,
                counts: [10, 25, 50, 100]
            });
            if (page && oldTotal === $scope.caregiverPtosBreakdownTable.total()) {
                $scope.caregiverPtosBreakdownTable.page(page);
            }
        };

        const initMissedVisitsBreakdownTable = (items) => {
            const options = {
                count: 10
            };

            $scope.missedVisitsBreakdownTable = new NgTableParams(options, {
                dataset: items,
                counts: [10, 25, 50, 100]
            });
        };

        const initMissedClockInOutsBreakdownTable = (items) => {
            const options = {
                count: 10
            };

            $scope.missedClockInOutsBreakdownTable = new NgTableParams(options, {
                dataset: items,
                counts: [10, 25, 50, 100]
            });
        };

        const getYears = () => {
            const years = [];
            const currentYear = JSJoda.LocalDate.now().year();
        
            for (let i = currentYear - 3; i <= currentYear + 1; i++) {
                years.unshift(i);
            }
        
            return years;
        };

        $scope.handleSelectYear = (year) => {
            $scope.selectedYear.val = year;
        };
    
        $scope.handleClickPreviousYear = () => {
            $scope.selectedYear.val--;
        };
    
        $scope.handleClickNextYear = () => {
            $scope.selectedYear.val++;
        };

        const refreshComponent = () => {
            if ($scope.selectedYear && $scope.selectedYear.val && $scope.caregiverId) {
                initAttendanceData($scope.selectedYear.val);
            }
        };

        $scope.$watch("selectedYear.val", () => {
            refreshComponent();
        });

        $rootScope.$on("refresh_ptos", () => {
            refreshComponent();
        });

        this.$onInit = async function () {
            $scope.caregiverId = $scope.$ctrl.caregiverId;
            if ($scope.caregiverId) {
                initialize();
            }
        }

        this.$onChanges = (changes) => {
            if ("caregiverId" in changes) {
                $scope.caregiverId = $scope.$ctrl.caregiverId;
                if ($scope.caregiverId) {
                    initialize();
                }
            }
        }
    }
});