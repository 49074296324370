app.controller(
    "newVisitCtrl",
    function (
      $scope,
      $rootScope,
      $window,
      $state,
      DatabaseApi,
      $q,
      $stateParams,
      visit,
      toaster,
      $filter,
      SweetAlert,
      $uibModal,
      $timeout,
      Analytics,
      $http,
      Consts,
      Storage,
      VisitStatus,
      GoogleAddressService
    ) {
    var GOOGLE_MAPS_API_KEY = 'AIzaSyAcpv_xCANE3_P2EEqNmZL4LESMR_iAIik';

    window.scrollTo(0, 0);
    $scope.patient = $stateParams.patient;
    $scope.contract = $stateParams.contract;
    $scope.treatment = $stateParams.treatment;
    init();

    // must have a patient
    if (!$stateParams.id) {
        if (!$stateParams.patient || !$stateParams.contract || !$stateParams.treatment) {
            $scope.hidePage = true;
            $state.go('app.visits.dashboard');
            return;
        }
    }

    $scope.certificationLabelMap = {};
    $scope.labels = [];
    $scope.visitLabelsData = DatabaseApi.visitLabelsData(false, true);
    $scope.visitInstances = [];

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem) => certificationItem.certification);

    if ($scope.visitLabelsData) initVisitLabelData();

    $rootScope.$on('got_visit_labels', () => {
        $scope.visitLabelsData = DatabaseApi.visitLabelsData(false, true);
        initVisitLabelData();
    });

    $rootScope.$on('got_plan_of_care_type', (event) => {
        $scope.planOfCareTypes = DatabaseApi.plansOfCare();
        initVisitLabelData();
    })

    $scope.indexOfSelectedLabels = function (label) {
        return $scope.form.labels.findIndex(x => x.id === label.id);
    }

    function initVisitLabelData() {
        // init label map and certification
        if ($scope.visitLabelsData !== undefined && $scope.visitLabelsData.activeLabels) {
            $scope.labels = [];
            for (const vld of $scope.visitLabelsData.activeLabels) {
                const label = {
                    id: vld.id,
                    name: vld.name,
                    color: vld.color,
                    hasPlanOfCare: vld.hasPlanOfCare,
                    certifications: $scope.visitLabelsData.certificationsAssoc.reduce((acc, curr) => {
                        if (curr.visitLabelId === vld.id) acc.push(curr.certification);
                        return acc;
                    }, []),
                    documents: []
                }
                $scope.labels.push(label);
            }

            // init patient document
            if ($scope.availableForms && $scope.availableForms.length) {
                for (const lbIdx in $scope.labels) {
                    $scope.labels[lbIdx].documents = $scope.visitLabelsData.patientDocsAssoc.reduce((acc, curr) => {
                        if ($scope.labels[lbIdx].id === curr.visitLabelId) {
                            const filteredDocument = $scope.availableForms.filter(x => x.id === curr.patientDocumentTypeId)[0];
                            if (filteredDocument !== undefined) {
                                acc.push({ id: filteredDocument.id, title: filteredDocument.title });
                            }
                        } return acc;
                    }, []);
                }
            }

            // init certification to visit labels
            $scope.certificationLabelMap = {};
            for (const certification of $scope.certifications) {
                $scope.certificationLabelMap[certification] = $scope.labels.reduce((acc, curr) => {
                    if (curr.certifications.includes(certification)) {
                        acc.push({ id: curr.id, name: curr.name, color: curr.color.substring(0, curr.color.length - 4) + '0.2)' });
                    }
                    return acc;
                }, []);
            }
        }

    }
    $scope.displaydLabels = [];

    $scope.filterLabels = (certifications) => {
        $scope.displaydLabels = [];
        $scope.displaydLabels = Object.keys($scope.certificationLabelMap).reduce((acc, curr) => {
            if (certifications.includes(curr)) {
                acc = acc.concat($scope.certificationLabelMap[curr])
            }
            return acc;
        }, []);


        //remove duplicate labels
        var obj = {};

        for (var i = 0, len = $scope.displaydLabels.length; i < len; i++)
            obj[$scope.displaydLabels[i]['id']] = $scope.displaydLabels[i];

        $scope.displaydLabels = new Array();
        for (var key in obj)
            $scope.displaydLabels.push(obj[key]);

        // sort labels
        $scope.displaydLabels.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }



    // // select/unselect docs
    // function toggleDocs (labels){


    // }



    // function prepareLabelData (){
    //     $scope.labels = [
    //         {
    //             name: "asfsafa"
    //             docs: [],
    //             planOfcare: null,
    //             certifications:
    //         }
    //     ];


    //     // helps you when clicking on certification - decide which labels to show
    //     var certificationLabelMap = {
    //         'HHA': ['single visit',...],
    //         'PCA': [...]
    //         'RN': [....]
    //     };


    //     // helps to decide which docs to select/unselect
    //     var labelPatientDocMap = {
    //         'single visit': {
    //             documents: [],
    //             planOfCareType: null
    //         },
    //         'long visit': {
    //             documents: [],
    //             planOfCareType: null
    //         }...
    //     }



    // }





    // if i click on certification - i can do things
    // if i click on a label  - i can do things


    // this is a full visit object template
    var newVisit = {
        treatment: null,
        startTime: undefined,
        endTime: undefined,
        dayTimeParams: null,
        price: 0,
        certification: [],
        visitLabels: [],
        gender: null,
        patientGender: null,
        languages: [],
        generalNotes: '',
        patientName: '',
        patientAddress: '',
        patientAddress2: null,
        patientAddressComponents: null,
        assignableCaregiverRadius: null,
        patientPhone: null,
        patientAlternativePhone: null,
        paymentType: 'Hourly',
        hideEndDateFromCaregiver: null,
        caseDetails: '',
        salesRep: null, // ?
        referralDate: null, // ?
        timezone: null,
        assignedCoordinators: [],
        isAssessment: false,
        isPreAssigned: false,
        isFromAlgorithm: false,
        rnVisitParams: null,
        flexibleVisitParams: null,
        splitShiftParams: null,
        isGradual: false,
        broadcastToRNTypes: [], // ?
        requiredPatientDocuments: [],
        patientId: null,
        planOfCareType: null,
        isSingleVisit: null
    };


    $scope.getAvailableForms = function () {
        return DatabaseApi.patientDocumentTypes().filter(function (form) {
            return form.versions.filter(function (ver) {
                return ver.isPublished;
            }).length !== 0
        });
    };

    function getPlansOfCare() {
        const plansOfCare = DatabaseApi.plansOfCare();
        return plansOfCare.filter(poc => poc.officeId === $scope.patient.currentOfficeId);
    }

    $scope.availableForms = $scope.getAvailableForms();
    $scope.availablePlansOfCare = getPlansOfCare();
    initVisitLabelData();
    $rootScope.$on("got_data", function () {
        if ($scope.availableForms.length === 0) {
            $scope.availableForms = $scope.getAvailableForms();
        }
        $scope.availablePlansOfCare = getPlansOfCare();

        initVisitLabelData();

        $scope.filterLabels($scope.form.certifications);
        $scope.form.labels = filterLabelsFromLabels(getLabelsByCertification($scope.form.certifications), $scope.form.labels)
        $scope.filterForms($scope.form.certifications, $scope.form.labels);
    });

    $scope.form = {
        canEdit: false, //
        daysOfTheWeek: {},
        daysOfTheWeekCount: 0,
        assignedCoordinatorForm: [], //
        errors: {}, //
        selectLanguages: [], //
        langError: false, //
        broadcastOptions: {
            val: 'auto',
            types: null
        },
        distanceType: {
            val: 'auto'
        },
        locationError: false, //
        datePicker: {
            date: {
                startDate: new Date(),
                endDate: new Date()
            },
            options: {
                applyClass: 'btn-primary',
                locale: {
                    applyLabel: "Apply",
                    fromLabel: "From",
                    //format: "YYYY-MM-DD", //will give you 2017-01-06
                    format: "D MMM YY", //will give you 6  Jan 17
                    //format: "D-MMMM-YY", //will give you 6-January-17
                    toLabel: "To",
                    cancelLabel: 'Cancel'
                },
                autoApply: true,
                minDate: new Date()
            }
        }, //
        flexibleDays: false,
        flexibleDaysCount: null,
        flexibleTimes: false,
        flexibleTimesCount: null,
        disableDays: false,
        advancedTimes: false,
        advancedTimesData: [{
            day: 0,
            startTime: '9:00 AM',
            endTime: '1:00 PM'
        }],
        advancedTimesData2: [{
            day: 0,
            startTime: '2:00 PM',
            endTime: '6:00 PM'
        }],
        priceDisplay: null, //
        priceType: 'Hourly', //
        genders: { //
            Male: false,//
            Female: true //
        },
        certifications: [], //
        visitDuration: 60, //
        isDuration: false, //
        contactPatient: false, //
        labels: [],
        rnVisitType: 'fixed',
        requiredPatientDocuments: [],
        splitShiftParams: null,
        rnVisitParams: null,
        flexibleVisitParams: null,
        planOfCareType: null,
        isSingleVisit: null,
        shifts: 1,
        totalTimePerWeek: '0H'
    };

    $scope.handleRequiredFormClick = function (id, insertOnly) {
        var index = $scope.form.requiredPatientDocuments.indexOf(id);
        if (index === -1) {
            $scope.form.requiredPatientDocuments.push(id);
        } else if (!insertOnly) {
            $scope.form.requiredPatientDocuments.splice(index, 1);
        }

    };

    $scope.toggleAllRequiredForms = function () {
        if ($scope.allRequiredForms) {
            $scope.availableForms.forEach(function (form) {
                $scope.form.requiredPatientDocuments.push(form.id);
            });
            if ($scope.availablePlansOfCare.length > 0) {
                $scope.form.planOfCareType = $scope.availablePlansOfCare[0].planOfCareTypeId;
            }
        } else {
            $scope.form.requiredPatientDocuments = [];
            $scope.form.planOfCareType = null;
        }
    }

    $scope.handlePlanOfCareClick = function (id) {
        if ($scope.form.planOfCareType === id) {
            $scope.form.planOfCareType = null;
        } else {
            $scope.form.planOfCareType = id;
        }

    };
    // todo - dev only
    //$scope.form = angular.extend($scope.form, newVisit);

    var visitToForm;
    var oldVisitForm;

    $scope.languages = [
        // main langs
        "English",
        "Spanish",
        "Russian",
        "Mandarin",
        "French",
        "Arabic",
        // extra langs
        "Albanian",
        "Armenian",
        "Bengali",
        "Bulgarian",
        "Cantonese",
        "Chinese",
        "Creole",
        "Danish",
        "Dutch",
        "Estonian",
        "Farsi",
        "Filipino",
        "Fukkianese",
        "Fula/Fulani",
        "Fuzhounese",
        "Georgian",
        "German",
        "Ghana",
        "Greek",
        "Hakka",
        "Hebrew",
        "Hindi",
        "Hungarian",
        "Italian",
        "Japanese",
        "Korean",
        "Krio",
        "Kyrgyz",
        "Pashto",
        "Persian",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Romanian",
        "Shanghainese",
        "Sign",
        "Soninke",
        "Swahili",
        "Tagalog",
        "Taishanese",
        "Taiwanese",
        "Turkish",
        "Twi",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Vietnamese",
        "Yiddish"
    ];

    $scope.visitLabels = [
        "Single visit",
        "Permanent visit",
        "Live In",
        "Replacement",
        "Emergency Visit",
        "UAS",
        "Initial Rn Assessment",
        "Re-Assessment",
        "Supervisory Visit",
        "Start of Care",
        "Follow-up",
        "Post Hospitalization",
        "Split hours",
        "Mutual Case",
        "Ambulate",
        "Paediatric",
        "Doctor Meeting",
        "Medical Escort"
    ];
    $scope.visitLabelsForRNs = [
        "UAS",
        "Initial Rn Assessment",
        "Re-Assessment",
        "Supervisory Visit",
        "Start of Care",
        "Follow-up",
        "Post Hospitalization"
    ];

    $scope.map = { center: { latitude: 40.00, longitude: -99.00 }, zoom: 17 };
    $scope.marker = {
        id: 0,
        coords: {
            latitude: 40.00,
            longitude: -99.00,
        },
        options: { draggable: false },

    };
    var daysMap = {
        sun: 0,
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6
    };

    $scope.advancedDays = [
        { day: 0, name: 'sun', fullName: 'Sunday' },
        { day: 1, name: 'mon', fullName: 'Monday' },
        { day: 2, name: 'tue', fullName: 'Tuesday' },
        { day: 3, name: 'wed', fullName: 'Wednesday' },
        { day: 4, name: 'thu', fullName: 'Thursday' },
        { day: 5, name: 'fri', fullName: 'Friday' },
        { day: 6, name: 'sat', fullName: 'Saturday' }
    ];

    $scope.fullDaysMap = {};
    $scope.advancedDays.forEach(function (day) {
        $scope.fullDaysMap[day.name] = day.fullName;
    });

    // make hours intervals
    var interval = 15; //minutes interval
    var times = []; // time array
    var times24 = []; // time array
    var tt = 0; // start time
    for (var i = 0; tt < 24 * 60; i++) {
        var clock = ' AM';
        var hh = Math.floor(tt / 60);
        var realHH = Math.floor(tt / 60); // getting hours of day in 0-24 format
        if (hh === 0) hh = 12;
        if (times.length > 47) {
            if (hh !== 12) hh -= 12;
            clock = ' PM';
            //console.log('pm')
        }
        var mm = (tt % 60); // getting minutes of the hour in 0-55 format
        times[i] = {
            label: hh + ':' + ("0" + mm).slice(-2) + clock,
            value: ("0" + realHH).slice(-2) + ':' + ("0" + mm).slice(-2),
            valueTrimmed: realHH + ':' + ("0" + mm).slice(-2)
        };
        if (i) {
            times24[i] = realHH + ':' + ("0" + mm).slice(-2);
        }
        tt = tt + interval;
        //console.log(i);
    }
    times24.push('24:00');
    $scope.times = times;
    $scope.times24 = times24;

    var ceil = $rootScope.agencyId === 49 ? 25 : 100;
    var step = $rootScope.agencyId === 49 ? 0.1 : 1;
    var showTicks = $rootScope.agencyId === 49 ? 1 : 5;
    var precision = $rootScope.agencyId === 49 ? 1 : undefined;
    $scope.slider = {
        value: 5,
        options: {
            floor: 0,
            ceil: ceil,
            step: step,
            precision: precision,
            showSelectionBar: true,
            translate: function (value) {
                return value + ' miles';
            },
            showTicks: showTicks,
            customValueToPosition: function (val, minVal, maxVal) {
                val = Math.sqrt(val);
                minVal = Math.sqrt(minVal);
                maxVal = Math.sqrt(maxVal);
                var range = maxVal - minVal;
                return (val - minVal) / range;
            },
            customPositionToValue: function (percent, minVal, maxVal) {
                maxVal = Math.sqrt(maxVal);
                var value = percent * maxVal;
                return Math.pow(value, 2);
            },
            getPointerColor: function () {
                return '#3077db';
            },
            getSelectionBarColor: function () {
                return '#3077db';
            }

        }
    };

    $scope.visitInProgress = false;

    $scope.flexibleVisitParamsTypes = {
        FlexibleTimeAndDays: {
            type: 'FlexibleTimeAndDays',
            numberOfDays: 7,
            hoursPerDay: 8
        },
        FlexibleTimeFixedDays: {
            type: 'FlexibleTimeFixedDays',
            days: [0, 1, 2, 3, 4, 5, 6],
            hoursPerDay: 8
        },
        FixedTimeFlexibleDays: {
            type: 'FixedTimeFlexibleDays',
            numberOfDays: 7,
            startTime: null,
            endTime: null
        }
    };


    $scope.goBack = function () {
        $window.history.back();
    };

    $scope.backToVisitsPage = function () {
        $state.go('app.visits.dashboard');
    };

    $scope.openRnSuggestionModal = function () {
        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/suggest-visit-to-rn-map.html',
            size: 'lg',
            controller: 'suggestVisitToRnModalCtrl',
            resolve: {
                newPatient: function () {
                    return $scope.patient;
                },
                patientAddress: function () {
                    return {
                        text: $scope.form.patientAddress,
                        components: {
                            location: $scope.form.patientAddressLoc
                        }
                    };
                },
                timezone: function () {
                    return $scope.form.timezone;
                }
            }

        });
        modalInstance.result.then(function (res) {
            console.log('modal click');
        }, function () {
            console.log('modal close');
        });
    };

    $scope.openCalendar = function () {
        $('#date-picker').data('daterangepicker').show();
    };

    $scope.isRNVisit = function () {
        var isRN = false;

        if ($scope.form.certifications.indexOf('RN') > -1) isRN = true;
        if ($scope.form.certifications.indexOf('LPN') > -1) isRN = true;

        return isRN;
    };

    $scope.toggleRnVisitType = function (type) {
        $scope.form.rnVisitType = type;
        if ($scope.form.rnVisitType === 'fixed' && $scope.selectedTimeSpanType !== 'flexible') {
            $scope.form.isDuration = false;
            $scope.form.contactPatient = false;
            $scope.form.rnVisitParams = false;
        } else {
            $scope.form.isDuration = true;
        }

        $scope.form.hideEndDateFromCaregiver = false;
        $scope.form.flexibleTimes = false;
        $scope.form.flexibleDays = false;
    };


    $scope.isSkilled = false;
    $scope.selectedTimeSpanType = 'single';
    $scope.isAllDayHours = false;

    $scope.getTimeSpanTypeFullName = function (timeSpanType) {
        switch (timeSpanType) {
            case 'single': return 'Single';
            case 'permanent': return 'Permanent';
            case 'flexible': return 'Flexible';
            case 'splitshift': return 'Live in / Splitshift';
            default: return '';
        }
    }

    $scope.getFullTimeSpanString = function () {
        var toReturn = $scope.getTimeSpanTypeFullName($scope.selectedTimeSpanType) + ', ' +
            moment($scope.form.datePicker.date.startDate).format('DD MMM') + ', ' +
            $scope.form.advancedTimesData[0].startTime + ' - ' +
            $scope.form.advancedTimesData[0].endTime;

        if ($scope.form.shifts === 2) {
            toReturn += ' (shift 1) ' +
                $scope.form.advancedTimesData2[0].startTime + ' - ' +
                $scope.form.advancedTimesData2[0].endTime + ' (shift 2)';
        }

        return toReturn;
    }

    $scope.toggleCertificationsSelection = function (certifications) {
        if (typeof certifications === 'string') {
            var certIndex = $scope.form.certifications.indexOf(certifications);
            if (certIndex === -1) {

                var isOk = true;

                if (certifications === 'RN' || certifications === 'LPN') {

                    $scope.certifications.forEach(function (cert) {
                        if (cert !== 'RN' && cert !== 'LPN') {
                            if ($scope.form.certifications.indexOf(cert) > -1) isOk = false;
                        }
                    });
                    if (isOk) {
                        $scope.isSkilled = true;
                        if ($scope.selectedTimeSpanType === 'flexible') {
                            $scope.form.rnVisitType = 'flexible';
                        }
                        $scope.toggleRnVisitType($scope.form.rnVisitType);
                    }

                } else {

                    if ($scope.form.certifications.indexOf('RN') > -1 ||
                        $scope.form.certifications.indexOf('LPN') > -1) {
                        isOk = false;
                    }

                    $scope.isSkilled = false;
                }

                if (isOk) $scope.form.certifications.push(certifications);
                else toaster.pop('warning', "Oops...", 'Can’t select professionals and paraprofessionals at the same time ')

            } else {
                $scope.form.certifications.splice(certIndex, 1);
                if ($scope.form.certifications.length === 0) {
                    $scope.isSkilled = false;
                }
            }
        } else {
            $scope.form.certifications = certifications;
        }

        $scope.filterLabels($scope.form.certifications);
        $scope.form.labels = filterLabelsFromLabels(getLabelsByCertification($scope.form.certifications), $scope.form.labels)
        $scope.filterForms($scope.form.certifications, $scope.form.labels);
    };

    $scope.toggleLabelsSelection = function (label) {
        if (typeof label.name === 'string') {
            if ($scope.certificationLabelMap["RN"].findIndex(x => x.id === label.id) > -1) {
                var isBanExists = false;
                $scope.visitLabelsForRNs.forEach(function (l) {
                    if (l !== label && $scope.form.labels.indexOf(l) > - 1) {
                        // todo revert
                        // isBanExists = true;
                    }
                });
                if (isBanExists) {
                    toaster.pop('warning', "Oops...",
                        'Can’t select this label with other assessments label');
                    return;
                }

            }


            var labelIndex = $scope.indexOfSelectedLabels(label);
            if (labelIndex === -1) {
                $scope.form.labels.push(label);
            } else {
                $scope.form.labels.splice(labelIndex, 1);
            }
        } else {
            $scope.form.labels = label;
        }

        // filter only if part of certification
        $scope.form.labels = filterLabelsFromLabels(getLabelsByCertification($scope.form.certifications),
            $scope.form.labels)
        $scope.filterForms($scope.form.certifications, $scope.form.labels);

    };

    function isLabelRelatedToPlanOfCare(labels) {
        let related = false;

        for (const label of labels) {
            const filteredLabel = $scope.labels.filter(x => x.id === label.id)[0];
            if (filteredLabel != undefined && filteredLabel.hasPlanOfCare) {
                // availablePlansOfCare already initialized with patient current office id
                related = $scope.availablePlansOfCare.length > 0;
                if (related) {
                    break;
                }
            }
        }

        return related;
    }

    function certificationsPartOfSkilledCertifications(certifications) {
        return certifications.includes('RN') || certifications.includes('LPN');
    }

    function filterLabelsFromLabels(aLabels, bLabels) {
        let labels = [];
        for (let i = 0; i < aLabels.length; i++) {
            for (let b = 0; b < bLabels.length; b++)
                if (aLabels[i].id == bLabels[b].id) {
                    labels.push(bLabels[b]);
                    break;
                }
        }


        //remove duplicate labels
        var obj = {};

        for (var i = 0, len = labels.length; i < len; i++)
            obj[labels[i]['id']] = labels[i];

        labels = new Array();
        for (var key in obj)
            labels.push(obj[key]);

        // sort labels
        labels.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return labels;
    }

    function getLabelsByCertification(certifications) {
        let labels = [];
        for (const certification of certifications) {
            labels = labels.concat($scope.certificationLabelMap[certification])
        }
        return labels;
    }

    function getDocumentsByLabel(labels) {
        let docs = [];
        for (const label of labels) {
            docs = docs.concat($scope.labels.reduce((acc, curr) => {
                if (curr.id === label.id) {
                    acc = acc.concat(curr.documents);
                }
                return acc;
            }, []))
        }

        //remove duplicate documents
        var obj = {};

        for (var i = 0, len = docs.length; i < len; i++)
            obj[docs[i]['id']] = docs[i];

        docs = new Array();
        for (var key in obj)
            docs.push(obj[key]);

        // sort documents
        docs.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

        return docs;
    }

    function getDocumentsByCertifications(certifications) {
        let docs = $scope.labels.reduce((acc, curr) => {
            for (const certification of certifications) {
                if (curr.certifications.includes(certification)) {
                    acc = acc.concat(curr.documents);
                    break;
                }
            }

            return acc;
        }, [])

        //remove duplicate documents
        var obj = {};

        for (var i = 0, len = docs.length; i < len; i++)
            obj[docs[i]['id']] = docs[i];

        docs = new Array();
        for (var key in obj)
            docs.push(obj[key]);

        // sort documents
        docs.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));


        return docs;
    }

    $scope.patientDocumentTypesForms = [];
    $scope.filterForms = (certifications, labels) => {
        if (
            $scope.form.canEdit ||
            $scope.form.scheduledCanEdit ||
            !$scope.formIsReady
        ) {
            //Editable - don't filter forms by label/certificate + Set patientDocs to default availables
            $scope.patientDocumentTypesForms = $scope.availableForms;
            return;
        }

        $scope.form.requiredPatientDocuments = [];

        let labelsDocs = getDocumentsByLabel(labels);

        $scope.patientDocumentTypesForms = [];
        for (const doc of labelsDocs) {
            $scope.form.requiredPatientDocuments.push(doc.id);
        }

        $scope.form.requiredPatientDocuments.sort((a, b) => {
            if (parseInt(a, 10) < parseInt(b, 10)) {
                return -1;
            }
            if (parseInt(a, 10) > parseInt(b, 10)) {
                return 1;
            }
            return 0;
        });

        $scope.patientDocumentTypesForms = $scope.availableForms;;


        // plan of care
        if (certificationsPartOfSkilledCertifications(certifications) &&
            isLabelRelatedToPlanOfCare(labels) &&
            $scope.availablePlansOfCare.length > 0) {
            $scope.form.planOfCareType = $scope.availablePlansOfCare[0].planOfCareTypeId;
        } else {
            $scope.form.planOfCareType = null;
        }

    }

    $scope.toggleDays = function (day) {

        if ($scope.form.disableDays) {
            return;
        }

        $scope.form.flexibleDays = false;

        $scope.form.daysOfTheWeek[day] = !$scope.form.daysOfTheWeek[day];

        $scope.form.daysOfTheWeekCount = 0;
        for (var key in $scope.form.daysOfTheWeek) {
            if ($scope.form.daysOfTheWeek[key]) $scope.form.daysOfTheWeekCount++;
        }

        $scope.setFlexibleDaysCount($scope.form.daysOfTheWeekCount);

    };




    $scope.setFlexibleDaysCount = function (days) {
        $scope.form.flexibleDaysCount = days;
    };

    $scope.toggleLanguageSelection = function (languages) {
        if (typeof languages === 'string') {
            var langIndex = $scope.form.selectLanguages.indexOf(languages);
            if (langIndex === -1) {
                $scope.form.selectLanguages.push(languages);
            } else {
                $scope.form.selectLanguages.splice(langIndex, 1);
            }
        } else {
            $scope.form.selectLanguages = languages;
        }

    };

    $scope.setFlexibleTimesCount = function (times) {
        $scope.form.flexibleTimesCount = times;
    };

    $scope.toggleHideEndDate = function () {
        $scope.form.hideEndDateFromCaregiver = !$scope.form.hideEndDateFromCaregiver;
    };

    $scope.toggleAdvancedTime = function () {
        $scope.isAdvancedTime = !$scope.isAdvancedTime;
    };

    $scope.isRequired = function (field) {
        if (!$rootScope.visitSettings) return true;
        return $rootScope.visitSettings[field];
    };

    $scope.restoreVisitOriginalTime = function () {
        var newData = angular.copy(visitToForm);

        newData.flexibleVisitParams = $scope.form.originalVisitDetails.flexibleVisitParams;
        newData.dayTimeParams = $scope.form.originalVisitDetails.dayTimeParams;
        newData.rnVisitParams = $scope.form.originalVisitDetails.rnVisitParams;
        newData.startTime = $scope.form.originalVisitDetails.startTimeLocal;
        newData.endTime = $scope.form.originalVisitDetails.endTimeLocal;
        newData.isPreAssigned = $scope.form.originalVisitDetails.isPreAssigned;
        delete $scope.form.originalVisitDetails;
        convertVisitToForm(newData);
        $scope.createVisit();
    };

    $scope.editScheduledVisit = function () {

        if (!$scope.form.scheduledCanEdit) {
            return;
        }
        if ($scope.visitInProgress) {
            return
        };
        if (!$scope.form.id) {
            return
        };

        $scope.visitInProgress = true;

        const form = angular.copy($scope.form);

        // assigned coordinators
        const parsedAssignedCoordinators = [];
        form.assignedCoordinatorForm.forEach(function (coordinator) {
            parsedAssignedCoordinators.push(parseInt(coordinator.id));
        });
        form.assignedCoordinators = parsedAssignedCoordinators;
        if (form.assignedCoordinators.indexOf($rootScope.agencyMemberId) === -1) {
            form.assignedCoordinators.push($rootScope.agencyMemberId);
        }

        const scheduledVisitToEdit = {};

        for (const key in newVisit) {
            if (
                form.hasOwnProperty(key) &&
                (key === "requiredPatientDocuments" ||
                    key === "assignedCoordinators" ||
                    key === "planOfCareType")
            ) {
                scheduledVisitToEdit[key] = form[key];
            }
        }

        scheduledVisitToEdit.id = form.id;

        // For now only forms (docs) and assigned coordinator(s) are editable
        DatabaseApi.patch('agencies/' + $rootScope.agencyId + '/visits/' + scheduledVisitToEdit.id + '/scheduled_visit_edit', scheduledVisitToEdit).then(function (res) {

            $rootScope.popupWaiting = res.data;

            const text = scheduledVisitToEdit.id ? 'Visit has been edited' : '';

            $scope.visitInProgress = false;

            toaster.pop('success', "Success", text);
            window.scrollTo(0, 0);

            $state.go('app.visits.dashboard');

        }, function (err) {
            $scope.visitInProgress = false;
            toaster.pop('error', "Something Went Wrong", err.data);
            return window.scrollTo(0, 0);
        });

    }

    $scope.createVisit = function () {

        console.log($scope.form);

        if ($scope.visitInProgress) return;
        $scope.visitInProgress = true;

        var form = angular.copy($scope.form);

        // find banned words in general notes
        var isBanned = isGeneralDetailsHasBannedWords(form.generalNotes);
        if (isBanned) {
            SweetAlert.swal({
                title: "Error",
                text: "Patient address is not allowed in general notes",
                type: "warning",
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ok",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function () {
                var hasErrors = true;
            });
        }

        // attach treatment id
        if (!form.treatment) {
            form.treatment = $scope.treatment ? $scope.treatment.id : null;
        }

        // set start and end dates
        var startDate = convertDate(form.datePicker.date.startDate, 'reset');
        var endDate = convertDate(form.datePicker.date.endDate, 'reset');
        var hours = getLocalTimeFromLabels(form.advancedTimesData[0].startTime,
            form.advancedTimesData[0].endTime);
        var startHour = JSJoda.LocalTime.of(hours.start.hour, hours.start.minute).toString();
        var endHour = JSJoda.LocalTime.of(hours.end.hour, hours.end.minute).toString();

        // helpers for visit "type"
        var isFlexible, visitType, isRN;

        // check if rn visit
        if (form.isDuration &&
            (form.certifications.indexOf('RN') > -1 || form.certifications.indexOf('LPN') > -1)) {
            isRN = true;
        }
        // check for flexible visit
        if (form.flexibleDays && form.flexibleTimes) isFlexible = 'FlexibleTimeAndDays';
        else if (!form.flexibleDays && form.flexibleTimes) isFlexible = 'FlexibleTimeFixedDays';
        else if (form.flexibleDays && !form.flexibleTimes) isFlexible = 'FixedTimeFlexibleDays';

        // is rn single visit
        if (isSameDay(startDate, endDate) && isRN) {
            if (form.advancedTimesData.length === 1 &&
                isOverNightFromTimeLabel(form.advancedTimesData[0].startTime,
                    form.advancedTimesData[0].endTime)) {
                console.log('overnight');
                endDate = endDate.plusDays(1);
            }
            form.isSingleVisit = false;
        } else
            // is single visit
            if (isSameDay(startDate, endDate) && (!isRN || $scope.isAllDayHours)) {
                console.log('same');
                if (form.advancedTimesData.length === 1 &&
                    isOverNightFromTimeLabel(form.advancedTimesData[0].startTime,
                        form.advancedTimesData[0].endTime)) {
                    console.log('overnight');
                    endDate = endDate.plusDays(1);

                }

                console.log('is single');

                visitType = 'single';

                form.isSingleVisit = true;

                // is recurring visit
            } else {

                visitType = 'recurring'
                form.isSingleVisit = false;
            }

        // certification
        form.certification = form.certifications;
        if (!form.certification || form.certification.length < 1) {
            toaster.pop('error', "Missing Certification", 'Please select');
            $scope.visitInProgress = false;
            return;// window.scrollTo(0, 0);
        }

        // days
        var numberOfDays = 0;
        for (var i in form.daysOfTheWeek) {
            if (form.daysOfTheWeek[i]) {
                numberOfDays++;
            }
        }

        if (numberOfDays === 0 &&
            isFlexible !== 'FlexibleTimeAndDays' &&
            isFlexible !== 'FixedTimeFlexibleDays') {
            toaster.pop('error', "Missing days", 'Please select');
            $scope.visitInProgress = false;
            return;// window.scrollTo(0, 0);
        }

        // price
        form.price = form.priceDisplay ? Math.round(100 * form.priceDisplay) : null;
        if (form.price > Number.MAX_SAFE_INTEGER) {
            toaster.pop('error', "Price is too high", 'Please renter');
            $scope.visitInProgress = false;
            return;
        }
        if (form.price === 0) form.price = null;
        if (!form.price && $scope.isRequired('visitPriceRequired')) {
            toaster.pop('error', "Missing Price", 'Please enter');
            $scope.visitInProgress = false;
            return;// window.scrollTo(0, 0);
        }

        // gender
        if (form.genders.Male === form.genders.Female) form.gender = null;
        else if (form.genders.Male) form.gender = 'M';
        else form.gender = 'F';

        // languages
        form.languages = form.selectLanguages;
        if (!form.languages || form.languages.length < 1) {
            toaster.pop('error', "Missing Language", 'Please select');
            $scope.visitInProgress = false;
            return;// window.scrollTo(0, 0);
        }

        //address
        if (form.locationError || !form.patientAddress) {
            toaster.pop('error', "Incorrect address", 'Please enter valid address');
            $scope.visitInProgress = false;
            return;
        }

        // phones

        // normalize phone
        if (form.patientPhoneForm === undefined || form.patientPhoneForm === 'undefined' || !form.patientPhoneForm || form.patientPhoneForm.length < 10) {
            form.patientPhoneForm = null;
        }
        if (form.patientAlternativePhoneForm === undefined || form.patientAlternativePhoneForm === 'undefined' || !form.patientAlternativePhoneForm || form.patientAlternativePhoneForm.length < 10) {
            form.patientAlternativePhoneForm = null;
        }

        // add country code
        form.patientPhone = form.patientPhoneForm ? '+1' + form.patientPhoneForm : null;
        form.patientAlternativePhone = form.patientAlternativePhoneForm ? '+1' + form.patientAlternativePhoneForm : null;

        var isOk = true;

        // check if patient phone is mandatory/valid
        if ($scope.isRequired('patientPhoneRequired')) {

            if (!form.patientPhone) {
                isOk = false;
            } else {
                try {
                    isOk = phoneUtils.isValidNumber(form.patientPhone);
                } catch (e) {
                    isOk = false;
                }
            }
        } else if (form.patientPhone) {
            try {
                isOk = phoneUtils.isValidNumber(form.patientPhone);
            } catch (e) {
                isOk = false;
            }
        }
        if (!isOk) {
            toaster.pop('error', "Phone Number Invalid", 'Please Complete The Form');
            $scope.visitInProgress = false;
            return;
        }

        // check if patient alternative phone is mandatory/valid
        if ($scope.isRequired('visitPatientAlternativePhoneRequired')) {
            if (!form.patientAlternativePhone) {
                isOk = false;
            } else {
                try {
                    isOk = phoneUtils.isValidNumber(form.patientAlternativePhone);
                } catch (e) {
                    isOk = false;
                }
            }
        } else if (form.patientAlternativePhone) {
            try {
                isOk = phoneUtils.isValidNumber(form.patientAlternativePhone);
            } catch (e) {
                isOk = false;
            }
        }
        if (!isOk) {
            toaster.pop('error', "Phone Number Invalid", 'Please Complete The Form');
            $scope.visitInProgress = false;
            return;
        }

        // Shift validation
        if (form.shifts > 1) {
            var shiftsOverlaps = false;
            var overlapShiftsDays = [];

            for (var i in form.daysOfTheWeek) {
                if (form.daysOfTheWeek[i]) {
                    var dayIdx = daysMap[i];
                    var shiftIdx = form.advancedTimesData.length === 1 ? 0 : dayIdx;

                    if (shiftsOverlapping(form.advancedTimesData[shiftIdx], form.advancedTimesData2[shiftIdx])) {
                        overlapShiftsDays.unshift($scope.advancedDays[dayIdx].fullName);
                        shiftsOverlaps = true;
                    }
                }
            }

            if (shiftsOverlaps) {
                toaster.pop('warning', "Oops...", 'Shifts overlapse on ' + overlapShiftsDays.join(', '));
                $scope.visitInProgress = false;
                return;
            }
        }

        // broadcasting
        if ($scope.slider.value && form.distanceType.val === 'manual') {
            form.assignableCaregiverRadius = $scope.slider.value * 1609;
            if ($scope.slider.value < 1) {
                if ($rootScope.agencyId !== 49) {
                    form.assignableCaregiverRadius = 1609;
                }
            }
        } else {
            form.assignableCaregiverRadius = null;
        }

        // assigned coordinators
        var parsedAssignedCoordinators = [];
        form.assignedCoordinatorForm.forEach(function (coordinator) {
            parsedAssignedCoordinators.push(parseInt(coordinator.id));
        });
        form.assignedCoordinators = parsedAssignedCoordinators;
        if (form.assignedCoordinators.indexOf($rootScope.agencyMemberId) === -1) {
            form.assignedCoordinators.push($rootScope.agencyMemberId);
        }

        // if it's a flexible visit
        if (isFlexible) {

            console.log('flex');
            if (form.flexibleDays && !form.flexibleDaysCount) {
                toaster.pop('error', "Missing days per week", 'Please select');
                $scope.visitInProgress = false;
                return;// window.scrollTo(0, 0);
            }

            if (form.flexibleTimes && !form.flexibleTimesCount) {
                toaster.pop('error', "Missing hours per day", 'Please select');
                $scope.visitInProgress = false;
                return;// window.scrollTo(0, 0);
            }


            if (isFlexible === 'FlexibleTimeAndDays') {
                form.flexibleVisitParams = $scope.flexibleVisitParamsTypes.FlexibleTimeAndDays;

                form.flexibleVisitParams.numberOfDays = form.flexibleDaysCount;
                form.flexibleVisitParams.hoursPerDay = parseInt(form.flexibleTimesCount.split(':')[0]) +
                    (parseInt(form.flexibleTimesCount.split(':')[1]) / 60);

                startHour = '00:00';
                endHour = '23:59';

            } else if (isFlexible === 'FlexibleTimeFixedDays') {
                form.flexibleVisitParams = $scope.flexibleVisitParamsTypes.FlexibleTimeFixedDays;

                form.flexibleVisitParams.hoursPerDay = parseInt(form.flexibleTimesCount.split(':')[0]) +
                    (parseInt(form.flexibleTimesCount.split(':')[1]) / 60);
                form.flexibleVisitParams.days = [];
                for (var i in form.daysOfTheWeek) {
                    if (form.daysOfTheWeek[i]) {
                        form.flexibleVisitParams.days.push(daysMap[i.toLowerCase()]);
                    }
                }

                startHour = '00:00';
                endHour = '23:59';

            } else if (isFlexible === 'FixedTimeFlexibleDays') {
                form.flexibleVisitParams = $scope.flexibleVisitParamsTypes.FixedTimeFlexibleDays;

                form.flexibleVisitParams.numberOfDays = form.flexibleDaysCount;
                form.flexibleVisitParams.startTime = startHour;
                form.flexibleVisitParams.endTime = endHour;
            }

            // if it's a recurring visit, and is not splitShift
        } else {
            form.dayTimeParams = { dayTimes: [] };

            for (var key in form.daysOfTheWeek) {
                if (form.daysOfTheWeek[key]) {

                    var dayIndex, dayHours;

                    if (form.advancedTimesData.length > 1) {
                        dayIndex = daysMap[key];
                    } else {
                        dayIndex = 0;
                    }

                    dayHours = getLocalTimeFromLabels(form.advancedTimesData[dayIndex].startTime,
                        form.advancedTimesData[dayIndex].endTime);
                    form.dayTimeParams.dayTimes.push({
                        day: daysMap[key],
                        startTime: JSJoda.LocalTime.of(dayHours.start.hour, dayHours.start.minute).toString(),
                        endTime: JSJoda.LocalTime.of(dayHours.end.hour, dayHours.end.minute).toString()
                    });

                    if (form.shifts > 1) {
                        dayHours = getLocalTimeFromLabels(form.advancedTimesData2[dayIndex].startTime,
                            form.advancedTimesData2[dayIndex].endTime);
                        form.dayTimeParams.dayTimes.push({
                            day: daysMap[key],
                            startTime: JSJoda.LocalTime.of(dayHours.start.hour, dayHours.start.minute).toString(),
                            endTime: JSJoda.LocalTime.of(dayHours.end.hour, dayHours.end.minute).toString()
                        });
                    }
                }
            }

            console.log(form.dayTimeParams);

            if (visitType === 'recurring' && form.advancedTimesData.length > 1) {

                var startTemp = new Date(startDate.toString());
                var endTemp = new Date(endDate.toString());

                var startDay = getFirstLastDayOfRange('first', startTemp, form.daysOfTheWeek);
                var endDay = getFirstLastDayOfRange('last', endTemp, form.daysOfTheWeek);

                if (!startDay || !endDay) {
                    toaster.pop('error', "Invalid dates", 'Please Complete The Form');
                    $scope.visitInProgress = false;
                    return;
                }

                form.advancedTimesData.forEach(function (day, index) {
                    var hours;
                    if (day.day === startDay.day) {
                        hours = getLocalTimeFromLabels(day.startTime, day.endTime);
                        startHour = JSJoda.LocalTime.of(hours.start.hour, hours.start.minute).toString();
                    }
                    if (day.day === endDay.day) {
                        hours = getLocalTimeFromLabels(day.startTime, day.endTime);
                        endHour = JSJoda.LocalTime.of(hours.end.hour, hours.end.minute).toString();

                        if (form.shifts > 1) {
                            var day2 = form.advancedTimesData2[index];

                            hours = getLocalTimeFromLabels(day2.startTime, day2.endTime);
                            endHour = JSJoda.LocalTime.of(hours.end.hour, hours.end.minute).toString();
                        }
                    }
                });

            }

        }

        if (isRN) {

            if (form.rnVisitParams) {
                form.rnVisitParams.duration = form.visitDuration;
                form.rnVisitParams.isFlexible = form.rnVisitParams.isFlexible;
                form.rnVisitParams.contactPatient = form.contactPatient;
            } else {
                form.rnVisitParams = {
                    duration: form.visitDuration,
                    isFlexible: true,
                    contactPatient: form.contactPatient
                };
            }

            form.isPreAssigned = true;

            if (form.labels.indexOf("Initial Rn Assessment") > -1 ||
                form.labels.indexOf("Re-Assessment") > -1 ||
                form.labels.indexOf("UAS") > -1) {
                form.isAssessment = true;
            }

            // talk with nir
            var rnVisitDurationOk = isRnVisitDurationOk(form.rnVisitParams.duration);

            if (!rnVisitDurationOk) {
                toaster.pop('error', "Invalid time", 'Not enough time for visit');
                $scope.visitInProgress = false;
                return;
            }

        } else {
            form.rnVisitParams = null;
        }


        // if it's split shift visit
        if (form.splitShiftParams !== null) {
            form.dayTimeParams = { dayTimes: [] };
            form.splitShiftParams.availableShifts = [];

            for (var key in form.daysOfTheWeek) {
                if (form.daysOfTheWeek[key] === true) {
                    form.dayTimeParams.dayTimes.push({
                        day: daysMap[key],
                        endTime: form.splitShiftParams.shiftChangeTime.plusHours(12).toString(),
                        startTime: form.splitShiftParams.shiftChangeTime.toString()
                    })
                    insertSplitShiftAvailableShifts(form, $scope.fullDaysMap[key].toUpperCase());
                }
            }

            if (!form.splitShiftParams.minShiftsPerCaregiver) {
                toaster.pop('error', "Missing minimum shifts number", 'Please select');
                $scope.visitInProgress = false;
                return;
            }

            if (!form.splitShiftParams.maxShiftsPerCaregiver) {
                toaster.pop('error', "Missing maximum shifts number", 'Please select');
                $scope.visitInProgress = false;
                return;
            }
        }


        // labels
        form.visitLabels = [];
        form.labels.forEach((label) => {
            const filteredLabel = $scope.labels.filter(x => x.id === label.id)[0]
            if (filteredLabel !== undefined) {
                form.visitLabels.push({ text: filteredLabel.name, color: filteredLabel.color });
            }
        });

        //  // labels
        //  form.visitLabels = [];
        //  form.labels.forEach(function (label) {
        //      form.visitLabels.push({ text: label, color: '' });
        //  });


        startDate = startDate.withHour(parseInt(startHour.split(':')[0]));
        startDate = startDate.withMinute(parseInt(startHour.split(':')[1]));

        endDate = endDate.withHour(parseInt(endHour.split(':')[0]));
        endDate = endDate.withMinute(parseInt(endHour.split(':')[1]));

        form.startTime = startDate.toString();
        form.endTime = endDate.toString();


        // hide end date
        if (visitType === 'single') {
            form.hideEndDateFromCaregiver = false;
        }


        // patient id
        if (!form.id) {
            form.patientId = $scope.patient.id;
        }

        var visitToCreateOrEdit = {};

        for (var key in newVisit) {
            if (form.hasOwnProperty(key)) visitToCreateOrEdit[key] = form[key]
        }

        visitToCreateOrEdit.id = form.id;

        console.log(JSON.stringify(visitToCreateOrEdit, false, 2));


        //return;

        // create / edit visit
        if (form.id) {

            if (visitToCreateOrEdit.broadcastToRNTypes) {
                if (!visitToCreateOrEdit.broadcastToRNTypes.length)
                    delete visitToCreateOrEdit.broadcastToRNTypes;

            } else {
                delete visitToCreateOrEdit.broadcastToRNTypes;

            }

            DatabaseApi.patch('agencies/' + $rootScope.agencyId + '/visits/' + visitToCreateOrEdit.id, visitToCreateOrEdit).then(function (res) {

                $rootScope.popupWaiting = res.data;

                var text = visitToCreateOrEdit.id ? 'Visit has been edited' : 'New visit has been created';

                $scope.visitInProgress = false;

                toaster.pop('success', "Success", text);
                window.scrollTo(0, 0);

                $state.go('app.visits.dashboard');

            }, function (err) {
                $scope.visitInProgress = false;
                toaster.pop('error', "Something Went Wrong", err.data);
                return window.scrollTo(0, 0);
            });

        } else {


            var route = 'visits';
            visitToCreateOrEdit.isGradual = false;
            delete visitToCreateOrEdit.broadcastToRNTypes;

            if (form.distanceType.val === 'auto' && !$scope.isRNVisit()) {
                route = 'gradual_visits';
                visitToCreateOrEdit.isGradual = true;
            }

            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/' + route, visitToCreateOrEdit).then(function (res) {

                res.data.isPreAssigned = visitToCreateOrEdit.isPreAssigned;
                $rootScope.popupWaiting = res.data;


                var text = 'New visit has been created';
                var analyticsEvent = 'new-visit';

                var visitType = [];

                if (!form.isSingleVisit) visitType.push('recurring');
                else visitType.push('single');

                if (form.flexibleVisitParams) visitType.push('flexible');
                if (form.rnVisitParams) visitType.push('rn');

                var analyticsData = {
                    visitType: visitType,
                    price: form.priceDisplay,
                    assignedCaregiver: form.assignedCaregiver,
                    radius: form.assignableCaregiverRadius,
                    languages: form.languages,
                    startTime: form.startTime,
                    endTime: form.endTime
                };
                Analytics.event(analyticsEvent, analyticsData);

                $scope.visitInProgress = false;
                toaster.pop('success', "Success", text);
                window.scrollTo(0, 0);
                $state.go('app.visits.dashboard');

            }, function (err) {
                $scope.visitInProgress = false;
                let errorMessage = err.data;
                if (err.data.length) {
                    if (err.data.find(e => (e.dataPath === ".startTime" && e.message === "must be greater than today")) !== undefined) {
                        errorMessage = "The visit start time should be later than the current time";
                    }
                }
                toaster.pop('error', "Something Went Wrong", errorMessage);
                return window.scrollTo(0, 0);
            });

        }


    };

    function insertSplitShiftAvailableShifts(form, dayName) {

        form.splitShiftParams.availableShifts.push({
            dayOfWeek: dayName,
            shiftNum: 0
        });
        form.splitShiftParams.availableShifts.push({
            dayOfWeek: dayName,
            shiftNum: 1
        });
    }

    $scope.autoFill = function () {
        var date = new Date();
        date.setSeconds(0);
        date.setMilliseconds(0);
        var minutes = date.getMinutes();
        if (minutes < 15) date.setMinutes(15);
        else if (minutes <= 30) date.setMinutes(30);
        else if (minutes <= 45) date.setMinutes(45);
        else if (minutes <= 59) {
            date.setMinutes(0);
            date.setHours(date.getHours() + 1);
        }

        date.setHours(date.getHours() + 3);

        var end = new Date(date);
        end = end.setHours(end.getHours() + 1);
        end = new Date(end);

        var date = {
            startDate: new Date(date),
            endDate: new Date(end)
        };

        $scope.form.datePicker.date = date;

        $scope.form.advancedTimesData[0].startTime = getHourLabelFromHour(date.startDate.toISOString());
        $scope.form.advancedTimesData[0].endTime = getHourLabelFromHour(date.endDate.toISOString());

        $scope.form.advancedTimesData2[0].startTime = getHourLabelFromHour(date.startDate.toISOString());
        $scope.form.advancedTimesData2[0].endTime = getHourLabelFromHour(date.endDate.toISOString());

        $scope.form.priceDisplay = parseFloat((Math.random() * 100).toFixed(2));
        //$scope.form.patientName = 'Auto ' + parseInt((Math.random() * 100));
        $scope.genders = {};
        $scope.form.selectLanguages = ['English', 'Spanish'];
        $scope.form.labels = [$scope.visitLabels[0], $scope.visitLabels[4], $scope.visitLabels[5]];
        $scope.form.certifications = ['HHA'];
        $scope.isSkilled = false;
        /*$scope.form.patientAddress = {"address_components":[{"long_name":"456","short_name":"456","types":["street_number"]},{"long_name":"Gates Avenue","short_name":"Gates Ave","types":["route"]},{"long_name":"Bedford-Stuyvesant","short_name":"Bedford-Stuyvesant","types":["neighborhood","political"]},{"long_name":"Brooklyn","short_name":"Brooklyn","types":["sublocality_level_1","sublocality","political"]},{"long_name":"Kings County","short_name":"Kings County","types":["administrative_area_level_2","political"]},{"long_name":"New York","short_name":"NY","types":["administrative_area_level_1","political"]},{"long_name":"United States","short_name":"US","types":["country","political"]},{"long_name":"11216","short_name":"11216","types":["postal_code"]}],"adr_address":"<span class=\"street-address\">456 Gates Ave</span>, <span class=\"locality\">Brooklyn</span>, <span class=\"region\">NY</span> <span class=\"postal-code\">11216</span>, <span class=\"country-name\">USA</span>","formatted_address":"456 Gates Ave, Brooklyn, NY 11216, USA","geometry":{"location":{"lat": function(){ return 40.68649619999999; },"lng": function(){ return -73.94804069999998; }},"viewport":{"south":40.6851542697085,"west":-73.94939108029149,"north":40.6878522302915,"east":-73.94669311970847}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png","id":"8754e401ee0a2510f736a6f73cc159e3d8d6b916","name":"456 Gates Ave","place_id":"EiY0NTYgR2F0ZXMgQXZlLCBCcm9va2x5biwgTlkgMTEyMTYsIFVTQQ","reference":"CpQBhAAAAHHJ_apaeKHtFO4MeOnNQfCisLhxR93AMbLyMZDOI6RN6Bk8cTBAQEzzxczw0r9rD4w5oHtRDgkyPZlRy_hx6ZmzX2AUXduadcLe8E4srTqk7lWkJYbptpzXWW6wQ1Z6ues_BCzXJWgb2SUuFd35vq_euGWbI5wLGJnT5Y_CxyaPPLecflXrnMDUOB72pwr7xxIQAcXA33GteTyz8hutTlMIohoUuEyLl-p_qVZYypLfE5RiZjOdUjY","scope":"GOOGLE","types":["street_address"],"url":"https://maps.google.com/?q=456+Gates+Ave,+Brooklyn,+NY+11216,+USA&ftid=0x89c25b9218c6bb7b:0x73ea36f8135875ab","utc_offset":-300,"vicinity":"Brooklyn","html_attributions":[]};
        $scope.locationChange();*/
        $scope.form.patientPhoneForm = '2125551234';
        $scope.form.patientAlternativePhoneForm = '2125551234';
        //$scope.form.patientAddress2 = '2nd floor';
    };

    attachAssignedCaregiverToForm();

    var addressRegex = new RegExp(/[\d]+[a-z\|#\|A-Z]|[a-z\|#\|A-Z]+[\d]|#|\brd\b|\broad\b|\bway\b|\bst\b|\bstr\b|\bstreet\b|\bave\b|\bav\b|\bavenue\b|\bBoulevard\b|\bblvd\b|\blane\b|\bln\b|\bterrace\b|\bTer\b|\bCourt\b|\bct\b|\bdrive\b|\bdr\b|\bapt\b|\bapartment\b|\bap\b|\bfl\b|\bflr\b|\bfloor\b/ig);

    function isGeneralDetailsHasBannedWords(data) {

        var isFound = data.match(addressRegex);
        return isFound
    }

    function debounce(cb) {
        var timeout = null;
        return function (data) {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
                cb(data);
            }, 500);
        };
    }

    /*
      function scrollToInvalid() {
            // Height of your nav bar plus a bottom margin
            var navHeight = 300;
            // Offset of the first input element minus your nav height
            var invalid_el = $('input:invalid').first().offset().top - navHeight;

            // If the invalid element is already within the window view, return true. If you return false, the validation will stop.
            /!*if ( invalid_el > (window.pageYOffset - navHeight) && invalid_el < (window.pageYOffset + window.innerHeight - navHeight) ) {
             return true;
             } else {*!/
            // If the first invalid input is not within the current view, scroll to it.
            $('html, body').scrollTop(invalid_el);
            //  }
        }

      $('input').on('invalid', scrollToInvalid);*/

    /**
       * Converts a ISO 8601 UTC timestamp according to the current browser's timezone
       * @param date {string} ISO 8601 UTC timestamp
       * @returns {JSJoda.LocalDateTime}
       */
    function convertDate(date, reset) {
        var d = new Date(date);
        var jodaDate;
        if (reset) {
            jodaDate = JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), 0, 0, 0, 0);

            jodaDate = jodaDate.withHour(0);
            jodaDate = jodaDate.withMinute(0);
            jodaDate = jodaDate.withSecond(0);
            jodaDate = jodaDate.withNano(0);

            console.log(jodaDate);
        } else {
            jodaDate = JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds() * 1000000);

        }
        return jodaDate;
    }

    function isSameDay(start, end) {
        if (start.year() !== end.year()) return false;
        if (start.monthValue() !== end.monthValue()) return false;
        if (start.dayOfMonth() !== end.dayOfMonth()) return false;
        return true;
    }

    function shiftsOverlapping(shift1, shift2) {
        var shift1end = getRelativeTimeFromLabels(shift1.startTime, shift1.endTime).end;
        var shift2start = getRelativeTimeFromLabels(shift2.startTime, shift2.endTime).start;

        // Checking overlap betweeen shifts
        return (
            parseInt([shift1end.hour, shift1end.minute].join('')) >=
            parseInt([shift2start.hour, shift2start.minute].join(''))
        );
    }

    function isOverNightFromTimeLabel(start, end) {
        console.log(start, end);

        var startTimeAMPM = start.split(' ')[1];
        var startTimeHour = parseInt(start.split(' ')[0].split(':')[0]);
        var startTimeMinutes = parseInt(start.split(' ')[0].split(':')[1]);
        var endTimeAMPM = end.split(' ')[1];
        var endTimeHour = parseInt(end.split(' ')[0].split(':')[0]);
        var endTimeMinutes = parseInt(end.split(' ')[0].split(':')[1]);

        if (startTimeHour === 12 && startTimeAMPM === 'AM') startTimeHour = 0;
        else if (startTimeHour !== 12 && startTimeAMPM === 'PM') startTimeHour += 12;
        if (endTimeHour === 12 && endTimeAMPM === 'AM') endTimeHour = 0;
        else if (endTimeHour !== 12 && endTimeAMPM === 'PM') endTimeHour += 12;

        var startTime = JSJoda.LocalTime.of(startTimeHour, startTimeMinutes);
        var endTime = JSJoda.LocalTime.of(endTimeHour, endTimeMinutes);

        console.log(startTime, endTime);
        return endTime.isBefore(startTime) || endTime.compareTo(startTime) === 0;
    }

    function getRelativeTimeFromLabels(start, end) {
        var localTime = getLocalTimeFromLabels(start, end);
        var startTimeAMPM = start.split(' ')[1];
        var endTimeAMPM = end.split(' ')[1];

        if ((startTimeAMPM === 'PM' && endTimeAMPM === 'AM') ||
            (startTimeAMPM === endTimeAMPM &&
                (localTime.start.hour > localTime.end.hour ||
                    (localTime.start.hour === localTime.end.hour &&
                        localTime.start.minute > localTime.end.minute
                    )
                )
            )
        ) {
            localTime.end.hour += 24;
        }

        return localTime;
    }

    function getLocalTimeFromLabels(start, end) {
        console.log(start, end);

        console.log(end.split(' ')[1]);
        console.log(end.split(' ')[0].split(':')[0]);
        console.log(end.split(' ')[0].split(':')[1]);

        var startTimeAMPM = start.split(' ')[1];
        var startTimeHour = parseInt(start.split(' ')[0].split(':')[0]);
        var startTimeMinutes = parseInt(start.split(' ')[0].split(':')[1]);

        var endTimeAMPM = end.split(' ')[1];
        var endTimeHour = parseInt(end.split(' ')[0].split(':')[0]);
        var endTimeMinutes = parseInt(end.split(' ')[0].split(':')[1]);

        console.log(startTimeHour, endTimeHour);

        if (startTimeHour === 12 && startTimeAMPM === 'AM') startTimeHour = 0;
        else if (startTimeHour !== 12 && startTimeAMPM === 'PM') startTimeHour += 12;
        if (endTimeHour === 12 && endTimeAMPM === 'AM') endTimeHour = 0;
        else if (endTimeHour !== 12 && endTimeAMPM === 'PM') endTimeHour += 12;

        var data = {
            start: { hour: startTimeHour, minute: startTimeMinutes },
            end: { hour: endTimeHour, minute: endTimeMinutes }
        };
        console.log(data);

        return data;
    }

    function getHourLabelFromHour(date) {
        console.log(date);

        date = date.split('T')[1];

        var hour = parseInt(date.split(':')[0]);
        var minute = parseInt(date.split(':')[1]);
        if (minute < 10) minute = '0' + minute;

        var ampm = 'AM';

        if (hour >= 12) {
            ampm = 'PM';
            hour -= 12;
        }
        if (hour === 0) hour = 12;

        return hour + ':' + minute + ' ' + ampm;
    }

    function getHourLabelFromHourString(date) {

        var hour = parseInt(date.split(':')[0]);
        var minute = parseInt(date.split(':')[1]);
        if (minute < 10) minute = '0' + minute;

        var ampm = 'AM';

        if (hour >= 12) {
            ampm = 'PM';
            hour -= 12;
        }
        if (hour === 0) hour = 12;

        return hour + ':' + minute + ' ' + ampm;
    }

    /**
       * @param {{lat: number, lng:number}} location
       * @param {(err: Error | null, timezone: string | undefined) => void} callback
       */
    function lookupLocationTimezone(location, callback) {
        // We don't care about a specific timestamp, but the API requires one,
        // so we use an arbitrary value
        var timestamp = Math.floor((new Date()).getTime() / 1000);

        var url = 'https://maps.googleapis.com/maps/api/timezone/json';
        url += '?location=' + location.lat + ',' + location.lng;
        url += '&timestamp=' + timestamp;
        url += '&key=' + GOOGLE_MAPS_API_KEY;

        // Use jQuery ajax rather than angular $http because we need a clean request without any custom headers
        $.ajax(url)
            .done(function (result) {
                var timezone = result.timeZoneId;
                if (!timezone) {
                    callback(new Error("Unknown Error"));
                    return;
                }
                callback(null, timezone);
            })
            .fail(function (err) {
                callback(new Error(err));
            });
    }

    function getFirstLastDayOfRange(firstLast, date, days) {

        var availableDays = [];
        for (var key in days) {
            if ($scope.form.daysOfTheWeek[key]) {
                availableDays.push(daysMap[key]);
            }
        }
        //console.log(availableDays);
        var d = moment(date);
        var action = firstLast === 'first' ? 'add' : 'subtract';

        for (var i = 0; i < 7; i++) {
            var dayOfWeek = d.day();
            if (availableDays.indexOf(dayOfWeek) > -1) return { day: dayOfWeek, offset: i };
            d[action](1, 'd');
        }

        return false;

    }

    $scope.$watch('map.center', function () {
        if ($scope.map && $scope.map.center) {
            mapChange();
        }
    }, true);

    var map = null, marker = null;

    function mapChange() {
        if (map) {
            map.setView(
                [$scope.map.center.latitude, $scope.map.center.longitude],
                $scope.map.zoom
            );
            marker.setLatLng([$scope.marker.coords.latitude, $scope.marker.coords.longitude]);

        } else {
            map = L.map('map', {
                center: [$scope.map.center.latitude, $scope.map.center.longitude],
                zoom: $scope.map.zoom
            });
            L.tileLayer('https://d5nz7zext4ylq.cloudfront.net/{z}/{x}/{y}.png', {
                tileSize: 512,
                zoomOffset: -1,
                minZoom: 5,
                attribution: '',
                crossOrigin: true
            }).addTo(map);
            if ($scope.marker && $scope.marker.coords) {
                marker = L.marker(
                    [$scope.marker.coords.latitude, $scope.marker.coords.longitude]
                ).addTo(map);
            }

        }
    }
    function attachAssignedCaregiverToForm() {
        var caregiversMap = DatabaseApi.caregivers() || {};
        if ($scope.form.assignedCaregiver) {
            $scope.form.assignedCaregiver = caregiversMap[$scope.form.assignedCaregiver.id.toString()];
        }
    }


    function initNewVisit() {

        //return;
        // copy all the elements from a new visit template
        for (var key in newVisit) {
            $scope.form[key] = newVisit[key];
        }

        // get saved assigned coordinators
        var savedCoordinators = Storage.getObject('savedAssignedCoordinators'); // only ids
        if (!savedCoordinators || !savedCoordinators.list) savedCoordinators = { list: [] };
        var assigned = [];
        $scope.coordinatorsForSelection.forEach(function (coordinator) {
            if (coordinator.id === $rootScope.agencyMemberId && !savedCoordinators.list.length) {
                assigned.push(coordinator);
            } else if (savedCoordinators.list.indexOf(coordinator.id) > -1) {
                assigned.push(coordinator);
            }
        });

        // add form data
        $scope.form.assignedCoordinatorForm = assigned;
        $scope.form.treatment = $scope.treatment.id;
        $scope.form.patientGender = $scope.patient.gender;
        $scope.form.patientAddress2 = $scope.patient.address2;
        $scope.form.patientName = $scope.patient.firstName + ' ' + $scope.patient.lastName;
        $scope.form.generalNotes = $scope.patient.generalNotes || '';
        $scope.form.caseDetails = $scope.patient.medicalNotes || '';
        $scope.form.gender = $scope.patient.preferredHHAGender;
        if (!$scope.form.gender) $scope.form.genderForm = 'both';
        else $scope.form.genderForm = $scope.form.gender === 'M' ? 'Male' : 'Female';

        var langs = [];
        if ($scope.patient.mainLanguage) langs.push($scope.patient.mainLanguage);
        if ($scope.patient.secondaryLanguage && $scope.patient.mainLanguage !== $scope.patient.secondaryLanguage) langs.push($scope.patient.secondaryLanguage);
        if (langs.length) $scope.toggleLanguageSelection(langs);

        const phoneNumbers = $scope.patient.phoneNumbers;
        const homePhone = phoneNumbers.find(phone => phone.type === "TEL");
        $scope.form.patientPhoneForm = homePhone !== undefined ?
            homePhone.phonenumber : null;

        const mobilePhone = phoneNumbers.find(phone => phone.type === "MOBILE");
        $scope.form.patientAlternativePhoneForm = mobilePhone !== undefined ?
            mobilePhone.phonenumber : null;
        
        setVisitAddress($scope.patient.address)
        $scope.newGoogleAddressComponents = $scope.patient.address.text
        // indication when form is ready
        $scope.formIsReady = true;
    }

    $scope.locationChange = async function () {
        try {
            const newAddress = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode($scope.newGoogleAddressComponents);
            setVisitAddress({
                components: newAddress,
                text: newAddress.formatedAddressDetails.fullAddress
            });
        }
        catch (e) {
            toaster.pop('error', 'Invalid address: ' + e.message);
        }
    }

    function setVisitAddress(newAddress) {
        $scope.form.locationError = false;
        if (newAddress.components && newAddress.components.location) {
            $scope.form.patientAddressComponents = newAddress.components;
            var map = {
                latitude: newAddress.components.location.lat,
                longitude: newAddress.components.location.lng
            };
            $scope.map.center = map;
            $scope.marker.coords = map;
            $scope.form.patientAddressLoc = {
                lat: $scope.map.center.latitude,
                lng: $scope.map.center.longitude
            };

            $scope.form.addressGeoLocation = {
                lat: $scope.map.center.latitude,
                lng: $scope.map.center.longitude
            };

            if (newAddress.components.formatedAddressDetails) {
                $scope.form.city = newAddress.components.formatedAddressDetails.city
            }
            else if (newAddress.components.neighborhood) {
                $scope.form.city = newAddress.components.neighborhood
            }
            else if (newAddress.components.sublocalityLevel1) {
                $scope.form.city = newAddress.components.sublocalityLevel1
            }
            else if (newAddress.components.locality) {
                $scope.form.city = newAddress.components.locality
            }
            else {
                $scope.form.city = '';
            }

            $scope.form.timezone = !$scope.patient.timezone ? 'America/New_York' : $scope.patient.timezone;
            $scope.form.zip = newAddress.components.formatedAddressDetails ? newAddress.components.formatedAddressDetails.zip : '';
            $scope.form.state = newAddress.components.formatedAddressDetails
                ? newAddress.components.formatedAddressDetails.state
                : newAddress.components.administrativeAreaLevel1;
            $scope.form.patientAddress = newAddress.text;
            mapChange()
            if (
                !$scope.form.state
                || !$scope.form.city
                || !$scope.form.timezone
                || !$scope.form.patientAddress) {
                $scope.form.locationError = true;
            }
        }
        else {
            $scope.form.locationError = true;
        }
    }

    function isRnVisitDurationOk(duration) {
        // check that duration is not above max input - 300 max
        if ($scope.isAllDayHours) {
            return duration < 300;
        }

        var start, end;
        var idx = 0;
        var allDurationsValid = $scope.form.advancedTimesData.every(function (day, index) {

            while ((!start || !end) && idx < $scope.times.length) {
                if (day.startTime === $scope.times[idx].label) {
                    start = $scope.times[idx].value;
                }
                if (day.endTime === $scope.times[idx].label) {
                    end = $scope.times[idx].value;
                }
                idx++;
            }

            if (!start || !end) return false;

            start = JSJoda.LocalTime.parse(start);
            end = JSJoda.LocalTime.parse(end);
            var difMinutes = start.until(end, JSJoda.ChronoUnit.MINUTES);

            if (difMinutes < 0) {
                difMinutes = 1440 + difMinutes;
            }

            start = null;
            end = null;
            idx = 0;
            if (difMinutes >= duration) {
                return true;
            }
        });

        return allDurationsValid;
    }


    function convertVisitToForm(visitData) {
        visitToForm = angular.copy(visitData);

        $scope.form = Object.assign($scope.form, visitToForm);

        $scope.form.createdByName = DatabaseApi.getAgencyMembers()[$scope.form.createdBy.id];
        $scope.form.status = VisitStatus.getVisitStatus($scope.form);

        var assignedCoordinators = [];
        $scope.form.assignedCoordinators.forEach(function (id) {
            const assignedCoordinator = $scope.coordinatorsForSelection.find(
                function (coordinator) {
                    return id === coordinator.id;
                }
            );

            if (assignedCoordinator === undefined) return;

            assignedCoordinators.push(assignedCoordinator);
        });

        $scope.form.assignedCoordinatorForm = assignedCoordinators;

        attachAssignedCaregiverToForm();

        if ($scope.form.assignableCaregiverRadius) {
            var radius = parseInt($scope.form.assignableCaregiverRadius / 1609);
            $scope.slider.value = radius;
        }


        // broadcastOptions
        // distanceType
        $scope.form.distanceType.val = $scope.form.isGradual ? 'auto' : 'manual';

        // certification
        $scope.form.certifications = $scope.form.certification;
        $scope.isSkilled = $scope.form.certification.some(function (cert) {
            return cert === 'RN' || cert === 'LPN';
        });

        $scope.filterLabels($scope.form.certifications);
        $scope.filterForms($scope.form.certifications, []);

        // date and time
        // check if need to go one day back
        // is single visit
        //$scope.form.endTime = goBackOneDayIfNeeded($scope.form);

        var date = {
            startDate: new Date($scope.form.startTime),
            endDate: new Date($scope.form.endTime)
        };

        $scope.form.datePicker.date = date;

        // labels
        $scope.form.labels = [];
        if (!$scope.form.visitLabels) $scope.form.visitLabels = [];

        $scope.form.visitLabels.forEach(function (label) {
            const filteredLabel = $scope.displaydLabels.filter(x => x.name === label.text)[0];
            if (filteredLabel !== undefined) {
                $scope.toggleLabelsSelection({ id: filteredLabel.id, name: filteredLabel.name });
            }
        });
        $scope.filterForms($scope.form.certifications, $scope.form.labels);

        // price
        $scope.form.priceDisplay = $scope.form.price ? $scope.form.price / 100 : undefined;
        $scope.form.priceType = $scope.form.paymentType ? $scope.form.paymentType : 'Hourly';

        // gender
        if (!$scope.form.gender) $scope.form.genders = { Male: true, Female: true };
        else $scope.form.genders = $scope.form.gender === 'M' ? { Male: true } : { Female: true };

        // languages
        $scope.form.selectLanguages = $scope.form.languages;

        //address
        var map = {
            latitude: $scope.form.patientAddressLoc.lat,
            longitude: $scope.form.patientAddressLoc.lng
        };
        if (!$scope.map) $scope.map = {};
        $scope.map.center = map;
        $scope.marker.coords = map;

        // phones
        if ($scope.form.patientPhone) $scope.form.patientPhoneForm = $scope.form.patientPhone.slice(2);
        if ($scope.form.patientAlternativePhone) $scope.form.patientAlternativePhoneForm = $scope.form.patientAlternativePhone.slice(2);


        // if this is flexible visit which is assigned - remove the flexible details
        if ($scope.form.dayTimeParams && $scope.form.flexibleVisitParams && $scope.form.assignedCaregiver) {
            $scope.form.flexibleVisitParams = null;

        }

        // if visit has splitShiftParams
        if ($scope.form.splitShiftParams) {
            var startDate = moment($scope.form.datePicker.date.startDate);
            $scope.form.splitShiftParams.shiftChangeTime = JSJoda.LocalTime.parse(startDate.format("hh:mm"));
        }

        if ($scope.form.flexibleVisitParams) {
            if ($scope.form.flexibleVisitParams.type === 'FlexibleTimeAndDays') {

                $scope.form.flexibleDays = true;
                $scope.form.flexibleDaysCount = $scope.form.flexibleVisitParams.numberOfDays;

                $scope.form.flexibleTimes = true;
                var hours = Math.floor($scope.form.flexibleVisitParams.hoursPerDay);
                var minutes = ($scope.form.flexibleVisitParams.hoursPerDay - hours) * 60;
                if (minutes === 0) minutes = '00';
                $scope.form.flexibleTimesCount = hours + ':' + minutes;

            } else if ($scope.form.flexibleVisitParams.type === 'FlexibleTimeFixedDays') {

                $scope.form.daysOfTheWeek = {};
                $scope.form.flexibleVisitParams.days.forEach(function (day) {
                    var dayName = $scope.advancedDays[day].name;
                    $scope.form.daysOfTheWeek[dayName] = true;
                });

                $scope.form.flexibleTimes = true;
                var hours = Math.floor($scope.form.flexibleVisitParams.hoursPerDay);
                var minutes = ($scope.form.flexibleVisitParams.hoursPerDay - hours) * 60;
                if (minutes === 0) minutes = '00';
                $scope.form.flexibleTimesCount = hours + ':' + minutes;

            } else if ($scope.form.flexibleVisitParams.type === 'FixedTimeFlexibleDays') {

                $scope.form.flexibleDays = true;
                $scope.form.flexibleDaysCount = $scope.form.flexibleVisitParams.numberOfDays;
                $scope.form.advancedTimesData[0].startTime = $scope.times.find(function (t) { return t.value === $scope.form.startTime.slice($scope.form.startTime.length - 5, $scope.form.startTime.length); }).label;
                $scope.form.advancedTimesData[0].endTime = $scope.times.find(function (t) { return t.value === $scope.form.endTime.slice($scope.form.endTime.length - 5, $scope.form.endTime.length); }).label;
            }

        }

        if ($scope.form.rnVisitParams) {
            $scope.form.isDuration = $scope.form.rnVisitParams.isFlexible;
            $scope.form.visitDuration = $scope.form.rnVisitParams.duration;
            $scope.form.contactPatient = $scope.form.rnVisitParams.contactPatient;
            $scope.form.rnVisitType = 'flexible';
        } else {
            $scope.form.rnVisitType = 'fixed';
        }

        if ($scope.form.dayTimeParams) {
            var shiftsByDay = $scope.form.dayTimeParams.dayTimes.reduce(function (acc, shift) {
                if (shift.day in acc === false) {
                    acc[shift.day] = [];
                    acc[shift.day].push(shift);
                } else {
                    shift.startTime > acc[shift.day][0].startTime ? acc[shift.day].push(shift) : acc[shift.day].unshift(shift);
                }

                return acc;
            }, {});

            if ($scope.form.isSingleVisit) {
                var shift, shift2;

                for (var i in shiftsByDay) {
                    $scope.form.advancedTimesData[0] = {
                        day: i
                    };
                    $scope.form.advancedTimesData2[0] = {
                        day: i
                    };

                    shift = shiftsByDay[i][0];
                    shift2 = shiftsByDay[i][1];

                    if (shift) {
                        $scope.form.advancedTimesData[0].startTime = getHourLabelFromHourString(shift.startTime);
                        $scope.form.advancedTimesData[0].endTime = getHourLabelFromHourString(shift.endTime);
                    }

                    if (shift2) {
                        $scope.form.advancedTimesData2[0].startTime = getHourLabelFromHourString(shift2.startTime);
                        $scope.form.advancedTimesData2[0].endTime = getHourLabelFromHourString(shift2.endTime);
                    }
                }
            } else {
                var sameStart = true;
                var sameEnd = true;

                var start, end, dayShift, start2, end2, dayShift2;

                for (var i = 0; i < 7; i++) {
                    if (!shiftsByDay[i]) continue;

                    dayShift = shiftsByDay[i][0];

                    if (!start) start = dayShift.startTime;
                    if (!end) end = dayShift.endTime;

                    if (start !== dayShift.startTime) sameStart = false;
                    if (end !== dayShift.endTime) sameEnd = false;

                    if (shiftsByDay[i].length > 1) {
                        dayShift2 = shiftsByDay[i][1];

                        if (!start2) start2 = dayShift2.startTime;
                        if (!end2) end2 = dayShift2.endTime;

                        if (start2 !== dayShift2.startTime) sameStart = false;
                        if (end2 !== dayShift2.endTime) sameEnd = false;
                    }

                    /* Currently not in use
                    $scope.advancedDays[shift.day].startTime = shift.startTime;
                    $scope.advancedDays[shift.day].endTime = shift.endTime;
                    */
                }

                if (!sameStart || !sameEnd) $scope.form.advancedTimes = true;

                if ($scope.form.advancedTimes) {
                    var shift, shift2;

                    var defaultShift = $scope.form.advancedTimesData[0];
                    var defaultShift2 = $scope.form.advancedTimesData2[0];

                    for (var i = 0; i < 7; i++) {
                        var dayDone = false;

                        $scope.form.advancedTimesData[i] = {
                            day: i
                        };
                        $scope.form.advancedTimesData2[i] = {
                            day: i
                        };

                        console.log($scope.form.dayTimeParams.dayTimes);

                        if (i in shiftsByDay) {
                            shift = shiftsByDay[i][0];
                            shift2 = shiftsByDay[i][1];

                            if (shift) {
                                $scope.form.advancedTimesData[i].startTime = getHourLabelFromHourString(shift.startTime);
                                $scope.form.advancedTimesData[i].endTime = getHourLabelFromHourString(shift.endTime);
                            }

                            if (shift2) {
                                $scope.form.advancedTimesData2[i].startTime = getHourLabelFromHourString(shift2.startTime);
                                $scope.form.advancedTimesData2[i].endTime = getHourLabelFromHourString(shift2.endTime);
                            }

                            dayDone = true;
                        }

                        if (!dayDone) {
                            if ($scope.form.shifts === 1) {
                                $scope.form.advancedTimesData[i].startTime = getHourLabelFromHour($scope.form.startTime);
                                $scope.form.advancedTimesData[i].endTime = getHourLabelFromHour($scope.form.endTime);
                            } else {
                                $scope.form.advancedTimesData[i].startTime = defaultShift.startTime;
                                $scope.form.advancedTimesData[i].endTime = defaultShift.endTime;

                                $scope.form.advancedTimesData2[i].startTime = defaultShift2.startTime;
                                $scope.form.advancedTimesData2[i].endTime = defaultShift2.endTime;
                            }
                        }
                    }
                } else {
                    var shift, shift2;

                    for (var i in shiftsByDay) {
                        shift = shiftsByDay[i][0];
                        shift2 = shiftsByDay[i][1];

                        if (shift) {
                            $scope.form.advancedTimesData[0].startTime = getHourLabelFromHourString(shift.startTime);
                            $scope.form.advancedTimesData[0].endTime = getHourLabelFromHourString(shift.endTime);
                        }

                        if (shift2) {
                            $scope.form.advancedTimesData2[0].startTime = getHourLabelFromHourString(shift2.startTime);
                            $scope.form.advancedTimesData2[0].endTime = getHourLabelFromHourString(shift2.endTime);
                        }

                        if (shift2 || shift) break;
                    }
                }
            }

            $scope.form.daysOfTheWeek = {};

            $scope.form.dayTimeParams.dayTimes.forEach(function (day) {
                var dayName = $scope.advancedDays[day.day].name;
                $scope.form.daysOfTheWeek[dayName] = true;
            });

            // shifts
            if (shiftsByDay) {
                var shiftsByDayValues = Object.values(shiftsByDay);
                $scope.form.shifts = shiftsByDayValues.length > 0 ? shiftsByDayValues[0].length : 1
            }
        } else {
            // shifts
            $scope.form.shifts = 1;
        }

        if ($scope.form.status === 'pending' || $scope.form.status === 'waiting' ||
            $scope.form.status === 'changes' || $scope.form.status === 'unstaffed') {
            $scope.form.canEdit = true;
        }

        // Future thoughts: Should we add `&& $scope.form.assignedCaregiver` here?
        if ($scope.form.status === 'scheduled') {
            $scope.form.scheduledCanEdit = true;
            if ($scope.slider !== undefined && $scope.slider.options !== undefined) {
                $scope.slider.options.readOnly = true;
            }
        }

        oldVisitForm = angular.copy($scope.form);
        console.log(oldVisitForm);

        // indication when form is ready
        $scope.formIsReady = true;
        getVisitInstances($scope.form.id);
    }


    $scope.$watch('form.generalNotes', debounce(function (data) {
        if ($scope.form && $scope.form.generalNotes) {
            var isBanned = isGeneralDetailsHasBannedWords($scope.form.generalNotes);

            if (isBanned) {
                SweetAlert.swal({
                    title: "Error",
                    text: "Patient address is not allowed in general notes",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ok",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function () {
                });
            }
        }

    }, true));


    // $scope.setSKilled(true)

    $scope.setSelectedTimeSpanType = function (val) {
        $scope.selectedTimeSpanType = val;
    }

    $scope.setIsAllDayHours = function (val) {
        $scope.isAllDayHours = val;
    }

    function getVisitInstances(visitId) {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/visits/' + visitId + '/visit_instances').then(function (res) {
            $scope.visitInstances = res.data.visitInstances;
        }, function () {
            toaster.pop('error', 'Something went wrong');
        });
    }

    $scope.cancelVisit = function () {
        if ($scope.visitInstances.length > 1) {
            $scope.cancelVisitModal = $uibModal.open({
                templateUrl: "admin/views/visit-cancel-modal.html",
                size: "lg",
                controller: "visitCancelModal",
                windowTopClass: "visit-cancel-modal",
                resolve: {
                    visit: () => $scope.form,
                    visitInstances: () => $scope.visitInstances,
                    onRemoveAllClicked: () => (visit) => {
                        $scope.showCancelReasonModal(visit);
                    },
                    onRemoveOccurrence: () => (visit, visitInstance) => {
                        cancelVisitInstance(visit, visitInstance);
                    },
                },
            });
        } else {
            $scope.showCancelReasonModal($scope.form);
        }
    }

    $scope.showCancelReasonModal = (visit) => {
        $scope.cancelReasonModal = $uibModal.open({
            templateUrl: "admin/views/visit-cancel-reason-modal.html",
            size: "lg",
            controller: "visitCancelReasonModal",
            windowTopClass: "visit-cancel-reason-modal",
            resolve: {
                visit: () => visit,
                onCancel: () => (visit, text) => {
                    deleteVisit(visit, text);
                },
            },
        });
    }

    function deleteVisit(visit, text) {
        var url = 'agencies/' + $rootScope.agencyId + '/visits/' + visit.id + '/cancel';

        var body = { reason: text ? 'Other - ' + text : 'Wasn\'t assigned by Medflyt' };
        DatabaseApi.put(url, body).then(function (res) {
            visit.assignedCaregiver = undefined;
            toaster.pop('success', "Success", 'Visit was canceled');

            Analytics.event('visit-modal-canceled', {
                visitId: visit.id,
                status: 'scheduled',
                reason: text ? 'Other - ' + text : 'Wasn\'t assigned by Medflyt',
                accepted: visit.acceptedBy.length
            });
            $scope.cancelReasonModal.close();
            $state.go('app.visits.dashboard', {}, { reload: true });

        }, function (err) {
            toaster.pop('error', "Something went wrong", err.data);

        });
    };


    function cancelVisitInstance(visit, visitInstance) {
        var url =
            "agencies/" + $rootScope.agencyId + "/visits/" + visit.id + "/cancel_visit_instance";

        var body = { visitInstanceId: visitInstance.id };

        DatabaseApi.put(url, body).then(
            function (res) {
                toaster.pop("success", "Success", "Visit was canceled");

                // $scope.cancelVisitModal.close();
            },
            function (err) {
                toaster.pop("error", "Something went wrong", err.data);
            }
        );
    }

    async function init() {
        await DatabaseApi.loadAgencyMembers();
        $scope.coordinatorsForSelection = DatabaseApi.getAllAgencyMembersArr();

        // multiple assigned coordinators selector
        $scope.multipleAssignedCoordinatorsData = angular
            .copy($scope.coordinatorsForSelection)
            .map((coord) => {
                const disabled = coord.status !== "Active";
                coord.disabled = disabled;
                coord.label = `${coord.label}${disabled ? " (Inactive)" : ""}`;
                return coord;
            }).filter((agencyMember) => {
                if (!$scope.patient.currentOfficeId && $scope.patient.currentOfficeId !== 0) {
                    return agencyMember;
                }
                return agencyMember.officeIds.includes($scope.patient.currentOfficeId);
            });
        
        $scope.multipleAssignedCoordinatorsOptions = {
            styleActive: true,
            scrollable: true,
            scrollableHeight: "180px",
            enableSearch: true,
        };
        $scope.multipleAssignedCoordinatorsEvents = {
            onSelectionChanged: function () {
                var ids = [];
                for (
                    var i = $scope.form.assignedCoordinatorForm.length - 1;
                    i >= 0;
                    i--
                ) {
                    var value = $scope.form.assignedCoordinatorForm[i];

                    if (!value.id || ids.indexOf(value.id) > -1) {
                        $scope.form.assignedCoordinatorForm.splice(i, 1);
                    } else {
                        ids.push(value.id);
                    }

                    if (!value.label) {
                        $scope.coordinatorsForSelection.forEach(function (c) {
                            if (c.id === value.id) $scope.form.assignedCoordinatorForm[i] = c;
                        });
                    }
                }

                Storage.setObject("savedAssignedCoordinators", { list: ids }); // only ids
            },
        };

        // is visit new or not
        if ($stateParams.id) {
            if (!visit) {
                $http
                    .get(
                        Consts.api +
                        "agencies/" +
                        $rootScope.agencyId +
                        "/coordinator/" +
                        $rootScope.agencyMemberId +
                        "/visits/" +
                        $stateParams.id
                    )
                    .then(function (v) {
                        // visit.dayTimeParams = visit.recurringVisitParams;
                        convertVisitToForm(v.data);
                        if ($rootScope.popupWaitingForSingle === true) {
                            $rootScope.popupWaitingForSingle = undefined;
                            const visit = $scope.form
                            $rootScope.openVisitModal({ visit });
                        }
                    });
            } else {
                if (visit.config && visit.data) {
                    // visit.dayTimeParams = visit.recurringVisitParams;

                    convertVisitToForm(visit.data);
                } else convertVisitToForm(visit);
            }
        } else {
            initNewVisit();
        }
    }
});
