'use strict'
app.controller('caregiverPtoLabelSetupModalCtrl', function ($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService, mfModal) {
    $scope.fields = FormService.getFields(fields, data);

    $scope.form = {};
    if (DatabaseApi.entitiesInitializeMap()["caregiverPtoLabels"]) {
        $scope.agencyCaregiverPtoLabels = DatabaseApi.caregiverPtoLabels();
    } else {
        DatabaseApi.getPtoLabels();
    }

    $scope.fieldChanged = (item) => {
        if (item.type.type === "Boolean" && data.id) {
            $scope.submitForm();
        }
    }

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid) return;

        const body = FormService.prepareFormData($scope.fields);

        if (data.id) {
            delete body.text;
            DatabaseApi.put(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregiver_pto_labels/${data.id}`, body)
                .then((res) => {
                    toaster.pop('success', "Collection status saved");
                    $scope.closeModal();
                }, (err) => {
                    toaster.pop('error', "Something went wrong", "Could not change PTO type")
                });
        } else {
            if (!$scope.agencyCaregiverPtoLabels) {
                toaster.pop("error", "Something went wrong", "Please try again");
                return;
            }
            const lowerCaseNewLabelText = body.text.toLowerCase();
            if ($scope.agencyCaregiverPtoLabels.find(label => label.text.toLowerCase() === lowerCaseNewLabelText)) {
                mfModal.createSimple({
                    variant: "danger",
                    subject: "Could not create PTO type",
                    message: `"${body.text}" is already in use. Please try a different text.`,
                    confirmLabel: "Okay",
                });
                return;
            }
            DatabaseApi.post(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregiver_pto_labels`, body).then((res) => {
                toaster.pop('success', "Collection status saved");
                $scope.closeModal();
            }, (err) => {
                toaster.pop('error', "Something went wrong", "could not create PTO type");
            });
        }
    };

    $rootScope.$on("got_pto_labels", () => {
        $scope.agencyCaregiverPtoLabels = DatabaseApi.caregiverPtoLabels();
    });

    $scope.closeModal = () => {
        $uibModalInstance.close('ok');
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

});
