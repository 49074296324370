/**
 * ORIGINAL FILE = missedVisitSideBar.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
"use strict";
class MissedVisitSideBarCtrl {
    missedVisitService;
    toaster;
    $rootScope;
    $filter;
    noteConsts;
    entityNoteService;
    entityNewVisitModalService;
    VisitInstanceModalService;
    generalUtils;
    changeState;
    selectedVisits;
    patientId;
    editShiftsParams;
    isLoading;
    isNoteRequired;
    modalState;
    visitsPreview = [];
    noteValidations;
    message;
    isMissedVisitSideModalOpen;
    constructor(missedVisitService, toaster, $rootScope, $filter, noteConsts, entityNoteService, entityNewVisitModalService, VisitInstanceModalService, generalUtils) {
        this.missedVisitService = missedVisitService;
        this.toaster = toaster;
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.noteConsts = noteConsts;
        this.entityNoteService = entityNoteService;
        this.entityNewVisitModalService = entityNewVisitModalService;
        this.VisitInstanceModalService = VisitInstanceModalService;
        this.generalUtils = generalUtils;
        this.isNoteRequired = this.entityNoteService.isEntityNoteRequired(this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
        this.noteValidations = {
            isPredefinedValid: undefined,
            isMessageValid: undefined
        };
        this.modalState = {
            selectedOption: { id: undefined },
            missedVisitNote: {
                status: "",
                noteType: "missed_visit_instance",
            },
        };
        this.isLoading = false;
        this.message = "";
        this.isMissedVisitSideModalOpen = true;
        this.selectedVisits = this.entityNewVisitModalService.missedVisitsData.selectedVisits;
        this.patientId = this.entityNewVisitModalService.missedVisitsData.patientId;
        this.editShiftsParams = this.entityNewVisitModalService.missedVisitsData.editShiftsParams;
        this.entityNewVisitModalService.registerObserverCallback("visits", "missedVisitSideBar", this.getSelectedVisits);
        this.updateVisitsPreview();
    }
    loadItems = () => {
        this.isLoading = true;
        const body = this.getMissedVisitsBody(this.selectedVisits);
        this.missedVisitService.missVisits(body).then((res) => {
            const skippedVisitsData = res.skippedVisitsAndMissingPermissions;
            if (!skippedVisitsData) {
                this.toaster.pop("success", "Selected visits succesfully missed");
            }
            else {
                this.VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: skippedVisitsData,
                    updatedVisitsAmount: res.visitInstances.length,
                    action: "update"
                });
            }
            this.$rootScope.$emit("refresh_visits");
            this.closeParentModal();
            this.generalUtils.scrollToElement('scroll-calendar');
        }, () => {
            this.toaster.pop("error", "Something went wrong", "Cannot set selected visits as missed");
        }).finally(() => {
            this.isLoading = false;
        });
    };
    getSelectedVisits = () => {
        this.selectedVisits = this.entityNewVisitModalService.selectedItems.visits;
        if (this.isMissedVisitSideModalOpen &&
            this.selectedVisits.length === 0) {
            this.closeParentModal();
        }
        else {
            this.updateVisitsPreview();
        }
    };
    updateVisitsPreview = () => {
        this.visitsPreview = this.selectedVisits.map((visit, index) => {
            const startDateAndTime = this.$filter("mfShortTime")(visit.startTime, ['withDate']);
            const endTime = this.$filter("mfShortTime")(visit.endTime);
            return `${index + 1}. ${startDateAndTime} - ${endTime}`;
        });
        // If more than one visit selected adjust the message accordingly
        this.message = this.selectedVisits.length === 1 ? `Are you sure you want to miss the selected visit?` :
            `Are you sure you want to miss the following ${this.selectedVisits.length} selected visits?`;
    };
    getMissedVisitsBody = (visits) => {
        const ids = visits.map((visit) => visit.visitInstanceId);
        const changes = {
            "missedVisit": true,
            "missedVisitNote": this.entityNoteService.buildEntityNoteRequest(this.modalState.missedVisitNote, this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE),
            "manualHoldInvoicingNotes": null,
            "travelTimeSeconds": 0
        };
        const body = {
            ids,
            changes
        };
        return body;
    };
    closeParentModal = () => {
        this.changeState(undefined);
        this.isMissedVisitSideModalOpen = false;
        this.$rootScope.$emit("close_missed_visit_modal");
    };
    onClickMiss = () => {
        const { isNoteValid, isPredefinedValid, isMessageValid } = this.entityNoteService.validateEntityNote(this.modalState.missedVisitNote, this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
        if (!isNoteValid) {
            this.noteValidations = { isPredefinedValid, isMessageValid };
            return;
        }
        this.loadItems();
    };
    handleNoteChanges = (updatedNote) => {
        this.modalState.missedVisitNote = updatedNote;
    };
    $onDestroy = () => {
        this.entityNewVisitModalService.unregisterObserverCallback("visits", "MissedVisitSideBarCtrl");
    };
}
const MissedVisitSideBar = {
    controller: MissedVisitSideBarCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/missed-visit-side-bar.html",
    bindings: {
        changeState: "&"
    },
};
angular
    .module("dashboard")
    .component("missedVisitSideBar", MissedVisitSideBar);
export {};
