app.service('VisitStatus', function () { 
    this.getVisitStatus = (visit) => {

        if (visit.assignedAt || visit.assignedCaregiver || visit.splitShiftFullyAssigned === true) {
            return 'scheduled';
        }
        
        const now = new Date().getTime();
        const start = moment.tz(visit.startTime, visit.timezone).toDate().getTime();

        if (now > start) {
            return 'unstaffed';
        }

        if (visit.acceptedBy && visit.acceptedBy.length) {
            return 'waiting';
        }

        return 'pending';
    }
});
