app.controller('visitSuggestModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, visit, DatabaseApi, toaster, Socket, SweetAlert, $timeout, $interval){

    window.scrollTo(0,0);

    $scope.activeTime = 0;
    var loadingTextInterval = [
      'Finding best matches',
      'Analyzing matches',
      'Matching cases',
      'Constructing results'
    ];
    $scope.loadingText = loadingTextInterval[0];
    $scope.isLoading = true;

    $interval(function () {
        var idx = loadingTextInterval.indexOf($scope.loadingText);
        if(idx < loadingTextInterval.length - 1){
            $scope.loadingText = loadingTextInterval[idx + 1];
        } else {
            $scope.loadingText = loadingTextInterval[0];
        }
    },2000);

    //console.log('modal');


   /* visit.startTime = '2018-01-07 09:00:00';
    visit.coords = { latitude: 40.5925013, longitude: -73.92872740000001 };
    visit.patientName = 'John Doe';
    visit.address = '123 address, NYC 12345';
    visit.dayTimeParams = {"dayTimes": [{"day": 0, "name": "sun", "endTime": "17:00", "startTime": "13:00"}, {"day": 6, "name": "sat", "endTime": "17:00", "startTime": "13:00"}], "daysOfWeek": ["Sun", "Sat"], "daysPerWeek": 2, "sessionHours": null};
    visit.startTime = '2018-01-07 09:00:00';
    visit.endTime = '2018-01-07 10:00:00';*/

   console.log(visit);
    $scope.visit = visit;
    $scope.visit.startTimeD = new Date(visit.startTime);
    $scope.visit.endTimeD = new Date(visit.endTime);

    $scope.visit.coords = { latitude: $scope.visit.patientAddressLoc.lat , longitude: $scope.visit.patientAddressLoc.lng };
    $scope.visit.center = { latitude: $scope.visit.patientAddressLoc.lat , longitude: $scope.visit.patientAddressLoc.lng };


    console.log('ccc', $scope.visit.coords);

    function convertDate(date) {
        var d = new Date(date);
        return JSJoda.LocalDateTime.of(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds() * 1000000);
    }

    $scope.closeModal = function(){
        $uibModalInstance.close();
    };

    var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

    $scope.getDay = function(num){
        return days[num];
    };

    $scope.pickTime = function(idx){
        $scope.activeTime = idx;
        $scope.activeTimeData = $scope.suggestions[idx];
    };

    $scope.pickTimeAndBroadcast = function(){
        //$scope.activeTime = idx;
        //$scope.activeTimeData = $scope.suggestions[idx];

        var t = $scope.activeTimeData;


        console.log(t);

        /*if(t.timeDelta >= 0) {
            if(!$scope.visit.isSingleVisit){
                $scope.visit.dayTimeParams.dayTimes.forEach(function (day) {

                    var start = JSJoda.LocalTime.parse(day.startTime);
                    var end = JSJoda.LocalTime.parse(day.endTime);
                    day.startTime = start.plusMinutes(t.timeDelta).toString();
                    day.endTime = end.plusMinutes(t.timeDelta).toString();

                });
            }
            $scope.visit.startTime = moment($scope.visit.startTime).add(t.timeDelta, 'minutes');
            $scope.visit.endTime = moment($scope.visit.endTime).add(t.timeDelta, 'minutes');
            $scope.visit.startTime = convertDate($scope.visit.startTime).toString();
            $scope.visit.endTime = convertDate($scope.visit.endTime).toString();
        } else {
            if(!$scope.visit.isSingleVisit){
                $scope.visit.dayTimeParams.dayTimes.forEach(function (day) {

                    var start = JSJoda.LocalTime.parse(day.startTime);
                    var end = JSJoda.LocalTime.parse(day.endTime);
                    day.startTime = start.minusMinutes(t.timeDelta).toString();
                    day.endTime = end.minusMinutes(t.timeDelta).toString();
                });
            }
            $scope.visit.startTime = moment($scope.visit.startTime).subtract(t.timeDelta * -1, 'minutes');
            $scope.visit.endTime = moment($scope.visit.endTime).subtract(t.timeDelta * -1, 'minutes');
            $scope.visit.startTime = convertDate($scope.visit.startTime).toString();
            $scope.visit.endTime = convertDate($scope.visit.endTime).toString();
        }
*/
        console.log($scope.visit);
        $scope.isSending = true;
        $timeout(function () {
            //$uibModalInstance.close($scope.visit);
            console.log('====', t.timeDelta);
            $uibModalInstance.close({time:t.timeDelta});
        }, 200);

    };

    if($scope.visit.isPreAssigned){
        $scope.visit.isPreAssigned = true;
    } else {
        $scope.visit.isPreAssigned = false;
    }

    DatabaseApi.post('agencies/' + $rootScope.agencyId + '/visit_recommendations' , $scope.visit).then(function (res) {

        //var data = ;

        start(res.data.recommendations);




    }, function (err) {
        $scope.isLoading = false;
        toaster.pop('error','Something went wrong');
    });


    function start(data) {

        // data.pop();
        // data.pop();
        // data.shift();
        // data.shift();



        console.log(data);

        /*data.splice(6, 0, {
            timeDela: 0,
            chancesDifference: 0,
            base: true
        });*/

        data.sort(function (a,b) {
            return a.timeDelta - b.timeDelta;
        });

        data[6].base = true;
        data[6].selected = true;
        data[6].chancesDifference = 0;

        var max, maxIdx = 0;

        var baseAvailableClusterCases = data[6].availableClusterCases.length;
        var baseAssignedClusterCases = data[6].assignedClusterCases.length;



        // adjust chances by count of potential matches
        data.forEach(function (t) {
            var availableClusterCases = t.availableClusterCases.length - baseAvailableClusterCases;
            var assignedClusterCases = t.assignedClusterCases.length - baseAssignedClusterCases;

            t.chancesDifference += ( 1 * (availableClusterCases + assignedClusterCases) / 100 );
        });

        data.forEach(function (t,i) {
            console.log(i, t.chancesDifference, max, maxIdx);
            if((!max && max !== 0 ) || t.chancesDifference > max) {
                max = t.chancesDifference;
                maxIdx = i;
            }
            if(t.timeDelta >= 0) {
                if (!$scope.visit.isSingleVisit){
                    var start = JSJoda.LocalTime.parse($scope.visit.dayTimeParams.dayTimes[0].startTime);
                    //start.plusMinutes(t.timeDelta).toString();
                    t.time = start.plusMinutes(t.timeDelta).toString();
                    t.time += t.time >= '12:00' ? ' PM' : ' AM';
                   // {"dayTimes": [{"day": 0, "name": "sun", "endTime": "17:00",
                } else {
                    var start = angular.copy($scope.visit.startTime);
                    t.time = start.plusMinutes(t.timeDelta).toString().split('T')[1];
                    t.time += t.time >= '12:00' ? ' PM' : ' AM';
                    //t.time = moment($scope.visit.startTime).add(t.timeDelta, 'minutes');//.format('LT');
                    console.log($scope.visit.startTime, t.time);
                }
            } else {

                if (!$scope.visit.isSingleVisit){
                    var start = JSJoda.LocalTime.parse($scope.visit.dayTimeParams.dayTimes[0].startTime);
                    //start.minusMinutes(t.timeDelta * -1).toString();
                    t.time = start.minusMinutes(t.timeDelta * -1).toString();
                    t.time += t.time >= '12:00' ? ' PM' : ' AM';
                    // {"dayTimes": [{"day": 0, "name": "sun", "endTime": "17:00",
                } else {
                    //console.log($scope.visit.startTime, t.timeDelta);
                    var start = angular.copy($scope.visit.startTime);
                    t.time = start.minusMinutes(t.timeDelta * -1).toString().split('T')[1];
                    t.time += t.time >= '12:00' ? ' PM' : ' AM';
                    //t.time = moment($scope.visit.startTime).subtract(t.timeDelta * -1, 'minutes');//.format('LT');
                    console.log($scope.visit.startTime, t.time);
                    //console.log(t.time);
                }
                //  t.time = moment($scope.visit.startTime).subtract(t.timeDelta * -1, 'minutes').format('LT');
            }

        });

        data[maxIdx].max = true;

        $scope.suggestions = data;

        $scope.pickTime(maxIdx);
        $scope.isLoading = false;

    }

    $scope.chooseDiff = function(diff){
        $uibModalInstance.close(diff);
    };




    var one = {
        timeDelta: -90,
        chancesDifference: 0,
        availableClusterCases: [
            {
                visitId: 1,
                patientName: 'aaa',
                dayTimeParams: {"dayTimes": [{"day": 0, "name": "sun", "endTime": "17:00", "startTime": "13:00"}, {"day": 6, "name": "sat", "endTime": "17:00", "startTime": "13:00"}], "daysOfWeek": ["Sun", "Sat"], "daysPerWeek": 2, "sessionHours": null},
                startTime: '2018-01-07 09:00:00',
                endTime: '2018-01-07 10:00:00',
                geo: {"lat": 40.5925013, "lng": -73.92872740000001},
                address: 'dsd sdvsd'
            },
            {
                visitId: 2,
                patientName: 'bbb',
                dayTimeParams: null,
                startTime: '2018-01-07 09:00:00',
                endTime: '2018-01-07 10:00:00',
                geo: {"lat": 40.5925013, "lng": -73.92872740000001},
                address: 'dsd sdvsd'
            }
        ],
        assignedClusterCases: [
            {
                case: {
                    visitId: 3,
                    patientName: 'ccc',
                    dayTimeParams: {"dayTimes": [{"day": 0, "name": "sun", "endTime": "17:00", "startTime": "13:00"}, {"day": 6, "name": "sat", "endTime": "17:00", "startTime": "13:00"}], "daysOfWeek": ["Sun", "Sat"], "daysPerWeek": 2, "sessionHours": null},
                    startTime: '2018-01-07 09:00:00',
                    endTime: '2018-01-07 10:00:00',
                    geo: {"lat": 40.5925013, "lng": -73.92872740000001},
                    address: 'dsd sdvsd'
                },
                caregiverId: 2
            }
        ]
    };

    var dd = {
        recommendations: [

        ]
    };

    for(var i = 0 ; i < 13 ; i++){
        var a = angular.copy(one);
        a.timeDelta += i * 15;
        a.chancesDifference = Math.random()  > 0.5 ? Math.random() : Math.random() * -1;
        a.availableClusterCases[0].geo.lat += Math.random() / 100;
        a.availableClusterCases[0].geo.lng -= Math.random() / 100;
        a.availableClusterCases[1].geo.lat += Math.random() / 100;
        a.availableClusterCases[1].geo.lng -= Math.random() / 100;

        a.availableClusterCases[0].center = { latitude: a.availableClusterCases[0].geo.lat, longitude: a.availableClusterCases[0].geo.lng };
        a.availableClusterCases[0].coords = { latitude: a.availableClusterCases[0].geo.lat, longitude: a.availableClusterCases[0].geo.lng };
        a.availableClusterCases[1].center = { latitude: a.availableClusterCases[1].geo.lat, longitude: a.availableClusterCases[1].geo.lng };
        a.availableClusterCases[1].coords = { latitude: a.availableClusterCases[1].geo.lat, longitude: a.availableClusterCases[1].geo.lng };


        a.assignedClusterCases[0].case.geo.lat += Math.random() / 100;
        a.assignedClusterCases[0].case.geo.lng -= Math.random() / 100;

        a.assignedClusterCases[0].case.center = { latitude: a.assignedClusterCases[0].case.geo.lat, longitude: a.assignedClusterCases[0].case.geo.lng };
        a.assignedClusterCases[0].case.coords = { latitude: a.assignedClusterCases[0].case.geo.lat, longitude: a.assignedClusterCases[0].case.geo.lng };

        a.assignedClusterCases[0].caregiver = getCoordinatorById(a.assignedClusterCases[0].caregiverId);



        dd.recommendations.push(a);
    }
    //start(dd.recommendations);


    function getCoordinatorById(id){
        return {
            displayName: 'Julia Adams',
            photoUrl: ''
        };
    }

});


//var end = JSJoda.LocalTime.parse('23:00');
//console.log(end.plusMinutes(90).toString());

