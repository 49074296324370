"use strict";
angular.module("dashboard").component("entityDeleteVisitComponent", {
    templateUrl: "admin/views/entity-delete-visit-component.html",
    controller: function (
        $rootScope,
        $scope,
        DatabaseApi,
        toaster,
        wildcard,
        $filter,
        VisitInstanceModalService,
        noteConsts,
        entityNoteService,
        entityNewVisitModalService,
        generalUtils
    ) {
        const initialize = () => {
            $rootScope.isDeleteVisitSideModalOpen = true;
            $scope.selectedVisits = entityNewVisitModalService.deleteVisitsData.selectedVisits;
            $scope.patientId = entityNewVisitModalService.deleteVisitsData.patientId;
            $scope.editShiftsParams = entityNewVisitModalService.deleteVisitsData.editShiftsParams;

            entityNewVisitModalService.registerObserverCallback(
                "visits",
                "entityDeleteVisitComponent",
                getSelectedVisits
            );

            $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_MANUAL_REMOVE);
            $scope.modalState = {
                selectedOption: { id: undefined },
                manualRemoveNote: {},
            }

            const visitsPreview = $scope.selectedVisits.map((visit, index) => {
                const startDateAndTime = $filter("mfShortTime")(visit.startTime, ['withDate']);
                const endTime = $filter("mfShortTime")(visit.endTime);
                return `${index + 1}. ${startDateAndTime} - ${endTime}`;
            });

            updateVisitsPreview();

            $scope.closeParentModal = () => {
                $scope.$parent.$ctrl.changeState(undefined);
                $rootScope.isDeleteVisitSideModalOpen = false;
                $rootScope.$emit("close_delete_visit_modal");
            };

            $scope.onClickDelete = () => {
                const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
                    $scope.modalState.manualRemoveNote,
                    noteConsts.NoteTypes.VISIT_MANUAL_REMOVE
                );
                if (!isNoteValid) {
                    $scope.noteValidations = { isPredefinedValid, isMessageValid };
                    return;
                }

                loadItems();
            }

            $scope.handleNoteChanges = (updatedNote) => {
                $scope.modalState.manualRemoveNote = updatedNote;
            };
        }

        const loadItems = () => {
            $scope.isLoading = true;

            const body = getDeleteVisitsBody($scope.editShiftsParams.type, $scope.selectedVisits);

            const deleteVisitsUrl = wildcard(
                "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits",
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                $scope.patientId
            );

            DatabaseApi.patch(deleteVisitsUrl, body).then((res) => {
                const skippedVisitsData = res.data.skippedVisitsAndMissingPermissions;
                if (!skippedVisitsData) {
                    toaster.pop("success", "Selected visits succesfully deleted");
                } else {
                    VisitInstanceModalService.showSkippedVisitsErrorModal({
                        skippedVisitsAndMissingPermissions: skippedVisitsData,
                        updatedVisitsAmount: res.data.deletedVisitInstanceIds.length,
                        action: "delete"
                    });
                }
                $rootScope.$emit("refresh_visits");
                $scope.closeParentModal();
                generalUtils.scrollToElement('scroll-calendar');
            }, () => {
                toaster.pop("error", "Something went wrong", "Can't delete selected visits");
            }).finally(() => {
                $scope.isLoading = false;
            });
        }

        const getSelectedVisits = () => {
            $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
            if (
                $rootScope.isDeleteVisitSideModalOpen &&
                $scope.selectedVisits.length === 0
            ) {
                $scope.closeParentModal();
            }
            else {
                updateVisitsPreview();
            }
        }

        const updateVisitsPreview = () => {
            $scope.visitsPreview = $scope.selectedVisits.map((visit, index) => {
                const startDateAndTime = $filter("mfShortTime")(visit.startTime, ['withDate']);
                const endTime = $filter("mfShortTime")(visit.endTime);
                return `${index + 1}. ${startDateAndTime} - ${endTime}`;
            });

            // If more than one visit selected adjust the message accordingly
            $scope.message = $scope.selectedVisits.length === 1 ? `Are you sure you want to delete the selected visit?` :
                `Are you sure you want to delete the following ${$scope.selectedVisits.length} selected visits?`;
        }

        const getDeleteVisitsBody = (deleteType, visits) => {
            const body = {
                removeMode: deleteType === "CURRENT_SHIFTS" ? "SingleVisits" : "WeeklyPattern",
                manualRemoveNote: entityNoteService.buildEntityNoteRequest(
                    $scope.modalState.manualRemoveNote,
                    noteConsts.NoteTypes.VISIT_MANUAL_REMOVE,
                ),
                visitInstanceIds: visits.map(visit => visit.visitInstanceId),
                shouldUpdateWeeklyTemplate: [
                    "ENTIRE_SERIES",
                    "GOING_FORWARD",
                    "CHOSEN_DAYS"
                ].includes(deleteType)
            };

            const handledDeleteTypes = [
                "CURRENT_SHIFTS",
                "GOING_FORWARD",
                "UNTIL_DATE",
                "ENTIRE_SERIES",
                "CHOSEN_DAYS",
            ];

            if (!handledDeleteTypes.includes(deleteType)) {
                toaster.pop("warning", "Something went wrong", "Unhandled Delete Mode");
                return;
            }

            return body;
        }

        this.$onDestroy = () => {
            entityNewVisitModalService.unregisterObserverCallback("visits", "entityDeleteVisitComponent");
        }

        initialize();
    }
});