'use strict'
app.controller("hhaIntegrationBillingCtrl", function (
    $scope,
    $rootScope,
    $filter,
    NgTableParams,
    hhaxIntegrationService,
    DatabaseApi,
    toaster,
    selectionLogic,
    billingInvoicesProcessor
) {
   $scope.offices = DatabaseApi.offices().map((office) => ({
        id: office.id,
        label: office.name,
    }));
    DatabaseApi.contractTypes().filter(b => b.eBillingProvider === 'HHAExchange')

    $scope.contractTypes = DatabaseApi.contractTypes().
            filter(b => b.eBillingProvider === 'HHAExchange').
        map((contractType) => ({
            id: contractType.id,
            label: contractType.name,
        }));
    $scope.serviceCodesMap = new Map(DatabaseApi.activeServiceCodes().map(serviceCode => [serviceCode.id, serviceCode.code] || {}));
    $scope.officesMap = new Map($scope.offices.map(i => [i.id, i.label])) || {};
    $scope.contractTypesMap = new Map($scope.contractTypes.map(i => [i.id, i.label])) || {};
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.patientsMap = DatabaseApi.patients() || {};
    $scope.hhaIntegrationGlobalFilter = { val: "" };
    $scope.selectedIssueName = "";
    $scope.selectedOffices = [];
    $scope.selectedContracts = [];

    $scope.integrationDatePicker = {
        from: new Date(JSJoda.LocalDate.now().minusMonths(3).format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy"))),
        to: new Date(JSJoda.LocalDate.now().format(JSJoda.DateTimeFormatter.ofPattern("MM/dd/yyyy")))
    }

    $scope.tablesMap = {
        'Rejections': null,
        'Billing Exceptions': null,
        'Pre Adj Rejections': null
    }

    $scope.selectionLogicMap = {
        'Rejections': null,
        'Billing Exceptions': null,
        'Pre Adj Rejections': null
    }

    $scope.filterComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '300px',
        enableSearch: true
    };

    const initialize = () => {
        $scope.isLoading = true;
        getIntegrationBillingRejections();
    }

    const initTable = () => {
        const options = {
            count: 25,
            sorting: { scheduledStartTime: "desc" }
        };

        $scope.tablesMap['Rejections'] = new NgTableParams(options, {
            counts: [],
            dataset: $scope.billingRejections.responseFileRejections
        });

        $scope.tablesMap['Billing Exceptions'] = new NgTableParams(options, {
            counts: [],
            dataset: $scope.billingRejections.billingExceptions
        });

        $scope.tablesMap['Pre Adj Rejections'] = new NgTableParams(options, {
            counts: [],
            dataset: $scope.billingRejections.preAdjRejections
        });
    }

    const reInitTable = (data) => {
        const options = {
            count: 25,
            sorting: { scheduledStartTime: "desc" }
        };

        $scope.tablesMap[$scope.activeTab] = new NgTableParams(options, {
            counts: [],
            dataset: data
        });
    }

    const initSelectionLogic = () => {
        // Rejections
        $scope.selectionLogicMap['Rejections'] = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogicMap['Rejections'].addItemToCollection(item);
        }, "visitInstanceId");

        $scope.billingRejections.responseFileRejections.forEach(item => {
            $scope.selectionLogicMap['Rejections'].initItem(item);
        });

        // Billing Exceptions
        $scope.selectionLogicMap['Billing Exceptions'] = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogicMap['Billing Exceptions'].addItemToCollection(item);
        }, "visitInstanceId");

        $scope.billingRejections.billingExceptions.forEach(item => {
            $scope.selectionLogicMap['Billing Exceptions'].initItem(item);
        });

        // Pre Adj Rejections
        $scope.selectionLogicMap['Pre Adj Rejections'] = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogicMap['Pre Adj Rejections'].addItemToCollection(item);
        }, "visitInstanceId");

        $scope.billingRejections.preAdjRejections.forEach(item => {
            $scope.selectionLogicMap['Pre Adj Rejections'].initItem(item);
        });
    };

    const reGenerateSelectionLogic = (data) => {
        $scope.selectionLogicMap[$scope.activeTab] = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogicMap[$scope.activeTab].addItemToCollection(item);
        }, "visitInstanceId");

        data.forEach(item => {
            $scope.selectionLogicMap[$scope.activeTab].initItem(item);
        });
    }

    $scope.applySearch = () => {
        initialize();
    }

   function getIntegrationBillingRejections() {
        $scope.billingRejections = null;

        hhaxIntegrationService.getBillingRejections($scope.integrationDatePicker, $scope.selectedOffices, $scope.selectedContracts).then(res => {
            $scope.billingRejections = res;
            initTable();
            initIssues();
            $scope.activeTab = "Rejections";
            initSelectionLogic();
            $scope.isLoading = false;
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'could not get DATA');
            $scope.isLoading = false;
        });
    }

    $scope.getRowPatient = (row) => {
        return `${row.patientFirstName} ${row.patientLastName} ${row.patientDisplayId === null ? '' : `(${row.patientDisplayId})`}`;
    }

    $scope.getRowCaregiver = (row) => {
        if (row.caregiverId !== null) {
            return `${row.caregiverFirstName} ${row.caregiverLastName} ${row.caregiverDisplayId === null ? '' : `(${row.caregiverDisplayId})`}`;
        }
    }

    $scope.getRowServiceCode = (row) => {
        if (row.serviceCodeId !== undefined) {
            return `${$scope.serviceCodesMap.get(row.serviceCodeId)} (${row.serviceCodeId})`
        }
    }

    $scope.openPatientInNewTab = (patientId)  => {
        $rootScope.openPatientNewTab(patientId)
    }

    $scope.openPatientModal = (patientId)  => {
        $rootScope.openPatientModal(patientId)
    }

    $scope.openCaregiverInNewTab = (caregiverId)  => {
        $rootScope.openCaregiverNewTab(caregiverId)
    }

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.onDateRangeChanged = (startDate, endDate) => {
        $scope.integrationDatePicker.from = startDate;
        $scope.integrationDatePicker.to = endDate;
    }

    $scope.applyHHAIntegrationGlobalSearch = () => {
        const filter = { $: $scope.hhaIntegrationGlobalFilter.val, issue: $scope.selectedIssueName };
        if ($scope.tablesMap[$scope.activeTab]) {
            angular.extend($scope.tablesMap[$scope.activeTab].filter(), filter);
        }
    };

    $scope.setTab = (tabName) => {
        $scope.activeTab = tabName;
        setIssues();
    }

    const initIssues = () => {
        $scope.issuesCounters = {};

        $scope.allIssues = {
            rejections: getIssuesWithCount($scope.billingRejections.responseFileRejections).map((item, index) => ({
                id: index,
                name: item,
                isSelected: false,
                tooltip: HHAXIssuesToolTip(item)
            })),
            billingExceptions: getIssuesWithCount($scope.billingRejections.billingExceptions).map((item, index) => ({
                id: index,
                name: item,
                isSelected: false,
                tooltip: HHAXIssuesToolTip(item)
            })),
            preAdjRejections: getIssuesWithCount($scope.billingRejections.preAdjRejections).map((item, index) => ({
                id: index,
                name: item,
                isSelected: false,
                tooltip: HHAXIssuesToolTip(item)
            })),
        };

        $scope.pageIssues = $scope.allIssues.rejections;
    }

    const getIssuesWithCount = (rejectionRecords) => {
        const uniqueIssues = new Set();

        for (const item of rejectionRecords) {
            const itemIssues = item.issue.split(",");

            for (const issue of itemIssues) {
                uniqueIssues.add(issue);
                
                if ($scope.issuesCounters[issue] === undefined) {
                    $scope.issuesCounters = {...$scope.issuesCounters, [issue]: 1}
                } else {
                    $scope.issuesCounters[issue]++;
                }
            }
        }

        return [...uniqueIssues].sort((a, b) => a.localeCompare(b));
    }

    $scope.getIssueCounterSuffix = (issue) => {
        if (!$scope.issuesCounters[issue]) {
          return "";
        }
        return ` (${$scope.issuesCounters[issue]})`;
    };

    const setIssues = () => {
        switch($scope.activeTab) {
            case 'Rejections':
                $scope.pageIssues = $scope.allIssues.rejections;
                break;
            case 'Billing Exceptions':
                $scope.pageIssues = $scope.allIssues.billingExceptions;
                break;
            case 'Pre Adj Rejections':
                $scope.pageIssues = $scope.allIssues.preAdjRejections;
                break;
            default:
                $scope.pageIssues = [];
        }    
    }

    const HHAXIssuesToolTip = (issue) => {
        let tooltip;

        switch(issue) {
            case "Medicaid Number is required.":
                tooltip = "Validate that the patient has a Medicaid Number on his profile page/active contract."
                break;  
            case "Caregiver Code is required.":
                tooltip = "Validate that there is a caregiver assigned to the visit.";
                break;
            case "Patient not found in HHAeXchange.":
                tooltip = "Validate that the Medicaid Number in HHAeXchange is equal to the one in Medflyt.";
                break;
            case "Procedure Code not found in HHAeXchange.":
                tooltip = "The service code 'export code' in Medflyt is different from the one HHAeXchange required. Please refer to HHAeXchange 'EDI Code Table Guide' of your state.";
                break;
            case "Overlapping shifts are not allowed.":
                tooltip = "This might be related to HHAeXchange account setup. Please get in touch with Medflyt's support team.";
                break;
            case "Agency is not linked with Payer.":
            case "Visit Edit Reason Code not found in HHAeXchange.":
            case "Visit Edit Action Taken not found in HHAeXchange.":
            case "Invalid value of Gender.":
            case "Payer ID is required.":
                tooltip = "Please get in touch with Medflyt's support team.";
                break;
            case "Procedure Code is required.":
                tooltip = "Validate that the service code's 'export code' in Medflyt is not empty.";
                break;
            case "Caregiver profile found with matching SSN and different Alt Caregiver Code.":
                tooltip = "Caregiver's Alt Code in HHAeXchange is not equal to Caregiver ID in Medflyt.";
                break;
            case "Visits cannot be imported prior to patient SOC date or after patient discharge date.":
                tooltip = "";
                break;
            case "Patient Diagnosis Code(DX Code) is required when visit is confirmed or billed.":
                tooltip = "Patient is missing dx code for these visits.";
                break;
            case "Visits that cross over midnight must be sent as two separate shifts.":
                tooltip = "Please get in touch with Medflyt's support team.";
                break;
            case "Caregiver Compliance":
                tooltip = "";
                break;
            case "No Authorization":
                tooltip = "";
                break;
            case "Shift Overlapping":
                tooltip = "";
                break;
            case "Timesheet Not Approved":
                tooltip = "";
                break;
            case "Caregiver Overlapping":
                tooltip = "";
                break;
            case "Incomplete Confirmation":
                tooltip = "";
                break;
            case "POC Compliance":
                tooltip = "";
                break;
            case "Pending billing of additional shifts on same day":
                tooltip = "";
                break;
            case "Missing Primary Diagnosis":
                tooltip = "";
                break;
            case "Billing greater than 24 hours":
                tooltip = "";
                break;
            case "Visits on same day/service code must be billed on same invoice":
                tooltip = "";
                break;
            default:
        }

        return tooltip;
        
    }

    $scope.filterByIssue = (issue, activeTab) => {
        let filter;
        
        if (issue.isSelected) {
            filter = { issue: "" };
            $scope.selectedIssueName = "";
            $scope.pageIssues = $scope.pageIssues.map(item => 
                issue.id === item.id ? ({...item, isSelected: false}) : item
            );
        } else {
            filter = { issue: issue.name };
            $scope.selectedIssueName = issue.name;
            $scope.pageIssues = $scope.pageIssues.map(item => 
                issue.id === item.id ? ({...item, isSelected: true}) : ({...item, isSelected: false})
            );
        }

        const filteredData = getIssueFilteredData(filter.issue);
        reInitTable(filteredData);
        $scope.selectionLogicMap[activeTab].initItemsCollection(filteredData);
    }

    const getIssueFilteredData = (issue) => {
        let filteredData;

        switch($scope.activeTab) {
            case 'Rejections':
                $scope.billingRejections.responseFileRejections = $scope.billingRejections.responseFileRejections.map(element => ({
                    ...element,
                    isSelected: false
                }));
                filteredData = issue === "" ? $scope.billingRejections.responseFileRejections : $scope.billingRejections.responseFileRejections.filter(item => item.issue === issue);
                break;
            case 'Billing Exceptions':
                $scope.billingRejections.billingExceptions = $scope.billingRejections.billingExceptions.map(element => ({
                    ...element,
                    isSelected: false
                }));
                filteredData = issue === "" ? $scope.billingRejections.billingExceptions : $scope.billingRejections.billingExceptions.filter(item => item.issue === issue);
                break;
            case 'Pre Adj Rejections':
                $scope.billingRejections.preAdjRejections = $scope.billingRejections.preAdjRejections.map(element => ({
                    ...element,
                    isSelected: false
                }));
                filteredData = issue === "" ? $scope.billingRejections.preAdjRejections : $scope.billingRejections.preAdjRejections.filter(item => item.issue === issue);
                break;
            default:
                filteredData = [];
        }

        return filteredData;
    }

    $scope.openModalToExportInvoices = function () {
        const selectedItems = $scope.selectionLogicMap[$scope.activeTab].getSelectedItems();
        billingInvoicesProcessor.openInvoicesExporterModalByVisits(selectedItems.map(item => item.visitInstanceId), initialize());
    };

    $scope.downloadTable = () => {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = ['Visit Instance ID', 'Invoice ID', 'Office', 'Contract', 'Service Code', 'Patient Name', 'Patient Id', 'Caregiver Name', 'Caregiver Id', 'Issue'];

        if ($scope.activeTab === 'Rejections') {
            titles.push('Last Exported At');
        } else {
            titles.push('Last File Date');
        }

        rows.push(titles);

        $scope.selectionLogicMap[$scope.activeTab].getSelectedItems().forEach(dataRow => {
            const csvRow = [];
            
            csvRow.push(`${dataRow.visitInstanceId}`);
            csvRow.push(`${dataRow.invoiceId}`);
            csvRow.push(`${$scope.officesMap.get(dataRow.officeId) ?? ''}`);
            csvRow.push(`${$scope.contractTypesMap.get(dataRow.contractTypeId)}`);
            csvRow.push(`${$scope.serviceCodesMap.get(dataRow.serviceCodeId)}`);
            csvRow.push(`${dataRow.patientFirstName} ${dataRow.patientLastName}`);
            csvRow.push(`${dataRow.patientDisplayId ?? ''}`);
            csvRow.push(`${dataRow.caregiverFirstName ?? ''} ${dataRow.caregiverLastName ?? ''}`);
            csvRow.push(`${dataRow.caregiverDisplayId ?? ''}`);
            csvRow.push(`${dataRow.issue.replaceAll(",", " | ")}`);

            if ($scope.activeTab === 'Rejections') {
                csvRow.push($filter("mfShortDate")(dataRow.exportedAt) || '');
            } else {
                csvRow.push($filter("mfShortDate")(dataRow.hhaIntegrationBillingFileDatetime) || '');
            }

            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(rowArray => {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `medflyt-export-HHAX-rejections-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`);
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    $rootScope.$on("got_caregivers_data",() => {
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
    });

    initialize();
});
