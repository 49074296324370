'use strict'
app.service('paginationLogic', function () {

    /*

    // example for 'onPageSelected' method:
    onPageSelected = function (eventObject, pageNumber, firstItemIndex, amountOfItems) {
        //DO SOMETHING
    };

    */
    var createNewLogic = function (onPageSelected) {

        var paginationData = {
            totalCount: 0,
            countPerPage: 10,
            lastPageNumber: 1,
            currentPageNumber: 1,
            pageLabelsToRender: [],
            pageCounts: [],
        };

        // atom action
        var updateTotalCount = function (totalAmountOfItems) {
            paginationData.totalCount = totalAmountOfItems;
        };

        // atom action
        var updateCountPerPage = function (countPerPage) {
            var validCountPerPage = countPerPage && countPerPage > 0 ? countPerPage : 10;
            paginationData.countPerPage = validCountPerPage;
        };

        // atom action
        var calculateLastPageNumber = function () {
            paginationData.lastPageNumber = Math.ceil(paginationData.totalCount / paginationData.countPerPage);
        };

        // atom action
        var updateCurrentPageNumber = function (selectedPageNumber) {
            paginationData.currentPageNumber = selectedPageNumber;
        };

        // atom action
        var updatePageCounts = function (pageCounts) {
            paginationData.pageCounts = pageCounts || [];
        };

        var initializePaginationData = function (totalAmountOfItems, countPerPage, pageCounts) {
            updateTotalCount(totalAmountOfItems);
            updateCountPerPage(countPerPage);
            calculateLastPageNumber();
            updateCurrentPageNumber(1);
            updatePageCounts(pageCounts);
        };

        var getPageNumberLabel = function (pageNumber) {
            return { value: pageNumber, label: pageNumber.toString() };
        };

        var getPageLabelBetweenPages = function () {
            return { value: -1, label: "...", isClickDisabled: true };
        };

        var updatePageLabelsToRender = function () {
            var toCreateLabelsForAllPageNumbers = paginationData.lastPageNumber <= 9;
            const pageLabels = [];
            if (toCreateLabelsForAllPageNumbers) {
                for (var pageNumber = 1; pageNumber <= paginationData.lastPageNumber; pageNumber++) {
                    pageLabels.push(getPageNumberLabel(pageNumber));
                }
            }
            else {
                // when one of the first pages is selected
                if (paginationData.currentPageNumber < 6) {
                    for (var pageNumber = 1; pageNumber <= 7; pageNumber++) {
                        pageLabels.push(getPageNumberLabel(pageNumber));
                    }
                    pageLabels.push(getPageLabelBetweenPages());
                    pageLabels.push(getPageNumberLabel(paginationData.lastPageNumber));
                }
                // when one of the last pages is selected
                else if (paginationData.currentPageNumber > paginationData.lastPageNumber - 5) {
                    pageLabels.push(getPageNumberLabel(1));
                    pageLabels.push(getPageLabelBetweenPages());
                    for (var pageNumber = paginationData.lastPageNumber - 6; pageNumber <= paginationData.lastPageNumber; pageNumber++) {
                        pageLabels.push(getPageNumberLabel(pageNumber));
                    }
                }
                // when one of the middle pages is selected
                else {
                    pageLabels.push(getPageNumberLabel(1));
                    pageLabels.push(getPageLabelBetweenPages());
                    for (var pageNumber = paginationData.currentPageNumber - 2; pageNumber <= paginationData.currentPageNumber + 2; pageNumber++) {
                        pageLabels.push(getPageNumberLabel(pageNumber));
                    }
                    pageLabels.push(getPageLabelBetweenPages());
                    pageLabels.push(getPageNumberLabel(paginationData.lastPageNumber));
                }
            }
            paginationData.pageLabelsToRender = pageLabels;
        };

        var hasPages = function () {
            return paginationData.lastPageNumber > 1;
        };

        var hasPreivousPage = function () {
            return paginationData.currentPageNumber > 1;
        };

        var hasNextPage = function () {
            return paginationData.currentPageNumber < paginationData.lastPageNumber;
        };

        var isPageSelected = function (pageNumber) {
            return paginationData.currentPageNumber === pageNumber;
        };

        var getFirstItemIndexOfCurrentPage = function () {
            return (paginationData.currentPageNumber - 1) * paginationData.countPerPage;
        };

        var selectPage = function (eventObject, selectedPageNumber) {
            updateCurrentPageNumber(selectedPageNumber);
            updatePageLabelsToRender();
            if (onPageSelected) {
                var firstItemIndex = getFirstItemIndexOfCurrentPage();
                onPageSelected(eventObject, paginationData.currentPageNumber, firstItemIndex, paginationData.countPerPage);
            }
        };

        var selectFirstPage = function (eventObject) {
            selectPage(eventObject, 1);
        };

        var logic = {

            data: paginationData,

            initPaginationData: initializePaginationData,

            hasPages: hasPages,

            hasPreivousPage: hasPreivousPage,

            hasNextPage: hasNextPage,

            isPageSelected: isPageSelected,

            onSelectPage: function (eventObject, selectedPageNumber) {
                if (isPageSelected(selectedPageNumber)) return;
                selectPage(eventObject, selectedPageNumber);
            },

            onSelectPreviousPage: function (eventObject) {
                if (hasPreivousPage()) {
                    selectPage(eventObject, paginationData.currentPageNumber - 1);
                }
            },

            onSelectNextPage: function (eventObject) {
                if (hasNextPage()) {
                    selectPage(eventObject, paginationData.currentPageNumber + 1);
                }
            },

            selectFirstPage: selectFirstPage,

            onPageCountSelected: function (eventObject, pageCount) {
                initializePaginationData(paginationData.totalCount, pageCount, paginationData.pageCounts);
                selectFirstPage(eventObject);
            },

        };

        return logic;

    };

    this.createNewLogic = createNewLogic;

});
