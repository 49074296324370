app.controller('applicantDownloadModalCtrl', function($scope, $rootScope, NgTableParams, $state, $uibModalInstance, modalData, DatabaseApi, $timeout, $window, toaster){

    $scope.closeModal = function(){
        $uibModalInstance.close('cancel');
    };

    console.log(modalData);

    $scope.sections = [];

    modalData.answers.forEach(function(section) {
        $scope.sections.push(section);
    });

    $scope.sections.sort(function(a, b){ if(a.data.title < b.data.title) return -1; if(a.data.title > b.data.title) return 1; return 0; });

    $scope.applicatDownloadDropDownOptions = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
        scrollableHeight: '180px',
        enableSearch: true
    };

    $scope.showSubSections = function(section) {
        $scope.subsections = [];
        section.subSections.forEach(function(subsection) {
            $scope.subsections.push(subsection);
        });


    }

    $scope.selectedProfileAreas = [];
});