app.controller('addFileTypeModalCtrl', function($scope, $rootScope, $uibModalInstance, toaster, certification, section, DatabaseApi){

    $scope.form = {};
    $scope.uploading = false;

    $scope.closeModal = function(){
        $uibModalInstance.dismiss();
    };

    $scope.addFile = function(name,  expires) {

        var body = {
            name: name,
            section: section,
            expires: expires || false
        };

        $scope.uploading = true;
        var url = 'hr/agencies/' + $rootScope.agencyId + '/certification/' + certification +
            '/document_types/custom';
        DatabaseApi.post(url, body).then(function (res) {
            toaster.pop('success','Something went wrong');
            $scope.closeModal();
        }, function (err) {
            $scope.uploading = false;
            toaster.pop('error','Something went wrong');
        });
    };

});

