/**
 * ORIGINAL FILE = officesService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
export class OfficesService {
    DatabaseApi2;
    endpoint;
    $rootScope;
    offices = undefined;
    constructor(DatabaseApi2, endpoint, $rootScope) {
        this.DatabaseApi2 = DatabaseApi2;
        this.endpoint = endpoint;
        this.$rootScope = $rootScope;
    }
    /**
     * Get a list of agency offices
     */
    async getOffices(params = {
        onlyActives: false,
        refetch: false
    }) {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/offices",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
            },
        });
        if (!params.refetch && this.offices !== undefined) {
            return params.onlyActives ? this.offices.filter(row => row.active) : this.offices;
        }
        return this.DatabaseApi2.get(url).then((res) => {
            this.offices = res.data.offices;
            return params.onlyActives ? this.offices.filter(row => row.active) : this.offices;
        });
    }
    ;
    /**
     * Update agency members offices list
     */
    updateAgencyMembersOffices = (agencyId, agencyMemberId, data) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/offices/agency_member/:agencyMemberId/assoc_agency_members",
            params: {
                agencyId: agencyId,
                agencyMemberId: agencyMemberId,
            },
        });
        return this.DatabaseApi2.put(url, data);
    };
    /**
     * Update caregivers offices list
     */
    updateCaregiversOffices = (agencyId, agencyMemberId, data) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/offices/agency_member/:agencyMemberId/assoc_caregivers",
            params: {
                agencyId: agencyId,
                agencyMemberId: agencyMemberId,
            },
        });
        return this.DatabaseApi2.put(url, data);
    };
    /**
     * Insert office
     */
    insertOffice = (agencyId, agencyMemberId, data) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/office",
            params: {
                agencyId: agencyId,
                agencyMemberId: agencyMemberId,
            },
        });
        return this.DatabaseApi2.post(url, data).then(({ data }) => this.offices?.push(data));
    };
    /**
     * Update office
     */
    updateOffice = (agencyId, agencyMemberId, officeId, data) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/office/:officeId",
            params: {
                agencyId: agencyId,
                agencyMemberId: agencyMemberId,
                officeId: officeId,
            },
        });
        return this.DatabaseApi2.put(url, data).then(({ data }) => {
            this.offices = this.offices?.map((office) => (office.id === officeId ? data : office));
        });
    };
}
angular.module("dashboard").service("officesService", OfficesService);
